import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Grid from "./Grid.tsx_";
// import HeaderGrid from "./HeaderGrid";
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import MailIcon from '@material-ui/icons/Mail';
import * as Line from '../Line/Saga/Line';
import * as Mail from '../Mail/Saga/Mail';
import SmsIcon from '@material-ui/icons/Sms';
import IconButton from '@material-ui/core/IconButton';
// import Search from "./Search.tsx_";
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
import * as Owner from './Saga/Owner';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
interface Props {
    get_owner_detail: any,
    owner_detail: any
    get_owner_renrakusaki: any,
    get_owner_address: any,
    owner_id: number,
    owner_renrakusaki: any,
    owner_address: any,
    change_mail_new: any,
    get_response_lines: any,
    // customerNewDisp:number,
    // customerno:number,
    // customerList:any,
    // toggle:any,
    // get_rent_contract_accounting:any,
    // get_remittance_details: any,
    // get_rent_moneies: any,
    // search_detail: any,
    // classes: any,
    // search_loading: boolean,
    // get_rent_money_bklist: any,
    // bklist: any,
    // addky: any,
    // delky: any,
}



interface State {
    reflesh: boolean,
    responseTabs: string,
    customerTabs: string,
    modalkys: boolean,

    // state types
}


const styles = ((theme: any) => ({

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}))



class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        // this.props.change_search_name("")
        this.state = {
            reflesh: false,
            responseTabs: "k1",
            modalkys: false,
            customerTabs: "kys1",
        }
        this.props.get_owner_detail(this.props.owner_id)
        this.props.get_owner_address(this.props.owner_id)
        this.props.get_owner_renrakusaki(this.props.owner_id)
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handlecustomerTabChange = this.handlecustomerTabChange.bind(this);
        this.togglekys = this.togglekys.bind(this);
        this.mail = this.mail.bind(this);

    }
    componentWillMount() {
        // this.props.get_owner_remittance_check_logs()
        // this.props.get_remittance_details()

        // this.props.get_rent_money_bklist()
        // this.props.get_rent_moneies()
        // this.props.customerNewDispSet(0)
        // this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state: any) {
        console.log(state)

        // this.props.get_remittance_details(state)
    }

    togglekys(state: any) {
        console.log(state)
        this.setState({ modalkys: !this.state.modalkys })
        // this.props.get_remittance_details(state)
    }

    csv() {

        // this.props.get_rent_contract_accounting(this.props.kyousais);
    }
    handleTabChange = (event: any, newValue: string) => {

        this.setState({ responseTabs: newValue });
    };
    handlecustomerTabChange = (event: any, newValue: string) => {

        this.setState({ customerTabs: newValue });
    };

    mail() {
        let maildetail = {
            toMail: "",
            toMailall: "",
            fromMail: "web@global-center.co.jp",
            fromName: "グローバルセンター",
            mailAccountId: 12,
            isSended: 1,
            rentResponseId: 93180
        };



        this.props.change_mail_new(maildetail)

    }

    render() {

        return (
            <div style={{ padding: 10, background: "#b0c4de" }} >

                <div className="row" style={{ padding: 10 }} >



                    {/* <Tabs
                    value={this.state.customerTabs}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handlecustomerTabChange}
                    aria-label="disabled tabs example"
                    style={{ float: "left" }}
                >
                    <Tab label="基本情報" value="kys1" />
                    <Tab label="勤務先" value="kys2" />
                    <Tab label="連絡先" value="kys3" />
                    <Tab label="書類送付先" value="kys4" />
                    <Tab label="保証人①" value="kys5" />

                    {/* <Tab label="見積・契約金" value="r2" /> */}
                    {/* {this.props.responsedetail.rentContractId > 0 ? <Tab label="売上" value="r3" /> : ""}
                        {this.props.responsedetail.rentContractId > 0 ? <Tab label="契約" value="r4" /> : ""}
                        {this.props.responsedetail.rentContractId > 0 ? <Tab label="進捗管理" value="r5" /> : ""} */}
                    {/* </Tabs> */}
                    <div style={{ padding: 10 }} className="col-lg-6">
                        {this.props.owner_detail.owner_name ? <Card >
                            <CardContent >
                                <div><TextField id="standard-basic" label="法人区分" value={this.props.owner_detail.houjin_kbn} style={{ width: 100 }} /></div>

                                <div><TextField id="standard-basic" label="オーナー名" value={this.props.owner_detail.owner_name} style={{ width: 400 }} />
                                    {/* <IconButton aria-label="delete"><MailIcon style={{ color: "blue" }} onClick={this.mail} /></IconButton>
                                    <IconButton aria-label="delete"><i className="fab fa-line" style={{ fontSize: "18px", color: "green" }} onClick={() => this.props.get_response_lines(93180)}></i></IconButton>
                                    <IconButton aria-label="delete"><SmsIcon /></IconButton> */}

                                </div>
                                <div><TextField id="standard-basic" label="オーナー名カナ" value={this.props.owner_detail.owner_name_kana} style={{ width: 400 }} /></div>

                                {this.props.owner_address.length > 0 ? this.props.owner_address.map(function (value: any) {
                                    return <div> <div><TextField id="standard-basic" label="郵便番号" value={value.zip_1 + "-" + value.zip_2} /></div>
                                        <div><TextField id="standard-basic" label="住所" value={value.todoufuken + value.shikuchoson + value.choiki + value.other_address} style={{ width: 400 }} /></div>
                                    </div>
                                }, this) : ""}

                                {this.props.owner_renrakusaki.length > 0 ? this.props.owner_renrakusaki.map(function (value: any) {
                                    return <div>
                                        <div><TextField id="standard-basic" label={value.shubetsu} value={value.tel_mail} /></div>
                                    </div>
                                }, this) : ""}


                            </CardContent>

                        </Card> : ""}
                    </div>
                    <div style={{ padding: 10 }} className="col-lg-6">
                        <Card  >
                            <CardContent style={{ textAlign: "center" }} >
                                <img src={process.env.REACT_APP_HOMESTATION4_URL + "/manage/common/img/owner/" + this.props.owner_id} alt="オーナー" width="100" />
                            </CardContent>

                        </Card>
                        <Card style={{ marginTop: 10 }}  >
                            <CardContent >
                                <div><TextField id="standard-basic" label="生年月日" value={this.props.owner_detail.kys1_birthday ? moment(this.props.owner_detail.kys1_birthday).format("YYYY年MM月DD日") : ""} /></div>

                                <div><TextField id="standard-basic" label="本籍" value={this.props.owner_detail.kys1_honseki} /></div>
                                {/* <div><TextField id="standard-basic" label="備考"

                                    multiline
                                    rows={4}

                                    variant="outlined"
                                    value={this.props.owner_detail.bikou} /></div>

                                <div><TextField id="standard-basic" label="趣味"

                                    multiline
                                    rows={4}

                                    variant="outlined"
                                    value={this.props.owner_detail.shumi} /></div> */}


                            </CardContent>

                        </Card>
                    </div>
                </div >
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // search_loading: state.ContractCheck.search_loading,
        // minories: state.Minori.minories,
        // bklist: state.RemittanceDetail.bklist,
        // addky: state.RemittanceDetail.addky,
        owner_detail: state.owner.owner_detail,
        owner_address: state.owner.owner_address,
        owner_renrakusaki: state.owner.owner_renrakusaki,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_owner_detail(state: any) {
            dispatch(Owner.get_owner_detail(state))
        },
        get_owner_renrakusaki(state: number) {
            dispatch(Owner.get_owner_renrakusaki(state))
        },
        get_owner_address(state: number) {
            dispatch(Owner.get_owner_address(state))
        },
        change_mail_new(state: any) {
            dispatch(Mail.change_mail_new(state))
        },
        get_response_lines(state: any) {
            dispatch(Line.get_response_lines(state))
        },

        // get_remittance_details(state: any) {
        //     dispatch(RentMoney.get_remittance_details(state))
        // },
        // get_owner_remittance_check_logs(state: any) {
        //     dispatch(RemittanceLogCheck.get_owner_remittance_check_logs(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));




