import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import ja from "date-fns/locale/ja";
import './Css/Grid.css';
import DateFnsUtils from '@date-io/date-fns';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import RequestDetail from './Index';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    }
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>
        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}
      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}
const columns =
  [
    { key: 'tsuki', name: '月', width: 150, resizable: true, frozen: true },
    { key: 'keiyaku', name: '契約数', width: 80, resizable: true },
    { key: 'touroku', name: '登録数', width: 110, resizable: true },
    { key: 'count', name: '未登録数', width: 80, resizable: true },
    { key: 'parse', name: '登録率', width: 110, resizable: true },
  ]

const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} > {props[rowIndex][column.key]} </Cell>

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns,
      reflash: false,
      colSortDirs: {},

    };

    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);

  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }





  _onSortChange(columnKey, sortDir) {

    let seisan_follow_search_list = this.props.signup_count_list;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  render() {
    return (
      <div id="seisanlist">
        <Table
          rowHeight={30}
          rowsCount={this.props.signup_count_list.length}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          width={window.innerWidth - 1350}
          height={window.innerHeight - 700}
          headerHeight={30}>
          {columns.map(function (value, i) {
            return <Column
              columnKey={value.key}
              header={<SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={this.state.colSortDirs[value.key]}
                style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

              cell={({ rowIndex, ...props }) => (
                <Base column={value} props={this.props.signup_count_list} rowIndex={rowIndex}></Base>
              )}
              width={this.state.columns.filter(a => a.key === value.key)[0].width}
              isResizable={true}
              fixed={value.frozen}
            />
          }, this)}
        </Table >
      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    signup_count_list: state.board.signup_count_list,
  }
}
function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);