import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import './Css/Grid.css';
import './Css/Keiyaku.css';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import SyousaiModal from "./SyousaiModal";
import * as Keiyaku from './Saga/Keiyaku';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { green } from '@material-ui/core/colors';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import SmsModal from "./SmsModal";
import NoticeModal from "./NoticeModal";
import NewsListModal from "./NewsListModal";
import Button from '@material-ui/core/Button';
import jaLocale from "date-fns/locale/ja";
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns'


const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');
var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

class JaLocalizedUtils extends DateFnsUtils {
  // ヘッダ部分のテキストを取得するメソッド
  getDatePickerHeaderText(date) {
    return format(date, 'M月d日(E)', { locale: this.locale })
  }
}


const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    }
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>
        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}
const columns =
  [
    // { key: 'check', name: 'SMS', width: 50, resizable: true },
    { key: 'appcheck', name: 'お知らせ', width: 90, resizable: true },
    { key: 'Tatemono_Name', name: '建物名', width: 200, resizable: true },
    { key: 'Room_Name', name: '部屋名', width: 70, resizable: true },
    { key: 'name', name: '顧客名', width: 200, resizable: true },
    { key: 'Keiyaku_Date', name: '契約日', width: 100, resizable: true },
    { key: 'Kaiyaku_Date', name: '解約日', width: 100, resizable: true },
    { key: 'tel', name: '電話番号', width: 110, resizable: true },
    { key: 'app', name: '入居者アプリ', width: 130, resizable: true },
  ]



const Base = ({ column, props, rowIndex, requestopen }) =>
  <div onClick={() => requestopen(rowIndex)}>
    <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}>
      <Cell style={{ whiteSpace: "nowrap" }} > {props[rowIndex][column.key]} </Cell>
    </Tooltip>
  </div>

const Dates = ({ column, props, rowIndex, requestopen, zoom_text }) =>
  <div onClick={() => requestopen(rowIndex)}>
    <Cell style={{ whiteSpace: "nowrap" }} >
      {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).tz("Asia/Tokyo").format('YYYY-MM-DD') : ""}
    </Cell>
  </div>


// const Check = ({ column, props, rowIndex, smscheck }) =>
//   <Cell style={{ whiteSpace: "nowrap" }} >
//     {props[rowIndex]["tel"] !== "" ?
//       <FormControlLabel
//         control={<GreenCheckbox defaultChecked onChange={smscheck} name={props[rowIndex]["m_customers_id"]} />}
//       />
//       : ""}
//   </Cell>


const AppCheck = ({ column, props, rowIndex, appcheck, appchecked }) =>
  (() => {
    var check_flag = 1;
    appchecked.forEach(element => {
      if (element == props[rowIndex]["m_customers_id"]) {
        check_flag = 0;
      }
    });
    return <Cell style={{ whiteSpace: "nowrap" }} >
      {props[rowIndex]["room_support_m_user_id"] ?

        check_flag == 0 ?
          <FormControlLabel
            control={<GreenCheckbox onChange={appcheck} name={props[rowIndex]["m_customers_id"]} />}
          /> :
          <FormControlLabel
            control={<GreenCheckbox defaultChecked onChange={appcheck} name={props[rowIndex]["m_customers_id"]} />}
          />

        : ""}
    </Cell>
  })()
const App = ({ column, props, rowIndex, smscheck }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props[rowIndex]["room_support_m_user_id"] ? "アプリ登録済み" : ""}
  </Cell>




class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns,
      reflash: false,
      reqtoggle: false,
      colSortDirs: {},
      checked: [],
      appchecked: [],
      smstoggle: false,
      noticetoggle: false,
      newslisttoggle: false,
      grid_disp: true,
      all_check_flag: false,
      houkokusyo_date1: null,
      houkokusyo_date2: new Date()
    };




    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.reqtoggle = this.reqtoggle.bind(this);
    // this.smscheck = this.smscheck.bind(this);
    this.appcheck = this.appcheck.bind(this);
    this.allcheck = this.allcheck.bind(this);

    this.newslisttoggle = this.newslisttoggle.bind(this);
    this.smstoggle = this.smstoggle.bind(this);
    this.noticetoggle = this.noticetoggle.bind(this);
    this.houkokusyo = this.houkokusyo.bind(this);
    this.houkokusyo_date1 = this.houkokusyo_date1.bind(this);
    this.houkokusyo_date2 = this.houkokusyo_date2.bind(this);

  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }





  _onSortChange(columnKey, sortDir) {

    let seisan_follow_search_list = this.props.customers;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  requestopen = (rowIndex) => {
    var data = {
      Keiyaku_No: this.props.customers[rowIndex].Keiyaku_No,
      m_customers_id: this.props.customers[rowIndex].m_customers_id

    }
    this.props.get_detail(data);
    this.reqtoggle();
  }
  reqtoggle() {
    this.setState({ reqtoggle: !this.state.reqtoggle })
  }


  // smscheck = (event) => {
  //   var data = this.state.checked;

  //   if (event.target.checked == false) {
  //     data.push(event.target.name);
  //   } else {
  //     var val = event.taあああ」rget.name;
  //     var index = data.indexOf(val);
  //     data.splice(index, 1);
  //   }
  //   this.setState({ checked: data })
  // }


  appcheck = (event) => {

    console.log(event.target.checked);
    var data = this.state.appchecked;
    console.log(data);

    if (event.target.checked == false) {
      data.push(event.target.name);
    } else {
      var val = event.target.name;
      var index = data.indexOf(val);
      data.splice(index, 1);
    }
    this.setState({ appchecked: data })
  }

  allcheck() {
    this.setState({ grid_disp: false })

    this.props.customers.forEach(element => {
      if (element.room_support_m_user_id) {
        var data = {
          target: {
            checked: this.state.all_check_flag,
            name: String(element.m_customers_id)
          }
        }
        this.appcheck(data);
      }
    });
    setTimeout(() => {
      this.setState({ grid_disp: true })
      this.setState(prevState => ({
        all_check_flag: !prevState.all_check_flag,
      }));
    }, 1000)
  }

  smstoggle() {
    this.setState({ smstoggle: !this.state.smstoggle })
  }

  noticetoggle() {
    this.setState({ noticetoggle: !this.state.noticetoggle })
  }

  newslisttoggle() {
    this.props.get_news_list(
      { tatemono_id: this.props.customers[0].Tatemono_Id }
    );
    this.setState({ newslisttoggle: !this.state.newslisttoggle })

  }




  houkokusyo_date1 = (date) => {
    this.setState({
      houkokusyo_date1: new Date(date),
    });
  }
  houkokusyo_date2 = (date) => {
    this.setState({
      houkokusyo_date2: new Date(date),
    });
  }

  houkokusyo() {
    // this.props.get_periodic_report(
    //   {
    //     id: this.props.tatemono_id,
    //     startdate: this.state.houkokusyo_date1,
    //     enddate: this.state.houkokusyo_date2
    //   }
    // );

  }


  render() {

    return (
      <div id="Keiyaku">

        {this.props.name !== "" ? "" :

          this.props.customers.length == 0 ? "" :
            <h5>
              {this.props.customers[0].Tatemono_Name}
            </h5>

        }

        {this.props.customers.length == 0 ? "" :
          <Button style={{ marginTop:12}} onClick={this.allcheck} color="error" variant="outlined">全て✓</Button>
        }

        {this.props.customers.length == 0 ? "" :
          <Button style={{ marginLeft: 20 ,marginTop:12}} onClick={this.noticetoggle} color="primary" variant="outlined">お知らせの送信</Button>
        }

        {this.props.name !== "" ? "" :

          this.props.customers.length == 0 ? "" :
            <Button style={{ marginLeft: 20,marginTop:12 }} onClick={this.newslisttoggle} color="primary" variant="outlined">この建物の過去のお知らせ</Button>

        }


        {this.props.tatemono_id == 0 ? "" :
            <span style={{ marginLeft: 50 }}>
              {/* 日付選択 */}
              <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                <KeyboardDatePicker
                  variant="inline"
                  margin="normal"
                  id="date-picker-dialog"
                  format="yyyy/MM/dd"
                  style={{ width: 130 }}
                  value={this.state.houkokusyo_date1}
                  onChange={this.houkokusyo_date1}
                  autoOk={true}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>

              <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>

              {/* 日付選択 */}
              <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                <KeyboardDatePicker

                  variant="inline"
                  margin="normal"
                  id="date-picker-dialog"
                  format="yyyy/MM/dd"
                  style={{ width: 130 }}
                  value={this.state.houkokusyo_date2}
                  onChange={this.houkokusyo_date2}
                  autoOk={true}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              <Button onClick={this.houkokusyo} style={{ marginLeft: 20,marginTop:12 }} color="primary" variant="outlined">リクエスト報告書</Button>
            </span>
        }

        {this.state.grid_disp ?
          <Table
            rowHeight={40}
            rowsCount={this.props.customers.length}
            // rowsCount={10}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            width={window.innerWidth - 260}
            height={window.innerHeight - 200}
            headerHeight={30}>
            {columns.map(function (value, i) {
              return <Column
                columnKey={value.key}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                cell={({ rowIndex, ...props }) => (
                  value.key === "appcheck" ? <AppCheck column={value} props={this.props.customers} rowIndex={rowIndex} appcheck={this.appcheck} appchecked={this.state.appchecked}></AppCheck>
                    : value.key === "app" ? <App column={value} props={this.props.customers} rowIndex={rowIndex}></App>
                      // : value.key === "check" ? <Check column={value} props={this.props.customers} rowIndex={rowIndex} smscheck={this.smscheck}></Check>
                      : value.key === "Keiyaku_Date" || value.key === "Kaiyaku_Date" ? <Dates column={value} props={this.props.customers} rowIndex={rowIndex} requestopen={this.requestopen}></Dates>
                        : <Base column={value} props={this.props.customers} rowIndex={rowIndex} requestopen={this.requestopen}></Base>
                )}
                width={this.state.columns.filter(a => a.key === value.key)[0].width}
                isResizable={true}
                fixed={value.frozen}

              />
            }, this)}
          </Table > :
          <div style={{ height: 800 }}></div>
        }


        {/* {this.props.customers.length == 0 ? "" :
          <Button onClick={this.smstoggle} color="secondary" variant="outlined">SMS一斉送信</Button>
        } */}


        < Modal isOpen={this.state.reqtoggle} toggle={this.reqtoggle} size="keiyaku" fade={false}>
          <ModalHeader toggle={this.reqtoggle}>契約情報</ModalHeader>
          <SyousaiModal />
          <ModalFooter>
          </ModalFooter>
        </Modal >

        {/* < Modal isOpen={this.state.smstoggle} toggle={this.smstoggle} size="keiyaku" fade={false}>
          <ModalHeader toggle={this.smstoggle}>SMS一斉送信</ModalHeader>
          <SmsModal tatemono_id={this.props.tatemono_id} checkout={this.state.checked} />
          <ModalFooter>
          </ModalFooter>
        </Modal > */}

        < Modal isOpen={this.state.noticetoggle} toggle={this.noticetoggle} size="keiyaku" fade={false}>
          <ModalHeader toggle={this.noticetoggle}>お知らせの送信</ModalHeader>
          <NoticeModal noticetoggle={this.noticetoggle} tatemono_id={this.props.tatemono_id} checkout={this.state.appchecked} />
          <ModalFooter>
          </ModalFooter>
        </Modal >

        < Modal isOpen={this.state.newslisttoggle} toggle={this.newslisttoggle} size="keiyaku" fade={false}>
          <ModalHeader toggle={this.newslisttoggle}>この建物の過去のお知らせ</ModalHeader>
          <NewsListModal />
          <ModalFooter>
          </ModalFooter>
        </Modal >


      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    customers: state.Keiyaku.customers,
    periodic_report: state.Keiyaku.periodic_report,

  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_detail(state) {
      dispatch(Keiyaku.get_detail(state))
    },
    get_chatlist(state) {
      dispatch(Keiyaku.get_chatlist(state))
    },
    get_news_list(state) {
      dispatch(Keiyaku.get_news_list(state))
    },
    // get_periodic_report(state) {
    //   dispatch(Keiyaku.get_periodic_report(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);