
// Initialize Firebase
export default {
  apiKey: "AIzaSyC-OMPj3vIk8r9XpQbzhc45rN45SY-Oi9w",
  authDomain: "homestation-sms.firebaseapp.com",
  databaseURL: "https://homestation-sms.firebaseio.com",
  projectId: "homestation-sms",
  storageBucket: "homestation-sms.appspot.com",
  messagingSenderId: "294035868454"
};

// var firebaseConfig = {
//   apiKey: "AIzaSyC-OMPj3vIk8r9XpQbzhc45rN45SY-Oi9w",
//   authDomain: "homestation-sms.firebaseapp.com",
//   projectId: "homestation-sms",
//   storageBucket: "homestation-sms.appspot.com",
//   messagingSenderId: "294035868454",
//   appId: "1:294035868454:web:201ca4c454540d98360fe7",
//   measurementId: "G-849T1Y142X"
// };