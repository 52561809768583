import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as KizuCheckListApi from '../Api/KizuCheckList';


///types
const moment = require('moment')

export interface State {
    search_status: boolean,
    kizu_check_list: any,
    kizu_check_detail: any,
    kizu_check_file_list: any,
    kizu_check_file: any
}
const initialState: State = {
    search_status: false,
    kizu_check_list: [],
    kizu_check_detail: [],
    kizu_check_file_list: [],
    kizu_check_file: []
}



///action 
const actionCreator = actionCreatorFactory();

export const search_status_true = actionCreator('SEARCH_STATUS_TRUE');
export const search_status_false = actionCreator('SEARCH_STATUS_FALSE');

export const get_kizu_check_list = actionCreator('GET_KIZU_CHECK_LIST');
export const get_kizu_check_list_success = actionCreator('GET_KIZU_CHECK_LIST_SUCCESS');

export const get_kizu_check_detail = actionCreator('GET_KIZU_CHECK_DETAIL');
export const get_kizu_check_detail_success = actionCreator('GET_KIZU_CHECK_DETAIL_SUCCESS');

export const kizu_check_file_upload = actionCreator('KIZU_CHECK_FILE_UPLOAD');
export const kizu_check_file_delete = actionCreator('KIZU_CHECK_FILE_DELETE');


export const get_kizu_check_file_list = actionCreator('GET_KIZU_CHECK_FILE_LIST');
export const get_kizu_check_file_list_success = actionCreator('GET_KIZU_CHECK_FILE_LIST_SUCCESS');

export const download_kizu_check_file = actionCreator('DOWNLOAD_KIZU_CHECK_FILE');
export const download_kizu_check_file_success = actionCreator('DOWNLOAD_KIZU_CHECK_FILE_SUCCESS');



export const KizuCheckListReducer = reducerWithInitialState(initialState)
    .case(search_status_true, (state, payload) => ({ ...state, search_status: true }))
    .case(search_status_false, (state, payload) => ({ ...state, search_status: false }))
    .case(get_kizu_check_list_success, (state, payload) => ({ ...state, kizu_check_list: payload }))
    .case(get_kizu_check_detail_success, (state, payload) => ({ ...state, kizu_check_detail: payload }))
    .case(get_kizu_check_file_list_success, (state, payload) => ({ ...state, kizu_check_file_list: payload }))
    .case(download_kizu_check_file_success, (state, payload) => ({ ...state, kizu_check_file: payload }))



// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const KizuCheckListSaga = [
    takeEvery('GET_KIZU_CHECK_LIST', handle_get_kizu_check_list),
    takeEvery('GET_KIZU_CHECK_DETAIL', handle_get_kizu_check_detail),
    takeEvery('KIZU_CHECK_FILE_UPLOAD', handle_kizu_check_file_upload),
    takeEvery('KIZU_CHECK_FILE_DELETE', handle_kizu_check_file_delete),
    takeEvery('GET_KIZU_CHECK_FILE_LIST', handle_get_kizu_check_file_list),
    takeEvery('DOWNLOAD_KIZU_CHECK_FILE', handle_download_kizu_check_file),

];






function* handle_download_kizu_check_file(action: any) {
    const { payload, error } = yield call(KizuCheckListApi.download_file, action.payload.id);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload);
        const a = document.createElement('a');
        // ダウンロードされるファイル名
        a.download = action.payload.file_name;
        a.href = URL.createObjectURL(payload);
        // ダウンロード開始
        a.click();
        yield put(download_kizu_check_file_success(payload));
    } else {
        // alert(error)
    }
}



function* handle_kizu_check_file_upload(action: any) {
    const { payload, error } = yield call(KizuCheckListApi.upload_file, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield call(handle_get_kizu_check_file_list, action.payload.Keiyaku_No); // handle_get_kizu_check_file_listを実行
    } else {
    }
}

function* handle_kizu_check_file_delete(action: any) {
    const { payload, error } = yield call(KizuCheckListApi.delete_file, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield call(handle_get_kizu_check_file_list, action.payload.Keiyaku_No); // handle_get_kizu_check_file_listを実行
    } else {
    }
}

function* handle_get_kizu_check_file_list(action: any) {

    console.log("===============")
    console.log(action)
    console.log(action.payload)
    var Keiyaku_No = action.payload;
    if(!action.payload){
        Keiyaku_No = action;
    }

    const { payload, error } = yield call(KizuCheckListApi.get_filelist, Keiyaku_No);
    // const { payload, error } = yield call(KizuCheckListApi.get_filelist, action);

    if (payload && !error) {
        yield put(get_kizu_check_file_list_success(payload));
    } else {
    }
}


function* handle_get_kizu_check_list(action: any) {

    yield put(search_status_true());

    const { payload, error } = yield call(KizuCheckListApi.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(search_status_false());
        yield put(get_kizu_check_list_success(payload));
    } else {
        // alert(error)
    }
}


function* handle_get_kizu_check_detail(action: any) {

    yield put(search_status_true());

    const { payload, error } = yield call(KizuCheckListApi.detail, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(search_status_false());
        yield put(get_kizu_check_detail_success(payload));
    } else {
        // alert(error)
    }
}




