import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as CompleteCheckApi from '../Api/CompleteCheck';


///types
const moment = require('moment')

export interface State {
    search_status: boolean,
    list: any
}
const initialState: State = {
    search_status: false,
    list: []
}



///action 
const actionCreator = actionCreatorFactory();

export const search_status_true = actionCreator('SEARCH_STATUS_TRUE');
export const search_status_false = actionCreator('SEARCH_STATUS_FALSE');

export const get_complete_list = actionCreator('GET_COMPLETE_LIST');
export const get_complete_list_success = actionCreator('GET_COMPLETE_LIST_SUCCESS');

export const complete_update = actionCreator('COMPLETE_UPDATE');
export const complete_approval = actionCreator('COMPLETE_APPROVAL');





export const CompleteListReducer = reducerWithInitialState(initialState)
    .case(search_status_true, (state, payload) => ({ ...state, search_status: true }))
    .case(search_status_false, (state, payload) => ({ ...state, search_status: false }))
    .case(get_complete_list_success, (state, payload) => ({ ...state, list: payload }))



// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const CompleteListSaga = [
    takeEvery('GET_COMPLETE_LIST', handle_get_complete_list),
    takeEvery('COMPLETE_UPDATE', handle_complete_update),
    takeEvery('COMPLETE_APPROVAL', handle_complete_approval),

];


function* handle_get_complete_list(action: any) {

    yield put(search_status_true());

    const { payload, error } = yield call(CompleteCheckApi.search, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

      
        yield put(get_complete_list_success(payload));
        yield put(search_status_false());
    } else {
        // alert(error)
    }
}


function* handle_complete_update(action: any) {
    yield put(search_status_true());

    const { payload, error } = yield call(CompleteCheckApi.update, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(search_status_false());
    } else {
        // alert(error)
    }
}


function* handle_complete_approval(action: any) {
    yield put(search_status_true());

    const { payload, error } = yield call(CompleteCheckApi.complete_approval, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(search_status_false());
    } else {
        // alert(error)
    }
}
