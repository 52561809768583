import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as Board_api from '../Api/Board_api';


///types
const moment = require('moment')

export interface State {
    chat_data_list: any,
    outai_sub_list: any,
    entered_list: any,
    signup_count_list: any,
    splus_count_list: any,
    search_status: boolean,
    loading1:boolean,
    loading2:boolean,

}
const initialState: State = {
    chat_data_list: [],
    outai_sub_list: [],
    entered_list: [],
    signup_count_list: [],
    splus_count_list:[],
    search_status: false,
    loading1:true,
    loading2:true,
}



///action 
const actionCreator = actionCreatorFactory();


export const search_status_true = actionCreator('SEARCH_STATUS_TRUE');
export const search_status_true_success = actionCreator('SEARCH_STATUS_TRUE_SUCCESS');
export const search_status_false_success = actionCreator('SEARCH_STATUS_FALSE_SUCCESS');

export const get_chat_data_list = actionCreator('GET_CHAT_DATA_LIST');
export const get_chat_data_list_success = actionCreator('GET_CHAT_DATA_LIST_SUCCESS');

export const get_outai_sub_list = actionCreator('GET_OUTAI_SUB_LIST');
export const get_outai_sub_list_success = actionCreator('GET_OUTAI_SUB_LIST_SUCCESS');

export const get_entered_list_list = actionCreator('GET_ENTERED_LIST');
export const get_entered_list_success = actionCreator('GET_ENTERED_LIST_SUCCESS');

export const get_signup_count_list = actionCreator('GET_SIGNUP_COUNT_LIST');
export const get_signup_count_list_success = actionCreator('GET_SIGNUP_COUNT_SUCCESS');

export const get_splus_count_list = actionCreator('GET_SPLUS_COUNT_LIST');
export const get_splus_count_list_success = actionCreator('GET_SPLUS_COUNT_SUCCESS');

export const loading1_false = actionCreator('LOADING1_FALSE');
export const loading1_success = actionCreator('LOADING1_SUCCESS');

export const loading2_false = actionCreator('LOADING2_FALSE');
export const loading2_success = actionCreator('LOADING2_SUCCESS');



export const boardReducer = reducerWithInitialState(initialState)
    .case(search_status_true_success, (state, payload) => ({ ...state, search_status: true }))
    .case(search_status_false_success, (state, payload) => ({ ...state, search_status: false }))
    .case(get_chat_data_list_success, (state, payload) => ({ ...state, chat_data_list: payload }))
    .case(get_outai_sub_list_success, (state, payload) => ({ ...state, outai_sub_list: payload }))
    .case(get_entered_list_success, (state, payload) => ({ ...state, entered_list: payload }))
    .case(get_signup_count_list_success, (state, payload) => ({ ...state, signup_count_list: payload }))
    .case(get_splus_count_list_success, (state, payload) => ({ ...state, splus_count_list: payload }))
    .case(loading1_false, (state, payload) => ({ ...state, loading1: false }))
    .case(loading1_success, (state, payload) => ({ ...state, loading1: true }))
    .case(loading2_false, (state, payload) => ({ ...state, loading2: false }))
    .case(loading2_success, (state, payload) => ({ ...state, loading2: true }))



// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const boardSaga = [
    takeEvery('GET_CHAT_DATA_LIST', handle_get_get_chat_data_list),
    takeEvery('GET_OUTAI_SUB_LIST', handle_get_outai_sub_list),
    takeEvery('GET_ENTERED_LIST', handle_get_entered_list_list),
    takeEvery('SEARCH_STATUS_TRUE', handle_search_status_true),
    takeEvery('GET_SIGNUP_COUNT_LIST', handle_get_signup_count_list),
    takeEvery('GET_SPLUS_COUNT_LIST', handle_get_splus_count_list),
];


function* handle_get_splus_count_list(action: any) {
    yield put(loading2_false());
    const { payload, error } = yield call(Board_api.splus_count, action.payload);
    // サーバーからのレスポンスデーaaaタによる分岐aaa処理
    if (payload && !error) {
        yield put(get_splus_count_list_success(payload));
        yield put(loading2_success());

    } else {
        // alert(error)
    }
}


function* handle_get_signup_count_list(action: any) {
    yield put(loading1_false());
    const { payload, error } = yield call(Board_api.signup_count, action.payload);
    // サーバーからのレスポンスデータによる分岐aaaa処理
    if (payload && !error) {
        yield put(get_signup_count_list_success(payload));
        yield put(loading1_success());

    } else {
        // alert(error)
    }
}


function* handle_search_status_true(action: any) {
    yield put(search_status_true_success());
}

function* handle_get_get_chat_data_list(action: any) {
    const { payload, error } = yield call(Board_api.get_chatdata, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_chat_data_list_success(payload));
        yield put(search_status_false_success());

    } else {
        // alert(error)
    }
}

function* handle_get_outai_sub_list(action: any) {
    const { payload, error } = yield call(Board_api.get_subdata, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_outai_sub_list_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_get_entered_list_list(action: any) {
    const { payload, error } = yield call(Board_api.get_entered, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_entered_list_success(payload));
        yield put(search_status_false_success());

    } else {
        // alert(error)
    }
}


