import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as ContractsListApi from '../Api/ContractsList';


///types
const moment = require('moment')

export interface State {
    contracts_list: any,
    search_status: boolean
}
const initialState: State = {
    contracts_list: [],
    search_status: false
}



///action 
const actionCreator = actionCreatorFactory();

export const get_contracts_list = actionCreator('GET_CONTRACTS_LIST');
export const get_contracts_list_success = actionCreator('GET_CONTRACTS_LIST_SUCCESS');

export const search_status_true = actionCreator('SEARCH_STATUS_TRUE');
export const search_status_false = actionCreator('SEARCH_STATUS_FALSE');






export const ContractsListReducer = reducerWithInitialState(initialState)
    .case(get_contracts_list_success, (state, payload) => ({ ...state, contracts_list: payload }))
    .case(search_status_true, (state, payload) => ({ ...state, search_status: true }))
    .case(search_status_false, (state, payload) => ({ ...state, search_status: false }))



// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const ContractsListSaga = [
    takeEvery('GET_CONTRACTS_LIST', handle_get_contracts_list),
];


function* handle_get_contracts_list(action: any) {
    yield put(search_status_true());

    const { payload, error } = yield call(ContractsListApi.search, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log("~~~~~~~~~~~~~~~~~~")
        console.log(payload)
        yield put(search_status_false());
        yield put(get_contracts_list_success(payload));
    } else {
        // alert(error)
    }
}