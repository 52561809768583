import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Adjustment from '../Adjustment/Saga/Adjustment';
import * as CustomerLink from './Saga/CustomerLink';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as Keiyaku from '../Keiyaku/Saga/Keiyaku';

import moment from 'moment'




interface Props {
    linktoggle: any,
    index: any,
    list: any,
    Tatemono: any,
    get_tatemono: any,
    get_room: any,
    Room: any,
    get_customers: any,
    customers: any,
    linking: any,
    linkings: any,
    get_list: any,
    linking_same: any,
    linking_notsame: any,
    links_place: any,
    link_data: any,
    get_family: any,
    family: any,
    user_details: any,
    send_news_personal: any,
    sumikae: any,
    linklist: any,
    get_linklist: any
}
interface State {
    tatemono_name: any,
    Tatemono_Id: any,
    Room_Id: any,
    Kokyaku_Name: any,
    birthday: any,
    tel: any,
    Keiyaku_Date: any,
    m_customer_id: any,
    check_id: any,
    check_tel: any,
    button_disp: any
}

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);

        props.get_tatemono();

        this.state = {
            tatemono_name: "",
            Tatemono_Id: 0,
            Room_Id: 0,
            Kokyaku_Name: "",
            birthday: "",
            tel: "",
            Keiyaku_Date: "",
            m_customer_id: 0,
            check_id: 0,
            check_tel: '',
            button_disp: true
        }

        this.onChange_mcid = this.onChange_mcid.bind(this);
        this.onChangeTatemono = this.onChangeTatemono.bind(this);
        this.onChangeTatemonoName = this.onChangeTatemonoName.bind(this);
        this.onChangeRoom = this.onChangeRoom.bind(this);
        this.link = this.link.bind(this);

    }


    // 登録する人を入居者の中からラジオボタンで選ぶ
    onChange_mcid(e: any) {

        console.log(e.target);
        this.setState({
            check_id: e.target.value,
            check_tel: e.target.id
        });
    }



    onChangeTatemono(event: any, values: any) {
        if (values) {
            this.props.get_room(values.Tatemono_Id);
            this.setState({
                Tatemono_Id: values.Tatemono_Id,
            });
        }
    }

    onChangeTatemonoName(e: any) {
        this.setState({
            tatemono_name: e.target.value,
        });


    }


    onChangeRoom(event: any, values: any) {
        if (values) {
            this.setState({
                Room_Id: values.Room_Id,
                Kokyaku_Name: "",
                birthday: "",
                tel: "",
                Keiyaku_Date: "",
                m_customer_id: 0,
            });

            var data = {
                tatemono_id: this.state.Tatemono_Id,
                room_id: values.Room_Id
            }
            this.props.get_family(data);

            setTimeout(() => {
                console.log("-----------------");
                console.log(this.props.family);
                if (this.props.family[0] && this.props.family[0].id) {
                    this.setState({
                        Kokyaku_Name: this.props.family[0].name,
                        birthday: this.props.family[0].birthday,
                        tel: this.props.family[0].tel,
                        Keiyaku_Date: this.props.family[0].Keiyaku_Date,
                        m_customer_id: this.props.family[0].id,
                    });
                } else {
                    this.setState({
                        Room_Id: values.Room_Id,
                        Kokyaku_Name: "契約情報無し",
                        birthday: "",
                        tel: "",
                        Keiyaku_Date: "",
                        m_customer_id: 0,
                    });
                }
            }, 3000)
        }
    }


    link() {
        if (this.state.check_id == 0) {
            alert("紐づける入居者にチェックを入れて下さい。")
            return;
        }

        // 電話番号が一致していればマッチングランク１　していなければ２で登録する
        var matching_rank = 2;
        if (this.props.linklist[this.props.index].user_tel == this.state.check_tel) {
            matching_rank = 1;
        }

        this.setState({ button_disp: !this.state.button_disp })
        this.props.sumikae({
            room_support_m_user_id: this.props.linklist[this.props.index].room_support_m_user_id,
            m_customer_id: this.state.check_id,
            customer_linked_user_id: this.props.user_details.id,
            matching_rank: matching_rank,
            old_m_customer_id: this.props.linklist[this.props.index].m_customer_id
        });

        setTimeout(() => {
            this.props.get_linklist();
            this.props.linktoggle();
        }, 3000)
    }

    render() {
        return (
            <div style={{ padding: 10, background: "#b0c4de" }} >
                <div className="item-outer col-lg-12">

                    <Card className="col-lg-12" >
                        <CardContent style={{ float: "left" }} className="col-lg-12">
                            <div>現在の契約</div>
                            <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                <tbody>
                                    <tr style={{ border: "0px" }}>
                                        <th colSpan={1} >建物名</th>
                                        <td colSpan={4} >
                                            {this.props.linklist[this.props.index].tatemono_name ? this.props.linklist[this.props.index].tatemono_name : this.props.linklist[this.props.index].tatemono_name2}
                                        </td>
                                        <th colSpan={1} >部屋名</th>
                                        <td colSpan={2} >
                                            {this.props.linklist[this.props.index].room_name ? this.props.linklist[this.props.index].room_name : this.props.linklist[this.props.index].room_name2}
                                        </td>
                                    </tr>
                                    <tr style={{ border: "0px" }}>
                                        <th colSpan={1} >氏名</th>
                                        <td colSpan={4} >
                                            {this.props.linklist[this.props.index].user_name}
                                        </td>
                                        <th colSpan={1} >電話番号</th>
                                        <td colSpan={4} >
                                            {this.props.linklist[this.props.index].user_tel}
                                        </td>
                                    </tr>
                                    <tr style={{ border: "0px" }}>
                                        <th colSpan={1} >カスタマーID</th>
                                        <td colSpan={4} >
                                            {this.props.linklist[this.props.index].m_customer_id}
                                        </td>
                                        <th colSpan={1} >アプリID</th>
                                        <td colSpan={4} >
                                            {this.props.linklist[this.props.index].room_support_m_user_id}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </CardContent>
                    </Card>


                    <div style={{ fontSize: 25, textAlign: "center" }}>
                        <i className="fas fa-arrow-down"></i>
                    </div>

                    <Card className="col-lg-12" style={{ marginTop: 10 }}>
                        <CardContent style={{ float: "left" }} className="col-lg-12">
                            <div>切り替える契約</div>
                            <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                <tbody>
                                    <tr style={{ border: "0px" }}>
                                        <th colSpan={1} >建物名</th>
                                        <td colSpan={4} >
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={this.props.Tatemono}
                                                getOptionLabel={(option: any) => option.Tatemono_Name + " (" + option.Tatemono_Kana + ")"}
                                                style={{ width: 350, float: "left", marginTop: 12 }}
                                                onChange={this.onChangeTatemono}
                                                renderInput={(params) => <TextField {...params} label="建物名" onChange={this.onChangeTatemonoName} value={this.state.tatemono_name} />}
                                            />                                        </td>
                                        <th colSpan={1} >部屋名</th>
                                        <td colSpan={2} >
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={this.props.Room}
                                                getOptionLabel={(option: any) => option.Room_Name}
                                                style={{ width: 200, float: "left", marginTop: 12 }}
                                                onChange={this.onChangeRoom}
                                                renderInput={(params) => <TextField {...params} label="部屋名" />}
                                            />                                        </td>
                                    </tr>
                                    <tr style={{ border: "0px" }}>
                                        <th colSpan={1} >氏名</th>
                                        <td colSpan={4} >
                                            {this.state.Kokyaku_Name}
                                        </td>
                                        <th colSpan={1} >生年月日</th>
                                        <td colSpan={2} >
                                            {this.state.birthday ? moment(this.state.birthday).tz("Asia/Tokyo").format('YYYY-MM-DD') : ""}
                                        </td>
                                    </tr>
                                    <tr style={{ border: "0px" }}>
                                        <th colSpan={1} >電話番号</th>
                                        <td colSpan={4} >
                                            {this.state.tel}
                                        </td>
                                        <th colSpan={1} >契約日</th>
                                        <td colSpan={2} >
                                            {this.state.Keiyaku_Date ? moment(this.state.Keiyaku_Date).tz("Asia/Tokyo").format('YYYY-MM-DD') : ""}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            {this.state.m_customer_id == 0 ? "" :
                                <div>
                                    <div>アプリを登録する人物にチェックを入れる</div>
                                    <RadioGroup onChange={this.onChange_mcid}>
                                        <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                            <tbody>
                                                {this.props.family.map((d: any) => {

                                                    var name1 = "";
                                                    var name2 = "";
                                                    if (this.state.Kokyaku_Name) {
                                                        name1 = this.state.Kokyaku_Name.replace("　", "").replace(" ", "")
                                                    }
                                                    if (d.name) {
                                                        name2 = d.name.replace("　", "").replace(" ", "")
                                                    }
                                                    if (d.family_no == 0 && name1 == name2) {
                                                        return <tr style={{ border: "0px" }}>
                                                            <th colSpan={1} >契約者</th>
                                                            <td colSpan={4} >
                                                                <FormControlLabel
                                                                    value={String(d.m_customer_id ? d.m_customer_id : d.id)}
                                                                    label={d.name}
                                                                    control={<Radio color="primary" id={d.tel} />}
                                                                />

                                                            </td>
                                                            <th colSpan={1} >アプリ登録状況</th>
                                                            <td colSpan={2} >
                                                                {d.room_support_m_user_id == "" ? "未" : "登録済み"}
                                                            </td>
                                                            <th colSpan={1} >電話番号</th>
                                                            <td colSpan={2} >
                                                                {d.tel}
                                                            </td>
                                                            <th colSpan={1} >カスタマーID</th>
                                                            <td colSpan={2} >
                                                                {d.m_customer_id ? d.m_customer_id : d.id}
                                                            </td>
                                                        </tr>;
                                                    } else if (d.family_no > 0 && name1 !== name2) {
                                                        return <tr style={{ border: "0px" }}>
                                                            <th colSpan={1} >同居人</th>
                                                            <td colSpan={4} >
                                                                <FormControlLabel
                                                                    value={String(d.m_customer_id ? d.m_customer_id : d.id)}
                                                                    label={d.name}
                                                                    control={<Radio color="primary" id={d.tel} />}
                                                                />
                                                            </td>
                                                            <th colSpan={1} >アプリ登録状況</th>
                                                            <td colSpan={2} >
                                                                {d.room_support_m_user_id == "" ? "未" : "登録済み"}
                                                            </td>
                                                            <th colSpan={1} >電話番号</th>
                                                            <td colSpan={2} >
                                                                {d.tel}
                                                            </td>
                                                            <th colSpan={1} >カスタマーID</th>
                                                            <td colSpan={2} >
                                                                {d.m_customer_id ? d.m_customer_id : d.id}
                                                            </td>
                                                        </tr>;
                                                    } else if (d.type == 1 && name1 == name2) {
                                                        return <tr style={{ border: "0px" }}>
                                                            <th colSpan={1} >契約者</th>
                                                            <td colSpan={4} >
                                                                <FormControlLabel
                                                                    value={String(d.m_customer_id ? d.m_customer_id : d.id)}
                                                                    label={d.name}
                                                                    control={<Radio color="primary" id={d.tel} />}
                                                                />

                                                            </td>
                                                            <th colSpan={1} >アプリ登録状況</th>
                                                            <td colSpan={2} >
                                                                {d.room_support_m_user_id == "" ? "未" : "登録済み"}
                                                            </td>
                                                            <th colSpan={1} >電話番号</th>
                                                            <td colSpan={2} >
                                                                {d.tel}
                                                            </td>
                                                            <th colSpan={1} >カスタマーID</th>
                                                            <td colSpan={2} >
                                                                {d.m_customer_id ? d.m_customer_id : d.id}
                                                            </td>
                                                        </tr>;
                                                    } else if (d.type == 2 && name1 !== name2) {
                                                        return <tr style={{ border: "0px" }}>
                                                            <th colSpan={1} >同居人</th>
                                                            <td colSpan={4} >
                                                                <FormControlLabel
                                                                    value={String(d.m_customer_id ? d.m_customer_id : d.id)}
                                                                    label={d.name}
                                                                    control={<Radio color="primary" id={d.tel} />}
                                                                />
                                                            </td>
                                                            <th colSpan={1} >アプリ登録状況</th>
                                                            <td colSpan={2} >
                                                                {d.room_support_m_user_id == "" ? "未" : "登録済み"}
                                                            </td>
                                                            <th colSpan={1} >電話番号</th>
                                                            <td colSpan={2} >
                                                                {d.tel}
                                                            </td>
                                                            <th colSpan={1} >カスタマーID</th>
                                                            <td colSpan={2} >
                                                                {d.m_customer_id ? d.m_customer_id : d.id}
                                                            </td>
                                                        </tr>;
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                    </RadioGroup>
                                </div>}

                            {this.state.m_customer_id == 0 ? "" :
                                <Card className="col-lg-12" style={{ marginTop: 20 }}>
                                    <CardContent style={{ float: "left" }} className="col-lg-12">
                                        {this.state.button_disp ?
                                            <button onClick={this.link} style={{ fontSize: "15px" }} className="btn btn btn-info item-outer col-lg-12">チェックした人物にアカウントを切り替える</button>
                                            : ""}
                                    </CardContent>
                                </Card>
                            }
                        </CardContent>
                    </Card>
                </div>
            </div >
        );

    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        list: state.CustomerLink.list,
        Tatemono: state.Adjustment.Tatemono,
        Room: state.Adjustment.Room,
        customers: state.Adjustment.customers,
        linkings: state.CustomerLink.linking,
        family: state.CustomerLink.family,
        user_details: state.masterReducer.user_details,
        linklist: state.CustomerLink.linklist,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_tatemono(state: any) {
            dispatch(Keiyaku.get_tatemono(state))
        },
        get_room(state: any) {
            dispatch(Adjustment.get_room(state))
        },
        get_customers(state: any) {
            dispatch(Adjustment.get_customers(state))
        },
        linking(state: any) {
            dispatch(CustomerLink.linking(state))
        },
        get_list(state: any) {
            dispatch(CustomerLink.get_list(state))
        },
        get_family(state: any) {
            dispatch(CustomerLink.get_family(state))
        },
        linking_same(state: any) {
            dispatch(CustomerLink.linking_same(state))
        },
        linking_notsame(state: any) {
            dispatch(CustomerLink.linking_notsame(state))
        },
        send_news_personal(state: any) {
            dispatch(Keiyaku.send_news_personal(state))
        },
        sumikae(state: any) {
            dispatch(CustomerLink.sumikae(state))
        },
        get_linklist(state: any) {
            dispatch(CustomerLink.get_linklist(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);

