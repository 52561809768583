import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import moment from 'moment'



interface Props {
    customerdetail: any
}



interface State {

}

class CustomerList extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);


    }





    render() {

        return (
            <div>
                {this.props.customerdetail[0] ? <Card style={{ marginTop: 10 }} className="col-lg-12">
                    <CardContent style={{ float: "left", padding: 10 }} className="col-lg-12">


                        <table className="col-lg-6" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                            <tbody>
                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >勤務先名称</th>
                                    <td colSpan={3} >
                                        {this.props.customerdetail[0].kinmu_name}
                                    </td>
                                </tr>


                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >〒</th>
                                    <td colSpan={3} >
                                        {this.props.customerdetail[0].kinmu_post}
                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >住所</th>
                                    <td colSpan={3} >
                                        {this.props.customerdetail[0].kinmu_add1}
                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} ></th>
                                    <td colSpan={3} >
                                        {this.props.customerdetail[0].kinmu_add2}
                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >TEL</th>
                                    <td colSpan={1} >
                                        {this.props.customerdetail[0].kinmu_tel1}
                                    </td>
                                    <th colSpan={1} >FAX</th>
                                    <td colSpan={1} >
                                        {this.props.customerdetail[0].kinmu_fax}
                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} ></th>
                                    <td colSpan={1} >
                                        {this.props.customerdetail[0].kinmu_tel2}
                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >業種</th>
                                    <td colSpan={3} >
                                        {this.props.customerdetail[0].kinmu_gy}
                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >部署・役職</th>
                                    <td colSpan={3} >
                                        {this.props.customerdetail[0].kinmu_busyo}
                                    </td>
                                </tr>


                            </tbody>
                        </table>
                    </CardContent>
                </Card>
                    : ""}
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        customerdetail: state.Keiyaku.customerdetail,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




