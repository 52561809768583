import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import ja from "date-fns/locale/ja";
import './Css/Grid.css';
import DateFnsUtils from '@date-io/date-fns';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CSVLink } from "react-csv";
import * as KizuCheckList from '../KizuCheckList/Saga/KizuCheckList';


const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');



var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    }
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


const columns =
  [
    { key: 'file_name', name: 'ファイル名', width: 200, resizable: true },
    { key: 'user_name_all', name: '登録者', width: 150, resizable: true },
    { key: 'created_at', name: '登録日', width: 100, resizable: true },
    { key: 'delete_button', name: '', width: 65, resizable: true },
  ]

const Base = ({ column, props, rowIndex, download_file }) =>
  <div style={{ cursor: "pointer" }} onClick={() => download_file(props[rowIndex])}>
    <Cell style={{ whiteSpace: "nowrap" }}> {props[rowIndex][column.key]} </Cell>
  </div>

const Date = ({ column, props, rowIndex, download_file }) =>
  <div style={{ cursor: "pointer" }} onClick={() => download_file(props[rowIndex])}>
    <Cell style={{ whiteSpace: "nowrap" }}>
      {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).tz("utc").format('YYYY-MM-DD') : ""}
    </Cell>
  </div>


const Delete = ({ column, props, rowIndex, delete_file }) =>
  <div>
    <Button size="small" onClick={() => delete_file(props[rowIndex])} color="secondary" variant="contained" >
      <i className="fas fa-times-circle"></i>
    </Button>
  </div>

class KizuList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns,
      reflash: false,
      colSortDirs: {},
      reqtoggle: false,
    };
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.download_file = this.download_file.bind(this);
    this.delete_file = this.delete_file.bind(this);
  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }

  download_file(ind) {
    this.props.download_kizu_check_file({ id: ind.id, file_name: ind.file_name });
  }
  delete_file(ind) {
    var result = window.confirm('削除します。よろしいですか？');
    if (result) {
    this.props.kizu_check_file_delete({ id: ind.id, Keiyaku_No: ind.Keiyaku_No });
    }
  }

  _onSortChange(columnKey, sortDir) {

    let seisan_follow_search_list = this.props.kizu_check_file_list;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }


  render() {
    return (
      <div>
        <Table
          rowHeight={30}
          rowsCount={this.props.kizu_check_file_list.length}
          // rowsCount={10}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          width={515}
          height={33 + this.props.kizu_check_file_list.length * 30}
          headerHeight={30}
        >

          {columns.map(function (value, i) {
            return <Column
              columnKey={value.key}
              header={<SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={this.state.colSortDirs[value.key]}
                style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}
              cell={({ rowIndex, ...props }) => (
                value.key === "delete_button" ? <Delete column={value} props={this.props.kizu_check_file_list} rowIndex={rowIndex} delete_file={this.delete_file}></Delete>
                  : value.key === "created_at" ? <Date column={value} props={this.props.kizu_check_file_list} rowIndex={rowIndex} download_file={this.download_file}></Date>
                    : <Base column={value} props={this.props.kizu_check_file_list} rowIndex={rowIndex} download_file={this.download_file}></Base>
              )}
              width={this.state.columns.filter(a => a.key === value.key)[0].width}
              isResizable={true}
              fixed={value.frozen}

            />
          }, this)}
        </Table >


      </div >
    );
  }
};


//export  default CsustomerList;
const mapStateToProps = (state) => {
  return {
    kizu_check_file_list: state.KizuCheckList.kizu_check_file_list,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    download_kizu_check_file(state) {
      dispatch(KizuCheckList.download_kizu_check_file(state))
    },
    kizu_check_file_delete(state) {
      dispatch(KizuCheckList.kizu_check_file_delete(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KizuList);
