import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import HeaderTable from "./HeaderTable";
import KizuAriContent from "./KizuAriContent";
import KizuNashiContent from "./KizuNashiContent";
import * as CustomerLink from './Saga/CustomerLink';
import Button from '@material-ui/core/Button';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import * as KizuCheckList from '../KizuCheckList/Saga/KizuCheckList';
import ReactToPrint from "react-to-print";
import Dropzone from 'react-dropzone';
import Grid from "./Grid";

var moment = require('moment-timezone');


interface Props {
    list: any,
    match: any,
    get_kizu_check_detail: any,
    kizu_check_detail: any,
    kizu_check_file_upload:any,
    user_details:any,
    get_kizu_check_file_list:any,
    kizu_check_file_list:any
}

interface State {
    conv_list: boolean,
    kizu_flg: boolean,
    filename: any,
    files: any,

}

class KizuDetail extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        if (this.props.match) {
            props.get_kizu_check_detail({ Keiyaku_No: this.props.match.params.keiyaku_no })
            props.get_kizu_check_file_list(this.props.match.params.keiyaku_no)
        }

        this.state = {
            conv_list: false,
            kizu_flg: true,
            filename: "",
            files: [],

        }
        this.print = this.print.bind(this);
        this.onDrop = this.onDrop.bind(this);

    }
    print() {
        window.print();
    }

    
    onDrop = (files: any) => {
        files.forEach((e: any) => {            
            var updata = {
                files: e,
                Keiyaku_No:this.props.match.params.keiyaku_no,
                created_user: this.props.user_details.id,
            }
            this.props.kizu_check_file_upload(updata);
        });
    };
    render() {
        return (
            <div>
                <div className="item-outer col-lg-12">
                    <Card>
                        <CardContent>
                            <div>
                                <div style={{ textAlign: 'right' }}>
                                    <Button
                                        onClick={this.print}
                                        // size="small"
                                        style={{ color: "black", marginBottom: 20, marginRight: 10, width: 80 }}
                                        variant="contained">
                                        <PrintOutlinedIcon />印刷
                                    </Button>
                                </div>
                                {this.props.kizu_check_detail[0] ?
                                    <HeaderTable /> : ""}
                            </div>
                        </CardContent >

                        <CardContent>
                            添付ファイル
                            <Grid />
                                <Dropzone
                                    onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: 'dropzone' })} style={{ background: "#f5f5f5",  border: "solid",width:500,height:30 }}>
                                            <input {...getInputProps()} />
                                            <div>
                                                <p>ファイルをアップロード</p>
                                                <ul >
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                        </CardContent >

                        <CardContent>
                            <div>
                                {this.props.kizu_check_detail[0] ?
                                    <KizuAriContent /> : ""}
                            </div>
                        </CardContent >
                    </Card >
                </div>
            </div >
        );
    }
};


const mapStateToProps = (state: any) => {
    return {
        kizu_check_detail: state.KizuCheckList.kizu_check_detail,
        user_details: state.masterReducer.user_details,
        kizu_check_file_list: state.KizuCheckList.kizu_check_file_list,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_kizu_check_detail(state: any) {
            dispatch(KizuCheckList.get_kizu_check_detail(state))
        },
        kizu_check_file_upload(state: any) {
            dispatch(KizuCheckList.kizu_check_file_upload(state))
        },
        get_kizu_check_file_list(state: any) {
            dispatch(KizuCheckList.get_kizu_check_file_list(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(KizuDetail);




