import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as BicycleListApi from '../Api/BicycleList';


///types
const moment = require('moment')

export interface State {
    search_status: boolean,
    bicycle_registration_list: any,
    bicycle_detail: any,
    // kizu_check_file_list: any,
    bicycle_file: any,

}
const initialState: State = {
    search_status: false,
    bicycle_registration_list: [],
    bicycle_detail: [],
    bicycle_file: [],

}



///action 
const actionCreator = actionCreatorFactory();

export const search_status_true = actionCreator('SEARCH_STATUS_TRUE');
export const search_status_false = actionCreator('SEARCH_STATUS_FALSE');

export const get_bicycle_registration_list = actionCreator('GET_BICYCLE_REGISTRATION_LIST');
export const get_bicycle_registration_list_success = actionCreator('GET_BICYCLE_REGISTRATION_LIST_SUCCESS');

export const get_bicycle_detail = actionCreator('GET_BICYCLE_DETAIL');
export const get_bicycle_detail_success = actionCreator('GET_BICYCLE_DETAIL_SUCCESS');

export const get_bicycle_file_list = actionCreator('GET_BICYCLE_FILE_LIST');
export const get_bicycle_file_list_success = actionCreator('GET_BICYCLE_FILE_LIST_SUCCESS');




export const BicycleListReducer = reducerWithInitialState(initialState)
    .case(search_status_true, (state, payload) => ({ ...state, search_status: true }))
    .case(search_status_false, (state, payload) => ({ ...state, search_status: false }))
    .case(get_bicycle_registration_list_success, (state, payload) => ({ ...state, bicycle_registration_list: payload }))
    .case(get_bicycle_detail_success, (state, payload) => ({ ...state, bicycle_detail: payload }))



// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const BicycleListSaga = [
    takeEvery('GET_BICYCLE_REGISTRATION_LIST', handle_get_bicycle_registration_list),
    takeEvery('GET_BICYCLE_DETAIL', handle_get_bicycle_detail),
];





function* handle_get_bicycle_registration_list(action: any) {
    // alert("***************handle_get_bicycle_registration_list");
    // console.log(action)
    // console.log("##########", action.payload)
    yield put(search_status_true());

    const { payload, error } = yield call(BicycleListApi.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // console.log("##########payload LIST:", payload);
        yield put(search_status_false());
        yield put(get_bicycle_registration_list_success(payload));
    } else {
        // alert(error)
    }
}


function* handle_get_bicycle_detail(action: any) {

    yield put(search_status_true());

    const { payload, error } = yield call(BicycleListApi.get_details, action.payload);
    // alert(JSON.stringify(payload))
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // console.log("##########payload DETAIL:", payload);
        // alert(JSON.stringify(payload[0].room_support_m_user_id))
        // alert(payload[0].Keiyaku_No)
        yield put(search_status_false());
        yield put(get_bicycle_detail_success(payload));
    } else {
        // alert(error)
    }
}


