
const authentication = require('../../../react-azure-adb2c2').default;
export const get_tatemono = (values: any) => {
    //a 
    // let url = `http://localhost:5000/v1/tm/tatemono/list2`;
    let url = process.env.REACT_APP_API_PM_URL + `/v1/tm/tatemono/list2`;
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
};

