import React, { Component } from 'react';
import { connect } from 'react-redux';
import RequestChart from "./RequestChart";
import OutaiSubChart from "./OutaiSubChart";
import EntryChart from "./EntryChart";
import KizuCheckEntryChart from "./KizuCheckEntryChart";
import Touroku from "./Touroku";
import Sinkikizon from "./Sinkikizon";

// import * as ContractSearch from '../ContractSearch/Saga/ContractSearch';
// import ContractSearchComponent from '../ContractSearch/Index';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
// import Search from "./Search.tsx__";
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
import * as Board from './Saga/Board';
import Draggable from 'react-draggable'; // The default
import RoomDocComponent from '../Rooms/RoomDocComponent';
import RentMoney from '../RentMoney/Index';
import Owner from '../Owner/Index';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Row, Col } from 'reactstrap';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


var moment = require('moment-timezone');

interface Props {
    get_chat_data_list: any,
    get_outai_sub_list: any,
    get_entered_list_list: any,
    get_signup_count_list: any,
    get_splus_count_list:any
}



interface State {
}



const date1 = new Date();
const year = date1.getFullYear();
const month = date1.getMonth() + 1;
var sdate = moment(year + "-" + month + "-01").format('YYYY-MM-DD');
var ssdate = moment(year + "-" + month + "-01").add(-1, 'years').format('YYYY-MM-DD');

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);

        props.get_chat_data_list(
            {
                date1: moment(sdate).utc().format('YYYY-MM-DD'),
                date2: moment().utc().format('YYYY-MM-DD'),
            }
        );
        props.get_outai_sub_list(
            {
                date1: moment(sdate).utc().format('YYYY-MM-DD'),
                date2: moment().utc().format('YYYY-MM-DD'),
            }
        );
        props.get_entered_list_list(
            {
                entered_at1: ssdate,
                entered_at2: new Date(),
                type: "all"
            }
        );

        // props.get_signup_count_list(
        //     { year: String(new Date().getFullYear()) }
        // )
        // props.get_splus_count_list(
        //     { year: String(new Date().getFullYear()) }
        // )


        this.state = {
        }
    }





    componentWillMount() {
    }

    render() {
        return (
            <div>
                <div className="row" >
                    <div className="col-lg-4" style={{ padding: 5 }}>
                        <Card  >
                            <CardContent >
                                <Touroku />
                            </CardContent >
                        </Card >
                    </div>
                    <div className="col-lg-4" style={{ padding: 5 }}>
                        <Card  >
                            <CardContent >
                                <Sinkikizon />
                            </CardContent >
                        </Card >
                    </div>
                </div>

                <div className="row" >
                    <div className="col-lg-4" style={{ padding: 5 }}>
                        <Card  >
                            <CardContent >
                                <RequestChart />
                            </CardContent >
                        </Card >
                    </div>
                    <div className="col-lg-4" style={{ padding: 5 }}>
                        <Card  >
                            <CardContent >
                                <EntryChart />
                            </CardContent >
                        </Card >
                    </div>
                    {/* <div className="col-lg-4" style={{ padding: 5 }}>
                        <Card  >
                            <CardContent >
                                <KizuCheckEntryChart />
                            </CardContent >
                        </Card >
                    </div> */}
                </div>

                <div className="row" >
                <div className="col-lg-8" style={{ padding: 5 }}>
                    <Card  >
                        <CardContent >
                            <OutaiSubChart />
                        </CardContent >
                    </Card >
                </div>
                </div>
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_chat_data_list(state: any) {
            dispatch(Board.get_chat_data_list(state))
        },
        get_outai_sub_list(state: any) {
            dispatch(Board.get_outai_sub_list(state))
        },
        get_entered_list_list(state: any) {
            dispatch(Board.get_entered_list_list(state))
        },
        get_signup_count_list(state: any) {
            dispatch(Board.get_signup_count_list(state))
        },
        get_splus_count_list(state: any) {
            dispatch(Board.get_splus_count_list(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




