import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Kihon from "./Kihon";
import Kinmu from "./Kinmu";


interface Props {
    classes: any,

}
interface State {
    responseTabs: string,
}


const styles = ((theme: any) => ({

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tab: {
        minWidth: 50, // a number of your choice
    }
}))

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            responseTabs: "k1"
        }
        this.handleTabChange = this.handleTabChange.bind(this);

    }

    handleTabChange = (event: any, newValue: string) => {
        this.setState({ responseTabs: newValue });
    };

    render() {

        return (
            <div style={{ padding: 10, background: "#b0c4de" }} >
                <Paper square={false} style={{ marginTop: 10 }}>
                    <Tabs
                        value={this.state.responseTabs}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        onChange={this.handleTabChange}
                        aria-label="disabled tabs example"
                        style={{ float: "left" }}
                    >
                        <Tab className={this.props.classes.tab} label="基本情報" value="k1" />
                        <Tab className={this.props.classes.tab} label="勤務先" value="k2" />
                        {/* <Tab className={this.props.classes.tab} label="連絡先" value="k3" />
                        <Tab className={this.props.classes.tab} label="書類送付先" value="k4" />
                        <Tab className={this.props.classes.tab} label="保証人１" value="k5" />
                        <Tab className={this.props.classes.tab} label="保証人２" value="k6" />
                        <Tab className={this.props.classes.tab} label="口座情報" value="k7" />
                        <Tab className={this.props.classes.tab} label="入出金照合" value="k8" />
                        <Tab className={this.props.classes.tab} label="過剰入金" value="k9" />
                        <Tab className={this.props.classes.tab} label="備考" value="k10" /> */}
                    </Tabs>


                    {this.state.responseTabs === "k1" ? <Card className="col-lg-12" square={true} style={{ background: "#b0c4de", padding: 5 }}>
                        <Kihon />
                    </Card> : ""}

                    {this.state.responseTabs === "k2" ? <Card className="col-lg-12" square={true} style={{ background: "#b0c4de", padding: 5 }}>
                        <Kinmu />
                    </Card> : ""}

                </Paper>
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {

    }
}
function mapDispatchToProps(dispatch: any) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




