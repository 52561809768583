import React, { Component } from 'react';

import { connect } from 'react-redux';

//import { actionCreators } from '../../Store/MailData';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';



import { Field, reduxForm } from 'redux-form';
// import '../../Css/Table.css';
import  EventListener from 'react-event-listener';






class MenuComponent extends Component {
   
    constructor(props) {
        super(props);
      
        this.state = {
            height: window.innerHeight,
        };


        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        //this.handleClick = this.handleClick.bind(this);
        // this.onChange = this.onChange.bind(this);
        this.props.initialize(this.props.roomdetail);//フォームに初期値を反映させる
    }
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);

    }

    handleResize = () => {
        this.setState({height: window.innerHeight});
      };
    
  componentDidMount() {
    // This method is called when the component is first added to the document
      this.ensureDataFetched();
      //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });
  }

  componentDidUpdate() {
    // This method is called when the route parameters change
    this.ensureDataFetched();
  }

  ensureDataFetched() {
      //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
      //console.log(startDateIndex)

      //this.props.requestWeatherForecasts(0);

    }


    render() {

       
        //moment.locale('ja')
        return (
            <div style={{backgroundColor:"White", width:180, textAlign:"left"}}>
                <EventListener target="window" onResize={this.handleResize} />
                <div style={{backgroundColor:"white", height:this.state.height-40}}>


                <ul className="nav navbar-nav">
                <li className="dropdown" >
                    <div className="left-side-list-menu">
                        
                        <Dropdown isOpen={this.state.dropdownOpenBuilding} toggle={() => { this.setState({ dropdownOpenBuilding: !this.state.dropdownOpenBuilding }); }}>
                            <DropdownToggle caret
                                tag="span"
                                className="icon-building left-side-list-icon"
                                data-toggle="dropdown"
                                aria-expanded={this.state.dropdownOpenBuilding}   
                            >
                            <span className="left-side-list-group">物件情報</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>HeaderPatrol</DropdownItem>
                                <DropdownItem>Some Action</DropdownItem>
                                <DropdownItem disabled>Action (disabled)</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>Foo Action</DropdownItem>
                                <DropdownItem>Bar Action</DropdownItem>
                                <DropdownItem>Quo Action</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                    </div>
                    
                </li>

                <li className="dropdown" >
                    <div className="left-side-list-menu">

                        <Dropdown isOpen={this.state.dropdownOpenPatrol} toggle={() => { this.setState({ dropdownOpenPatrol: !this.state.dropdownOpenPatrol }); }}>
                            <DropdownToggle caret
                                tag="span"
                                className="icon-patrol left-side-list-icon"
                                data-toggle="dropdown"
                                aria-expanded={this.state.dropdownOpenPatrol}
                            >
                            <span className="left-side-list-group">物件管理</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>HeaderPatrol</DropdownItem>
                                <DropdownItem>Some Action</DropdownItem>
                                <DropdownItem disabled>Action (disabled)</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>Foo Action</DropdownItem>
                                <DropdownItem>Bar Action</DropdownItem>
                                <DropdownItem>Quo Action</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                    </div>

                </li>

                <li className="dropdown" >
                    <div className="left-side-list-menu">

                        <Dropdown isOpen={this.state.dropdownOpenRent} toggle={() => { this.setState({ dropdownOpenRent: !this.state.dropdownOpenRent }); }}>
                            <DropdownToggle caret
                                tag="span"
                                className="icon-rent left-side-list-icon"
                                data-toggle="dropdown"
                                aria-expanded={this.state.dropdownOpenRent}
                            >
                            <span className="left-side-list-group">賃貸</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>HeaderRent</DropdownItem>
                                <DropdownItem>Some Action</DropdownItem>
                                <DropdownItem disabled>Action (disabled)</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>Foo Action</DropdownItem>
                                <DropdownItem>Bar Action</DropdownItem>
                                <DropdownItem>Quo Action</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                    </div>

                </li>

                <li className="dropdown" >
                    <div className="left-side-list-menu">

                        <Dropdown isOpen={this.state.dropdownOpenGroupware} toggle={() => { this.setState({ dropdownOpenGroupware: !this.state.dropdownOpenGroupware }); }}>
                            <DropdownToggle caret
                                tag="span"
                                className="icon-groupware left-side-list-icon"
                                data-toggle="dropdown"
                                aria-expanded={this.state.dropdownOpenGroupware}
                            >
                            <span className="left-side-list-group">グループウェア</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>HeaderGroupware</DropdownItem>
                                <DropdownItem>Some Action</DropdownItem>
                                <DropdownItem disabled>Action (disabled)</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>Foo Action</DropdownItem>
                                <DropdownItem>Bar Action</DropdownItem>
                                <DropdownItem>Quo Action</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                    </div>

                </li>

                <li className="dropdown" >
                    <div className="left-side-list-menu">

                        <Dropdown isOpen={this.state.dropdownOpenSellbuy} toggle={() => { this.setState({ dropdownOpenSellbuy: !this.state.dropdownOpenSellbuy }); }}>
                            <DropdownToggle caret
                                tag="span"
                                className="icon-sellbuy left-side-list-icon"
                                data-toggle="dropdown"
                                aria-expanded={this.state.dropdownOpenSellbuy}
                            >
                            <span className="left-side-list-group">売買</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>HeaderSellbuy</DropdownItem>
                                <DropdownItem>Some Action</DropdownItem>
                                <DropdownItem disabled>Action (disabled)</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>Foo Action</DropdownItem>
                                <DropdownItem>Bar Action</DropdownItem>
                                <DropdownItem>Quo Action</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                    </div>

                </li>
                <li className="dropdown" >
                    <div className="left-side-list-menu">

                        <Dropdown isOpen={this.state.dropdownOpenMove} toggle={() => { this.setState({ dropdownOpenMove: !this.state.dropdownOpenMove }); }}>
                            <DropdownToggle caret
                                tag="span"
                                className="icon-move left-side-list-icon"
                                data-toggle="dropdown"
                                aria-expanded={this.state.dropdownOpenMove}
                            >
                            <span className="left-side-list-group">入居準備</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>HeaderMove</DropdownItem>
                                <DropdownItem>Some Action</DropdownItem>
                                <DropdownItem disabled>Action (disabled)</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>Foo Action</DropdownItem>
                                <DropdownItem>Bar Action</DropdownItem>
                                <DropdownItem>Quo Action</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                    </div>

                </li>
                <li className="dropdown" >
                    <div className="left-side-list-menu">

                        <Dropdown isOpen={this.state.dropdownOpenReform} toggle={() => { this.setState({ dropdownOpenReform: !this.state.dropdownOpenReform }); }}>
                            <DropdownToggle caret
                                tag="span"
                                className="icon-reform left-side-list-icon"
                                data-toggle="dropdown"
                                aria-expanded={this.state.dropdownOpenReform}
                            >
                            <span className="left-side-list-group">工事管理</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>HeaderReform</DropdownItem>
                                <DropdownItem>Some Action</DropdownItem>
                                <DropdownItem disabled>Action (disabled)</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>Foo Action</DropdownItem>
                                <DropdownItem>Bar Action</DropdownItem>
                                <DropdownItem>Quo Action</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                    </div>

                </li>
                <li className="dropdown" >
                    <div className="left-side-list-menu">

                        <Dropdown isOpen={this.state.dropdownOpenOwner} toggle={() => { this.setState({ dropdownOpenOwner: !this.state.dropdownOpenOwner }); }}>
                            <DropdownToggle caret
                                tag="span"
                                className="icon-owner left-side-list-icon"
                                data-toggle="dropdown"
                                aria-expanded={this.state.dropdownOpenOwner}
                            >
                            <span className="left-side-list-group">オーナー</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>HeaderOwner</DropdownItem>
                                <DropdownItem>Some Action</DropdownItem>
                                <DropdownItem disabled>Action (disabled)</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>Foo Action</DropdownItem>
                                <DropdownItem>Bar Action</DropdownItem>
                                <DropdownItem>Quo Action</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                    </div>

                </li>

                <li className="dropdown" >
                    <div className="left-side-list-menu">

                        <Dropdown isOpen={this.state.dropdownOpenMoney} toggle={() => { this.setState({ dropdownOpenMoney: !this.state.dropdownOpenMoney }); }}>
                            <DropdownToggle caret
                                tag="span"
                                className="icon-money left-side-list-icon"
                                data-toggle="dropdown"
                                aria-expanded={this.state.dropdownOpenMoney}
                            >
                            <span className="left-side-list-group">入金・送金</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>HeaderOwner</DropdownItem>
                                <DropdownItem>Some Action</DropdownItem>
                                <DropdownItem disabled>Action (disabled)</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>Foo Action</DropdownItem>
                                <DropdownItem>Bar Action</DropdownItem>
                                <DropdownItem>Quo Action</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                    </div>

                </li>
                <li className="dropdown" >
                    <div className="left-side-list-menu">

                        <Dropdown isOpen={this.state.dropdownOpenLocal} toggle={() => { this.setState({ dropdownOpenLocal: !this.state.dropdownOpenLocal }); }}>
                            <DropdownToggle caret
                                tag="span"
                                className="icon-local left-side-list-icon"
                                data-toggle="dropdown"
                                aria-expanded={this.state.dropdownOpenLocal}
                            >
                            <span className="left-side-list-group">WEB</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>HeaderOwner</DropdownItem>
                                <DropdownItem>Some Action</DropdownItem>
                                <DropdownItem disabled>Action (disabled)</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>Foo Action</DropdownItem>
                                <DropdownItem>Bar Action</DropdownItem>
                                <DropdownItem>Quo Action</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                    </div>

                </li>

                <li className="dropdown" >
                    <div className="left-side-list-menu">

                        <Dropdown isOpen={this.state.dropdownOpenContract} toggle={() => { this.setState({ dropdownOpenContract: !this.state.dropdownOpenContract }); }}>
                            <DropdownToggle caret
                                tag="span"
                                className="icon-contract left-side-list-icon"
                                data-toggle="dropdown"
                                aria-expanded={this.state.dropdownOpenContract}
                            >
                            <span className="left-side-list-group">契約・解約</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>HeaderOwner</DropdownItem>
                                <DropdownItem>Some Action</DropdownItem>
                                <DropdownItem disabled>Action (disabled)</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>Foo Action</DropdownItem>
                                <DropdownItem>Bar Action</DropdownItem>
                                <DropdownItem>Quo Action</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                    </div>

                </li>

                <li className="dropdown" >
                    <div className="left-side-list-menu">

                        <Dropdown isOpen={this.state.dropdownOpenGeneral} toggle={() => { this.setState({ dropdownOpenGeneral: !this.state.dropdownOpenGeneral }); }}>
                            <DropdownToggle caret
                                tag="span"
                                className="icon-general left-side-list-icon"
                                data-toggle="dropdown"
                                aria-expanded={this.state.dropdownOpenGeneral}
                            >
                            <span className="left-side-list-group">共通</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>HeaderOwner</DropdownItem>
                                <DropdownItem>Some Action</DropdownItem>
                                <DropdownItem disabled>Action (disabled)</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>Foo Action</DropdownItem>
                                <DropdownItem>Bar Action</DropdownItem>
                                <DropdownItem>Quo Action</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                    </div>

                </li>

                <li className="dropdown" >
                    <div className="left-side-list-menu">

                        <Dropdown isOpen={this.state.dropdownOpenMonthlyQuick} toggle={() => { this.setState({ dropdownOpenMonthlyQuick: !this.state.dropdownOpenMonthlyQuick }); }}>
                            <DropdownToggle caret
                                tag="span"
                                className="icon-monthly_quick left-side-list-icon"
                                data-toggle="dropdown"
                                aria-expanded={this.state.dropdownOpenMonthlyQuick}
                            >
                            <span className="left-side-list-group">マンスリー</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>HeaderOwner</DropdownItem>
                                <DropdownItem>Some Action</DropdownItem>
                                <DropdownItem disabled>Action (disabled)</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>Foo Action</DropdownItem>
                                <DropdownItem>Bar Action</DropdownItem>
                                <DropdownItem>Quo Action</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>

                    </div>

                </li>

            </ul>
















                </div>
                
            </div>
    );
  }
} 




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {

        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

MenuComponent = reduxForm({
    form: 'roomeditform', // a unique identifier for this form
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(MenuComponent)

export default connect(mapStateToProps, mapDispatchToProps)(MenuComponent);