import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Kys from '../Kys/Index';
import * as KysSaga from '../Kys/Saga/Kys';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as Line from '../Line/Saga/Line';
import * as Chat from '../Chat/Saga/Chat';
import * as Sms from '../Sms/Saga/Sms';
import * as Mail from '../Mail/Saga/Mail';
import FileGrid from "./FileGrid";
import EditOutai from "./EditOutai";
import Button from '@material-ui/core/Button';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme: any) => ({

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tab: {

        minWidth: 50, // a number of your choice
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton style={{ position: 'absolute' }} aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}

        </MuiDialogTitle>
    );
});

interface Props {
    get_ky: any,
    ky: any,
    change_mail_new: any,
    get_response_lines: any,
    get_chats: any,
    get_smses: any,
    classes: any,
    kys_biko: any,
    get_kys: any,
    request_detail: any,
    index: any,
    reqtoggle: any,
    request_file: any
}



interface State {
    reflesh: boolean,
    responseTabs: string,
    customerTabs: string,
    modalkys: boolean,
    edittoggle: boolean

    // state types
}



class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        // this.props.change_search_name("")
        this.state = {
            reflesh: false,
            responseTabs: "k1",
            modalkys: false,
            customerTabs: "kys1",
            edittoggle: false

        }
        this.edittoggle = this.edittoggle.bind(this);


    }






    edittoggle() {
        if (this.state.edittoggle == true) {
            this.props.reqtoggle();
        }
        this.setState({ edittoggle: !this.state.edittoggle })
    }

    render() {


        return (
            <div style={{ padding: 10, background: "#b0c4de" }} >
                <Card className="col-lg-12" >
                    <CardContent style={{ float: "left" }} className="col-lg-12">
                        <Button size="small" style={{ float: "right" }} onClick={this.edittoggle} color="primary" variant="contained">編集</Button>

                        <table className="col-lg-8" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                            <tbody>
                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >応対者</th>
                                    <td colSpan={2} >
                                        {this.props.request_detail.detail[this.props.index].syosai_taiou_user}
                                    </td>

                                    {this.props.request_detail.detail[this.props.index].schedule_completed == 1 ?
                                        <th colSpan={1} style={{ backgroundColor: '#f0e68c' }} >応対予定</th> : <th colSpan={1} >応対日</th>}
                                    <td colSpan={2} >
                                        {this.props.request_detail.detail[this.props.index].OutaiDate ? moment(this.props.request_detail.detail[this.props.index].OutaiDate).tz("Asia/Tokyo").format('YYYY-MM-DD') : ""}
                                        <span> </span>
                                        {this.props.request_detail.detail[this.props.index].OutaiDate_H}
                                        <span> : </span>
                                        {this.props.request_detail.detail[this.props.index].OutaiDate_M}
                                    </td>
                                </tr>
                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >応対方法</th>
                                    <td colSpan={2} >
                                        {this.props.request_detail.detail[this.props.index].OutaiHoho_Name}
                                    </td>
                                    <th colSpan={1} >応対方法 備考</th>
                                    <td colSpan={2} >
                                        {this.props.request_detail.detail[this.props.index].OutaiHoho_Biko}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </CardContent>
                </Card>
                <Card style={{ marginTop: 10 }} className="col-lg-12" >
                    <CardContent style={{ float: "left" }} className="col-lg-12">
                        <TextField
                            id="outlined-read-only-input"
                            label="応対内容"
                            fullWidth
                            multiline
                            defaultValue={this.props.request_detail.detail[this.props.index].ShosaiNaiyo}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            style={{ marginTop: "10px" }}
                        />
                        <TextField
                            id="outlined-read-only-input"
                            label="備考"
                            fullWidth
                            multiline
                            defaultValue={this.props.request_detail.detail[this.props.index].Biko}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            style={{ marginTop: "10px" }}
                        />
                    </CardContent>
                </Card>


                {this.props.request_file.length !== 0 ?
                    <Card style={{ marginTop: 10 }} className="col-lg-12" >
                        <CardContent style={{ float: "left" }} className="col-lg-12">
                            <FileGrid />
                        </CardContent>
                    </Card>
                    : ""}

                < Modal isOpen={this.state.edittoggle} toggle={this.edittoggle} size="kokyaku" fade={false} backdrop={false}>
                    {/* <ModalHeader toggle={this.edittoggle}>応対編集</ModalHeader> */}
                    <DialogTitle id="customized-dialog-title" onClose={this.edittoggle} >応対編集</DialogTitle>
                    <EditOutai
                        index={this.props.index}
                        OutaiShosai_No={this.props.request_detail.detail.length}
                        Outai_No={this.props.request_detail.detail[0].Outai_No}
                        reqtoggle={this.edittoggle} />
                    <ModalFooter>
                    </ModalFooter>
                </Modal >

            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        ky: state.KyDetail.ky,
        kys_biko: state.kys.kys_biko,
        request_detail: state.Adjustment.request_detail,
        request_file: state.RequestDetail.request_file,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        change_mail_new(state: any) {
            dispatch(Mail.change_mail_new(state))
        },
        get_response_lines(state: any) {
            dispatch(Line.get_response_lines(state))
        },
        get_chats(state: any) {
            dispatch(Chat.get_chats(state))
        },
        get_smses(state: any) {
            dispatch(Sms.get_smses(state))
        },
        get_kys(state: number) {
            dispatch(KysSaga.get_kys(state))
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));




