import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
//const authentication = require('react-azure-adb2c').default;
//import { Dispatch } from 'redux';


import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
// import * as RentContractMoniesApi from '../Api/RentContractMoniesApi';
// import * as RentContractAccountingApi from '../Api/RentContractAccountingApi.ts_';
import * as MessageListApi from '../Api/MessageList';


// import * as RentAgentsApi from './Api/RentAgentsApi';

///types
const moment = require('moment')

//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}
export interface State {
    customers: any,
    newlist_issei: any,
    issei_details: any,
    message_count: any,
    search_status: boolean,
    news_count:any
}
const initialState: State = {
    customers: [],
    newlist_issei: [],
    issei_details: [],
    message_count: 0,
    search_status: false,
    news_count:[]
}



///action 
const actionCreator = actionCreatorFactory();

export const search_status_true = actionCreator('SEARCH_STATUS_TRUE');
export const search_status_true_success = actionCreator('SEARCH_STATUS_TRUE_SUCCESS');
export const search_status_false_success = actionCreator('SEARCH_STATUS_FALSE_SUCCESS');


export const search_customers = actionCreator('SEARCH_CUSTOMERS');
export const search_customers_success = actionCreator('SEARCH_CUSTOMERS_SUCCESS');

export const update_customers = actionCreator('UPDATE_CUSTOMERS');

export const newmessage_customers = actionCreator('NEWMESSAGE_CUSTOMERS');

export const newlist_issei = actionCreator('NEWLIST_ISSEI');
export const newlist_issei_success = actionCreator('NEWLIST_ISSEI_SUCCESS');

export const issei_detail = actionCreator('ISSEI_DETAIL');
export const issei_detail_success = actionCreator('ISSEI_DETAIL_SUCCESS');

export const message_count = actionCreator('MESSAGE_COUNT');
export const message_count_success = actionCreator('MESSAGE_COUNT_SUCCESS');

export const news_count = actionCreator('NEWS_COUNT');
export const news_count_success = actionCreator('NEWS_COUNT_SUCCESS');


export const MessageListReducer = reducerWithInitialState(initialState)

    .case(search_status_true_success, (state, payload) => ({ ...state, search_status: true }))
    .case(search_status_false_success, (state, payload) => ({ ...state, search_status: false }))
    .case(search_customers_success, (state, payload) => ({ ...state, customers: payload }))
    .case(newlist_issei_success, (state, payload) => ({ ...state, newlist_issei: payload }))
    .case(issei_detail_success, (state, payload) => ({ ...state, issei_details: payload }))
    .case(message_count_success, (state, payload) => ({ ...state, message_count: payload }))
    .case(news_count_success, (state, payload) => ({ ...state, news_count: payload }))


// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const MessageListSaga = [
    takeEvery('SEARCH_CUSTOMERS', handle_search_customers),
    takeEvery('NEWMESSAGE_CUSTOMERS', handle_newmessage_customers),
    takeEvery('NEWLIST_ISSEI', handle_newlist_issei),
    takeEvery('ISSEI_DETAIL', handle_issei_detail),
    takeEvery('UPDATE_CUSTOMERS', handle_update_customers),
    takeEvery('MESSAGE_COUNT', handle_message_count),
    takeEvery('SEARCH_STATUS_TRUE', handle_search_status_true),
    takeEvery('NEWS_COUNT', handle_news_count),

];
// export default masterSaga;


function* handle_news_count(action: any) {

    const { payload, error } = yield call(MessageListApi.news_count, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(news_count_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_update_customers(action: any) {

    const { payload, error } = yield call(MessageListApi.update_customers, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(newmessage_customers(payload));
    } else {
        alert(error)
    }
}

function* handle_issei_detail(action: any) {

    const { payload, error } = yield call(MessageListApi.inbulks_byid, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(issei_detail_success(payload));
    } else {
        alert(error)
    }
}
function* handle_newlist_issei(action: any) {

    const { payload, error } = yield call(MessageListApi.newlist_issei, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(newlist_issei_success(payload));
    } else {
        alert(error)
    }
}

function* handle_search_customers(action: any) {

    const { payload, error } = yield call(MessageListApi.search_customers, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(search_customers_success(payload));
        yield put(search_status_false_success());

    } else {
        alert(error)
    }
}
function* handle_newmessage_customers(action: any) {

    const { payload, error } = yield call(MessageListApi.newmessage_customers, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(search_customers_success(payload));
        yield put(search_status_false_success());

    } else {
        alert(error)
    }
}

function* handle_message_count(action: any) {

    const { payload, error } = yield call(MessageListApi.newmessagecount, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(message_count_success(payload));
    } else {
        // alert(error)
    }
}

function* handle_search_status_true(action: any) {
    yield put(search_status_true_success());
}