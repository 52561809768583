import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
// import * as firebase from "firebase";
import '@firebase/firestore' // 👈 If you're using firestore

// import ReduxSagaFirebase from 'redux-saga-firebase'
import { eventChannel } from 'redux-saga'
import { call, put, takeEvery, take, select, delay, all } from 'redux-saga/effects';
import * as LinesApi from '../Api/LinesApi';
import * as TimeLineApi from '../Api/TimeLineApi';
import * as TimeLineResponsesApi from '../Api/TimeLineResponsesApi';
import * as room_support_m_devices_api from '../Api/room_support_m_devices_api';
import * as RequestDetail from '../../RequestDetail/Saga/RequestDetail';
import * as Adjustment from '../../Adjustment/Saga/Adjustment';

import * as chat_send_api from '../Api/chat_send_api';
import * as LineUserResponsesApi from '../Api/LineUserResponsesApi';
import { reset } from 'redux-form';
// import firebaseConfig from '../../../firebase-config';
// // 初期化
// const firebaseApp = firebase.initializeApp(firebaseConfig)

// const rsf = new ReduxSagaFirebase(firebaseApp)

// import console = require('console');
// import * as Common from './Common';

export interface ChatState {
    chats: any;
    chat_id: any;
    chat_bottom: boolean;
    chat_send: boolean;
    get_chat_open: any;
    chat_open_flg: boolean;
    process_flag: any;
    process_user: any;
    chatimage: any;
    chat_images: any;
    chat_file_data: any;
    chat_send_modal: number;
    chat_send_file_loading: boolean;
    chat_send_list_loading: boolean;
    Outai_No: any;
    completion_at: any;
    new_chat_id: any;

    chat_file_dialog_disp:boolean;
    room_support_m_devices:any;
    chat_image_disp:boolean;

}

const initialState: ChatState = {

    chats: [],
    chat_id: 0,
    chat_bottom: false,

    process_flag: 1,
    process_user: "",

    chat_send: false,
    get_chat_open: [],
    chat_open_flg: false,
    chatimage: [],
    chat_images: {},
    chat_file_data: null,
    chat_send_modal: 0,
    chat_send_file_loading: false,
    chat_send_list_loading: false,
    Outai_No: 0,
    completion_at: 0,
    new_chat_id: [],

    chat_file_dialog_disp : false,
    room_support_m_devices: [],
    chat_image_disp:false,
}



///action
const actionCreator = actionCreatorFactory();

export const new_chat = actionCreator<any>('NEW_CHAT');
export const new_chat_success = actionCreator<any>('NEW_CHAT_SUCCESS');


export const set_Outai_No = actionCreator<any>('SET_OUTAI_NO');
export const set_Outai_No_success = actionCreator<any>('SET_OUTAI_NO_SUCCESS');


export const set_process_flag = actionCreator<any>('SET_PROCESS_FLAG');
export const set_process_flag_success = actionCreator<any>('SET_PROCESS_FLAG_SUCCESS');
export const set_process_user_success = actionCreator<any>('SET_PROCESS_USER_SUCCESS');
export const set_completion_at_success = actionCreator<any>('SET_COMPLETION_AT_SUCCESS');


export const get_chats = actionCreator<any>('GET_CHATS');
export const change_chats = actionCreator<any>("CHANGE_CHATS");
export const change_chat_id = actionCreator<any>("CHANGE_CHAT_ID");


export const send_chat_message = actionCreator<any>('SEND_CHAT_MESSAGE');
export const send_chat_outai = actionCreator<any>('SEND_CHAT_OUTAI');


export const change_chat_bottom = actionCreator<any>('CHANGE_CHAT_BOTTOM');

export const change_chat_send = actionCreator<any>('CHANGE_CHAT_SEND');


export const get_chat_open = actionCreator<any>('GET_CHAT_OPEN');
export const get_chat_close = actionCreator<any>('GET_CHAT_CLOSE');


export const change_chat_open_flg = actionCreator<any>('CHANGE_CHAT_OPEN_FLG');

export const get_chat_images = actionCreator<any>('GET_CHAT_IMAGES');
export const change_chat_images = actionCreator<any>('CHANGE_CHAT_IMAGES');

export const change_chat_file_data = actionCreator<any>('CHANGE_CHAT_FILE_DATA');


export const upload_chat_file = actionCreator<any>('UPLOAD_CHAT_FILE');

export const download_chat_file = actionCreator<any>('DOWNLOAD_CHAT_FILE');

export const change_chat_send_modal = actionCreator<number>('CHANGE_CHAT_SEND_MODAL');



export const change_chat_send_file_loading = actionCreator<boolean>('CHANGE_CHAT_SEND_FILE_LOADING');
export const change_chat_send_list_loading = actionCreator<boolean>('CHANGE_CHAT_SEND_LIST_LOADING');

export const change_chat_file_dialog_disp = actionCreator<boolean>('CHANGE_CHAT_FILE_DIALOG_DISP');

export const get_room_support_m_devices = actionCreator('GET_ROOM_SUPPORT_M_DEVICE');
export const change_room_support_m_devices = actionCreator('CHANGE_ROOM_SUPPORT_M_DEVICES');
export const change_chat_image_disp = actionCreator<boolean>('CHANGE_CHAT_IMAGE_DISP');




export const chatReducer = reducerWithInitialState(initialState)
    .case(new_chat_success, (state, payload) => ({ ...state, new_chat_id: payload }))

    .case(set_Outai_No_success, (state, payload) => ({ ...state, Outai_No: payload }))
    .case(set_completion_at_success, (state, payload) => ({ ...state, completion_at: payload }))

    .case(set_process_flag_success, (state, payload) => ({ ...state, process_flag: payload }))
    .case(set_process_user_success, (state, payload) => ({ ...state, process_user: payload }))


    .case(change_chats, (state, payload) => ({ ...state, chats: payload }))
    .case(change_chat_bottom, (state, payload) => ({ ...state, chat_bottom: payload }))
    .case(change_chat_send, (state, payload) => ({ ...state, chat_send: payload }))
    .case(change_chat_open_flg, (state, payload) => ({ ...state, chat_open_flg: payload }))
    .case(change_chat_id, (state, payload) => ({ ...state, chat_id: payload }))

    .case(change_chat_images, (state, payload) => ({ ...state, chat_images: payload }))

    .case(change_chat_file_data, (state, payload) => ({ ...state, chat_file_data: payload }))
    .case(change_chat_send_modal, (state, payload) => ({ ...state, chat_send_modal: payload }))
    .case(change_chat_send_file_loading, (state, payload) => ({ ...state, chat_send_file_loading: payload }))

    .case(change_chat_file_dialog_disp, (state, payload) => ({ ...state, chat_file_dialog_disp: payload }))

    .case(change_room_support_m_devices, (state, payload) => ({ ...state, room_support_m_devices: payload }))
    .case(change_chat_image_disp, (state, payload) => ({ ...state, chat_image_disp: payload }))


// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const chatSaga = [takeEvery('GET_CHATS', handle_get_chats),
takeEvery('NEW_CHAT', handle_new_chat),

takeEvery('SET_OUTAI_NO', handle_set_Outai_No),

takeEvery('SEND_CHAT_MESSAGE', handle_send_chat_message),
takeEvery('SEND_CHAT_OUTAI', handle_send_chat_outai),


takeEvery('GET_CHAT_OPEN', handle_get_chat_open),
takeEvery('GET_CHAT_CLOSE', handle_get_chat_close),

takeEvery('GET_CHAT_IMAGES', handle_get_chat_images),

takeEvery('UPLOAD_CHAT_FILE', handle_upload_chat_file),

takeEvery('DOWNLOAD_CHAT_FILE', handle_download_chat_file),

takeEvery('SET_PROCESS_FLAG', handle_set_process_flag),

takeEvery('GET_ROOM_SUPPORT_M_DEVICE', handle_get_room_support_m_devices),

];



// 新しいチャットを作る　帰ってきたidでチャットを開く
function* handle_new_chat(action: any) {
    const { payload, error } = yield call(chat_send_api.chat_new, action.payload);
    if (payload && !error) {
        yield put(new_chat_success(payload));

    } else {

    }

}

// 応対NOをセットする
function* handle_set_Outai_No(action: any) {
    yield put(set_Outai_No_success(action.payload));
}

function* handle_send_chat_outai(action: any) {

    const { payload, error } = yield call(chat_send_api.chat_insert, action.payload);

    if (payload && !error) {
        // yield put(new_chat_success(payload));

        yield put(Adjustment.get_request_detail({ Outai_No: action.payload.Outai_No }));
        yield put(get_chats(action.payload.room_support_m_chat_id));

    } else {

    }

}



function* handle_set_process_flag(action: any) {
    yield put(set_process_flag_success(action.payload.process_flag));
    yield put(set_process_user_success(action.payload.process_user));
    yield put(set_Outai_No_success(action.payload.Outai_No))
    yield put(set_completion_at_success(action.payload.completion_at))

}



function* handle_get_chats(action: any) {
    yield put(change_chat_bottom(true));
    yield put(change_chat_open_flg(true))
    yield put(change_chat_id(action.payload));
    yield put(change_chat_open_flg(true))

}







function* handle_get_chat_open(action: any) {


    const { payload, error } = yield call(TimeLineApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        // alert()
        // 成功時アクション呼び出し
        yield put(change_chats(payload))
        yield put(change_chat_bottom(true));
        // yield put(change_get_mail_details_loading(0));
        yield put(change_chat_open_flg(true))

    } else {


    }
}



function* handle_get_chat_close(action: any) {

    yield put(change_chat_open_flg(false))
}



function* handle_send_chat_message(action: any):any {

    const state = yield select();

    if (action.payload.chat_message.contents) {
        yield put(change_chat_send(true));
    }
    const { payload, error } = yield call(chat_send_api.send, action.payload.chat_message);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        yield put(change_chat_send(false));

        let data_chat_message = action.payload.chat_message
        let data = action.payload.data

        yield put(RequestDetail.chat_kanryou({
            id: data.chat_id,
            user: state.masterReducer.user_details.id
        }));


        var data_send_chat_outai = {
            OutaiNaiyo: data_chat_message.contents,
            OutaiTanto_Id: state.masterReducer.user_details.id,
            room_support_m_chat_id: data.chat_id,
            Outai_No: data.Outai_No
        }

        yield put(send_chat_outai(data_send_chat_outai));
        yield put(set_process_flag(
            {
                process_user: state.masterReducer.user_details.userNameAll,
                process_flag: 1,
                Outai_No: data.Outai_No
            }
        ));


        alert("送信されました")


    } else {
        alert("チャットが送信されませんでした")

    }
}




function* handle_get_chat_images(action: any) {



    const { payload, error } = yield call(LinesApi.get_images, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        let bloburl = URL.createObjectURL(payload)
        // console.log(bloburl)
        // 成功時アクション呼び出し
        yield put(change_chat_images({
            id: action.payload,
            bloburl: bloburl
        }))



    } else {


    }
}

// 画像送信
function* handle_upload_chat_file(action: any):any {

    const state = yield select();
    yield put(change_chat_send_file_loading(true));

    // alert(state.chat.chat_file_data.name)
    // alert(state.chat.chat_file_data.mimeType)
    // alert(state.chat.chat_id)

    const { payload, error } = yield call(chat_send_api.upload, { room_support_m_chat_id: state.chat.chat_id, name: state.chat.chat_file_data.name, file: state.chat.chat_file_data.file, mime_type: state.chat.chat_file_data.mime_type });

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(change_chat_file_data(null));
        yield put(change_chat_send_file_loading(false));
        yield put(change_chat_send_modal(0));

        yield put(change_chat_file_dialog_disp(false));


    } else {
        alert(error)

    }
}



function* handle_download_chat_file(action: any):any {

    const state = yield select();

    const { payload, error } = yield call(LinesApi.download_file, action.payload.file_id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {


        const a = document.createElement('a');
        // ダウンロードされるファイル名
        a.download = action.payload.message_text;
        // a.download = "10.pdf";
        a.href = URL.createObjectURL(payload);
        // ダウンロード開始
        a.click();


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_room_support_m_devices(action: any): any {
    const state = yield select();

    const { payload, error } = yield call(room_support_m_devices_api.detail, {chat_id:state.chat.chat_id});
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_room_support_m_devices(payload))

        let flg = true
        payload.forEach(function( value:any ) {

            if(value.app_versions && value.app_versions != ""){
                if(value.app_versions.split(".").length > 1){
                    if(parseInt(value.app_versions.split(".")[0]) <= 2){
                        if(parseInt(value.app_versions.split(".")[0]) < 2){
                            flg = false
                        }else{
                            if(parseInt(value.app_versions.split(".")[1]) <= 2){
                                flg = false
                            }
                        }

                    }
                    
                }else{
                    flg = false
                }
            }else{
                flg = false
            }
         
        });
        yield put(change_chat_image_disp(flg))
        // yield put(change_rent_m_corporates_list_search(payload))
    } else {

    }
}