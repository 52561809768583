import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Kys from '../Kys/Index';
import * as KysSaga from '../Kys/Saga/Kys';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as Line from '../Line/Saga/Line';
import * as Chat from '../Chat/Saga/Chat';
import * as Sms from '../Sms/Saga/Sms';
import * as Mail from '../Mail/Saga/Mail';
import DetailListGrid from "./demoDetailListGrid";
import NewOutai from "./demoNewOutai";
import EditRequest from "./demoEditRequest";
import IkkatsuModal from "./demoIkkatsuModal";
import SmsModal from "./SmsModal";
import NewChatModal from "./NewChatModal";
import SmsGrid from "./SmsGrid";
import CompleteModal from "./demoCompleteModal";
import PastRequest from "./PastRequestGrid";
import KeiyakuDetail from "./KeiyakuDetail";
import KanrenKoujiModal from "./KanrenKoujiModal";
import Button from '@material-ui/core/Button';
import * as Adjustment from '../Adjustment/Saga/Adjustment';
import * as RequestDetailSaga from './Saga/RequestDetail';
import { Row, Col } from 'reactstrap';
import * as Keiyaku from '../Keiyaku/Saga/Keiyaku';
import TabletMacIcon from '@material-ui/icons/TabletMac';


import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme: any) => ({
    margin: {
        margin: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tab: {

        minWidth: 50, // a number of your choice
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton style={{ position: 'absolute' }} aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}

        </MuiDialogTitle>
    );
});


interface Props {
    get_ky: any,
    ky: any,
    change_mail_new: any,
    get_response_lines: any,
    get_chats: any,
    get_smses: any,
    classes: any,
    kys_biko: any,
    get_kys: any,
    request_detail: any,
    get_request_detail: any,
    get_tatemono: any,
    get_room: any,
    get_gyousya: any,
    get_outaisub: any,
    get_past_request: any,
    get_keiyaku: any,
    outai_delete: any,
    reqtoggle: any,
    get_kouji: any,
    outai_complete: any,
    chat_kanryou: any,
    chat_complet: any,
    user_details: any,
    get_request_excels: any,
    match: any,
    get_parking: any,
    get_parkingarea: any,
    get_chatlist: any,
    get_sms_share: any,
    set_process_flag: any,
    set_Outai_No: any,
    get_template_category_list: any,
    importance: any,
    get_kouji_list: any,
    kouji_list: any,
    new_chat: any,
    new_chat_id: any,
    chat_plus: any,
    ipad_height: any,
    get_file: any,
    ikkatsu_file: any,
    get_sms: any
}



interface State {
    reqtoggle: boolean,
    edittoggle: boolean,
    ikkatsutoggle: boolean,
    smstoggle: boolean,
    newchattoggle: boolean,
    Completetoggle: boolean,
    KanrenKoujitoggle: boolean,
    reflesh: boolean,
    responseTabs: string,
    customerTabs: string,
    modalkys: boolean,
    disp: boolean,
    popup: boolean,
    search_status: boolean,
    ipad_height: any,
    demo_date: any
    // state types
}



class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        if (this.props.match) {
            props.get_request_detail({ Outai_No: this.props.match.params.outai_no })
            props.get_kouji_list(this.props.match.params.outai_no)
        }

        var data: {
            Gyosha2_Name: null; Gyosha_Name: null; Keiyaku_Date: string; Kokyaku_Kbn: string; Kokyaku_Name: string; Last_Date: null; Outai_Joukyou_Name: string; Outai_No: number; PA_Name: null; P_Name: null; Renraku_Saki: string; Room_Name: string; Shoyuusha_Tantou_Name: string; Syokai_Date: string; Tatemono_Name: string; Title: string; complete_date: string; completed_remarks: null; completed_title: null; created_at: string; importance: null; is_kaketsuke: boolean; is_kenan: boolean; is_long: boolean; is_seikyuu: boolean; lasts: string; main_outai_sub: number; not_published_flag: number; owner_houkoku: string; process_flag: number; remarks: string; room_support_m_chat_id: number; second_date: null; shokai_user: string; taiou_user: string; type: string; Renraku_Kibou: string; Tenpo_Name_Short: string; outai_sub_name: string; Naiyou: string; OutaiShosai_No: number;
            room_support_m_user_id:string;OutaiHoho_Name: string; OutaiHoho_Biko: string; syosai_taiou_user: string; ShosaiNaiyo: string; OutaiDate_M: string; OutaiDate_H: string; OutaiDate: string;
        }[] = [];


        if (this.props.match.params.outai_no == "111111") {
            data = [{
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "伊藤 太郎(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111111,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "101",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件１",
                Title: "騒音",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-16",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 12774,
                second_date: null,
                shokai_user: "佐藤",
                taiou_user: "佐藤",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "騒音",
                Naiyou: "上階の住人が、夜中に騒音を立てている",
                OutaiDate: "2023-02-15T00:00:00.000Z",
                OutaiDate_H: "12",
                OutaiDate_M: "50",
                ShosaiNaiyo: "電話で内容をヒアリング",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                OutaiShosai_No: 1111140,
                room_support_m_user_id:"BznjR8k8B4SKXU5kWov7Ef5z6t12"
            }, {
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "伊藤 太郎(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111111,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "101",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件１",
                Title: "騒音",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-16",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "佐藤",
                taiou_user: "佐藤",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "コンセント・スイッチ",
                Naiyou: "コンセントが使えない。ブレーカーは上がっている",
                OutaiDate: "2023-02-16T00:00:00.000Z",
                OutaiDate_H: "13",
                OutaiDate_M: "45",
                ShosaiNaiyo: "ご連絡",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                OutaiShosai_No: 1111141,
                room_support_m_user_id:""
            }]
        }

        if (this.props.match.params.outai_no == "111112") {
            data = [{
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "田中 花子(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111112,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "102",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件２",
                Title: "蛇口劣化",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-16",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "佐藤",
                taiou_user: "佐藤",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "水栓（漏水以外）",
                Naiyou: "洗濯機を設置する蛇口の劣化",
                OutaiDate: "2023-02-15T00:00:00.000Z",
                OutaiDate_H: "12",
                OutaiDate_M: "50",
                ShosaiNaiyo: "電話で内容をヒアリング",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                OutaiShosai_No: 1111140,
                room_support_m_user_id:""
            }, {
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "田中 花子(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111112,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "102",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件２",
                Title: "蛇口劣化",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-16",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "佐藤",
                taiou_user: "佐藤",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "コンセント・スイッチ",
                Naiyou: "コンセントが使えない。ブレーカーは上がっている",
                OutaiDate: "2023-02-16T00:00:00.000Z",
                OutaiDate_H: "13",
                OutaiDate_M: "45",
                ShosaiNaiyo: "ご連絡",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111141
            }]
        }

        if (this.props.match.params.outai_no == "111113") {
            data = [{
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "田中 次郎(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111113,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "104",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件３",
                Title: "トイレ漏水",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-16",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "佐藤",
                taiou_user: "佐藤",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "トイレ（便器・タンク）",
                Naiyou: "年数、メーカーは不明だが水を流した後ずっとポタポタ音がしている。",
                OutaiDate: "2023-02-15T00:00:00.000Z",
                OutaiDate_H: "12",
                OutaiDate_M: "50",
                ShosaiNaiyo: "電話で内容をヒアリング",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111140
            }, {
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "田中 次郎(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111113,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "104",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件３",
                Title: "トイレ漏水",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-16",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "佐藤",
                taiou_user: "佐藤",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "コンセント・スイッチ",
                Naiyou: "コンセントが使えない。ブレーカーは上がっている",
                OutaiDate: "2023-02-16T00:00:00.000Z",
                OutaiDate_H: "13",
                OutaiDate_M: "45",
                ShosaiNaiyo: "ご連絡",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111141
            }]
        }


        if (this.props.match.params.outai_no == "111114") {
            data = [{
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "井上 和則(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111114,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "202",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件４",
                Title: "コンセント不具合",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-03",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 0,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "鈴木",
                taiou_user: "鈴木",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "コンセント・スイッチ",
                Naiyou: "コンセントが使えない。ブレーカーは上がっている",
                OutaiDate: "2023-02-15T00:00:00.000Z",
                OutaiDate_H: "12",
                OutaiDate_M: "50",
                ShosaiNaiyo: "電話で内容をヒアリング",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111140
            }, {
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "井上 和則(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111114,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "202",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件４",
                Title: "コンセント不具合",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-03",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 0,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "鈴木",
                taiou_user: "鈴木",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "コンセント・スイッチ",
                Naiyou: "コンセントが使えない。ブレーカーは上がっている",
                OutaiDate: "2023-02-16T00:00:00.000Z",
                OutaiDate_H: "13",
                OutaiDate_M: "45",
                ShosaiNaiyo: "ご連絡",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111141
            }]
        }

        if (this.props.match.params.outai_no == "111115") {
            data = [{
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "吉田 良子(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111115,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "304",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件５",
                Title: "宅配ボックスが開かない",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-16",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "鈴木",
                taiou_user: "鈴木",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "ポスト・宅配BOX",
                Naiyou: "置き配伝票に記載された暗証番号も間違えているのか開きません",
                OutaiDate: "2023-02-15T00:00:00.000Z",
                OutaiDate_H: "12",
                OutaiDate_M: "50",
                ShosaiNaiyo: "電話で内容をヒアリング",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111140
            }, {
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "吉田 良子(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111115,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "304",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件５",
                Title: "宅配ボックスが開かない",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-16",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "鈴木",
                taiou_user: "鈴木",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "コンセント・スイッチ",
                Naiyou: "コンセントが使えない。ブレーカーは上がっている",
                OutaiDate: "2023-02-16T00:00:00.000Z",
                OutaiDate_H: "13",
                OutaiDate_M: "45",
                ShosaiNaiyo: "ご連絡",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111141
            }]
        }

        if (this.props.match.params.outai_no == "111116") {
            data = [{
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "内田 内子(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111116,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "202",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件６",
                Title: "ゴミ分別に関して",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-16",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "佐藤",
                taiou_user: "佐藤",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "ゴミ分別",
                Naiyou: "「燃やせるごみ」と「破砕ごみ」については、高松市の指定収集袋に入れなくても良いのでしょうか？とのご質問",
                OutaiDate: "2023-02-15T00:00:00.000Z",
                OutaiDate_H: "12",
                OutaiDate_M: "50",
                ShosaiNaiyo: "電話で内容をヒアリング",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111140
            }, {
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "内田 内子(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111116,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "202",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件６",
                Title: "ゴミ分別に関して",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-16",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "佐藤",
                taiou_user: "佐藤",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "コンセント・スイッチ",
                Naiyou: "コンセントが使えない。ブレーカーは上がっている",
                OutaiDate: "2023-02-16T00:00:00.000Z",
                OutaiDate_H: "13",
                OutaiDate_M: "45",
                ShosaiNaiyo: "ご連絡",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111141
            }]
        }

        if (this.props.match.params.outai_no == "111117") {
            data = [{
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "白井 花子(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111117,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "403",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件７",
                Title: "給湯器エラー",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-16",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "山本",
                taiou_user: "山本",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "給湯器(電気・ガス)",
                Naiyou: "給湯器に「651」のエラーが発生しています。",
                OutaiDate: "2023-02-15T00:00:00.000Z",
                OutaiDate_H: "12",
                OutaiDate_M: "50",
                ShosaiNaiyo: "電話で内容をヒアリング",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111140
            }, {
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "白井 花子(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111117,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "403",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件７",
                Title: "給湯器エラー",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-16",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "山本",
                taiou_user: "山本",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "コンセント・スイッチ",
                Naiyou: "コンセントが使えない。ブレーカーは上がっている",
                OutaiDate: "2023-02-16T00:00:00.000Z",
                OutaiDate_H: "13",
                OutaiDate_M: "45",
                ShosaiNaiyo: "ご連絡",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111141
            }]
        }

        if (this.props.match.params.outai_no == "111118") {
            data = [{
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "木村 太郎(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111118,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "304",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件８",
                Title: "モニターフォン不具合",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-16",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "山本",
                taiou_user: "山本",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "インターフォン・チャイム",
                Naiyou: "モニターフォン　エラー　が出る。",
                OutaiDate: "2023-02-15T00:00:00.000Z",
                OutaiDate_H: "12",
                OutaiDate_M: "50",
                ShosaiNaiyo: "電話で内容をヒアリング",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111140
            }, {
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "木村 太郎(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111118,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "304",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件８",
                Title: "モニターフォン不具合",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-16",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "山本",
                taiou_user: "山本",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "コンセント・スイッチ",
                Naiyou: "コンセントが使えない。ブレーカーは上がっている",
                OutaiDate: "2023-02-16T00:00:00.000Z",
                OutaiDate_H: "13",
                OutaiDate_M: "45",
                ShosaiNaiyo: "ご連絡",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111141
            }]
        }

        if (this.props.match.params.outai_no == "111119") {
            data = [{
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "伊藤 士郎(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111119,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "104",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件９",
                Title: "インターネットが繋がりにくい",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-16",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "山本",
                taiou_user: "山本",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "インターネット",
                Naiyou: "2～3日前からインターネットが繋がらない・遅い。",
                OutaiDate: "2023-02-15T00:00:00.000Z",
                OutaiDate_H: "12",
                OutaiDate_M: "50",
                ShosaiNaiyo: "電話で内容をヒアリング",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111140
            }, {
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "伊藤 士郎(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111119,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "104",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件９",
                Title: "インターネットが繋がりにくい",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-16",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "山本",
                taiou_user: "山本",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "コンセント・スイッチ",
                Naiyou: "コンセントが使えない。ブレーカーは上がっている",
                OutaiDate: "2023-02-16T00:00:00.000Z",
                OutaiDate_H: "13",
                OutaiDate_M: "45",
                ShosaiNaiyo: "ご連絡",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111141
            }]
        }

        if (this.props.match.params.outai_no == "111120") {
            data = [{
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "木村 卓也(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111120,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "204",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件１０",
                Title: "浴室不具合",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-05",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "山本",
                taiou_user: "山本",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "建具　浴室",
                Naiyou: "浴室扉の取っ手が両端の付け根から折れた。",
                OutaiDate: "2023-02-15T00:00:00.000Z",
                OutaiDate_H: "12",
                OutaiDate_M: "50",
                ShosaiNaiyo: "電話で内容をヒアリング",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111140
            }, {
                Gyosha2_Name: null,
                Gyosha_Name: null,
                Keiyaku_Date: "2020-03-29",
                Kokyaku_Kbn: "入居者",
                Kokyaku_Name: "木村 卓也(テスト)",
                Last_Date: null,
                Outai_Joukyou_Name: "応対中",
                Outai_No: 111120,
                PA_Name: null,
                P_Name: null,
                Renraku_Saki: "00011110000",
                Room_Name: "204",
                Shoyuusha_Tantou_Name: "金田",
                Syokai_Date: "2023-02-16",
                Tatemono_Name: "テスト物件１０",
                Title: "浴室不具合",
                complete_date: "",
                completed_remarks: null,
                completed_title: null,
                created_at: "2023-02-16",
                importance: null,
                is_kaketsuke: false,
                is_kenan: false,
                is_long: false,
                is_seikyuu: false,
                lasts: "2023-02-05",
                main_outai_sub: 66,
                not_published_flag: 0,
                owner_houkoku: "",
                process_flag: 1,
                remarks: "",
                room_support_m_chat_id: 0,
                second_date: null,
                shokai_user: "山本",
                taiou_user: "山本",
                type: "電話",
                Renraku_Kibou: "午前中",
                Tenpo_Name_Short: "業務課",
                outai_sub_name: "コンセント・スイッチ",
                Naiyou: "コンセントが使えない。ブレーカーは上がっている",
                OutaiDate: "2023-02-16T00:00:00.000Z",
                OutaiDate_H: "13",
                OutaiDate_M: "45",
                ShosaiNaiyo: "ご連絡",
                syosai_taiou_user: "鈴木",
                OutaiHoho_Biko: "",
                OutaiHoho_Name: "電話",
                room_support_m_user_id:"",
                OutaiShosai_No: 1111141
            }]
        }


        // this.props.change_search_nｓｓame("")
        this.state = {
            reqtoggle: false,
            edittoggle: false,
            ikkatsutoggle: false,
            smstoggle: false,
            newchattoggle: false,
            Completetoggle: false,
            KanrenKoujitoggle: false,
            reflesh: false,
            responseTabs: "k1",
            modalkys: false,
            customerTabs: "kys1",
            disp: true,
            popup: false,
            search_status: false,
            ipad_height: 0,
            demo_date: data
        }
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handlecustomerTabChange = this.handlecustomerTabChange.bind(this);
        this.togglekys = this.togglekys.bind(this);
        this.reqtoggle = this.reqtoggle.bind(this);
        this.edittoggle = this.edittoggle.bind(this);
        this.edit_after_toggle = this.edit_after_toggle.bind(this);

        this.ikkatsutoggle = this.ikkatsutoggle.bind(this);
        this.smstoggle = this.smstoggle.bind(this);
        this.newchattoggle = this.newchattoggle.bind(this);

        this.KanrenKoujitoggle = this.KanrenKoujitoggle.bind(this);

        this.url_open = this.url_open.bind(this);
        this.url_open_tatemono = this.url_open_tatemono.bind(this);
        this.url_open_room = this.url_open_room.bind(this);
        this.url_open_kaketuke = this.url_open_kaketuke.bind(this);
        this.url_open_isai = this.url_open_isai.bind(this);
        this.url_open_uketuke = this.url_open_uketuke.bind(this);
        this.url_open_kako = this.url_open_kako.bind(this);
        this.url_open_seisan = this.url_open_seisan.bind(this);
        this.delete = this.delete.bind(this);
        this.kouji_list = this.kouji_list.bind(this);
        this.tabreset = this.tabreset.bind(this);
        this.chat_open = this.chat_open.bind(this);
        this.chat_open2 = this.chat_open2.bind(this);

        this.chat_list_open = this.chat_list_open.bind(this);

        this.popup = this.popup.bind(this);
        this.kanryou = this.kanryou.bind(this);
        this.mikanryou = this.mikanryou.bind(this);
        this.chat_kanryou = this.chat_kanryou.bind(this);
        this.star_plus = this.star_plus.bind(this);
        this.star_minus = this.star_minus.bind(this);
        this.chat_plus = this.chat_plus.bind(this);
        this.ipad_mode = this.ipad_mode.bind(this);
        this.Completetoggle = this.Completetoggle.bind(this);
        this.disp_reset = this.disp_reset.bind(this);
        this.chat_plus_update = this.chat_plus_update.bind(this);

    }
    Completetoggle() {

        if (this.state.Completetoggle == true) {
            this.props.get_request_detail({ Outai_No: this.state.demo_date[0].Outai_No });
        } else {
            this.props.get_outaisub();
        }
        this.setState({ Completetoggle: !this.state.Completetoggle })
    }

    star_plus(i: any) {
        var importance = this.state.demo_date[0].importance ? this.state.demo_date[0].importance : 0;

        importance = importance + i;

        this.props.importance(
            {
                importance: importance,
                Outai_No: this.state.demo_date[0].Outai_No
            }
        )
    }
    star_minus(i: any) {

        var importance = this.state.demo_date[0].importance ? this.state.demo_date[0].importance : 0;

        importance = importance - i;

        if (importance == 0) {
            importance = null
        }

        this.props.importance(
            {
                importance: importance,
                Outai_No: this.state.demo_date[0].Outai_No
            }
        )

    }



    reqtoggle() {
        if (this.state.reqtoggle == true) {
            this.props.get_request_detail({ Outai_No: this.state.demo_date[0].Outai_No });
        }
        this.setState({ reqtoggle: !this.state.reqtoggle })
    }

    edittoggle() {
        this.props.get_gyousya();
        this.props.get_outaisub();
        this.props.get_tatemono();
        this.props.get_parking();
        this.props.get_room(this.state.demo_date[0].Tatemono_Id);
        if (this.state.demo_date[0].Tatemono_Id) {
            this.props.get_parkingarea(this.state.demo_date[0].Tatemono_Id.replace("SysP", ""));
        }
        this.setState({ edittoggle: !this.state.edittoggle })
    }

    edit_after_toggle() {
        if (this.state.edittoggle == true) {
            this.props.get_request_detail({ Outai_No: this.state.demo_date[0].Outai_No });
            this.setState({ disp: !this.state.disp })

            setTimeout(() => {
                this.setState({ disp: !this.state.disp })
            }, 2000)
        }
        this.setState({ edittoggle: !this.state.edittoggle })
    }

    ikkatsutoggle() {
        this.setState({ ikkatsutoggle: !this.state.ikkatsutoggle })
    }
    smstoggle() {
        if (this.state.smstoggle == true) {
            this.props.get_request_detail({ Outai_No: this.state.demo_date[0].Outai_No });
        } else {
            this.props.get_template_category_list();
        }
        this.setState({ smstoggle: !this.state.smstoggle })
    }

    newchattoggle() {
        if (this.state.newchattoggle == true) {
            this.props.get_request_detail({ Outai_No: this.state.demo_date[0].Outai_No });
        } else {
            this.props.get_template_category_list();
        }
        this.setState({ newchattoggle: !this.state.newchattoggle })
    }

    KanrenKoujitoggle() {
        this.setState({ KanrenKoujitoggle: !this.state.KanrenKoujitoggle })
    }

    submit(state: any) {
        console.log(state)
        // bababa

        // this.props.get_remittance_details(state)
    }

    togglekys(kys_no: any) {
        console.log(kys_no)
        if (!this.state.modalkys) {
            this.props.get_kys(kys_no)
        }
        this.setState({ modalkys: !this.state.modalkys })
        // this.props.get_remittance_details(state)
    }

    url_open() {
        var url = process.env.REACT_APP_HOMESTATION4_URL+'/manage/request/detail?no=' + this.state.demo_date[0].Outai_No;
        window.open(url, '_blank')
    }

    url_open_tatemono() {

        if (this.state.demo_date[0].Tatemono_Name) {
            var url = process.env.REACT_APP_HOMESTATION4_URL+'/manage/building/detail/index?t_id=' + this.state.demo_date[0].Tatemono_Id;
        } else {
            var id = this.state.demo_date[0].Tatemono_Id.replace("SysP", "");
            var url = process.env.REACT_APP_HOMESTATION4_URL+'/manage/parking/detail/index?p_id=' + id;
        }
        window.open(url, '_blank')
    }

    url_open_room() {
        var url = process.env.REACT_APP_HOMESTATION4_URL+'/manage/room/detail/index?t_id=' + this.state.demo_date[0].Tatemono_Id + '&r_id=' + + this.state.demo_date[0].Room_Id;
        window.open(url, '_blank')
    }

    url_open_kaketuke() {
        alert("※デモ画面のため、ダウンロードはされません");
        return;
    }

    url_open_isai() {
        alert("※デモ画面のため、ダウンロードはされません");
        return;
    }

    url_open_uketuke() {
        alert("※デモ画面のため、ダウンロードはされません");
        return;
    }

    url_open_kako() {
        var url = process.env.REACT_APP_HOMESTATION4_URL+'/manage/construction/construction/list?Tatemono_Name=' + this.state.demo_date[0].Tatemono_Name;
        window.open(url, '_blank')
    }

    url_open_seisan() {
        var url = process.env.REACT_APP_HOMESTATION4_URL+'/manage/building/checkout/flow?ky_no=' + this.state.demo_date[0].Pre_Keiyaku_No;
        window.open(url, '_blank', 'noopener')
    }

    chat_open() {


        this.props.set_process_flag(
            {
                process_user: this.props.user_details.id,
                process_flag: 0
            }
        );

        this.props.get_chats(this.state.demo_date[0].room_support_m_chat_id);
        this.props.set_Outai_No(this.state.demo_date[0].Outai_No);
    }

    chat_open2() {
        this.props.set_process_flag(
            {
                process_user: this.state.demo_date[0].chat_user,
                process_flag: 1
            }
        );

        this.props.get_chats(this.state.demo_date[0].room_support_m_chat_id);
        this.props.set_Outai_No(this.state.demo_date[0].Outai_No);
    }


    chat_list_open() {
        // this.props.get_sms_share({ m_customer_id: this.state.demo_date[0].m_customer_id });

        this.props.get_chatlist({
            m_customer_id: this.state.demo_date[0].m_customer_id,
            room_support_m_user_id: this.state.demo_date[0].room_support_m_user_id
        });

        this.props.get_chats(0);
    }

    delete() {

        alert("※デモ画面のため、削除はされません");
        return;


        var result = window.confirm('リクエストを削除します。データは戻りません。宜しいですか？');
        if (result) {
            this.props.outai_delete({ Outai_No: this.state.demo_date[0].Outai_No });
            alert("削除されました");

            if (this.state.demo_date[0].room_support_m_chat_id) {
                this.props.chat_complet({
                    id: this.state.demo_date[0].room_support_m_chat_id
                })
                alert("チャットは完了になりました。");
            }


            this.props.reqtoggle();
        }
    }

    kouji_list() {

        this.KanrenKoujitoggle();

    }


    handleTabChange = (event: any, newValue: string) => {
        this.setState({ responseTabs: newValue });

        // 過去のリクエストタブの場合、読み込みを開始する
        if (newValue == "k3") {
            var data = {
                Tatemono_Id: this.state.demo_date[0].Tatemono_Id,
                Room_Id: this.state.demo_date[0].Room_Id
            }
            this.props.get_past_request(data);

            // 契約情報のタブ　読み込み開始
        } else if (newValue == "k2") {

            this.props.get_keiyaku({ Keiyaku_No: this.state.demo_date[0].Keiyaku_No });

            // SMS履歴のタブ
        } else if (newValue == "k4") {

            var data = {
                Tatemono_Id: this.state.demo_date[0].Tatemono_Id,
                Room_Id: this.state.demo_date[0].Room_Id
            }
            this.props.get_past_request(data);
            var tel = this.state.demo_date[0].Renraku_Saki.replace("-", "").replace("-", "").replace("　", "").replace(" ", "").replace(/\r?\n/g, "");

            this.props.get_sms(tel);
        }

    };


    tabreset() {
        this.setState({ disp: !this.state.disp })

        setTimeout(() => {
            this.setState({ disp: !this.state.disp })
        }, 1000)

        this.setState({ responseTabs: "k1" });

    }

    handlecustomerTabChange = (event: any, newValue: string) => {
        this.setState({ customerTabs: newValue });
    };


    popup() {
        this.setState({ popup: !this.state.popup })
    }

    kanryou() {
        // チャットがある場合はチャットとリクエストを完了状態に、チャット無しならリクエストのみ完了に
        // if (this.state.demo_date[0].room_support_m_chat_id) {
        //     var result = window.confirm('チャットを完了状態にします。チャット完了は取り消せません、よろしいですか？');
        //     if (result) {a
        //         this.props.outai_complete({
        //             Outai_Joukyou_Name: "完了",
        //             Outai_No: this.state.demo_date[0].Outai_No,
        //             room_support_m_chat_id: this.state.demo_date[0].room_support_m_chat_id
        //         });

        //         this.props.chat_complet({
        //             id: this.state.demo_date[0].room_support_m_chat_id
        //         })
        //     }
        // } else {

        //     this.props.outai_complete({
        //         Outai_Joukyou_Name: "完了",
        //         Outai_No: this.state.demo_date[0].Outai_No,
        //         room_support_m_chat_id: this.state.demo_date[0].room_support_m_chat_id
        //     });

        // }
        this.setState({ disp: !this.state.disp })
        setTimeout(() => {
            this.props.get_request_detail({ Outai_No: this.state.demo_date[0].Outai_No });
            this.setState({ disp: !this.state.disp, Completetoggle: !this.state.Completetoggle })
        }, 1000)
    }

    mikanryou() {
        this.props.outai_complete({
            Outai_Joukyou_Name: "応対中",
            Outai_No: this.state.demo_date[0].Outai_No,
            room_support_m_chat_id: this.state.demo_date[0].room_support_m_chat_id
        });

        this.setState({ disp: !this.state.disp })

        setTimeout(() => {
            this.props.get_request_detail({ Outai_No: this.state.demo_date[0].Outai_No });
            this.setState({ disp: !this.state.disp })
        }, 1000)
    }

    chat_kanryou() {
        this.props.chat_kanryou(
            {
                id: this.state.demo_date[0].room_support_m_chat_id,
                user: this.props.user_details.id
            });

        this.setState({ disp: !this.state.disp })

        setTimeout(() => {
            this.props.get_request_detail({ Outai_No: this.state.demo_date[0].Outai_No });
            this.setState({ disp: !this.state.disp })
        }, 2000)
    }

    // 新しくチャットを追加する。
    chat_plus() {

        var result = window.confirm('契約者とのチャットが作成されます。顧客が契約者でない場合は作成しても届きません。このまま作成しますか？');
        if (result) {


            this.setState({
                disp: !this.state.disp,
                search_status: !this.state.search_status
            })

            // チャット追加
            this.props.new_chat({
                room_support_m_user_id: this.props.request_detail.m_customers.room_support_m_user_id
            });
            alert("チャットが作成されました。")


            setTimeout(() => {
                // 返ってきたチャットのIDをリクエストに追加
                this.props.chat_plus({
                    room_support_m_chat_id: this.props.new_chat_id.room_support_m_chat_id,
                    Outai_No: this.state.demo_date[0].Outai_No,
                    main_outai_sub: this.state.demo_date[0].main_outai_sub
                })

                setTimeout(() => {
                    this.props.get_request_detail({ Outai_No: this.state.demo_date[0].Outai_No });
                    this.setState({
                        disp: !this.state.disp,
                        search_status: !this.state.search_status
                    })
                }, 2000)

            }, 3000)

        }
    }

    chat_plus_update() {
        setTimeout(() => {
            // 返ってきたチャットのIDをリクエストに追加
            this.props.chat_plus({
                room_support_m_chat_id: this.props.new_chat_id.room_support_m_chat_id,
                Outai_No: this.state.demo_date[0].Outai_No,
                main_outai_sub: this.state.demo_date[0].main_outai_sub
            })

            setTimeout(() => {
                this.props.get_request_detail({ Outai_No: this.state.demo_date[0].Outai_No });
                this.setState({
                    disp: !this.state.disp,
                    search_status: !this.state.search_status
                })
            }, 2000)

        }, 3000)
    }

    disp_reset() {
        this.setState({
            disp: !this.state.disp,
            search_status: !this.state.search_status
        })
    }


    ipad_mode() {
        this.setState({
            ipad_height: this.state.demo_date.length * 30
        })
    }


    render() {

        if (this.state.search_status == true) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'auto';
        }
        var shokai_D = "";
        shokai_D = moment(this.state.demo_date[0]).tz("utc").format('YYYY-MM-DD');

        if (this.state.disp == true) {

            return (
                <div style={{ background: "#b0c4de" }} >

                    <Paper square={false} style={{ marginTop: 10 }}>

                        <Tabs
                            value={this.state.responseTabs}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            onChange={this.handleTabChange}
                            aria-label="disabled tabs example"
                            style={{ float: "left" }}
                        >


                            <div style={{ marginTop: 15 }}>
                                NO.{this.state.demo_date ? this.state.demo_date[0].Outai_No : ""}
                            </div>
                            <span style={{ marginLeft: 20, marginTop: 15 }}>
                                登録日時:{this.state.demo_date ? moment(this.state.demo_date[0].created_at).utc().format('YYYY-MM-DD HH:mm:ss') : ""}
                            </span>

                            <span style={{ marginLeft: 20, marginTop: 15, marginRight: 20 }}>
                                重要度：
                                {this.state.demo_date ?
                                    this.state.demo_date[0].importance ?
                                        this.state.demo_date[0].importance == 1 ?

                                            <span>
                                                <i className="fas fa-star" onClick={() => this.star_minus(1)} style={{ cursor: "pointer" }}></i>
                                                <i className="far fa-star" onClick={() => this.star_plus(1)} style={{ marginLeft: 5, cursor: "pointer" }}></i>
                                                <i className="far fa-star" onClick={() => this.star_plus(2)} style={{ marginLeft: 5, cursor: "pointer" }}></i>
                                            </span>
                                            :

                                            this.state.demo_date[0].importance == 2 ?

                                                <span>
                                                    <i className="fas fa-star" onClick={() => this.star_minus(2)} style={{ cursor: "pointer" }}></i>
                                                    <i className="fas fa-star" onClick={() => this.star_minus(1)} style={{ marginLeft: 5, cursor: "pointer" }}></i>
                                                    <i className="far fa-star" onClick={() => this.star_plus(1)} style={{ marginLeft: 5, cursor: "pointer" }}></i>
                                                </span>
                                                :

                                                this.state.demo_date[0].importance == 3 ?

                                                    <span>
                                                        <i className="fas fa-star" onClick={() => this.star_minus(3)} style={{ cursor: "pointer" }}></i>
                                                        <i className="fas fa-star" onClick={() => this.star_minus(2)} style={{ marginLeft: 5, cursor: "pointer" }}></i>
                                                        <i className="fas fa-star" onClick={() => this.star_minus(1)} style={{ marginLeft: 5, cursor: "pointer" }}></i>
                                                    </span>
                                                    :
                                                    ""

                                        :
                                        <span>
                                            <i className="far fa-star" onClick={() => this.star_plus(1)} style={{ cursor: "pointer" }}></i>
                                            <i className="far fa-star" onClick={() => this.star_plus(2)} style={{ marginLeft: 5, cursor: "pointer" }}></i>
                                            <i className="far fa-star" onClick={() => this.star_plus(3)} style={{ marginLeft: 5, cursor: "pointer" }}></i>
                                        </span>

                                    :
                                    ""}
                            </span>

                            <Tab style={{ color: "black" }} className={this.props.classes.tab} label="リクエスト情報" value="k1" />
                            <Tab style={{ color: "black" }} className={this.props.classes.tab} label="この部屋の過去のリクエスト" value="k3" />
                            {/* <Tab style={{ color: "black" }} className={this.props.classes.tab} label="この連絡先へのSMS履歴" value="k4" /> */}
                        </Tabs>

                        {this.state.responseTabs === "k1" && this.state.demo_date ?
                            <Card className="col-lg-12" square={true} style={{ background: "#b0c4de", padding: 5 }}>

                                <Button size="small" style={{ float: "left", margin: 5 }} onClick={this.delete} color="secondary" variant="contained">
                                    <i className="fas fa-times-circle"></i>削除
                                </Button>
                                {/* <Button size="small" style={{ color: "white", backgroundColor: "#28a745", float: "left", margin: 5 }} onClick={this.url_open_kako} variant="contained">
                                    <i className="fas fa-search"></i>過去工事
                                </Button> */}
                                {/* <Button size="small" style={{ color: "white", backgroundColor: "#5bc0de", float: "left", margin: 5 }} onClick={this.kouji_list} variant="contained">
                                    <i className="fab fa-staylinked"></i>関連工事
                                </Button> */}
                                <Button size="small" style={{ color: "white", backgroundColor: "#f0ad4e", float: "left", margin: 5 }} onClick={this.url_open_uketuke} variant="contained">
                                    <i className="fas fa-print"></i>リクエスト受付書
                                </Button>
                                <Button size="small" style={{ color: "white", backgroundColor: "#f0ad4e", float: "left", margin: 5 }} onClick={this.url_open_isai} variant="contained">
                                    <i className="fas fa-print"></i>リクエスト委細書
                                </Button>
                                <Button size="small" style={{ color: "white", backgroundColor: "#f0ad4e", float: "left", margin: 5 }} onClick={this.url_open_kaketuke} variant="contained">
                                    <i className="fas fa-print"></i>かけつけ依頼書
                                </Button>
                                {/* <Button size="small" style={{ color: "white", backgroundColor: "#008000", float: "left", margin: 5 }} onClick={this.smstoggle} variant="contained">
                                    <i className="fas fa-sms"></i>SMS送信
                                </Button> */}

                                {this.state.demo_date[0].room_support_m_chat_id ?
                                    <Button size="small" style={{ color: "white", backgroundColor: "#ff6347", float: "left", margin: 5 }} onClick={this.chat_list_open} variant="contained">
                                        <i className="fas fa-list"></i> チャットリスト
                                    </Button>
                                    : ""}



                                {this.state.demo_date[0].room_support_m_chat_id ?
                                    this.state.demo_date[0].process_flag == 0 ?
                                        <Button className="animetion_box" size="small" style={{ color: "white", backgroundColor: "#ff007f", float: "left", margin: 5 }} onClick={this.chat_open} variant="contained">
                                            <i className="fas fa-comment-dots"></i>チャット(新着あり)
                                        </Button> :
                                        <Button size="small" style={{ color: "white", backgroundColor: "#ff6347", float: "left", margin: 5 }} onClick={this.chat_open2} variant="contained">
                                            <i className="fas fa-comment-dots"></i>チャット
                                        </Button>

                                    : ""}


                                {this.state.demo_date[0].room_support_m_chat_id ?
                                    ""
                                    :
                                    this.props.request_detail.m_customers ?
                                        this.props.request_detail.m_customers.room_support_m_user_id ?
                                            <Button size="small" style={{ color: "white", backgroundColor: "#c71585", float: "left", margin: 5 }} onClick={this.newchattoggle} variant="contained">
                                                <i className="fas fa-comment-medical"></i>チャットを作成する
                                            </Button> : "" : ""}

                                {/* {this.state.demo_date[0].process_flag == 1 ?
                                    <span>最新チャット対応：{this.state.demo_date[0].chat_user}({moment.utc(new Date(this.state.demo_date[0].process_at)).format('YYYY-MM-DD HH:mm:ss')})</span>
                                    : ""} */}

                                <Button size="small" style={{ float: "right", margin: 5 }} onClick={this.edittoggle} color="primary" variant="contained">編集</Button>

                                {this.state.demo_date[0].Outai_Joukyou_Name == "応対中" ?
                                    <Button size="small" style={{ float: "right", margin: 5, backgroundColor: "#40e0d0", }} onClick={this.Completetoggle} variant="contained">
                                        {this.state.demo_date[0].room_support_m_chat_id ? "リクエスト(チャット)完了にする" : "リクエスト完了にする"}
                                    </Button>
                                    :
                                    <Button size="small" style={{ float: "right", margin: 5, backgroundColor: "#ffff00", }} onClick={this.Completetoggle} variant="contained">リクエスト応対中に戻す</Button>}
                                {/* <Button size="small" style={{ float: "right", margin: 5 }} onClick={this.url_open} variant="contained">
                                    HS版へ<i className="fas fa-external-link-alt"></i>
                                </Button> */}



                                {/* <Button size="small" style={{ color: "white", backgroundColor: "#28a745", float: "right", margin: 5 }} onClick={this.url_open_seisan} variant="contained">
                                    <i className="fas fa-broom"></i>精算フォロー
                                </Button> */}

                                <div style={{ float: "right", margin: 5, fontSize: 15 }}>
                                    {this.state.demo_date[0].Kouji_Gyousha ? "原復業者:" + this.state.demo_date[0].Kouji_Gyousha : ""}
                                    {this.state.demo_date[0].completion_at ? "(完工:" + moment(this.state.demo_date[0].completion_at).utc().format('YYYY-MM-DD') + ")" : ""}
                                </div>

                                <Card style={{ marginTop: 10 }} className="col-lg-12">
                                    <CardContent style={{ float: "left", padding: 10 }} className="col-lg-12">

                                        <Row>


                                            {this.state.demo_date[0].Outai_Joukyou_Name == "完了" ?
                                                <table className="col-lg-12" style={{ textAlign: "left", border: "0px" }}>
                                                    <tbody>
                                                        <tr style={{ border: "0px" }}>
                                                            <th style={{ width: 100, textAlign: "left", backgroundColor: "#f0e68c" }}>
                                                                完了タイトル
                                                            </th>
                                                            <td colSpan={1} >
                                                                {this.state.demo_date[0].completed_title}
                                                            </td>
                                                            <th style={{ width: 100, textAlign: "left", backgroundColor: "#f0e68c" }} >
                                                                完了結果
                                                            </th>
                                                            <td colSpan={3} >
                                                                {this.state.demo_date[0].completed_remarks}
                                                            </td>
                                                            <th style={{ width: 100, textAlign: "left", backgroundColor: this.state.demo_date[0].not_published_flag == 0 ? "#f0e68c" : "#ff6347" }} >
                                                                {this.state.demo_date[0].not_published_flag == 0 ? "掲載する" : "掲載しない"}
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table> : ""}

                                            <table className="col-lg-6" style={{ textAlign: "left", border: "0px" }}>
                                                <tbody>

                                                    <tr style={{ border: "0px" }}>

                                                        <th style={{ textAlign: "left" }} colSpan={1} >受付日</th>
                                                        <td colSpan={1} >
                                                            {this.state.demo_date[0].created_at ? moment(this.state.demo_date[0].created_at).utc().format('YYYY-MM-DD') : ""}
                                                        </td>

                                                        <th style={{ textAlign: "left" }} colSpan={1} >受付方法</th>
                                                        <td colSpan={1} >
                                                            {this.state.demo_date[0].room_support_m_chat_id ? "アプリ"
                                                                :
                                                                this.state.demo_date[0].type == '通常対応' ? "直接受付" : this.state.demo_date[0].type}                                                    </td>

                                                    </tr>

                                                    <tr style={{ border: "0px" }}>

                                                        <th style={{ textAlign: "left" }} colSpan={1} >
                                                            物件名
                                                            {/* {this.state.demo_date[0].Group_Naiyou_No ?
                                                                <span className="label" style={{ float: "right", backgroundColor: "green" }}>
                                                                    かけつけ</span> :


                                                                this.props.request_detail.kaketsuke[0] ?

                                                                    this.props.request_detail.kaketsuke[0]['keiyaku_type'] == 3 ?
                                                                        <span className="label" style={{ float: "right", backgroundColor: "#90ee90" }}>
                                                                            シード？</span>
                                                                        :
                                                                        <span className="label" style={{ float: "right", backgroundColor: "green" }}>
                                                                            かけつけ</span> : ""
                                                            } */}

                                                        </th>
                                                        <td colSpan={2} >
                                                            {this.state.demo_date[0].Tatemono_Name ? this.state.demo_date[0].Tatemono_Name : this.state.demo_date[0].P_Name}
                                                            {/* <IconButton onClick={this.url_open_tatemono} style={{ margin: 0 }} aria-label="delete" className={this.props.classes.margin}>
                                                                <i style={{ fontSize: 5 }} className="fas fa-link"></i>
                                                            </IconButton> */}
                                                        </td>
                                                        <td colSpan={1} >
                                                            {this.state.demo_date[0].Room_Name ? this.state.demo_date[0].Room_Name : this.state.demo_date[0].PA_Name}
                                                            {/* {this.state.demo_date[0].Room_Name ?
                                                                <IconButton onClick={this.url_open_room} style={{ margin: 0 }} aria-label="delete" className={this.props.classes.margin}>
                                                                    <i style={{ fontSize: 5 }} className="fas fa-link"></i>
                                                                </IconButton>
                                                                : ""} */}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >
                                                            顧客名
                                                            {/* {this.props.request_detail.m_customers ?
                                                                this.props.request_detail.m_customers.room_support_m_user_id ?
                                                                    <span className="label" style={{ float: "right", backgroundColor: "orange" }}>
                                                                        契約者はアプリ登録済み</span> : "" : ""} */}
                                                        </th>
                                                        <td colSpan={3} >
                                                            {this.state.demo_date[0].Kokyaku_Name}
                                                        </td>

                                                    </tr>
                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >連絡先</th>
                                                        <td colSpan={3} >
                                                            {this.state.demo_date[0].Renraku_Saki}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >連絡希望時間</th>
                                                        <td colSpan={3} >
                                                            {this.state.demo_date[0].Renraku_Kibou}
                                                        </td>
                                                    </tr>

                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >契約日</th>
                                                        <td colSpan={1} >
                                                            {this.state.demo_date[0].Keiyaku_Date ? moment(this.state.demo_date[0].Keiyaku_Date).utc().format('YYYY-MM-DD') : ""}
                                                        </td>

                                                        <th style={{ textAlign: "left" }} colSpan={1} >オーナー担当</th>
                                                        <td colSpan={1} >
                                                            {/* {this.state.demo_date[0].Shoyuusha_Tantou_Name} */}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table className="col-lg-6" style={{ textAlign: "left", border: "0px" }}>
                                                <tbody>
                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >
                                                            区分
                                                        </th>
                                                        <td colSpan={1} >
                                                            {this.state.demo_date[0].Tatemono_Name ? "建物" : "駐車場"}
                                                        </td>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >顧客区分</th>
                                                        <td colSpan={1} >
                                                            {this.state.demo_date[0].Kokyaku_Kbn}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >受電者</th>
                                                        <td colSpan={1} >
                                                            {this.state.demo_date[0].room_support_m_chat_id ?
                                                                <span className="label" style={{ backgroundColor: "orange" }}>
                                                                    アプリ
                                                                </span>
                                                                :
                                                                this.state.demo_date[0].Tenpo_Name_Short + " " + this.state.demo_date[0].shokai_user}

                                                        </td>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >対応担当</th>
                                                        <td colSpan={1} >
                                                            {this.state.demo_date[0].taiou_user}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >初回対応日</th>
                                                        <td colSpan={1} >
                                                            {this.state.demo_date[0].Syokai_Date ? moment(this.state.demo_date[0].Syokai_Date).tz("utc").format('YYYY-MM-DD') : ""}
                                                            <span style={{ marginLeft: 10 }}>
                                                                {this.state.demo_date[0].Shokai_H}
                                                                {this.state.demo_date[0].Shokai_H ? ":" : ""}
                                                                {this.state.demo_date[0].Shokai_M}
                                                            </span>
                                                        </td>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >初回手配業者</th>
                                                        <td colSpan={1} >
                                                            {this.state.demo_date[0].Gyosha_Name}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >次回対応予定</th>
                                                        <td colSpan={1} >
                                                            {this.state.demo_date[0].Next_Date ? moment(this.state.demo_date[0].Next_Date).tz("utc").format('YYYY-MM-DD') : ""}

                                                            <span style={{ marginLeft: 5 }}>
                                                                {this.state.demo_date[0].Next_Time ? this.state.demo_date[0].Next_Time : ""}
                                                            </span>
                                                        </td>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >次回手配業者</th>
                                                        <td colSpan={1} >
                                                            {this.state.demo_date[0].Gyosha2_Name}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >業者連絡可否</th>
                                                        <td colSpan={1} >
                                                            {this.state.demo_date[0].gyousha_renraku == 0 ? "未確認" : ""}
                                                            {this.state.demo_date[0].gyousha_renraku == 1 ? "可能" : ""}
                                                            {this.state.demo_date[0].gyousha_renraku == 2 ? "不可" : ""}
                                                            {this.state.demo_date[0].gyousha_renraku == 3 ? "報告不要" : ""}
                                                        </td>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >業者担当</th>
                                                        <td colSpan={1} >
                                                            {this.state.demo_date[0].gyousha_tantou}
                                                        </td>
                                                    </tr>

                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >対応状況</th>
                                                        <td colSpan={1} >
                                                            {this.state.demo_date[0].Outai_Joukyou_Name}
                                                        </td>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >対応完了日</th>
                                                        <td colSpan={1} >
                                                            {this.state.demo_date[0].complete_date ? moment(this.state.demo_date[0].complete_date).utc().format('YYYY-MM-DD') : ""}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Row>
                                    </CardContent>
                                </Card>
                                <Card style={{ marginTop: 3 }} className="col-lg-12">
                                    <CardContent style={{ float: "left" }} className="col-lg-12">


                                        <table style={{ textAlign: "left" }}>
                                            <tbody>
                                                <tr style={{ border: "0px" }}>
                                                    <th style={{ width: 100, textAlign: "left" }}>タイトル</th>
                                                    <td>
                                                        {this.state.demo_date[0].Title}
                                                    </td>
                                                    <th style={{ width: 100, textAlign: "left" }}>区分</th>
                                                    <td>

                                                        <span style={{ fontWeight: "bold", marginRight: 10 }}>
                                                            {this.state.demo_date[0].outai_sub_name}
                                                        </span>
                                                        <span>
                                                            {(this.props.request_detail.subkubun) ?
                                                                this.props.request_detail.subkubun.map((str: any) => {
                                                                    if (str.name == "設備") {
                                                                        return "入居時リクエスト(設備)" + "　"
                                                                    } else if (str.name == "人的") {
                                                                        return "入居時リクエスト(人的)" + "　"
                                                                    } else {
                                                                        return str.name + "　"
                                                                    }
                                                                }) : ' '
                                                            }
                                                        </span>
                                                    </td>

                                                    {this.props.kouji_list.kouji ?
                                                        <th rowSpan={2} style={{ width: 80, textAlign: "left" }} >工事情報</th> : ""}
                                                    {this.props.kouji_list.kouji ?
                                                        <td rowSpan={2} style={{ width: 100, textAlign: "left" }}>
                                                            <div>
                                                                <div>発注日：</div>
                                                                <div>
                                                                    {this.props.kouji_list.kouji[0].order_issue ? moment(this.props.kouji_list.kouji[0].order_issue).tz("utc").format('YYYY-MM-DD') : "-"}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div>完工日:</div>
                                                                <div>
                                                                    {this.props.kouji_list.kouji[0].completion_enter ? moment(this.props.kouji_list.kouji[0].completion_enter).tz("utc").format('YYYY-MM-DD') : "-"}
                                                                </div>
                                                            </div>
                                                        </td> : ""}
                                                </tr>
                                                <tr style={{ border: "0px" }}>
                                                    <th style={{ textAlign: "left" }} >内容</th>
                                                    <td>
                                                        {this.state.demo_date[0].Naiyou.split("\n").map((i: any, key: any) => {
                                                            return <div key={key}>{i}</div>;
                                                        })}

                                                    </td>
                                                    <th style={{ textAlign: "left" }} >備考</th>
                                                    <td>
                                                        {this.state.demo_date[0].remarks}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>


                                    </CardContent>
                                </Card>
                                <Card style={{ marginTop: 3 }} className="col-lg-12">
                                    <CardContent style={{ float: "left" }} className="col-lg-12">
                                        <Button onClick={this.reqtoggle} color="primary" variant="outlined">
                                            <i className="fas fa-plus"></i>　追加</Button>


                                        <span style={{ fontSize: 15 }}>
                                            <span>
                                                <span style={{ marginLeft: 50, color: "#f0e68c" }}>■</span> 予定
                                            </span>

                                            <span>
                                                <span style={{ marginLeft: 20, color: "#ee82ee" }}>■</span> 訪問依頼
                                            </span>
                                        </span>

                                        <Button style={{ float: "right", marginBottom: "10px", }} onClick={this.ikkatsutoggle} color="primary" variant="contained">
                                            <i className="fas fa-eye"></i>　一括表示</Button>


                                        <DetailListGrid demo_date={this.state.demo_date} ipad_height={this.state.ipad_height} />
                                    </CardContent>
                                </Card>

                            </Card> : ""}



                        {this.state.responseTabs === "k2" ? <Card className="col-lg-12" square={true} style={{ background: "#b0c4de", padding: 5 }}>
                            <Card style={{ marginTop: 10 }} className="col-lg-12">
                                <CardContent style={{ float: "left" }} className="col-lg-12">
                                    <KeiyakuDetail />
                                </CardContent>
                            </Card>
                        </Card> : ""}

                        {this.state.responseTabs === "k3" ? <Card className="col-lg-12" square={true} style={{ background: "#b0c4de", padding: 5 }}>
                            <Card style={{ marginTop: 10 }} className="col-lg-12">
                                <CardContent style={{ float: "left" }} className="col-lg-12">
                                    <PastRequest
                                        tabreset={this.tabreset} />
                                </CardContent>
                            </Card>
                        </Card> : ""}

                        {this.state.responseTabs === "k4" ? <Card className="col-lg-12" square={true} style={{ background: "#b0c4de", padding: 5 }}>
                            <Card style={{ marginTop: 10 }} className="col-lg-12">
                                <CardContent style={{ float: "left" }} className="col-lg-12">
                                    <SmsGrid
                                        tabreset={this.tabreset} />
                                </CardContent>
                            </Card>
                        </Card> : ""}

                    </Paper>




                    {
                        this.state.demo_date ?
                            < Modal isOpen={this.state.reqtoggle} toggle={this.reqtoggle} size="kokyaku" fade={false} backdrop={false}>
                                <DialogTitle id="customized-dialog-title" onClose={this.reqtoggle} >応対追加</DialogTitle>

                                <NewOutai
                                    OutaiShosai_No={this.state.demo_date.length}
                                    Outai_No={this.state.demo_date[0].Outai_No}
                                    reqtoggle={this.reqtoggle} />
                                <ModalFooter>
                                </ModalFooter>
                            </Modal > : ""
                    }

                    {
                        this.state.demo_date ?
                            < Modal isOpen={this.state.edittoggle} toggle={this.edittoggle} size="request" fade={false} backdrop={false}>
                                <DialogTitle id="customized-dialog-title" onClose={this.edittoggle} >リクエスト編集</DialogTitle>

                                <EditRequest
                                    demo_date={this.state.demo_date}
                                    edit_after_toggle={this.edit_after_toggle}
                                    edittoggle={this.edittoggle}
                                    Outai_No={this.state.demo_date[0].Outai_No} />
                                <ModalFooter>
                                </ModalFooter>
                            </Modal > : ""
                    }

                    {
                        this.state.demo_date ?
                            < Modal isOpen={this.state.ikkatsutoggle} toggle={this.ikkatsutoggle} size="kokyaku" fade={false}>
                                <DialogTitle id="customized-dialog-title" onClose={this.ikkatsutoggle} >一括表示</DialogTitle>

                                <IkkatsuModal demo_date={this.state.demo_date} />
                                <ModalFooter>
                                    <Button size="small" onClick={this.ikkatsutoggle} color="secondary" variant="contained">
                                        <i className="fas fa-door-closed"></i>閉じる
                                    </Button>
                                </ModalFooter>
                            </Modal > : ""
                    }

                    {
                        this.state.demo_date ?
                            < Modal isOpen={this.state.smstoggle} toggle={this.smstoggle} size="kokyaku" fade={false}>
                                <DialogTitle id="customized-dialog-title" onClose={this.smstoggle} >SMS</DialogTitle>
                                <SmsModal
                                    Outai_No={this.state.demo_date[0].Outai_No}
                                    smstoggle={this.smstoggle}
                                />
                                <ModalFooter>
                                </ModalFooter>
                            </Modal > : ""
                    }

                    {
                        this.state.demo_date ?
                            < Modal isOpen={this.state.popup} toggle={this.popup} size="kokyaku" fade={false}>
                                <Card style={{ marginTop: 10 }} className="col-lg-12">
                                    <CardContent style={{ float: "left" }} className="col-lg-12">
                                        <TextField
                                            id="outlined-read-only-input"
                                            label="案件内容"
                                            fullWidth
                                            multiline
                                            defaultValue={this.state.demo_date[0].Naiyou}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                            style={{ marginTop: "10px" }}
                                        />
                                    </CardContent>
                                </Card>
                            </Modal > : ""
                    }


                    {
                        this.state.demo_date ?
                            < Modal isOpen={this.state.KanrenKoujitoggle} toggle={this.KanrenKoujitoggle} size="kokyaku" fade={false}>
                                <DialogTitle id="customized-dialog-title" onClose={this.KanrenKoujitoggle} >関連工事/伝票</DialogTitle>
                                <KanrenKoujiModal />
                                <ModalFooter>
                                </ModalFooter>
                            </Modal > : ""
                    }

                    {
                        this.state.demo_date ?
                            < Modal isOpen={this.state.Completetoggle} toggle={this.Completetoggle} size="xs" fade={false}>
                                <DialogTitle id="customized-dialog-title" onClose={this.Completetoggle} >対応状況の変更</DialogTitle>
                                <CompleteModal demo_date={this.state.demo_date} kanryou={this.kanryou} />
                                <ModalFooter>
                                </ModalFooter>
                            </Modal > : ""
                    }

                    {
                        this.state.demo_date && this.props.request_detail.m_customers ?
                            < Modal isOpen={this.state.newchattoggle} toggle={this.newchattoggle} size="kokyaku" fade={false}>
                                <DialogTitle id="customized-dialog-title" onClose={this.newchattoggle} >チャット作成</DialogTitle>
                                <NewChatModal
                                    chat_plus_update={this.chat_plus_update}
                                    room_support_m_chat_id={this.state.demo_date[0].room_support_m_chat_id}
                                    disp_reset={this.disp_reset}
                                    Outai_No={this.state.demo_date[0].Outai_No}
                                    newchattoggle={this.newchattoggle}
                                    room_support_m_user_id={this.state.demo_date[0].room_support_m_user_id}
                                />
                                <ModalFooter>
                                </ModalFooter>
                            </Modal > : ""
                    }

                </div >
            );
        } else {
            return (
                <div>
                    <i style={{ fontSize: 26, textAlign: "center" }} className="fas fa-spinner"></i>
                </div>
            )
        }
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        ky: state.KyDetail.ky,
        kys_biko: state.kys.kys_biko,
        request_detail: state.Adjustment.request_detail,
        user_details: state.masterReducer.user_details,
        kouji_list: state.RequestDetail.kouji_list,
        new_chat_id: state.chat.new_chat_id,
        ikkatsu_file: state.RequestDetail.ikkatsu_file,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        change_mail_new(state: any) {
            dispatch(Mail.change_mail_new(state))
        },
        get_response_lines(state: any) {
            dispatch(Line.get_response_lines(state))
        },
        get_chats(state: any) {
            dispatch(Chat.get_chats(state))
        },
        get_smses(state: any) {
            dispatch(Sms.get_smses(state))
        },
        get_kys(state: number) {
            dispatch(KysSaga.get_kys(state))
        },
        get_request_detail(state: any) {
            dispatch(Adjustment.get_request_detail(state))
        },
        get_tatemono(state: any) {
            dispatch(Adjustment.get_tatemono(state))
        },
        get_room(state: any) {
            dispatch(Adjustment.get_room(state))
        },
        get_gyousya(state: any) {
            dispatch(Adjustment.get_gyousya(state))
        },
        get_outaisub(state: any) {
            dispatch(Adjustment.get_outaisub(state))
        },
        get_past_request(state: any) {
            dispatch(RequestDetailSaga.get_past_request(state))
        },
        get_keiyaku(state: any) {
            dispatch(RequestDetailSaga.get_keiyaku(state))
        },
        outai_delete(state: any) {
            dispatch(RequestDetailSaga.outai_delete(state))
        },
        outai_complete(state: any) {
            dispatch(RequestDetailSaga.outai_complete(state))
        },
        chat_kanryou(state: any) {
            dispatch(RequestDetailSaga.chat_kanryou(state))
        },
        chat_complet(state: any) {
            dispatch(RequestDetailSaga.chat_complet(state))
        },
        get_kouji(state: any) {
            dispatch(RequestDetailSaga.get_kouji(state))
        },
        get_request_excels(state: any) {
            dispatch(RequestDetailSaga.get_request_excels(state))
        },
        get_parking(state: any) {
            dispatch(Adjustment.get_parking(state))
        },
        get_parkingarea(state: any) {
            dispatch(Adjustment.get_parkingarea(state))
        },
        get_chatlist(state: any) {
            dispatch(Keiyaku.get_chatlist(state))
        },
        get_sms_share(state: any) {
            dispatch(Sms.get_sms_share(state))
        },
        set_process_flag(state: any) {
            dispatch(Chat.set_process_flag(state))
        },
        set_Outai_No(state: any) {
            dispatch(Chat.set_Outai_No(state))
        },
        get_template_category_list(state: any) {
            dispatch(Keiyaku.get_template_category_list(state))
        },
        importance(state: any) {
            dispatch(Adjustment.importance(state))
        },
        get_kouji_list(state: any) {
            dispatch(RequestDetailSaga.get_kouji_list(state))
        },
        new_chat(state: any) {
            dispatch(Chat.new_chat(state))
        },
        chat_plus(state: any) {
            dispatch(RequestDetailSaga.chat_plus(state))
        },
        get_file(state: any) {
            dispatch(RequestDetailSaga.get_file(state))
        },
        get_sms(state: any) {
            dispatch(RequestDetailSaga.get_sms(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));




