import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from "./Grid";

import './Css/Modal.css';
// import HeaderGrid from "./HeaderGrid";
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import moment from 'moment'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Search from "./Search";
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
import * as RentMoney from './Saga/RentMoney';
import KyDetails from '../KyDetail/Index';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { runInThisContext } from 'vm';
interface Props {
    // customerNewDispSet: any,
    // customerNewDisp:number,
    // customerno:number,
    // customerList:any,

    // get_rent_contract_accounting:any,
    // get_remittance_details: any,
    get_rent_moneies: any,
    // search_detail: any,
    // classes: any,
    // search_loading: boolean,
    get_rent_money_bklist: any,
    // bklist: any,
    // addky: any,
    // delky: any,

}



interface State {
    kytoggle: boolean
    // state types
}


const styles = ((theme: any) => ({

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}))



class CustomerList extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        // this.props.change_search_name("")
        this.state = {
            kytoggle: false
        }

    }


    componentWillMount() {
        // this.props.get_owner_remittance_check_logs()
        // this.props.get_remittance_details()

        this.props.get_rent_money_bklist()
        // this.props.get_rent_moneies()
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);

        this.kytoggle = this.kytoggle.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state: any) {
        console.log(state)

        // this.props.get_remittance_details(state)
    }
    kytoggle() {
        this.setState({ kytoggle: !this.state.kytoggle })
        // this.props.get_rent_contract_accounting(this.props.kyousais);
    }
    csv() {

        // this.props.get_rent_contract_accounting(this.props.kyousais);
    }
    render() {

        return (
            <div >
                <div className="item-outer col-lg-12"

                //  style={{ display: (this.props.customerNewDisp === 0) ? '' : 'none' }}
                >
                    {/* {this.props.search_detail.contractor}   {this.props.search_detail.tatemono_name + this.props.search_detail.room_name}　 {moment(this.props.search_detail.start_at).format("YYYY/MM/DD")}～{moment(this.props.search_detail.end_at).format("YYYY/MM/DD")}
                    {this.props.search_detail.corse} */}

                    <Card >
                        <CardContent>
                            <Search />
                        </CardContent>
                    </Card>

                </div>


                <Card className="col-lg-12">
                    <CardContent style={{ float: "left" }} className="col-lg-12">
                        {/* {this.props.addky.map(function (value: any) {
                            return value.hy_no + " " + value.kys_name
                        })}

                        {this.props.delky.map(function (value: any) {
                            return value.hy_no + " " + value.kys_name
                        })} */}
                        <Grid kytoggle={this.kytoggle} />
                    </CardContent>
                </Card>

                {/* <Backdrop className={this.props.classes.backdrop} open={this.props.search_loading} >
                    <CircularProgress color="inherit" />
                </Backdrop> */}
                <Modal isOpen={this.state.kytoggle} toggle={this.kytoggle} size="lg" fade={false}>

                    <ModalHeader toggle={this.kytoggle}>契約情報</ModalHeader>
                    {/* <ModalBody> */}
                    <KyDetails />
                    {/* </ModalBody> */}
                    <ModalFooter>
                        {/* <button className="btn btn btn-danger" style={{margin:"5px"}} onClick={this.delete_introductions}>削除する</button>*/}
                        {/* <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={() => this.props.change_search_modal(false)}>キャンセル</button> */}
                    </ModalFooter>
                </Modal >

            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // search_loading: state.ContractCheck.search_loading,
        // minories: state.Minori.minories,
        // bklist: state.RemittanceDetail.bklist,
        // addky: state.RemittanceDetail.addky,
        // delky: state.RemittanceDetail.delky,
        // search: state.RemittanceDetail.search,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_rent_money_bklist(state: any) {
            dispatch(RentMoney.get_rent_money_bklist(state))
        },
        get_rent_moneies(state: number) {
            dispatch(RentMoney.get_rent_moneies(state))
        },
        // get_remittance_details(state: any) {
        //     dispatch(RentMoney.get_remittance_details(state))
        // },
        // get_owner_remittance_check_logs(state: any) {
        //     dispatch(RemittanceLogCheck.get_owner_remittance_check_logs(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




