import authentication from 'react-azure-adb2c'
import * as firebase from 'firebase';

import firebaseConfig from '../firebase-config';
import firebaseSmsConfig from '../firebase-sms-config';
import firebaseRoomsupportConfig from '../firebase-roomsupport-config';


export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const lineDB = firebaseApp.firestore();
// export const firebaseDb = firebaseApp.database().ref();
const firebaseRoomSupportApp = firebase.initializeApp(firebaseRoomsupportConfig, "roomsupport");
export const roomsupportDB = firebaseRoomSupportApp.firestore();
const firebaseSmsApp = firebase.initializeApp(firebaseSmsConfig, "sms");
export const smsDB = firebaseSmsApp.firestore();





// export const firebaseSmsDb = firebaseSmsApp.database().ref();
// Subscribe


// Subscribe
//export function loadTodos() {
//    return dispatch => {
//        ref.off()
//        ref.on('child_added',
//            (snapshot) => { dispatch(loadTodosSuccess(snapshot)) },

//        )
//    }
//}


//function loadTodosSuccess(snapshot) {

//    return {
//        type: 'TODOS_RECEIVE_DATA',
//        data: snapshot.val()
//    }
//}

//function loadTodosError(error) {
//    return {
//        type: 'TODOS_RECIVE_ERROR',
//        message: error.message
//    }
//}


//const firebasetokenset = (token) => ({
//    type: 'FIREBASE_TOKEN',
//    payload: {
//        token
//    }
//});


export const firebaseSet = (token) => ({
    type: 'FIREBASE_SET',
    payload: {
        token
    }
});

export const firebaseToken = () => {
    return async dispatch => {

        const url = process.env.REACT_APP_API_BASE_URL + `/firebase/token`;
        const token = authentication.getAccessToken();

        const response = await fetch(url, {
            headers: { 'Authorization': 'Bearer ' + token }
        });

        const firebasetoken = await response.json();
        // alert(firebasetoken)

        firebase.auth(firebaseApp).onAuthStateChanged((user) => {


            firebase.auth(firebaseApp).signInWithCustomToken(firebasetoken).then(
                function (e) {
                    console.log('success');
                    // alert("linesuccess")
                }, function (e) {

                    console.log('failer');
                    console.log(e);
                    // this.disabled = false;
                });

        });

        dispatch({
            type: 'FIREBASE_TOKEN',
            payload: {
                firebasetoken
            }
        });





    };

};









export const firebaseSmsToken = () => {
    return async dispatch => {


        const url = process.env.REACT_APP_API_PM_URL + `/v1/sms/firestoretoken`;
        // const url = `http://localhost:5000/v1/sms/firestoretoken`;
        const token = authentication.getAccessToken();

        const response = await fetch(url, {
            headers: { 'Authorization': 'Bearer ' + token }
        });

        const firebasetokensms = await response.json();
        // alert(firebasetokensms.token)

        firebase.auth(firebaseSmsApp).onAuthStateChanged((user) => {

            // console.log(user)
            // alert(user.uid)
            firebase.auth(firebaseSmsApp).signInWithCustomToken(firebasetokensms.token).then(
                function (e) {
                    console.log('success');
                    console.log(e);
                    // alert('successsms')

                }, function (e) {
                    alert(e)
                    console.log('failer');
                    console.log(e);
                    // this.disabled = false;
                });

        });

        let firebasesmstoken = firebasetokensms.token
        dispatch({
            type: 'FIREBASE_SMS_TOKEN',
            payload: {
                firebasesmstoken
            }
        });
    };

};




export const firebaseRoomSupportToken = () => {
    return async dispatch => {


        const url = process.env.REACT_APP_API_PM_URL + `/v1/room/support/chats/firestoretoken`;
        // const url = `http://localhost:5000/v1/room/support/chats/firestoretoken`;
        const token = authentication.getAccessToken();

        const response = await fetch(url, {
            headers: { 'Authorization': 'Bearer ' + token }
        });

        const firebasetokenroomsupport = await response.json();
        // alert(firebasetokenroomsupport.token)





        firebase.auth(firebaseRoomSupportApp).onAuthStateChanged((user) => {


            firebase.auth(firebaseRoomSupportApp).signInWithCustomToken(firebasetokenroomsupport.token).then(
                function (e) {
                    console.log('successsupo');
                    console.log(e);
                    // alert('successsupo')

                }, function (e) {
                    alert(e)
                    console.log('failer');
                    console.log(e);
                    // this.disabled = false;
                });

        });
        let firebaseroomsupporttoken = firebasetokenroomsupport.token

        dispatch({
            type: 'FIREBASE_ROOMSUPPORT_TOKEN',
            payload: {
                firebaseroomsupporttoken
            }
        });
    };

};



//reducers

const initialState = {

    firetoken: '',
    firebasedata: []
}


export default function firebaseReducer(state = initialState, action) {
    switch (action.type) {
        case 'FIREBASE_TOKEN':
            return {
                ...state,
                firebasetoken: action.payload.firebasetoken
            };
        case 'FIREBASE_SET':
            return {
                ...state,
                firebaseset: action.payload.token
            };
        case 'TODOS_RECEIVE_DATA':
            return {
                ...state,
                firebasedata: action.data.date
            };

        default:
            return state;
    }
}
