import React, { Component } from 'react';

import { connect } from 'react-redux';

import ja from "date-fns/locale/ja";
import MaterialSelect from '@material-ui/core/Select';
import Select from 'react-select';
import Chip from '@material-ui/core/Chip';
import "react-datepicker/dist/react-datepicker.css";
// import ContactsComponent from '../CustomerContacts/ContactsComponent';
// import Loading from '../Layout/LoadingComponent';
import { Field, reduxForm, submit } from 'redux-form';
// import '../../Css/Table.css';
import { Button, ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
//import ResponseAddComponent from './ResponseAddComponent';
import { withRouter } from 'react-router';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Calendarsaga from './Saga/Calendar';

// import Grid from '@material-ui/core/Grid';


import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import DateFnsUtils from '@date-io/date-fns';
import InputAdornment from '@material-ui/core/InputAdornment';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
// import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,

} from '@material-ui/pickers';


// const selectStyles = {
//   menu: base => ({
//     ...base,
//     zIndex: 0
//   })
// };


// const RenderSelectInput = ({ input, options, name, id }) => (
//   <Select
//     {...input}
//     id={id}
//     name={name}
//     options={options}
//     value={input.value}
//     onChange={(a) => input.onChange(a ? a.value : 0)}
//     onBlur={(a) => input.onBlur(a ? a.value : 0)}

//     placeholder="店舗を選択"
//   />
// )

// const RenderSelectUsers = ({ input, options, name, id }) => (
//   <Select
//     {...input}
//     id={id}
//     name={name}
//     options={options}
//     value={input.value}
//     onChange={(a) => input.onChange(a ? a.value : 0)}
//     onBlur={(a) => input.onBlur(a ? a.value : 0)}

//     placeholder="追客担当を選択"
//   />
// )



class ResponseListSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalselect: false,
      modalcalender: false,

    };

    let formData = {
      email: '',
      password: '',
      tatemonoName: '',
      applicationDate: null,
      contractionDateStart: null,
      contractionDateEnd: null,
      condition: "1",
      primaryUserId: 0,
      name: null,
      sectionId: this.props.user_details.amSectionId > 0 ? this.props.user_details.amSectionId : 0,
    }



    this.handleChangeSectionId = this.handleChangeSectionId.bind(this);
    this.handlePrimaryUserIdChange = this.handlePrimaryUserIdChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeTatemono = this.onChangeTatemono.bind(this);

  }


  handleChangeSectionId(e, newValue) {
    console.log(newValue)
    // alert(newValue)
    if (newValue) {
      let calendar_list_form = this.props.calendar_list_form
      calendar_list_form.section_id = newValue.value
      this.props.change_calendar_list_form(calendar_list_form);
    } else {
      let calendar_list_form = this.props.calendar_list_form
      calendar_list_form.section_id = 0

      this.props.change_calendar_list_form(calendar_list_form);
    }
    this.props.get_calendar_list();

  }

  handleChange = (event) => {
    console.log(event)
    alert()
    let formData = this.props.responselist.formData
    formData[event.target.name] = event.target.value;
    if (event.target.name === "condition") {
      formData.contractionDateStart = new Date();
      formData.contractionDateEnd = null;
    }
    // this.props.change_formData(formData);
    if (event.target.name === "condition") {


    }
    // this.setState({ formData });
  }
  componentDidMount() {
    //  alert(this.props.masterReducer.user_details.amSectionId)
    //  console.log(this.props.masterReducer)
    //  alert("ddd")


  }



  // handleSectionIdChange(sectionId) {
  //   alert()
  //   let formData =   this.state.formData
  //   formData.sectionId=sectionId
  //     this.setState(formData);

  //   }
  handlePrimaryUserIdChange(primaryUserId) {
    if (primaryUserId) {
      let formData = this.props.responselist.formData
      formData.primaryUserId = primaryUserId.value
      this.setState(formData);
    } else {
      let formData = this.props.responselist.formData
      formData.primaryUserId = 0
      // this.props.change_formData(formData);
    }
  };
  handleContractionDateStartChange = date => {
    let formData = this.props.responselist.formData
    formData.contractionDateStart = date
    // this.props.change_formData(formData);

  };

  handleContractionDateEndChange = date => {
    let formData = this.props.responselist.formData
    formData.contractionDateEnd = date
    // this.props.change_formData(formData);

  };

  onChangeTatemono(e, value) {
    // alert(value.Tatemono_Id)
    let calendar_form = this.props.calendar_form
    calendar_form.tatemono_id = value.Tatemono_Id
    this.props.change_calendar_form(calendar_form)
    // alert(value.Tatemono_Id)/
    this.props.get_calendar_list()

  }
  render() {



    return (
      <div>


        <div className="form-inline">

          <Autocomplete
            id="combo-box-demo"
            defaultValue={this.props.calendar_tatemono_list.find(v => v.Tatemono_Id == "100062")}
            options={this.props.calendar_tatemono_list}
            getOptionLabel={(option) => option.Tatemono_Name}
            style={{ width: 500, float: "left", marginTop: 12 }}
            onChange={this.onChangeTatemono}
            renderInput={(params) => <TextField {...params} label="建物検索" onChange={this.get_calendar_tatemono_list} value={this.state.tatemono_name} />}
          />

        </div>



      </div >
    );
  }
}




//<Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" fade={false}>
//    <ResponseInsertComponent
//        toggle={this.toggle} onSubmit={this.props.customerUpdate}
//    />
//</Modal>


const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,
    section_select: state.masterReducer.section_select,

    calendar_list: state.calendar.calendar_list,
    calendar_tatemono_list: state.calendar.calendar_tatemono_list,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_calendar_list(state) {
      dispatch(Calendarsaga.get_calendar_list(state))
    },
    get_calendar_tatemono_list(state) {
      dispatch(Calendarsaga.get_calendar_tatemono_list(state))
    },

    change_calendar_form(state) {
      dispatch(Calendarsaga.change_calendar_form(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseListSearch);

// ResponseListSearch = reduxForm({
//   form: 'formset', // a unique identifier for this form
//   onSubmit: submit
//   //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(ResponseListSearch)
