import React, { createContext, useState, useContext } from 'react'
import { Authentication } from '../../App'
import axios from "axios";
import Grid from '@mui/material/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Table, Column, Cell } from 'fixed-data-table-2';
import {
  Bar, Line, ComposedChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from "recharts";
function RoomGraph(props) {
  const authentication = useContext(Authentication);
  const rooms_data = props.rooms_data.graph
  // const [created_at2, setCreated_at2] = useState(new Date());
  // React.useEffect(() => {
  // }, []);


  return (
    <div>
      <ResponsiveContainer width="95%" height={200}>
        <ComposedChart width={750} height={300} data={rooms_data}>
          <XAxis dataKey="month" padding={{ right: 50, left: 50 }} />
          <YAxis yAxisId={1} label={{ value: "リクエスト件数", angle: -90, dx: -20 }} />

          <Tooltip />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar
            yAxisId={1}
            dataKey="ファミリー"
            stackId="a"
            barSize={50}
            fill="#FF8042"
          />
          <Bar yAxisId={1} dataKey="単身" stackId="a" fill="#FFBB28" />
          <Bar yAxisId={1} dataKey="その他" stackId="a" fill="#00C49F" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RoomGraph;
