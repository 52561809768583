import React from 'react';

import styled from 'styled-components';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const MenuLi = styled.li`
    display: inline-block;

    
`;

export default class NavMenu extends React.Component {
  constructor (props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle () {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }


  render () {

    const result = this.props.shops.filter(x => x.tenpoNo ===this.props.user_details.amSectionId);
   

//    alert(shops.tenpoName)
    return (
        <header>
            
            <div style={{textAlign:"right", position:"relative"}}>

            <a href={process.env.REACT_APP_HOMESTATION4_URL + "/manage/top/index"}>
                                <i className="icon-homestation navbar-brand" style={{lineHeight:"inherit"}}></i>
                            </a>
                    <nav>
                 
                   
                         
                      
                        <ul className="">
                       

                        <MenuLi >
ログイン中： {!result[0]? "" : result[0].tenpoName}　{this.props.user_details.userNameAll}
</MenuLi>


                            <MenuLi className="dropdown" >
                                <Dropdown direction="down" isOpen={this.state.dropdownOpenCog} toggle={() => { this.setState({ dropdownOpenCog: !this.state.dropdownOpenCog }); }}>
                                    <DropdownToggle
                                        tag="span"
                                        className="fas fa-cog navbar-menu"
                                        data-toggle="dropdown"
                                        aria-expanded={this.state.dropdownOpenCog}
                                    >
                                    </DropdownToggle>
                                    <DropdownMenu right>

                                        <DropdownItem href={process.env.REACT_APP_HOMESTATION4_URL + "/manage/login/logout"}>ログアウト</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </MenuLi>
                        </ul>
                    </nav>

                </div >





      </header>
    );
  }
}
 //<NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
              //  <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>

              //<NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>