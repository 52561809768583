import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import * as CustomerLink from './Saga/CustomerLink';
import Button from '@material-ui/core/Button';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import * as KizuCheckList from '../KizuCheckList/Saga/KizuCheckList';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const authentication = require('../../react-azure-adb2c2').default;

var moment = require('moment-timezone');

function createData(
    part: any,
    confirm_date: any,
    content: any,
    photo1: any,
    photo2: any,
) {
    return { part, confirm_date, content, photo1, photo2 };
}


interface Props {
    kizu_check_detail: any
}

interface State {
    photo_load_flag: boolean,
    bloburl: any,
    bloburl_detail: any,
}

class KizuDetail extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            photo_load_flag: false,
            bloburl: [],
            bloburl_detail: []
        }

        this.onPress = this.onPress.bind(this);

    }




    onPress = (e: any, item: any, str: any) => {
        let isRight = e.nativeEvent.which === 3;
        if (isRight) {
            // 右クリック 

        } else {
            // 左クリック
            const token = authentication.getAccessToken();
            var url = "";
            if (str == "image") {
                // url = `http://localhost:5000/v1/kizu/check/sheet/img/` + item.id;
                url = process.env.REACT_APP_API_PM_URL + `/v1/kizu/check/sheet/img/` + item.id;
            } else {
                // url = `http://localhost:5000/v1/kizu/check/sheet/imgdetail/` + item.id;
                url = process.env.REACT_APP_API_PM_URL + `/v1/kizu/check/sheet/imgdetail/` + item.id;
            }

            fetch(url, {
                method: 'GET',
                headers: { 'Authorization': 'Bearer ' + token }
            }).then(response => response.blob()).then(images => {
                const a = document.createElement('a');
                a.download = item.id + ".jpg";
                a.href = URL.createObjectURL(images);
                a.click();
            });
        }
    };



    render() {

        if (this.state.photo_load_flag === false) {
            this.props.kizu_check_detail.forEach((e: any) => {

                console.log("=))(I)==========)=")
                console.log(e);

                const token = authentication.getAccessToken();
                // const url = `http://localhost:5000/v1/kizu/check/sheet/img/` + e.id;
                const url = process.env.REACT_APP_API_PM_URL + `/v1/kizu/check/sheet/img/` + e.id;

                var bloburl
                fetch(url, {
                    method: 'GET',
                    headers: { 'Authorization': 'Bearer ' + token }
                }).then(response => response.blob()).then(images => {



                    bloburl = {
                        id: e.id,
                        url: URL.createObjectURL(images)
                    }
                    var blobs = this.state.bloburl;

                    if (blobs[e.index]) {

                    } else {
                        blobs[e.index] = [];
                    }
                    blobs[e.index] = bloburl;

                    this.setState({
                        bloburl: blobs
                    })

                });


                // const url2 = `http://localhost:5000/v1/kizu/check/sheet/imgdetail/` + e.id;
                const url2 = process.env.REACT_APP_API_PM_URL + `/v1/kizu/check/sheet/imgdetail/` + e.id;

                var bloburl2
                fetch(url2, {
                    method: 'GET',
                    headers: { 'Authorization': 'Bearer ' + token }
                }).then(response => response.blob()).then(images2 => {



                    bloburl2 = {
                        id: e.id,
                        url: URL.createObjectURL(images2)
                    }
                    var blobs2 = this.state.bloburl_detail;

                    if (blobs2[e.index]) {

                    } else {
                        blobs2[e.index] = [];
                    }
                    blobs2[e.index] = bloburl2;

                    this.setState({
                        bloburl_detail: blobs2
                    })

                });


                this.setState({
                    photo_load_flag: true
                });
            });
        }


        return (
            <div>
                <TableContainer>
                    <Table>
                        {this.props.kizu_check_detail.map((row: any, index: any) => (
                            <TableBody>
                                <TableRow
                                    style={{ borderTop: 'solid' }}
                                >
                                    <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                        傷汚れ箇所 {index + 1}
                                    </TableCell>
                                    <TableCell style={{ width: '35%', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                        {row.basho}
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{ width: '15%', fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                        傷汚れ確認日
                                    </TableCell>
                                    <TableCell style={{ width: '35%', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                        {row.check_at ? moment(row.check_at).tz("utc").format('YYYY-MM-DD') : ""}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                        傷汚れの内容
                                    </TableCell>
                                    <TableCell colSpan={3} style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                        {row.naiyou}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                        該当箇所の写真（全体）
                                    </TableCell>
                                    <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                                        <img src={this.state.bloburl[index] ? this.state.bloburl[index].url : ""} alt="該当箇所(全体)" width={"50%"} onMouseDown={(e) => this.onPress(e, this.state.bloburl[index], "image")} />
                                    </TableCell>
                                    <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                        該当箇所の写真（詳細）
                                    </TableCell>
                                    <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
                                        <img src={this.state.bloburl_detail[index] ? this.state.bloburl_detail[index].url : ""} alt="該当箇所(詳細)" width={"50%"} onMouseDown={(e) => this.onPress(e, this.state.bloburl_detail[index], "detail")} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ))}
                        {/* <TableRow style={{ borderTop: 'solid' }}>
                            <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                確認者備考<CreateIcon />
                            </TableCell>
                            <TableCell colSpan={3} style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                <TextField
                                    fullWidth
                                    // margin="normal"
                                    placeholder="気づいた点等あれば記入"
                                />
                            </TableCell>
                        </TableRow> */}
                    </Table>
                </TableContainer>
            </div >
        );
    }
};


const mapStateToProps = (state: any) => {
    return {
        kizu_check_detail: state.KizuCheckList.kizu_check_detail,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(KizuDetail);




