import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Adjustment from './Saga/Adjustment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from "date-fns/locale/ja";
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Dropzone from 'react-dropzone';
import './Css/Adjustment.css';
import { format } from 'date-fns'
import { Row, Col } from 'reactstrap';
import moment from 'moment'

const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');



interface Props {
    get_tatemono: any,
    get_room: any,
    get_parking: any,
    get_parkingarea: any,
    Tatemono: any,
    Room: any,
    Parking: any,
    Parkingarea: any,
    shops: any,
    users_select: any,
    Gyousya: any,
    Outaisub: any,
    new_request: any,
    reqtoggle: any,
    file_upload: any,
    get_customers: any,
    customers: any,
    user_details: any,
    new_outai_no: any,
    req_search: any,
    get_kaketsuke_kojin: any,
    kaketsuke_kojin: any,
    Syokai_Date: any,
    Shokai_H: any,
    Shokai_M: any
}
interface State {
    checked: boolean,
    checked1: boolean,
    checked2: boolean,
    checked3: boolean,
    checked4: boolean,
    checked5: boolean,
    checked6: boolean,
    checked7: boolean,
    checked8: boolean,
    checked9: boolean,
    checked10: boolean,
    checked11: boolean,
    checked12: boolean,
    checked13: boolean,
    checked14: boolean,
    checked15: boolean,
    checked16: boolean,
    checked17: boolean,
    checked18: boolean,
    checked19: boolean,
    checked20: boolean,
    checked21: boolean,
    checked22: boolean,
    checked23: boolean,
    checked24: boolean,
    checked25: boolean,
    checked26: boolean,
    checked27: boolean,
    checked28: boolean,
    checked29: boolean,
    checked30: boolean,
    checked31: boolean,
    checked32: boolean,
    checked33: boolean,
    checked34: boolean,
    checked35: boolean,
    checked36: boolean,
    checked37: boolean,
    checked38: boolean,
    checked39: boolean,
    checked40: boolean,
    checked41: boolean,
    checked42: boolean,
    checked43: boolean,
    checked44: boolean,
    checked45: boolean,
    checked46: boolean,
    checked47: boolean,
    checked48: boolean,
    checked49: boolean,
    checked50: boolean,
    checked51: boolean,
    checked52: boolean,
    checked53: boolean,
    checked54: boolean,
    checked55: boolean,
    checked56: boolean,
    checked57: boolean,
    checked58: boolean,
    checked59: boolean,
    checked60: boolean,
    checked61: boolean,
    checked62: boolean,
    checked63: boolean,
    checked64: boolean,
    checked65: boolean,
    checked66: boolean,
    checked67: boolean,
    checked68: boolean,
    Tatemono_Id: any,
    Room_Id: any,
    Kokyaku_Name: any,
    Renraku_Saki: any,
    Renraku_Kibou: any,
    gyousha_renraku: any,
    Outai_Joukyou_Name: any,
    Tatemono_or_P: any,
    Kokyaku_Kbn: any,
    Shokai_section_id: any,
    Shokai_User_Id: any,
    taiou_tantou_user_id: any,
    Syokai_Date: any,
    Shokai_H: any,
    Shokai_M: any,
    syokai_gyousha_no: any,
    second_gyousha_no: any,
    Title: any,
    Naiyou: any,
    remarks: any,
    OutaiDate: any,
    OutaiDate_H: any,
    OutaiDate_M: any,
    OutaiNaiyo: any,
    OutaiHoho_Name: any,
    OutaiHoho_Biko: any,
    OutaiTanto_Id: any,
    OutaiBiko: any,
    main_outai_sub: any,
    base64: any,
    name: any,
    tatemono_name: any,
    room_name: any,
    Keiyaku_No: any,
    Next_Date: any,
    shopname: any,
    type: any,
    first_taiou: boolean,
    second_date: any,
    filename: any,
    files: any,
    button_disp: boolean,
    check_count: any,
    symbol_name: any,
    symbol: any,
    room_disp: boolean,
    time_disp: boolean
}

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}

const kokyaku = [
    { label: "", key: "" },
    { label: "オーナー", key: "オーナー" },
    { label: "入居者", key: "入居者" },
    { label: "退去者", key: "退去者" },
    { label: "その他", key: "その他" },
];

var test = [
    { Tatemono_Name: "ああああ" },
    { Tatemono_Name: "おおおお" },
]

var taiou_joukyou = [
    { value: "応対中", key: "応対中" },
    { value: "完了", key: "完了" },
    { value: "一旦保留", key: "一旦保留" },
    { value: "業者対応完了", key: "業者対応完了" },
]

var outai_houhou = [
    { value: "電話" },
    { value: "FAX" },
    { value: "訪問" },
    { value: "来店" },
    { value: "通知文" },
    { value: "メール" },
    { value: "Teams" },
    { value: "傷汚れチェックシート受付" },
    { value: "その他" },
    { value: "駆け付け" },
    { value: "チャット" },
    { value: "LINE" },
    { value: "Smarep"},
]
var type = [
    { value: "電話", key: "電話" },
    { value: "メール", key: "メール" },
    { value: "UME", key: "UME" },
    { value: "来店", key: "来店" },
    { value: "シード", key: "シード" },
    { value: "駆付", key: "駆付" },
    { value: "留守電", key: "留守電" },
    { value: "アプリ", key: "アプリ" },
    { value: "Smarep", key: "Smarep" },
]


class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);

        props.get_tatemono();

        var shopname = "";
        props.shops.forEach((element: any) => {
            if (props.user_details.amSectionId == element.tenpoNo) {
                shopname = element.tenpoName;
            }
            console.log(shopname);
        });


        this.state = {
            shopname: shopname,
            checked: false,
            checked1: false,
            checked2: false,
            checked3: false,
            checked4: false,
            checked5: false,
            checked6: false,
            checked7: false,
            checked8: false,
            checked9: false,
            checked10: false,
            checked11: false,
            checked12: false,
            checked13: false,
            checked14: false,
            checked15: false,
            checked16: false,
            checked17: false,
            checked18: false,
            checked19: false,
            checked20: false,
            checked21: false,
            checked22: false,
            checked23: false,
            checked24: false,
            checked25: false,
            checked26: false,
            checked27: false,
            checked28: false,
            checked29: false,
            checked30: false,
            checked31: false,
            checked32: false,
            checked33: false,
            checked34: false,
            checked35: false,
            checked36: false,
            checked37: false,
            checked38: false,
            checked39: false,
            checked40: false,
            checked41: false,
            checked42: false,
            checked43: false,
            checked44: false,
            checked45: false,
            checked46: false,
            checked47: false,
            checked48: false,
            checked49: false,
            checked50: false,
            checked51: false,
            checked52: false,
            checked53: false,
            checked54: false,
            checked55: false,
            checked56: false,
            checked57: false,
            checked58: false,
            checked59: false,
            checked60: false,
            checked61: false,
            checked62: false,
            checked63: false,
            checked64: false,
            checked65: false,
            checked66: false,
            checked67: false,
            checked68: false,
            Tatemono_Id: null,
            Room_Id: null,
            Kokyaku_Name: "",
            Renraku_Saki: "",
            Renraku_Kibou: "",
            gyousha_renraku: 0,
            Outai_Joukyou_Name: "応対中",
            Tatemono_or_P: "建物",
            Kokyaku_Kbn: "入居者",
            Shokai_section_id: props.user_details.amSectionId,
            Shokai_User_Id: props.user_details.id,
            taiou_tantou_user_id: 0,
            Syokai_Date: this.props.Syokai_Date,
            Shokai_H: this.props.Shokai_H,
            Shokai_M: this.props.Shokai_M,
            syokai_gyousha_no: 0,
            second_gyousha_no: 0,
            Title: "",
            Naiyou: "",
            remarks: "",
            OutaiDate: new Date(),
            OutaiDate_H: 0,
            OutaiDate_M: 0,
            OutaiNaiyo: "",
            OutaiHoho_Name: "",
            OutaiHoho_Biko: "",
            OutaiTanto_Id: 0,
            OutaiBiko: "",
            main_outai_sub: 0,
            base64: "",
            name: "",
            tatemono_name: "",
            room_name: "",
            Keiyaku_No: "",
            Next_Date: null,
            type: "",
            first_taiou: false,
            second_date: null,
            filename: "",
            files: [],
            button_disp: true,
            check_count: 0,
            symbol_name: "",
            symbol: "",
            room_disp: true,
            time_disp: true
        }
        this.handleChange = this.handleChange.bind(this);
        this.onChangeTatemono = this.onChangeTatemono.bind(this);
        this.onChangeRoom = this.onChangeRoom.bind(this);
        this.onChangeKokyaku_Name = this.onChangeKokyaku_Name.bind(this);
        this.onChangeRenraku_Saki = this.onChangeRenraku_Saki.bind(this);
        this.onChangeRenraku_Kibou = this.onChangeRenraku_Kibou.bind(this);
        this.onChangegyousha_renraku = this.onChangegyousha_renraku.bind(this);
        this.onChangeOutai_Joukyou_Name = this.onChangeOutai_Joukyou_Name.bind(this);
        this.onChangeKokyaku_Kbn = this.onChangeKokyaku_Kbn.bind(this);
        this.onChangeTatemono_or_P = this.onChangeTatemono_or_P.bind(this);
        this.onChangeShokai_section_id = this.onChangeShokai_section_id.bind(this);
        this.onChangeShokai_User_Id = this.onChangeShokai_User_Id.bind(this);
        this.onChangetaiou_tantou_user_id = this.onChangetaiou_tantou_user_id.bind(this);
        this.onChangeShokai_H = this.onChangeShokai_H.bind(this);
        this.onChangeShokai_M = this.onChangeShokai_M.bind(this);
        this.onChangesyokai_gyousha_no = this.onChangesyokai_gyousha_no.bind(this);
        this.onChangesecond_gyousha_no = this.onChangesecond_gyousha_no.bind(this);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeNaiyou = this.onChangeNaiyou.bind(this);
        this.onChangeremarks = this.onChangeremarks.bind(this);
        this.onChangeOutaiTanto_Id = this.onChangeOutaiTanto_Id.bind(this);
        this.onChangeOutaiHoho_Name = this.onChangeOutaiHoho_Name.bind(this);
        this.onChangeOutaiHoho_Biko = this.onChangeOutaiHoho_Biko.bind(this);
        this.onChangeOutaiNaiyo = this.onChangeOutaiNaiyo.bind(this);
        this.onChangeOutaiBiko = this.onChangeOutaiBiko.bind(this);
        this.onChangemain_outai_sub = this.onChangemain_outai_sub.bind(this);
        this.save = this.save.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onChangeTatemonoName = this.onChangeTatemonoName.bind(this);
        this.onChangeNext_Date = this.onChangeNext_Date.bind(this);
        this.onChange_type = this.onChange_type.bind(this);
        this.second_date = this.second_date.bind(this);
        this.onChange_roomname = this.onChange_roomname.bind(this);
        this.shokai_delete = this.shokai_delete.bind(this);
        this.ima = this.ima.bind(this);

        this.first_taiou = this.first_taiou.bind(this);


    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });


        if (event.target.value !== '入居時リクエスト') {
            if (this.state.check_count == 0) {
                var subs = event.target.name.replace("checked", "")
                this.setState({
                    main_outai_sub: subs,
                    symbol_name: event.target.value,
                    symbol: ''
                });
            }
            if (event.target.checked == true) {
                this.setState({
                    check_count: this.state.check_count + 1
                });
            } else {
                this.setState({
                    check_count: this.state.check_count - 1
                });
            }
        }
    };

    onChangeTatemono(event: any, values: any) {

        if (values) {
            if (this.state.Tatemono_or_P == "建物") {
                console.log(values);

                this.props.get_room(values.Tatemono_Id);
                this.setState({
                    Tatemono_Id: values.Tatemono_Id,
                    Room_Id: null,
                    room_name: "",
                    room_disp: false
                });

                setTimeout(() => {
                    this.setState({
                        Kokyaku_Name: "",
                        Renraku_Saki: "",
                        Keiyaku_No: "",
                        room_name: "",
                        room_disp: true
                    });
                }, 1000)

            } else {
                console.log(values);
                this.props.get_parkingarea(values.P_Id);
                this.setState({
                    Tatemono_Id: "SysP" + values.P_Id,
                    Room_Id: null,
                    room_name: "",
                    room_disp: false
                });
                setTimeout(() => {
                    this.setState({
                        room_disp: true
                    });
                }, 1000)
            }
        } else {
            this.setState({
                Tatemono_Id: null,
                Room_Id: null,
                room_name: "",
                Kokyaku_Name: "",
                Renraku_Saki: "",
                Keiyaku_No: "",
                room_disp: false
            });
            setTimeout(() => {
                this.setState({
                    room_disp: true
                });
            }, 1000)
        }
    }

    onChangeRoom(event: any, values: any) {
        if (values) {
            this.setState({
                Room_Id: values.Room_Id,
            });

            var data = {
                tatemono_id: this.state.Tatemono_Id,
                room_id: values.Room_Id
            }
            this.props.get_customers(data);

            setTimeout(() => {



                console.log("-----------------");
                console.log(this.props.customers);
                if (this.props.customers) {


                    // 携帯の電話番号をハイフンつきにする
                    var str = this.props.customers.tel;
                    console.log("1")
                    console.log(str);
                    if (str == "") {

                        str = this.props.customers.tel1;
                        console.log("2")
                        console.log(str);
                    }
                    if (str == "") {

                        str = this.props.customers.tel2;
                        console.log("3")
                        console.log(str);
                    }

                    if (str) {
                        if (str.length == 11) {
                            const a = str.slice(0, 3)
                            const b = '-'
                            const c = str.slice(3)
                            var str2 = a + b + c;
                            const d = str2.slice(-4)
                            var str3 = str2.split("-");
                            var str4 = str3[1].replace(d, "");
                            var str5 = str3[0] + "-" + str4 + "-" + d
                        } else {
                            str5 = str;
                        }

                        this.setState({
                            Kokyaku_Name: this.props.customers.name,
                            Renraku_Saki: str5,
                            Keiyaku_No: this.props.customers.Keiyaku_No
                        });
                    } else {
                        this.setState({
                            Kokyaku_Name: this.props.customers.name,
                            Renraku_Saki: str,
                            Keiyaku_No: this.props.customers.Keiyaku_No
                        });
                    }

                    this.props.get_kaketsuke_kojin({
                        Tatemono_Id: this.state.Tatemono_Id,
                        Room_Id: values.Room_Id,
                        nyuukyosha_name: this.props.customers.name
                    });

                } else {
                    this.setState({
                        Kokyaku_Name: "",
                        Renraku_Saki: "",
                        Keiyaku_No: "",
                    });
                }
            }, 1000)

        } else {
            this.setState({
                Room_Id: null
            });
        }
    }

    onChangeKokyaku_Name(e: any) {
        this.setState({
            Kokyaku_Name: e.target.value,
        });
    }

    onChangeRenraku_Saki(e: any) {
        this.setState({
            Renraku_Saki: e.target.value,
        });
    }

    onChangeRenraku_Kibou(e: any) {
        this.setState({
            Renraku_Kibou: e.target.value,
        });
    }

    onChangegyousha_renraku(e: any) {
        this.setState({
            gyousha_renraku: e.target.value,
        });
    }

    onChangeOutai_Joukyou_Name(event: any, values: any) {
        this.setState({
            Outai_Joukyou_Name: values.value,
        });
    }

    onChange_type(event: any, values: any) {
        if (values) {
            this.setState({
                type: values.value,
            });
        } else {
            this.setState({
                type: "",
            });
        }
    }

    onChangeTatemono_or_P(e: any) {

        this.setState({
            Tatemono_or_P: e.target.value,
        });

        if (e.target.value == "駐車場") {

            // 駐車場の一覧を取る
            this.props.get_parking();

        } else {

            // 建物の一覧を取る
            this.props.get_tatemono();

        }
    }

    onChangeTatemonoName(e: any) {
        this.setState({
            tatemono_name: e.target.value,
            room_name: "",
            Room_Id: null
        });

        // if (this.state.tatemono_name !== "") {
        //     setTimeout(() => {
        //         this.props.get_tatemono();
        //     }, 1000)
        // }
    }

    onChange_roomname(e: any) {
        this.setState({
            room_name: e.target.value,
        });
    }

    onChangeKokyaku_Kbn(event: any, values: any) {
        if (values) {
            this.setState({
                Kokyaku_Kbn: values.label,
            });
        } else {
            this.setState({
                Kokyaku_Kbn: null,
            });
        }
    }


    onChangeShokai_section_id(event: any, values: any) {
        if (values) {
            this.setState({
                Shokai_section_id: values.tenpoNo,
            });
        }
    }

    onChangeShokai_User_Id(event: any, values: any) {
        if (values) {
            this.setState({
                Shokai_User_Id: values.value,
            });
        }
    }

    onChangetaiou_tantou_user_id(event: any, values: any) {
        if (values) {
            this.setState({
                taiou_tantou_user_id: values.value,
            });
        }
    }

    second_date = (date: any) => {
        this.setState({
            second_date: new Date(date),
        });
    };

    onChangeSyokai_Date = (date: any) => {
        this.setState({
            Syokai_Date: new Date(date),
            OutaiDate: new Date(date),
        });
    };

    onChangeShokai_H(e: any) {
        var time = e.target.value;
        if (e.target.value < 0) {
            time = 23;
        } else if (e.target.value > 23) {
            time = 0;
        }
        this.setState({
            Shokai_H: time,
            OutaiDate_H: time,
        });
    }

    onChangeShokai_M(e: any) {
        var time = e.target.value;
        if (e.target.value < 0) {
            time = 59;
        } else if (e.target.value > 59) {
            time = 0;
        }
        this.setState({
            Shokai_M: time,
            OutaiDate_M: time,
        });
    }

    shokai_delete() {
        this.setState({
            Syokai_Date: null,
            Shokai_M: null,
            Shokai_H: null,
            time_disp: false
        });
        setTimeout(() => {
            this.setState({
                time_disp: true
            });
        }, 300)
    }


    ima() {
        this.setState({
            Syokai_Date: new Date(),
            Shokai_H: moment().tz("Asia/Tokyo").format('HH'),
            Shokai_M: moment().tz("Asia/Tokyo").format('mm'),
            time_disp: false
        });
        setTimeout(() => {
            this.setState({
                time_disp: true
            });
        }, 300)
    }

    onChangeNext_Date = (date: any) => {
        this.setState({
            Next_Date: new Date(date)
        });
    };

    onChangesyokai_gyousha_no(event: any, values: any) {
        if (values) {
            this.setState({
                syokai_gyousha_no: values.Gyosha_No,
            });
        }
    }

    onChangesecond_gyousha_no(event: any, values: any) {
        if (values) {
            this.setState({
                second_gyousha_no: values.Gyosha_No,
            });
        }
    }


    onChangeTitle(e: any) {
        this.setState({
            Title: e.target.value,
        });
    }

    onChangeNaiyou(e: any) {
        this.setState({
            Naiyou: e.target.value,
        });
    }


    onChangeremarks(e: any) {
        this.setState({
            remarks: e.target.value,
        });
    }

    onChangeOutaiTanto_Id(event: any, values: any) {
        if (values) {
            this.setState({
                OutaiTanto_Id: values.value,
            });
        }
    }

    onChangeOutaiHoho_Name(event: any, values: any) {
        this.setState({
            OutaiHoho_Name: values.value,
        });
    }

    onChangeOutaiHoho_Biko(e: any) {
        this.setState({
            OutaiHoho_Biko: e.target.value,
        });
    }

    onChangeOutaiNaiyo(e: any) {
        this.setState({
            OutaiNaiyo: e.target.value,
        });
    }

    onChangeOutaiBiko(e: any) {
        this.setState({
            OutaiBiko: e.target.value,
        });
    }

    onChangemain_outai_sub(event: any, values: any) {
        if (values) {
            this.setState({
                main_outai_sub: values.id,
                symbol_name: values.name,
                symbol: ''
            });
        }
    }

    first_taiou() {
        this.setState({ first_taiou: !this.state.first_taiou })
    }



    save() {

        alert("※デモ画面のため、登録はされません");
        this.props.reqtoggle();
        return;

        this.setState({ button_disp: !this.state.button_disp })


        let checked = [{
            checked: this.state.checked1
        }, {
            checked: this.state.checked2
        }, {
            checked: this.state.checked3
        }, {
            checked: this.state.checked4
        }, {
            checked: this.state.checked5
        }, {
            checked: this.state.checked6
        }, {
            checked: this.state.checked7
        }, {
            checked: this.state.checked8
        }, {
            checked: this.state.checked9
        }, {
            checked: this.state.checked10
        }, {
            checked: this.state.checked11
        }, {
            checked: this.state.checked12
        }, {
            checked: this.state.checked13
        }, {
            checked: this.state.checked14
        }, {
            checked: this.state.checked15
        }, {
            checked: this.state.checked16
        }, {
            checked: this.state.checked17
        }, {
            checked: this.state.checked18
        }, {
            checked: this.state.checked19
        }, {
            checked: this.state.checked20
        }, {
            checked: this.state.checked21
        }, {
            checked: this.state.checked22
        }, {
            checked: this.state.checked23
        }, {
            checked: this.state.checked24
        }, {
            checked: this.state.checked25
        }, {
            checked: this.state.checked26
        }, {
            checked: this.state.checked27
        }, {
            checked: this.state.checked28
        }, {
            checked: this.state.checked29
        }, {
            checked: this.state.checked30
        }, {
            checked: this.state.checked31
        }, {
            checked: this.state.checked32
        }, {
            checked: this.state.checked33
        }, {
            checked: this.state.checked34
        }, {
            checked: this.state.checked35
        }, {
            checked: this.state.checked36
        }, {
            checked: this.state.checked37
        }, {
            checked: this.state.checked38
        }, {
            checked: this.state.checked39
        }, {
            checked: this.state.checked40
        }, {
            checked: this.state.checked41
        }, {
            checked: this.state.checked42
        }, {
            checked: this.state.checked43
        }, {
            checked: this.state.checked44
        }, {
            checked: this.state.checked45
        }, {
            checked: this.state.checked46
        }, {
            checked: this.state.checked47
        }, {
            checked: this.state.checked48
        }, {
            checked: this.state.checked49
        }, {
            checked: this.state.checked50
        }, {
            checked: this.state.checked51
        }, {
            checked: this.state.checked52
        }, {
            checked: this.state.checked53
        }, {
            checked: this.state.checked54
        }, {
            checked: this.state.checked55
        }, {
            checked: this.state.checked56
        }, {
            checked: this.state.checked57
        }, {
            checked: this.state.checked58
        }, {
            checked: this.state.checked59
        }, {
            checked: this.state.checked60
        }, {
            checked: this.state.checked61
        }, {
            checked: this.state.checked62
        }, {
            checked: this.state.checked63
        }, {
            checked: this.state.checked64
        }, {
            checked: this.state.checked65
        }, {
            checked: this.state.checked66
        }, {
            checked: this.state.checked67
        }];




        var checkers: number[] = [];

        var i = 1;
        checked.forEach(element => {
            if (element.checked == true) {
                checkers.push(i);
            }
            i++;
        });

        console.log(checkers);


        var Naiyo = this.state.OutaiNaiyo;
        if (this.state.first_taiou == false) {
            Naiyo = "初回対応登録なし"
        }

        var data =
        {
            new: {
                Kokyaku_Kbn: this.state.Kokyaku_Kbn,
                Kokyaku_Name: this.state.Kokyaku_Name,
                Renraku_Saki: this.state.Renraku_Saki,
                Renraku_Kibou: this.state.Renraku_Kibou,
                Shokai_User_Id: this.state.Shokai_User_Id,
                Syokai_Date: this.state.Syokai_Date,
                Shokai_H: this.state.Shokai_H,
                Shokai_M: this.state.Shokai_M,
                // Tatemono_Id: this.state.Tatemono_or_P == "建物" ? this.state.Tatemono_Id : "SysP" + this.state.Tatemono_Id,
                Room_Id: this.state.Tatemono_or_P == "建物" ? this.state.Room_Id : "SysP" + this.state.Room_Id,
                Tatemono_Id: this.state.Tatemono_or_P == "建物" ? this.state.Tatemono_Id : this.state.Tatemono_Id,
                // Room_Id: this.state.Tatemono_or_P == "建物" ? this.state.Room_Id : this.state.Room_Id,
                Outai_Joukyou_Name: this.state.Outai_Joukyou_Name,
                Title: this.state.Title,
                Naiyou: this.state.Naiyou,
                main_outai_sub: this.state.main_outai_sub,
                created_at: moment().tz("Asia/Tokyo").format('YYYY-MM-DD HH:mm:ss'),
                created_user_id: this.state.Shokai_User_Id,
                taiou_tantou_user_id: this.state.taiou_tantou_user_id,
                remarks: this.state.remarks,
                syokai_gyousha_no: this.state.syokai_gyousha_no,
                Shokai_section_id: this.state.Shokai_section_id,
                gyousha_renraku: this.state.gyousha_renraku,
                Keiyaku_No: this.state.Keiyaku_No,
                type: this.state.type,
                second_date: this.state.second_date,
                second_gyousha_no: this.state.second_gyousha_no
            },
            detail: {
                OutaiDate: this.state.Syokai_Date,
                OutaiDate_H: this.state.Shokai_H,
                OutaiDate_M: this.state.Shokai_M,
                Naiyo: Naiyo,
                OutaiHoho_Name: this.state.OutaiHoho_Name,
                OutaiHoho_Biko: this.state.OutaiHoho_Biko,
                Tanto_Id: this.state.OutaiTanto_Id,
                Biko: this.state.OutaiBiko
            },
            sub: {
                checkers
            }
        }
        this.props.new_request(data);
        setTimeout(() => {
            console.log("----------------------");
            console.log(this.props.new_outai_no.Outai_No);
            var file_no = 1;
            this.state.files.forEach((e: any) => {

                console.log(e);

                var updata = {
                    files: e,
                    file_no: file_no,
                    Outai_No: this.props.new_outai_no.Outai_No,
                    OutaiShosai_No: this.props.new_outai_no.OutaiShosai_No,
                    uploaded_user_id: this.props.user_details.id,
                }
                console.log(updata);
                // 添付ファイルをアップロード
                this.props.file_upload(updata);
                file_no = file_no + 1;

            });

            alert("登録しました");
            this.props.req_search();
            this.props.reqtoggle();
        }, 3000)


    }

    onDrop = (files: any) => {
        console.log(files);
        files.forEach((e: any) => {

            var statefiles = this.state.files;
            statefiles.push(e);

            var filenames = "";
            if (this.state.filename !== "") {
                filenames = this.state.filename + " , " + e.name;
            } else {
                filenames = e.name;
            }

            this.setState({
                filename: filenames,
                files: statefiles
            });

        });

    };

    render() {
        return (
            <div className="newrequest" style={{ padding: 10, background: "#b0c4de" }} >
                <div className="item-outer col-lg-12">
                    <Row>
                        <Card className="col-lg-6" square={true} style={{ padding: 5 }}>
                            <CardContent style={{ float: "left", padding: 10 }} className="col-lg-12">

                                <table className="col-lg-5" style={{ border: "0px", float: "left", marginRight: "50px" }}>

                                    {this.props.customers.Group_Naiyou_No ?
                                        <span className="label" style={{ float: "left", backgroundColor: "green" }}>
                                            かけつけ</span> :

                                        this.props.kaketsuke_kojin[0] ?

                                            this.props.kaketsuke_kojin[0]['keiyaku_type'] == 3 ?
                                                <span className="label" style={{ float: "left", backgroundColor: "#90ee90" }}>
                                                    シード？</span>
                                                :
                                                <span className="label" style={{ float: "left", backgroundColor: "green" }}>
                                                    かけつけ</span> : ""
                                    }

                                    {this.props.customers.room_support_m_user_id ?
                                        <span className="label" style={{ float: "left", backgroundColor: "orange" }}>
                                            契約者はアプリ登録済み</span> : ""
                                    }



                                    <tbody>
                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={2} style={{ border: "0px" }}>
                                                {this.state.Tatemono_or_P == "建物" ?
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={this.props.Tatemono}
                                                        getOptionLabel={(option: any) => option.Tatemono_Name + " (" + option.Tatemono_Kana + ")"}
                                                        style={{ width: 400, float: "left", marginTop: 12 }}
                                                        onChange={this.onChangeTatemono}
                                                        renderInput={(params) => <TextField
                                                            autoComplete='off' {...params} label="建物名 (カナ)" onChange={this.onChangeTatemonoName} value={this.state.tatemono_name} />}
                                                    /> :
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={this.props.Parking}
                                                        getOptionLabel={(option: any) => option.P_Name + " (" + option.P_Kana + ")"}
                                                        style={{ width: 400, float: "left", marginTop: 12 }}
                                                        onChange={this.onChangeTatemono}
                                                        renderInput={(params) => <TextField
                                                            autoComplete='off' {...params} label="駐車場名 (カナ)" />}
                                                    />}
                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            {this.state.room_disp ?
                                                <td colSpan={1} style={{ border: "0px" }}>
                                                    {this.state.Tatemono_or_P == "建物" ?
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={this.props.Room}
                                                            getOptionLabel={(option: any) => option.Room_Name}
                                                            style={{ width: 200, float: "left", marginTop: 12 }}
                                                            onChange={this.onChangeRoom}
                                                            renderInput={(params) => <TextField
                                                                autoComplete='off' {...params} label="部屋名" onChange={this.onChange_roomname} value={this.state.room_name} />}
                                                        /> :
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={this.props.Parkingarea}
                                                            getOptionLabel={(option: any) => option.Room_Name}
                                                            style={{ width: 200, float: "left", marginTop: 12 }}
                                                            onChange={this.onChangeRoom}
                                                            renderInput={(params) => <TextField
                                                                autoComplete='off' {...params} label="区画名" onChange={this.onChange_roomname} value={this.state.room_name} />}
                                                        />}
                                                </td> : ""}
                                        </tr>

                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={2} style={{ border: "0px" }}>
                                                <TextField
                                                    autoComplete='off' value={this.state.Kokyaku_Name} onChange={this.onChangeKokyaku_Name} style={{ width: 400 }} id="standard-basic" label="顧客名" />
                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={2} style={{ border: "0px" }}>
                                                <TextField
                                                    autoComplete='off' value={this.state.Renraku_Saki} onChange={this.onChangeRenraku_Saki} style={{ width: 400 }} id="standard-basic" label="連絡先" />
                                            </td>
                                        </tr>

                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={2} style={{ border: "0px" }}>
                                                <TextField
                                                    autoComplete='off' value={this.state.Renraku_Kibou} onChange={this.onChangeRenraku_Kibou} style={{ width: 400 }} id="standard-basic" label="連絡希望時間" />
                                            </td>
                                        </tr>

                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={2} style={{ border: "0px" }}>
                                                <RadioGroup onChange={this.onChangegyousha_renraku} row aria-label="position" name="position" defaultValue="top">
                                                    <FormControlLabel
                                                        value="1"
                                                        control={<Radio color="primary" />}
                                                        label="業者連絡可能"
                                                    />
                                                    <FormControlLabel
                                                        value="2"
                                                        control={<Radio color="primary" />}
                                                        label="不可"
                                                    />
                                                    <FormControlLabel
                                                        value="0"
                                                        control={<Radio color="primary" />}
                                                        label="未確認"
                                                    />
                                                    <FormControlLabel
                                                        value="3"
                                                        control={<Radio color="primary" />}
                                                        label="報告不要"
                                                    />
                                                </RadioGroup>
                                            </td>
                                        </tr>

                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    defaultValue={{ value: this.state.Outai_Joukyou_Name, key: this.state.Outai_Joukyou_Name }}
                                                    options={taiou_joukyou}
                                                    getOptionLabel={(option: any) => option.value}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangeOutai_Joukyou_Name}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="対応状況" />}
                                                />
                                            </td>
                                            {/* <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={type}
                                                    getOptionLabel={(option: any) => option.key}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChange_type}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="受付方法" />}
                                                />
                                            </td> */}

                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={this.props.users_select}
                                                    getOptionLabel={(option: any) => option.label}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangetaiou_tantou_user_id}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="対応担当" />}
                                                />
                                            </td>


                                        </tr>
                                    </tbody>
                                </table>

                                <table className="col-lg-5" style={{ border: "0px" }}>
                                    <tbody>
                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <RadioGroup onChange={this.onChangeTatemono_or_P} row aria-label="position" name="position" defaultValue="top">
                                                    <FormControlLabel
                                                        checked={this.state.Tatemono_or_P === '建物'}
                                                        value="建物"
                                                        control={<Radio color="primary" />}
                                                        label="建物"
                                                    />
                                                    <FormControlLabel
                                                        checked={this.state.Tatemono_or_P === '駐車場'}
                                                        value="駐車場"
                                                        control={<Radio color="primary" />}
                                                        label="駐車場"
                                                    />
                                                </RadioGroup>
                                            </td>

                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={kokyaku}
                                                    defaultValue={{ key: "入居者", label: "入居者" }}
                                                    getOptionLabel={(option: any) => option.label}
                                                    style={{ width: 200 }}
                                                    onChange={this.onChangeKokyaku_Kbn}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="顧客区分" />}
                                                />
                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>

                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    defaultValue={{ value: this.props.user_details.amSectionId, tenpoName: this.state.shopname }}
                                                    options={this.props.shops}
                                                    getOptionLabel={(option: any) => option.tenpoName}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangeShokai_section_id}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="受電部署" />}
                                                />
                                            </td>
                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={this.props.users_select}
                                                    defaultValue={{ value: this.props.user_details.id, label: this.props.user_details.userNameAll }}
                                                    getOptionLabel={(option: any) => option.label}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangeShokai_User_Id}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="受電者" />}
                                                />
                                            </td>


                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            {/* <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={this.props.users_select}
                                                    getOptionLabel={(option: any) => option.label}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangetaiou_tantou_user_id}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="対応担当" />}
                                                />
                                            </td> */}

                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={type}
                                                    getOptionLabel={(option: any) => option.key}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChange_type}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="受付方法" />}
                                                />
                                            </td>

                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                                                    <KeyboardDatePicker

                                                        variant="inline"
                                                        margin="normal"
                                                        id="date-picker-dialog"
                                                        label="初回対応日"
                                                        format="yyyy/MM/dd"
                                                        autoOk={true}
                                                        value={this.state.Syokai_Date}
                                                        onChange={this.onChangeSyokai_Date}
                                                        style={{ width: 130 }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>


                                                {this.state.time_disp ?
                                                    <TextField
                                                        autoComplete='off'
                                                        id="standard-number"
                                                        label="時"
                                                        type="number"
                                                        value={this.state.Shokai_H}
                                                        onChange={this.onChangeShokai_H}
                                                        style={{ width: 30, marginTop: 16 }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    /> : ""}

                                                {this.state.time_disp ?
                                                    <TextField
                                                        autoComplete='off'
                                                        id="standard-number"
                                                        label="分"
                                                        type="number"
                                                        value={this.state.Shokai_M}
                                                        onChange={this.onChangeShokai_M}
                                                        style={{ width: 30, marginTop: 16 }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    /> : ""}

                                                <i onClick={this.shokai_delete} style={{ paddingTop: 40, cursor: "pointer" }} className="fas fa-times"></i>

                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={this.props.Gyousya}
                                                    getOptionLabel={(option: any) => option.Gyosha_Name}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangesyokai_gyousha_no}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="初回手配業者" />}
                                                />
                                            </td>

                                            <td colSpan={1} style={{ border: "0px" }}>
                                            </td>
                                        </tr>

                                        <tr style={{ border: "0px" }}>
                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={this.props.Gyousya}
                                                    getOptionLabel={(option: any) => option.Gyosha_Name}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangesecond_gyousha_no}
                                                    renderInput={(params) => <TextField
                                                        autoComplete='off' {...params} label="次回手配業者" />}
                                                />
                                            </td>

                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                                                    <KeyboardDatePicker
                                                        onChange={this.second_date}
                                                        value={this.state.second_date}
                                                        variant="inline"
                                                        margin="normal"
                                                        id="date-picker-dialog"
                                                        label="次回対応日"
                                                        format="yyyy/MM/dd"
                                                        style={{ width: 130 }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </CardContent>

                            <CardContent style={{ float: "left" }} className="col-lg-12">

                                <TextField
                                    autoComplete='off'
                                    id="outlined-read-only-input"
                                    label="案件タイトル"
                                    fullWidth
                                    multiline
                                    rows={1}
                                    variant="outlined"
                                    onChange={this.onChangeTitle}
                                    style={{ marginTop: "10px" }}
                                />

                                <TextField
                                    autoComplete='off'
                                    id="outlined-read-only-input"
                                    label="案件内容"
                                    fullWidth
                                    multiline
                                    rows={6}
                                    variant="outlined"
                                    onChange={this.onChangeNaiyou}
                                    style={{ marginTop: "10px" }}
                                />

                                <div>
                                    <TextField
                                        autoComplete='off'
                                        id="outlined-read-only-input"
                                        label="業務課備考"
                                        fullWidth
                                        multiline
                                        rows={1}
                                        variant="outlined"
                                        onChange={this.onChangeremarks}
                                        style={{ marginTop: "10px" }}
                                    />
                                </div>
                            </CardContent>

                            <CardContent style={{ float: "left" }} className="col-lg-12">


                                {this.state.first_taiou == false ?
                                    <Button
                                        onClick={this.first_taiou} variant="outlined" color="primary">
                                        <i className="fas fa-plus"></i>初回対応を登録する</Button>
                                    :
                                    <div>
                                        <div>
                                            <span style={{ fontSize: 20, fontWeight: "bold", backgroundColor: "#e0f4ff" }}>初回対応</span>
                                            <Button
                                                style={{ marginLeft: 50 }}
                                                onClick={this.first_taiou}
                                                variant="outlined"
                                                color="primary">
                                                <i className="fas fa-minus"></i>初回対応を登録しない</Button>
                                        </div>

                                        <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                            <tbody>
                                                <tr style={{ border: "0px" }}>
                                                    <td colSpan={1} style={{ border: "0px" }}>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={this.props.users_select}
                                                            defaultValue={{ value: this.props.user_details.id, label: this.props.user_details.userNameAll }}
                                                            getOptionLabel={(option: any) => option.label}
                                                            style={{ width: 200, float: "left", marginTop: 12 }}
                                                            onChange={this.onChangeOutaiTanto_Id}
                                                            renderInput={(params) => <TextField
                                                                autoComplete='off' {...params} label="応対者" />}
                                                        />
                                                    </td>
                                                    <td colSpan={1} style={{ border: "0px" }}>
                                                        <Autocomplete
                                                            id="combo-box-demo"
                                                            options={outai_houhou}
                                                            getOptionLabel={(option: any) => option.value}
                                                            style={{ width: 200, float: "left", marginTop: 12 }}
                                                            onChange={this.onChangeOutaiHoho_Name}
                                                            renderInput={(params) => <TextField
                                                                autoComplete='off' {...params} label="応対方法" />}
                                                        />
                                                    </td>

                                                    <td colSpan={1} style={{ border: "0px" }}>
                                                        <TextField
                                                            autoComplete='off'
                                                            id="outlined-read-only-input"
                                                            label="応対方法備考"
                                                            fullWidth
                                                            multiline
                                                            rows={1}
                                                            variant="outlined"
                                                            onChange={this.onChangeOutaiHoho_Biko}
                                                            style={{ marginTop: "10px" }}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <TextField
                                            autoComplete='off'
                                            id="outlined-read-only-input"
                                            label="応対内容"
                                            fullWidth
                                            multiline
                                            rows={6}
                                            variant="outlined"
                                            onChange={this.onChangeOutaiNaiyo}
                                            style={{ marginTop: "10px" }}
                                        />

                                        <TextField
                                            autoComplete='off'
                                            id="outlined-read-only-input"
                                            label="応対内容備考"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            onChange={this.onChangeOutaiBiko}
                                            style={{ marginTop: "10px" }}
                                        />

                                        <div style={{ width: "250px", height: 40, margin: 5 }}>
                                            <Dropzone
                                                onDrop={this.onDrop}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps({ className: 'dropzone' })} style={{ background: "#f5f5f5", height: 60, border: "solid" }}>
                                                        <input {...getInputProps()} />
                                                        <div>
                                                            <p>クリックまたはドラッグでファイルをアップロード</p>
                                                            <ul style={{ padding: 0, marginTop: 3 }}  >
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>
                                        <div style={{ marginTop: 30 }}>
                                            {this.state.filename}
                                        </div>

                                    </div>}
                            </CardContent>

                        </Card>



                        <Card className="col-lg-6" square={true} style={{ padding: 5 }}>
                            <CardContent style={{ float: "left" }} className="col-lg-12">
                                <div>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={this.props.Outaisub}
                                        value={{ symbol: this.state.symbol, name: this.state.symbol_name }}
                                        getOptionLabel={(option: any) =>
                                            // option.symbol == "入居時リクエスト" ? "" : option.symbol + ":" + option.name
                                            option.symbol + ":" + option.name
                                        }
                                        style={{ width: 500 }}
                                        onChange={this.onChangemain_outai_sub}
                                        renderInput={(params) => <TextField
                                            autoComplete='off' {...params} label="メイン区分" />}
                                    />
                                </div>

                                <table className="col-lg-12" style={{ border: "0px", float: "left" }}>
                                    <tbody>
                                        <tr style={{ border: "0px" }}>
                                            <td style={{ border: "0px", backgroundColor: "#fff4c8", fontWeight: "bold" }}>
                                                <div>入</div>
                                                <div>居</div>
                                                <div>時</div>
                                                <div>リ</div>
                                                <div>ク</div>
                                                <div>エ</div>
                                                <div>ス</div>
                                                <div>ト</div>
                                            </td>
                                            <td colSpan={1} style={{ border: "0px", backgroundColor: "#ffffe0" }}>

                                                <div>※入居1か月以内のリクエストの場合はチェック</div>
                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", width: 210 }}
                                                    control={<GreenCheckbox checked={this.state.checked29} onChange={this.handleChange} name="checked29" value="入居時リクエスト" />}
                                                    label="設備"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", width: 210 }}
                                                    control={<GreenCheckbox checked={this.state.checked30} onChange={this.handleChange} name="checked30" value="入居時リクエスト" />}
                                                    label="人的"
                                                />

                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            <th style={{ border: "0px", backgroundColor: "#edfbff" }}>
                                                <div>設</div>
                                                <div>備</div>
                                                <div>リ</div>
                                                <div>ク</div>
                                                <div>エ</div>
                                                <div>ス</div>
                                                <div>ト</div>
                                            </th>
                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <h5 style={{ backgroundColor: "#edfbff" }}>【共用部】</h5>

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked1} onChange={this.handleChange} name="checked1" value="共用灯" />}
                                                    label="共用灯"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked31} onChange={this.handleChange} name="checked31" value="分電盤" />}
                                                    label="分電盤"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked2} onChange={this.handleChange} name="checked2" value="ポスト・宅配BOX" />}
                                                    label="ポスト・宅配BOX"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked32} onChange={this.handleChange} name="checked32" value="消防・警報機" />}
                                                    label="消防・警報機"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked4} onChange={this.handleChange} name="checked4" value="漏水 給排水管" />}
                                                    label="漏水 給排水管"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked33} onChange={this.handleChange} name="checked33" value="排水詰まり・溢れ" />}
                                                    label="排水詰まり・溢れ"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked34} onChange={this.handleChange} name="checked34" value="受水槽・ポンプ" />}
                                                    label="受水槽・ポンプ"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked35} onChange={this.handleChange} name="checked35" value="浄化槽・ブロワー" />}
                                                    label="浄化槽・ブロワー"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked36} onChange={this.handleChange} name="checked36" value="マンホール" />}
                                                    label="マンホール"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked37} onChange={this.handleChange} name="checked37" value="植栽・除草" />}
                                                    label="植栽・除草"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked38} onChange={this.handleChange} name="checked38" value="外装関係" />}
                                                    label="外装関係"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked39} onChange={this.handleChange} name="checked39" value="清掃" />}
                                                    label="清掃"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked3} onChange={this.handleChange} name="checked3" value="ゴミ集積所" />}
                                                    label="ゴミ集積所"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked6} onChange={this.handleChange} name="checked6" value="その他" />}
                                                    label="その他"
                                                />

                                            </td>
                                        </tr>


                                        <tr style={{ border: "0px" }}>
                                            <th style={{ border: "0px", backgroundColor: "#e0f4ff" }}>
                                                <div>設</div>
                                                <div>備</div>
                                                <div>リ</div>
                                                <div>ク</div>
                                                <div>エ</div>
                                                <div>ス</div>
                                                <div>ト</div>                                        </th>
                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <h5 style={{ backgroundColor: "#e0f4ff" }}>【居室内】</h5>

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked7} onChange={this.handleChange} name="checked7" value="エアコン" />}
                                                    label="エアコン"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked8} onChange={this.handleChange} name="checked8" value="建具" />}
                                                    label="建具"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked41} onChange={this.handleChange} name="checked41" value="網戸" />}
                                                    label="網戸"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked42} onChange={this.handleChange} name="checked42" value="水栓(漏水)" />}
                                                    label="水栓(漏水)"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked43} onChange={this.handleChange} name="checked43" value="水栓(漏水以外)" />}
                                                    label="水栓(漏水以外)"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked10} onChange={this.handleChange} name="checked10" value="給水管漏水" />}
                                                    label="給水管漏水"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked44} onChange={this.handleChange} name="checked44" value="排水管漏水" />}
                                                    label="排水管漏水"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked45} onChange={this.handleChange} name="checked45" value="天井・階下漏水" />}
                                                    label="天井・階下漏水"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked46} onChange={this.handleChange} name="checked46" value="雨漏り" />}
                                                    label="雨漏り"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked47} onChange={this.handleChange} name="checked47" value="排水臭" />}
                                                    label="排水臭"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked11} onChange={this.handleChange} name="checked11" value="排水詰まり" />}
                                                    label="排水詰まり"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked48} onChange={this.handleChange} name="checked48" value="給湯機（電気・ガス）" />}
                                                    label="給湯機（電気・ガス）"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked12} onChange={this.handleChange} name="checked12" value="ガスコンロ(IH含)" />}
                                                    label="ガスコンロ(IH含)"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked13} onChange={this.handleChange} name="checked13" value="換気扇" />}
                                                    label="換気扇"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked14} onChange={this.handleChange} name="checked14" value="照明" />}
                                                    label="照明"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked15} onChange={this.handleChange} name="checked15" value="テレビ" />}
                                                    label="テレビ"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked16} onChange={this.handleChange} name="checked16" value="インターネット" />}
                                                    label="インターネット"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked49} onChange={this.handleChange} name="checked49" value="トイレ(便器・タンク)" />}
                                                    label="トイレ(便器・タンク)"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked50} onChange={this.handleChange} name="checked50" value="温水洗浄便座" />}
                                                    label="温水洗浄便座"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked51} onChange={this.handleChange} name="checked51" value="浴室" />}
                                                    label="浴室"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked52} onChange={this.handleChange} name="checked52" value="洗面" />}
                                                    label="洗面"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked53} onChange={this.handleChange} name="checked53" value="ガラス" />}
                                                    label="ガラス"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked9} onChange={this.handleChange} name="checked9" value="バルコニー" />}
                                                    label="バルコニー"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked55} onChange={this.handleChange} name="checked55" value="インターフォン・チャイム" />}
                                                    label="インターフォン・チャイム"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked56} onChange={this.handleChange} name="checked56" value="カーテンレール" />}
                                                    label="カーテンレール"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked57} onChange={this.handleChange} name="checked57" value="コンセント・スイッチ" />}
                                                    label="コンセント・スイッチ"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked58} onChange={this.handleChange} name="checked58" value="ブレーカー" />}
                                                    label="ブレーカー"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked59} onChange={this.handleChange} name="checked59" value="レンタル家電" />}
                                                    label="レンタル家電"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked60} onChange={this.handleChange} name="checked60" value="内装関係" />}
                                                    label="内装関係"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked61} onChange={this.handleChange} name="checked61" value="美装" />}
                                                    label="美装"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked17} onChange={this.handleChange} name="checked17" value="その他" />}
                                                    label="その他"
                                                />
                                            </td>
                                        </tr>




                                        <tr style={{ border: "0px" }}>
                                            <th style={{ border: "0px", backgroundColor: "#e0f4ff" }}>
                                                <div>人</div>
                                                <div>的</div>
                                                <div>リ</div>
                                                <div>ク</div>
                                                <div>エ</div>
                                                <div>ス</div>
                                                <div>ト</div>
                                            </th>
                                            <td colSpan={1} style={{ border: "0px" }}>
                                                <h5 style={{ backgroundColor: "#e0f4ff" }}>　</h5>

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked18} onChange={this.handleChange} name="checked18" value="騒音" />}
                                                    label="騒音"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked19} onChange={this.handleChange} name="checked19" value="ゴミ分別" />}
                                                    label="ゴミ分別"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked62} onChange={this.handleChange} name="checked62" value="ペット躾" />}
                                                    label="ペット躾"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked20} onChange={this.handleChange} name="checked20" value="小動物" />}
                                                    label="小動物"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked21} onChange={this.handleChange} name="checked21" value="鍵関係" />}
                                                    label="鍵関係"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked22} onChange={this.handleChange} name="checked22" value="遊び・たむろ" />}
                                                    label="遊び・たむろ"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked23} onChange={this.handleChange} name="checked23" value="不審者" />}
                                                    label="不審者"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked24} onChange={this.handleChange} name="checked24" value="害虫" />}
                                                    label="害虫"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked25} onChange={this.handleChange} name="checked25" value="迷惑駐車" />}
                                                    label="迷惑駐車"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked26} onChange={this.handleChange} name="checked26" value="自転車・バイク" />}
                                                    label="自転車・バイク"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked63} onChange={this.handleChange} name="checked63" value="捜査協力" />}
                                                    label="捜査協力"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked64} onChange={this.handleChange} name="checked64" value="安否確認" />}
                                                    label="安否確認"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked65} onChange={this.handleChange} name="checked65" value="事故" />}
                                                    label="事故"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked66} onChange={this.handleChange} name="checked66" value="問合せ・質問" />}
                                                    label="問合せ・質問"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked27} onChange={this.handleChange} name="checked27" value="その他" />}
                                                    label="その他"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked67} onChange={this.handleChange} name="checked67" value="保険・更新" />}
                                                    label="保険・更新"
                                                />

                                                <FormControlLabel
                                                    style={{ display: "block", float: "left", height: 40 }}
                                                    control={<GreenCheckbox checked={this.state.checked68} onChange={this.handleChange} name="checked68" value="カウント無し" />}
                                                    label="カウント無し"
                                                />

                                            </td>
                                        </tr>



                                    </tbody>
                                </table>


                            </CardContent>
                        </Card>

                    </Row>
                </div>


                <div className="item-outer col-lg-7">
                    {/* <Card style={{ marginTop: 10 }} className="col-lg-12">
                        <CardContent style={{ float: "left" }} className="col-lg-12">
                            <div>初回対応</div>

                            <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                <tbody>
                                    <tr style={{ border: "0px" }}>
                                        <td colSpan={1} style={{ border: "0px" }}>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={this.props.users_select}
                                                getOptionLabel={(option: any) => option.label}
                                                style={{ width: 200, float: "left", marginTop: 12 }}
                                                onChange={this.onChangeOutaiTanto_Id}
                                                renderInput={(params) => <TextField 
autoComplete='off' {...params} label="応対者" />}
                                            />
                                        </td>
                                        <td colSpan={1} style={{ border: "0px" }}>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={outai_houhou}
                                                getOptionLabel={(option: any) => option.value}
                                                style={{ width: 200, float: "left", marginTop: 12 }}
                                                onChange={this.onChangeOutaiHoho_Name}
                                                renderInput={(params) => <TextField 
autoComplete='off' {...params} label="応対方法" />}
                                            />
                                        </td>

                                        <td colSpan={1} style={{ border: "0px" }}>
                                            <TextField 
autoComplete='off'
                                                id="outlined-read-only-input"
                                                label="応対方法備考"
                                                fullWidth
                                                multiline
                                                rows={1}
                                                variant="outlined"
                                                onChange={this.onChangeOutaiHoho_Biko}
                                                style={{ marginTop: "10px" }}
                                            />
                                        </td>

                                    </tr>
                                </tbody>
                            </table>

                            <TextField 
autoComplete='off'
                                id="outlined-read-only-input"
                                label="応対内容"
                                fullWidth
                                multiline
                                rows={6}
                                variant="outlined"
                                onChange={this.onChangeOutaiNaiyo}
                                style={{ marginTop: "10px" }}
                            />

                            <TextField 
autoComplete='off'
                                id="outlined-read-only-input"
                                label="応対内容備考"
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                onChange={this.onChangeOutaiBiko}
                                style={{ marginTop: "10px" }}
                            />
                        </CardContent>
                    </Card> */}
                </div>




                <div style={{ float: "right", marginBottom: "10px", }}>

                    {this.state.button_disp ?
                        <Button
                            style={{
                                marginRight: "20px",
                                color: "#fff",
                                backgroundColor: "#5cb85c",
                                borderColor: "#4cae4c",
                                width: "200px"
                            }}
                            onClick={this.save}
                            variant="outlined" color="primary">
                            登録
          </Button> : "登録中..."}
                </div>


            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        Tatemono: state.Adjustment.Tatemono,
        Room: state.Adjustment.Room,
        Parking: state.Adjustment.Parking,
        Parkingarea: state.Adjustment.Parkingarea,
        Gyousya: state.Adjustment.Gyousya,
        Outaisub: state.Adjustment.Outaisub,
        customers: state.Adjustment.customers,
        user_details: state.masterReducer.user_details,

        shops: state.masterReducer.shops,
        users_select: state.masterReducer.users_select,
        new_outai_no: state.Adjustment.new_outai_no,
        kaketsuke_kojin: state.Adjustment.kaketsuke_kojin,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_room(state: any) {
            dispatch(Adjustment.get_room(state))
        },
        get_tatemono(state: any) {
            dispatch(Adjustment.get_tatemono(state))
        },
        get_parking(state: any) {
            dispatch(Adjustment.get_parking(state))
        },
        get_parkingarea(state: any) {
            dispatch(Adjustment.get_parkingarea(state))
        },
        new_request(state: any) {
            dispatch(Adjustment.new_request(state))
        },
        file_upload(state: any) {
            dispatch(Adjustment.file_upload(state))
        },
        get_customers(state: any) {
            dispatch(Adjustment.get_customers(state))
        },
        get_kaketsuke_kojin(state: any) {
            dispatch(Adjustment.get_kaketsuke_kojin(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);

