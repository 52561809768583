import React, { Component } from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { Container, Draggable } from "react-smooth-dnd";
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DragHandleIcon from "@material-ui/icons/DragHandle";
import Radio from '@material-ui/core/Radio';
import arrayMove from "array-move";
import DeleteIcon from '@material-ui/icons/Delete';
import * as Adjustment from './Saga/Adjustment';


const moment = require('moment')

interface Props {
    notset_contents: any,
    change_notset_contents: any,
    inset_contents: any,
    change_inset_contents: any,
    inset_contents_disp: any
}



interface State {
    testdatas: any
}

var testdatas = [
    { id: 1, name: "テスト", key_name: "test" },
    { id: 2, name: "テスト2", key_name: "test2" }
];

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            testdatas: [
                { id: 1, name: "テスト", key_name: "test" },
                { id: 2, name: "テスト2", key_name: "test2" }
            ]
        }
    }



    onDrop = ({ removedIndex, addedIndex }: any) => {
        let data = this.state.testdatas
        testdatas = arrayMove(data, removedIndex - 1, addedIndex - 1)
        this.setState({
            testdatas: testdatas
        });

        // props.change_kanri_m_follows_disp([])
        // props.change_kanri_m_follows_disp(follows)
    };

    save(id: any, name: any, width: any, key_name: any) {

        console.log("----------------")
        console.log(this.props.notset_contents);

        this.props.change_notset_contents({ data: this.props.notset_contents, id: id, delete: 1 });
        let data = this.props.inset_contents;
        data.push({ id: id, name: name, width: width, key_name: key_name });
        this.props.change_inset_contents(data);

        this.props.inset_contents_disp();
    }

    render() {

        if (this.props.notset_contents[0]) {
            return (
                <div>
                    <List>
                        <Container dragHandleSelector=".drag-handle" lockAxis="y" style={{ width: "400px" }} onDrop={this.onDrop}>

                            <Divider />
                            {this.props.notset_contents.map(({ id, name, width, key_name }: any) => (
                                <Draggable key={id}>
                                    <ListItem>



                                        <ListItemText primary={name} />

                                        <IconButton aria-label="delete" onClick={() => this.save(id, name, width, key_name)}>
                                            <i className="fas fa-plus-circle"></i>
                                        </IconButton>
                                    </ListItem>
                                    <Divider />
                                </Draggable>
                            ))}

                        </Container>
                    </List>
                </div >

            );
        } else {
            return "読み込み中"
        }
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {

        notset_contents: state.Adjustment.notset_contents,
        inset_contents: state.Adjustment.inset_contents,

    }
}



function mapDispatchToProps(dispatch: any) {
    return {
        change_notset_contents(state: any) {
            dispatch(Adjustment.change_notset_contents(state))
        },
        change_inset_contents(state: any) {
            dispatch(Adjustment.change_inset_contents(state))
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




