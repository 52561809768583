// const authentication = require('react-azure-adb2c').default;
const authentication = require('../../../react-azure-adb2c2').default;



export const list = (value: any) => {

    // let url = `http://localhost:5000/v1/room/support/chat/templates2/` + value;
    let url = process.env.REACT_APP_ROOM_SUPPORT_API_URL + `/v1/room/support/chat/templates2/` + value;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const update = (value: any) => {
    // alert( JSON.stringify(value))

    // let url = `http://localhost:5000/v1/room/support/chat/templates2/update/`+ value.room_support_chat_template_category_id;
    let url = process.env.REACT_APP_ROOM_SUPPORT_API_URL + `/v1/room/support/chat/templates2/update/`+ value.room_support_chat_template_category_id;
    

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value.room_support_chat_template_list),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};
