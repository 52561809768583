const authentication = require('../../../react-azure-adb2c2').default;



export const list = (values: any) => {

    const token = authentication.getAccessToken();

    let url = ``;

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        // headers: { 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}