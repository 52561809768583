import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Grid from "./Grid.tsx_";
// import HeaderGrid from "./HeaderGrid";
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
// import Search from "./Search.tsx_";
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
import * as KyDetail from './Saga/KyDetail';
import Kys from '../Kys/Index';
import * as KysSaga from '../Kys/Saga/Kys';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import MailIcon from '@material-ui/icons/Mail';
import * as Line from '../Line/Saga/Line';
import * as Chat from '../Chat/Saga/Chat';
import * as Sms from '../Sms/Saga/Sms';
import * as Mail from '../Mail/Saga/Mail';
import KyIndex from '../RentMoney/KyIndex';
import SmsIcon from '@material-ui/icons/Sms';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import Select from '@material-ui/core/Select';
interface Props {
    get_ky: any,
    ky: any,
    change_mail_new: any,
    // customerNewDisp:number,
    // customerno:number,
    get_response_lines: any,
    get_chats: any,
    get_smses: any,
    // toggle:any,
    // get_rent_contract_accounting:any,
    // get_remittance_details: any,
    // get_rent_moneies: any,
    // search_detail: any,
    classes: any,
    kys_biko: any,
    get_kys: any,
    // search_loading: boolean,
    // get_rent_money_bklist: any,
    // bklist: any,
    // addky: any,
    // delky: any,
}



interface State {
    reflesh: boolean,
    responseTabs: string,
    customerTabs: string,
    modalkys: boolean,

    // state types
}


const styles = ((theme: any) => ({

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tab: {

        minWidth: 50, // a number of your choice
    }
}))



class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        // this.props.change_search_name("")
        this.state = {
            reflesh: false,
            responseTabs: "k1",
            modalkys: false,
            customerTabs: "kys1",
        }
        this.props.get_ky()
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handlecustomerTabChange = this.handlecustomerTabChange.bind(this);
        this.togglekys = this.togglekys.bind(this);
        this.mail = this.mail.bind(this);


    }
    componentWillMount() {
        // this.props.get_owner_remittance_check_logs()
        // this.props.get_remittance_details()

        // this.props.get_rent_money_bklist()
        // this.props.get_rent_moneies()
        // this.props.customerNewDispSet(0)
        // this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state: any) {
        console.log(state)

        // this.props.get_remittance_details(state)
    }

    togglekys(kys_no: any) {
        console.log(kys_no)
        if (!this.state.modalkys) {
            this.props.get_kys(kys_no)
        }
        this.setState({ modalkys: !this.state.modalkys })
        // this.props.get_remittance_details(state)
    }

    csv() {

        // this.props.get_rent_contract_accounting(this.props.kyousais);
    }

    mail() {
        let maildetail = {
            toMail: "",
            toMailall: "",
            fromMail: "web@global-center.co.jp",
            fromName: "グローバルセンター",
            mailAccountId: 12,
            isSended: 1,
            rentResponseId: 93180
        };



        this.props.change_mail_new(maildetail)

    }


    handleTabChange = (event: any, newValue: string) => {

        this.setState({ responseTabs: newValue });
    };
    handlecustomerTabChange = (event: any, newValue: string) => {

        this.setState({ customerTabs: newValue });
    };
    render() {

        return (
            <div style={{ padding: 10, background: "#b0c4de" }} >





                <Card className="col-lg-12" >
                    {this.props.ky.bk_name ? <CardContent style={{ float: "left" }} className="col-lg-12">

                        <div><TextField id="standard-basic" label="建物名" value={this.props.ky.bk_name} style={{ width: 500 }} /> <TextField id="standard-basic" label="部屋名" value={this.props.ky.hy_no} />　</div>
                        {/* {this.props.addky.map(function (value: any) {
                            return value.hy_no + " " + value.kys_name
                        })}

                        {this.props.delky.map(function (value: any) {
                            return value.hy_no + " " + value.kys_name
                        })} */}
                        {/* <Grid /> */}
                    </CardContent> : ""}
                </Card>

                <Paper square={false} style={{ marginTop: 10 }}>

                    <Tabs
                        value={this.state.responseTabs}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        onChange={this.handleTabChange}
                        aria-label="disabled tabs example"
                        style={{ float: "left" }}
                    >
                        <Tab className={this.props.classes.tab} label="契約情報" value="k1" />
                        <Tab className={this.props.classes.tab} label="家賃口座・振替設定" value="k2" />
                        <Tab className={this.props.classes.tab} label="入金項目(毎月)" value="k3" />
                        <Tab className={this.props.classes.tab} label="契約・更新時" value="k4" />
                        <Tab className={this.props.classes.tab} label="随時・変動費" value="k5" />
                        <Tab className={this.props.classes.tab} label="特約" value="k6" />
                        <Tab className={this.props.classes.tab} label="文章" value="k7" />


                        {/* <Tab label="見積・契約金" value="r2" /> */}
                        {/* {this.props.responsedetail.rentContractId > 0 ? <Tab label="売上" value="r3" /> : ""}
                        {this.props.responsedetail.rentContractId > 0 ? <Tab label="契約" value="r4" /> : ""}
                        {this.props.responsedetail.rentContractId > 0 ? <Tab label="進捗管理" value="r5" /> : ""} */}
                    </Tabs>

                    {this.state.responseTabs === "k1" && this.props.ky.kys_name1 ? <Card className="col-lg-12" square={true} style={{ background: "#b0c4de", padding: 5 }}>
                        <Card style={{ marginTop: 10 }} className="col-lg-12">
                            <CardContent style={{ float: "left", padding: 10 }} className="col-lg-12">

                                <div><TextField id="standard-basic" label="契約者1" value={this.props.ky.kys_name1} style={{ width: 500, margin: 5 }} />  <Button variant="contained" color="primary" onClick={() => this.togglekys(this.props.ky.kys_no1)}>詳細</Button>
                                    {/* <IconButton aria-label="delete"><MailIcon style={{ color: "blue" }} onClick={this.mail} /></IconButton>
                                    <IconButton aria-label="delete"><i className="fab fa-line" style={{ fontSize: "18px", color: "green" }} onClick={() => this.props.get_response_lines(93180)}></i></IconButton>
                                     */}
                                    <IconButton ><QuestionAnswerIcon color="primary" onClick={() => this.props.get_chats(93180)} /></IconButton>
                                    <IconButton aria-label="delete"><SmsIcon color="secondary" onClick={() => this.props.get_smses("09028233963")} /></IconButton>
                                </div>
                                <div><TextField id="standard-basic" label="契約者2" value={this.props.ky.kys_name2} style={{ width: 500, margin: 5 }} /> <Button variant="contained" color="primary" onClick={() => this.togglekys(this.props.ky.kys_no2)}>詳細</Button></div>
                                <div><TextField id="standard-basic" label="契約者3" value={this.props.ky.kys_name3} style={{ width: 500, margin: 5 }} /> <Button variant="contained" color="primary" onClick={() => this.togglekys(this.props.ky.kys_no3)}>詳細</Button></div>


                            </CardContent>
                        </Card>

                        <Card style={{ marginTop: 10 }} className="col-lg-12">
                            <CardContent style={{ float: "left" }} className="col-lg-8">

                                <div><TextField id="standard-basic" label="契約日" value={moment(this.props.ky.ky_date).format("YYYY年MM月DD日")} style={{ width: 200, margin: 5 }} /></div>
                                <div><TextField id="standard-basic" label="契約期間" value={moment(this.props.ky.ky_start_ymd).format("YYYY年MM月DD日")} style={{ margin: 5 }} />～<TextField id="standard-basic" label="契約期間" value={moment(this.props.ky.ky_end_ymd).format("YYYY年MM月DD日")} style={{ margin: 5 }} /></div>
                                <div><TextField id="standard-basic" label="契約終了通知期間" value={moment(this.props.ky.teki_start_ymd).format("YYYY年MM月DD日")} style={{ margin: 5 }} />～<TextField id="standard-basic" label="契約終了通知期間" value={moment(this.props.ky.teki_end_ymd).format("YYYY年MM月DD日")} style={{ margin: 5 }} /></div>

                            </CardContent>
                            <CardContent style={{ float: "left" }} className="col-lg-4">

                                <div><TextField id="standard-basic" label="更新内容" style={{ width: 200, margin: 5 }} /></div>
                                <div><TextField id="standard-basic" label="契約分類" value="通常契約" style={{ margin: 5 }} /></div>
                                <div><TextField id="standard-basic" label="契約更新業務" value="無" style={{ margin: 5 }} /></div>
                                <div><TextField id="standard-basic" label="使用目的" value="住居" style={{ margin: 5 }} /></div>
                            </CardContent>
                        </Card>
                        <Card style={{ marginTop: 10 }} className="col-lg-12">
                            <CardContent style={{ float: "left", padding: 10 }} className="col-lg-6">

                                <div><TextField id="standard-basic" label="担当者" style={{ margin: 5 }} /></div>
                                <div><TextField id="standard-basic" label="仲介会社" style={{ margin: 5 }} /></div>
                                <div><TextField id="standard-basic" label="備考" style={{ margin: 5 }} /></div>

                            </CardContent>
                            <CardContent style={{ float: "left" }} className="col-lg-6">

                                <div><TextField id="standard-basic" label="宅建士" style={{ margin: 5 }} /></div>
                                <div><TextField id="standard-basic" label="管理業者" style={{ margin: 5 }} /></div>
                                <div><TextField id="standard-basic" label="請求担当" style={{ margin: 5 }} /></div>
                                <div><TextField id="standard-basic" label="家賃保証会社" style={{ margin: 5 }} /></div>
                            </CardContent>
                        </Card>
                        <Card style={{ marginTop: 10 }} className="col-lg-12">
                            <CardContent style={{ float: "left" }} className="col-lg-12">
                                <KyIndex ky={this.props.ky} />
                            </CardContent>

                        </Card>


                    </Card> : ""}



                    {this.state.responseTabs === "k2" ? <Card className="col-lg-12" square={true} style={{ background: "#b0c4de", padding: 5 }}>


                        <Card style={{ marginTop: 10 }} className="col-lg-12">
                            <CardContent style={{ float: "left" }} className="col-lg-12">

                                <div><TextField id="standard-basic" label="家賃入金区分" style={{ width: 500 }} /></div>
                                <div><TextField id="standard-basic" label="口座振替" /></div>
                                <div><TextField id="standard-basic" label="振込先口座" /></div>
                                <div><TextField id="standard-basic" label="金融機関" /></div>
                                <div><TextField id="standard-basic" label="口座種別" /></div>
                                <div><TextField id="standard-basic" label="口座番号" /></div>
                                <div><TextField id="standard-basic" label="口座名義" /></div>
                            </CardContent>

                        </Card>

                    </Card> : ""}



                </Paper>


                <Modal isOpen={this.state.modalkys} toggle={this.togglekys} size="xl" fade={false} >

                    <ModalHeader toggle={this.togglekys}>契約者情報</ModalHeader>
                    {/* <AppBar position="static" color="default"> */}
                    <Kys />
                    <ModalFooter>
                        {/* <button className="btn btn btn-danger" style={{margin:"5px"}} onClick={this.delete_introductions}>削除する</button>*/}
                        {/* <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={() => this.props.change_search_modal(false)}>キャンセル</button> */}
                    </ModalFooter>
                </Modal >
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // search_loading: state.ContractCheck.search_loading,
        // minories: state.Minori.minories,
        // bklist: state.RemittanceDetail.bklist,
        // addky: state.RemittanceDetail.addky,
        ky: state.KyDetail.ky,

        kys_biko: state.kys.kys_biko,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_ky(state: any) {
            dispatch(KyDetail.get_ky(state))
        },
        change_mail_new(state: any) {
            dispatch(Mail.change_mail_new(state))
        },
        get_response_lines(state: any) {
            dispatch(Line.get_response_lines(state))
        },
        get_chats(state: any) {
            dispatch(Chat.get_chats(state))
        },
        get_smses(state: any) {
            dispatch(Sms.get_smses(state))
        },
        get_kys(state: number) {
            dispatch(KysSaga.get_kys(state))
        },
        // get_remittance_details(state: any) {
        //     dispatch(RentMoney.get_remittance_details(state))
        // },
        // get_owner_remittance_check_logs(state: any) {
        //     dispatch(RemittanceLogCheck.get_owner_remittance_check_logs(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));




