import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from "date-fns/locale/ja";
import Button from '@material-ui/core/Button';
import format from "date-fns/format";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const moment = require('moment')


class ExtendedUtils extends DateFnsUtils {
    getCalendarHeaderText(date: any) {
        return format(date, "yyyy MMM", { locale: this.locale });
    }
    getDatePickerHeaderText(date: any) {
        return format(date, "MMMd日", { locale: this.locale });
    }
}


class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}


const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');

interface Props {
    shops: any,
    users_select: any,
    search_change_names: any,
    search_change_section: any,
    search_change_user: any,
    search_change_date1: any,
    search_change_date2: any,
    search: any,
    contract_date1: any,
    contract_date2: any,
    not_app: any,
    search_status: any
}



interface State {
}

class CustomerList extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
    }
    render() {

        if (this.props.search_status == true) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'auto';
        }


        return (
            <div>


                {/* 契約店舗 */}
                <Autocomplete
                    id="section_id"
                    className="section_id"
                    options={this.props.shops}
                    getOptionLabel={(option: any) => option.tenpoName}
                    style={{ width: 200, margin: 5 }}
                    onChange={this.props.search_change_section}
                    renderInput={(params) => <TextField
                        autoComplete='off' {...params} label="契約店舗" />}
                />


                {/* 担当者 */}
                <Autocomplete
                    id="combo-box-demo"
                    options={this.props.users_select}
                    getOptionLabel={(option: any) => option.label}
                    style={{ width: 200, margin: 5 }}
                    onChange={this.props.search_change_user}
                    renderInput={(params) => <TextField
                        autoComplete='off' {...params} label="担当" />}
                />



                {/* 日付選択 */}
                <div>
                    <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                        <KeyboardDatePicker
                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="契約日"
                            format="yyyy/MM/dd"
                            style={{ width: 128, marginTop: 30 }}
                            value={this.props.contract_date1}
                            onChange={this.props.search_change_date1}
                            autoOk={true}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </div>
                <div>
                    <i className="fas fa-long-arrow-alt-down" style={{ verticalAlign: "bottom", marginLeft: 20, marginTop: 10, fontSize: 20 }}></i>
                </div>
                <div>
                    {/* 日付選択 */}
                    <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                        <KeyboardDatePicker

                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="契約日"
                            format="yyyy/MM/dd"
                            style={{ width: 128, margin: 0 }}
                            value={this.props.contract_date2}
                            onChange={this.props.search_change_date2}
                            autoOk={true}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </div>

                {/* 未登録のみチェックボックス */}
                <FormControlLabel
                    style={{ marginTop: 30 }}
                    onChange={this.props.not_app}
                    control={<Checkbox
                        color="primary"
                        name="yanushi_hoshu" />}

                    label="未登録のみ"
                />


                {/* 物件名 */}
                <TextField
                    autoComplete='off'
                    // value={this.props.bukken}
                    style={{ width: 200, marginTop: 20 }}
                    id="tatemono_name"
                    onChange={this.props.search_change_names}
                    label="物件名"
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            this.props.search();
                        }
                    }}
                />


                <TextField
                    autoComplete='off'
                    // value={this.props.bukken}
                    style={{ width: 200, marginTop: 20 }}
                    id="customer_name"
                    onChange={this.props.search_change_names}
                    label="契約者名"
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            this.props.search();
                        }
                    }}
                />


            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        shops: state.masterReducer.shops,
        users_select: state.masterReducer.users_select,
        search_status: state.ContractsList.search_status,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




