import React from 'react';
import Chip from '@material-ui/core/Chip';

import styled from 'styled-components';
var moment = require('moment-timezone');



const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin: 10px 0;
margin-top:15px;
`;

const Chatting = styled.div`
    width: 100%;
    text-align: right;
`;

const Says = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 60px 0 60px;
    padding: 10px;
    max-width: 250px;
    border-radius: 12px;
    background: white;
    margin-bottom: 2px;
    text-align: left;
    &:after {
        content: "";
        position: absolute;
        top: 3px; 
        right: -19px;
        border: 8px solid transparent;
        border-left: 18px solid white;
        -ms-transform: rotate(-35deg);
        -webkit-transform: rotate(-35deg);
        transform: rotate(-35deg);
    }
`;


const Name = styled.div`
    position: relative; 
    margin: 0 0 0 0px;
    bottom: 0;
    margin-right:60px;
    font-size:12px;
    color:#FFF;
`;

const Kidoku = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 25px 0;
    margin-top:15px;
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;



const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 10px 0;
    margin-top:15px;
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;

const MsgArea = styled.p`
    margin:0px;
    word-wrap: break-word;
    whiteSpace:pre-line;
`;

export default class LineTextComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: '1',
            messages: [],

            date_time: moment(this.props.msg.created_at).tz("Asia/Tokyo").format('MM/DD HH:mm'),

        };
    }

    render() {
        return (
            <Balloon className="col-xs-12">
                <Chatting>
                    <Name>
                        <span style={{ color: "black" }}>
                            <Chip label={this.props.msg.created_user_name} size="small" variant="outlined" />
                        </span>
                    </Name>
                    <Time>
                        <span style={{ color: "black" }}>
                            {moment.utc(this.props.msg.created_at).format('MM/DD HH:mm')}
                        </span>
                    </Time>
                    <Kidoku>
                        <span style={{ color: "black" }}>
                            {this.props.msg.read_at ? "既読" : ""}
                        </span>
                    </Kidoku>
                    <Says>
                        <MsgArea >  <span dangerouslySetInnerHTML={{ __html: this.props.msg.contents.replace(/\r?\n/g, '<br/>') }} /></MsgArea>
                    </Says>
                </Chatting>
            </Balloon>

        )
    }
}
