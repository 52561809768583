import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Kys from '../Kys/Index';
import * as KysSaga from '../Kys/Saga/Kys';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as Line from '../Line/Saga/Line';
import * as Chat from '../Chat/Saga/Chat';
import * as Sms from '../Sms/Saga/Sms';
import * as Mail from '../Mail/Saga/Mail';
import DetailListGrid from "./DetailListGrid";
import NewOutai from "./NewOutai";
import EditRequest from "./EditRequest";
import IkkatsuModal from "./IkkatsuModal";
import SmsModal from "./SmsModal";
import NewChatModal from "./NewChatModal";
import SmsGrid from "./SmsGrid";
import CompleteModal from "./CompleteModal";
import PastRequest from "./PastRequestGrid";
import KeiyakuDetail from "./KeiyakuDetail";
import KanrenKoujiModal from "./KanrenKoujiModal";
import MemoModal from "./MemoModal";
import ShinyouModal from "./shinyouModal";
import Button from '@material-ui/core/Button';
import * as Adjustment from '../Adjustment/Saga/Adjustment';
import * as RequestDetailSaga from './Saga/RequestDetail';
import { Row, Col } from 'reactstrap';
import * as Keiyaku from '../Keiyaku/Saga/Keiyaku';
import TabletMacIcon from '@material-ui/icons/TabletMac';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme: any) => ({
    margin: {
        margin: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tab: {

        minWidth: 50, // a number of your choice
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton style={{ position: 'absolute' }} aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}

        </MuiDialogTitle>
    );
});


interface Props {
    get_ky: any,
    ky: any,
    change_mail_new: any,
    get_response_lines: any,
    get_chats: any,
    get_smses: any,
    classes: any,
    kys_biko: any,
    get_kys: any,
    request_detail: any,
    get_request_detail: any,
    get_tatemono: any,
    get_room: any,
    get_gyousya: any,
    get_outaisub: any,
    get_past_request: any,
    get_keiyaku: any,
    outai_delete: any,
    reqtoggle: any,
    get_kouji: any,
    outai_complete: any,
    chat_kanryou: any,
    chat_complet: any,
    user_details: any,
    get_request_excels: any,
    match: any,
    get_parking: any,
    get_parkingarea: any,
    get_chatlist: any,
    get_sms_share: any,
    set_process_flag: any,
    set_Outai_No: any,
    get_template_category_list: any,
    importance: any,
    get_kouji_list: any,
    kouji_list: any,
    new_chat: any,
    new_chat_id: any,
    chat_plus: any,
    ipad_height: any,
    get_file: any,
    ikkatsu_file: any,
    get_sms: any,
    kaketsukethrow: any,
    get_request_detail_kaketuke_irai: any,
}



interface State {
    reqtoggle: boolean,
    edittoggle: boolean,
    ikkatsutoggle: boolean,
    smstoggle: boolean,
    newchattoggle: boolean,
    Completetoggle: boolean,
    KanrenKoujitoggle: boolean,
    reflesh: boolean,
    responseTabs: string,
    customerTabs: string,
    modalkys: boolean,
    disp: boolean,
    popup: boolean,
    search_status: boolean,
    ipad_height: any,
    MemoModaltoggle: boolean,
    shinyouModaltoggle: boolean
    // state types
}



class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        if (this.props.match) {
            props.get_request_detail({ Outai_No: this.props.match.params.outai_no })
            props.get_kouji_list(this.props.match.params.outai_no)
        }


        // this.props.change_search_nｓｓame("")
        this.state = {
            reqtoggle: false,
            edittoggle: false,
            ikkatsutoggle: false,
            smstoggle: false,
            newchattoggle: false,
            Completetoggle: false,
            KanrenKoujitoggle: false,
            reflesh: false,
            responseTabs: "k1",
            modalkys: false,
            customerTabs: "kys1",
            disp: true,
            popup: false,
            search_status: false,
            ipad_height: 0,
            MemoModaltoggle: false,
            shinyouModaltoggle: false
        }
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handlecustomerTabChange = this.handlecustomerTabChange.bind(this);
        this.togglekys = this.togglekys.bind(this);
        this.reqtoggle = this.reqtoggle.bind(this);
        this.edittoggle = this.edittoggle.bind(this);
        this.edit_after_toggle = this.edit_after_toggle.bind(this);

        this.ikkatsutoggle = this.ikkatsutoggle.bind(this);
        this.smstoggle = this.smstoggle.bind(this);
        this.newchattoggle = this.newchattoggle.bind(this);

        this.KanrenKoujitoggle = this.KanrenKoujitoggle.bind(this);
        this.MemoModaltoggle = this.MemoModaltoggle.bind(this);
        this.shinyouModaltoggle = this.shinyouModaltoggle.bind(this);
        this.url_open = this.url_open.bind(this);
        this.url_open_tatemono = this.url_open_tatemono.bind(this);
        this.url_open_room = this.url_open_room.bind(this);
        this.url_open_keiyaku = this.url_open_keiyaku.bind(this);
        this.url_open_kaketuke = this.url_open_kaketuke.bind(this);
        this.url_open_isai = this.url_open_isai.bind(this);
        this.url_open_uketuke = this.url_open_uketuke.bind(this);
        this.url_open_kako = this.url_open_kako.bind(this);
        this.url_open_seisan = this.url_open_seisan.bind(this);
        this.url_open_nyukyo = this.url_open_nyukyo.bind(this);
        this.delete = this.delete.bind(this);
        this.kouji_list = this.kouji_list.bind(this);
        this.tabreset = this.tabreset.bind(this);
        this.chat_open = this.chat_open.bind(this);
        this.chat_open2 = this.chat_open2.bind(this);

        this.chat_list_open = this.chat_list_open.bind(this);

        this.popup = this.popup.bind(this);
        this.kanryou = this.kanryou.bind(this);
        this.mikanryou = this.mikanryou.bind(this);
        this.chat_kanryou = this.chat_kanryou.bind(this);
        this.star_plus = this.star_plus.bind(this);
        this.star_minus = this.star_minus.bind(this);
        this.chat_plus = this.chat_plus.bind(this);
        this.ipad_mode = this.ipad_mode.bind(this);
        this.Completetoggle = this.Completetoggle.bind(this);
        this.disp_reset = this.disp_reset.bind(this);
        this.chat_plus_update = this.chat_plus_update.bind(this);
        this.kaketsuke_irai = this.kaketsuke_irai.bind(this);

    }
    Completetoggle() {

        if (this.state.Completetoggle == true) {
            this.props.get_request_detail({ Outai_No: this.props.request_detail.detail[0].Outai_No });
        } else {
            this.props.get_outaisub();
        }
        this.setState({ Completetoggle: !this.state.Completetoggle })
    }

    star_plus(i: any) {
        var importance = this.props.request_detail.detail[0].importance ? this.props.request_detail.detail[0].importance : 0;

        importance = importance + i;

        this.props.importance(
            {
                importance: importance,
                Outai_No: this.props.request_detail.detail[0].Outai_No
            }
        )
    }
    star_minus(i: any) {

        var importance = this.props.request_detail.detail[0].importance ? this.props.request_detail.detail[0].importance : 0;

        importance = importance - i;

        if (importance == 0) {
            importance = null
        }

        this.props.importance(
            {
                importance: importance,
                Outai_No: this.props.request_detail.detail[0].Outai_No
            }
        )

    }



    reqtoggle() {
        if (this.state.reqtoggle == true) {
            this.props.get_request_detail({ Outai_No: this.props.request_detail.detail[0].Outai_No });
        }
        this.setState({ reqtoggle: !this.state.reqtoggle })
    }

    edittoggle() {
        this.props.get_gyousya();
        this.props.get_outaisub();
        this.props.get_tatemono();
        this.props.get_parking();
        this.props.get_room(this.props.request_detail.detail[0].Tatemono_Id);
        if (this.props.request_detail.detail[0].Tatemono_Id) {
            this.props.get_parkingarea(this.props.request_detail.detail[0].Tatemono_Id.replace("SysP", ""));
        }
        this.setState({ edittoggle: !this.state.edittoggle })
    }

    edit_after_toggle() {
        if (this.state.edittoggle == true) {
            this.props.get_request_detail({ Outai_No: this.props.request_detail.detail[0].Outai_No });
            this.setState({ disp: !this.state.disp })

            setTimeout(() => {
                this.setState({ disp: !this.state.disp })
            }, 2000)
        }
        this.setState({ edittoggle: !this.state.edittoggle })
    }

    ikkatsutoggle() {
        this.setState({ ikkatsutoggle: !this.state.ikkatsutoggle })
    }
    smstoggle() {
        if (this.state.smstoggle == true) {
            this.props.get_request_detail({ Outai_No: this.props.request_detail.detail[0].Outai_No });
        } else {
            this.props.get_template_category_list();
        }
        this.setState({ smstoggle: !this.state.smstoggle })
    }

    newchattoggle() {
        if (this.state.newchattoggle == true) {
            this.props.get_request_detail({ Outai_No: this.props.request_detail.detail[0].Outai_No });
        } else {
            this.props.get_template_category_list();
        }
        this.setState({ newchattoggle: !this.state.newchattoggle })
    }

    KanrenKoujitoggle() {
        this.setState({ KanrenKoujitoggle: !this.state.KanrenKoujitoggle })
    }
    MemoModaltoggle() {
        this.setState({ MemoModaltoggle: !this.state.MemoModaltoggle })
    }
    shinyouModaltoggle() {
        this.setState({ shinyouModaltoggle: !this.state.shinyouModaltoggle })
    }
    submit(state: any) {
        console.log(state)
        // bababaaaaaaaaaa

        // this.props.get_remittance_details(state)
    }

    togglekys(kys_no: any) {
        console.log(kys_no)
        if (!this.state.modalkys) {
            this.props.get_kys(kys_no)
        }
        this.setState({ modalkys: !this.state.modalkys })
        // this.props.get_remittance_details(state)
    }

    url_open() {
        var url = process.env.REACT_APP_HOMESTATION4_URL+'/manage/request/detail?no=' + this.props.request_detail.detail[0].Outai_No;
        window.open(url, '_blank')
    }

    url_open_tatemono() {

        if (this.props.request_detail.detail[0].Tatemono_Name) {
            var url = process.env.REACT_APP_HOMESTATION4_URL+'/manage/building/detail/index?t_id=' + this.props.request_detail.detail[0].Tatemono_Id;
        } else {
            var id = this.props.request_detail.detail[0].Tatemono_Id.replace("SysP", "");
            var url = process.env.REACT_APP_HOMESTATION4_URL+'/manage/parking/detail/index?p_id=' + id;
        }
        window.open(url, '_blank')
    }

    url_open_room() {
        var url = process.env.REACT_APP_HOMESTATION4_URL+'/manage/room/detail/index?t_id=' + this.props.request_detail.detail[0].Tatemono_Id + '&r_id=' + + this.props.request_detail.detail[0].Room_Id;
        window.open(url, '_blank')
    }

    url_open_keiyaku() {
        var url = process.env.REACT_APP_HOMESTATION4_URL+'/manage/contract/detail/view?w_id=' + this.props.request_detail.detail[0].workflow_id;
        window.open(url, '_blank')
    }

    url_open_kaketuke() {

        this.props.get_request_detail_kaketuke_irai({outai_no: this.props.request_detail.detail[0].Outai_No, kaketsuke: 1});

        // var url = process.env.REACT_APP_API_PM_URL+`/v1/t/outai/requestreport/` + this.props.request_detail.detail[0].Outai_No + "/1";
        // window.open(url, '_blank', 'noopener')

    }

    url_open_isai() {
        var url = process.env.REACT_APP_HOMESTATION4_URL+'/manage/request/detailsheet?no=' + this.props.request_detail.detail[0].Outai_No;
        window.open(url, '_blank')
    }

    url_open_uketuke() {

        this.props.get_request_detail_kaketuke_irai({outai_no: this.props.request_detail.detail[0].Outai_No, kaketsuke: 0});

        // var url = process.env.REACT_APP_API_PM_URL+`/v1/t/outai/requestreport/` + this.props.request_detail.detail[0].Outai_No + "/0";
        // window.open(url, '_blank', 'noopener')

    }

    url_open_kako() {
        var url = process.env.REACT_APP_HOMESTATION4_URL+'/manage/construction/construction/list?Tatemono_Name=' + this.props.request_detail.detail[0].Tatemono_Name;
        window.open(url, '_blank')
    }

    url_open_seisan() {
        var url = process.env.REACT_APP_HOMESTATION4_URL+'/manage/building/checkout/flow?ky_no=' + this.props.request_detail.detail[0].Pre_Keiyaku_No;
        window.open(url, '_blank', 'noopener')
    }
    url_open_nyukyo() {
        var url = process.env.REACT_APP_HOMESTATION4_URL+'/manage/nyuukyomae/check?keiyaku_no=' + this.props.request_detail.detail[0].Keiyaku_No;
        window.open(url, '_blank', 'noopener')
    }
    chat_open() {


        this.props.set_process_flag(
            {
                process_user: this.props.user_details.id,
                process_flag: 0
            }
        );

        this.props.get_chats(this.props.request_detail.detail[0].room_support_m_chat_id);
        this.props.set_Outai_No(this.props.request_detail.detail[0].Outai_No);
    }

    chat_open2() {
        this.props.set_process_flag(
            {
                process_user: this.props.request_detail.detail[0].chat_user,
                process_flag: 1
            }
        );

        this.props.get_chats(this.props.request_detail.detail[0].room_support_m_chat_id);
        this.props.set_Outai_No(this.props.request_detail.detail[0].Outai_No);
    }


    chat_list_open() {
        // this.props.get_sms_share({ m_customer_id: this.props.request_detail.detail[0].m_customer_id });

        this.props.get_chatlist({
            m_customer_id: this.props.request_detail.detail[0].m_customer_id,
            room_support_m_user_id: this.props.request_detail.detail[0].room_support_m_user_id
        });

        this.props.get_chats(0);
    }


    kaketsuke_irai() {
        var result = window.confirm('リクエストをアクアクリエイティブの画面に表示します');
        if (result) {
            this.props.kaketsukethrow({ Outai_No: this.props.request_detail.detail[0].Outai_No });
            alert('依頼しました')
            this.props.get_request_detail({ Outai_No: this.props.request_detail.detail[0].Outai_No });

        }
    }

    delete() {
        var result = window.confirm('リクエストを削除します。データは戻りません。宜しいですか？');
        if (result) {
            this.props.outai_delete({ Outai_No: this.props.request_detail.detail[0].Outai_No });
            alert("削除されました");

            if (this.props.request_detail.detail[0].room_support_m_chat_id) {
                this.props.chat_complet({
                    id: this.props.request_detail.detail[0].room_support_m_chat_id
                })
                alert("チャットは完了になりました。");
            }


            this.props.reqtoggle();
        }
    }

    kouji_list() {

        this.KanrenKoujitoggle();

    }


    handleTabChange = (event: any, newValue: string) => {
        this.setState({ responseTabs: newValue });

        // 過去のリクエストタブの場合、読み込みを開始する
        if (newValue == "k3") {
            var data = {
                Tatemono_Id: this.props.request_detail.detail[0].Tatemono_Id,
                Room_Id: this.props.request_detail.detail[0].Room_Id
            }
            this.props.get_past_request(data);

            // 契約情報のタブ　読み込み開始
        } else if (newValue == "k2") {

            this.props.get_keiyaku({ Keiyaku_No: this.props.request_detail.detail[0].Keiyaku_No });

            // SMS履歴のタブ
        } else if (newValue == "k4") {

            var data = {
                Tatemono_Id: this.props.request_detail.detail[0].Tatemono_Id,
                Room_Id: this.props.request_detail.detail[0].Room_Id
            }
            this.props.get_past_request(data);
            var tel = this.props.request_detail.detail[0].Renraku_Saki.replace("-", "").replace("-", "").replace("　", "").replace(" ", "").replace(/\r?\n/g, "");

            this.props.get_sms(tel);
        }

    };


    tabreset() {
        this.setState({ disp: !this.state.disp })

        setTimeout(() => {
            this.setState({ disp: !this.state.disp })
        }, 1000)

        this.setState({ responseTabs: "k1" });

    }

    handlecustomerTabChange = (event: any, newValue: string) => {
        this.setState({ customerTabs: newValue });
    };


    popup() {
        this.setState({ popup: !this.state.popup })
    }

    kanryou() {
        this.setState({ disp: !this.state.disp })
        setTimeout(() => {
            this.props.get_request_detail({ Outai_No: this.props.request_detail.detail[0].Outai_No });
            this.setState({ disp: !this.state.disp, Completetoggle: !this.state.Completetoggle })
        }, 1000)
    }

    mikanryou() {
        this.props.outai_complete({
            Outai_Joukyou_Name: "応対中",
            Outai_No: this.props.request_detail.detail[0].Outai_No,
            room_support_m_chat_id: this.props.request_detail.detail[0].room_support_m_chat_id
        });

        this.setState({ disp: !this.state.disp })

        setTimeout(() => {
            this.props.get_request_detail({ Outai_No: this.props.request_detail.detail[0].Outai_No });
            this.setState({ disp: !this.state.disp })
        }, 1000)
    }

    chat_kanryou() {
        this.props.chat_kanryou(
            {
                id: this.props.request_detail.detail[0].room_support_m_chat_id,
                user: this.props.user_details.id
            });

        this.setState({ disp: !this.state.disp })

        setTimeout(() => {
            this.props.get_request_detail({ Outai_No: this.props.request_detail.detail[0].Outai_No });
            this.setState({ disp: !this.state.disp })
        }, 2000)
    }

    // 新しくチャットを追加する。
    chat_plus() {

        var result = window.confirm('契約者とのチャットが作成されます。顧客が契約者でない場合は作成しても届きません。このまま作成しますか？');
        if (result) {


            this.setState({
                disp: !this.state.disp,
                search_status: !this.state.search_status
            })

            // チャット追加
            this.props.new_chat({
                room_support_m_user_id: this.props.request_detail.m_customers.room_support_m_user_id
            });
            alert("チャットが作成されました。")


            setTimeout(() => {
                // 返ってきたチャットのIDをリクエストに追加
                this.props.chat_plus({
                    room_support_m_chat_id: this.props.new_chat_id.room_support_m_chat_id,
                    Outai_No: this.props.request_detail.detail[0].Outai_No,
                    main_outai_sub: this.props.request_detail.detail[0].main_outai_sub
                })

                setTimeout(() => {
                    this.props.get_request_detail({ Outai_No: this.props.request_detail.detail[0].Outai_No });
                    this.setState({
                        disp: !this.state.disp,
                        search_status: !this.state.search_status
                    })
                }, 2000)

            }, 3000)

        }
    }

    chat_plus_update() {
        setTimeout(() => {
            // 返ってきたチャットのIDをリクエストに追加
            this.props.chat_plus({
                room_support_m_chat_id: this.props.new_chat_id.room_support_m_chat_id,
                Outai_No: this.props.request_detail.detail[0].Outai_No,
                main_outai_sub: this.props.request_detail.detail[0].main_outai_sub
            })

            setTimeout(() => {
                this.props.get_request_detail({ Outai_No: this.props.request_detail.detail[0].Outai_No });
                this.setState({
                    disp: !this.state.disp,
                    search_status: !this.state.search_status
                })
            }, 2000)

        }, 3000)
    }

    disp_reset() {
        this.setState({
            disp: !this.state.disp,
            search_status: !this.state.search_status
        })
    }


    ipad_mode() {
        this.setState({
            ipad_height: this.props.request_detail.detail.length * 30
        })
    }


    render() {

        if (this.state.search_status == true) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'auto';
        }
        var shokai_D = "";
        shokai_D = moment(this.props.request_detail[0]).tz("utc").format('YYYY-MM-DD');

        if (this.state.disp == true) {

            return (
                <div style={{ background: "#b0c4de" }} >

                    <Paper square={false} style={{ marginTop: 10 }}>

                        <Tabs
                            value={this.state.responseTabs}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            onChange={this.handleTabChange}
                            aria-label="disabled tabs example"
                            style={{ float: "left" }}
                        >


                            <div style={{ marginTop: 15 }}>
                                NO.{this.props.request_detail.detail ? this.props.request_detail.detail[0].Outai_No : ""}
                            </div>

                            <span style={{ marginLeft: 20, marginTop: 15, marginRight: 20 }}>
                                重要度：
                                {this.props.request_detail.detail ?
                                    this.props.request_detail.detail[0].importance ?
                                        this.props.request_detail.detail[0].importance == 1 ?

                                            <span>
                                                <i className="fas fa-star" onClick={() => this.star_minus(1)} style={{ cursor: "pointer" }}></i>
                                                <i className="far fa-star" onClick={() => this.star_plus(1)} style={{ marginLeft: 5, cursor: "pointer" }}></i>
                                                <i className="far fa-star" onClick={() => this.star_plus(2)} style={{ marginLeft: 5, cursor: "pointer" }}></i>
                                            </span>
                                            :

                                            this.props.request_detail.detail[0].importance == 2 ?

                                                <span>
                                                    <i className="fas fa-star" onClick={() => this.star_minus(2)} style={{ cursor: "pointer" }}></i>
                                                    <i className="fas fa-star" onClick={() => this.star_minus(1)} style={{ marginLeft: 5, cursor: "pointer" }}></i>
                                                    <i className="far fa-star" onClick={() => this.star_plus(1)} style={{ marginLeft: 5, cursor: "pointer" }}></i>
                                                </span>
                                                :

                                                this.props.request_detail.detail[0].importance == 3 ?

                                                    <span>
                                                        <i className="fas fa-star" onClick={() => this.star_minus(3)} style={{ cursor: "pointer" }}></i>
                                                        <i className="fas fa-star" onClick={() => this.star_minus(2)} style={{ marginLeft: 5, cursor: "pointer" }}></i>
                                                        <i className="fas fa-star" onClick={() => this.star_minus(1)} style={{ marginLeft: 5, cursor: "pointer" }}></i>
                                                    </span>
                                                    :
                                                    ""

                                        :
                                        <span>
                                            <i className="far fa-star" onClick={() => this.star_plus(1)} style={{ cursor: "pointer" }}></i>
                                            <i className="far fa-star" onClick={() => this.star_plus(2)} style={{ marginLeft: 5, cursor: "pointer" }}></i>
                                            <i className="far fa-star" onClick={() => this.star_plus(3)} style={{ marginLeft: 5, cursor: "pointer" }}></i>
                                        </span>

                                    :
                                    ""}
                            </span>

                            <Tab style={{ color: "black" }} className={this.props.classes.tab} label="リクエスト情報" value="k1" />
                            {/* <Tab className={this.props.classes.tab} label="アプリチャット" value="k4" /> */}
                            {/* <Tab className={this.props.classes.tab} label="契約情報" value="k2" /> */}
                            <Tab style={{ color: "black" }} className={this.props.classes.tab} label="この部屋の過去のリクエスト" value="k3" />
                            <Tab style={{ color: "black" }} className={this.props.classes.tab} label="この連絡先へのSMS履歴" value="k4" />




                        </Tabs>

                        <span style={{ float: "right", marginLeft: 20, marginRight: 20, marginTop: 15 }}>
                            更新日時:{this.props.request_detail.detail && this.props.request_detail.detail[0].updated_at ? moment(this.props.request_detail.detail[0].updated_at).utc().format('YYYY-MM-DD HH:mm:ss') : "-"} {this.props.request_detail.detail ? this.props.request_detail.detail[0].updated_user_name : ""}
                        </span>

                        <span style={{ float: "right", marginTop: 15 }}>
                            登録日時:{this.props.request_detail.detail ? moment(this.props.request_detail.detail[0].created_at).utc().format('YYYY-MM-DD HH:mm:ss') : ""} {this.props.request_detail.detail ? this.props.request_detail.detail[0].created_user_name : ""}
                        </span>

                        {this.state.responseTabs === "k1" && this.props.request_detail.detail ?
                            <Card className="col-lg-12" square={true} style={{ background: "#b0c4de", padding: 5 }}>

                                <Button size="small" style={{ float: "left", margin: 5 }} onClick={this.delete} color="secondary" variant="contained">
                                    <i className="fas fa-times-circle"></i>削除
                                </Button>
                                <Button size="small" style={{ color: "white", backgroundColor: "#28a745", float: "left", margin: 5 }} onClick={this.url_open_kako} variant="contained">
                                    <i className="fas fa-search"></i>過去工事
                                </Button>
                                <Button size="small" style={{ color: "white", backgroundColor: "#5bc0de", float: "left", margin: 5 }} onClick={this.kouji_list} variant="contained">
                                    <i className="fab fa-staylinked"></i>関連工事
                                </Button>
                                <Button size="small" style={{ color: "white", backgroundColor: "#f0ad4e", float: "left", margin: 5 }} onClick={this.url_open_uketuke} variant="contained">
                                    <i className="fas fa-print"></i>リクエスト受付書
                                </Button>
                                <Button size="small" style={{ color: "white", backgroundColor: "#f0ad4e", float: "left", margin: 5 }} onClick={this.url_open_isai} variant="contained">
                                    <i className="fas fa-print"></i>リクエスト委細書
                                </Button>

                                <Button size="small" style={{ color: "white", backgroundColor: "#f0ad4e", float: "left", margin: 5 }} onClick={this.url_open_kaketuke} variant="contained">
                                    <i className="fas fa-print"></i>かけつけ依頼書
                                </Button>

                                {!this.props.request_detail.detail[0].is_kaketsuke ?
                                <Button size="small" style={{ color: "white", backgroundColor: "#4169e1", float: "left", margin: 5 }} onClick={this.kaketsuke_irai} variant="contained">
                                    アクアへ依頼
                                </Button> : <Button disabled size="small" style={{ color: "white", backgroundColor: "#4169e1", float: "left", margin: 5 }} variant="contained">
                                    アクアへ依頼済み
                                </Button>}


                                <Button size="small" style={{ color: "white", backgroundColor: "#008000", float: "left", margin: 5 }} onClick={this.smstoggle} variant="contained">
                                    <i className="fas fa-sms"></i>SMS送信
                                </Button>

                                {this.props.request_detail.detail[0].room_support_m_chat_id ?
                                    <Button size="small" style={{ color: "white", backgroundColor: "#ff6347", float: "left", margin: 5 }} onClick={this.chat_list_open} variant="contained">
                                        <i className="fas fa-list"></i> チャットリスト
                                    </Button>
                                    : ""}

                                {/* {this.props.request_detail.detail[0].room_support_m_chat_id ?
                                    <Button size="small" style={{ color: "white", backgroundColor: "#ff6347", float: "left", margin: 5 }} onClick={this.chat_open} variant="contained">
                                        <i className="fas fa-comment-dots"></i>{this.props.request_detail.detail[0].process_flag == 0 ? "チャット(新着あり)" : "チャット"}
                                    </Button>
                                    : ""} */}

                                {this.props.request_detail.detail[0].room_support_m_chat_id ?
                                    this.props.request_detail.detail[0].process_flag == 0 ?
                                        <Button className="animetion_box" size="small" style={{ color: "white", backgroundColor: "#ff007f", float: "left", margin: 5 }} onClick={this.chat_open} variant="contained">
                                            <i className="fas fa-comment-dots"></i>チャット(新着あり)
                                        </Button> :
                                        <Button size="small" style={{ color: "white", backgroundColor: "#ff6347", float: "left", margin: 5 }} onClick={this.chat_open2} variant="contained">
                                            <i className="fas fa-comment-dots"></i>チャット
                                        </Button>

                                    : ""}


                                {this.props.request_detail.detail[0].room_support_m_chat_id ?
                                    ""
                                    :
                                    this.props.request_detail.m_customers ?
                                        this.props.request_detail.m_customers.room_support_m_user_id ?
                                            moment(this.props.request_detail.detail[0].created_at).utc().format('YYYY-MM-DD') >= moment(this.props.request_detail.m_customers.Keiyaku_Date ? this.props.request_detail.m_customers.Keiyaku_Date : "2000-01-01").utc().format('YYYY-MM-DD') ?
                                                <Button size="small" style={{ color: "white", backgroundColor: "#c71585", float: "left", margin: 5 }} onClick={this.newchattoggle} variant="contained">
                                                    <i className="fas fa-comment-medical"></i>チャットを作成する
                                                </Button> : "" : "" : ""}






                                {/* {this.props.request_detail.detail[0].process_flag == 0 ?
                                    <Button size="small" style={{ color: "white", backgroundColor: "#ff6347", float: "left", margin: 5 }} onClick={this.chat_kanryou} variant="contained">
                                        <i className="fas fa-check"></i>最後のチャットに対応した
                            </Button>
                                    : ""} */}

                                {this.props.request_detail.detail[0].process_flag == 1 ?
                                    <span>最新チャット対応：{this.props.request_detail.detail[0].chat_user}({moment.utc(new Date(this.props.request_detail.detail[0].process_at)).format('YYYY-MM-DD HH:mm:ss')})</span>
                                    : ""}

                                <Button size="small" style={{ float: "right", margin: 5 }} onClick={this.edittoggle} color="primary" variant="contained">編集</Button>

                                {this.props.request_detail.detail[0].Outai_Joukyou_Name == "応対中" ?
                                    <Button size="small" style={{ float: "right", margin: 5, backgroundColor: "#40e0d0", }} onClick={this.Completetoggle} variant="contained">
                                        {this.props.request_detail.detail[0].room_support_m_chat_id ? "リクエスト(チャット)完了にする" : "リクエスト完了にする"}
                                    </Button>
                                    :
                                    <Button size="small" style={{ float: "right", margin: 5, backgroundColor: "#ffff00", }} onClick={this.Completetoggle} variant="contained">リクエスト応対中に戻す</Button>}
                                <Button size="small" style={{ float: "right", margin: 5 }} onClick={this.url_open} variant="contained">
                                    HS版へ<i className="fas fa-external-link-alt"></i>
                                </Button>

                                {this.props.request_detail.detail[0].Keiyaku_No ?
                                    <Button size="small" style={{ color: "white", backgroundColor: "#28a745", float: "right", margin: 5 }} onClick={this.url_open_nyukyo} variant="contained">
                                        <i className="fa fa-broom"></i>入居前チェック
                                    </Button> : ""}

                                <Button size="small" style={{ color: "white", backgroundColor: "#28a745", float: "right", margin: 5 }} onClick={this.url_open_seisan} variant="contained">
                                    <i className="fas fa-broom"></i>精算フォロー
                                </Button>

                                <div style={{ float: "right", margin: 5, fontSize: 15 }}>
                                    {this.props.request_detail.detail[0].Kouji_Gyousha ? "原復業者:" + this.props.request_detail.detail[0].Kouji_Gyousha : ""}
                                    {this.props.request_detail.detail[0].completion_at ? "(完工:" + moment(this.props.request_detail.detail[0].completion_at).utc().format('YYYY-MM-DD') + ")" : ""}
                                </div>

                                <Card style={{ marginTop: 10 }} className="col-lg-12">
                                    <CardContent style={{ float: "left", padding: 10 }} className="col-lg-12">

                                        <Row>


                                            {/* {this.props.request_detail.detail[0].Outai_Joukyou_Name == "完了" ?
                                                        <tr style={{ border: "0px" }}>
                                                            <th style={{ textAlign: "left" }} colSpan={1} >完了内容</th>
                                                            <td colSpan={3} >
                                                                {this.props.request_detail.detail[0].completed_remarks}
                                                            </td>
                                                        </tr> : ""} */}
                                            {this.props.request_detail.detail[0].Outai_Joukyou_Name == "完了" ?
                                                <table className="col-lg-12" style={{ textAlign: "left", border: "0px" }}>
                                                    <tbody>
                                                        <tr style={{ border: "0px" }}>
                                                            <th style={{ width: 100, textAlign: "left", backgroundColor: "#f0e68c" }}>
                                                                完了タイトル
                                                            </th>
                                                            <td colSpan={1} >
                                                                {this.props.request_detail.detail[0].completed_title}
                                                            </td>
                                                            <th style={{ width: 100, textAlign: "left", backgroundColor: "#f0e68c" }} >
                                                                完了結果
                                                            </th>
                                                            <td colSpan={3} >
                                                                {this.props.request_detail.detail[0].completed_remarks}
                                                            </td>
                                                            <th style={{ width: 100, textAlign: "left", backgroundColor: this.props.request_detail.detail[0].not_published_flag == 0 ? "#f0e68c" : "#ff6347" }} >
                                                                {this.props.request_detail.detail[0].not_published_flag == 0 ? "掲載する" : "掲載しない"}
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table> : ""}

                                            <table className="col-lg-6" style={{ textAlign: "left", border: "0px" }}>
                                                <tbody>

                                                    <tr style={{ border: "0px" }}>

                                                        <th style={{ textAlign: "left" }} colSpan={1} >受付日</th>
                                                        <td colSpan={2} >
                                                            {this.props.request_detail.detail[0].created_at ? moment(this.props.request_detail.detail[0].created_at).utc().format('YYYY-MM-DD') : ""}
                                                        </td>

                                                        <th style={{ textAlign: "left" }} colSpan={1} >受付方法</th>
                                                        <td colSpan={2} >
                                                            {this.props.request_detail.detail[0].room_support_m_chat_id ? "アプリ"
                                                                :
                                                                this.props.request_detail.detail[0].type == '通常対応' ? "直接受付" : this.props.request_detail.detail[0].type}                                                    </td>

                                                    </tr>

                                                    <tr style={{ border: "0px" }}>

                                                        <th style={{ textAlign: "left" }} colSpan={1} >
                                                            物件名
                                                            {this.props.request_detail.detail[0].is_mamorocca ? <span className="label" style={{ float: "right", backgroundColor: "#deb887" }}>
                                                                マモロッカ</span> : ""}
                                                            {this.props.request_detail.detail[0].Group_Naiyou_No ?
                                                                <span className="label" style={{ float: "right", backgroundColor: "green" }}>
                                                                    かけつけ</span> :


                                                                this.props.request_detail.kaketsuke[0] ?

                                                                    this.props.request_detail.kaketsuke[0]['keiyaku_type'] == 3 ?
                                                                        <span className="label" style={{ float: "right", backgroundColor: "#90ee90" }}>
                                                                            シード？</span>
                                                                        :
                                                                        <span className="label" style={{ float: "right", backgroundColor: "green" }}>
                                                                            かけつけ</span> :
                                                                    this.props.request_detail.kaketsuke2[0] ?
                                                                        <span className="label" style={{ float: "right", backgroundColor: "green" }}>
                                                                            かけつけ</span> : ""
                                                            }

                                                        </th>
                                                        <td colSpan={3} >
                                                            {this.props.request_detail.detail[0].Tatemono_Name ? this.props.request_detail.detail[0].Tatemono_Name : this.props.request_detail.detail[0].P_Name}
                                                            <IconButton onClick={this.url_open_tatemono} style={{ margin: 0 }} aria-label="delete" className={this.props.classes.margin}>
                                                                <i style={{ fontSize: 13 }} className="fas fa-link"></i>
                                                            </IconButton>
                                                        </td>
                                                        <td colSpan={2} >
                                                            {this.props.request_detail.detail[0].Room_Name ? this.props.request_detail.detail[0].Room_Name : this.props.request_detail.detail[0].PA_Name}
                                                            {this.props.request_detail.detail[0].Room_Name ?
                                                                <IconButton onClick={this.url_open_room} style={{ margin: 0 }} aria-label="delete" className={this.props.classes.margin}>
                                                                    <i style={{ fontSize: 13 }} className="fas fa-link"></i>
                                                                </IconButton>
                                                                : ""}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >
                                                            顧客名
                                                            {this.props.request_detail.m_customers ?
                                                                this.props.request_detail.m_customers.room_support_m_user_id ?
                                                                    moment(this.props.request_detail.detail[0].created_at).utc().format('YYYY-MM-DD') >= moment(this.props.request_detail.m_customers.Keiyaku_Date ? this.props.request_detail.m_customers.Keiyaku_Date : "2000-01-01").utc().format('YYYY-MM-DD') ?
                                                                        <span className="label" style={{ float: "right", backgroundColor: "orange" }}>
                                                                            契約者はアプリ登録済み</span> : "" : "" : ""}
                                                        </th>
                                                        <td colSpan={5} >
                                                            {this.props.request_detail.detail[0].Kokyaku_Name}
                                                            {this.props.request_detail.detail[0].Keiyaku_No ?
                                                                <IconButton onClick={this.MemoModaltoggle} style={{ margin: 0 }} aria-label="delete" className={this.props.classes.margin}>
                                                                    <TextSnippetIcon />
                                                                </IconButton> : ""}
                                                            {this.props.request_detail.m_customers ?
                                                                this.props.request_detail.m_customers.room_support_m_user_id ?
                                                                    moment(this.props.request_detail.detail[0].created_at).utc().format('YYYY-MM-DD') >= moment(this.props.request_detail.m_customers.Keiyaku_Date ? this.props.request_detail.m_customers.Keiyaku_Date : "2000-01-01").utc().format('YYYY-MM-DD') ?
                                                                        <span style={{ float: "right" }}>
                                                                            (アプリ登録名:{this.props.request_detail.m_customers.name})</span> : "" : "" : ""}
                                                            {this.props.request_detail.shinyou ?
                                                                this.props.request_detail.shinyou[0] ?
                                                                    <span onClick={this.shinyouModaltoggle} className="label" style={{ cursor: "pointer", float: "right", backgroundColor: "red" }}>
                                                                        信用情報マッチングあり</span> : "" : ""}
                                                        </td>

                                                    </tr>
                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >連絡先</th>
                                                        <td colSpan={5} >
                                                            {this.props.request_detail.detail[0].Renraku_Saki}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >顧客メモ</th>
                                                        <td colSpan={5} >
                                                            {this.props.request_detail.detail[0].Keiyaku_No ?
                                                                this.props.request_detail.detail[0].customer_memo : "契約情報無し"}
                                                        </td>
                                                    </tr>

                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >契約日</th>
                                                        <td colSpan={1} >
                                                            {this.props.request_detail.detail[0].Keiyaku_Date ? moment(this.props.request_detail.detail[0].Keiyaku_Date).utc().format('YYYY-MM-DD') : "-"}
                                                            {this.props.request_detail.detail[0].workflow_id ?
                                                                <IconButton onClick={this.url_open_keiyaku} style={{ margin: 0 }} aria-label="delete" className={this.props.classes.margin}>
                                                                    <i style={{ fontSize: 13 }} className="fas fa-link"></i>
                                                                </IconButton>
                                                                : ""}
                                                        </td>

                                                        <th style={{
                                                            textAlign: "left",
                                                            backgroundColor:
                                                                this.props.request_detail.detail[0].Kaiyaku_Date && this.props.request_detail.detail[0].kaiyaku_Is_Cancel == 0 ? "#f0e68c" : ""
                                                        }} colSpan={1} >解約日</th>
                                                        <td style={{
                                                            backgroundColor:
                                                                this.props.request_detail.detail[0].Kaiyaku_Date && this.props.request_detail.detail[0].kaiyaku_Is_Cancel == 0 ? "#fffacd" : ""
                                                        }} colSpan={1} >
                                                            {this.props.request_detail.detail[0].Kaiyaku_Date && this.props.request_detail.detail[0].kaiyaku_Is_Cancel == 0 ?
                                                                <span style={{ color: "red", fontWeight: "bold" }}>{moment(this.props.request_detail.detail[0].Kaiyaku_Date).utc().format('YYYY-MM-DD')}</span> : "-"}
                                                        </td>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >オーナー担当</th>
                                                        <td colSpan={1} >
                                                            {this.props.request_detail.detail[0].Shoyuusha_Tantou_Name}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table className="col-lg-6" style={{ textAlign: "left", border: "0px" }}>
                                                <tbody>
                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >
                                                            区分
                                                        </th>
                                                        <td colSpan={1} >
                                                            {this.props.request_detail.detail[0].Tatemono_Name ? "建物" : "駐車場"}
                                                        </td>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >顧客区分</th>
                                                        <td colSpan={1} >
                                                            {this.props.request_detail.detail[0].Kokyaku_Kbn}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >受電者</th>
                                                        <td colSpan={1} >
                                                            {this.props.request_detail.detail[0].room_support_m_chat_id ?
                                                                <span className="label" style={{ backgroundColor: "orange" }}>
                                                                    アプリ
                                                                </span>
                                                                :
                                                                this.props.request_detail.detail[0].Tenpo_Name_Short ? this.props.request_detail.detail[0].Tenpo_Name_Short + " " + this.props.request_detail.detail[0].shokai_user : this.props.request_detail.detail[0].shokai_user ? this.props.request_detail.detail[0].shokai_user : ""}

                                                        </td>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >対応担当</th>
                                                        <td colSpan={1} >
                                                            {this.props.request_detail.detail[0].taiou_user}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >初回対応日</th>
                                                        <td colSpan={1} >
                                                            {this.props.request_detail.detail[0].Syokai_Date ? moment(this.props.request_detail.detail[0].Syokai_Date).tz("utc").format('YYYY-MM-DD') : ""}
                                                            <span style={{ marginLeft: 10 }}>
                                                                {this.props.request_detail.detail[0].Shokai_H}
                                                                {this.props.request_detail.detail[0].Shokai_H ? ":" : ""}
                                                                {this.props.request_detail.detail[0].Shokai_M}
                                                            </span>
                                                        </td>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >初回手配業者</th>
                                                        <td colSpan={1} >
                                                            {this.props.request_detail.detail[0].Gyosha_Name}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >次回対応予定</th>
                                                        <td colSpan={1} >
                                                            {this.props.request_detail.detail[0].Next_Date ? moment(this.props.request_detail.detail[0].Next_Date).tz("utc").format('YYYY-MM-DD') : ""}

                                                            <span style={{ marginLeft: 5 }}>
                                                                {this.props.request_detail.detail[0].Next_Time ? this.props.request_detail.detail[0].Next_Time : ""}
                                                            </span>
                                                        </td>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >次回手配業者</th>
                                                        <td colSpan={1} >
                                                            {this.props.request_detail.detail[0].Gyosha2_Name}
                                                        </td>
                                                    </tr>
                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >業者連絡可否</th>
                                                        <td colSpan={1} >
                                                            {this.props.request_detail.detail[0].gyousha_renraku == 0 ? "未確認" : ""}
                                                            {this.props.request_detail.detail[0].gyousha_renraku == 1 ? "可能" : ""}
                                                            {this.props.request_detail.detail[0].gyousha_renraku == 2 ? "不可" : ""}
                                                            {this.props.request_detail.detail[0].gyousha_renraku == 3 ? "報告不要" : ""}
                                                        </td>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >業者担当</th>
                                                        <td colSpan={1} >
                                                            {this.props.request_detail.detail[0].gyousha_tantou}
                                                        </td>
                                                    </tr>

                                                    <tr style={{ border: "0px" }}>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >対応状況</th>
                                                        <td colSpan={1} >
                                                            {this.props.request_detail.detail[0].Outai_Joukyou_Name}
                                                        </td>
                                                        <th style={{ textAlign: "left" }} colSpan={1} >対応完了日</th>
                                                        <td colSpan={1} >
                                                            {this.props.request_detail.detail[0].complete_date ? moment(this.props.request_detail.detail[0].complete_date).utc().format('YYYY-MM-DD') : ""}
                                                        </td>
                                                    </tr>


                                                    {/* <tr style={{ border: "0px" }}>
                                                    <td colSpan={　1} >
                                                        {this.props.request_detail.detail[0].type == "通常対応" ? "直接受付" : this.props.request_detail.detail[0].type}
                                                    </td>
                                                    {this.props.request_detail.detail[0].Keiyaku_Date ?
                                                        <td colSpan={1} >
                                                            契約日:{moment(this.props.request_detail.detail[0].Keiyaku_Date).utc().format('YYYY-MM-DD')}
                                                        </td>
                                                        : ""}
                                                </tr> */}
                                                </tbody>
                                            </table>
                                        </Row>
                                    </CardContent>
                                </Card>
                                {/* <Row> */}
                                {/* <div style={{ float: "right" }} className="col-lg-6"> */}
                                <Card style={{ marginTop: 3 }} className="col-lg-12">
                                    <CardContent style={{ float: "left" }} className="col-lg-12">

                                        {/* <h5 style={{ fontWeight: "bold" }}>
                                            区分：
                                    {(this.props.request_detail.subkubun) ?
                                                this.props.request_detail.subkubun.map((str: any) => {
                                                    return str.name + "　"
                                                }) : ' '
                                            }
                                        </h5> */}
                                        <table style={{ textAlign: "left" }}>
                                            <tbody>
                                                <tr style={{ border: "0px" }}>
                                                    <th style={{ width: 100, textAlign: "left" }}>タイトル</th>
                                                    <td>
                                                        {this.props.request_detail.detail[0].Title}
                                                    </td>
                                                    <th style={{ width: 100, textAlign: "left" }}>区分</th>
                                                    <td>

                                                        <span style={{ fontWeight: "bold", marginRight: 10 }}>
                                                            {this.props.request_detail.detail[0].outai_sub_name}
                                                        </span>
                                                        <span>
                                                            {(this.props.request_detail.subkubun) ?
                                                                this.props.request_detail.subkubun.map((str: any) => {
                                                                    if (str.name == "設備") {
                                                                        return "入居時リクエスト(設備)" + "　"
                                                                    } else if (str.name == "人的") {
                                                                        return "入居時リクエスト(人的)" + "　"
                                                                    } else {
                                                                        return str.name + "　"
                                                                    }
                                                                }) : ' '
                                                            }
                                                        </span>
                                                    </td>

                                                    {this.props.kouji_list.kouji ?
                                                        <th rowSpan={2} style={{ width: 80, textAlign: "left" }} >工事情報</th> : ""}
                                                    {this.props.kouji_list.kouji ?
                                                        <td rowSpan={2} style={{ width: 100, textAlign: "left" }}>
                                                            <div>
                                                                <div>発注日：</div>
                                                                <div>
                                                                    {this.props.kouji_list.kouji[0].order_issue ? moment(this.props.kouji_list.kouji[0].order_issue).tz("utc").format('YYYY-MM-DD') : "-"}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div>完工日:</div>
                                                                <div>
                                                                    {this.props.kouji_list.kouji[0].completion_enter ? moment(this.props.kouji_list.kouji[0].completion_enter).tz("utc").format('YYYY-MM-DD') : "-"}
                                                                </div>
                                                            </div>
                                                        </td> : ""}

                                                </tr>
                                                <tr style={{ border: "0px" }}>
                                                    <th style={{ textAlign: "left" }} >内容</th>

                                                    <td>

                                                        {this.props.request_detail.detail[0].Naiyou.split("\n").map((i: any, key: any) => {
                                                            return <div key={key}>{i}</div>;
                                                        })}

                                                        {/* {this.props.request_detail.detail[0].Naiyou} */}
                                                    </td>

                                                    <th style={{ textAlign: "left" }} >備考</th>
                                                    <td>
                                                        {this.props.request_detail.detail[0].remarks}
                                                    </td>



                                                </tr>


                                                {/* <tr style={{ border: "0px" }}>

                                                    <th style={{ textAlign: "left" }} >備考</th>
                                                    <td>
                                                        {this.props.request_detail.detail[0].remarks}
                                                    </td>
                                                </tr> */}
                                            </tbody>
                                        </table>


                                    </CardContent>
                                </Card>
                                {/* </div> */}
                                {/* <div className="col-lg-6"> */}
                                <Card style={{ marginTop: 3 }} className="col-lg-12">
                                    <CardContent style={{ float: "left" }} className="col-lg-12">
                                        <Button onClick={this.reqtoggle} color="primary" variant="outlined">
                                            <i className="fas fa-plus"></i>　追加</Button>


                                        <span style={{ fontSize: 15 }}>
                                            <span>
                                                <span style={{ marginLeft: 50, color: "#f0e68c" }}>■</span> 予定
                                            </span>

                                            <span>
                                                <span style={{ marginLeft: 20, color: "#ee82ee" }}>■</span> 訪問依頼
                                            </span>
                                        </span>

                                        <Button style={{ float: "right", marginBottom: "10px", }} onClick={this.ikkatsutoggle} color="primary" variant="contained">
                                            <i className="fas fa-eye"></i>　一括表示</Button>

                                        <span style={{ cursor: "pointer", float: "right", fontSize: 10, marginRight: 20 }}>
                                            <Button onClick={this.ipad_mode} size="small" variant="outlined" color="primary">
                                                <TabletMacIcon />
                                            </Button>
                                        </span>

                                        <DetailListGrid ipad_height={this.state.ipad_height} />
                                    </CardContent>
                                </Card>
                                {/* </div> */}
                                {/* </Row> */}
                            </Card> : ""}



                        {this.state.responseTabs === "k2" ? <Card className="col-lg-12" square={true} style={{ background: "#b0c4de", padding: 5 }}>
                            <Card style={{ marginTop: 10 }} className="col-lg-12">
                                <CardContent style={{ float: "left" }} className="col-lg-12">
                                    <KeiyakuDetail />
                                </CardContent>
                            </Card>
                        </Card> : ""}

                        {this.state.responseTabs === "k3" ? <Card className="col-lg-12" square={true} style={{ background: "#b0c4de", padding: 5 }}>
                            <Card style={{ marginTop: 10 }} className="col-lg-12">
                                <CardContent style={{ float: "left" }} className="col-lg-12">
                                    <PastRequest
                                        tabreset={this.tabreset} />
                                </CardContent>
                            </Card>
                        </Card> : ""}

                        {this.state.responseTabs === "k4" ? <Card className="col-lg-12" square={true} style={{ background: "#b0c4de", padding: 5 }}>
                            <Card style={{ marginTop: 10 }} className="col-lg-12">
                                <CardContent style={{ float: "left" }} className="col-lg-12">
                                    <SmsGrid
                                        tabreset={this.tabreset} />
                                </CardContent>
                            </Card>
                        </Card> : ""}

                    </Paper>




                    {
                        this.props.request_detail.detail ?
                            < Modal isOpen={this.state.reqtoggle} toggle={this.reqtoggle} size="kokyaku" fade={false} backdrop={false}>
                                {/* <ModalHeader toggle={this.reqtoggle}>応対追加</ModalHeader> */}
                                <DialogTitle id="customized-dialog-title" onClose={this.reqtoggle} >応対追加</DialogTitle>

                                <NewOutai
                                    OutaiShosai_No={this.props.request_detail.detail.length}
                                    Outai_No={this.props.request_detail.detail[0].Outai_No}
                                    reqtoggle={this.reqtoggle} />
                                <ModalFooter>
                                </ModalFooter>
                            </Modal > : ""
                    }

                    {
                        this.props.request_detail.detail ?
                            < Modal isOpen={this.state.edittoggle} toggle={this.edittoggle} size="request" fade={false} backdrop={false}>
                                {/* <ModalHeader toggle={this.edittoggle}>リクエスト編集</ModalHeader> */}
                                <DialogTitle id="customized-dialog-title" onClose={this.edittoggle} >リクエスト編集</DialogTitle>

                                <EditRequest
                                    edit_after_toggle={this.edit_after_toggle}
                                    edittoggle={this.edittoggle}
                                    Outai_No={this.props.request_detail.detail[0].Outai_No} />
                                <ModalFooter>
                                </ModalFooter>
                            </Modal > : ""
                    }

                    {
                        this.props.request_detail.detail ?
                            < Modal isOpen={this.state.ikkatsutoggle} toggle={this.ikkatsutoggle} size="kokyaku" fade={false}>
                                {/* <ModalHeader toggle={this.ikkatsutoggle}>一括表示</ModalHeader> */}
                                <DialogTitle id="customized-dialog-title" onClose={this.ikkatsutoggle} >一括表示</DialogTitle>

                                <IkkatsuModal />
                                <ModalFooter>
                                    <Button size="small" onClick={this.ikkatsutoggle} color="secondary" variant="contained">
                                        <i className="fas fa-door-closed"></i>閉じる
                                    </Button>
                                </ModalFooter>
                            </Modal > : ""
                    }

                    {
                        this.props.request_detail.detail ?
                            < Modal isOpen={this.state.smstoggle} toggle={this.smstoggle} size="kokyaku" fade={false}>
                                {/* <ModalHeader toggle={this.smstoggle}>SMS</ModalHeader> */}
                                <DialogTitle id="customized-dialog-title" onClose={this.smstoggle} >SMS</DialogTitle>
                                <SmsModal
                                    Outai_No={this.props.request_detail.detail[0].Outai_No}
                                    smstoggle={this.smstoggle}
                                />
                                <ModalFooter>
                                </ModalFooter>
                            </Modal > : ""
                    }

                    {
                        this.props.request_detail.detail ?
                            < Modal isOpen={this.state.popup} toggle={this.popup} size="kokyaku" fade={false}>
                                <Card style={{ marginTop: 10 }} className="col-lg-12">
                                    <CardContent style={{ float: "left" }} className="col-lg-12">
                                        <TextField
                                            id="outlined-read-only-input"
                                            label="案件内容"
                                            fullWidth
                                            multiline
                                            defaultValue={this.props.request_detail.detail[0].Naiyou}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                            style={{ marginTop: "10px" }}
                                        />
                                    </CardContent>
                                </Card>
                            </Modal > : ""
                    }


                    {
                        this.props.request_detail.detail ?
                            < Modal isOpen={this.state.KanrenKoujitoggle} toggle={this.KanrenKoujitoggle} size="kokyaku" fade={false}>
                                {/* <ModalHeader toggle={this.smstoggle}>SMS</ModalHeader> */}
                                <DialogTitle id="customized-dialog-title" onClose={this.KanrenKoujitoggle} >関連工事/伝票</DialogTitle>
                                <KanrenKoujiModal />
                                <ModalFooter>
                                </ModalFooter>
                            </Modal > : ""
                    }
                    {
                        this.props.request_detail.detail ?
                            < Modal isOpen={this.state.MemoModaltoggle} toggle={this.MemoModaltoggle} size="xs" fade={false}>
                                <DialogTitle id="customized-dialog-title" onClose={this.MemoModaltoggle} >顧客メモ</DialogTitle>
                                <MemoModal MemoModaltoggle={this.MemoModaltoggle} />
                                <ModalFooter>
                                </ModalFooter>
                            </Modal > : ""
                    }
                    {
                        this.props.request_detail.detail ?
                            < Modal isOpen={this.state.shinyouModaltoggle} toggle={this.shinyouModaltoggle} size="kokyaku" fade={false}>
                                <DialogTitle id="customized-dialog-title" onClose={this.shinyouModaltoggle} >信用情報</DialogTitle>
                                <ShinyouModal shinyouModaltoggle={this.shinyouModaltoggle} />
                                <ModalFooter>
                                </ModalFooter>
                            </Modal > : ""
                    }
                    {
                        this.props.request_detail.detail ?
                            < Modal isOpen={this.state.Completetoggle} toggle={this.Completetoggle} size="xs" fade={false}>
                                {/* <ModalHeader toggle={this.smstoggle}>SMS</ModalHeader> */}
                                <DialogTitle id="customized-dialog-title" onClose={this.Completetoggle} >対応状況の変更</DialogTitle>
                                <CompleteModal kanryou={this.kanryou} />
                                <ModalFooter>
                                </ModalFooter>
                            </Modal > : ""
                    }

                    {
                        this.props.request_detail.detail && this.props.request_detail.m_customers ?
                            < Modal isOpen={this.state.newchattoggle} toggle={this.newchattoggle} size="kokyaku" fade={false}>
                                {/* <ModalHeader toggle={this.smstoggle}>SMS</ModalHeader> */}
                                <DialogTitle id="customized-dialog-title" onClose={this.newchattoggle} >チャット作成</DialogTitle>
                                <NewChatModal
                                    chat_plus_update={this.chat_plus_update}
                                    room_support_m_chat_id={this.props.new_chat_id.room_support_m_chat_id}
                                    disp_reset={this.disp_reset}
                                    Outai_No={this.props.request_detail.detail[0].Outai_No}
                                    newchattoggle={this.newchattoggle}
                                    room_support_m_user_id={this.props.request_detail.m_customers.room_support_m_user_id}
                                />
                                <ModalFooter>
                                </ModalFooter>
                            </Modal > : ""
                    }

                </div >
            );
        } else {
            return (
                <div>
                    <i style={{ fontSize: 26, textAlign: "center" }} className="fas fa-spinner"></i>
                </div>
            )
        }
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        ky: state.KyDetail.ky,
        kys_biko: state.kys.kys_biko,
        request_detail: state.Adjustment.request_detail,
        user_details: state.masterReducer.user_details,
        kouji_list: state.RequestDetail.kouji_list,
        new_chat_id: state.chat.new_chat_id,
        ikkatsu_file: state.RequestDetail.ikkatsu_file,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        change_mail_new(state: any) {
            dispatch(Mail.change_mail_new(state))
        },
        get_response_lines(state: any) {
            dispatch(Line.get_response_lines(state))
        },
        get_chats(state: any) {
            dispatch(Chat.get_chats(state))
        },
        get_smses(state: any) {
            dispatch(Sms.get_smses(state))
        },
        get_kys(state: number) {
            dispatch(KysSaga.get_kys(state))
        },
        get_request_detail(state: any) {
            dispatch(Adjustment.get_request_detail(state))
        },
        get_tatemono(state: any) {
            dispatch(Adjustment.get_tatemono(state))
        },
        get_room(state: any) {
            dispatch(Adjustment.get_room(state))
        },
        get_gyousya(state: any) {
            dispatch(Adjustment.get_gyousya(state))
        },
        get_outaisub(state: any) {
            dispatch(Adjustment.get_outaisub(state))
        },
        get_past_request(state: any) {
            dispatch(RequestDetailSaga.get_past_request(state))
        },
        get_keiyaku(state: any) {
            dispatch(RequestDetailSaga.get_keiyaku(state))
        },
        outai_delete(state: any) {
            dispatch(RequestDetailSaga.outai_delete(state))
        },
        outai_complete(state: any) {
            dispatch(RequestDetailSaga.outai_complete(state))
        },
        chat_kanryou(state: any) {
            dispatch(RequestDetailSaga.chat_kanryou(state))
        },
        chat_complet(state: any) {
            dispatch(RequestDetailSaga.chat_complet(state))
        },
        get_kouji(state: any) {
            dispatch(RequestDetailSaga.get_kouji(state))
        },
        get_request_excels(state: any) {
            dispatch(RequestDetailSaga.get_request_excels(state))
        },
        get_parking(state: any) {
            dispatch(Adjustment.get_parking(state))
        },
        get_parkingarea(state: any) {
            dispatch(Adjustment.get_parkingarea(state))
        },
        get_chatlist(state: any) {
            dispatch(Keiyaku.get_chatlist(state))
        },
        get_sms_share(state: any) {
            dispatch(Sms.get_sms_share(state))
        },
        set_process_flag(state: any) {
            dispatch(Chat.set_process_flag(state))
        },
        set_Outai_No(state: any) {
            dispatch(Chat.set_Outai_No(state))
        },
        get_template_category_list(state: any) {
            dispatch(Keiyaku.get_template_category_list(state))
        },
        importance(state: any) {
            dispatch(Adjustment.importance(state))
        },
        get_kouji_list(state: any) {
            dispatch(RequestDetailSaga.get_kouji_list(state))
        },
        new_chat(state: any) {
            dispatch(Chat.new_chat(state))
        },
        chat_plus(state: any) {
            dispatch(RequestDetailSaga.chat_plus(state))
        },
        get_file(state: any) {
            dispatch(RequestDetailSaga.get_file(state))
        },
        get_sms(state: any) {
            dispatch(RequestDetailSaga.get_sms(state))
        },
        kaketsukethrow(state: any) {
            dispatch(RequestDetailSaga.kaketsukethrow(state))
        },
        get_request_detail_kaketuke_irai(state: any) {
            dispatch(RequestDetailSaga.get_request_detail_kaketuke_irai(state))
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));




