import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

import moment from 'moment'
import { connect } from 'react-redux';
import * as RentMoney from './Saga/RentMoney';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import "Components/Vacancy/node_modules/react-datepicker/dist/react-datepicker.css";
import DateFnsUtils from '@date-io/date-fns';
// import "Components/Vacancy/node_modules/react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
// import { Field, reduxForm ,InjectedFormProps} from 'redux-form';
// // import '../../Css/Table.css';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import DatePicker from "react-datepicker";
const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
interface Props {
  // minories: any,
  rent_money_bklist: any,
  // get_shlist: any,
  change_rent_money_search: any,
  rent_money_search: any,
  // search: any,
  // bklist: any,
  // get_owner_remittance_check_logs: any,
  // remittancelogcheck_search_form: any,
  // change_remittancelogcheck_search_form: any,
  // change_search_diff: any,
  // so_ymd_list_sum: number,
  // owner_remittance_check_logs_sum: number,
  // owner_remittance_check_logs: any,
  // owner_remittance_check_logs_list: any,
  // search_form: any,
  // check_completed: any,
  // get_rent_contracts_check_list: any,
  // rent_contracts_check_list: any,
  // check_completed_cancel: any,

  // get_rent_contracts: any,
  // change_search_name: any,
  // change_search_contract_date_start: any,
  // change_search_contract_date_end: any,
  // search_contract_date_start: any,
  // search_contract_date_end: any,
  // change_search_tatemono_name: any,
  // handleSubmit:any,
  // onSubmit:any,
  // get_rent_contract_accounting:any,
  // output:any,
  // name:string,
  // date1:string,
  // date2:string
}



interface State {
  reflesh: boolean
}


class Search extends Component<Props, State> {

  constructor(props: Readonly<Props>) {
    super(props);
    // this.props.change_search_name("")
    this.state = {
      reflesh: false
    }
    // this.props.change_search_contract_date_end(null)
    // this.change_search_name = this.change_search_name.bind(this);

    // this.handleContractionDateChange = this.handleContractionDateChange.bind(this);
    // this.handleContractionDateChangeEnd = this.handleContractionDateChangeEnd.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.change = this.change.bind(this);

    this.changeDate1 = this.changeDate1.bind(this);
    this.changeDate2 = this.changeDate2.bind(this);
  }

  componentWillMount() {

    // this.props.initialize({output:true,name:"",date1:"",date2:""});//フォームに初期値を反映させる


  }
  handleChange(e: any) {

    // let remittancelogcheck_search_form = this.props.remittancelogcheck_search_form
    // remittancelogcheck_search_form[e.target.name] = e.target.value

    // this.props.change_remittancelogcheck_search_form(remittancelogcheck_search_form)

    this.setState({ reflesh: !this.state.reflesh })
  }


  change(e: any, newValue: any) {

    let search = this.props.rent_money_search
    search.bk_no = newValue.value
    this.props.change_rent_money_search(search)
    this.setState({ reflesh: !this.state.reflesh })
  }

  changeDate1(e: any) {

    let search = this.props.rent_money_search
    search.date1 = e
    this.props.change_rent_money_search(search)
    this.setState({ reflesh: !this.state.reflesh })
  }
  changeDate2(e: any) {
    let search = this.props.rent_money_search
    search.date2 = e
    this.props.change_rent_money_search(search)
    this.setState({ reflesh: !this.state.reflesh })
  }
  // handleContractionDateChange(e: any) {
  //   let search_form = this.props.search_form
  //   search_form.search_contract_date_start = e
  //   this.props.change_search_form(search_form)
  //   this.setState({ reflesh: !this.state.reflesh })
  //   // this.props.change_search_contract_dat / e_start(e)

  // }
  // search(e: any) {
  //   this.props.get_owner_remittance_check_logs()
  //   // this.props.change_search_contract_date_end(e)
  // }

  // handle_change_search_diff(e: any) {

  //   if (e.target.checked) {
  //     this.props.change_search_diff(true)

  //   } else {
  //     this.props.change_search_diff(false)
  //   }
  // }

  render() {

    // const { handleSubmit } = this.props;


    return (
      <div>

        <table>

          <tbody>

            <tr >


              <td >


                <div className="searchform">
                  {this.props.rent_money_bklist ? <Autocomplete
                    id="combo-box-demo"
                    options={this.props.rent_money_bklist}
                    getOptionLabel={(option: any) => option.label}
                    // value={this.props.bklist.filter((a: any) => a.value === this.props.search.bk_no)[0]}
                    // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                    style={{ width: 600 }}
                    onChange={this.change}
                    renderInput={(params) => <TextField
                      {...params} label="物件選択" />}

                  /> : ""}
                </div>
              </td>
              <td >   <button
                type="button"
                style={{ margin: "20px" }}

                // onClick={() => this.props.get_shlist()}
                className="btn btn-primary float-right"
              >検索</button></td>
              <td>




              </td>
            </tr>
            {/* <tr >

              <td colSpan={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>


                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    id="date-picker-estimate2"
                    label="送金日"
                    //   showTodayButton={true}
                    style={{ float: "left" }}
                    // onChange={this.onChangeStartDate}
                    // error={true}
                    // helperText="契約日を選択してください"
                    //   errorMessages={['契約日を選択してください']}
                    name="date1"
                    value={this.props.rent_money_search.date1}

                    onChange={this.changeDate1}
                    //    value={this.props.estimate_search.contractDateStart}
                    //    value={this.props.estimateData.contractDate}
                    //              onChange={this.handleContractionDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    //   minDateMessage="契約日を選択してください"
                    // minDate={new Date("2000-01-01")}
                    invalidDateMessage={"日付の形式が違います"}
                  />
           ～
           <KeyboardDatePicker

                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    id="date-picker-estimate2"
                    label="送金日"
                    style={{ marginLeft: "20px" }}
                    // value={this.props.search_list.end_date}
                    // onChange={this.onChangeEndDate}
                    // error={true}
                    // helperText="申込日を選択してください"
                    //   showTodayButton={true}
                    //  name ="applicationDate"
                    name="date2"
                    value={this.props.rent_money_search.date2}
                    onChange={this.changeDate2}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    invalidDateMessage={"日付の形式が違います"}
                  />

                </MuiPickersUtilsProvider>
                <button
                  type="button"
                  style={{ margin: "20px" }}
                  // onClick={() => this.props.get_shlist()}
                  className="btn btn-primary float-right"
                >検索</button>
              </td>


            </tr > */}
          </tbody>
        </table >








      </div >
    );
  }
}




// const mapStateToProps = (state:any) => {
//     return {

//         responseid: state.customersReducer.responseid,
//         responsedetail: state.customersReducer.responsedetail,
//         customerNewDisp: state.customersReducer.customerNewDisp,
//         customerdetail0: state.customersReducer.customerdetail0

//     }
// }
// function mapDispatchToProps(dispatch:any) {
//     return {

//         // responseUpdate(state:any) {
//         //     dispatch(Responses.responseUpdate(state))
//         // },
//         // change_customerloding1(state:number) {
//         //     dispatch(Customers.change_customerloding1(state))
//         // },
//         // change_customerloding2(state:number) {
//         //     dispatch(Customers.change_customerloding2(state))
//         // },
//         // customerNewDispSet(state:number) {
//         //     dispatch(Customers.customerNewDispSet(state))
//         // },
//         // change_customeredit0(state:number) {
//         //     dispatch(Customers.change_customeredit0(state))
//         // },
//        get_rent_contract_accounting(state:any) {
//             dispatch(Monies.get_rent_contract_accounting(state))
//         },

//     };
// }
// AccountingListSearchComponent = connect(
//     mapStateToProps, mapDispatchToProps
// )(AccountingListSearchComponent);

const mapStateToProps = (state: any) => {
  return {
    // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
    // shops: state.masterReducer.shops,
    // search: state.RemittanceDetail.search,
    // so_ymd_list: state.RemittanceLogCheck.so_ymd_list,
    // remittancelogcheck_search_form: state.RemittanceLogCheck.remittancelogcheck_search_form,

    // so_ymd_list_sum: state.RemittanceLogCheck.so_ymd_list_sum,
    // owner_remittance_check_logs_sum: state.RemittanceLogCheck.owner_remittance_check_logs_sum,
    // owner_remittance_check_logs: state.RemittanceLogCheck.owner_remittance_check_logs,

    rent_money_bklist: state.RentMoney.rent_money_bklist,
    rent_money_search: state.RentMoney.rent_money_search,
    // bklist: state.RemittanceDetail.bklist,
  }
}
function mapDispatchToProps(dispatch: any) {
  return {
    // get_remittance_details(state: any) {
    //   dispatch(RentMoney.get_remittance_details(state))
    // },
    // get_shlist(state: any) {
    //   dispatch(RentMoney.get_shlist(state))
    // },
    change_rent_money_search(state: any) {
      dispatch(RentMoney.change_rent_money_search(state))
    },
    // change_search_diff(state: any) {
    //   dispatch(RemittanceLogCheck.change_search_diff(state))
    // },
    // check_completed(state: any) {
    //   dispatch(RemittanceLogCheck.check_completed(state))
    // },
    // get_rent_contracts_check_list(state: any) {
    //   dispatch(RemittanceLogCheck.get_rent_contracts_check_list(state))
    // },
    // check_completed_cancel(state: any) {
    //   dispatch(RemittanceLogCheck.check_completed_cancel(state))
    // },

    // change_search_name(state: any) {
    //   dispatch(ContractSearch.change_search_name(state))
    // },
    // change_search_contract_date_start(state: any) {
    //   dispatch(ContractSearch.change_search_contract_date_start(state))
    // },
    // change_search_contract_date_end(state: any) {
    //   dispatch(ContractSearch.change_search_contract_date_end(state))
    // },
    // change_search_tatemono_name(state: any) {
    //   dispatch(ContractSearch.change_search_tatemono_name(state))
    // },
    //   get_banks(state:any) {
    //     dispatch(Kyousai.get_banks(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
// const form = reduxForm({
//     form: 'formset', // a unique identifier for this form

//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(AccountingListSearchComponent)

// export default Form;

