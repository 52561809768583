import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './Css/RequestDetail.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import * as Sms from '../Sms/Saga/Sms';
import * as RequestDetailSaga from './Saga/RequestDetail';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Keiyaku from '../Keiyaku/Saga/Keiyaku';
import ChatTemplateGrid from '../Chat/ChatTemplateGrid';
import TemplateKanri from "../Keiyaku/TemplateKanri";
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Paper from '@material-ui/core/Paper';
import * as Chat from '../Chat/Saga/Chat';
import * as Adjustment from '../Adjustment/Saga/Adjustment';

import moment from 'moment'



interface Props {
    request_detail: any,
    user_details: any,
    send_message: any,
    sms_status: any,
    newchattoggle: any,
    room_support_m_user_id: any,
    Outai_No: any,
    syousai_insert: any,
    template_category_list: any,
    get_template: any,
    get_template_category_list: any,
    sms_insert: any,
    new_chat: any,
    chat_plus: any,
    new_chat_id: any,
    get_request_detail: any,
    disp_reset: any,
    room_support_m_chat_id: any,
    chat_plus_update: any

}
interface State {
    title: any,
    message: any,
    disp: boolean,
    buton_disp: boolean,
    Templatetoggle: boolean
}

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            Templatetoggle: false,
            disp: true,
            buton_disp: true,
            title: "",
            message: ""
        }
        this.title = this.title.bind(this);
        this.onChangemessage = this.onChangemessage.bind(this);

        this.send = this.send.bind(this);
        this.onchange_category = this.onchange_category.bind(this);
        this.onChange = this.onChange.bind(this);
        this.Templatetoggle = this.Templatetoggle.bind(this);

    }

    Templatetoggle() {

        if (this.state.Templatetoggle == false) {
            this.props.get_template_category_list();
        }
        this.setState({ Templatetoggle: !this.state.Templatetoggle })

    }

    onChange(e: any) {
        console.log(e.target.value);
        this.setState({ message: e.target.value, disp: false });
        setTimeout(() => {
            this.setState({ disp: true })
        }, 1000)
    }

    title(e: any) {
        this.setState({
            title: e.target.value,
        });
    }
    onChangemessage(e: any) {
        this.setState({
            message: e.target.value,
        });
    }


    send() {
        if (this.state.title == "") {
            alert("タイトルが未入力です");
            return;
        }
        if (this.state.message == "") {
            alert("メッセージが未入力です");
            return;
        }
        var result = window.confirm('契約者とのチャットが作成されます。顧客が契約者でない場合は作成しても届きません。このまま作成しますか？');
        if (result) {

            this.props.disp_reset();
            this.props.newchattoggle();

            this.props.new_chat({
                room_support_m_user_id: this.props.room_support_m_user_id,
                // room_support_m_user_id: 'rEEv8jM9LESlNvgMQeRiWTBWFJM2',
                message: this.state.message,
                title: this.state.title,
            });

            alert("チャットが作成されました。")

            this.props.chat_plus_update();

        }

    }

    onchange_category(event: any, values: any) {
        if (values) {
            this.props.get_template(values.id);
        }
    }

    render() {

        return (
            <div style={{ padding: 10, background: "#b0c4de" }} >
                <div className="item-outer col-lg-12">
                    <Card className="col-lg-12" >
                        <CardContent style={{ float: "left" }} className="col-lg-12">
                            <div>
                                タイトル：<TextField
                                    autoComplete='off'
                                    value={this.state.title}
                                    onChange={this.title}
                                    style={{ width: 400 }}
                                    id="standard-basic" />
                            </div>
                            {this.state.disp ?
                                <TextField
                                    autoComplete='off'
                                    id="outlined-read-only-input"
                                    label="本文"
                                    fullWidth
                                    multiline
                                    rows={15}
                                    variant="outlined"
                                    onChange={this.onChangemessage}
                                    defaultValue={this.state.message}
                                    style={{ marginTop: "10px" }}
                                />
                                : ""}
                            <div>
                                文字数:{this.state.message.length}
                            </div>
                            {/* <Button style={{ color: "white", backgroundColor: "#28a745", margin: 5 }} variant="contained" onClick={this.ontemplate}>アプリ勧誘テンプレート</Button> */}

                            <div>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={this.props.template_category_list}
                                    getOptionLabel={(option: any) => option.category_title}
                                    style={{ width: 200, marginTop: 12 }}
                                    onChange={this.onchange_category}
                                    renderInput={(params) => <TextField {...params} label="テンプレートカテゴリー" />}
                                />



                                {this.state.buton_disp ?
                                    <Button style={{ float: "right", margin: 5 }} color="primary" variant="contained" onClick={this.send}>送信</Button>
                                    : ""}
                            </div>
                            <Button
                                style={{ marginLeft: 200 }}
                                onClick={this.Templatetoggle}
                                color="primary"
                                variant="outlined">
                                テンプレートを作成する
                                    </Button>

                            <div>
                                <ChatTemplateGrid onChange={this.onChange} />
                            </div>





                        </CardContent>
                    </Card>

                </div>

                < Modal isOpen={this.state.Templatetoggle} toggle={this.Templatetoggle} size="keiyaku" fade={false} style={{ marginRight: 700 }}>
                    <ModalHeader toggle={this.Templatetoggle}>テンプレート管理</ModalHeader>
                    <div style={{ padding: 10, background: "#b0c4de" }} >
                        <Paper square={false} style={{ marginTop: 10 }}>
                            <TemplateKanri />
                        </Paper>
                    </div>
                    <ModalFooter>
                    </ModalFooter>
                </Modal >
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        request_detail: state.Adjustment.request_detail,
        user_details: state.masterReducer.user_details,
        sms_status: state.sms.sms_status,
        template_category_list: state.Keiyaku.template_category_list,
        new_chat_id: state.chat.new_chat_id,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        send_message(state: any) {
            dispatch(Sms.send_sms_message(state))
        },
        syousai_insert(state: any) {
            dispatch(RequestDetailSaga.syousai_insert(state))
        },
        get_template(state: any) {
            dispatch(Keiyaku.get_template(state))
        },
        get_template_category_list(state: any) {
            dispatch(Keiyaku.get_template_category_list(state))
        },
        sms_insert(state: any) {
            dispatch(RequestDetailSaga.sms_insert(state))
        },
        new_chat(state: any) {
            dispatch(Chat.new_chat(state))
        },
        chat_plus(state: any) {
            dispatch(RequestDetailSaga.chat_plus(state))
        },
        get_request_detail(state: any) {
            dispatch(Adjustment.get_request_detail(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);

