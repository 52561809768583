import React, { createContext, useState, useContext } from 'react'
import { Authentication } from '../../App'
import axios from "axios";
import Grid from '@mui/material/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Table, Column, Cell } from 'fixed-data-table-2';

function KokyakuGrid(props) {
  const authentication = useContext(Authentication);
  const koyaku_data = props.koyaku_data
  // const [created_at2, sownerreated_at2] = useState(new Date());
  // React.useEffect(() => {
  // }, []);


  return (
    <div>
      {koyaku_data['nyuukyosya'] ?
        <Table rowHeight={35} rowsCount={5} width={window.innerWidth / 2.5} height={230} headerHeight={40}>
          <Column header={<Cell>部屋区分</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? "入居者" :
              rowIndex == 1 ? "その他" :
                rowIndex == 2 ? "オーナー" :
                  rowIndex == 3 ? "退去者" :
                    rowIndex == 4 ? "合計" : ""}
          </Cell>)} width={100} />

          <Column header={<Cell style={{ textAlign: "center" }}>1</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? koyaku_data['nyuukyosya']["1"] :
              rowIndex == 1 ? koyaku_data['sonota']["1"] :
                rowIndex == 2 ? koyaku_data['owner']["1"] :
                  rowIndex == 3 ? koyaku_data['taikyosya']["1"] :
                    rowIndex == 4 ? koyaku_data['total']["1"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>2</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? koyaku_data['nyuukyosya']["2"] :
              rowIndex == 1 ? koyaku_data['sonota']["2"] :
                rowIndex == 2 ? koyaku_data['owner']["2"] :
                  rowIndex == 3 ? koyaku_data['taikyosya']["2"] :
                    rowIndex == 4 ? koyaku_data['total']["2"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>3</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? koyaku_data['nyuukyosya']["3"] :
              rowIndex == 1 ? koyaku_data['sonota']["3"] :
                rowIndex == 2 ? koyaku_data['owner']["3"] :
                  rowIndex == 3 ? koyaku_data['taikyosya']["3"] :
                    rowIndex == 4 ? koyaku_data['total']["3"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>4</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? koyaku_data['nyuukyosya']["4"] :
              rowIndex == 1 ? koyaku_data['sonota']["4"] :
                rowIndex == 2 ? koyaku_data['owner']["4"] :
                  rowIndex == 3 ? koyaku_data['taikyosya']["4"] :
                    rowIndex == 4 ? koyaku_data['total']["4"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>5</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? koyaku_data['nyuukyosya']["5"] :
              rowIndex == 1 ? koyaku_data['sonota']["5"] :
                rowIndex == 2 ? koyaku_data['owner']["5"] :
                  rowIndex == 3 ? koyaku_data['taikyosya']["5"] :
                    rowIndex == 4 ? koyaku_data['total']["5"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>6</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? koyaku_data['nyuukyosya']["6"] :
              rowIndex == 1 ? koyaku_data['sonota']["6"] :
                rowIndex == 2 ? koyaku_data['owner']["6"] :
                  rowIndex == 3 ? koyaku_data['taikyosya']["6"] :
                    rowIndex == 4 ? koyaku_data['total']["6"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>7</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? koyaku_data['nyuukyosya']["7"] :
              rowIndex == 1 ? koyaku_data['sonota']["7"] :
                rowIndex == 2 ? koyaku_data['owner']["7"] :
                  rowIndex == 3 ? koyaku_data['taikyosya']["7"] :
                    rowIndex == 4 ? koyaku_data['total']["7"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>8</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? koyaku_data['nyuukyosya']["8"] :
              rowIndex == 1 ? koyaku_data['sonota']["8"] :
                rowIndex == 2 ? koyaku_data['owner']["8"] :
                  rowIndex == 3 ? koyaku_data['taikyosya']["8"] :
                    rowIndex == 4 ? koyaku_data['total']["8"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>9</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? koyaku_data['nyuukyosya']["9"] :
              rowIndex == 1 ? koyaku_data['sonota']["9"] :
                rowIndex == 2 ? koyaku_data['owner']["9"] :
                  rowIndex == 3 ? koyaku_data['taikyosya']["9"] :
                    rowIndex == 4 ? koyaku_data['total']["9"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>10</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? koyaku_data['nyuukyosya']["10"] :
              rowIndex == 1 ? koyaku_data['sonota']["10"] :
                rowIndex == 2 ? koyaku_data['owner']["10"] :
                  rowIndex == 3 ? koyaku_data['taikyosya']["10"] :
                    rowIndex == 4 ? koyaku_data['total']["10"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>11</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? koyaku_data['nyuukyosya']["11"] :
              rowIndex == 1 ? koyaku_data['sonota']["11"] :
                rowIndex == 2 ? koyaku_data['owner']["11"] :
                  rowIndex == 3 ? koyaku_data['taikyosya']["11"] :
                    rowIndex == 4 ? koyaku_data['total']["11"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>12</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? koyaku_data['nyuukyosya']["12"] :
              rowIndex == 1 ? koyaku_data['sonota']["12"] :
                rowIndex == 2 ? koyaku_data['owner']["12"] :
                  rowIndex == 3 ? koyaku_data['taikyosya']["12"] :
                    rowIndex == 4 ? koyaku_data['total']["12"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>平均</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? koyaku_data['nyuukyosya']["avg"] :
              rowIndex == 1 ? koyaku_data['sonota']["avg"] :
                rowIndex == 2 ? koyaku_data['owner']["avg"] :
                  rowIndex == 3 ? koyaku_data['taikyosya']["avg"] :
                    rowIndex == 4 ? koyaku_data['total']["avg"] : ""}
          </Cell>)} width={60} />

          <Column header={<Cell style={{ textAlign: "center" }}>全部</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? koyaku_data['nyuukyosya']["all"] :
              rowIndex == 1 ? koyaku_data['sonota']["all"] :
                rowIndex == 2 ? koyaku_data['owner']["all"] :
                  rowIndex == 3 ? koyaku_data['taikyosya']["all"] :
                    rowIndex == 4 ? koyaku_data['total']["all"] : ""}
          </Cell>)} width={60} />


          {/* <Column header={<Cell>配信予定日</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} onClick={() => edit(noticeList[rowIndex])} style={{ cursor: "pointer" }}>{noticeList[rowIndex].send_schedule_at ? moment(noticeList[rowIndex].send_schedule_at).utc().format('YYYY-MM-DD') : ""}</Cell>)} width={100} />
        <Column header={<Cell>配信日</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} onClick={() => edit(noticeList[rowIndex])} style={{ cursor: "pointer" }}>{noticeList[rowIndex].send_at ? moment(noticeList[rowIndex].send_at).utc().format('YYYY-MM-DD') : ""}</Cell>)} width={100} />
        <Column header={<Cell>記入者</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} onClick={() => edit(noticeList[rowIndex])} style={{ cursor: "pointer" }}>{noticeList[rowIndex].user_name_all}</Cell>)} width={120} />
        <Column header={<Cell>承認</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} onClick={() => edit(noticeList[rowIndex])} style={{ cursor: "pointer", textAlign: 'center', fontSize: noticeList[rowIndex].approval_flag === true ? 11 : "" }}>{noticeList[rowIndex].status === false ? "下書き" : noticeList[rowIndex].approval_flag === false ? "待" : "済"}</Cell>)} width={60} />
        <Column header={<Cell>承認日</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} onClick={() => edit(noticeList[rowIndex])} style={{ cursor: "pointer" }}>{noticeList[rowIndex].approval_at ? moment(noticeList[rowIndex].approval_at).utc().format('YYYY-MM-DD') : ""}</Cell>)} width={100} />
        <Column header={<Cell>通知</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} onClick={() => edit(noticeList[rowIndex])} style={{ cursor: "pointer", textAlign: 'center' }}>{noticeList[rowIndex].notice === "" ? "" : noticeList[rowIndex].is_push ? "あり" : "なし"}</Cell>)} width={50} />
        <Column header={<Cell>へやのしおり公開</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ textAlign: 'center' }}>{noticeList[rowIndex].heyanoshiori === "" ? "" : noticeList[rowIndex].is_heyanoshiori ? "あり" : "なし"}</Cell>)} width={150} /> */}
        </Table> : ""}
    </div>
  );
};

export default KokyakuGrid;
