import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from "./Grid";
import * as Vacancy from './Saga/Vacancy';
// import ContractSearchComponent from '../ContractSearch/Index';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

import MaterialTable from 'material-table'

interface Props {
    get_vacancies: any,
    // customerNewDisp:number,
    // customerno:number,
    // customerList:any,
    // toggle:any,
    // get_rent_contract_accounting: any,
    // get_seisan_follow_list: any,
    // room_details: any,
    // search_modal: boolean,

}



interface State {
    // refresh: boolean,
    // modalsheet: boolean,
    // modal_rent_money: boolean,
    // modal_owner: boolean,
    // tatemono_id: string,
    // room_id: string
    // kashinushi_id: number
    // state types
}

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            refresh: false,
            modalsheet: false,
            modal_rent_money: false,
            modal_owner: false,
            tatemono_id: "",
            room_id: "",
            kashinushi_id: 0
        }


        this.props.get_vacancies()


        //     this.togglesheet = this.togglesheet.bind(this);
        //     this.togglesheet_set = this.togglesheet_set.bind(this);

        //     this.toggle_rent_money = this.toggle_rent_money.bind(this);
        //     this.toggle_owner = this.toggle_owner.bind(this);

        //     this.toggle_owner_set = this.toggle_owner_set.bind(this);

        //     // this.state = {
        //     //     modalcontract: false,


        //     // }



    }





    // componentWillMount() {
    //     // this.props.get_kyousais()
    //     // this.props.customerNewDispSet(0)
    //     this.submit = this.submit.bind(this);
    // }
    // //     aa(state:any){
    // // console.log(state)
    // //     }

    // submit(state: any) {
    //     console.log(state)

    //     this.props.get_seisan_follow_list(state)
    // }

    // togglesheet = () => {


    //     this.setState({
    //         tatemono_id: "",
    //         room_id: "",
    //         modalsheet: !this.state.modalsheet,

    //     });
    // }
    // togglesheet_set = (tatemono_id: any, room_id: any) => {


    //     this.setState({
    //         tatemono_id: tatemono_id,
    //         room_id: room_id,
    //         modalsheet: !this.state.modalsheet,

    //     });
    // }

    // toggle_rent_money = () => {

    //     this.setState({
    //         modal_rent_money: !this.state.modal_rent_money,

    //     });
    // }
    // toggle_owner = () => {

    //     this.setState({
    //         kashinushi_id: 0,
    //         modal_owner: !this.state.modal_owner,

    //     });
    // }
    // toggle_owner_set = (kashinushi_id: number) => {

    //     this.setState({
    //         kashinushi_id: kashinushi_id,
    //         modal_owner: !this.state.modal_owner,

    //     });

    // }
    // // togglecontract(e: any) {

    // //     this.setState(prevState => ({
    // //         modalcontract: !prevState.modalcontract,

    // //     }));

    // //     return
    // // }
    // csv() {

    //     // this.props.get_rent_contract_accounting(this.props.kyousais);
    // }
    render() {

        return (
            <div style={{ maxWidth: '100%' }}>
                <Grid

                />
            </div>
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // banks: state.rentContractMoniesReducer.banks,
        search_modal: state.Adjustment.search_modal,
        room_details: state.roomsReducer.room_details,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {

        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },


        get_vacancies(state: any) {
            dispatch(Vacancy.get_vacancies(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




