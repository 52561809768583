const authentication = require('../../../react-azure-adb2c2').default;



export const excel = (values: any) => {

  // let url = `http://localhost:5000/v1/t/outai/requestreport/` + values.outai_no + "/" + values.kaketsuke;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outai/requestreport/` + values.outai_no + "/" + values.kaketsuke;
  // alert(url)

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
  }).then(response => response.blob())
  .then(payload => ({ payload }))
  // .then(res => res.json())
  // .then(payload => ({ payload }))
  .catch(error => ({ error }));

};