import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import ja from "date-fns/locale/ja";
import './Css/Grid.css';
import DateFnsUtils from '@date-io/date-fns';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import KyDetails from '../KyDetail/Index';
import * as KyDetail from '../KyDetail/Saga/KyDetail';
import * as RentMoney from './Saga/RentMoney';
import { months } from 'moment-timezone';

const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');
// Table data as a list of array.
const rows = [
  "first row",
  "second row",
  "third row"
  // .... and more
];

let columns = []

// Custom cell implementation with special prop




const Date = ({ column, props, rowIndex }) =>
  // <Cell style={{ whiteSpace: "nowrap", width: "100%", height: "100%", background: moment(props.seisan_follow_list[rowIndex][column.key]).tz("Asia/Tokyo").format('YYYY/MM/DD') > moment().tz("Asia/Tokyo").format('YYYY/MM/DD') ? "#87cefa" : "#98fb98" }} >
  <Cell style={{ whiteSpace: "nowrap", width: "100%", height: "100%" }} >

    {props.rent_moneies[rowIndex][column.key] ? moment(props.rent_moneies[rowIndex][column.key]).tz("Asia/Tokyo").format('YYYY/MM/DD') : ""}
    {/* <Badge badgeContent={"次2日"} color="secondary">　</Badge> */}

  </Cell >;


const DateCheck = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap", background: props.rent_moneies[rowIndex][column.key] ? "#f5f5f5" : "pink", width: "100%", height: "100%" }} >{props.rent_moneies[rowIndex][column.key] ? moment(props.rent_moneies[rowIndex][column.key]).tz("Asia/Tokyo").format('YYYY/MM/DD') : ""}</Cell>;

const Money1 = ({ column, props, rowIndex }) =>
  parseInt(props.rent_moneies[rowIndex][column.key]) === 0 ? <Cell key={rowIndex + "month1"}></Cell>
    : parseInt(props.rent_moneies[rowIndex].month1micount) === 0 ? <Cell key={rowIndex + "month1"} style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
      : parseInt(props.rent_moneies[rowIndex].month1micount) !== parseInt(props.rent_moneies[rowIndex].month1count) ? <Cell style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
        : <Cell key={rowIndex + "month1"} style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>

const Money2 = ({ column, props, rowIndex }) =>
  parseInt(props.rent_moneies[rowIndex][column.key]) === 0 ? <Cell key={rowIndex + "month2"}></Cell>
    : parseInt(props.rent_moneies[rowIndex].month2micount) === 0 ? <Cell key={rowIndex + "month2"} style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
      : parseInt(props.rent_moneies[rowIndex].month2micount) !== parseInt(props.rent_moneies[rowIndex].month2count) ? <Cell style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
        : <Cell key={rowIndex + "month2"} style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>

const Money3 = ({ column, props, rowIndex }) =>
  parseInt(props.rent_moneies[rowIndex][column.key]) === 0 ? <Cell key={rowIndex + "month3"} ></Cell>
    : parseInt(props.rent_moneies[rowIndex].month3micount) === 0 ? <Cell key={rowIndex + "month3"} style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
      : parseInt(props.rent_moneies[rowIndex].month3micount) !== parseInt(props.rent_moneies[rowIndex].month3count) ? <Cell style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
        : <Cell key={rowIndex + "month3"} style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
const Money4 = ({ column, props, rowIndex }) =>
  parseInt(props.rent_moneies[rowIndex][column.key]) === 0 ? <Cell key={rowIndex + "month4"} ></Cell>
    : parseInt(props.rent_moneies[rowIndex].month4micount) === 0 ? <Cell key={rowIndex + "month4"} style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
      : parseInt(props.rent_moneies[rowIndex].month4micount) !== parseInt(props.rent_moneies[rowIndex].month4count) ? <Cell style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
        : <Cell key={rowIndex + "month4"} style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>

const Money5 = ({ column, props, rowIndex }) =>
  parseInt(props.rent_moneies[rowIndex][column.key]) === 0 ? <Cell key={rowIndex + "month5"}></Cell>
    : parseInt(props.rent_moneies[rowIndex].month5micount) === 0 ? <Cell key={rowIndex + "month5"} style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
      : parseInt(props.rent_moneies[rowIndex].month5micount) !== parseInt(props.rent_moneies[rowIndex].month5count) ? <Cell style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
        : <Cell key={rowIndex + "month5"} style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>

const Money6 = ({ column, props, rowIndex }) =>
  parseInt(props.rent_moneies[rowIndex][column.key]) === 0 ? <Cell ></Cell>
    : parseInt(props.rent_moneies[rowIndex].month6micount) === 0 ? <Cell style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
      : parseInt(props.rent_moneies[rowIndex].month6micount) !== parseInt(props.rent_moneies[rowIndex].month6count) ? <Cell style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
        : <Cell style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>

const Money7 = ({ column, props, rowIndex }) =>
  parseInt(props.rent_moneies[rowIndex][column.key]) === 0 ? <Cell ></Cell>
    : parseInt(props.rent_moneies[rowIndex].month7micount) === 0 ? <Cell style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
      : parseInt(props.rent_moneies[rowIndex].month7micount) !== parseInt(props.rent_moneies[rowIndex].month7count) ? <Cell style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
        : <Cell style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>

const Money8 = ({ column, props, rowIndex }) =>
  parseInt(props.rent_moneies[rowIndex][column.key]) === 0 ? <Cell ></Cell>
    : parseInt(props.rent_moneies[rowIndex].month8micount) === 0 ? <Cell style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
      : parseInt(props.rent_moneies[rowIndex].month8micount) !== parseInt(props.rent_moneies[rowIndex].month8count) ? <Cell style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
        : <Cell style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>

const Money9 = ({ column, props, rowIndex }) =>
  parseInt(props.rent_moneies[rowIndex][column.key]) === 0 ? <Cell ></Cell>
    : parseInt(props.rent_moneies[rowIndex].month9micount) === 0 ? <Cell style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
      : parseInt(props.rent_moneies[rowIndex].month9micount) !== parseInt(props.rent_moneies[rowIndex].month9count) ? <Cell style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
        : <Cell style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>

const Money10 = ({ column, props, rowIndex }) =>
  parseInt(props.rent_moneies[rowIndex][column.key]) === 0 ? <Cell ></Cell>
    : parseInt(props.rent_moneies[rowIndex].month10micount) === 0 ? <Cell style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
      : parseInt(props.rent_moneies[rowIndex].month10micount) !== parseInt(props.rent_moneies[rowIndex].month10count) ? <Cell style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
        : <Cell style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>

const Money11 = ({ column, props, rowIndex }) =>
  parseInt(props.rent_moneies[rowIndex][column.key]) === 0 ? <Cell ></Cell>
    : parseInt(props.rent_moneies[rowIndex].month11micount) === 0 ? <Cell style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
      : parseInt(props.rent_moneies[rowIndex].month11micount) !== parseInt(props.rent_moneies[rowIndex].month11count) ? <Cell style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
        : <Cell style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>

const Money12 = ({ column, props, rowIndex }) =>
  parseInt(props.rent_moneies[rowIndex][column.key]) === 0 ? <Cell ></Cell>
    : parseInt(props.rent_moneies[rowIndex].month12micount) === 0 ? <Cell style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
      : parseInt(props.rent_moneies[rowIndex].month12micount) !== parseInt(props.rent_moneies[rowIndex].month12count) ? <Cell style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>
        : <Cell style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(props.rent_moneies[rowIndex][column.key]).toLocaleString()}</Cell>


const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >{props.rent_moneies[rowIndex][column.key]}</Cell>;

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // columns: columns,
      reflash: false,
      kytoggle: false

      // dataList: nereflashw FakeObjectDataListStore(1000000),
      // columnWidths: {
      //   firstName: 240,
      //   lastName: 150,
      //   sentence: 140,
      //   companyName: 60,
      // },
    };

    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this.kytoggle = this.kytoggle.bind(this);
    this.onKyopen = this.onKyopen.bind(this);
    var dtend = moment({
      year: this.props.baseyear,
      month: this.props.basemonth, // 1月
      day: 1
    })
    var dt = moment({
      year: this.props.baseyear,
      month: this.props.basemonth, // 1月
      day: 1
    }).subtract(1, 'years')

    let monthno = 1
    columns = [
      {
        key: 'bk_name', name: '建物名', width: 150, frozen: true,

      },
      { key: 'hy_no', name: '部屋名', width: 60, frozen: true },
      { key: 'kys_name', name: '契約者名', width: 100, frozen: true },
      // { key: 'owner_kaiyaku_date', name: '解約報告日', width: 80, resizable: true },


    ]
    for (let d = dt; dtend > d; d = moment(d).add(1, 'months')) {

      columns.push({ key: 'month' + monthno, name: moment(d).format("M月"), width: 80, resizable: true })
      monthno = monthno + 1
    }

    this.props.get_rent_moneies(dtend)
  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })



    this.setState({ columns: columnsset })

  }
  // componentDidUpdate(nextProps) {

  //   this.setState({ reflash: !this.state.reflash });
  // }
  onKyopen = (bk_no, hy_no, ky_no) => {

    this.props.change_searchky({ bk_no, hy_no, ky_no })

    this.kytoggle()

  }
  kytoggle() {
    this.setState({ kytoggle: !this.state.kytoggle })
    // this.props.get_rent_contract_accounting(this.props.kyousais);
  }
  render() {

    return (
      <div id="seisanlist">

        <Table
          rowHeight={45}
          rowsCount={this.props.rent_moneies.length}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          width={1000}
          height={500}
          headerHeight={40}>
          {columns.map(function (value, i) {
            return <Column
              columnKey={value.key}
              header={<Cell style={{ whiteSpace: "nowrap" }}>{value.name}</Cell>}
              // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
              cell={({ rowIndex, ...props }) => (


                value.key === "order_at" ? <DateCheck column={value} props={this.props} rowIndex={rowIndex}></DateCheck>
                  : value.key === "hoshuu_date" ? <DateCheck column={value} props={this.props} rowIndex={rowIndex}></DateCheck>
                    : value.key === "completion_at" ? <DateCheck column={value} props={this.props} rowIndex={rowIndex}></DateCheck>
                      : value.key === "estimate_request" ? <DateCheck column={value} props={this.props} rowIndex={rowIndex}></DateCheck>
                        : value.key === "after_check" ? <DateCheck column={value} props={this.props} rowIndex={rowIndex}></DateCheck>
                          : value.key === "estimate_receives" ? <DateCheck column={value} props={this.props} rowIndex={rowIndex}></DateCheck>

                            : value.key === "month1" ? <Money1 key={rowIndex + "-1"} column={value} props={this.props} rowIndex={rowIndex}></Money1>
                              : value.key === "month2" ? <Money2 key={rowIndex + "-2"} column={value} props={this.props} rowIndex={rowIndex}></Money2>
                                : value.key === "month3" ? <Money3 key={rowIndex + "-3"} column={value} props={this.props} rowIndex={rowIndex}></Money3>
                                  : value.key === "month4" ? <Money4 key={rowIndex + "-4"} column={value} props={this.props} rowIndex={rowIndex}></Money4>
                                    : value.key === "month5" ? <Money5 key={rowIndex + "-5"} column={value} props={this.props} rowIndex={rowIndex}></Money5>
                                      : value.key === "month6" ? <Money6 column={value} props={this.props} rowIndex={rowIndex}></Money6>
                                        : value.key === "month7" ? <Money7 column={value} props={this.props} rowIndex={rowIndex}></Money7>
                                          : value.key === "month8" ? <Money8 column={value} props={this.props} rowIndex={rowIndex}></Money8>
                                            : value.key === "month9" ? <Money9 column={value} props={this.props} rowIndex={rowIndex}></Money9>
                                              : value.key === "month10" ? <Money10 column={value} props={this.props} rowIndex={rowIndex}></Money10>
                                                : value.key === "month11" ? <Money11 column={value} props={this.props} rowIndex={rowIndex}></Money11>
                                                  : value.key === "month12" ? <Money12 column={value} props={this.props} rowIndex={rowIndex}></Money12>

                                                    : <Base column={value} props={this.props} rowIndex={rowIndex}></Base>
              )}
              width={columns.filter(a => a.key === value.key)[0].width}
              isResizable={true}
              fixed={value.frozen}

            />
          }, this)}

          {/* <Column
          header={<Cell>Col 2</Cell>}
          cell={<MyCustomCell mySpecialProp="column2" />}
          width={100}
        />
        <Column
          header={<Cell>Col 3</Cell>}
          cell={({ rowIndex, ...props }) => (
            <Cell {...props}>
              Data for column 3: {rows[rowIndex]}
            </Cell>
          )}
          width={200}
        /> */}

        </Table >


        < Modal isOpen={this.state.kytoggle} toggle={this.kytoggle} size="xl" fade={false}>

          <ModalHeader toggle={this.kytoggle}>契約情報</ModalHeader>
          {/* <ModalBody> */}
          <KyDetails />
          {/* </ModalBody> */}
          <ModalFooter>
            {/* <button className="btn btn btn-danger" style={{margin:"5px"}} onClick={this.delete_introductions}>削除する</button>*/}
            {/* <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={() => this.props.change_search_modal(false)}>キャンセル</button> */}
          </ModalFooter>
        </Modal >
      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    rent_moneies: state.RentMoney.rent_moneies,
    search_modal: state.Adjustment.search_modal,
    room_details: state.roomsReducer.room_details,
    seisan_follow_list: state.Adjustment.seisan_follow_list,
    seisan_follow_search_list: state.Adjustment.seisan_follow_search_list,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_searchky(state) {
      dispatch(KyDetail.change_searchky(state))
    },
    get_rent_moneies(state) {
      dispatch(RentMoney.get_rent_moneies(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);