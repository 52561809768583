import React, { Component } from 'react';
// import ReactDOM from "react-dom";
import { connect } from 'react-redux';
// import * as Contracts from '../../Store/Contracts';
import * as RentMoney from './Saga/RentMoney';

import * as KyDetail from '../KyDetail/Saga/KyDetail';

import Button from '@material-ui/core/Button';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import NetworkWifiIcon from '@material-ui/icons/NetworkWifi';
import RouterIcon from '@material-ui/icons/Router';
import KitchenIcon from '@material-ui/icons/Kitchen';
import CallIcon from '@material-ui/icons/Call';
import Brightness5Icon from '@material-ui/icons/Brightness5';
import './Css/Grid.css';
import moment from 'moment'

const ReactDataGrid = require('react-data-grid');
interface Props {
  // get_banks: any,
  rent_moneies: [],
  koumokulist: [],
  kytoggle: any,
  change_searchky: any,
  // owner_remittance_check_logs_list: [],
  // shops: any,
  // parking_checked: any,
  // parking_check_loading: number,
  // energization_checked: any,
  // energization_check_loading: number,
  // net_checked: any,
  // net_check_loading: number,

  // electronics_checked: any,
  // electronics_check_loading: number,

  // wifi_checked: any,
  // wifi_check_loading: number,
  // completed_checked: any,

  // owner_tel_check_loading: number,
  // owner_tel_checked: any,

  // columns:[],

}



interface State {
  columns: any
  refresh: boolean
  // state types
}


const DateFormatter = (payload: any) => {
  if (payload.value) {
    return moment(payload.value).format("YYYY/MM/DD");
  } else {
    return "";
  }
};
const nameFormatter = (payload: any) => {

  return <div style={{ fontWeight: "bold", background: "#dcdcdc" }}>{payload.value}</div>

};
const roomFormatter = (payload: any) => {

  return <div style={{ textAlign: "right", fontWeight: "bold", background: "#dcdcdc" }}>{payload.value}</div>

};
const netFormatter = (payload: any) => {
  if (payload.value) {
    return <div style={{ marginLeft: "10px" }}>{payload.value}</div >

  } else {
    return <div style={{ background: payload.row.is_cancel === 1 ? "pink" : payload.row.counting === 2 ? "#f5f5f5" : "#CCC", height: "100px" }}>　</div >
  }
};

const parkingFormatter = (payload: any) => {
  if (payload.value && payload.row.counting === 1) {
    return ""

  } else {
    return <div style={{ background: payload.row.is_cancel === 1 ? "pink" : payload.row.counting === 2 ? "#f5f5f5" : "#CCC", height: "100px" }}>　</div >
  }
};
const electronicsFormatter = (payload: any) => {
  if (payload.value) {
    return ""

  } else {
    return <div style={{ background: payload.row.is_cancel === 1 ? "pink" : payload.row.counting === 2 ? "#f5f5f5" : "#CCC", height: "100px" }}>　</div >
  }
};


const energizationFormatter = (payload: any) => {
  if (payload.value) {
    return ""

  } else {
    return <div style={{ background: payload.row.is_cancel === 1 ? "pink" : payload.row.counting === 2 ? "#f5f5f5" : "#CCC", height: "100px" }}>　</div >
  }
};

const wifiFormatter = (payload: any) => {
  if (payload.value !== 2 && payload.row.net && (parseInt(payload.row.net_gyosha_no) === 1 || parseInt(payload.row.net_gyosha_no) === 2)) {
    return ""

  } else {

    return <div style={{ background: payload.row.is_cancel === 1 ? "pink" : payload.row.counting === 2 ? "#f5f5f5" : "#CCC", height: "100px" }}>　</div >
  }
};


const month1formatter = (payload: any) => {
  if (parseInt(payload.value) === 0) {
    return ""
  } else if (parseInt(payload.row.month1micount) === 0) {
    return <div style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else if (parseInt(payload.row.month1micount) !== parseInt(payload.row.month1count)) {
    return <div style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else {
    return <div style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  }
};
const month2formatter = (payload: any) => {
  if (parseInt(payload.value) === 0) {
    return ""
  } else if (parseInt(payload.row.month2micount) === 0) {
    return <div style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else if (parseInt(payload.row.month2micount) !== parseInt(payload.row.month2count)) {
    return <div style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else {
    return <div style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  }
};
const month3formatter = (payload: any) => {
  if (parseInt(payload.value) === 0) {
    return ""
  } else if (parseInt(payload.row.month3micount) === 0) {
    return <div style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else if (parseInt(payload.row.month3micount) !== parseInt(payload.row.month3count)) {
    return <div style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else {
    return <div style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  }
};
const month4formatter = (payload: any) => {
  if (parseInt(payload.value) === 0) {
    return ""
  } else if (parseInt(payload.row.month4micount) === 0) {
    return <div style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else if (parseInt(payload.row.month4micount) !== parseInt(payload.row.month4count)) {
    return <div style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else {
    return <div style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  }
};
const month5formatter = (payload: any) => {
  if (parseInt(payload.value) === 0) {
    return ""
  } else if (parseInt(payload.row.month5micount) === 0) {
    return <div style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else if (parseInt(payload.row.month5micount) !== parseInt(payload.row.month5count)) {
    return <div style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else {
    return <div style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  }
};
const month6formatter = (payload: any) => {
  if (parseInt(payload.value) === 0) {
    return ""
  } else if (parseInt(payload.row.month6micount) === 0) {
    return <div style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else if (parseInt(payload.row.month6micount) !== parseInt(payload.row.month6count)) {
    return <div style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else {
    return <div style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  }
};
const month7formatter = (payload: any) => {
  if (parseInt(payload.value) === 0) {
    return ""
  } else if (parseInt(payload.row.month7micount) === 0) {
    return <div style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else if (parseInt(payload.row.month7micount) !== parseInt(payload.row.month7count)) {
    return <div style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else {
    return <div style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  }
};

const month8formatter = (payload: any) => {
  if (parseInt(payload.value) === 0) {
    return ""
  } else if (parseInt(payload.row.month8micount) === 0) {
    return <div style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else if (parseInt(payload.row.month8micount) !== parseInt(payload.row.month8count)) {
    return <div style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else {
    return <div style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  }
};
const month9formatter = (payload: any) => {
  if (parseInt(payload.value) === 0) {
    return ""
  } else if (parseInt(payload.row.month9micount) === 0) {
    return <div style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else if (parseInt(payload.row.month9micount) !== parseInt(payload.row.month9count)) {
    return <div style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else {
    return <div style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  }
};
const month10formatter = (payload: any) => {
  if (parseInt(payload.value) === 0) {
    return ""
  } else if (parseInt(payload.row.month10micount) === 0) {
    return <div style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else if (parseInt(payload.row.month10micount) !== parseInt(payload.row.month10count)) {
    return <div style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else {
    return <div style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  }
};
const month11formatter = (payload: any) => {
  if (parseInt(payload.value) === 0) {
    return ""
  } else if (parseInt(payload.row.month11micount) === 0) {
    return <div style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else if (parseInt(payload.row.month11micount) !== parseInt(payload.row.month11count)) {
    return <div style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else {
    return <div style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  }
};
const month12formatter = (payload: any) => {
  if (parseInt(payload.value) === 0) {
    return ""
  } else if (parseInt(payload.row.month12micount) === 0) {
    return <div style={{ textAlign: "right", color: "blue", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else if (parseInt(payload.row.month12micount) !== parseInt(payload.row.month12count)) {
    return <div style={{ textAlign: "right", color: "green", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  } else {
    return <div style={{ textAlign: "right", color: "red", fontWeight: "bold" }}>{parseInt(payload.value).toLocaleString()}</div>
  }
};
// const SelectFormatter =(payload:any) => {
//   console.log(payload)
//   if(payload.row.receipt_at && payload.row.payment_at){
//     // return moment(payload.value).format("YYYY/MM/DD");
//     return <div>　</div>
//   }else{
//     return <div  style={{background:"#CCC"}}>　</div>;
//   }
//   };


const RowRenderer = ({ renderBaseRow, ...props }: any) => {
  //console.log(props)
  //const color = props.idx % 2 ? "green" : "blue";

  let backgroundColor = ""
  if (props.row.is_cancel === 1) {
    backgroundColor = " row-highlight-pink";
  } else if (props.row.counting === 2) {
    backgroundColor = " row-highlight-smoke";
  }

  //divだと描画が震える　.border-bottoms>div>div 　アンダーバーは←のように指定
  return <span className={backgroundColor}>{renderBaseRow(props)}</span>;



};





const defaultColumnProperties = {
  formatter: function (props: any, a: any) {
    console.log(props)
    if (props.row.new === true) {
      return <div style={{ background: '#7fffd4', height: "100%", paddingLeft: "5px" }}>{props.value}</div>
    } else if (props.row.del === true) {
      return <div style={{ background: 'pink', height: "100%", paddingLeft: "5px" }}>{props.value}</div>
    } else if (props.row["1010_check"]) {
      return <div style={{ paddingLeft: "5px", color: 'red' }}>{props.value}　</div>
    } else {
      return <div style={{ paddingLeft: "5px" }}>{props.value}　</div>
    }



  },

  // width: 120
};



/**
 *会計連動グリッド
 *
 * @class AccountingGridComponent
 * @extends {Component<Props, State>}
 */
class AccountingGridComponent extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.onRowClick = this.onRowClick.bind(this);

    this.state = {
      refresh: false,
      columns: this.props.koumokulist


      // (c: any) => ({ ...c, ...defaultColumnProperties }))
    };

    this.sortRows = this.sortRows.bind(this);
    // this.SectionFormatter = this.SectionFormatter.bind(this);
    // this.getCellActions = this.getCellActions.bind(this);
    this.getRowCount = this.getRowCount.bind(this);

  }




  // \[
  //   { key: 'bk_name', name: '部屋名', width: 60, resizable: true },
  //   { key: 'hy_name', name: '契約者名', width: 100, resizable: true },
  //   { key: 'kys_name', name: '契約者名', width: 100, resizable: true },
  // //   { key: 'building_name', name: '物件名', width: 150, resizable: true },
  // //   { key: 'room_name', name: '物件名', width: 150, resizable: true },
  // //   { key: 'Tenpo_Name', name: '店舗', width: 100, resizable: true },
  // //   { key: 'user_name', name: '担当', width: 100, resizable: true },
  // //   { key: 'name1', name: '入居者名', width: 150, resizable: true },

  //     ].map(c => ({ ...c, ...defaultColumnProperties }))

  // SectionFormatter = (payload: any) => {

  //   const result = this.props.shops.filter((x: any) => x.tenpoNo === payload.value);

  //   return result[0].tenpoName;

  // };


  componentWillMount() {

    // this.props.get_banks({output:true,name:"",date1:"",date2:""})


  }

  getSelectedCell(event: any) {
    alert()
  }

  onRowClick = (rowIdx: any, row: any) => {
    this.props.change_searchky({ bk_no: row.bk_no, hy_no: row.hy_no, ky_no: row.ky_no })

    this.props.kytoggle()

  }


  // rowGetter = (i:number) => {
  //     return this.props.rent_contracts_list[i];
  //   };

  onRowsSelected = (rows: any) => {
    console.log(rows)
    rows.forEach(function (r: any) {
      r.row.selected = true;
    });

    this.forceUpdate();

  };

  onRowsDeselected = (rows: any) => {

    rows.forEach(function (r: any) {
      r.row.selected = false;
    });
    this.forceUpdate();
  };

  sortRows = (initialRows: any, sortColumn: any, sortDirection: any) => {


    const comparer = (a: any, b: any) => {
      // if (sortColumn === "created_at") {
      //   if (sortDirection === "ASC") {

      //     return new Date(a[sortColumn]) > new Date(b[sortColumn]) ? 1 : -1;
      //   } else if (sortDirection === "DESC") {
      //     return new Date(a[sortColumn]) < new Date(b[sortColumn]) ? 1 : -1;
      //   } else {
      //     return 1;
      //   }
      // } else {

      if (sortDirection === "ASC") {

        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      } else {
        return 1;
      }
      // }
    };


    this.setState({
      refresh: !this.state.refresh
    });
    return sortDirection === "NONE" ? initialRows : this.props.rent_moneies.sort(comparer);
  };




  getRowCount() {
    let count = this.props.rent_moneies.length;
    if (this.state.refresh && count > 0) {
      count--; // hack for update data-grid
      this.setState({
        refresh: false
      });
    }

    return count;
  }



  render() {

    return (
      <div id="rentmonies">

        <ReactDataGrid
          // ref={node => this.grid = node}
          enableCellSelect={false}
          columns={[

            { key: 'hy_no', name: '部屋名', width: 60, resizable: true, formatter: roomFormatter },
            { key: 'kys_name', name: '契約者名', width: 100, resizable: true, formatter: nameFormatter },
            { key: 'month1', name: '1月', width: 80, resizable: true, formatter: month1formatter },
            { key: 'month2', name: '2月', width: 80, resizable: true, formatter: month2formatter },
            { key: 'month3', name: '3月', width: 80, resizable: true, formatter: month3formatter },
            { key: 'month4', name: '4月', width: 80, resizable: true, formatter: month4formatter },
            { key: 'month5', name: '5月', width: 80, resizable: true, formatter: month5formatter },
            { key: 'month6', name: '6月', width: 80, resizable: true, formatter: month6formatter },
            { key: 'month7', name: '7月', width: 80, resizable: true, formatter: month7formatter },
            { key: 'month8', name: '8月', width: 80, resizable: true, formatter: month8formatter },
            { key: 'month9', name: '9月', width: 80, resizable: true, formatter: month9formatter },
            { key: 'month10', name: '10月', width: 80, resizable: true, formatter: month10formatter },
            { key: 'month11', name: '11月', width: 80, resizable: true, formatter: month11formatter },
            { key: 'month12', name: '12月', width: 80, resizable: true, formatter: month12formatter },
            //   { key: 'room_name', name: '物件名80 width: 150, resizable: true },
            //   { key: 'Tenpo_Name', name: '店舗', width: 100, resizable: true },
            //   { key: 'user_name', name: '担当', width: 100, resizable: true },
            //   { key: 'name1', name: '入居者名', width: 150, resizable: true },

          ]}
          // .map(c => ({ ...c, ...defaultColumnProperties }))}
          // .map((c: any) => ({ ...c, ...defaultColumnProperties }))}

          rowGetter={(i: number) => this.props.rent_moneies[i]}
          // getCellActions={this.getCellActions}
          rowsCount={this.getRowCount()}
          headerRenderer={true}
          dragable={true}
          rowRenderer={RowRenderer}
          // onColumnResize={(idx, width) =>
          //     console.log(`Column ${idx} has been resized to ${width}`)
          // }
          // emptyRowsView={this.EmptyRowsView}
          onRowClick={this.onRowClick}
          // rowSelection={{
          //     showCheckbox: true,
          //     enableShiftSelect: true,
          //     onRowsSelected: this.onRowsSelected,
          //     onRowsDeselected: this.onRowsDeselected,
          //     selectBy: {
          //       isSelectedKey: 'selected'
          //       // indexes: this.state.selectedIndexes
          //     }
          //   }}
          onGridSort={(sortColumn: any, sortDirection: any) => { this.sortRows(this.props.rent_moneies, sortColumn, sortDirection) }
            //
          }
          //onCellSelected={this.getSelectedCell}
          minHeight={600} />
      </div>
    );
  }


}



const mapStateToProps = (state: any) => {
  return {
    // user_details: state.masterReducer.user_details,
    // shops: state.masterReducer.shops,
    // owner_remittance_check_logs: state.RemittanceLogCheck.owner_remittance_check_logs,

    // minories: state.Minori.minories,

    sokinlist: state.RentMoney.sokinlist,
    koumokulist: state.RentMoney.koumokulist,
    rent_moneies: state.RentMoney.rent_moneies,
    // parking_check_loading: state.ContractCheck.owner_remittance_check_logs_list,
    // energization_check_loading: state.ContractCheck.energization_check_loading,
    // net_check_loading: state.ContractCheck.net_check_loading,
    // electronics_check_loading: state.ContractCheck.electronics_check_loading,
    // wifi_check_loading: state.ContractCheck.wifi_check_loading,
    // owner_tel_check_loading: state.ContractCheck.owner_tel_check_loading,

  }
}
function mapDispatchToProps(dispatch: any) {
  return {

    change_searchky(state: any) {
      dispatch(KyDetail.change_searchky(state))
    },
    // energization_checked(state: any) {
    //   dispatch(RemittanceLogCheck.energization_checked(state))
    // },
    // net_checked(state: any) {
    //   dispatch(RemittanceLogCheck.net_checked(state))
    // },
    // electronics_checked(state: any) {
    //   dispatch(RemittanceLogCheck.electronics_checked(state))
    // },
    // wifi_checked(state: any) {
    //   dispatch(RemittanceLogCheck.wifi_checked(state))
    // },
    // completed_checked(state: any) {
    //   dispatch(RemittanceLogCheck.completed_checked(state))
    // },

    // owner_tel_checked(state: any) {
    //   dispatch(RemittanceLogCheck.owner_tel_checked(state))
    // },

    // bind_kyousai_contract(state: any) {
    //   dispatch(ContractSearch.bind_kyousai_contract(state))
    // },
    //   get_banks(state:any) {
    //     dispatch(Kyousai.get_banks(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingGridComponent);