import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import Button from '@material-ui/core/Button';
// import { CSVLink, CSVDownload } from "react-csv";
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from "date-fns/locale/ja";
import format from "date-fns/format";
import * as Board from './Saga/Board';
import { CSVLink } from "react-csv";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Cell, Text, PieChart, Pie, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

var moment = require('moment-timezone');
class ExtendedUtils extends DateFnsUtils {
    getCalendarHeaderText(date: any) {
        return format(date, "yyyy MMM", { locale: this.locale });
    }
    getDatePickerHeaderText(date: any) {
        return format(date, "MMMd日", { locale: this.locale });
    }
}
const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');


class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}

interface Props {
    get_chat_data_list: any,
    chat_data_list: any,
    search_status_true: any,
    search_status: any,
}



interface State {
    date1: any,
    date2: any,
    totyu: boolean,
    all: any,
    load: boolean
}

var datatata = [
    { name: "あ", value: 10 },
    { name: "い", value: 20 },
    { name: "う", value: 30 }

]



//円グラフの各領域の色を定義
const COLORS = [
    '#40e0d0',
    '#90ee90',
    "#eee8aa",
    "#ffff00",
    "#fa8072",
    "#ff00ff",
    "#7b68ee",
    "#f0fff0",
    "#66cdaa"
];


const label = ({ name, value, cx, x, y, color }: any) => {
    const textAnchor = x > cx ? "start" : "end";

    if (value !== 0) {
        return (
            <>
                <Text style={{ fontSize: "13px" }} x={x} y={y} textAnchor={textAnchor}>
                    {name}
                </Text>
                <Text
                    x={x}
                    y={y}
                    dominantBaseline="hanging"
                    textAnchor={textAnchor}
                >
                    {value}
                </Text>
            </>
        )
    } else {
        return (
            <div></div>
        )
    }
}

const date1 = new Date();
const year = date1.getFullYear();
const month = date1.getMonth() + 1;
var sdate = moment(year + "-" + month + "-01").format('YYYY-MM-DD');

class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            date1: sdate,
            date2: new Date(),
            totyu: false,
            all: 0,
            load:true
        }
        this.csv = this.csv.bind(this);
        this.totyu = this.totyu.bind(this);

    }


    totyu() {
        this.setState({ totyu: !this.state.totyu })
    }


    onchange_date1 = (date: any) => {
        this.setState({
            date1: new Date(date),
        });
    };

    onchange_date2 = (date: any) => {
        this.setState({
            date2: new Date(date),
        });
    };

    csv() {
     
        this.props.search_status_true();

        var data = {
            date1: moment(this.state.date1).utc().format('YYYY-MM-DD'),
            date2: moment(this.state.date2).utc().format('YYYY-MM-DD'),
            totyu: this.state.totyu
        }

        this.props.get_chat_data_list(data);
    }

    render() {


        if (this.props.search_status == true) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'auto';
        }
        return (
            <div>
                <div>
                    チャットの集計

                    
                    
                </div>
                <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker
                        variant="inline"
                        margin="normal"
                        id="date1"
                        format="yyyy/MM/dd"
                        style={{ width: 130 }}
                        value={this.state.date1}
                        onChange={this.onchange_date1}
                        autoOk={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>

                <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>

                <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker
                        variant="inline"
                        margin="normal"
                        id="date-picker-dialog"
                        format="yyyy/MM/dd"
                        style={{ width: 130 }}
                        value={this.state.date2}
                        onChange={this.onchange_date2}
                        autoOk={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>


                <FormControlLabel
                    onChange={this.totyu}
                    control={<Checkbox
                        color="primary"
                        name="totyu" />}
                    label="入力途中含む"
                />

              

                {this.props.search_status == false ?
                    <Button
                        size="small"
                        style={{ color: "white", backgroundColor: "#4169e1", margin: 1 }}
                        onClick={this.csv}
                        variant="contained">
                        検索
                            </Button> : <div></div>}
                {/* {this.props.chat_data_list[0] ?
                    <CSVLink
                        data={this.props.chat_data_list[0].csv}
                        filename={"my-file.csv"}
                        target="_blank"
                    >
                        <Button
                            size="small"
                            style={{ color: "white", backgroundColor: "#008000", margin: 1 }}
                            variant="contained">
                            CSV
                            </Button>
                    </CSVLink> : ""} */}
                {this.props.chat_data_list[0] ?

                    <div>
                        {this.props.chat_data_list[0].all}件　(全リクエスト中{this.props.chat_data_list[0].parseint}%)

                        <PieChart width={450} height={190}>
                            <Pie data={this.props.chat_data_list[0].data} dataKey="value" cx="50%" cy="50%" outerRadius={60} label={label}
                                startAngle={90}
                                endAngle={-270}>
                                { //円グラフの色を各領域ごとに分けるように指定
                                    this.props.chat_data_list[0].data.map((entry: any, index: any) =>
                                        <Cell fill={COLORS[index % COLORS.length]} />)
                                }
                            </Pie>
                        </PieChart>


                    </div>

                    : <div style={{ height: window.innerHeight - 710 }}>
                    <CircularProgress color="inherit" /></div>
                }





            </div>
        );

    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        chat_data_list: state.board.chat_data_list,
        search_status: state.board.search_status,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_chat_data_list(state: any) {
            dispatch(Board.get_chat_data_list(state))
        },
        search_status_true(state: any) {
            dispatch(Board.search_status_true(state))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);




