// const authentication = require('react-azure-adb2c').default;
const authentication = require('../../react-azure-adb2c2').default;
export const UsersLIst = (values: any) => {
    const url = process.env.REACT_APP_API_BASE_URL + `/api/users/`;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token }
    })
        .then(res => (res.json()))
        .catch(error => ({ error }));
}
export const UsersDetails = (values: any) => {
    const url = process.env.REACT_APP_API_PM_URL + `/v1/users/details`;
        // let url = `http://localhost:5000/v1/users/details`;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    return fetch(url, {
        method: 'post',
        body: JSON.stringify({application_id:"room_support_desk"}),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}