import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import ja from "date-fns/locale/ja";
import './Css/Grid.css';
import DateFnsUtils from '@date-io/date-fns';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CSVLink } from "react-csv";
import * as CompleteCheck from './Saga/CompleteCheck';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import jaLocale from "date-fns/locale/ja";
import { format } from 'date-fns'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');



var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}


class JaLocalizedUtils extends DateFnsUtils {
  // ヘッダ部分のテキストを取得するメソッド
  getDatePickerHeaderText(date) {
    return format(date, 'M月d日(E)', { locale: this.locale })
  }
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    }
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


const columns =
  [
    { key: 'completed_approval', name: '承認', width: 50, resizable: true },
    { key: 'created_at', name: '受付日', width: 90, resizable: true },
    { key: 'complete_date', name: '完了日', width: 140, resizable: true },
    { key: 'keika_date', name: '経過日数', width: 70, resizable: true },
    { key: 'Tatemono_Name', name: '建物名', width: 200, resizable: true },
    { key: 'Room_Name', name: '部屋名', width: 80, resizable: true },
    { key: 'completed_title', name: '完了タイトル', width: 300, resizable: true },
    { key: 'completed_remarks', name: '完了結果', width: 400, resizable: true },
    { key: 'not_published_flag', name: '掲載', width: 50, resizable: true },
    { key: 'taiou_user', name: '対応担当', width: 70, resizable: true },
    { key: 'remarks', name: '業務課備考', width: 150, resizable: true },
    { key: 'name', name: '区分', width: 150, resizable: true },
    { key: 'Title', name: '案件タイトル', width: 150, resizable: true },
    { key: 'Naiyou', name: '案件内容', width: 300, resizable: true },
    { key: 'Kokyaku_Name', name: '顧客名', width: 150, resizable: true },

  ]

const Base = ({ column, props, rowIndex, detail_list }) =>

  (() => {
    if (column.key == "Tatemono_Name") {
      return <div onClick={() => detail_list(props[rowIndex]['Outai_No'])} style={{ cursor: "pointer" }}>
        <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}>
          <Cell style={{ whiteSpace: "nowrap", cursor: "pointer" }}>
            {props[rowIndex][column.key] ? props[rowIndex][column.key] : props[rowIndex]["P_Name"]}
          </Cell></Tooltip>
      </div>
    } else if (column.key == "Room_Name") {
      return <div onClick={() => detail_list(props[rowIndex]['Outai_No'])} style={{ cursor: "pointer" }}>
        <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}>
          <Cell style={{ whiteSpace: "nowrap", cursor: "pointer" }}>
            {props[rowIndex][column.key] ? props[rowIndex][column.key] : props[rowIndex]["PA_Name"]}
          </Cell></Tooltip>
      </div>
    } else {
      return <div onClick={() => detail_list(props[rowIndex]['Outai_No'])} style={{ cursor: "pointer" }}>
        <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}>
          <Cell style={{ whiteSpace: "nowrap", cursor: "pointer" }}>
            {props[rowIndex][column.key]}
          </Cell></Tooltip>
      </div>
    }
  })()
const GridDates = ({ column, props, rowIndex, detail_list }) =>
  <div onClick={() => detail_list(props[rowIndex]['Outai_No'])} style={{ cursor: "pointer" }}>
    <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}>
      <Cell style={{ whiteSpace: "nowrap", cursor: "pointer" }}>
        {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).tz("utc").format('YYYY-MM-DD') : ""}
      </Cell></Tooltip>
  </div>

const ChangeDates = ({ column, props, rowIndex, date_input }) =>
  <div>
    <Cell style={{ whiteSpace: "nowrap" }}>
      <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
        <KeyboardDatePicker
          autoOk={true}
          variant="inline"
          margin="normal"
          id="date-picker-dialog"
          format="yyyy/MM/dd"
          value={props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).tz("utc").format('YYYY-MM-DD') : ""}
          onChange={(e) => date_input(e, rowIndex, column.key)}
          style={{ width: 130, marginTop: 0 }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    </Cell>
  </div>

const KeikaDates = ({ column, props, rowIndex, detail_list }) =>
  (() => {
    var dateTo = props[rowIndex]['created_at'] ? moment(props[rowIndex]['created_at']).tz("utc").format('YYYY-MM-DD 00:00:00.000') : "";
    var dateFrom = props[rowIndex]['complete_date'] ? moment(props[rowIndex]['complete_date']).tz("utc").format('YYYY-MM-DD 00:00:00.000') : "";
    var keika = "";
    if (dateTo && dateFrom) {
      var diffMilliSec = new Date(dateFrom) - new Date(dateTo);
      var keika = parseInt(diffMilliSec / 1000 / 60 / 60 / 24);
    }
    return <div onClick={() => detail_list(props[rowIndex]['Outai_No'])} style={{ cursor: "pointer" }}>
      <Cell style={{ whiteSpace: "nowrap", cursor: "pointer" }}>
        {keika}日
      </Cell>
    </div>
  })()

const Text = ({ column, props, rowIndex, detail_list, text_input }) =>
  <div style={{ borderTop: "2px solid black" }}>
    <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}>
      <textarea
        rows="2"
        onChange={(e) => text_input(e, rowIndex, column.key)}
        value={props[rowIndex][column.key]}
        style={{ width: window.innerWidth - 360, backgroundColor: props[rowIndex]['edit'] ? "#87cefa" : "" }}
      ></textarea>
    </Tooltip>
  </div>

const Check = ({ column, props, rowIndex, detail_list, check_input }) =>
  (() => {
    if (props[rowIndex][column.key]) {
      return <div>
        <Cell onClick={() => check_input(rowIndex, props[rowIndex][column.key])} style={{ whiteSpace: "nowrap", cursor: "pointer" }}>　</Cell>
      </div>
    } else {
      return <div>
        <Cell onClick={() => check_input(rowIndex, props[rowIndex][column.key])} style={{ whiteSpace: "nowrap", cursor: "pointer" }}>
          <CheckIcon />
        </Cell>
      </div>
    }
  })()


const Syounin = ({ column, props, rowIndex, detail_list, approval }) =>
  (() => {
    if (props[rowIndex][column.key] == 0) {
      return <div>
        {/* <Cell style={{ whiteSpace: "nowrap", marginBottom: 10, marginLeft: 6 }}> */}
        {/* <FormControlLabel
            onChange={() => approval(rowIndex, props[rowIndex][column.key])}
            control={<Checkbox
              color="primary"
              name="yanushi_hoshu" />}
          />
        </Cell> */}
        <Cell onClick={() => approval(rowIndex, props[rowIndex][column.key])} style={{ whiteSpace: "nowrap", cursor: "pointer", marginLeft: 6, marginTop: 2 }}>
          <CheckBoxOutlineBlank />
        </Cell>
      </div>
    } else {
      return <div>
        {/* <Cell style={{ whiteSpace: "nowrap", marginBottom: 10, marginLeft: 6 }}>
          <FormControlLabel
            onChange={() => approval(rowIndex, props[rowIndex][column.key])}
            control={<Checkbox
              defaultChecked
              color="primary"
              name="yanushi_hoshu" />}
          />
        </Cell> */}
        <Cell onClick={() => approval(rowIndex, props[rowIndex][column.key])} style={{ whiteSpace: "nowrap", cursor: "pointer", marginLeft: 6, marginTop: 2 }}>
          <CheckBoxIcon />
        </Cell>
      </div>
    }
  })()
// <div>
//   {props[rowIndex][column.key] == 0 ?
//     <Button onClick={() => approval(props[rowIndex]["Outai_No"], 1, rowIndex)} style={{ marginTop: 10 }} size="small" variant="contained" color="primary"  >承認する</Button>
//     : <Button onClick={() => approval(props[rowIndex]["Outai_No"], 0, rowIndex)} style={{ color: "white", backgroundColor: "green", marginTop: 10 }} size="small" variant="contained"  >承認済み</Button>}
// </div>


class KizuList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns,
      reflash: false,
      colSortDirs: {},
      text_edit_flag: false,
      disps: true,
      alls: false
    };
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.detail_list = this.detail_list.bind(this);
    this.text_input = this.text_input.bind(this);
    this.date_input = this.date_input.bind(this);
    this.check_input = this.check_input.bind(this);
    this.text_save = this.text_save.bind(this);
    this.approval = this.approval.bind(this);
    this.all_approval = this.all_approval.bind(this);

    this._rowClassNameGetter = this._rowClassNameGetter.bind(this);

  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }
  _onSortChange(columnKey, sortDir) {
    let seisan_follow_search_list = this.props.list;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }



  all_approval() {
    this.setState({ disps: false })
    var i = 0;
    this.props.list.forEach(e => {
      if (this.state.alls) {
        this.props.list[i]["completed_approval"] = 0;
      } else {
        this.props.list[i]["completed_approval"] = 1;
      }
      this.props.list[i]['edit'] = true;
      i++;
    });
    this.setState({ text_edit_flag: true })
    setTimeout(() => {
      this.setState({ disps: true })
      this.setState({ alls: !this.state.alls })
    }, 100)

  }
  approval(index, check) {

    console.log(index);
    console.log(check);
    if (check == 0) {
      this.props.list[index]['completed_approval'] = 1;
      this.props.list[index]['edit'] = true;
    } else {
      this.props.list[index]['completed_approval'] = 0;
      this.props.list[index]['edit'] = true;
    }


    this.setState({ text_edit_flag: true })
  }

  // approval = (Outai_No, completed_approval, index) => {
  //   this.props.list[index]["completed_approval"] = completed_approval;
  //   this.props.complete_approval([{ Outai_No: Outai_No, completed_approval: completed_approval }])
  // }
  // all_approval = (Outai_No, completed_approval, index) => {
  //   var result = window.confirm('一括承認を実行してよろしいですか？');
  //   if (result) {
  //     var i = 0;
  //     this.props.list.forEach(e => {
  //       this.props.list[i]["completed_approval"] = 1;
  //       i++;
  //     });
  //     this.props.complete_approval(this.props.list);
  //     alert("一括承認を実行しました")
  //   }
  // }

  detail_list = (Outai_No) => {
    window.open(`/RequestDetail/` + Outai_No, '_blank', 'noopener')
  }

  text_input(text, index, col) {
    this.props.list[index][col] = text.target.value;
    this.props.list[index]['edit'] = true;

    this.setState({ text_edit_flag: true })
  }

  date_input(date, index, col) {
    this.props.list[index][col] = new Date(date);
    this.props.list[index]['edit'] = true;

    this.setState({ text_edit_flag: true })
  }

  check_input(index, check) {
    if (check == 0) {
      this.props.list[index]['not_published_flag'] = 1;
      this.props.list[index]['edit'] = true;
    } else {
      this.props.list[index]['not_published_flag'] = 0;
      this.props.list[index]['edit'] = true;
    }

    console.log(check);
    console.log(index);

    // this.props.list[index][col] = text.target.value;
    // this.props.list[index]['edit'] = true;

    this.setState({ text_edit_flag: true })
  }

  text_save() {
    var updatedata = [];
    var u = 0;
    this.props.list.forEach(e => {
      if (e.edit) {
        updatedata[u] = e;
        u++;
      }
    });
    this.props.complete_update(updatedata);
    var i = 0;
    this.props.list.forEach(e => {
      this.props.list[i]['edit'] = false;
      i++;
    });
    this.setState({ text_edit_flag: false });
    setTimeout(() => {

      alert("保存しました");
    }, 1000)
  }

  _rowClassNameGetter(index) {
    if (this.props.list[index]) {
      if (this.props.list[index]['edit']) {
        return 'pushed-row';
      }
    }
  }

  render() {
    return (
      <div>
        {/* <Button style={{ marginBottom: 0, marginRight: 20 }} size="small" variant="contained" color="primary" onClick={this.all_approval}  >一括で承認する</Button> */}


        {this.state.text_edit_flag ?
          <Button style={{ marginBottom: 0, marginRight: 20 }} size="small" variant="contained" color="primary" onClick={this.text_save}  >変更内容を保存する</Button>
          : ""}
        {this.state.disps ?
          <Table
            rowHeight={50}
            rowsCount={this.props.list.length}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            width={window.innerWidth - 370}
            height={window.innerHeight - 105}
            headerHeight={30}
            rowClassNameGetter={this._rowClassNameGetter}
          >

            {columns.map(function (value, i) {
              return <Column
                columnKey={value.key}
                header={<SortHeaderCell
                  onSortChange={value.key == "completed_approval" ? this.all_approval : this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}
                cell={({ rowIndex, ...props }) => (
                  value.key === "not_published_flag" ? <Check column={value} props={this.props.list} rowIndex={rowIndex} detail_list={this.detail_list} check_input={this.check_input}></Check>
                    : value.key === "completed_approval" ? <Syounin column={value} props={this.props.list} rowIndex={rowIndex} detail_list={this.detail_list} approval={this.approval}></Syounin>
                      : value.key === "keika_date" ? <KeikaDates column={value} props={this.props.list} rowIndex={rowIndex} detail_list={this.detail_list} text_input={this.text_input}></KeikaDates>
                        : value.key === "completed_title" || value.key === "completed_remarks" ? <Text column={value} props={this.props.list} rowIndex={rowIndex} detail_list={this.detail_list} text_input={this.text_input}></Text>
                          : value.key === "created_at" ? <GridDates column={value} props={this.props.list} rowIndex={rowIndex} detail_list={this.detail_list}></GridDates>
                            : value.key === "complete_date" ? <ChangeDates column={value} props={this.props.list} rowIndex={rowIndex} detail_list={this.detail_list} date_input={this.date_input}></ChangeDates>
                              : <Base column={value} props={this.props.list} rowIndex={rowIndex} detail_list={this.detail_list}></Base>
                )}
                width={this.state.columns.filter(a => a.key === value.key)[0].width}
                isResizable={true}
                fixed={value.frozen}

              />
            }, this)}
          </Table > : ""}
      </div >
    );
  }
};


//export  default CsustomerList;
const mapStateToProps = (state) => {
  return {
    list: state.CompleteCheck.list,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    complete_update(state) {
      dispatch(CompleteCheck.complete_update(state))
    },
    complete_approval(state) {
      dispatch(CompleteCheck.complete_approval(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KizuList);
