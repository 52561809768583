import React from 'react';
import Chip from '@material-ui/core/Chip';
import CallIcon from '@material-ui/icons/Call';
import WebIcon from '@material-ui/icons/Web';
import Button from '@material-ui/core/Button';

import styled from 'styled-components';

var moment = require('moment-timezone');


const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin: 10px 0;
    margin-top:15px;
`;

const Chatting = styled.div`
    width: 100%;
    text-align: right;
`;

const List = styled.ul`
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
        
    padding-left:0;
    margin-bottom:0px;
`;

const ListItem = styled.li`
        display: inline-block;
        width: 50%;
        height:300px;
        margin-left: 5px;
        white-space:normal;
        vertical-align: top;
        margin-bottom: 0px;
        
        background-color:white;
        border-radius: 12px;
        width:200px;
`;

const Title = styled.div`
        padding:10px;
    text-align:left;
`;

const Detail = styled.div`
        padding:10px;padding-top:0px;font-size:10px;height:55px;
    text-align:left;
`;

const Menu1 = styled.div`
        padding-top:5px;padding-bottom:5px;border-top:1px solid #dcdcdc;font-weight:bold;text-align:center;
`;

const Menu2 = styled.div`
        padding-top:5px;padding-bottom:5px;font-weight:bold;text-align:center;
`;


const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 10px 0;
    margin-top:15px;
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;

const Kidoku = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 25px 0;
    margin-top:15px;
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;

const Name = styled.div`
    position: relative; 
    margin: 0 0 0 0px;
    bottom: 0;
    margin-right:60px;
    font-size:12px;
    color:#FFF;
`;
// position: absolute;
const Says = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 60px 0 60px;
    padding: 5px;
    max-width: 250px;
    border-radius: 12px;
    background: #33beff;
    margin-bottom: 2px;
    text-align: left;
    color:white;

`;


const MsgArea = styled.p`
    margin:0px;
    word-wrap: break-word;
    whiteSpace:pre-line;
`;


export default class LineTextComponent extends React.Component {
    constructor(props) {
        super(props);
        const dt = new Date(this.props.msg.date)
        // alert(this.props.msg.template)
        this.state = {
            mode: '1',
            messages: [],
            text: JSON.parse(this.props.msg.template),
            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),
        };
    }

    onClickLink(uri){
        window.open(uri, '_blank', 'noopener')
        
    }

    render() {


        return (
            <Balloon className="col-xs-12">
                <Chatting>
                    <Name>
                        <span style={{ color: "black" }}>
                            <Chip label="自動" size="small" variant="outlined" />
                        </span>
                    </Name>
                    <Time>
                        <span style={{ color: "black" }}>
                            {moment.utc(this.props.msg.created_at).format('MM/DD HH:mm')}
                        </span>
                    </Time>
                    <Kidoku>
                        <span style={{ color: "black" }}>
                            {this.props.msg.read_at ? "既読" : ""}
                        </span>
                    </Kidoku>

                    <Says onClick={()=>this.onClickLink(this.state.text.uri)}>
                        <MsgArea>
                            {/* {this.state.text.text} */}
                            {/* {this.state.text.text ? <span dangerouslySetInnerHTML={{ __html: this.state.text.text.replace(/\r?\n/g, '<br/>') }} />:""} */}
                            <WebIcon style={{float:"left", marginTop:10, marginRight:20, marginLeft:20}}></WebIcon>
   
                                <p style={{marginBottom:0}}>{this.state.text.text}</p>

                            {/* <span dangerouslySetInnerHTML={{ __html: this.props.msg.template.replace(/\r?\n/g, '<br/>') }} /> */}
                        </MsgArea>
                    </Says>

                </Chatting>
            </Balloon>
        )
    }
}
