import React, { createContext, useState, useContext } from 'react'
import { Authentication } from '../../App'
import axios from "axios";
import Grid from '@mui/material/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RoomGrid from './RoomGrid';
import RoomGraph from './RoomGraph';
import KokyakuGrid from './KokyakuGrid';
import KokyakuGraph from './KokyakuGraph';
function RequestAnalysis() {
  const authentication = useContext(Authentication);

  const [created_at1, setCreated_at1] = useState(new Date());
  const [created_at2, setCreated_at2] = useState(new Date());
  const [rooms_data, setRooms_data] = useState([]);
  const [koyaku_data, setKoyaku_data] = useState([]);

  React.useEffect(() => {
    let url = `http://localhost:5000/v1/t/outai/request_analysis`;
    axios.post(url, {
      created_at1: '2023-01-01',
      created_at2: created_at2
    }).then((r) => {
      setRooms_data(r.data);
    });

    let url2 = `http://localhost:5000/v1/t/outai/request_analysis2`;
    axios.post(url2, {
      created_at1: '2023-01-01',
      created_at2: created_at2
    }).then((r) => {
      setKoyaku_data(r.data);
    });
  }, []);


  return (
    <div>

      <div className="row" >
        <div className="col-lg-4" style={{ padding: 15 }}>
          <span>日・月・年</span>
          <span style={{ marginLeft: 10 }}>日付を選択</span>
          <span style={{ marginLeft: 10 }}>検索</span>
        </div>


        {/* <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Item One" {...a11yProps(0)} />
          <Tab label="Item Two" {...a11yProps(1)} />
          <Tab label="Item Three" {...a11yProps(2)} />
        </Tabs>
      </AppBar> */}


      </div>

      <div className="row" >
        <div className="col-lg-4" style={{ padding: 5 }}>
          <Card  >
            <CardContent >
              対応種別
            </CardContent >
          </Card >
        </div>
        <div className="col-lg-4" style={{ padding: 5 }}>
          <Card  >
            <CardContent >
              グラフ
            </CardContent >
          </Card >
        </div>
      </div>

      <div className="row" >
        <div className="col-lg-6" style={{ padding: 5 }}>
          <Card  >
            <CardContent >
              部屋別区分
              <RoomGrid rooms_data={rooms_data} />
            </CardContent >
          </Card >
        </div>
        <div className="col-lg-6" style={{ padding: 5 }}>
          <Card  >
            <CardContent >
              グラフ
              <RoomGraph rooms_data={rooms_data} />
            </CardContent >
          </Card >
        </div>
      </div>

      <div className="row" >
        <div className="col-lg-6" style={{ padding: 5 }}>
          <Card  >
            <CardContent >
              顧客区分別
              <KokyakuGrid koyaku_data={koyaku_data} />
            </CardContent >
          </Card >
        </div>
        <div className="col-lg-6" style={{ padding: 5 }}>
          <Card  >
            <CardContent >
              グラフ
              <KokyakuGraph koyaku_data={koyaku_data}/>
            </CardContent >
          </Card >
        </div>
      </div>

      <div className="row" >
        <div className="col-lg-4" style={{ padding: 5 }}>
          <Card  >
            <CardContent >
              契約店舗別
            </CardContent >
          </Card >
        </div>
        <div className="col-lg-4" style={{ padding: 5 }}>
          <Card  >
            <CardContent >
              グラフ
            </CardContent >
          </Card >
        </div>
      </div>
    </div>
  );
};

export default RequestAnalysis;
