import React from 'react';

import { connect } from 'react-redux';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Menu from './MenuComponent';
import styled from 'styled-components';
import EventListener from 'react-event-listener';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { withRouter } from 'react-router';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TimelineIcon from '@material-ui/icons/Timeline';
import EventNoteIcon from '@material-ui/icons/EventNote';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SubjectIcon from '@material-ui/icons/Subject';
import LinkIcon from '@material-ui/icons/Link';
import ContactsIcon from '@material-ui/icons/Contacts';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import NotificationsIcon from '@mui/icons-material/Notifications';
// import FactCheckIcon from '@mui/icons-material/FactCheck';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import * as MessageList from '../MessageList/Saga/MessageList';
import * as Chat from '../Chat/Saga/Chat';

// import Badge from '@material-ui/core/Badge';
import Badge from '@mui/material/Badge';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
// import { smsDB } from '../../Store/FirebaseStore';
let unsub
var moment = require('moment-timezone');
// const Button = styled.button`
//     background: red;
//     border-radius: 8px;
//     color: white;
// `;

const MDiv = styled.div`
    list-style-type: none;
   
`;

const styles = ((theme) => ({
    root: {
        // flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
        // display: 'flex',

        color: "#FFF",
        textAlign: "center",
        paddingTop: 10,
        paddingLeft: 10,
        // height: 224,
    },
    active: {
        // flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
        // display: 'flex',
        paddingTop: 10,
        color: "#FFF",
        textAlign: "center",
        paddingLeft: 10,
        backgroundColor: "#b0c4de"
        // height: 224,
    },
    gutters: {
        textAlign: "center"
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: 60,
        color: "#FFF",
        paddingLeft: 3
    },

}))

const theme = createTheme({
    palette: {
        badge_color: {
            main: '#E4566D',
            contrastText: '#fff',
        },
    },
});


class NavMenu extends React.Component {

    constructor(props) {
        super(props);


        this.toggle = this.toggle.bind(this);
        this.link = this.link.bind(this);
        this.state = {
            dropdownOpenBuilding: false,
            dropdownOpenPatrol: false,
            height: window.innerHeight,
            showFlag: 0,
            now: moment().tz("Asia/Tokyo").format('YYYY-MM-DD HH:mm:ss'),
            times: false,
            user_seach: false,
            approvalPendingFlag: true,  //  お知らせの承認待バッジ用
            approvalPendingNum: 2,
            deliveryPendingFlag: true,  //  お知らせの配信待バッジ用
            deliveryPendingNum: 1,
        };
        // props.get_message_count(props.user_details.id);

    }
    toggle() {
    }
    handleResize = () => {
        this.setState({ height: window.innerHeight });
    };
    link = (link) => {
        this.props.history.push(link)
    }
    openInNewTab = (url) =>{
        window.open(url, '_blank');
    }
    render() {


        // 一旦消してみてた
        if (this.state.user_seach == false && this.props.user_details.id) {
            this.props.get_message_count(this.props.user_details.id);
            this.props.get_news_count(this.props.user_details.id);
            this.setState({ user_seach: true });
        }


        return (

            <div id="aside" style={{ position: "relative" }}>
                <MenuList >
                    <MenuItem className={this.props.location.pathname === "/board" ? this.props.classes.active : this.props.classes.root} onClick={() => { this.link('/board'); this.props.get_chat_close(); }}>
                    {/* <MenuItem className={this.props.location.pathname === "/board" ? this.props.classes.active : this.props.classes.root}> */}

                        <div>
                            <div><DashboardIcon style={{ fontSize: 28 }} />
                            </div>
                            <div style={{ fontSize: "8px", marginTop: "5px", marginLeft: "0px", color: "#FFFFFF" }}>ボ ー ド</div>
                        </div>
                    </MenuItem >

                    <MenuItem className={this.props.location.pathname === "/Adjustment" ? this.props.classes.active : this.props.classes.root} onClick={() => { this.link('/Adjustment'); this.props.get_chat_close(); }}>
                        <div title="リクエストの確認や登録が行えます。">
                            <div><MenuBookIcon style={{ fontSize: 28 }} />
                            </div>
                            <div style={{ fontSize: "8px", marginTop: "5px", marginLeft: "0px", color: "#FFFFFF" }}>ﾘ ｸ ｴ ｽ ﾄ</div>
                        </div>
                    </MenuItem >

                    <MenuItem className={this.props.location.pathname === "/Keiyaku" ? this.props.classes.active : this.props.classes.root} onClick={() => { this.link('/Keiyaku'); this.props.get_chat_close(); }}>
                        <div title="契約情報の確認や一斉通知が行えます。">
                            <div><AccountBalanceWalletIcon style={{ fontSize: 28 }} /></div>
                            <div style={{ fontSize: "8px", marginTop: "5px", marginLeft: "0px", color: "#FFFFFF" }}>建物管理</div>
                        </div>
                    </MenuItem>
                    {/* <MenuItem className={this.props.location.pathname === "/MessageList" ? this.props.classes.active : this.props.classes.root} onClick={() => { this.link('/MessageList'); this.props.get_chat_close(); }}>
                    
                        <div title="メッセージのやりとりが一覧で確認できます。">

                            {this.props.message_count.count == "0" ? <SubjectIcon style={{ fontSize: 28 }} />

                                :
                                <ThemeProvider theme={theme}>
                                    <Badge badgeContent={this.props.message_count.count} color="badge_color">
                                        {this.props.message_count.users == "0" ? "" : <Badge badgeContent={this.props.message_count.users} color="primary">
                                        </Badge  >}
                                        <SubjectIcon style={{ fontSize: 28 }} />
                                    </Badge></ThemeProvider>}


                            <div style={{ fontSize: "8px", marginTop: "5px", marginLeft: "0px", color: "#FFFFFF" }}>ｱｸﾃｨﾋﾞﾃｨ</div>
                        </div>
                    </MenuItem> */}
                    <MenuItem className={this.props.location.pathname === "/customerlink" ? this.props.classes.active : this.props.classes.root} onClick={() => { this.link('/customerlink'); this.props.get_chat_close(); }}>
                        <div title="入居者アプリのアカウントと契約情報の紐付けを行えます。">
                            <div><LinkIcon style={{ fontSize: 28 }} /></div>
                            <div style={{ fontSize: "8px", marginTop: "5px", marginLeft: "0px", color: "#FFFFFF" }}>顧客結付</div>
                        </div>
                    </MenuItem>
                    <MenuItem className={this.props.location.pathname === "/ContractsList" ? this.props.classes.active : this.props.classes.root} onClick={() => { this.link('/ContractsList'); this.props.get_chat_close(); }}>
                        <div title="店舗毎の契約のアプリ登録状況を確認できます。">
                            <div><ContactsIcon style={{ fontSize: 28 }} /></div>
                            <div style={{ fontSize: "8px", marginTop: "5px", marginLeft: "0px", color: "#FFFFFF" }}>登録状況</div>
                        </div>
                    </MenuItem>
                    <MenuItem className={this.props.location.pathname === "/KizuCheckList" ? this.props.classes.active : this.props.classes.root} onClick={() => { this.link('/KizuCheckList'); this.props.get_chat_close(); }} style={{ paddingLeft: '5px' }}>
                        <div title="傷汚れチェックシート確認画面">
                            <div><PlaylistAddCheckIcon style={{ fontSize: 35 }} /></div>
                            <div style={{ fontSize: "8px", marginTop: "5px", marginLeft: "0px", color: "#FFFFFF" }}>傷チェック</div>
                        </div>
                    </MenuItem>
                    <MenuItem className={this.props.location.pathname === "/BicycleList" ? this.props.classes.active : this.props.classes.root} onClick={() => { this.link('/BicycleList'); this.props.get_chat_close(); }} style={{ paddingLeft: '5px' }}>
                        <div title="所有自転車登録確認">
                            <div><PedalBikeIcon style={{ fontSize: 35 }} /></div>
                            <div style={{ fontSize: "8px", marginTop: "5px", marginLeft: "0px", color: "#FFFFFF" }}>所有自転車</div>
                        </div>
                    </MenuItem>
                </MenuList>

            </div >
        );
    }
}
// export default withRouter(withStyles(styles)(NavMenu));





//コンテナ
const mapStateToProps = (state) => {
    return {
        message_count: state.MessageList.message_count,
        news_count: state.MessageList.news_count,
        user_details: state.masterReducer.user_details,

    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_news_count(state) {
            dispatch(MessageList.news_count(state))
        },
        get_message_count(state) {
            dispatch(MessageList.message_count(state))
        },
        get_chat_close(state) {
            dispatch(Chat.get_chat_close(state))
        },
    };
}
export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NavMenu)));
