const authentication = require('../../../react-azure-adb2c2').default;

export const list = (values: any) => {

  // let url = `http://localhost:5000/v1/kizu/check/sheet/list`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/kizu/check/sheet/list`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const detail = (values: any) => {

  // let url = `http://localhost:5000/v1/kizu/check/sheet/detail`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/kizu/check/sheet/detail`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const get_filelist = (values: any) => {
  console.log(values);
  // let url = `http://localhost:5000/v1/kizu/check/sheet/file/bykeiyakuno/` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/kizu/check/sheet/file/bykeiyakuno/` + values;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const upload_file = (values: any) => {

  // let url = `http://localhost:5000/v1/kizu/check/sheet/file/upload_file/` + values.Keiyaku_No + "/" + values.created_user;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/kizu/check/sheet/file/upload_file/` + values.Keiyaku_No + "/" + values.created_user;

  const token = authentication.getAccessToken();
  var formData = new FormData();
  formData.append("files", values.files);
  console.log(formData);
  return fetch(url, {
    method: 'post',
    body: formData,
    headers: { 'Authorization': 'Bearer ' + token },
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};

export const download_file = (values: any) => {
  // let url = `http://localhost:5000/v1/kizu/check/sheet/file/download_file/` + values;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/kizu/check/sheet/file/download_file/` + values;
  const token = authentication.getAccessToken();
  return fetch(url, { method: 'GET', headers: new Headers([["Authorization", 'Bearer ' + token]]) }).then(response => response.blob())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};



export const delete_file = (values: any) => {

  // let url = `http://localhost:5000/v1/kizu/check/sheet/file/file_delete`;
  let url = process.env.REACT_APP_API_PM_URL + `/v1/kizu/check/sheet/file/file_delete`;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};