import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Search from "./Search";
import Grid from "./Grid";
import { Row, Col } from 'reactstrap';
import * as ContractsList from './Saga/ContractsList';


// import * as MesssageList from './Saga/MessageList';
var moment = require('moment-timezone');


interface Props {
    get_contracts_list: any
}

interface State {
    section_id: any,
    user_id: any,
    contract_date1: any,
    contract_date2: any,
    tatemono_name: any,
    customer_name: any,
    not_app: boolean
}


const date1 = new Date();
const year = date1.getFullYear();
const month = date1.getMonth() + 1;
var sdate = moment(year + "-" + month + "-01").format('YYYY-MM-DD');


class CustomerList extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            section_id: 0,
            user_id: 0,
            contract_date1: sdate,
            contract_date2: null,
            tatemono_name: "",
            customer_name: "",
            not_app: false
        }

        this.not_app = this.not_app.bind(this);
        this.search_change_names = this.search_change_names.bind(this);
        this.search_change_section = this.search_change_section.bind(this);
        this.search_change_user = this.search_change_user.bind(this);
        this.search_change_date1 = this.search_change_date1.bind(this);
        this.search_change_date2 = this.search_change_date2.bind(this);
        this.search = this.search.bind(this);

    }

    not_app() {
        this.setState({ not_app: !this.state.not_app })
        this.search();
    }

    search_change_names(e: any) {
        this.setState({ ...this.state, [e.target.id]: e.target.value });
    }
    search_change_section(e: any, values: any) {
        if (values) {
            this.setState({
                section_id: values.tenpoNo
            });
        } else {
            this.setState({
                section_id: 0
            });
        }
        this.search();
    }
    search_change_user(e: any, values: any) {
        if (values) {
            this.setState({
                user_id: values.value
            });
        } else {
            this.setState({
                user_id: 0
            });
        }
        this.search();
    }
    search_change_date1 = (date: any) => {
        this.setState({
            contract_date1: new Date(date),
        });
        this.search();
    };
    search_change_date2 = (date: any) => {
        this.setState({
            contract_date2: new Date(date),
        });
        this.search();
    };

    search() {
        setTimeout(() => {
            var data = {
                section_id: this.state.section_id,
                user_id: this.state.user_id,
                contract_date1: this.state.contract_date1,
                contract_date2: this.state.contract_date2,
                tatemono_name: this.state.tatemono_name,
                customer_name: this.state.customer_name,
                not_app: this.state.not_app
            }

            console.log("~=~=~=~==========================")
            console.log(data);

            this.props.get_contracts_list(data);

        }, 100)
    }

    render() {
        return (
            <div className="row" style={{ width: window.innerWidth - 70 }}>

                <div className="item-outer col-lg-2" style={{ padding: 0, height: window.innerHeight - 100 }}>
                    <Card >
                        <CardContent >
                            <Search
                                search_change_names={this.search_change_names}
                                search_change_section={this.search_change_section}
                                search_change_user={this.search_change_user}
                                search_change_date1={this.search_change_date1}
                                search_change_date2={this.search_change_date2}
                                search={this.search}
                                contract_date1={this.state.contract_date1}
                                contract_date2={this.state.contract_date2}
                                not_app={this.not_app}
                            />
                        </CardContent >
                    </Card >
                </div >

                <div className="item-outer col-lg-10" style={{ padding: 0, height: window.innerHeight - 100 }}>
                    <Card >
                        <CardContent >
                            <Grid />
                        </CardContent >
                    </Card >
                </div >
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_contracts_list(state: any) {
            dispatch(ContractsList.get_contracts_list(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




