import React ,{ useState, useEffect, useRef } from 'react';
import { connect,Field, reduxForm } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import * as Mail from './Saga/Mail';
import WebIcon from '@material-ui/icons/Web';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListDividers(props) {
  const classes = useStyles();


 

//   const node = useRef();
const select=(mail_template_id,rent_introduction_header_id)=>{
    props.add_mypage_link({window:window,id:mail_template_id,rentResponseId:props.responsedetail.id,rent_introduction_header_id:rent_introduction_header_id})
    props.togglemypageclose()
}
  return (
    <List component="nav"  className={classes.root} aria-label="mailbox folders">
         <ListItem　>
         <WebIcon/> <b >マイページ選択</b>
        </ListItem>
        <Divider />
        {props.mail_rent_introduction_header_list.map((value,key) => (
            <span key={key}>
           <ListItem　onClick={()=>select(186,value.id)}  button>
            <ListItemText primary={value.name} /> <MailOutlineIcon/>
            
          </ListItem>
          <Divider />
          </span>
        ))}
      
     
      <ListItem　onClick={()=>select(179,"")} button>
        <ListItemText primary="旧マイページ" /> <MailOutlineIcon/>
      </ListItem>
    </List>
  );
}


const mapStateToProps = state => {
    return {
        responsedetail: state.responsesReducer.responsedetail,
        mail_rent_introduction_header_list: state.mailReducer.mail_rent_introduction_header_list,
    //     map_settings_edit: state.mapmypage.map_settings_edit,
    //     rent_introduction_headers:state.mapmypage.rent_introduction_headers,
    //     map_disp:state.mapmypage.map_disp,

    //    rent_introduction_point_headers:state.mapmypage.rent_introduction_point_headers,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
  function mapDispatchToProps(dispatch) {
    return {
        add_mypage_link(state) {
            dispatch(Mail.add_mypage_link(state))
          },
         get_mail_rent_introduction_header_list(state) {
            dispatch(Mail.get_mail_rent_introduction_header_list(state))
          },
    };
}

// App = reduxForm({
//     form: 'mapsearchform', // a unique identifier for this form
//     validate,//バリデーション
  
//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(App)
// App = withStyles(useStyles)(App);  
export default connect(mapStateToProps, mapDispatchToProps)(ListDividers);   