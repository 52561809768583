import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import DateFnsUtils from '@date-io/date-fns';
import format from "date-fns/format";
import jaLocale from "date-fns/locale/ja";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloseIcon from "@material-ui/icons/Close";
import * as CompleteCheck from './Saga/CompleteCheck';
import { CSVLink } from "react-csv";

const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');

const styles = (theme: any) => ({
    margin: {
        margin: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tab: {

        minWidth: 50, // a number of your choice
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}



interface Props {
    users_select: any,
    request_syubetsu: any,
    get_complete_list: any,
    list: any,
    search_status: any
}



interface State {
    created_at1: any,
    created_at2: any,
    completed_at1: any,
    completed_at2: any,
    tatemono_name: any,
    room_name: any,
    tantou_user: any,
    main_kubun: any,
    keisai_only_check: boolean,
    misyounin_check: boolean,
}


// csvカラム★
const headers = [
    { label: "受付日", key: "created_at" },
    { label: "完了日", key: "complete_date" },
    { label: "建物名", key: "Tatemono_Name" },
    { label: "部屋名", key: "Room_Name" },
    { label: "完了タイトル", key: "completed_title" },
    { label: "完了結果", key: "completed_remarks" },
    { label: "掲載", key: "not_published_flag" },
    { label: "対応担当", key: "taiou_user" },
    { label: "業務課備考", key: "remarks" },
    { label: "案件タイトル", key: "Title" },
    { label: "顧客名", key: "Kokyaku_Name" },
];


class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.props.get_complete_list({ created_at1: moment(new Date()).utc().add(-1, 'month').format("YYYY-MM-DD") });
        this.state = {
            created_at1: moment(new Date()).utc().add(-1, 'month').format("YYYY-MM-DD"),
            created_at2: null,
            completed_at1: null,
            completed_at2: null,
            tatemono_name: null,
            room_name: null,
            tantou_user: null,
            main_kubun: null,
            keisai_only_check: false,
            misyounin_check: false
        }

        this.search = this.search.bind(this);
        this.created_at1 = this.created_at1.bind(this);
        this.created_at2 = this.created_at2.bind(this);
        this.created_at1_null = this.created_at1_null.bind(this);
        this.created_at2_null = this.created_at2_null.bind(this);
        this.completed_at1 = this.completed_at1.bind(this);
        this.completed_at2 = this.completed_at2.bind(this);
        this.completed_at1_null = this.completed_at1_null.bind(this);
        this.completed_at2_null = this.completed_at2_null.bind(this);

        this.names = this.names.bind(this);
        this.tantou_user = this.tantou_user.bind(this);
        this.main_kubun = this.main_kubun.bind(this);
        this.keisai_only_check = this.keisai_only_check.bind(this);
        this.misyounin_check = this.misyounin_check.bind(this);

    }


    search() {

        var data = {
            created_at1: this.state.created_at1,
            created_at2: this.state.created_at2,
            completed_at1: this.state.completed_at1,
            completed_at2: this.state.completed_at2,
            tatemono_name: this.state.tatemono_name,
            room_name: this.state.room_name,
            tantou_user: this.state.tantou_user,
            main_kubun: this.state.main_kubun,
            keisai_only_check: this.state.keisai_only_check,
            misyounin_check:this.state.misyounin_check
        }
        console.log(data);
        this.props.get_complete_list(data);
    }

    created_at1 = (date: any) => {
        this.setState({
            created_at1: new Date(date),
        });
    };
    created_at2 = (date: any) => {
        this.setState({
            created_at2: new Date(date),
        });
    };
    created_at1_null = (date: any) => {
        this.setState({
            created_at1: null,
        });
    }
    created_at2_null = (date: any) => {
        this.setState({
            created_at2: null,
        });
    }

    completed_at1 = (date: any) => {
        this.setState({
            completed_at1: new Date(date),
        });
    };
    completed_at2 = (date: any) => {
        this.setState({
            completed_at2: new Date(date),
        });
    };
    completed_at1_null = (date: any) => {
        this.setState({
            completed_at1: null,
        });
    }
    completed_at2_null = (date: any) => {
        this.setState({
            completed_at2: null,
        });
    }

    names(e: any) {
        console.log(e.target.id);
        console.log(e.target.value);
        this.setState({ ...this.state, [e.target.id]: e.target.value });
    }
    tantou_user(e: any, values: any) {
        if (values) {
            this.setState({
                tantou_user: values.value
            });
        } else {
            this.setState({
                tantou_user: 0
            });
        }
    }

    main_kubun = (e: any) => {
        console.log(e.target.value);

        if (e.target.value == "") {
            this.setState({
                main_kubun: null
            });
        } else {
            this.setState({
                main_kubun: e.target.value
            });
        }
    };

    keisai_only_check = (e: any) => {
        this.setState({
            keisai_only_check: e.target.checked
        });
    };
    misyounin_check = (e: any) => {
        this.setState({
            misyounin_check: e.target.checked
        });
    };
    render() {
        return (
            <div>
                <Button
                    onClick={this.search}
                    style={{ width: 100, color: "white", backgroundColor: "#43BBBB", marginTop: 15 }}
                    variant="contained">
                    検索
                </Button>
                <Chip label={(this.props.search_status == true ? 0 : this.props.list.length) + "件"} style={{ margin: 5, marginTop: 15, marginLeft: 10, fontSize: 14, }} variant="outlined" />
                <div>
                    {this.props.list.length ?
                        <CSVLink style={{ textDecoration: "none" }} headers={headers} filename={"リクエスト一覧.csv"} data={this.props.list}>
                            <Button style={{ width: 100, color: "white", backgroundColor: "#43BBBB", marginTop: 15 }} variant="contained">
                                CSV
                            </Button> </CSVLink> : ""}
                </div>

                {/* 受付日1 sss*/}
                <div>
                    <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                        <KeyboardDatePicker
                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="受付日"
                            format="yyyy/MM/dd"
                            style={{ width: 128, marginTop: 30 }}
                            value={this.state.created_at1}
                            onChange={this.created_at1}
                            autoOk={true}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>

                    <CloseIcon onClick={this.created_at1_null} style={{ marginTop: 51, fontSize: 16, cursor: "pointer" }} />

                </div>
                <div>
                    <i className="fas fa-long-arrow-alt-down" style={{ verticalAlign: "bottom", marginLeft: 20, marginTop: 10, fontSize: 20 }}></i>
                </div>
                <div>
                    {/* 受付日2 */}
                    <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                        <KeyboardDatePicker

                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="受付日"
                            format="yyyy/MM/dd"
                            style={{ width: 128, margin: 0 }}
                            value={this.state.created_at2}
                            onChange={this.created_at2}
                            autoOk={true}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>

                    <CloseIcon onClick={this.created_at2_null} style={{ marginTop: 20, fontSize: 16, cursor: "pointer" }} />

                </div>

                {/* 完了日1 */}
                <div>
                    <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                        <KeyboardDatePicker
                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="完了日"
                            format="yyyy/MM/dd"
                            style={{ width: 128, marginTop: 30 }}
                            value={this.state.completed_at1}
                            onChange={this.completed_at1}
                            autoOk={true}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <CloseIcon onClick={this.completed_at1_null} style={{ marginTop: 51, fontSize: 16, cursor: "pointer" }} />

                </div>
                <div>
                    <i className="fas fa-long-arrow-alt-down" style={{ verticalAlign: "bottom", marginLeft: 20, marginTop: 10, fontSize: 20 }}></i>
                </div>
                <div>
                    {/* 完了日2 */}
                    <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                        <KeyboardDatePicker

                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="完了日"
                            format="yyyy/MM/dd"
                            style={{ width: 128, margin: 0 }}
                            value={this.state.completed_at2}
                            onChange={this.completed_at2}
                            autoOk={true}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <CloseIcon onClick={this.completed_at2_null} style={{ marginTop: 20, fontSize: 16, cursor: "pointer" }} />

                </div>


                {/* 物件名 */}
                <TextField
                    autoComplete='off'
                    value={this.state.tatemono_name}
                    style={{ width: 150, marginTop: 20, marginLeft: 2 }}
                    id="tatemono_name"
                    onChange={this.names}
                    label="物件名"
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            // this.props.search();
                        }
                    }}
                />
                {/* 部屋号室 */}
                <TextField
                    autoComplete='off'
                    value={this.state.room_name}
                    style={{ width: 150, marginTop: 20, marginLeft: 2 }}
                    id="room_name"
                    onChange={this.names}
                    label="部屋号室"
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            // this.props.search();
                        }
                    }}
                />

                {/* 対応担当 */}
                <Autocomplete
                    id="combo-box-demo"
                    options={this.props.users_select}
                    getOptionLabel={(option: any) => option.label}
                    style={{ width: 150, marginTop: 20, marginLeft: 2 }}
                    onChange={this.tantou_user}
                    renderInput={(params) => <TextField
                        autoComplete='off' {...params} label="対応担当" />}
                />

                <div style={{ width: 150, marginTop: 20, marginLeft: 2 }}>
                    <InputLabel htmlFor="age-native-helper">メイン区分</InputLabel>
                    <NativeSelect
                        onChange={this.main_kubun}
                        inputProps={{
                            name: 'メイン区分',
                            id: 'uncontrolled-native',
                        }}
                    >
                        <option key={0}> </option>
                        <option key={5656539} value={5656539}>設備全般①～45</option>
                        <option key={5656114} value={5656114}>人的全般A～O</option>
                        <option key={5656117} value={5656117}>入居時リクエスト(設備・人的)</option>
                        {this.props.request_syubetsu.map((d: any) => (
                            <option key={d.id} value={d.id}>
                                {d.symbol}:{d.name}
                            </option>
                        ))}
                    </NativeSelect>
                </div>

                <FormControlLabel
                    style={{ marginTop: 10 }}
                    onChange={this.keisai_only_check}
                    control={<Checkbox
                        color="primary"
                        name="yanushi_hoshu" />}

                    label="掲載案件のみ"
                />

                <FormControlLabel
                    style={{ marginTop: 10 }}
                    onChange={this.misyounin_check}
                    control={<Checkbox
                        color="primary"
                        name="yanushi_hoshu" />}

                    label="未承認のみ"
                />

            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        users_select: state.masterReducer.users_select,
        request_syubetsu: state.Adjustment.request_syubetsu,
        list: state.CompleteCheck.list,
        search_status: state.CompleteCheck.search_status,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_complete_list(state: any) {
            dispatch(CompleteCheck.get_complete_list(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));




