import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import './Css/Grid.css';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import * as MessageList from './Saga/MessageList';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { smsDB } from '../../Store/FirebaseStore';
import * as Keiyaku from '../Keiyaku/Saga/Keiyaku';
import * as Sms from '../Sms/Saga/Sms';
import LinksModal from '../CustomerLink/LinksModal';
import TemplateKanri from "../Keiyaku/TemplateKanri";
import Paper from '@material-ui/core/Paper';
import SubjectIcon from '@material-ui/icons/Subject';
import Badge from '@material-ui/core/Badge';
import TabletMacIcon from '@material-ui/icons/TabletMac';

import * as Chat from '../Chat/Saga/Chat';


import Icon from '@material-ui/core/Icon';
import LaunchIcon from '@material-ui/icons/Launch';
const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
let unsub
var moment = require('moment-timezone');
var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    }
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>
        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}
const columns =
  [
    { key: 'Tatemono_Name', name: '建物', width: 200, resizable: true },
    { key: 'Room_Name', name: '部屋', width: 70, resizable: true },
    { key: 'name', name: '氏名', width: 170, resizable: true },
    // { key: 'tel', name: 'TEL', width: 150, resizable: true },
    { key: 'new_message_at', name: '最新送受信日', width: 170, resizable: true },
    { key: 'new_message', name: '最新メッセージ', width: 200, resizable: true },
    { key: 'button', name: '', width: 200, resizable: true },
    // { key: 'buttons', name: '', width: 200, resizable: true },

  ]


const Base = ({ column, props, rowIndex }) =>
  <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}>
    <Cell style={{ whiteSpace: "nowrap" }} > {props[rowIndex][column.key]} </Cell>
  </Tooltip>


const Tatemono = ({ column, props, rowIndex }) =>
  <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}>
    <Cell style={{ whiteSpace: "nowrap" }} > {props[rowIndex][column.key] ? props[rowIndex][column.key] : props[rowIndex]['Tatemono_Name2']} </Cell>
  </Tooltip>


const Room = ({ column, props, rowIndex }) =>
  <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}>
    <Cell style={{ whiteSpace: "nowrap" }} > {props[rowIndex]['Room_Name2'] ? props[rowIndex]['Room_Name2'] : Array.isArray(props[rowIndex][column.key]) ? props[rowIndex][column.key][0] : props[rowIndex][column.key]} </Cell>
  </Tooltip>


const Name = ({ column, props, rowIndex, linksopen }) =>
  (() => {
    if (props[rowIndex].m_customer_id == 0) {
      return <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}>
        <Cell style={{ whiteSpace: "nowrap" }} >
          <button onClick={() => linksopen(props[rowIndex])} className="label" style={{ backgroundColor: "#ff6347" }}>
            結付未登録
          </button>{props[rowIndex][column.key]}
        </Cell>
      </Tooltip>
    } else {
      return <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}>
        <Cell style={{ whiteSpace: "nowrap" }} >
          {props[rowIndex][column.key]}
        </Cell>
      </Tooltip>
    }
  })();

const Dates = ({ column, props, rowIndex }) =>
  (() => {
    if (props[rowIndex]["timeform"]) {
      return <Cell style={{ whiteSpace: "nowrap" }} >
        {props[rowIndex][column.key]}
      </Cell>
    } else {
      return <Cell style={{ whiteSpace: "nowrap" }} >
        {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).utc().format('YYYY-MM-DD HH:mm:ss') : ""}
      </Cell>
    }
  })();

const Buttonlink = ({ column, props, rowIndex, smsopen, chatopen }) =>
  (() => {
    return <Cell style={{ whiteSpace: "nowrap" }} >
      <Badge style={{ marginTop: 10 }} badgeContent={props[rowIndex]["red_count"]} color="secondary">
        {props[rowIndex]["blue_count"] == 0 ? "" : <Badge badgeContent={props[rowIndex]["blue_count"]} color="primary">
        </Badge  >}
        <Button
          variant="contained"
          color="primary"
          onClick={() => chatopen(props[rowIndex]["tel"], props[rowIndex]["sms_id"], props[rowIndex]["m_customer_id"], props[rowIndex]["room_support_m_user_id"])}>
          チャットを開く
        </Button>
      </Badge  >

    </Cell>;
  })();



const NewMessage = ({ column, props, rowIndex }) =>
  (() => {

    // var devices = "アプリ";
    // var devices_color = "orange";
    // if (props[rowIndex]['new_message_flag'] == 2) {
    //   devices = "SMS";
    //   devices_color = "deepskyblue";
    // }
    var url = process.env.REACT_APP_BASE_URL+"/RequestDetail/" + props[rowIndex]['outai_no'];

    if (props[rowIndex]["new_message_side"] == 1) {
      return <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}>
        <Cell style={{ whiteSpace: "nowrap", overflowWrap: 'break-word' }} >
          <div>
            {props[rowIndex]['outai_no'] !== 0 ? <a style={{ marginLeft: 10 }} href={url} target="_blank" rel="noopener"><i className="fas fa-window-restore"></i></a> : ""}

            <span className="label" style={{ backgroundColor: "blue" }}>
              送信
            </span>
            {/* <span className="label" style={{ backgroundColor: devices_color }}>
              {devices}
            </span> */}

            {props[rowIndex]["nickname"] ? <span>【{props[rowIndex]["nickname"]}】</span> : ""}

          </div>
          {props[rowIndex]["title"] ? <div>【{props[rowIndex]["title"]}】</div> : ""}
          {props[rowIndex][column.key]}
        </Cell>
      </Tooltip>;
    } else {
      return <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}>
        <Cell style={{ whiteSpace: "nowrap", overflowWrap: 'break-word' }} >
          <div>
            {props[rowIndex]['outai_no'] !== 0 ? <a style={{ marginLeft: 10 }} href={url} target="_blank" rel="noopener"><i className="fas fa-window-restore"></i></a> : ""}

            <span className="label" style={{ backgroundColor: "red" }}>
              受信
            </span>

            {/* <span className="label" style={{ backgroundColor: devices_color }}>
              {devices}
            </span> */}



          </div>
          {props[rowIndex]["title"] ? <div>【{props[rowIndex]["title"]}】</div> : ""}
          {props[rowIndex][column.key]}
        </Cell>
      </Tooltip>;
    }
  })();

const Buttons = ({ column, props, rowIndex, taiou_kanryou }) =>
  (() => {
    if (props[rowIndex].m_customer_id == 0) {
      if (props[rowIndex]["new_message_side"] == 2 && props[rowIndex]["new_message_process_flag"] == 0) {
        return <Cell style={{ whiteSpace: "nowrap" }} >
          <Button
            onClick={() => taiou_kanryou(props[rowIndex]["room_support_m_user_id"], 2, props[rowIndex].m_customer_id)}
            size="small" variant="contained" color="secondary">対応完了にする</Button>
        </Cell>
      } else {
        return ""
      }
    } else {
      if (props[rowIndex]["new_message_side"] == 2 && props[rowIndex]["new_message_process_flag"] == 0) {
        return <Cell style={{ whiteSpace: "nowrap" }} >
          <Button
            onClick={() => taiou_kanryou(props[rowIndex]["id"], 1, props[rowIndex].m_customer_id)}
            size="small" variant="contained" color="secondary">対応完了にする</Button>
        </Cell>
      } else {
        return ""
      }
    }
  })();

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns,
      reflash: false,
      reqtoggle: false,
      colSortDirs: {},
      news: false,
      linktoggle: false,
      link_data: [],
      Templatetoggle: false,
      ipad_height: 0
    };



    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.reqtoggle = this.reqtoggle.bind(this);
    this.smsopen = this.smsopen.bind(this);
    this.chatopen = this.chatopen.bind(this);
    this.taiou_kanryou = this.taiou_kanryou.bind(this);
    this._rowClassNameGetter = this._rowClassNameGetter.bind(this);
    this.linktoggle = this.linktoggle.bind(this);
    this.Templatetoggle = this.Templatetoggle.bind(this);
    this.ipad_mode = this.ipad_mode.bind(this);

  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }





  _onSortChange(columnKey, sortDir) {

    let seisan_follow_search_list = this.props.customers;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  requestopen = (rowIndex) => {
    var data = {
      Keiyaku_No: this.props.customers[rowIndex].Keiyaku_No
    }
    this.props.get_detail(data);

    this.reqtoggle();
  }
  reqtoggle() {
    this.setState({ reqtoggle: !this.state.reqtoggle })
  }





  // 画面右下にSMSの画面を出す
  smsopen = (tel, sms_id, m_customer_id) => {

    // this.props.get_sms_share({ sms_id: sms_id });
    var data = {
      mobilenumber: tel,
      sms_id: sms_id,
      m_customer_id: m_customer_id
    }
    this.props.get_smses(data);
  }

  chatopen = (tel, sms_id, m_customer_id, room_support_m_user_id) => {
    // this.props.get_sms_share({ m_customer_id: m_customer_id });

    this.props.get_chatlist({
      m_customer_id: m_customer_id,
      room_support_m_user_id: room_support_m_user_id
    });

    this.props.get_chats(0);
  }

  taiou_kanryou(id, flag, m) {

    var mid = id;

    if (m == 0) {
      var mid = id;
    } else {
      var mid = id[0]
    }


    if (flag == 1) {
      var data = {
        id: id,
        new_message_process_flag: 1
      }
    } else if (flag == 2) {
      var data = {
        room_support_m_user_id: id,
        new_message_process_flag: 1
      }
    }


    this.props.update_customers(data);

    // 一旦消す
    // setTimeout(() => {
    //   this.props.get_message_count();
    // }, 1000)

  }

  _rowClassNameGetter(index) {
    if (this.props.customers[index]) {
      if (this.props.customers[index]["red_flag"] == 1) {
        return 'selected-row';
      }
    }
  }


  linksopen = (row) => {
    console.log(row);
    this.setState({
      link_data: {
        room_support_m_user_id: row.room_support_m_user_id,
        tatemono_name: row.Tatemono_Name,
        room_name: row.Room_Name,
        user_name: row.name
      }
    })
    this.linktoggle();
  }
  linktoggle() {
    this.setState({
      linktoggle: !this.state.linktoggle
    })
  }

  Templatetoggle() {
    if (this.state.Templatetoggle == false) {
      this.props.get_template_category_list();
    }
    this.setState({ Templatetoggle: !this.state.Templatetoggle })

  }
  ipad_mode() {
    this.setState({
      ipad_height: this.props.customers.length * 70,
    })
  }

  render() {

    // unsub = smsDB.collection("m_customers_news")
    //   .where("timestamp", ">", Math.floor(new Date().getTime() / 1000))
    //   .orderBy("timestamp", "desc")
    //   .onSnapshot((querySnapshot) => {

    //     querySnapshot.forEach((doc) => {
    //       var flag = 0;
    //       var i = 0;
    //       this.props.customers.forEach(element => {

    //         if (element.tel == doc.data().tel) {
    //           flag = 1;
    //           this.setState({ news: true })
    //           this.props.customers[i] = doc.data();
    //           this.props.customers[i]["timeform"] = true;
    //         }
    //         i = i + 1;
    //       });
    //       if (flag == 0) {
    //         this.setState({ news: true })
    //       }
    //     }, this);

    //     this.props.now_set();
    //   });

    return (
      <div id="Keiyaku">
        <div style={{ fontSize: 15, fontWeight: "bold" }}><i className="far fa-comments">
        </i> メッセージ一覧


          <span style={{ fontSize: 12 }}>
            <span style={{ marginLeft: 20, color: "#ffb6c1" }}>■</span> リクエストが発生している未読チャットは赤くなります。
          </span>

          {this.state.news ?
            <span style={{ marginLeft: 5, backgroundColor: "#ff69b4", fontSize: 6 }}>
              <i class="fas fa-exclamation-circle">新着メッセージ受信</i></span> : ""}



          <span style={{ float: "right", display: "inline-block", marginRight: 15, marginBottom: 5 }}>
            <Autocomplete
              id="combo-box-demo"
              options={this.props.shops}
              // defaultValue={{ tenpoNo: this.props.user_details.amSectionId }}
              getOptionLabel={(option) => option.tenpoName}
              style={{ width: 200 }}
              onChange={this.props.onChangeSection}
              renderInput={(params) => <TextField {...params} label="部署" />}
            />
          </span>

          <Button
            style={{ float: "right", marginRight: 20, marginTop: 20 }}
            onClick={this.Templatetoggle}
            color="primary"
            variant="outlined">
            テンプレートを作成する
          </Button>



          <span style={{ cursor: "pointer", float: "right", fontSize: 10, marginRight: 20, marginTop: 20 }}>
            <Button onClick={this.ipad_mode} size="small" variant="outlined" color="primary">
              <TabletMacIcon />
            </Button>
          </span>

          <div>
            {this.props.search_flag ? "" : "最新"}
            {this.props.customers.length}件
          </div>

        </div>
        <Table
          rowHeight={70}
          rowsCount={this.props.customers.length}
          // rowsCount={10}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          width={window.innerWidth - 150}
          height={window.innerHeight - 300 + this.state.ipad_height}
          headerHeight={30}
          rowClassNameGetter={this._rowClassNameGetter}>
          {columns.map(function (value, i) {
            return <Column
              columnKey={value.key}
              header={<SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={this.state.colSortDirs[value.key]}
                style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

              cell={({ rowIndex, ...props }) => (
                value.key === "Room_Name" ? <Room column={value} props={this.props.customers} rowIndex={rowIndex} linksopen={this.linksopen}></Room>
                  : value.key === "Tatemono_Name" ? <Tatemono column={value} props={this.props.customers} rowIndex={rowIndex} linksopen={this.linksopen}></Tatemono>
                    : value.key === "name" ? <Name column={value} props={this.props.customers} rowIndex={rowIndex} linksopen={this.linksopen}></Name>
                      : value.key === "buttons" ? <Buttons column={value} props={this.props.customers} rowIndex={rowIndex} taiou_kanryou={this.taiou_kanryou}></Buttons>
                        : value.key === "new_message_at" ? <Dates column={value} props={this.props.customers} rowIndex={rowIndex}></Dates>
                          : value.key === "new_message" ? <NewMessage column={value} props={this.props.customers} rowIndex={rowIndex}></NewMessage>
                            : value.key === "button" ? <Buttonlink column={value} props={this.props.customers} rowIndex={rowIndex} smsopen={this.smsopen} chatopen={this.chatopen}></Buttonlink>
                              : <Base column={value} props={this.props.customers} rowIndex={rowIndex} ></Base>
              )}
              width={this.state.columns.filter(a => a.key === value.key)[0].width}
              isResizable={true}
              fixed={value.frozen}
            />
          }, this)}
        </Table >




        < Modal isOpen={this.state.linktoggle} toggle={this.linktoggle} size="xl" fade={false}>
          <ModalHeader toggle={this.linktoggle}>アプリ利用者→契約の紐付け</ModalHeader>
          <LinksModal
            links_place={2}
            index={1}
            link_data={this.state.link_data}
            linktoggle={this.linktoggle} />
          <ModalFooter>
          </ModalFooter>
        </Modal >

        < Modal isOpen={this.state.Templatetoggle} toggle={this.Templatetoggle} size="keiyaku" fade={false} style={{ marginRight: 700 }}>
          <ModalHeader toggle={this.Templatetoggle}>テンプレート管理</ModalHeader>
          <div style={{ padding: 10, background: "#b0c4de" }} >
            <Paper square={false} style={{ marginTop: 10 }}>
              <TemplateKanri />
            </Paper>
          </div>
          <ModalFooter>
          </ModalFooter>
        </Modal >

      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    customers: state.MessageList.customers,
    shops: state.masterReducer.shops,
    user_details: state.masterReducer.user_details,

  }
}
function mapDispatchToProps(dispatch) {
  return {
    newmessage_customers(state) {
      dispatch(MessageList.newmessage_customers(state))
    },
    update_customers(state) {
      dispatch(MessageList.update_customers(state))
    },
    get_smses(state) {
      dispatch(Sms.get_smses(state))
    },
    get_sms_share(state) {
      dispatch(Sms.get_sms_share(state))
    },
    get_chats(state) {
      dispatch(Chat.get_chats(state))
    },
    get_chatlist(state) {
      dispatch(Keiyaku.get_chatlist(state))
    },
    get_message_count(state) {
      dispatch(MessageList.message_count(state))
    },
    get_template_category_list(state) {
      dispatch(Keiyaku.get_template_category_list(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);

