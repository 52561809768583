import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Grid from "./Grid.tsx_";
// import HeaderGrid from "./HeaderGrid";
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Row, Col, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
// import Search from "./Search.tsx_";
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
import * as Kys from './Saga/Kys';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import MailIcon from '@material-ui/icons/Mail';
import * as Line from '../Line/Saga/Line';
import * as Mail from '../Mail/Saga/Mail';
import KyIndex from '../RentMoney/KyIndex';
import SmsIcon from '@material-ui/icons/Sms';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
interface Props {
    get_kys: any,
    // kys_no: any,
    kys: any,
    change_mail_new: any,
    // customerNewDisp:number,
    // customerno:number,
    get_response_lines: any,
    // toggle:any,
    // get_rent_contract_accounting:any,
    // get_remittance_details: any,
    // get_rent_moneies: any,
    // search_detail: any,
    classes: any,
    kys_biko: any,
    // search_loading: boolean,
    // get_rent_money_bklist: any,
    // bklist: any,
    // addky: any,
    // delky: any,
}



interface State {
    reflesh: boolean,
    responseTabs: string,
    customerTabs: string,
    modalkys: boolean,

    // state types
}


const styles = ((theme: any) => ({
    root: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tab: {

        minWidth: 50, // a number of your choice
    }
}))



class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        // this.props.change_search_name("")
        this.state = {
            reflesh: false,
            responseTabs: "k1",
            modalkys: false,
            customerTabs: "kys1",
        }

        this.handleTabChange = this.handleTabChange.bind(this);
        this.handlecustomerTabChange = this.handlecustomerTabChange.bind(this);
        this.togglekys = this.togglekys.bind(this);
        this.mail = this.mail.bind(this);


    }
    componentWillMount() {
        // this.props.get_owner_remittance_check_logs()
        // this.props.get_remittance_details()

        // this.props.get_rent_money_bklist()
        // this.props.get_rent_moneies()
        // this.props.customerNewDispSet(0)
        // this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state: any) {
        console.log(state)

        // this.props.get_remittance_details(state)
    }

    togglekys(state: any) {
        console.log(state)
        this.setState({ modalkys: !this.state.modalkys })
        // this.props.get_remittance_details(state)
    }

    csv() {

        // this.props.get_rent_contract_accounting(this.props.kyousais);
    }

    mail() {
        let maildetail = {
            toMail: "",
            toMailall: "",
            fromMail: "web@global-center.co.jp",
            fromName: "グローバルセンター",
            mailAccountId: 12,
            isSended: 1,
            rentResponseId: 93180
        };



        this.props.change_mail_new(maildetail)

    }


    handleTabChange = (event: any, newValue: string) => {

        this.setState({ responseTabs: newValue });
    };
    handlecustomerTabChange = (event: any, newValue: string) => {

        this.setState({ customerTabs: newValue });
    };
    render() {

        return (


            <div >
                {this.props.kys.kys_name ? <div>
                    <Tabs
                        value={this.state.customerTabs}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handlecustomerTabChange}
                        aria-label="disabled tabs example"
                        style={{ float: "left" }}
                        className="col-lg-12"
                    >
                        <Tab className={this.props.classes.tab} label="基本情報" value="kys1" />
                        <Tab className={this.props.classes.tab} label="勤務先" value="kys2" />
                        <Tab className={this.props.classes.tab} label="連絡先" value="kys3" />
                        <Tab className={this.props.classes.tab} label="書類送付先" value="kys4" />
                        <Tab className={this.props.classes.tab} label="保証人①" value="kys5" />
                        <Tab className={this.props.classes.tab} label="保証人②" value="kys6" />
                        <Tab className={this.props.classes.tab} label="口座情報" value="kys7" />

                        <Tab className={this.props.classes.tab} label="備考" value="kys8" />
                        {/* <Tab label="見積・契約金" value="r2" /> */}
                        {/* {this.props.responsedetail.rentContractId > 0 ? <Tab label="売上" value="r3" /> : ""}
                        {this.props.responsedetail.rentContractId > 0 ? <Tab label="契約" value="r4" /> : ""}
                        {this.props.responsedetail.rentContractId > 0 ? <Tab label="進捗管理" value="r5" /> : ""} */}
                    </Tabs>
                    {/* </AppBar> */}

                    {this.state.customerTabs === "kys1" ? <Grid container   >  <Grid item xs={12} style={{ padding: 10, background: "#b0c4de" }}><Card  >
                        <CardContent style={{ float: "left" }}>

                            <div><TextField id="standard-basic" label="契約者名" value={this.props.kys.kys_name} style={{ width: 400 }} /></div>
                            <div><TextField id="standard-basic" label="契約者カナ" value={this.props.kys.kys_kana} /></div>
                            <div><TextField id="standard-basic" label="〒" value={this.props.kys.post1} /></div>
                            <div><TextField id="standard-basic" label="住所" value={this.props.kys.add1 + this.props.kys.add2} style={{ width: 400 }} /></div>
                            <div><TextField id="standard-basic" label="TEL1" value={this.props.kys.tel1} /></div>
                            <div><TextField id="standard-basic" label="TEL2" value={this.props.kys.tel2} /></div>
                            <div><TextField id="standard-basic" label="FAX" value={this.props.kys.fax} /></div>
                            <div><TextField id="standard-basic" label="EMAIL" value={this.props.kys.email} /></div>
                            <div><TextField id="standard-basic" label="生年月日" value={moment(this.props.kys.birthday).format("YYYY年MM月DD日")} /></div>

                            <div><TextField id="standard-basic" label="本籍" value={this.props.kys.honseki} /></div>


                        </CardContent>

                    </Card></Grid>  </Grid> : ""}

                    {this.state.customerTabs === "kys2" ? <Grid container >  <Grid item xs={12} style={{ padding: 10, background: "#b0c4de" }}> <Card >
                        <CardContent style={{ float: "left" }}>

                            <div><TextField id="standard-basic" label="勤務先名称" value={this.props.kys.kinmu_name} style={{ width: 400 }} /></div>
                            <div><TextField id="standard-basic" label="勤務先カナ" value={this.props.kys.kinmu_kana} /></div>
                            <div><TextField id="standard-basic" label="〒" value={this.props.kys.kinmu_post} /></div>
                            <div><TextField id="standard-basic" label="住所" value={this.props.kys.kinmu_add1 + this.props.kys.kinmu_add2} style={{ width: 400 }} /></div>
                            <div><TextField id="standard-basic" label="TEL1" value={this.props.kys.kinmu_tel1} /></div>
                            <div><TextField id="standard-basic" label="TEL2" value={this.props.kys.kinmu_tel2} /></div>
                            <div><TextField id="standard-basic" label="FAX" value={this.props.kys.kinmu_fax} /></div>
                            <div><TextField id="standard-basic" label="業種" value={this.props.kys.kinmu_gy} /></div>
                            <div><TextField id="standard-basic" label="部署・役職" value={this.props.kys.kinmu_busyo} /></div>

                            <div><TextField id="standard-basic" label="入社年月" value={this.props.kys.kinmu_nsya ? moment(this.props.kys.kinmu_nsya).format("YYYY年MM月") : ""} /></div>


                        </CardContent>

                    </Card></Grid>  </Grid> : ""}

                    {this.state.customerTabs === "kys3" ? <Grid container >  <Grid item xs={12} style={{ padding: 10, background: "#b0c4de" }}> <Card ></Card><Card style={{ marginTop: 10 }}>
                        <CardContent style={{ float: "left" }} >

                            <div><TextField id="standard-basic" label="連絡先名称" value={this.props.kys.ren_name} style={{ width: 400 }} /></div>

                            <div><TextField id="standard-basic" label="カナ" value={this.props.kys.ren_kana} /></div>
                            <div><TextField id="standard-basic" label="〒" value={this.props.kys.ren_post} /></div>
                            <div><TextField id="standard-basic" label="住所" value={this.props.kys.ren_add1 + this.props.kys.ren_add2} style={{ width: 400 }} /></div>
                            <div><TextField id="standard-basic" label="TEL1" value={this.props.kys.ren_tel1} /></div>
                            <div><TextField id="standard-basic" label="TEL2" value={this.props.kys.ren_tel2} /></div>
                            <div><TextField id="standard-basic" label="FAX" value={this.props.kys.ren_fax} /></div>
                            <div><TextField id="standard-basic" label="間柄" value={this.props.kys.ren_aida} /></div>

                        </CardContent>

                    </Card> </Grid>  </Grid> : ""}

                    {this.state.customerTabs === "kys4" ? <Grid container >  <Grid item xs={12} style={{ padding: 10, background: "#b0c4de" }}><Card >
                        <CardContent style={{ float: "left" }} >


                            <div> <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.props.kys.syorui_kbn}
                            // onChange={handleChange}
                            >

                                <MenuItem value={1}>物件住所</MenuItem>
                                <MenuItem value={2}>基本情報の住所</MenuItem>
                                <MenuItem value={3}>勤務先</MenuItem>
                                <MenuItem value={4}>連絡先</MenuItem>
                                <MenuItem value={5}>保証人①</MenuItem>
                                <MenuItem value={6}>書類送付先</MenuItem>
                            </Select></div>

                            <div> <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.props.kys.atena_out}

                            >

                                <MenuItem value={0}>様</MenuItem>
                                <MenuItem value={1}>御中</MenuItem>

                            </Select></div>


                            <div><TextField id="standard-basic" label="送付先名称" value={this.props.kys.syorui_name} style={{ width: 400 }} /></div>

                            <div><TextField id="standard-basic" label="カナ" value={this.props.kys.syorui_kana} /></div>
                            <div><TextField id="standard-basic" label="〒" value={this.props.kys.syorui_post} /></div>
                            <div><TextField id="standard-basic" label="住所" value={this.props.kys.syorui_add1 + this.props.kys.ren_add2} style={{ width: 400 }} /></div>
                            <div><TextField id="standard-basic" label="TEL1" value={this.props.kys.syorui_tel1} /></div>
                            <div><TextField id="standard-basic" label="TEL2" value={this.props.kys.syorui_tel2} /></div>
                            <div><TextField id="standard-basic" label="FAX" value={this.props.kys.syorui_fax} /></div>


                        </CardContent>

                    </Card> </Grid>  </Grid> : ""}



                    {this.state.customerTabs === "kys5" ? <Grid container style={{ background: "#b0c4de" }}>
                        <Grid item xs={6} style={{ padding: 3 }}>
                            <Card  >
                                <CardContent  >

                                    <div><TextField id="standard-basic" label="保証人名称" value={this.props.kys.hs1_name} style={{ width: 400 }} /></div>

                                    <div><TextField id="standard-basic" label="カナ" value={this.props.kys.hs1_kana} /></div>
                                    <div><TextField id="standard-basic" label="〒" value={this.props.kys.hs1_post} /></div>
                                    <div><TextField id="standard-basic" label="住所" value={this.props.kys.hs1_add1 + this.props.kys.hs1_add2} style={{ width: 400 }} /></div>
                                    <div><TextField id="standard-basic" label="TEL1" value={this.props.kys.hs1_tel1} /></div>
                                    <div><TextField id="standard-basic" label="TEL2" value={this.props.kys.hs1_tel2} /></div>
                                    <div><TextField id="standard-basic" label="FAX" value={this.props.kys.hs1_fax} /></div>
                                    <div><TextField id="standard-basic" label="間柄" value={this.props.kys.hs1_aida} /></div>
                                    <div><TextField id="standard-basic" label="生年月日" value={this.props.kys.hs1_birthday} /></div>
                                    <div><TextField id="standard-basic" label="備考" value={this.props.kys.hs1_biko} /></div>


                                </CardContent></Card>
                        </Grid>
                        <Grid item xs={6} style={{ padding: 3 }}>
                            <Card  >
                                <CardContent  >

                                    <div><TextField id="standard-basic" label="勤務先名称" value={this.props.kys.hs1_kinmu_name} /></div>
                                    <div><TextField id="standard-basic" label="カナ" value={this.props.kys.hs1_kinmu_kana} /></div>
                                    <div><TextField id="standard-basic" label="〒" value={this.props.kys.hs1_kinmu_post} /></div>
                                    <div><TextField id="standard-basic" label="住所" value={this.props.kys.hs1_kinmu_add1 + this.props.kys.hs1_kinmu_add2} style={{ width: 400 }} /></div>
                                    <div><TextField id="standard-basic" label="TEL1" value={this.props.kys.hs1_kinmu_tel1} /></div>
                                    <div><TextField id="standard-basic" label="TEL2" value={this.props.kys.hs1_kinmu_tel2} /></div>
                                    <div><TextField id="standard-basic" label="FAX" value={this.props.kys.hs1_kinmu_fax} /></div>
                                    <div><TextField id="standard-basic" label="年収" value={this.props.kys.hs1_nensyu} />万円</div>
                                </CardContent>
                            </Card></Grid></Grid> : ""}








                    {this.state.customerTabs === "kys6" ? <Grid container style={{ background: "#b0c4de" }}>
                        <Grid item xs={6} style={{ padding: 3 }}>
                            <Card  >
                                <CardContent style={{ float: "left" }} >

                                    <div><TextField id="standard-basic" label="保証人名称" value={this.props.kys.hs2_name} style={{ width: 400 }} /></div>

                                    <div><TextField id="standard-basic" label="カナ" value={this.props.kys.hs2_kana} /></div>
                                    <div><TextField id="standard-basic" label="〒" value={this.props.kys.hs2_post} /></div>
                                    <div><TextField id="standard-basic" label="住所" value={this.props.kys.hs2_add1 + this.props.kys.hs2_add2} style={{ width: 400 }} /></div>
                                    <div><TextField id="standard-basic" label="TEL1" value={this.props.kys.hs2_tel1} /></div>
                                    <div><TextField id="standard-basic" label="TEL2" value={this.props.kys.hs2_tel2} /></div>
                                    <div><TextField id="standard-basic" label="FAX" value={this.props.kys.hs2_fax} /></div>
                                    <div><TextField id="standard-basic" label="間柄" value={this.props.kys.hs2_aida} /></div>
                                    <div><TextField id="standard-basic" label="生年月日" value={this.props.kys.hs2_birthday} /></div>
                                    <div><TextField id="standard-basic" label="備考" value={this.props.kys.hs2_biko} /></div>


                                </CardContent> </Card>
                        </Grid>
                        <Grid item xs={6} style={{ padding: 3 }}>
                            <Card  >
                                <CardContent style={{ float: "left" }} >

                                    <div><TextField id="standard-basic" label="勤務先名称" value={this.props.kys.hs2_kinmu_name} /></div>
                                    <div><TextField id="standard-basic" label="カナ" value={this.props.kys.hs2_kinmu_kana} /></div>
                                    <div><TextField id="standard-basic" label="〒" value={this.props.kys.hs2_kinmu_post} /></div>
                                    <div><TextField id="standard-basic" label="住所" value={this.props.kys.hs2_kinmu_add1 + this.props.kys.hs2_kinmu_add2} style={{ width: 400 }} /></div>
                                    <div><TextField id="standard-basic" label="TEL1" value={this.props.kys.hs2_kinmu_tel1} /></div>
                                    <div><TextField id="standard-basic" label="TEL2" value={this.props.kys.hs2_kinmu_tel2} /></div>
                                    <div><TextField id="standard-basic" label="FAX" value={this.props.kys.hs2_kinmu_fax} /></div>
                                    <div><TextField id="standard-basic" label="年収" value={this.props.kys.hs2_nensyu} />万円</div>
                                </CardContent>
                            </Card> </Grid> </Grid> : ""}


                    {this.state.customerTabs === "kys7" ? <Grid container >  <Grid item xs={12} style={{ padding: 10, background: "#b0c4de" }}><Card  >
                        <CardContent style={{ float: "left" }} className="col-lg-8">

                            <div><TextField id="standard-basic" label="金融機関No" value={this.props.kys.kinyu_no} style={{ width: 80 }} /> <TextField id="standard-basic" label="金融機関名" value={this.props.kys.kinyu_name} /></div>

                            <div><TextField id="standard-basic" label="店No" value={this.props.kys.ten_no} style={{ width: 80 }} /> <TextField id="standard-basic" label="店名" value={this.props.kys.ten_name} /></div>
                            <div> <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.props.kys.kosyu_no}

                            >

                                <MenuItem value={1}>普通</MenuItem>
                                <MenuItem value={2}>当座</MenuItem>
                                <MenuItem value={3}>貯蓄</MenuItem>
                            </Select></div>
                            <div><TextField id="standard-basic" label="口座番号" value={this.props.kys.koza_no} /></div>
                            <div><TextField id="standard-basic" label="口座名義" value={this.props.kys.koza_meigi} /></div>
                            <div><TextField id="standard-basic" label="名義カナ" value={this.props.kys.koza_kana} /></div>
                            <div><TextField id="standard-basic" label="口座振替No" value={this.props.kys.fkae_no} style={{ width: 80 }} /> <TextField id="standard-basic" label="口座振替" value={this.props.kys.fkae_name} /></div>
                            <div><TextField id="standard-basic" label="振替手数料" value={parseInt(this.props.kys.fkae_tesu) ? parseInt(this.props.kys.fkae_tesu) : ""} /></div>
                            <div><TextField id="standard-basic" label="契約者番号" value={this.props.kys.kys_bango} /></div>

                        </CardContent>

                    </Card></Grid>   </Grid > : ""}
                    {this.state.customerTabs === "kys8" ? <Grid container >  <Grid item xs={12} style={{ padding: 10, background: "#b0c4de" }}> <Card  >
                        <CardContent style={{ float: "left", minHeight: 400 }} >
                            {this.props.kys_biko.map(function (value: any) {
                                return <div>{value.biko}</div>
                            })}

                        </CardContent>

                    </Card></Grid>   </Grid > : ""
                    }

                </div> : ""
                }
            </div >


        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // search_loading: state.ContractCheck.search_loading,
        // minories: state.Minori.minories,
        // bklist: state.RemittanceDetail.bklist,
        // addky: state.RemittanceDetail.addky,
        kys: state.kys.kys,

        kys_biko: state.kys.kys_biko,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_kys(state: any) {
            dispatch(Kys.get_kys(state))
        },
        change_mail_new(state: any) {
            dispatch(Mail.change_mail_new(state))
        },
        get_response_lines(state: any) {
            dispatch(Line.get_response_lines(state))
        },
        // get_rent_moneies(state: number) {
        //     dispatch(KyDetail.get_rent_moneies(state))
        // },
        // get_remittance_details(state: any) {
        //     dispatch(RentMoney.get_remittance_details(state))
        // },
        // get_owner_remittance_check_logs(state: any) {
        //     dispatch(RemittanceLogCheck.get_owner_remittance_check_logs(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));




