import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './Css/RequestDetail.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns'
import moment from 'moment'
import { Row, Col } from 'reactstrap';
import * as RequestDetailSaga from './Saga/RequestDetail';



class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}

interface Props {
    request_detail: any,
    memo_insert: any,
    memo_update: any,
    user_details: any,
    MemoModaltoggle: any
}
interface State {
    customer_memo: any
}

class CustomerList extends Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            customer_memo: this.props.request_detail.detail[0].customer_memo
        }
        this.customer_memo = this.customer_memo.bind(this);
        this.data_up = this.data_up.bind(this);
    }

    customer_memo(e: any) {
        this.setState({
            customer_memo: e.target.value,
        });
    }

    data_up() {
        // すでにデータがあればアップデート、無ければインサートする
        if (this.props.request_detail.detail[0].customer_memo_user) {
            var data = {
                Keiyaku_No: this.props.request_detail.detail[0].Keiyaku_No,
                data: {
                    customer_memo: this.state.customer_memo,
                    updated_user: this.props.user_details.id,
                    updated_at: new Date()
                }
            }
            this.props.memo_update(data);
            this.props.request_detail.detail[0].customer_memo = this.state.customer_memo;
            this.props.request_detail.detail[0].customer_memo_at = new Date();
            this.props.request_detail.detail[0].customer_memo_user = this.props.user_details.userNameAll;
            alert("更新しました");
            this.props.MemoModaltoggle();
        } else {
            var data2 = {
                Keiyaku_No: this.props.request_detail.detail[0].Keiyaku_No,
                customer_memo: this.state.customer_memo,
                created_user: this.props.user_details.id,
                created_at: new Date(),
                updated_user: this.props.user_details.id,
                updated_at: new Date()
            }
            this.props.memo_insert(data2);
            this.props.request_detail.detail[0].customer_memo = this.state.customer_memo;
            this.props.request_detail.detail[0].customer_memo_at = new Date();
            this.props.request_detail.detail[0].customer_memo_user = this.props.user_details.userNameAll;
            alert("登録しました");
            this.props.MemoModaltoggle();
        }
    }

    render() {
        return (
            <div style={{ padding: 10, background: "#b0c4de" }} >
                <div className="item-outer col-lg-12">
                    <Card className="col-lg-12" >
                        <CardContent style={{ float: "left" }} className="col-lg-12">
                            <TextField
                                autoComplete='off'
                                id="outlined-read-only-input"
                                label="メモを入力"
                                fullWidth
                                multiline
                                rows={8}
                                variant="outlined"
                                defaultValue={this.state.customer_memo}
                                onChange={this.customer_memo}
                                style={{ marginTop: "10px" }}
                            />

                            {this.props.request_detail.detail[0].customer_memo_user ?
                                <div>
                                    最終更新：{this.props.request_detail.detail[0].customer_memo_user}
                                    <span style={{marginLeft:5}}>
                                        - {moment(this.props.request_detail.detail[0].customer_memo_at).utc().format('YYYY-MM-DD')}
                                    </span>
                                </div> : ""}

                            <Button
                                style={{
                                    marginTop: 30,
                                    color: "#fff",
                                    backgroundColor: "#5cb85c",
                                    borderColor: "#4cae4c",
                                    width: "200px",
                                    float: "right"
                                }}
                                onClick={this.data_up}
                                variant="outlined" color="primary">
                                登録
                            </Button>
                        </CardContent>
                    </Card>
                </div>
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        user_details: state.masterReducer.user_details,
        request_detail: state.Adjustment.request_detail,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        memo_insert(state: any) {
            dispatch(RequestDetailSaga.memo_insert(state))
        },
        memo_update(state: any) {
            dispatch(RequestDetailSaga.memo_update(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);

