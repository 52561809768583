import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Search from "./Search";
import Grid from "./Grid";
import * as Adjustment from '../Adjustment/Saga/Adjustment';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme: any) => ({
    margin: {
        margin: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tab: {

        minWidth: 50, // a number of your choice
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


interface Props {
    get_syubetsu_list: any,
    search_status: any
}



interface State {
}



class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        props.get_syubetsu_list();

        this.state = {
        }
    }


    render() {

        if (this.props.search_status == true) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'auto';
        }

        return (
            <div className="row" style={{ width: window.innerWidth - 70, minWidth: '737px' }}>
                <div className="item-outer" style={{ width:250,padding: 0 }}>
                    <Card style={{ marginLeft: 16, height: window.innerHeight - 65, overflowY: 'auto' }}>
                        <CardContent >
                            <div style={{ display: 'flex' }}>
                                <div style={{ fontSize: 15, fontWeight: 'bold' }}>完了済みリクエスト<br></br>確認画面</div>
                            </div>
                            <Search />
                        </CardContent >
                    </Card >
                </div>
                <div className="item-outer" style={{ padding: 0 }}>
                    <Card style={{ padding: 0, height: window.innerHeight - 65 }}>
                        <CardContent >
                            {this.props.search_status == false ?
                                <Grid /> :
                                <div style={{ height: window.innerHeight - 260 }}><CircularProgress color="inherit" />
                                </div>}
                        </CardContent >
                    </Card >
                </div >
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        search_status: state.CompleteCheck.search_status,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_syubetsu_list(state: any) {
            dispatch(Adjustment.get_syubetsu_list(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));




