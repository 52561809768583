import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Adjustment from '../Adjustment/Saga/Adjustment';
import * as CustomerLink from './Saga/CustomerLink';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as Keiyaku from '../Keiyaku/Saga/Keiyaku';

import moment from 'moment'




interface Props {
    linktoggle: any,
    index: any,
    list: any,
    Tatemono: any,
    get_tatemono: any,
    get_room: any,
    Room: any,
    get_customers: any,
    customers: any,
    linking: any,
    linkings: any,
    get_list: any,
    linking_same: any,
    linking_notsame: any,
    links_place: any,
    link_data: any,
    get_family: any,
    family: any,
    family_kakumei: any,
    user_details: any,
    send_news_personal: any,
    link_delete: any,
    get_family_kakumei: any,
    kakumei_disp: any,
    kakumei_load: any,
    kakumei_insert: any,
    kakumei_load_true: any,
    kakumei_disp_false: any,
    get_keiyakulist: any,
    keiyaku_list: any
}
interface State {
    tatemono_name: any,
    tatemono_name2: any,
    room_name: any,
    Tatemono_Id: any,
    Room_Id: any,
    Kokyaku_Name: any,
    birthday: any,
    tel: any,
    Keiyaku_Date: any,
    m_customer_id: any,
    check_id: any,
    check_tel: any,
    button_disp: any
}

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);

        props.get_tatemono();

        this.state = {
            tatemono_name: "",
            tatemono_name2: "",
            room_name: "",
            Tatemono_Id: 0,
            Room_Id: 0,
            Kokyaku_Name: "",
            birthday: "",
            tel: "",
            Keiyaku_Date: "",
            m_customer_id: 0,
            check_id: 0,
            check_tel: '',
            button_disp: true
        }

        this.onChange_mcid = this.onChange_mcid.bind(this);
        this.onChangeTatemono = this.onChangeTatemono.bind(this);
        this.onChangeTatemonoName = this.onChangeTatemonoName.bind(this);
        this.onChangeRoom = this.onChangeRoom.bind(this);
        this.link = this.link.bind(this);
        this.chinkaku_search = this.chinkaku_search.bind(this);
        this.chinkaku_insert = this.chinkaku_insert.bind(this);
    }


    chinkaku_search() {
        this.props.get_keiyakulist({ tatemono_id: this.state.Tatemono_Id, room_id: this.state.Room_Id })
        this.props.get_family_kakumei({ tatemono_id: this.state.Tatemono_Id, room_id: this.state.Room_Id });
    }
    chinkaku_insert() {
        var result = window.confirm('HomeStationに契約データが新規作成されます。よろしいですか？');
        if (result) {
            var datass = this.props.family_kakumei;
            datass.users = this.props.user_details;
            this.props.kakumei_insert(datass);
            alert("契約データの登録が完了しました");
            this.props.kakumei_disp_false();
            this.props.linktoggle();
        }
    }

    // 登録する人を入居者の中からラジオボタンで選ぶ
    onChange_mcid(e: any) {
        this.props.kakumei_disp_false();
        this.setState({
            check_id: e.target.value,
            check_tel: e.target.id
        });
    }



    onChangeTatemono(event: any, values: any) {
        this.props.kakumei_disp_false();
        if (values) {
            this.props.get_room(values.Tatemono_Id);
            this.setState({
                Tatemono_Id: values.Tatemono_Id,
                tatemono_name2: values.Tatemono_Name
            });
        }
    }

    onChangeTatemonoName(e: any) {
        this.props.kakumei_disp_false();
        this.setState({
            tatemono_name: e.target.value,
        });
    }


    onChangeRoom(event: any, values: any) {
        this.props.kakumei_disp_false();
        if (values) {
            this.setState({
                Room_Id: values.Room_Id,
                room_name: values.Room_Name,
                Kokyaku_Name: "",
                birthday: "",
                tel: "",
                Keiyaku_Date: "",
                m_customer_id: 0,
            });

            var data = {
                tatemono_id: this.state.Tatemono_Id,
                room_id: values.Room_Id
            }
            this.props.get_family(data);
            setTimeout(() => {
                console.log("-----------------");
                console.log(this.props.family);
                console.log(this.props.family[0]);
                if (this.props.family[0]) {
                    this.setState({
                        Kokyaku_Name: this.props.family[0].name,
                        birthday: this.props.family[0].birthday,
                        tel: this.props.family[0].tel.replace(/-/g, ""),
                        Keiyaku_Date: this.props.family[0].Keiyaku_Date,
                        m_customer_id: this.props.family[0].id,
                    });
                } else {
                    this.setState({
                        Room_Id: values.Room_Id,
                        Kokyaku_Name: "契約情報無し",
                        birthday: "",
                        tel: "",
                        Keiyaku_Date: "",
                        m_customer_id: 0,
                    });
                }
            }, 3000)
        }
    }


    link() {



        if (this.state.check_id == 0) {
            alert("紐づける入居者にチェックを入れて下さい。")
            return;
        }

        this.setState({ button_disp: !this.state.button_disp })


        // 電話番号が一致していればマッチングランク１　していなければ２で登録する
        var matching_rank = 2;
        if (this.props.list[this.props.index].tel.replace(/-/g, "") == this.state.check_tel.replace(/-/g, "")) {
            matching_rank = 1;
        }

        var m_user_id = 0;

        if (this.props.links_place == 1) {
            m_user_id = this.props.list[this.props.index].room_support_m_user_id;
        } else {
            m_user_id = this.props.link_data.room_support_m_user_id;

        }

        this.props.linking({
            m_customer_id: this.state.check_id,
            room_support_m_user_id: m_user_id,
            customer_linked_user_id: this.props.user_details.id,
            matching_rank: matching_rank
        });


        setTimeout(() => {

            console.log(this.props.linkings);
            if (this.props.linkings == 1) {
                // var result = window.confirm('登録済みユーザーと一致しました。再度登録しますか？');
                // if (result) {
                // 上書き処理
                this.props.linking_same({
                    m_customer_id: this.state.check_id,
                    room_support_m_user_id: m_user_id
                });
                alert("登録しました");
                this.props.get_list();
                this.props.linktoggle();
                // } else {
                //     // いいえの場合、紐付けを解除
                //     this.props.link_delete({ room_support_m_user_id: m_user_id });
                // }
            } else if (this.props.linkings == 2) {
                // var result = window.confirm('すでに登録があります。上書きしますか？');
                // if (result) {

                //         // 上書き処理
                this.props.linking_notsame({
                    m_customer_id: this.state.check_id,
                    room_support_m_user_id: m_user_id
                });
                alert("登録しました");
                this.props.get_list();
                this.props.linktoggle();
                // } else {
                //     // いいえの場合、紐付けを解除
                //     this.props.link_delete({ room_support_m_user_id: m_user_id });
                // }
            } else {


                // 紐付けが完了したら、アプリにお知らせを飛ばす。
                // title タイトル
                // contents 内容
                // is_push 1: 0 プッシュ通知 有無
                // targets Tatemono_Id,Room_Id(配列）

                var rooms: any[] = [];
                rooms.push({
                    Tatemono_Id: this.state.Tatemono_Id,
                    Room_Id: this.state.Room_Id
                });

                var data = {
                    title: "承認完了のお知らせ",
                    contents: "グローバルセンター入居者様専用アプリ「Room Support」にご登録いただき誠にありがとうございます。\nお客様の承認が完了致しました。\nこれより建物情報の確認やお困りごとのチャット機能がご利用いただけます。\n入居中のお問い合わせなどにぜひご活用ください。",
                    targets: rooms,
                    is_push: 1
                }
                this.props.send_news_personal(data);


                alert("登録しました");
                this.props.get_list();
                this.props.linktoggle();
            }
            this.setState({ button_disp: !this.state.button_disp })

        }, 2000)

    }

    render() {

        if (this.props.links_place == 1) {
            return (
                <div style={{ padding: 10, background: "#b0c4de" }} >
                    <div className="item-outer col-lg-12">

                        <Card className="col-lg-12" >
                            <CardContent style={{ float: "left" }} className="col-lg-12">
                                <div>入力された内容</div>
                                <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                    <tbody>
                                        <tr style={{ border: "0px" }}>
                                            <th colSpan={1} >建物名</th>
                                            <td colSpan={4} >
                                                {this.props.list[this.props.index].tatemono_name}
                                            </td>
                                            <th colSpan={1} >部屋名</th>
                                            <td colSpan={2} >
                                                {this.props.list[this.props.index].room_name}
                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            <th colSpan={1} >氏名</th>
                                            <td colSpan={4} >
                                                {this.props.list[this.props.index].user_name}
                                            </td>
                                            <th colSpan={1} >電話番号</th>
                                            <td colSpan={4} >
                                                {this.props.list[this.props.index].tel}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </CardContent>
                        </Card>


                        <div style={{ fontSize: 25, textAlign: "center" }}>
                            <i className="fas fa-arrow-down"></i>
                        </div>

                        <Card className="col-lg-12" style={{ marginTop: 10 }}>
                            <CardContent style={{ float: "left" }} className="col-lg-12">
                                <div>紐付ける契約</div>
                                <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                    <tbody>
                                        <tr style={{ border: "0px" }}>
                                            <th colSpan={1} >建物名</th>
                                            <td colSpan={4} >
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={this.props.Tatemono}
                                                    getOptionLabel={(option: any) => option.Tatemono_Name + " (" + option.Tatemono_Kana + ")"}
                                                    style={{ width: 350, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangeTatemono}
                                                    renderInput={(params) => <TextField {...params} label="建物名" onChange={this.onChangeTatemonoName} value={this.state.tatemono_name} />}
                                                />                                        </td>
                                            <th colSpan={1} >部屋名</th>
                                            <td colSpan={2} >
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={this.props.Room}
                                                    getOptionLabel={(option: any) => option.Room_Name}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangeRoom}
                                                    renderInput={(params) => <TextField {...params} label="部屋名" />}
                                                />
                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            <th colSpan={1} >氏名</th>
                                            <td colSpan={4} >
                                                {this.state.Kokyaku_Name}
                                            </td>
                                            <th colSpan={1} >生年月日</th>
                                            <td colSpan={2} >
                                                {this.state.birthday ? moment(this.state.birthday).tz("Asia/Tokyo").format('YYYY-MM-DD') : ""}
                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            <th colSpan={1} >電話番号</th>
                                            <td colSpan={4} >
                                                {this.state.tel}
                                            </td>
                                            <th colSpan={1} >契約日</th>
                                            <td colSpan={2} >
                                                {this.state.Keiyaku_Date ? moment(this.state.Keiyaku_Date).tz("Asia/Tokyo").format('YYYY-MM-DD') : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {this.state.m_customer_id == 0 ? "" :
                                    <div>
                                        <div>アプリを登録する人物にチェックを入れる</div>
                                        <RadioGroup onChange={this.onChange_mcid}>
                                            <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                                <tbody>
                                                    {this.props.family.map((d: any) => {

                                                        var name1 = "";
                                                        var name2 = "";
                                                        if (this.state.Kokyaku_Name) {
                                                            name1 = this.state.Kokyaku_Name.replace("　", "").replace(" ", "")
                                                        }
                                                        if (d.name) {
                                                            name2 = d.name.replace("　", "").replace(" ", "")
                                                        }

                                                        return <tr style={{ border: "0px" }}>
                                                            {/* <th colSpan={1} >　</th> */}
                                                            <td colSpan={4} >
                                                                <FormControlLabel
                                                                    value={String(d.id)}
                                                                    label={d.name}
                                                                    control={<Radio color="primary" id={d.tel} />}
                                                                />

                                                            </td>
                                                            <th colSpan={1} >アプリ登録状況</th>
                                                            <td colSpan={2} >
                                                                {d.room_support_m_user_id == "" || d.room_support_m_user_id == "0" ? "未" : "登録済み"}
                                                            </td>
                                                            <th colSpan={1} >電話番号</th>
                                                            <td colSpan={2} >
                                                                {d.tel}
                                                            </td>
                                                        </tr>;

                                                        // if (d.family_no == 0 && d.type !== 2) {
                                                        //     return <tr style={{ border: "0px" }}>
                                                        //         <th colSpan={1} >契約者</th>
                                                        //         <td colSpan={4} >
                                                        //             <FormControlLabel
                                                        //                 value={String(d.id)}
                                                        //                 label={d.name}
                                                        //                 control={<Radio color="primary" id={d.tel} />}
                                                        //             />

                                                        //         </td>
                                                        //         <th colSpan={1} >アプリ登録状況</th>
                                                        //         <td colSpan={2} >
                                                        //             {d.room_support_m_user_id == "" || d.room_support_m_user_id == "0" ? "未" : "登録済み"}
                                                        //         </td>
                                                        //         <th colSpan={1} >電話番号</th>
                                                        //         <td colSpan={2} >
                                                        //             {d.tel}
                                                        //         </td>
                                                        //     </tr>;
                                                        // } else if (d.family_no > 0) {
                                                        //     return <tr style={{ border: "0px" }}>
                                                        //         <th colSpan={1} >同居人</th>
                                                        //         <td colSpan={4} >
                                                        //             <FormControlLabel
                                                        //                 value={String(d.id)}
                                                        //                 label={d.name}
                                                        //                 control={<Radio color="primary" id={d.tel} />}
                                                        //             />
                                                        //         </td>
                                                        //         <th colSpan={1} >アプリ登録状況</th>
                                                        //         <td colSpan={2} >
                                                        //             {d.room_support_m_user_id == "" || d.room_support_m_user_id == "0" ? "未" : "登録済み"}
                                                        //         </td>
                                                        //         <th colSpan={1} >電話番号</th>
                                                        //         <td colSpan={2} >
                                                        //             {d.tel}
                                                        //         </td>
                                                        //     </tr>;
                                                        // } else if (d.type == 1) {
                                                        //     return <tr style={{ border: "0px" }}>
                                                        //         <th colSpan={1} >契約者</th>
                                                        //         <td colSpan={4} >
                                                        //             <FormControlLabel
                                                        //                 value={String(d.id)}
                                                        //                 label={d.name}
                                                        //                 control={<Radio color="primary" id={d.tel} />}
                                                        //             />

                                                        //         </td>
                                                        //         <th colSpan={1} >アプリ登録状況</th>
                                                        //         <td colSpan={2} >
                                                        //             {d.room_support_m_user_id == "" || d.room_support_m_user_id == "0" ? "未" : "登録済み"}
                                                        //         </td>
                                                        //         <th colSpan={1} >電話番号</th>
                                                        //         <td colSpan={2} >
                                                        //             {d.tel}
                                                        //         </td>
                                                        //     </tr>;
                                                        // } else if (d.type == 2) {
                                                        //     return <tr style={{ border: "0px" }}>
                                                        //         <th colSpan={1} >入居者</th>
                                                        //         <td colSpan={4} >
                                                        //             <FormControlLabel
                                                        //                 value={String(d.id)}
                                                        //                 label={d.name}
                                                        //                 control={<Radio color="primary" id={d.tel} />}
                                                        //             />
                                                        //         </td>
                                                        //         <th colSpan={1} >アプリ登録状況</th>
                                                        //         <td colSpan={2} >
                                                        //             {d.room_support_m_user_id == "" || d.room_support_m_user_id == "0" ? "未" : "登録済み"}
                                                        //         </td>
                                                        //         <th colSpan={1} >電話番号</th>
                                                        //         <td colSpan={2} >
                                                        //             {d.tel}
                                                        //         </td>
                                                        //     </tr>;
                                                        // }
                                                    })}
                                                </tbody>
                                            </table>
                                        </RadioGroup>
                                    </div>}
                            </CardContent>
                            {this.props.list[this.props.index].tel.replace(/-/g, "") == this.state.check_tel.replace(/-/g, "") ? "電話番号が一致しているため、マッチングランク１で登録します。" : "電話番号が一致していない場合、マッチングランク２で登録します。"}
                        </Card>






                        {this.state.m_customer_id == 0 ? "" :
                            <Card className="col-lg-12" style={{ marginTop: 20 }}>
                                <CardContent style={{ float: "left" }} className="col-lg-12">
                                    {this.props.list[this.props.index].tel !== null ?
                                        this.state.button_disp ?
                                            <button onClick={this.link} style={{ fontSize: "15px" }} className="btn btn btn-info item-outer col-lg-12">この契約に紐づける</button>
                                            : "" : "「入力された内容」の電話番号データがないため結び付けできません"}
                                </CardContent>
                            </Card>
                        }




                        {/* 賃貸革命の情報 */}
                        {this.props.kakumei_disp ?
                            <Card className="col-lg-12" style={{ marginTop: 10 }}>
                                <CardContent style={{ float: "left" }} className="col-lg-12">
                                    <div>ＨＳにある契約データ</div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th style={{ width: "80px" }}>契約日</th>
                                                <th style={{ width: "100px" }}>契約者</th>
                                            </tr>


                                            {this.props.keiyaku_list.map((row: any, index: any) => (
                                                <tr>
                                                    <td style={{ backgroundColor: "white" }}>{moment(row.Keiyaku_Date).format("YYYY-MM-DD")}</td>
                                                    <td style={{ backgroundColor: "white" }}>{row.Kei_Name}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </CardContent>
                            </Card>
                            : ""
                        }
                        {this.props.kakumei_disp ?
                            this.props.family_kakumei.error ?
                                <Card className="col-lg-12" style={{ marginTop: 10 }}>
                                    <CardContent style={{ float: "left" }} className="col-lg-12">賃貸革命を検索できませんでした                                </CardContent>
                                </Card>
                                :
                                <Card className="col-lg-12" style={{ marginTop: 10 }}>
                                    <CardContent style={{ float: "left" }} className="col-lg-12">
                                        <div>賃貸革命のデータ</div>
                                        <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                            <tbody>
                                                <tr style={{ border: "0px" }}>
                                                    <th colSpan={1} >契約NO</th>
                                                    <td colSpan={4} >
                                                        {this.props.family_kakumei.kys.ky_no}
                                                        <button style={{ marginLeft: 5 }} onClick={this.chinkaku_insert} className="btn btn-sm btn-success">契約をHSに新規登録</button>
                                                        ※上記リストにすでに契約がないか確認してください
                                                    </td>
                                                </tr>
                                                <tr style={{ border: "0px" }}>
                                                    <th colSpan={1} >建物名</th>
                                                    <td colSpan={4} >
                                                        {this.state.tatemono_name2}                                            </td>
                                                    <th colSpan={1} >部屋名</th>
                                                    <td colSpan={2} >
                                                        {this.state.room_name}
                                                    </td>
                                                </tr>
                                                <tr style={{ border: "0px" }}>
                                                    <th colSpan={1} >氏名</th>
                                                    <td colSpan={4} >
                                                        {this.props.family_kakumei.kys.kys_name}
                                                    </td>
                                                    <th colSpan={1} >生年月日</th>
                                                    <td colSpan={2} >
                                                        {this.props.family_kakumei.kys.birthday ? moment(this.props.family_kakumei.kys.birthday).tz("Asia/Tokyo").format('YYYY-MM-DD') : ""}
                                                    </td>
                                                </tr>
                                                <tr style={{ border: "0px" }}>
                                                    <th colSpan={1} >電話番号</th>
                                                    <td colSpan={4} >
                                                        {this.props.family_kakumei.kys.tel}
                                                    </td>
                                                    <th colSpan={1} >契約日</th>
                                                    <td colSpan={2} >
                                                        {this.props.family_kakumei.kys.ky_date ? moment(this.props.family_kakumei.kys.ky_date).tz("Asia/Tokyo").format('YYYY-MM-DD') : ""}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div>
                                            <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                                <tbody>
                                                    {this.props.family_kakumei.kazoku.map((d: any) => {
                                                        return <tr style={{ border: "0px" }}>
                                                            <th colSpan={1} >{d.aida}</th>
                                                            <td colSpan={4} >
                                                                {d.name}
                                                            </td>
                                                            <th colSpan={1} >電話番号</th>
                                                            <td colSpan={2} >
                                                                {d.tel}
                                                            </td>
                                                        </tr>;
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardContent>
                                </Card> : ""}



                        <Card className="col-lg-12" style={{ marginTop: 20 }}>
                            <CardContent style={{ float: "left" }} className="col-lg-12">
                                <div>
                                    ・ランク２（電話番号不一致）の場合は、個人情報等がアプリに表示されなくなります（問い合わせや申請は可能）
                                </div>
                                <div>
                                    ・すでにアプリを登録済みの場合でも、上書きして登録することができます。
                                </div>
                                <div>
                                    ・同居人申請を行っていない場合は、登録することはできません。
                                </div>
                                <div>
                                    ・法人契約の場合に、法人（契約者）と結び付けをしてもアプリは利用できます。電話番号が入居者の場合は入居者に紐付けするのが望ましいです。
                                </div>
                                <div>
                                    ・アプリを登録済みでないのに「上書きしますか」とアラートが出る場合は、エラーです。そのまま結び付けをしても支障ありません。
                                </div>
                                <div>
                                    ・紐付けを行わずにリストから削除した場合、「契約の照会が行えなかった」旨がアプリに表示され、一切利用不可になります。
                                </div>
                            </CardContent>
                        </Card>

                    </div>
                </div >
            );
        } else {
            return (
                <div style={{ padding: 10, background: "#b0c4de" }} >
                    <div className="item-outer col-lg-12">

                        <Card className="col-lg-12" >
                            <CardContent style={{ float: "left" }} className="col-lg-12">
                                <div>入力された内容</div>
                                <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                    <tbody>
                                        <tr style={{ border: "0px" }}>
                                            <th colSpan={1} >建物名</th>
                                            <td colSpan={4} >
                                                {this.props.link_data.tatemono_name}
                                            </td>
                                            <th colSpan={1} >部屋名</th>
                                            <td colSpan={2} >
                                                {this.props.link_data.room_name}
                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            <th colSpan={1} >氏名</th>
                                            <td colSpan={4} >
                                                {this.props.link_data.user_name}
                                            </td>
                                            <th colSpan={1} >電話番号</th>
                                            <td colSpan={4} >
                                                {this.props.list[this.props.index].tel}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </CardContent>
                        </Card>


                        <div style={{ fontSize: 25, textAlign: "center" }}>
                            <i className="fas fa-arrow-down"></i>
                        </div>

                        <Card className="col-lg-12" style={{ marginTop: 10 }}>
                            <CardContent style={{ float: "left" }} className="col-lg-12">
                                <div>紐付ける契約</div>
                                <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                    <tbody>
                                        <tr style={{ border: "0px" }}>
                                            <th colSpan={1} >建物名</th>
                                            <td colSpan={4} >
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={this.props.Tatemono}
                                                    getOptionLabel={(option: any) => option.Tatemono_Name + " (" + option.Tatemono_Kana + ")"}
                                                    style={{ width: 350, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangeTatemono}
                                                    renderInput={(params) => <TextField {...params} label="建物名" onChange={this.onChangeTatemonoName} value={this.state.tatemono_name} />}
                                                />                                        </td>
                                            <th colSpan={1} >部屋名</th>
                                            <td colSpan={2} >
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={this.props.Room}
                                                    getOptionLabel={(option: any) => option.Room_Name}
                                                    style={{ width: 200, float: "left", marginTop: 12 }}
                                                    onChange={this.onChangeRoom}
                                                    renderInput={(params) => <TextField {...params} label="部屋名" />}
                                                />                                        </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            <th colSpan={1} >氏名</th>
                                            <td colSpan={4} >
                                                {this.state.Kokyaku_Name}
                                            </td>
                                            <th colSpan={1} >生年月日</th>
                                            <td colSpan={2} >
                                                {this.state.birthday ? moment(this.state.birthday).tz("Asia/Tokyo").format('YYYY-MM-DD') : ""}
                                            </td>
                                        </tr>
                                        <tr style={{ border: "0px" }}>
                                            <th colSpan={1} >電話番号</th>
                                            <td colSpan={4} >
                                                {this.state.tel}
                                            </td>
                                            <th colSpan={1} >契約日</th>
                                            <td colSpan={2} >
                                                {this.state.Keiyaku_Date ? moment(this.state.Keiyaku_Date).tz("Asia/Tokyo").format('YYYY-MM-DD') : ""}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {this.state.m_customer_id == 0 ? "" :
                                    <div>
                                        <div>アプリを登録する人物にチェックを入れる</div>
                                        <RadioGroup onChange={this.onChange_mcid}>
                                            <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                                <tbody>
                                                    {this.props.family.map((d: any) => {
                                                        var name1 = this.state.Kokyaku_Name.replace("　", "").replace(" ", "")
                                                        var name2 = d.name.replace("　", "").replace(" ", "")
                                                        if (d.type == 1 && name1 == name2) {
                                                            return <tr style={{ border: "0px" }}>
                                                                <th colSpan={1} >契約者</th>
                                                                <td colSpan={4} >
                                                                    <FormControlLabel
                                                                        value={String(d.id)}
                                                                        label={d.name}
                                                                        control={<Radio color="primary" id={d.tel} />}
                                                                    />

                                                                </td>
                                                                <th colSpan={1} >アプリ登録状況</th>
                                                                <td colSpan={2} >
                                                                    {d.room_support_m_user_id == "" ? "未" : "登録済み"}
                                                                </td>
                                                                <th colSpan={1} >電話番号</th>
                                                                <td colSpan={2} >
                                                                    {d.tel}
                                                                </td>
                                                            </tr>;
                                                        } else if (d.type == 2 && name1 !== name2) {
                                                            return <tr style={{ border: "0px" }}>
                                                                <th colSpan={1} >入居者</th>
                                                                <td colSpan={4} >
                                                                    <FormControlLabel
                                                                        value={String(d.id)}
                                                                        label={d.name}
                                                                        control={<Radio color="primary" id={d.tel} />}
                                                                    />
                                                                </td>
                                                                <th colSpan={1} >アプリ登録状況</th>
                                                                <td colSpan={2} >
                                                                    {d.room_support_m_user_id == "" ? "未" : "登録済み"}
                                                                </td>
                                                                <th colSpan={1} >電話番号</th>
                                                                <td colSpan={2} >
                                                                    {d.tel}
                                                                </td>
                                                            </tr>;
                                                        }
                                                    })}
                                                </tbody>
                                            </table>
                                        </RadioGroup>
                                    </div>}

                            </CardContent>
                            {this.props.list[this.props.index].tel.replace(/-/g, "") == this.state.check_tel.replace(/-/g, "") ? "電話番号が一致しているため、マッチングランク１で登録します。" : "電話番号が一致していない場合、マッチングランク２で登録します。"}

                        </Card>




                        {this.state.m_customer_id == 0 ? "" :
                            <Card className="col-lg-12" style={{ marginTop: 20 }}>
                                <CardContent style={{ float: "left" }} className="col-lg-12">
                                    {this.state.button_disp ?
                                        <button onClick={this.link} style={{ fontSize: "15px" }} className="btn btn btn-info item-outer col-lg-12">この契約に紐づける</button>
                                        : ""}
                                </CardContent>
                            </Card>
                        }

                        <div>
                            ・ランク２（電話番号不一致）の場合は、個人情報等がアプリに表示されなくなります（問い合わせや申請は可能）
                        </div>
                        <div>
                            ・すでにアプリを登録済みの場合でも、上書きして登録することができます。
                        </div>
                        <div>
                            ・同居人申請を行っていない場合は、登録することはできません。
                        </div>
                        <div>
                            ・法人契約の場合に、法人（契約者）と結び付けをしてもアプリは利用できます。
                        </div>
                        <div>
                            ・アプリを登録済みでないのに「上書きしますか」とアラートが出る場合は、エラーです。そのまま結び付けをしても支障ありません。
                        </div>
                        <div>
                            ・紐付けを行わずにリストから削除した場合、「契約の照会が行えなかった」旨がアプリに表示され、一切利用不可になります。
                        </div>
                    </div>
                </div >
            );
        }
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        list: state.CustomerLink.list,
        Tatemono: state.Adjustment.Tatemono,
        Room: state.Adjustment.Room,
        customers: state.Adjustment.customers,
        linkings: state.CustomerLink.linking,
        family: state.CustomerLink.family,
        family_kakumei: state.CustomerLink.family_kakumei,
        user_details: state.masterReducer.user_details,
        kakumei_disp: state.CustomerLink.kakumei_disp,
        kakumei_load: state.CustomerLink.kakumei_load,
        keiyaku_list: state.CustomerLink.keiyaku_list,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_tatemono(state: any) {
            dispatch(Keiyaku.get_tatemono(state))
        },
        get_room(state: any) {
            dispatch(Adjustment.get_room(state))
        },
        get_customers(state: any) {
            dispatch(Adjustment.get_customers(state))
        },
        linking(state: any) {
            dispatch(CustomerLink.linking(state))
        },
        get_list(state: any) {
            dispatch(CustomerLink.get_list(state))
        },
        get_family(state: any) {
            dispatch(CustomerLink.get_family(state))
        },
        linking_same(state: any) {
            dispatch(CustomerLink.linking_same(state))
        },
        linking_notsame(state: any) {
            dispatch(CustomerLink.linking_notsame(state))
        },
        send_news_personal(state: any) {
            dispatch(Keiyaku.send_news_personal(state))
        },
        link_delete(state: any) {
            dispatch(CustomerLink.link_delete(state))
        },
        get_family_kakumei(state: any) {
            dispatch(CustomerLink.get_family_kakumei(state))
        },
        kakumei_insert(state: any) {
            dispatch(CustomerLink.kakumei_insert(state))
        },
        kakumei_load_true(state: any) {
            dispatch(CustomerLink.kakumei_load_true(state))
        },
        kakumei_disp_false(state: any) {
            dispatch(CustomerLink.kakumei_disp_false(state))
        },
        get_keiyakulist(state: any) {
            dispatch(CustomerLink.get_keiyakulist(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);

