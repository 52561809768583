const authentication = require('../../../react-azure-adb2c2').default;







export const list = (mailId: number) => {

    let url = process.env.REACT_APP_API_BASE_URL + `/api/MailFiles/` + mailId;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



export const get_file = (Id: number) => {
    const token = authentication.getAccessToken();

    let url = process.env.REACT_APP_API_BASE_URL + `/api/c/MailFileDownloadApi/` + Id;
    return fetch(url, { method: 'GET', headers: new Headers([["Authorization", 'Bearer ' + token]]) }).then(response => response.blob())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};