import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from "date-fns/locale/ja";
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import * as RequestDetailSaga from './Saga/RequestDetail';
import Dropzone from 'react-dropzone';
import * as Adjustment from '../Adjustment/Saga/Adjustment';
import moment from 'moment'
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { format } from 'date-fns'

// import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
const { DateTimePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');


const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);


class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}


interface Props {
    users_select: any,
    reqtoggle: any,
    Outai_No: any,
    OutaiShosai_No: any,
    syousai_insert: any,
    file_upload: any,
    user_details: any,
    request_detail: any,
    new_syousai: any
}
interface State {
    OutaiDate: any,
    OutaiDate_H: any,
    OutaiDate_M: any,
    OutaiNaiyo: any,
    OutaiHoho_Name: any,
    OutaiHoho_Biko: any,
    OutaiTanto_Id: any,
    OutaiBiko: any,
    filename: any,
    files: any,
    yotei: boolean,
    houmon1: boolean,
    irai: any,
    button_disp: any
}



var outai_houhou = [
    { value: "電話" },
    { value: "FAX" },
    { value: "訪問" },
    { value: "来店" },
    { value: "通知文" },
    { value: "メール" },
    { value: "Teams" },
    { value: "傷汚れチェックシート受付" },
    { value: "その他" },
    { value: "駆け付け" },
    { value: "チャット" },
    { value: "LINE" },
    { value: "アプリ" },
    { value: "メモ" },
    { value: "Smarep"},
]


class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            OutaiDate: new Date(),
            OutaiDate_H: moment(new Date()).hours(),
            OutaiDate_M: moment(new Date()).minutes(),
            OutaiNaiyo: "",
            OutaiHoho_Name: "",
            OutaiHoho_Biko: "",
            OutaiTanto_Id: props.user_details.id,
            OutaiBiko: "",
            filename: "",
            files: [],
            yotei: false,
            houmon1: false,
            irai: new Date(),
            button_disp: true,
        }
        this.onChangeOutaiTanto_Id = this.onChangeOutaiTanto_Id.bind(this);
        this.onChangeOutaiHoho_Name = this.onChangeOutaiHoho_Name.bind(this);
        this.onChangeOutaiHoho_Biko = this.onChangeOutaiHoho_Biko.bind(this);
        this.onChangeOutaiNaiyo = this.onChangeOutaiNaiyo.bind(this);
        this.onChangeOutaiBiko = this.onChangeOutaiBiko.bind(this);
        this.save = this.save.bind(this);
        this.onChangeOutaiDate = this.onChangeOutaiDate.bind(this);
        this.onChangeOutaiDate_H = this.onChangeOutaiDate_H.bind(this);
        this.onChangeOutaiDate_M = this.onChangeOutaiDate_M.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.yoteiCheck = this.yoteiCheck.bind(this);
        this.iraiChange = this.iraiChange.bind(this);
        this.ima = this.ima.bind(this);

    }


    ima() {
        this.setState({
            OutaiDate: new Date(),
            OutaiDate_H: moment().tz("Asia/Tokyo").format('HH'),
            OutaiDate_M: moment().tz("Asia/Tokyo").format('mm'),
        });
    }


    onChangeOutaiDate = (date: any) => {
        this.setState({
            OutaiDate: new Date(date),
        });
    };

    onChangeOutaiDate_H(e: any) {

        var time = e.target.value;
        if (e.target.value < 0) {
            time = 24;
        } else if (e.target.value > 23) {
            time = 0;
        }

        this.setState({
            OutaiDate_H: time,
        });
    }

    onChangeOutaiDate_M(e: any) {

        var time = e.target.value;
        if (e.target.value < 0) {
            time = 59;
        } else if (e.target.value > 59) {
            time = 0;
        }

        this.setState({
            OutaiDate_M: time,
        });
    }


    onChangeOutaiTanto_Id(event: any, values: any) {
        this.setState({
            OutaiTanto_Id: values.value,
        });
    }

    onChangeOutaiHoho_Name(event: any, values: any) {
        this.setState({
            OutaiHoho_Name: values.value,
        });
    }

    onChangeOutaiHoho_Biko(e: any) {
        this.setState({
            OutaiHoho_Biko: e.target.value,
        });
    }

    onChangeOutaiNaiyo(e: any) {
        this.setState({
            OutaiNaiyo: e.target.value,
        });
    }

    onChangeOutaiBiko(e: any) {
        this.setState({
            OutaiBiko: e.target.value,
        });
    }

    save() {

        var schedule_completed = 0;

        if (this.state.yotei == true) {
            schedule_completed = 1;
        }

        var houmon_irai = 0;

        if (this.state.houmon1 == true) {
            houmon_irai = 1;
        }


        var data =
        {
            Outai_No: this.props.Outai_No,
            OutaiDate: moment(this.state.OutaiDate).tz("Asia/Tokyo").format('YYYY-MM-DD'),
            OutaiDate_H: this.state.OutaiDate_H,
            OutaiDate_M: this.state.OutaiDate_M,
            Naiyo: this.state.OutaiNaiyo,
            OutaiHoho_Name: this.state.OutaiHoho_Name,
            OutaiHoho_Biko: this.state.OutaiHoho_Biko,
            Tanto_Id: this.state.OutaiTanto_Id,
            Biko: this.state.OutaiBiko,
            schedule_completed: schedule_completed,
            houmon_irai: houmon_irai,
            houmon_datetime: this.state.irai,
        }

        this.props.syousai_insert(data);


        console.log("-----------------------");
        console.log(this.state.files);

        this.setState({
            button_disp: false,
        });

        setTimeout(() => {
            console.log("this.props.new_syousai");
            console.log(this.props.new_syousai);


            var file_no = 1;
            this.state.files.forEach((e: any) => {
                setTimeout(() => {
                    console.log(e);

                    var updata = {
                        files: e,
                        file_no: file_no,
                        OutaiShosai_No: this.props.new_syousai,
                        Outai_No: this.props.Outai_No,
                        uploaded_user_id: this.props.user_details.id,
                    }
                    console.log(updata);
                    // 添付ファイルをアップロード
                    this.props.file_upload(updata);
                    file_no = file_no + 1;
                }, 1000)
            });

            this.setState({
                button_disp: true,
            });
            alert("登録しました");
            this.props.reqtoggle();
        }, 1100 * this.state.files.length)



    }

    onDrop = (files: any) => {

        console.log(files);

        files.forEach((e: any) => {

            var statefiles = this.state.files;
            statefiles.push(e);

            var filenames = "";
            if (this.state.filename !== "") {
                filenames = this.state.filename + " , " + e.name;
            } else {
                filenames = e.name;
            }

            this.setState({
                filename: filenames,
                files: statefiles
            });

        });

    };


    yoteiCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };
    houmonCheck1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };

    iraiChange = (date: any) => {
        this.setState({
            irai: new Date(date),
        });
    };

    render() {

        return (
            <div className="newoutai" style={{ padding: 10, background: "#b0c4de" }} >
                <div className="item-outer col-lg-12">
                    <Card style={{ marginTop: 10 }} className="col-lg-12">
                        <CardContent style={{ float: "left" }} className="col-lg-12">

                            <table className="col-lg-12" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                                <tbody>
                                    <tr style={{ border: "0px" }}>
                                        <td colSpan={1} style={{ border: "0px" }}>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={this.props.users_select}
                                                defaultValue={{ value: this.props.user_details.id, label: this.props.user_details.userNameAll }}
                                                getOptionLabel={(option: any) => option.label}
                                                style={{ width: 200, float: "left", marginTop: 12 }}
                                                onChange={this.onChangeOutaiTanto_Id}
                                                renderInput={(params) => <TextField
                                                    autoComplete='off' {...params} label="応対者" />}
                                            />
                                        </td>
                                        <td colSpan={1} style={{ border: "0px" }}>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={outai_houhou}
                                                getOptionLabel={(option: any) => option.value}
                                                style={{ width: 200, float: "left", marginTop: 12 }}
                                                onChange={this.onChangeOutaiHoho_Name}
                                                renderInput={(params) => <TextField
                                                    autoComplete='off' {...params} label="応対方法" />}
                                            />
                                        </td>

                                        <td colSpan={1} style={{ border: "0px" }}>
                                            <TextField
                                                autoComplete='off'
                                                id="outlined-read-only-input"
                                                label="応対方法備考"
                                                fullWidth
                                                multiline
                                                rows={1}
                                                variant="outlined"
                                                onChange={this.onChangeOutaiHoho_Biko}
                                                style={{ marginTop: "10px" }}
                                            />
                                        </td>
                                    </tr>
                                    <tr style={{ border: "0px" }}>
                                        <td colSpan={2} style={{ border: "0px" }}>

                                            <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                                                <KeyboardDatePicker

                                                    variant="inline"
                                                    autoOk={true}
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="対応日"
                                                    format="yyyy/MM/dd"
                                                    value={this.state.OutaiDate}
                                                    onChange={this.onChangeOutaiDate}
                                                    style={{ width: 130 }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>

                                            <TextField
                                                autoComplete='off'
                                                id="standard-number"
                                                label="時"
                                                type="number"
                                                value={this.state.OutaiDate_H}
                                                onChange={this.onChangeOutaiDate_H}
                                                style={{ width: 60, marginTop: 16 }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />

                                            <TextField
                                                autoComplete='off'
                                                id="standard-number"
                                                label="分"
                                                type="number"
                                                value={this.state.OutaiDate_M}
                                                onChange={this.onChangeOutaiDate_M}
                                                style={{ width: 60, marginTop: 16 }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />

                                            <button onClick={this.ima} style={{ fontSize: 10, marginTop: 40, backgroundColor: "#5cb85c", color: "white" }}>今</button>

                                            <FormControlLabel
                                                style={{ marginLeft: 50, marginTop: 20 }}
                                                control={<GreenCheckbox name="yotei" onChange={this.yoteiCheck} />}
                                                label="予定"
                                            />

                                            <FormControlLabel
                                                style={{ marginLeft: 50, marginTop: 20 }}
                                                control={<GreenCheckbox name="houmon1" onChange={this.houmonCheck1} />}
                                                label="訪問依頼として登録"
                                            />

                                            {this.state.houmon1 ?
                                                <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                                                    <DateTimePicker
                                                        variant="inline"
                                                        label="訪問日時"
                                                        format="yyyy/MM/dd HH:mm"
                                                        value={this.state.irai}
                                                        onChange={this.iraiChange}
                                                        style={{ marginTop: 16 }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                                : ""
                                            }

                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <TextField
                                autoComplete='off'
                                id="outlined-read-only-input"
                                label="応対内容"
                                fullWidth
                                multiline
                                rows={6}
                                variant="outlined"
                                onChange={this.onChangeOutaiNaiyo}
                                style={{ marginTop: "10px" }}
                            />

                            <TextField
                                autoComplete='off'
                                id="outlined-read-only-input"
                                label="応対内容備考"
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                onChange={this.onChangeOutaiBiko}
                                style={{ marginTop: "10px" }}
                            />

                            <div style={{ width: "250px", height: 40, margin: 5 }}>
                                <Dropzone
                                    onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: 'dropzone' })} style={{ background: "#f5f5f5", height: 60, border: "solid" }}>
                                            <input {...getInputProps()} />
                                            <div>
                                                <p>クリックまたはドラッグでファイルをアップロード</p>
                                                <ul style={{ padding: 0, marginTop: 3 }}  >
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>

                            </div>
                            <div style={{ marginTop: 30 }}>
                                {this.state.filename}
                            </div>
                        </CardContent>
                    </Card>
                </div>

                <div style={{ float: "right", marginBottom: "10px", }}>

                    {this.state.button_disp ?
                        <Button
                            style={{
                                marginRight: "20px",
                                color: "#fff",
                                backgroundColor: "#5cb85c",
                                borderColor: "#4cae4c",
                                width: "200px"
                            }}
                            onClick={this.save}
                            variant="outlined" color="primary">
                            登録
          </Button> :
                        <span style={{ fontWeight: "bold" }}>登録中...</span>}
                </div>


            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        Tatemono: state.Adjustment.Tatemono,
        Room: state.Adjustment.Room,
        Parking: state.Adjustment.Parking,
        Gyousya: state.Adjustment.Gyousya,
        Outaisub: state.Adjustment.Outaisub,
        shops: state.masterReducer.shops,
        users_select: state.masterReducer.users_select,
        user_details: state.masterReducer.user_details,
        request_detail: state.Adjustment.request_detail,
        new_syousai: state.RequestDetail.new_syousai,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        syousai_insert(state: any) {
            dispatch(RequestDetailSaga.syousai_insert(state))
        },
        file_upload(state: any) {
            dispatch(Adjustment.file_upload(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);

