import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './Css/RequestDetail.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import * as Sms from '../Sms/Saga/Sms';
import * as RequestDetailSaga from './Saga/RequestDetail';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Keiyaku from '../Keiyaku/Saga/Keiyaku';
import ChatTemplateGrid from '../Chat/ChatTemplateGrid';
import TemplateKanri from "../Keiyaku/TemplateKanri";
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Paper from '@material-ui/core/Paper';

import moment from 'moment'



interface Props {
    request_detail: any,
    user_details: any,
    send_message: any,
    sms_status: any,
    smstoggle: any,
    Outai_No: any,
    syousai_insert: any,
    template_category_list: any,
    get_template: any,
    get_template_category_list: any,
    sms_insert: any
}
interface State {
    Renraku_Saki: any,
    message: any,
    disp: boolean,
    buton_disp: boolean,
    Templatetoggle: boolean
}

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            Templatetoggle: false,
            disp: true,
            buton_disp: true,
            Renraku_Saki: props.request_detail.detail[0].Renraku_Saki.replace("-", "").replace("-", "").replace("　", "").replace(" ", "").replace(/\r?\n/g, ""),
            message: "この度はお部屋の不具合でご迷惑をお掛けしました。\nお忙しい中ご連絡頂き有難う御座いました。\n\nなお、このメッセージは送信専用となっておりますので、ご返信は受付できません。\n\n今後お困り事が御座いましたら入居者様専用アプリをご活用ください。\n入居中のお問合せや各種申請、建物情報(ライフライン等)の確認が可能です。\n\n「 RoomSupport　(ﾙｰﾑｻﾎﾟｰﾄ）」\n★ダウンロードはこちら↓↓★\n・iPhone\nhttps://apps.apple.com/jp/app/id1571451725?mt=8\n\n・android\nhttps://play.google.com/store/apps/details?id=jp.co.globalcenter.roomsupport\n\n詳細\nhttps://living.global-center.co.jp/roomsupport\n\n●㈱グローバルセンター\n●お問合せ先\nhttps://living.global-center.co.jp/contact/"
        }
        this.onChangeRenraku_Saki = this.onChangeRenraku_Saki.bind(this);
        this.onChangemessage = this.onChangemessage.bind(this);

        this.ontemplate = this.ontemplate.bind(this);
        this.send = this.send.bind(this);
        this.onchange_category = this.onchange_category.bind(this);
        this.onChange = this.onChange.bind(this);
        this.Templatetoggle = this.Templatetoggle.bind(this);

    }

    Templatetoggle() {





        if (this.state.Templatetoggle == false) {
            this.props.get_template_category_list();
        }
        this.setState({ Templatetoggle: !this.state.Templatetoggle })

    }

    onChange(e: any) {
        console.log(e.target.value);
        this.setState({ message: e.target.value, disp: false });
        setTimeout(() => {
            this.setState({ disp: true })
        }, 1000)
    }

    onChangeRenraku_Saki(e: any) {
        this.setState({
            Renraku_Saki: e.target.value,
        });
    }
    onChangemessage(e: any) {
        this.setState({
            message: e.target.value,
        });
    }

    ontemplate() {
        this.setState({
            message: "いつも大変お世話になっております。\n株式会社グローバルセンターです。\n\n弊社の管理物件にお住まいの方向けの入居者様専用アプリをご案内致します。iPhone、androidをご使用の方は、下記URLからアプリをダウンロードいただけます。\n\niPhone\nhttps://apps.apple.com/jp/app/id1571451725?mt=8\n\nandroid\nhttps://play.google.com/store/apps/details?id=jp.co.globalcenter.roomsupport\n\nアプリの利用方法などは下記のページをご確認下さい。\nhttps://living.global-center.co.jp/roomsupport\n\nなお、このメッセージは送信専用となっておりますので、ご返信は受付できません。 \n以上、宜しくお願い致します。",
            disp: false
        });

        setTimeout(() => {
            this.setState({ disp: true })
        }, 1000)
    }

    send() {

        this.setState({ buton_disp: false })

        var d = moment(new Date()).tz("Asia/Tokyo").format('YYYY年MM月DD日 HH:mm:ss');

        var data = {
            message_text: this.state.message,
            mobilenumber: this.state.Renraku_Saki,
            returnsmsdatetime: d,
            user_id: this.props.user_details.id,
            user_name: this.props.user_details.userNameAll,
            section_id: this.props.user_details.amSectionId
        }

        this.props.send_message(data);

        setTimeout(() => {
            if (this.props.sms_status == 200) {
                alert("送信しました。");



                var data =
                {
                    Outai_No: this.props.Outai_No,
                    OutaiDate: moment().tz("Asia/Tokyo").format('YYYY-MM-DD'),
                    OutaiDate_H: moment(new Date()).hours(),
                    OutaiDate_M: moment(new Date()).minutes(),
                    Naiyo: this.state.message,
                    OutaiHoho_Name: "SMS",
                    OutaiHoho_Biko: this.state.Renraku_Saki,
                    Tanto_Id: this.props.user_details.id,
                    Biko: "",
                }

                this.props.syousai_insert(data);


                var sms_data = {
                    mobilenumber: this.state.Renraku_Saki,
                    message: this.state.message,
                    sended_at: moment().tz("Asia/Tokyo").format('YYYY-MM-DD HH:mm:ss'),
                    sended_user: this.props.user_details.id,
                    sended_section: this.props.user_details.amSectionId,
                    return_code: this.props.sms_status,
                    Outai_No: this.props.Outai_No
                }

                this.props.sms_insert(sms_data);

                setTimeout(() => {
                    this.props.smstoggle();
                }, 500)
            } else {
                var sms_data = {
                    mobilenumber: this.state.Renraku_Saki,
                    message: this.state.message,
                    sended_at: moment().tz("Asia/Tokyo").format('YYYY-MM-DD HH:mm:ss'),
                    sended_user: this.props.user_details.id,
                    sended_section: this.props.user_details.amSectionId,
                    return_code: this.props.sms_status,
                    Outai_No: this.props.Outai_No
                }

                this.props.sms_insert(sms_data);

                alert("失敗しました。 エラーコードは" + this.props.sms_status + "です。下にあるエラーコード一覧をご確認下さい。");
                this.setState({ buton_disp: true });
            }
        }, 2000)
    }

    onchange_category(event: any, values: any) {
        if (values) {
            this.props.get_template(values.id);
        }
    }

    render() {

        return (
            <div style={{ padding: 10, background: "#b0c4de" }} >
                <div className="item-outer col-lg-12">
                    <Card className="col-lg-12" >
                        <CardContent style={{ float: "left" }} className="col-lg-12">
                            <div>
                                送信先：<TextField
                                    value={this.state.Renraku_Saki}
                                    onChange={this.onChangeRenraku_Saki}
                                    style={{ width: 400 }}
                                    id="standard-basic" />
                            </div>
                            <div>●連絡先はハイフン無しの携帯電話の番号にしてください。</div>
                            <div>●このSMSに返信はできません。</div>
                            {this.state.disp ?
                                <TextField
                                    id="outlined-read-only-input"
                                    label="本文"
                                    fullWidth
                                    multiline
                                    rows={15}
                                    variant="outlined"
                                    onChange={this.onChangemessage}
                                    defaultValue={this.state.message}
                                    style={{ marginTop: "10px" }}
                                />
                                : ""}
                            <div>
                                文字数:{this.state.message.length}
                            </div>
                            {/* <Button style={{ color: "white", backgroundColor: "#28a745", margin: 5 }} variant="contained" onClick={this.ontemplate}>アプリ勧誘テンプレート</Button> */}

                            <div>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={this.props.template_category_list}
                                    getOptionLabel={(option: any) => option.category_title}
                                    style={{ width: 200, marginTop: 12 }}
                                    onChange={this.onchange_category}
                                    renderInput={(params) => <TextField {...params} label="テンプレートカテゴリー" />}
                                />



                                {this.state.buton_disp ?
                                    <Button style={{ float: "right", margin: 5 }} color="primary" variant="contained" onClick={this.send}>送信</Button>
                                    : ""}
                            </div>
                            <Button
                                style={{ marginLeft: 200 }}
                                onClick={this.Templatetoggle}
                                color="primary"
                                variant="outlined">
                                テンプレートを作成する
                                    </Button>

                            <div>
                                <ChatTemplateGrid onChange={this.onChange} />
                            </div>





                        </CardContent>
                    </Card>
                    <Card className="col-lg-12" style={{ marginTop: 10 }}  >
                        <CardContent style={{ float: "left" }} className="col-lg-12">
                            <div>エラーコード一覧</div>
                            <div>
                                401 認証エラー 402 送信数が上限 503 一時的に利用不可 555 不正アクセス
                            </div>
                            <div>
                                560 電話番号の値がおかしい
                                </div>


                        </CardContent>
                    </Card>
                </div>

                < Modal isOpen={this.state.Templatetoggle} toggle={this.Templatetoggle} size="keiyaku" fade={false} style={{ marginRight: 700 }}>
                    <ModalHeader toggle={this.Templatetoggle}>テンプレート管理</ModalHeader>
                    <div style={{ padding: 10, background: "#b0c4de" }} >
                        <Paper square={false} style={{ marginTop: 10 }}>
                            <TemplateKanri />
                        </Paper>
                    </div>
                    <ModalFooter>
                    </ModalFooter>
                </Modal >
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        request_detail: state.Adjustment.request_detail,
        user_details: state.masterReducer.user_details,
        sms_status: state.sms.sms_status,
        template_category_list: state.Keiyaku.template_category_list,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        send_message(state: any) {
            dispatch(Sms.send_sms_message(state))
        },
        syousai_insert(state: any) {
            dispatch(RequestDetailSaga.syousai_insert(state))
        },
        get_template(state: any) {
            dispatch(Keiyaku.get_template(state))
        },
        get_template_category_list(state: any) {
            dispatch(Keiyaku.get_template_category_list(state))
        },
        sms_insert(state: any) {
            dispatch(RequestDetailSaga.sms_insert(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);

