import React, { Component } from 'react';
import { connect } from 'react-redux';
import CardComponent from "./CardComponent";
import CardComponent2 from "./CardComponent2";
import * as Scenario from './Saga/Scenario';

import Button from '@material-ui/core/Button';

import { Modal, ModalHeader, ModalFooter, ModalBody, Row, Col } from 'reactstrap';
// import * as Board from './Saga/Board';
import Draggable from 'react-draggable'; // The default
import Owner from '../Owner/Index';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { TextField } from '@material-ui/core';




class CustomerList extends Component {


    constructor(props) {
        super(props);

        this.state = {
            refresh: false,
           
        }


        // alert(JSON.stringify(this.props.room_support_chat_template_list))


        // this.togglesheet = this.togglesheet.bind(this);
        // this.togglesheet_set = this.togglesheet_set.bind(this);

        // this.toggle_rent_money = this.toggle_rent_money.bind(this);
        // this.toggle_owner = this.toggle_owner.bind(this);

        // this.toggle_owner_set = this.toggle_owner_set.bind(this);

        // this.state = {
        //     modalcontract: false,


        // }

        this.props.get_tm_outai_sub_list()
        this.props.get_room_support_chat_forms_list()
        this.props.get_room_support_chat_template_list(this.props.room_support_chat_template_category_id)

    }





    componentWillMount() {
        // this.props.get_kyousais()
        // this.props.customerNewDispSet(0)
        // this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    // submit(state: any) {
    //     console.log(state)

    //     this.props.get_seisan_follow_list(state)
    // }

    // togglesheet = () => {


    //     this.setState({
    //         tatemono_id: "",
    //         room_id: "",
    //         modalsheet: !this.state.modalsheet,

    //     });
    // }
    // togglesheet_set = (tatemono_id: any, room_id: any) => {


    //     this.setState({
    //         tatemono_id: tatemono_id,
    //         room_id: room_id,
    //         modalsheet: !this.state.modalsheet,

    //     });
    // }

    // toggle_rent_money = () => {

    //     this.setState({
    //         modal_rent_money: !this.state.modal_rent_money,

    //     });
    // }
    // toggle_owner = () => {

    //     this.setState({
    //         kashinushi_id: 0,
    //         modal_owner: !this.state.modal_owner,

    //     });
    // }
    // toggle_owner_set = (kashinushi_id: number) => {

    //     this.setState({
    //         kashinushi_id: kashinushi_id,
    //         modal_owner: !this.state.modal_owner,

    //     });

    // }
    // togglecontract(e: any) {

    //     this.setState(prevState => ({
    //         modalcontract: !prevState.modalcontract,

    //     }));

    //     return
    // }
    csv() {

        // this.props.get_rent_contract_accounting(this.props.kyousais);
    }
    render() {

        
        return (
            // <div style={{overflowX:"scroll" ,width:500 }}>
            <div>
                <div style={{width:"100%" }}>
                    <div style={{marginLeft:10, fontSize:16}}>
                        {this.props.room_support_chat_template_category_list.filter(a=>a.id == this.props.room_support_chat_template_category_id)[0].id}：{this.props.room_support_chat_template_category_list.filter(a=>a.id == this.props.room_support_chat_template_category_id)[0].name}
                    </div>
                {/* <div style={{position: "fixed", Top: 0, paddingTop: 0, right: 0, zIndex: 900}}>
                    <Button variant="contained" color="primary" onClick={() => this.props.change_save_modal_disp(true)}>保存</Button>
                </div> */}
                        {/* {this.props.room_support_chat_template_list.filter(a=>a.id == 0 && a.status == 0).map(function (value) {
                            return <CardComponent card_data = {value} />
                        })} */}
                            {this.props.room_support_chat_template_list.length > 0 ? 
                                this.props.room_support_chat_template_list.filter(a=>a.id == 0 && a.status == 0).length > 0 ? 
                                parseInt(this.props.room_support_chat_template) == 0 ? <CardComponent2 room_support_chat_template_list ={this.props.room_support_chat_template_list} tm_outai_sub_list = {this.props.tm_outai_sub_list} shops = {this.props.shops} room_support_chat_template_category_id={this.props.room_support_chat_template_category_id} card_data = {this.props.room_support_chat_template_list.filter(a=>a.id == 0 && a.status == 0)[0]} />:
                                <CardComponent room_support_chat_template_list ={this.props.room_support_chat_template_list} tm_outai_sub_list = {this.props.tm_outai_sub_list} room_support_chat_forms_list = {this.props.room_support_chat_forms_list} room_support_chat_template_category_id={this.props.room_support_chat_template_category_id} card_data = {this.props.room_support_chat_template_list.filter(a=>a.id == 0 && a.status == 0)[0]} />
                            :"":""}
                </div >
                
                {/* <Modal isOpen={this.props.save_modal_disp} fade={false}  toggle={() => this.props.change_save_modal_disp(false)} > */}
                <Modal isOpen={this.props.save_modal_disp} fade={false} >
                    {/* <ModalHeader toggle={(e) => { this.setState({ "modal": false }) }}>
                        <span>営業対応履歴</span>
                    </ModalHeader> */}
                    <ModalBody>
                        <div>本当に保存してよろしいですか</div>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="contained" color="primary" onClick={() => this.props.update_room_support_chat_template_list()}>保存</Button>
                        <Button variant="contained" color="" style={{ marginLeft: 3 }} onClick={() => this.props.change_save_modal_disp(false)}>閉じる</Button>
                    </ModalFooter>

                </Modal>
            </div>
            // <div className="row" >

            //         {data.filter(a=>a.id == 0).map(function (data_value) {
            //             return <Card  >
            //             {data_value.name ? <CardContent>
            //                 {data_value.name}
            //                 {data_value.contents.map(function (value) {
                                
            //                     if(value["type"] == "text"){
            //                         return <div><TextField style={{ width: "300" }} value={value["text"]} id="search" label="表示" name="search" variant="outlined" InputLabelProps={{ shrink: true }} /></div>
            //                     }else if(value["type"] == "select"){
            //                         // alert(value["actions"].length)
        
            //                         return <Card  style={{display:'flex'}}>
                                            
        
            //                         {value["actions"].map(function (contents_value) {
            //                             return <CardContent style={{width:300}}>
            //                                 <Card>
            //                                     <CardContent>{contents_value["label"]}</CardContent>
            //                                     {/* <CardContent>{this.props.card_data_all.filter(a => a.id == parseInt(contents_value["data"])).length}</CardContent> */}
            //                                     {/* <CardComponent card_data_all = {this.props.card_data_all} card_data = {this.props.card_data_all.filter(a => a.id == parseInt(contents_value["data"])).length > 0 ? this.props.card_data_all.filter(a => a.id == parseInt(contents_value["data"]))[0] : ""} /> */}
            //                                     {console.log("bbbbbbbbbb")}
            //                                     {console.log(contents_value["data"])}
            //                                     {console.log(data.filter(a => a.id == parseInt(contents_value["data"])))}
            //                                     <CardComponent card_data_all = {data} card_data = {value} />
            //                                 </Card>
            //                                 </CardContent>
            //                         },this)}
        
                                        
            //                         </Card>
                                    
            //                     }
            //                     // return <CardComponet card_data = {value} />
            //                 },this)}
            //             </CardContent >:""}
            //         </Card >
            //         })}
            // </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {


        room_support_chat_template_list: state.scenario.room_support_chat_template_list,
        tm_outai_sub_list: state.scenario.tm_outai_sub_list,
        shops: state.masterReducer.shops,
        room_support_chat_template_category_id: state.scenario.room_support_chat_template_category_id,
        save_modal_disp: state.scenario.save_modal_disp,
        room_support_chat_template: state.scenario.room_support_chat_template,

        room_support_chat_template_category_list: state.scenario.room_support_chat_template_category_list,

    }
}
function mapDispatchToProps(dispatch) {
    return {

        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },

        // get_board_seisan_follow_list(state: any) {
        //     dispatch(Board.get_board_seisan_follow_list(state))
        // },
        // change_search_modal(state: any) {
        //     dispatch(Adjustment.change_search_modal(state))
        // },

        get_tm_outai_sub_list(state) {
            dispatch(Scenario.get_tm_outai_sub_list(state))
        },
        get_room_support_chat_forms_list(state) {
            dispatch(Scenario.get_room_support_chat_forms_list(state))
        },
        get_room_support_chat_template_list(state) {
            dispatch(Scenario.get_room_support_chat_template_list(state))
        },
        update_room_support_chat_template_list(state) {
            dispatch(Scenario.update_room_support_chat_template_list(state))
        },
        change_save_modal_disp(state) {
            dispatch(Scenario.change_save_modal_disp(state))
        },
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




