import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from "./Grid";
import LinkedGrid from "./LinkedGrid";
import * as CustomerLink from './Saga/CustomerLink';
import Button from '@material-ui/core/Button';


var moment = require('moment-timezone');


interface Props {
    list: any,
    get_list: any,
    get_linklist: any
}

interface State {
    conv_list: boolean
}

class CustomerList extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            conv_list: false
        }
        props.get_list();
        props.get_linklist();


        this.listswich = this.listswich.bind(this);
    }


    listswich() {
        this.props.get_list();
        this.props.get_linklist();
        this.setState({ conv_list: !this.state.conv_list })
    }

    render() {
        return (
            <div>
                <div className="item-outer col-lg-12">
                    <Card >

                        {this.state.conv_list ?
                            <CardContent >
                                <h3>入居者アプリ 登録済みリスト</h3>
                                <div>
                                    <Button size="small" style={{ color: "white", backgroundColor: "#f0ad4e", margin: 5 }} onClick={this.listswich} variant="contained">
                                        未登録を表示
                            </Button>
                                </div>
                                <div>
                                    <LinkedGrid />
                                </div>
                            </CardContent >
                            :
                            <CardContent >
                                <h3>入居者アプリ 未登録リスト</h3>
                                <div><Button size="small" style={{ color: "white", backgroundColor: "#f0ad4e", margin: 5 }} onClick={this.listswich} variant="contained">
                                    登録済みを表示
                            </Button>
                                </div>
                                <div>
                                    <Grid />
                                </div>
                            </CardContent >
                        }

                    </Card >
                </div>
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_list(state: any) {
            dispatch(CustomerLink.get_list(state))
        },
        get_linklist(state: any) {
            dispatch(CustomerLink.get_linklist(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




