import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import Button from '@material-ui/core/Button';
// import { CSVLink, CSVDownload } from "react-csv";
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from "date-fns/locale/ja";
import format from "date-fns/format";
import * as Board from './Saga/Board';
import { CSVLink } from "react-csv";
import OutaiSubGrid from "./OutaiSubGrid";


var moment = require('moment-timezone');
class ExtendedUtils extends DateFnsUtils {
    getCalendarHeaderText(date: any) {
        return format(date, "yyyy MMM", { locale: this.locale });
    }
    getDatePickerHeaderText(date: any) {
        return format(date, "MMMd日", { locale: this.locale });
    }
}
const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');


class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}

interface Props {
    get_outai_sub_list: any,
    outai_sub_list: any
}



interface State {
    date1: any,
    date2: any
}


const date1 = new Date();
const year = date1.getFullYear();
const month = date1.getMonth() + 1;
var sdate = moment(year + "-" + month + "-01").format('YYYY-MM-DD');
class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            date1: sdate,
            date2: new Date(),
        }
        this.csv = this.csv.bind(this);

    }




    onchange_date1 = (date: any) => {
        this.setState({
            date1: new Date(date),
        });
    };

    onchange_date2 = (date: any) => {
        this.setState({
            date2: new Date(date),
        });
    };

    csv() {
        var data = {
            date1: moment(this.state.date1).utc().format('YYYY-MM-DD'),
            date2: moment(this.state.date2).utc().format('YYYY-MM-DD'),
        }

        this.props.get_outai_sub_list(data);

    }

    render() {
        return (
            <div>
                <div>
                    リクエスト区分の集計
                </div>
                <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker
                        variant="inline"
                        margin="normal"
                        id="date1"
                        format="yyyy/MM/dd"
                        style={{ width: 130 }}
                        value={this.state.date1}
                        onChange={this.onchange_date1}
                        autoOk={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>

                <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>

                <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker
                        variant="inline"
                        margin="normal"
                        id="date-picker-dialog"
                        format="yyyy/MM/dd"
                        style={{ width: 130 }}
                        value={this.state.date2}
                        onChange={this.onchange_date2}
                        autoOk={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>

                <Button
                    size="small"
                    style={{ color: "white", backgroundColor: "#4169e1", margin: 5 }}
                    onClick={this.csv}
                    variant="contained">
                    検索
                            </Button>


                {this.props.outai_sub_list[0] ?
                    <CSVLink
                        data={this.props.outai_sub_list}
                        filename={"my-file.csv"}
                        target="_blank"
                    >
                        <Button
                            size="small"
                            style={{ color: "white", backgroundColor: "#008000", margin: 5 }}
                            variant="contained">
                            CSV
                            </Button>
                    </CSVLink> : ""}
                {this.props.outai_sub_list[0] ?

                    <div>
                        {/* {this.props.outai_sub_list.map((e: any) => (
                            <div>
                                {e.name}：{e.count}件（内完了{e.completion}件 自動応答で完了したのは{e.auto}件）
                            </div>
                        ))} */}
                        <OutaiSubGrid />

                    </div>
                    : <div>読み込み中...</div>}

            </div>



            /* <ComposedChart　　//グラフ全体のサイズや位置、データを指定。場合によってmarginで上下左右の位置を指定する必要あり。
                        width={800}  //グラフ全体の幅を指定
                        height={470}  //グラフ全体の高さを指定
                        layout="vertical" //グラフのX軸とY軸を入れ替え
                        data={test_data}   //Array型のデータを指定
                        margin={{ top: 0, right: 60, bottom: 0, left: 190 }}  //marginを指定
                    >
                        <XAxis  //X軸に関する設定
                            type="number" //データタイプをnumberに変更。デフォルトではcategoryになっている
                            domain={['dataMin - 1', 'dataMax']} //軸の表示領域を指定
                        />
                        <YAxis //Y軸に関する設定
                            type="category" //データタイプをcategoryに変更
                            dataKey="name"  //Array型のデータの、Y軸に表示したい値のキーを指定
                        />
                        <Tooltip />
                        <CartesianGrid  //グラフのグリッドを指定
                            stroke="#f5f5f5"  //グリッド線の色を指定
                        />
                        <Bar
                            dataKey="count"
                            barSize={20}
                            stroke="rgba(34, 80, 162, 0.2)"
                            fillOpacity={1}
                            fill="#2250A2"
                        />
                    </ComposedChart> */
        );

    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        outai_sub_list: state.board.outai_sub_list,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_outai_sub_list(state: any) {
            dispatch(Board.get_outai_sub_list(state))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);




