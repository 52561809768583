import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import * as Keiyaku from './Saga/Keiyaku';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Kihon from "./Kihon";
import SmsGrid from "./SmsGrid";
import TextField from '@material-ui/core/TextField';
import { Row, Col } from 'reactstrap';
import Button from '@material-ui/core/Button';
const request = require('request');



interface Props {
    classes: any,
    checkout: any,
    customers: any,
    sms_allsend: any,
    tatemono_id: any,
    user_details: any
}

interface State {
    message: any,
    send_custmers: any
}


class CustomerList extends Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            message: "",
            send_custmers: []
        }

        this.onChangemessage = this.onChangemessage.bind(this);
        this.send = this.send.bind(this);

    }


    onChangemessage(e: any) {
        this.setState({
            message: e.target.value,
        });
    }

    send(send_custmers: any) {



        var data = {
            message: this.state.message,
            send_custmers: send_custmers,
            tatemono_id: this.props.tatemono_id,
            user_id: this.props.user_details.id,
            section_id: this.props.user_details.amSectionId
        }

        this.props.sms_allsend(data);

    }

    render() {

        var send_custmers: any[] = [];
        // チェックを入れてない人、電話番号がない人を除外する
        this.props.customers.forEach((value: any) => {
            if (this.props.checkout.indexOf(String(value.m_customers_id)) != -1) {

            } else if (value.tel !== "") {
                send_custmers.push(value);
            }
        });

        return (
            <div style={{ padding: 10, background: "#b0c4de" }} >
                <Paper square={false} style={{ marginTop: 10 }}>

                    <Row style={{ marginLeft: 10 }}>
                        <div>
                            <div style={{ fontSize: 20 }}>
                                送信対象：{send_custmers.length}件
                            </div>
                            <SmsGrid send_custmers={send_custmers} />
                        </div>


                        <div style={{ marginTop: 10, marginLeft: 10 }}>
                            <div>
                                <TextField
                                    id="outlined-read-only-input"
                                    label="送信内容(※ここで送信した内容に、返信はできません)"
                                    multiline
                                    onChange={this.onChangemessage}
                                    rows={25}
                                    variant="outlined"
                                    style={{ width: 800, marginTop: "10px" }}
                                />
                            </div>
                            <div style={{ fontSize: 15 }}>
                                文字数:{this.state.message.length}
                            </div>
                            <div style={{ marginTop: 10, marginLeft: 750 }}>
                                <Button onClick={() => this.send(send_custmers)} color="primary" variant="outlined">送信</Button>

                            </div>
                        </div>
                    </Row>

                </Paper>
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        customers: state.Keiyaku.customers,
        user_details: state.masterReducer.user_details,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        sms_allsend(state: any) {
            dispatch(Keiyaku.sms_allsend(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




