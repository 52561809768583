import React, { Component } from 'react';
import * as Adjustment from './Saga/Adjustment';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './Css/Search.css';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import jaLocale from "date-fns/locale/ja";
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import NewRequest from "./demoNewRequest";
import { format } from 'date-fns'
import { CSVLink, CSVDownload } from "react-csv";

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme: any) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton style={{ position: 'absolute' }} aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}

    </MuiDialogTitle>
  );
});



const moment = require('moment')

// import DatePicker from "react-datepicker";
const kokyaku = [
  { label: "", key: "" },
  { label: "オーナー", key: "オーナー" },
  { label: "入居者", key: "入居者" },
  { label: "退去者", key: "退去者" },
  { label: "その他", key: "その他" },
];

const taiou = [
  { label: "", key: "" },
  { label: "応対中", key: "応対中" },
  { label: "完了", key: "完了" },
  { label: "一旦保留", key: "一旦保留" },
  { label: "業者対応完了", key: "業者対応完了" },
  { label: "全て", key: "全て" },
];

// const type = [
//   { value: "電話", label: "電話" },
//   { value: "メール", label: "メール" },
//   { value: "問い合せフォーム", label: "問い合せフォーム" },
//   { value: "UME", label: "UME" },
//   { value: "来店", label: "来店" },
//   { value: "くらしーど", label: "くらしーど" },
//   { value: "駆け付け", label: "駆け付け" },
// ]

var type = [
  { value: "電話", key: "電話" },
  { value: "メール", key: "メール" },
  { value: "UME", key: "UME" },
  { value: "来店", key: "来店" },
  { value: "シード", key: "シード" },
  { value: "駆付", key: "駆付" },
  { value: "留守電", key: "留守電" },
]

// csvカラム★
const headers = [
  { label: "No.", key: "Outai_No" },
  { label: "受付日", key: "created_at" },
  { label: "最終対応", key: "lasts" },
  { label: "物件名", key: "Tatemono_Name" },
  { label: "部屋名", key: "Room_Name" },
  { label: "案件タイトル", key: "Title" },
  { label: "業務課備考", key: "remarks" },
  { label: "対応担当", key: "taiou_user" },
  { label: "応対状況", key: "Outai_Joukyou_Name" },
  { label: "顧客名", key: "Kokyaku_Name" },
  { label: "連絡先", key: "Renraku_Saki" },
  { label: "顧客区分", key: "Kokyaku_Kbn" },
  { label: "受電者", key: "shokai_user" },
  { label: "営業担当", key: "Shoyuusha_Tantou_Name" },
  { label: "受付方法", key: "type" },
  { label: "初回対応日", key: "Syokai_Date" },
  { label: "初回手配業者", key: "Gyosha_Name" },
  { label: "オーナー報告", key: "owner_houkoku" },
  { label: "次回手配業者", key: "Gyosha2_Name" },
  { label: "完了日", key: "complete_date" },
  { label: "契約日", key: "Keiyaku_Date" },
  { label: "完了タイトル", key: "completed_title" },
  { label: "完了結果", key: "completed_remarks" },
];



// import DatePicker from "react-datepicker";
let timer: any
const { DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');

class JaLocalizedUtils extends DateFnsUtils {
  // ヘッダ部分のテキストを取得するメソッド
  getDatePickerHeaderText(date: Date) {
    return format(date, 'M月d日(E)', { locale: this.locale })
  }
}


interface Props {
  users_select: any,
  get_request_list: any,
  request_syubetsu: any,
  get_tatemono: any,
  get_gyousya: any,
  get_outaisub: any,
  search_request: any,
  uketuke_Date1: any,
  uketuke_Date2: any,
  syokai_Date1: any,
  syokai_Date2: any,
  saisyu_Date1: any,
  saisyu_Date2: any,
  jikai_Date1: any,
  jikai_Date2: any,
  kanryo_Date1: any,
  kanryo_Date2: any,
  title: any,
  bukken: any,
  naiyou: any,
  room: any,
  joukyou: any,
  kokyaku: any,
  main: any,
  kokyaku_name: any,
  judensya: any,
  tantou_owner: any,
  tantou_taiou: any,
  kaketuke_check: any,
  nyuukyo_check: any,
  tyouki_check: any,
  nozoku_check: any,
  kenan_check: any,
  app_check: any,
  app_apply_check: any,
  disp: any,
  onChangeuketukeDate1: any,
  onChangeuketukeDate2: any,
  onnowuketukeDate: any,
  onChangeusyokaiDate1: any,
  onChangeusyokaiDate2: any,
  onChangesaisyuDate1: any,
  onChangesaisyuDate2: any,
  onChangejikaiDate1: any,
  onChangejikaiDate2: any,
  onChangekanryoDate1: any,
  onChangekanryoDate2: any,
  onChangetitle: any,
  onChangebukken: any,
  onChangenaiyou: any,
  onChangeroom: any,
  onChangejoukyou: any,
  onChangekokyaku: any,
  onChangemain: any,
  onChangekokyaku_name: any,
  onChangejudensya: any,
  onChangetantou_owner: any,
  onChangetantou_taiou: any,
  onChangekaketuke_check: any,
  onChangenyuukyo_check: any,
  onChangetyouki_check: any,
  onChangekenan_check: any,
  onChangenozoku_check: any,
  onChangeapp_check: any,
  onChangeapp_apply_check: any,
  req_search: any,
  search_clear: any,
  onChange_Renraku_Saki: any,
  Renraku_Saki: any,
  onChange_OutaiHoho_Biko: any,
  onChange_type: any,
  OutaiHoho_Biko: any,
  type: any,
  onChange_Kanryo_Naiyo: any,
  onChange_completed_title: any,
  request_list: any,
  request_list_csv:any,
  onChangepublished_check:any,
  published_check:any
}


interface State {
  syousaikensaku: any,
  reqtoggle: boolean,
  open: boolean,
  Syokai_Date: any,
  Shokai_H: any,
  Shokai_M: any,
  csv_data: any
}



var date1 = new Date();
var Hours = date1.getHours();
var Minutes = date1.getMinutes();
class KyousaiSearch extends Component<Props, State> {

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      syousaikensaku: "詳細検索を開く",
      reqtoggle: false,
      open: false,
      Syokai_Date: new Date(),
      Shokai_H: Hours,
      Shokai_M: Minutes,
      csv_data: []
    }


    this.open = this.open.bind(this);
    this.reqtoggle = this.reqtoggle.bind(this);

  }



  open() {
    this.setState(prevState => ({
      open: !prevState.open,
    }));


    if (this.state.open == true) {
      this.setState({
        syousaikensaku: "詳細検索を開く",
      });
    } else {
      this.setState({
        syousaikensaku: "詳細検索を閉じる",
      });
    }

  }

  reqtoggle() {

    date1 = new Date();
    Hours = date1.getHours();
    Minutes = date1.getMinutes();

    // this.props.get_tatemono();
    this.props.get_gyousya();
    this.props.get_outaisub();
    this.setState({
      Syokai_Date: new Date(),
      Shokai_H: Hours,
      Shokai_M: Minutes,
    });
    this.setState({ reqtoggle: !this.state.reqtoggle })
  }

  render() {

    return (
      this.props.disp ?
        <div id="request_search">
          <Button onClick={this.reqtoggle} color="secondary" variant="outlined">リクエスト新規登録</Button>
          {/* <form onSubmit={handleSubmit}  > */}
          <table style={{ border: "0px" }}>

            <tbody>

              <tr style={{ border: "0px" }}>
                <td colSpan={1} style={{ border: "0px", width: 400 }}>

                  {/* 日付選択 */}
                  <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker
                      variant="inline"
                      margin="normal"
                      id="date-picker-dialog"
                      label="受付日"
                      format="yyyy/MM/dd"
                      style={{ width: 130 }}
                      value={this.props.uketuke_Date1}
                      onChange={this.props.onChangeuketukeDate1}
                      autoOk={true}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>

                  {/* 日付選択 */}
                  <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker

                      variant="inline"
                      margin="normal"
                      id="date-picker-dialog"
                      label="受付日"
                      format="yyyy/MM/dd"
                      style={{ width: 130 }}
                      value={this.props.uketuke_Date2}
                      onChange={this.props.onChangeuketukeDate2}
                      autoOk={true}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  <Button onClick={this.props.onnowuketukeDate} size="small" style={{ marginLeft: 20, marginTop: 20 }} variant="outlined" color="primary">
                    今日
                  </Button>
                </td>


                <td colSpan={1} style={{ border: "0px", width: 300 }}>
                  <td colSpan={1} style={{ border: "0px" }}>
                    <TextField
                      autoComplete='off'
                      value={this.props.bukken}
                      style={{ width: 200 }}
                      id="standard-basic"
                      onChange={this.props.onChangebukken}
                      label="物件名"
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          this.props.req_search();
                        }
                      }} />
                  </td>
                </td>


                <td colSpan={1} style={{ border: "0px" }}>
                  <TextField
                    autoComplete='off'
                    style={{ width: 200 }}
                    id="standard-basic"
                    onChange={this.props.onChangeroom}
                    label="部屋名"
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        this.props.req_search();
                      }
                    }} />



                  <Button style={{ marginLeft: 20 }} onClick={this.props.search_clear} variant="outlined">検索条件クリア</Button>
                </td>






              </tr>

              <tr style={{ border: "0px" }}>

                <td colSpan={1} style={{ border: "0px" }}>
                  <TextField
                    autoComplete='off'
                    style={{ width: 200 }}
                    onChange={this.props.onChangetitle}
                    id="standard-basic"
                    label="案件タイトル"
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        this.props.req_search();
                      }
                    }} />
                </td>


                <td colSpan={1} style={{ border: "0px" }}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={taiou}
                    defaultValue={{ label: "応対中", key: "応対中" }}
                    getOptionLabel={(option: any) => option.label}
                    style={{ width: 200 }}
                    onChange={this.props.onChangejoukyou}
                    renderInput={(params) => <TextField
                      autoComplete='off' {...params} label="対応状況" />}
                  />
                </td>

                <td colSpan={1} style={{ border: "0px" }}>

                  <FormGroup row>

                    <Autocomplete
                      id="combo-box-demo"
                      options={this.props.users_select}
                      getOptionLabel={(option: any) => option.label}
                      style={{ width: 200 }}
                      onChange={this.props.onChangetantou_taiou}
                      renderInput={(params) => <TextField
                        autoComplete='off' {...params} label="対応担当" />}
                    />



                    {/* <span style={{ marginLeft: 30 }}>
                      <FormControlLabel
                        onChange={this.props.onChangeapp_check}
                        control={<Checkbox
                          color="primary"
                          name="yanushi_hoshu" />}

                        label="アプリリクエストのみ"
                      />
                    </span> */}


                    {/* <span style={{ marginLeft: 30 }}>
                      <FormControlLabel
                        onChange={this.props.onChangeapp_apply_check}
                        control={<Checkbox
                          color="primary"
                          name="yanushi_hoshu" />}

                        label="アプリ申請のみ"
                        checked={this.props.app_apply_check}
                      />
                    </span> */}
                  </FormGroup>




                </td>


              </tr>
              {(() => {
                if (this.state.open == true) {
                  return (
                    <tr style={{ border: "0px" }}>


                      <td colSpan={1} style={{ border: "0px" }}>
                        <TextField
                          autoComplete='off'
                          style={{ width: 200 }}
                          id="standard-basic"
                          onChange={this.props.onChangenaiyou}
                          label="案件内容"
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              this.props.req_search();
                            }
                          }} />
                      </td>

                      <td colSpan={1} style={{ border: "0px" }}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={this.props.users_select}
                          getOptionLabel={(option: any) => option.label}
                          style={{ width: 200 }}
                          onChange={this.props.onChangetantou_owner}
                          renderInput={(params) => <TextField
                            autoComplete='off' {...params} label="営業担当" />}
                        />
                      </td>


                      <td colSpan={1} style={{ border: "0px" }}>
                        {/* 日付選択 */}
                        <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                          <KeyboardDatePicker

                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="最終対応日"
                            format="yyyy/MM/dd"
                            style={{ width: 130 }}
                            value={this.props.saisyu_Date1}
                            onChange={this.props.onChangesaisyuDate1}
                            autoOk={true}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>


                        <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>

                        {/* 日付選択 */}
                        <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                          <KeyboardDatePicker

                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="最終対応日"
                            format="yyyy/MM/dd"
                            style={{ width: 130 }}
                            value={this.props.saisyu_Date2}
                            onChange={this.props.onChangesaisyuDate2}
                            autoOk={true}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </td>
                    </tr>
                  );
                }
              })()}

              {(() => {
                if (this.state.open == true) {
                  return (
                    <tr style={{ border: "0px" }}>

                      <td colSpan={1} style={{ border: "0px" }}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={kokyaku}
                          getOptionLabel={(option: any) => option.label}
                          style={{ width: 200 }}
                          onChange={this.props.onChangekokyaku}
                          renderInput={(params) => <TextField
                            autoComplete='off' {...params} label="顧客区分" />}
                        />
                      </td>

                      <td colSpan={1} style={{ border: "0px" }}>
                        <InputLabel htmlFor="age-native-helper">メイン区分</InputLabel>
                        <NativeSelect
                          style={{ fontSize: "10px", width: 200 }}
                          onChange={this.props.onChangemain}
                          inputProps={{
                            name: 'メイン区分',
                            id: 'uncontrolled-native',
                          }}
                        >
                          <option key={0}> </option>
                          <option key={5656539} value={5656539}>設備全般①～45</option>
                          <option key={5656114} value={5656114}>人的全般A～O</option>
                          <option key={5656117} value={5656117}>入居時リクエスト(設備・人的)</option>
                          {this.props.request_syubetsu.map((d: any) => (
                            <option key={d.id} value={d.id}>
                              {d.symbol}:{d.name}
                            </option>
                          ))}
                        </NativeSelect>
                      </td>
                      <td colSpan={1} style={{ border: "0px" }}>
                        {/* 日付選択 */}
                        <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                          <KeyboardDatePicker

                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="完了日"
                            format="yyyy/MM/dd"
                            style={{ width: 130 }}
                            value={this.props.kanryo_Date1}
                            onChange={this.props.onChangekanryoDate1}
                            autoOk={true}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>

                        <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>

                        {/* 日付選択 */}
                        <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                          <KeyboardDatePicker

                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="完了日"
                            format="yyyy/MM/dd"
                            style={{ width: 130 }}
                            value={this.props.kanryo_Date2}
                            onChange={this.props.onChangekanryoDate2}
                            autoOk={true}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </td>
                    </tr>

                  );
                }
              })()}




              {(() => {
                if (this.state.open == true) {
                  return (
                    <tr style={{ border: "0px" }}>
                      <td colSpan={1} style={{ border: "0px" }}>
                        <TextField
                          autoComplete='off'
                          style={{ width: 200 }}
                          id="standard-basic"
                          onChange={this.props.onChangekokyaku_name}
                          label="顧客名"
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              this.props.req_search();
                            }
                          }} />
                      </td>

                      <td colSpan={1} style={{ border: "0px" }}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={this.props.users_select}
                          getOptionLabel={(option: any) => option.label}
                          style={{ width: 200 }}
                          onChange={this.props.onChangejudensya}
                          renderInput={(params) => <TextField
                            autoComplete='off' {...params} label="受電者" />}
                        />
                      </td>



                      {/* 日付選択 */}
                      <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                        <KeyboardDatePicker

                          variant="inline"
                          margin="normal"
                          id="date-picker-dialog"
                          label="初回対応日"
                          format="yyyy/MM/dd"
                          style={{ width: 130 }}
                          value={this.props.syokai_Date1}
                          onChange={this.props.onChangeusyokaiDate1}
                          autoOk={true}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>

                      <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>

                      {/* 日付選択 */}
                      <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                        <KeyboardDatePicker

                          variant="inline"
                          margin="normal"
                          id="date-picker-dialog"
                          label="初回対応日"
                          format="yyyy/MM/dd"
                          style={{ width: 130 }}
                          value={this.props.syokai_Date2}
                          onChange={this.props.onChangeusyokaiDate2}
                          autoOk={true}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>


                    </tr>

                  );
                }
              })()}

              {(() => {
                if (this.state.open == true) {
                  return (
                    <tr style={{ border: "0px" }}>
                      <td colSpan={1} style={{ border: "0px" }}>
                        <TextField
                          autoComplete='off'
                          style={{ width: 200 }}
                          id="standard-basic"
                          onChange={this.props.onChange_Renraku_Saki}
                          label="連絡先"
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              this.props.req_search();
                            }
                          }} />
                      </td>

                      <td colSpan={1} style={{ border: "0px" }}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={type}
                          getOptionLabel={(option: any) => option.label}
                          style={{ width: 200 }}
                          onChange={this.props.onChange_type}
                          renderInput={(params) => <TextField
                            autoComplete='off' {...params} label="受付方法" />}
                        />
                      </td>

                      <td colSpan={1} style={{ border: "0px" }}>
                        <TextField
                          autoComplete='off'
                          style={{ width: 200 }}
                          id="standard-basic"
                          onChange={this.props.onChange_OutaiHoho_Biko}
                          label="応対方法備考"
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              this.props.req_search();
                            }
                          }} />
                      </td>



                    </tr>
                  );
                }
              })()}

              {(() => {
                if (this.state.open == true) {
                  return (
                    <tr style={{ border: "0px" }}>

                      <td colSpan={1} style={{ border: "0px" }}>
                        <TextField
                          autoComplete='off'
                          style={{ width: 200, marginTop: 15 }}
                          id="standard-basic"
                          onChange={this.props.onChange_completed_title}
                          label="完了タイトル"
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              this.props.req_search();
                            }
                          }} />
                      </td>

                      <td colSpan={1} style={{ border: "0px" }}>
                        <TextField
                          autoComplete='off'
                          style={{ width: 200, marginTop: 15 }}
                          id="standard-basic"
                          onChange={this.props.onChange_Kanryo_Naiyo}
                          label="完了結果"
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              this.props.req_search();
                            }
                          }} />
                      </td>

                    </tr>
                  );
                }
              })()}


              {/* {(() => {
                if (this.state.open == true) {
                  return (
                    <tr style={{ border: "0px" }}>

                      <td colSpan={3} style={{ border: "0px" }}>
                        <FormGroup row>
                          <FormControlLabel
                            onChange={this.props.onChangekaketuke_check}
                            control={<Checkbox
                              color="primary"
                              name="tousha_hoshu" />}

                            label="駆け付けのみ"
                          />
                          <FormControlLabel
                            onChange={this.props.onChangenyuukyo_check}
                            control={<Checkbox
                              color="primary"
                              name="yanushi_hoshu" />}

                            label="入居時リクエストのみ"
                          />
                          <FormControlLabel
                            onChange={this.props.onChangetyouki_check}
                            control={<Checkbox
                              color="primary"
                              name="yanushi_hoshu" />}

                            label="長期案件のみ"
                          />
                          <FormControlLabel
                            onChange={this.props.onChangenozoku_check}
                            control={<Checkbox
                              color="primary"
                              name="yanushi_hoshu" />}
                            label="入居時リクエスト/懸案事項/長期案件を除く"
                          />
                          <FormControlLabel
                            onChange={this.props.onChangekenan_check}
                            control={<Checkbox
                              color="primary"
                              name="yanushi_hoshu" />}

                            label="懸案事項"
                          />
                            <FormControlLabel
                            onChange={this.props.onChangepublished_check}
                            control={<Checkbox
                              color="primary"
                              name="yanushi_hoshu" />}
                            label="掲載案件のみ"
                          />
                        </FormGroup>
                      </td>
                    </tr>
                  );
                }
              })()} */}


            </tbody >
          </table >

          <div style={{ float: "right", marginBottom: "10px", }}>




            <Button
              style={{
                marginLeft: "50px"
              }}
              onClick={this.open} variant="outlined">{this.state.syousaikensaku}</Button>



            {/* {this.props.request_list.length ?
              <Button style={{ marginLeft: "20px" }} variant="outlined">
                <CSVLink style={{ textDecoration: "none" }} headers={headers} filename={"リクエスト一覧.csv"} data={this.props.request_list_csv}>CSV</CSVLink>
              </Button> : ""} */}

            <Button
              style={{
                marginLeft: "20px"
              }}
              onClick={this.props.req_search}
              variant="outlined" color="primary">
              検索
            </Button>
          </div>




          < Modal isOpen={this.state.reqtoggle} toggle={this.reqtoggle} size="request" fade={false} backdrop={false}>
            {/* <ModalHeader toggle={this.reqtoggle}>リクエスト新規登録</ModalHeader> */}
            <DialogTitle id="customized-dialog-title" onClose={this.reqtoggle} >リクエスト新規登録</DialogTitle>

            <NewRequest
              req_search={this.props.req_search}
              reqtoggle={this.reqtoggle}
              Syokai_Date={this.state.Syokai_Date}
              Shokai_H={this.state.Shokai_H}
              Shokai_M={this.state.Shokai_M}
            />
            <ModalFooter>
            </ModalFooter>
          </Modal >


        </div > : <div style={{ height: 180 }}></div>
    );
  }
}




const mapStateToProps = (state: any) => {
  return {
    request_syubetsu: state.Adjustment.request_syubetsu,
    users_select: state.masterReducer.users_select,
    request_list: state.Adjustment.request_list,
    request_list_csv: state.Adjustment.request_list_csv,

  }
}
function mapDispatchToProps(dispatch: any) {
  return {
    get_request_list(state: any) {
      dispatch(Adjustment.get_request_list(state))
    },
    get_tatemono(state: any) {
      dispatch(Adjustment.get_tatemono(state))
    },
    get_gyousya(state: any) {
      dispatch(Adjustment.get_gyousya(state))
    },
    get_outaisub(state: any) {
      dispatch(Adjustment.get_outaisub(state))
    },
    search_request(state: any) {
      dispatch(Adjustment.search_request(state))
    },
  };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(KyousaiSearch));
