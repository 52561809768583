import React from 'react';

import styled from 'styled-components';
// import './Css/Images.css';
import Carousel, { Modal, ModalGateway } from 'react-images';
const authentication = require('../../react-azure-adb2c2').default;
var moment = require('moment-timezone');

const Balloon = styled.div`
    position:relative;

    padding:0px;
    margin: 15px 0 0 0;
    margin-bottom: 0;

`;

const Chatting = styled.div`
    width: 100%;
    text-align: right;

`;

const Says = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 60px 0 60px;
    padding: 0;
    max-width: 250px;
    height:270px;
    border-radius: 12px;
    margin-bottom: 2px;
    text-align: left;
`;

const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 10px 0;
    margin-top:15px;
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;

const Kidoku = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 25px 0;
    margin-top:15px;
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;

// const MsgArea = styled.p`
//     margin:0px;
//     word-wrap: break-word;
// `;

const BodyImg = styled.img`
    border-radius: 12px;
height:250px;
    margin:0;
`;

export default class LineTextComponent extends React.Component {
    constructor(props) {
        super(props);
        const dt = new Date(this.props.msg.date)
        this.state = {
            mode: '1',
            messages: [],
            bloburl: "",
            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),
            images: [],
            currentIndex: 0,
            modalIsOpen: false,

        };
        this.imagebox = this.imagebox.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentWillMount() {



    }

    imagebox(index) {

        let images = [];

        images.push({ src: this.state.bloburl })



        this.setState({ "images": images })
        if (index >= 0) {
            this.setState({ currentIndex: 0 })
            this.setState({ modalIsOpen: true });
        }
    }
    toggleModal = () => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
    };
    render() {
        return (
            <div>
                <Balloon className="col-xs-12">

                    <Chatting>
                        <Time>
                            <span style={{ color: "black" }}>
                                {moment(this.props.msg.created_at).format('MM/DD HH:mm')}
                            </span>
                        </Time>
                        <Kidoku>
                        <span style={{ color: "black" }}>
                            {this.props.msg.read_at ? "既読" : ""}
                        </span>
                    </Kidoku>
                        <Says>

                        </Says>
                    </Chatting>
                </Balloon>
                <ModalGateway >
                    {this.state.modalIsOpen ? (
                        <Modal onClose={this.toggleModal} >
                            <Carousel views={this.state.images}
                                currentIndex={this.state.currentIndex}

                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>

        )
    }
}
