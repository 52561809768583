import React, { Component } from 'react';
import { connect } from 'react-redux';
import Calendar from "./Calendar";
import Search from "./Search";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import * as Calendarsaga from './Saga/Calendar';

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }

    this.props.get_calendar_tatemono_list()
  }


  render() {
    return (
      <div>
        <Card style={{ margin: 10, height: 80 }}>
          <CardContent >
            {this.props.calendar_tatemono_list.length > 0 ? <Search /> : ""}
          </CardContent >
        </Card >

        <Card style={{ margin: 10, height: window.innerHeight - 170 }}>
          <CardContent style={{ margin: 10, height: window.innerHeight - 200 }}>
            {this.props.calendar_tatemono_list.length > 0 ? <Calendar /> : ""}
          </CardContent >
        </Card >
      </div>
    );
  }
};




const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    calendar_tatemono_list: state.calendar.calendar_tatemono_list,
    // users_select: state.masterReducer.users_select,

    // calendar_list: state.calendar.calendar_list,
    // calendar_list_form: state.calendar.calendar_list_form,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_calendar_tatemono_list(state) {
      dispatch(Calendarsaga.get_calendar_tatemono_list(state))
    },
    // change_calendar_list_form(state) {
    //   dispatch(Calendarsaga.change_calendar_list_form(state))
    // },
    // change_calendar_list(state) {
    //   dispatch(Calendarsaga.change_calendar_list(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);