import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Search from "./Search";
import Grid from "./Grid";
import IsseiGrid from "./IsseiGrid";

import * as MessageList from './Saga/MessageList';
var moment = require('moment-timezone');


interface Props {
    get_tatemono: any,
    newmessage_customers: any,
    newlist_issei: any,
    user_details: any,
    search_status_true: any,
    search_status: any,
    get_message_count: any
}



interface State {
    now: any,
    issei_sousin: boolean,
    section_id: any,
    not_process_only_flag: boolean,
    auto_complete_flag: boolean,
    middle_text_flag: boolean,
    user_seach: boolean,
    search_flag: boolean
}

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);


        this.state = {
            now: moment(new Date()).tz("Asia/Tokyo").format('YYYY-MM-DD HH:mm:ss'),
            issei_sousin: false,
            section_id: 0,
            not_process_only_flag: false,
            auto_complete_flag: false,
            middle_text_flag: false,
            user_seach: false,
            search_flag: false
        }

        setTimeout(() => {
            props.search_status_true();
            props.newmessage_customers(this.props.user_details.id);
        }, 500)
        props.newlist_issei();
        this.issei_disp = this.issei_disp.bind(this);
        this.now_set = this.now_set.bind(this);
        this.onChangeSection = this.onChangeSection.bind(this);
        this.not_process_only_flag = this.not_process_only_flag.bind(this);
        this.auto_complete_flag = this.auto_complete_flag.bind(this);
        this.middle_text_flag = this.middle_text_flag.bind(this);
        this.search_flag = this.search_flag.bind(this);

    }

    not_process_only_flag() {
        this.setState({ not_process_only_flag: !this.state.not_process_only_flag })
    }
    auto_complete_flag() {
        this.setState({ auto_complete_flag: !this.state.auto_complete_flag })
    }
    middle_text_flag() {
        this.setState({ middle_text_flag: !this.state.middle_text_flag })
    }

    issei_disp() {
        this.setState({ issei_sousin: !this.state.issei_sousin })
    }

    search_flag() {
        this.setState({ search_flag: true })
    }

    now_set() {
        this.setState({
            now: moment(new Date()).tz("Asia/Tokyo").format('YYYY-MM-DD HH:mm:ss')
        });
    }


    onChangeSection(event: any, values: any) {
        this.setState({
            section_id: values.tenpoNo,
        });
    }

    render() {


        // 一旦消す
        // if (this.state.user_seach == false && this.props.user_details.id) {
        //     this.props.get_message_count(this.props.user_details.id);
        //     this.setState({ user_seach: true });
        // }

        if (this.props.search_status == true) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'auto';
        }
        return (
            <div>
                <div className="item-outer col-lg-12">
                    <Card >
                        <CardContent >
                            <Search
                                search_flag={this.search_flag}
                                not_process_only_flag_data={this.state.not_process_only_flag}
                                auto_complete_flag_data={this.state.auto_complete_flag}
                                middle_text_flag_data={this.state.middle_text_flag}
                                not_process_only_flag={this.not_process_only_flag}
                                auto_complete_flag={this.auto_complete_flag}
                                middle_text_flag={this.middle_text_flag}
                                section_id={this.state.section_id}
                                issei_sousin={this.issei_disp} />
                        </CardContent >
                    </Card >
                </div>
                <div className="item-outer col-lg-12">
                    <Card >
                        <CardContent >
                            {(() => {
                                if (this.state.issei_sousin == false) {
                                    return <Grid
                                        search_flag={this.state.search_flag}
                                        onChangeSection={this.onChangeSection}
                                        now_set={this.now_set}
                                        now={this.state.now}
                                        auto_complete_flag={this.state.auto_complete_flag}
                                        middle_text_flag={this.state.middle_text_flag}
                                    />
                                } else {
                                    return <IsseiGrid />
                                }
                            })()}
                        </CardContent >
                    </Card >
                </div>
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        user_details: state.masterReducer.user_details,
        search_status: state.MessageList.search_status,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        newmessage_customers(state: any) {
            dispatch(MessageList.newmessage_customers(state))
        },
        newlist_issei(state: any) {
            dispatch(MessageList.newlist_issei(state))
        },
        search_status_true(state: any) {
            dispatch(MessageList.search_status_true(state))
        },
        get_message_count(state: any) {
            dispatch(MessageList.message_count(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




