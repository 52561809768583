
const authentication = require('../../../react-azure-adb2c2').default;






export const send = (values) => {

    // let url = process.env.REACT_APP_API_PM_URL + `/v1/room/support/chat/sends/text`;
    let url = process.env.REACT_APP_ROOM_SUPPORT_API_URL + `/v1/hs/send/messages`;
    // let url = `http://localhost:5000/v1/hs/send/messages`;
    // alert(url)
    const token = authentication.getAccessToken();
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



export const send_line_stock_rooms = (values) => {
    // let url = `http://localhost:5000/v1/line/send/carousel/`;

    let url = process.env.REACT_APP_API_PM_URL + `/v1/line/send/carousel/`;

    const token = authentication.getAccessToken();
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};




export const upload = (values) => {

    // let url = `http://localhost:5000/v1/hs/send/upload/`;
    let url = process.env.REACT_APP_ROOM_SUPPORT_API_URL + `/v1/hs/send/upload/`;

    const token = authentication.getAccessToken();


    var formData = new FormData();
    formData.append("files", values.file[0]);
    // formData.append("user_id", values.user_id);
    formData.append("name", values.name);
    formData.append("room_support_m_chat_id", values.room_support_m_chat_id);
    console.log(formData)
    // alert(JSON.stringify(accountinglistset))


    return fetch(url, {
        method: 'post',
        body: formData,

        headers: { 'Authorization': 'Bearer ' + token },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



export const chat_insert = (values) => {
    // let url = `http://localhost:5000/v1/t/outaishosai/chat_insert`;
    let url = process.env.REACT_APP_API_PM_URL + `/v1/t/outaishosai/chat_insert`;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
};



export const chat_new = (values) => {

    // let url = 'http://localhost:5000/v1/hs/send/new/' + values.room_support_m_user_id;
    let url = process.env.REACT_APP_ROOM_SUPPORT_API_URL + '/v1/hs/send/new/' + values.room_support_m_user_id;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
};

