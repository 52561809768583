import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import ja from "date-fns/locale/ja";
import './Css/Grid.css';
import DateFnsUtils from '@date-io/date-fns';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import LinksModal from './LinksModal';
import Button from '@material-ui/core/Button';
import * as CustomerLink from './Saga/CustomerLink';
import TextField from '@material-ui/core/TextField';
import { CSVLink } from "react-csv";
import SumikaeModal from './SumikaeModal';

const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');



var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    }
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

// csvカラム★
const headers = [
  { label: "登録日時", key: "created_at" },
  { label: "物件名", key: "tatemono_name" },
  { label: "部屋名", key: "room_name" },
  { label: "氏名", key: "user_name" },
  { label: "TEL", key: "user_tel" },
  { label: "ランク", key: "matching_rank" },
  { label: "紐付け登録者", key: "user_name_all" },
  { label: "契約日", key: "Keiyaku_Date" },
  { label: "解約日", key: "Kaiyaku_Date" },
];
const columns =
  [
    { key: 'sumikae', name: '', width: 100, resizable: true },
    { key: 'updated_at', name: '登録日時', width: 150, resizable: true },
    { key: 'tatemono_name', name: '建物名', width: 300, resizable: true },
    { key: 'room_name', name: '部屋名', width: 100, resizable: true },
    { key: 'user_name', name: '氏名', width: 100, resizable: true },
    { key: 'user_tel', name: '電話番号', width: 100, resizable: true },
    { key: 'input_tatemono_name', name: '入力した建物名', width: 300, resizable: true },
    { key: 'input_room_name', name: '入力した部屋名', width: 100, resizable: true },
    { key: 'input_user_name', name: '入力した氏名', width: 100, resizable: true },
    { key: 'input_tel', name: '入力した電話番号', width: 100, resizable: true },
    { key: 'matching_rank', name: 'ランク', width: 50, resizable: true },
    { key: 'user_name_all', name: '紐付け登録者', width: 150, resizable: true },
    { key: 'Keiyaku_Date', name: '契約日', width: 150, resizable: true },
    { key: 'Kaiyaku_Date', name: '解約日', width: 150, resizable: true },
    { key: 'app_versions', name: 'アプリver', width: 100, resizable: true },
    { key: 'device', name: 'デバイス', width: 100, resizable: true },
    { key: 'device_versions', name: 'デバイスver', width: 100, resizable: true },
    // { key: 'birthday', name: '生年月日', width: 100, resizable: true },
    { key: 'delete', name: '', width: 100, resizable: true }
  ]

const Base = ({ column, props, rowIndex }) =>
  <div>
    <Cell style={{ whiteSpace: "nowrap" }} >
      {[column.key] == "tatemono_name" ? props[rowIndex]["tatemono_name"] ? props[rowIndex]["tatemono_name"] : props[rowIndex]["tatemono_name2"]
        : [column.key] == "room_name" ? props[rowIndex]["room_name"] ? props[rowIndex]["room_name"] : props[rowIndex]["room_name2"]
          : props[rowIndex][column.key]}
    </Cell>
  </div>

const Sumikae = ({ column, props, rowIndex,linksopen }) =>
  <div>
    <Cell style={{ whiteSpace: "nowrap" }} >
      <Button onClick={() => linksopen(rowIndex)} size="small" style={{ color: "white", backgroundColor: "#43BBBB" }} variant="contained">
        住み替え登録
      </Button>
    </Cell>
  </div>

const DateTime = ({ column, props, rowIndex }) =>
  <div>
    <Cell style={{ whiteSpace: "nowrap" }} >
      {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).tz("utc").format('YYYY-MM-DD HH:mm:ss') : ""}
    </Cell>
  </div>


const Date = ({ column, props, rowIndex }) =>
  <div>
    <Cell style={{ whiteSpace: "nowrap" }} >
      {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).tz("utc").format('YYYY-MM-DD') : ""}
    </Cell>
  </div>

const Delete = ({ column, props, rowIndex, link_delete }) =>
  <div>
    <Cell style={{ whiteSpace: "nowrap" }} >
      <Button onClick={() => link_delete(rowIndex)} size="small" style={{ color: "white", backgroundColor: "#dc143c" }} variant="contained">
        紐付け解除
      </Button>
    </Cell>
  </div>

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns,
      reflash: false,
      colSortDirs: {},
      index: 0,
      select_row: 0,
      tatemono_name: "",
      user_name: "",
      index: 0

    };
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.link_delete = this.link_delete.bind(this);
    this.linktoggle = this.linktoggle.bind(this);
    this.search_change = this.search_change.bind(this);
    this.search = this.search.bind(this);
  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }
  _onSortChange(columnKey, sortDir) {

    let seisan_follow_search_list = this.props.linklist;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }


  link_delete(index) {
    this.setState({ select_row: index })
    setTimeout(() => {
      var result = window.confirm('紐付けを解除して宜しいですか？解除すると未登録リストに戻ります。');

      if (result) {
        this.props.link_delete({ room_support_m_user_id: this.props.linklist[index].room_support_m_user_id });
        alert("解除されました");
        setTimeout(() => {
          this.props.get_linklist();
        }, 500)

      }
    }, 500)
  }


  linksopen = (rowIndex) => {
    this.setState({
      index: rowIndex,
    })
    this.linktoggle();
  }
  linktoggle() {
    this.setState({
      linktoggle: !this.state.linktoggle
    })
  }



  search_change(e) {
    this.setState({ ...this.state, [e.target.id]: e.target.value });
  }
  search() {
    this.props.get_linklist_search(
      {
        tatemono_name: this.state.tatemono_name,
        user_name: this.state.user_name
      }
    );
  }

  render() {

    if (this.props.linklist[0]) {
      return (
        <div id="seisanlist">

          <div>
            <TextField
              autoComplete='off'
              style={{ width: 200 }}
              onChange={this.search_change}
              id="tatemono_name"
              label="建物名"
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  this.search();
                }
              }}
            />

            <TextField
              autoComplete='off'
              style={{ marginLeft: 30, width: 200 }}
              onChange={this.search_change}
              id="user_name"
              label="氏名"
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  this.search();
                }
              }}
            />

            <Button
              style={{
                marginLeft: 30
              }}
              onClick={this.search}
              variant="outlined" color="primary">
              検索
            </Button>

            <CSVLink
              headers={headers}
              data={this.props.linklist}
              filename={"my-file.csv"}
              target="_blank"
            >
              <Button
                size="small"
                style={{ color: "white", backgroundColor: "#008000", margin: 1, marginLeft: 10 }}
                variant="contained">
                CSV
              </Button>
            </CSVLink>
          </div>

          <div>
            {this.props.linklist.length}件
          </div>
          <Table
            rowHeight={30}
            rowsCount={this.props.linklist.length}
            // rowsCount={10}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            width={window.innerWidth - 110}
            height={window.innerHeight - 240}
            headerHeight={30}
          >
            {columns.map(function (value, i) {
              return <Column
                columnKey={value.key}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}
                cell={({ rowIndex, ...props }) => (
                  value.key === "sumikae" ? <Sumikae column={value} props={this.props.linklist} rowIndex={rowIndex} linksopen={this.linksopen}></Sumikae>
                    : value.key === "delete" ? <Delete column={value} props={this.props.linklist} rowIndex={rowIndex} link_delete={this.link_delete}></Delete>
                      : value.key === "birthday" || value.key === "updated_at" ? <DateTime column={value} props={this.props.linklist} rowIndex={rowIndex} ></DateTime>
                        : value.key === "Keiyaku_Date" || value.key === "Kaiyaku_Date" ? <Date column={value} props={this.props.linklist} rowIndex={rowIndex} ></Date>
                          : <Base column={value} props={this.props.linklist} rowIndex={rowIndex} ></Base>
                )}
                width={this.state.columns.filter(a => a.key === value.key)[0].width}
                isResizable={true}
                fixed={value.frozen}

              />
            }, this)}
          </Table >


          < Modal isOpen={this.state.linktoggle} toggle={this.linktoggle} size="xl" fade={false}>
            <ModalHeader toggle={this.linktoggle}>アプリ利用者→契約の変更（住み替え）</ModalHeader>
            <SumikaeModal
              links_place={1}
              index={this.state.index}
              linktoggle={this.linktoggle} />
            <ModalFooter>
            </ModalFooter>
          </Modal >




        </div >
      );
    } else {
      return (
        <div>
          <div>
            <TextField
              autoComplete='off'
              style={{ width: 200 }}
              onChange={this.search_change}
              id="tatemono_name"
              label="建物名"
            />

            <TextField
              autoComplete='off'
              style={{ marginLeft: 30, width: 200 }}
              onChange={this.search_change}
              id="user_name"
              label="氏名"
            />

            <Button
              style={{
                marginLeft: 30
              }}
              onClick={this.search}
              variant="outlined" color="primary">
              検索
            </Button>
          </div>
        </div>
      );
    }
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    linklist: state.CustomerLink.linklist,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    link_delete(state) {
      dispatch(CustomerLink.link_delete(state))
    },
    get_linklist(state) {
      dispatch(CustomerLink.get_linklist(state))
    },
    get_linklist_search(state) {
      dispatch(CustomerLink.get_linklist_search(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);