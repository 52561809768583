import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import HeaderTable from "./HeaderTable";
import BicyclePhotoContent from "./BicyclePhotoContent";
import * as BicycleList from '../BicycleList/Saga/BicycleList';



interface Props {
    // list: any,
    match: any,
    get_bicycle_detail: any,
    bicycle_detail: any,
    // bicycle_file_upload: any,
    get_bicycle_file_list: any,
    bicycle_file_list: any
    // download_bicycle_file: any
    // user_details:any,
    // get_kizu_check_file_list:any,
    // kizu_check_file_list:any
}

interface State {
    // conv_list: boolean,
    // kizu_flg: boolean,
    // filename: any,
    // files: any,
}




class BicycleDetail extends Component<Props, State> {
    
    constructor(props: Readonly<Props>) {
        super(props);

        if (this.props.match) {

            this.props.get_bicycle_detail({Keiyaku_No: this.props.match.params.keiyaku_no})

        }

    }


    render() {
        return (
            <div>
                <div className="item-outer col-lg-12">
                    <Card>
                        <CardContent>
                            <div>
                                <HeaderTable/>
                            </div>
                        </CardContent >

                        <CardContent>
                            <div>
                                {/* {JSON.stringify(this.props.bicycle_detail)} */}
                                {this.props.bicycle_detail.length > 0 ? <BicyclePhotoContent /> : ""}
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        );
    }
};


const mapStateToProps = (state: any) => {
    return {
        bicycle_detail: state.BicycleList.bicycle_detail,
        // download_bicycle_file: state.BicycleList.download_bicycle_file,
        // user_details: state.masterReducer.user_details,
        // kizu_check_file_list: state.BicycleList.kizu_check_file_list,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_bicycle_detail(state: any) {
            dispatch(BicycleList.get_bicycle_detail(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BicycleDetail);



