import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';


var moment = require('moment-timezone');



interface Props {
    bicycle_detail: any,
}

interface State {
}

class BicycleDetail extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
    }



    render() {
        return (
            <div>
                {/* {this.props.bicycle_detail[0]?JSON.stringify(this.props.bicycle_detail[0]):""} */}

                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ width: '15%', fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    登録日
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.bicycle_detail[0] ? moment(this.props.bicycle_detail[0].created_at).tz("utc").format('YYYY-MM-DD') : ""}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    号室
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.bicycle_detail[0] ? this.props.bicycle_detail[0].room_name : ""}
                                </TableCell>
                                {/* <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    登録者携帯番号
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                </TableCell> */}
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    契約日
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.bicycle_detail[0] ? moment(this.props.bicycle_detail[0].Keiyaku_Date).tz("utc").format('YYYY-MM-DD') : ""}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ width: '15%', fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    契約者
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.bicycle_detail[0] ? this.props.bicycle_detail[0].Kei_Name : ""}
                                </TableCell>
                                {/* <TableCell component="th" scope="row" style={{ width: '15%', fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    客付店舗
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                </TableCell> */}
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    更新日
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.bicycle_detail[0] ? moment(this.props.bicycle_detail[0].updated_at).tz("utc").format('YYYY-MM-DD') : ""}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    契約者携帯番号
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.bicycle_detail[0] ? this.props.bicycle_detail[0].Kei_Tel1 : ""}
                                </TableCell>
                                {/* <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    客付担当
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                </TableCell> */}
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    物件名
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0}} colSpan={3}>
                                    {this.props.bicycle_detail[0] ? this.props.bicycle_detail[0].tatemono_name : ""}
                                </TableCell>
                                {/* <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    登録者
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                </TableCell> */}
                                {/* <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    管理担当
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                </TableCell> */}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div >
        );
    }
};


const mapStateToProps = (state: any) => {
    return {
        bicycle_detail: state.BicycleList.bicycle_detail,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BicycleDetail);




