import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import ja from "date-fns/locale/ja";
import './Css/Grid.css';
import DateFnsUtils from '@date-io/date-fns';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import LinksModal from './LinksModal';
import Button from '@material-ui/core/Button';
import * as CustomerLink from './Saga/CustomerLink';

const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');



var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    }
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


const columns =
  [
    { key: 'updated_at', name: '登録日時', width: 150, resizable: true },
    { key: 'tatemono_name', name: '建物名', width: 300, resizable: true },
    { key: 'room_name', name: '部屋名', width: 200, resizable: true },
    { key: 'user_name', name: '氏名', width: 250, resizable: true },
    { key: 'app_versions', name: 'アプリver', width: 100, resizable: true },
    { key: 'device', name: 'デバイス', width: 100, resizable: true },
    { key: 'device_versions', name: 'デバイスver', width: 100, resizable: true },
    { key: 'delete', name: '', width: 350, resizable: true },
    // { key: 'birthday', name: '生年月日', width: 100, resizable: true }
  ]

const Base = ({ column, props, rowIndex, linksopen }) =>
  <div onClick={() => linksopen(rowIndex)}>
    <Cell style={{ whiteSpace: "nowrap" }} > {props[rowIndex][column.key]} </Cell>
  </div>

const Date = ({ column, props, rowIndex, linksopen }) =>
  <div onClick={() => linksopen(rowIndex)}>
    <Cell style={{ whiteSpace: "nowrap" }} >
      {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).tz("utc").format('YYYY-MM-DD HH:mm:ss') : ""}
    </Cell>
  </div>

const Delete = ({ column, props, rowIndex, link_delete }) =>
  <div>
    <Cell style={{ whiteSpace: "nowrap" }} >
      <Button onClick={() => link_delete(props[rowIndex])} size="small" style={{ color: "white", backgroundColor: "#dc143c" }} variant="contained">
        削除
      </Button>
    </Cell>
  </div>


class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns,
      reflash: false,
      linktoggle: false,
      colSortDirs: {},
      index: 0
    };
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.linktoggle = this.linktoggle.bind(this);
    this.link_delete = this.link_delete.bind(this);

  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }
  _onSortChange(columnKey, sortDir) {

    let seisan_follow_search_list = this.props.list;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  linksopen = (rowIndex) => {
    this.setState({
      index: rowIndex,
    })
    this.linktoggle();
  }
  linktoggle() {
    this.setState({
      linktoggle: !this.state.linktoggle
    })
  }



  link_delete(index) {
    console.log(index);
    var result = window.confirm('削除してよろしいですか？');
    if (result) {
      this.props.devices_delete({ room_support_m_user_id: index.room_support_m_user_id,updated_user_id:this.props.user_details.id });
      alert("削除されました");
      setTimeout(() => {
        this.props.get_list();
      }, 500)
    }
  }

  render() {

    if (this.props.list[0]) {
      return (
        <div id="seisanlist">
          <div>{this.props.list.length}件</div>
          <Table
            rowHeight={30}
            rowsCount={this.props.list.length}
            // rowsCount={10}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            width={window.innerWidth - 110}
            height={window.innerHeight - 200}
            headerHeight={30}>
            {columns.map(function (value, i) {
              return <Column
                columnKey={value.key}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}
                cell={({ rowIndex, ...props }) => (
                  value.key === "delete" ? <Delete column={value} props={this.props.list} rowIndex={rowIndex} link_delete={this.link_delete}></Delete>
                    : value.key === "birthday" || value.key === "updated_at" ? <Date column={value} props={this.props.list} rowIndex={rowIndex} linksopen={this.linksopen}></Date>
                      : <Base column={value} props={this.props.list} rowIndex={rowIndex} linksopen={this.linksopen}></Base>
                )}
                width={this.state.columns.filter(a => a.key === value.key)[0].width}
                isResizable={true}
                fixed={value.frozen}

              />
            }, this)}
          </Table >


          < Modal isOpen={this.state.linktoggle} toggle={this.linktoggle} size="xl" fade={false}>
            <ModalHeader toggle={this.linktoggle}>アプリ利用者→契約の紐付け</ModalHeader>
            <LinksModal
              links_place={1}
              index={this.state.index}
              linktoggle={this.linktoggle} />
            <ModalFooter>
            </ModalFooter>
          </Modal >


        </div >
      );
    } else {
      return (
        <div>データがありません</div>
      );
    }
  }
};


//export  default CusatomerList;
const mapStateToProps = (state) => {
  return {
    list: state.CustomerLink.list,
    user_details: state.masterReducer.user_details,

  }
}
function mapDispatchToProps(dispatch) {
  return {
    devices_delete(state) {
      dispatch(CustomerLink.devices_delete(state))
    },
    get_list(state) {
      dispatch(CustomerLink.get_list(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);