import React, { Component } from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { Container, Draggable } from "react-smooth-dnd";
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DragHandleIcon from "@material-ui/icons/DragHandle";
import Radio from '@material-ui/core/Radio';
import arrayMove from "array-move";
import DeleteIcon from '@material-ui/icons/Delete';
import * as Adjustment from './Saga/Adjustment';


const moment = require('moment')

interface Props {
    inset_contents: any,
    change_inset_contents: any,
    inset_contents_disp: any,
    notset_contents: any,
    change_notset_contents: any
}



interface State {
}


class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
    }



    onDrop = ({ removedIndex, addedIndex }: any) => {


        let data = this.props.inset_contents;
        data = arrayMove(data, removedIndex - 1, addedIndex - 1)

        this.props.change_inset_contents(data);
    };

    displaynone(id: any, name: any, width: any, key_name: any) {

        var data = {
            data: this.props.inset_contents,
            id: id,
            delete: 1
        }
        this.props.change_inset_contents(data);



        let data2 = this.props.notset_contents;
        data2.push({ id: id, name: name, width: width, key_name: key_name });
        this.props.change_notset_contents(data2);

    }

    render() {

        if (this.props.inset_contents_disp) {
            return (
                <div>
                    <List>
                        <Container dragHandleSelector=".drag-handle" lockAxis="y" style={{ width: "400px" }} onDrop={this.onDrop}>

                            <Divider />
                            {this.props.inset_contents.map(({ id, name, width, key_name }: any) => (
                                <Draggable key={id}>
                                    <ListItem>
                                        <ListItemText primary={name} />

                                        <ListItemSecondaryAction style={{ marginRight: 30, cursor: "pointer" }}>
                                            <ListItemIcon className="drag-handle" >
                                                <DragHandleIcon />
                                            </ListItemIcon>

                                        </ListItemSecondaryAction>
                                        <IconButton aria-label="delete" onClick={() => this.displaynone(id, name, width, key_name)}>
                                            <DeleteIcon fontSize="small" color="secondary" />
                                        </IconButton>
                                    </ListItem>
                                    <Divider />
                                </Draggable>
                            ))}

                        </Container>
                    </List>


                </div >
            );
        } else {
            return ""
        }
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        notset_contents: state.Adjustment.notset_contents,
        inset_contents: state.Adjustment.inset_contents,
    }
}



function mapDispatchToProps(dispatch: any) {
    return {
        change_inset_contents(state: any) {
            dispatch(Adjustment.change_inset_contents(state))
        },
        change_notset_contents(state: any) {
            dispatch(Adjustment.change_notset_contents(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




