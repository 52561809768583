import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Scenario from './Saga/Scenario';

import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
// import * as Board from './Saga/Board';
import Draggable from 'react-draggable'; // The default
import Owner from '../Owner/Index';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ArrowCircleUpIcon from '@material-ui/icons/ArrowDropDownCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import { Radio, RadioGroup } from '@material-ui/core';
import { stat } from 'fs';
import ListItemText from '@material-ui/core/ListItemText';


let delete_list = []

function fncDeleteSelect(room_support_chat_template_list, id, status) {
    // alert(id + "-" + status)
    let new_room_support_chat_template_list = room_support_chat_template_list

    new_room_support_chat_template_list = new_room_support_chat_template_list.map(function (value) {
        if (value.id == id && value.status == status) {
            // alert(value["contents"].filter(a => a["type"] == "select").length)
            if(value["contents"].filter(a => a["type"] == "select").length > 0){
                
                value["contents"].filter(a => a["type"] == "select")[0]["actions"].map(function (value2) {   
                    let data_tmp2 = value2["data"]
                    let label_tmp2 = value2["label"]
                    if(label_tmp2 != "前の質問に戻る"){
                        // alert(data_tmp2)
                        // alert(label_tmp2)
                        fncDeleteSelect(new_room_support_chat_template_list, data_tmp2.split("-")[0], data_tmp2.split("-")[1])

                    }
                    
                })
                // value["contents"][key]["actions"].splice(contents_key, 1)

            }
        }
        return value
    })

    // 削除
    // let delete_key = 0
    // room_support_chat_template_list = room_support_chat_template_list.map(function (value, key) {
    //     if (value.id == id && value.status == status) {
    //         delete_key = key
    //         // alert(value["contents"].filter(a => a["type"] == "select").length)
    //         // value["contents"][key]["actions"].splice(contents_key, 1)
    //     }
    //     return value
    // })
    // alert(delete_key)
    // room_support_chat_template_list.splice(delete_key, 1)

    delete_list.push(id + "-" + status)

    // console.log(new_room_support_chat_template_list)
    let result = new_room_support_chat_template_list.filter((a) => {
        // if(parseInt(a.id) == parseInt(id) && parseInt(a.status) == parseInt(status))alert(a.id)
        return !(parseInt(a.id) == parseInt(id) && parseInt(a.status) == parseInt(status));
    });
    // alert(result.length)
// alert(JSON.stringify(new_room_support_chat_template_list))
// alert(result)
    // console.log(id + "-" + status)
    // console.log("bbbbbbbbbbbbb")
    // console.log(result)

    return result

    // new_room_support_chat_template_list = result

    // change_room_support_chat_template_list(new_room_support_chat_template_list)



}




class CardComponent extends Component{

    constructor(props) {
        super(props);

        this.state = {
            refresh: false,
           
        }
        // alert(JSON.stringify(this.props.room_support_chat_template_list))

        this.onDelete = this.onDelete.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.onChangeLabel = this.onChangeLabel.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
        this.onChangeOutai = this.onChangeOutai.bind(this);
        this.onChangeShops = this.onChangeShops.bind(this);
        this.onChangeIsGyousyaTel = this.onChangeIsGyousyaTel.bind(this);
        this.onChangeKaketsukeOutaiSub = this.onChangeKaketsukeOutaiSub.bind(this);
        this.onChangeFree = this.onChangeFree.bind(this);
        this.onChangeSelected = this.onChangeSelected.bind(this);
        this.onAddSelect = this.onAddSelect.bind(this);
        this.onAddSelectRe = this.onAddSelectRe.bind(this);
        this.onDeleteSelect = this.onDeleteSelect.bind(this);
        this.onAddSelectNext = this.onAddSelectNext.bind(this);//つかってない
        this.onSelectChip = this.onSelectChip.bind(this);

    }





    componentWillMount() {
        // this.props.get_kyousais()
        // this.props.customerNewDispSet(0)
        // this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }


    csv() {

        // this.props.get_rent_contract_accounting(this.props.kyousais);
    }

    onDelete(id, status, key){
        // alert("onDelete")
        let room_support_chat_template_list = this.props.room_support_chat_template_list

console.log(this.props.room_support_chat_template_list)
console.log("cccccccccccccccccccccccccc")

        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {
                
                if(value.contents[key]["type"] == "select"){
                    value.contents[key]["actions"].map(function (value2) {   
                        let data_tmp2 = value2["data"]
                        let label_tmp2 = value2["label"]
                        if(label_tmp2 != "前の質問に戻る"){
                            // alert(data_tmp2)
                            // alert(label_tmp2)
                            fncDeleteSelect(room_support_chat_template_list, data_tmp2.split("-")[0], data_tmp2.split("-")[1])
    
                        }
                        
                    })
                }


                value.contents.splice(key, 1)

            }
            return value
        })

        for(let i = 0; i < delete_list.length; i++){
            let result = room_support_chat_template_list.filter((a) => {
                return !(a.id == delete_list[i].split("-")[0] && a.status == delete_list[i].split("-")[1]);
            });
    
            room_support_chat_template_list = result
        }
        delete_list=[]
        this.props.change_room_support_chat_template_list(room_support_chat_template_list)

        this.setState({ reflesh: !this.state.reflesh })

    }

    onAdd(id, status, key){
// alert(id)
// alert(status)
// alert("onAdd")
// console.log(this.props.room_support_chat_template_list)
// console.log("cccccccccccccccccccccccccc")
        let room_support_chat_template_list = this.props.room_support_chat_template_list

        
        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {
                
                // value.contents.splice(key, 1)
                // value.contents.push({"type":"text","text":""})
                value.contents.splice(key + 1, 0, {"type":"text","text":"","color":"black","style":""})

            }
            return value
        })

        this.props.change_room_support_chat_template_list(room_support_chat_template_list)

        this.setState({ reflesh: !this.state.reflesh })

    }

    onChangeName(e, id, status){
        // alert("onChangeName")
        let room_support_chat_template_list = this.props.room_support_chat_template_list

        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {
                
                value.name = e.target.value

            }
            return value
        })

        this.props.change_room_support_chat_template_list(room_support_chat_template_list)

        this.setState({ reflesh: !this.state.reflesh })

    }

    onChangeText(e, id, status, key, column){
        // alert("onChangeText")
        let room_support_chat_template_list = this.props.room_support_chat_template_list

        
        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {
                
                if(e.target.name == "style"){
                    value.contents[key][column] = e.target.checked == true ? "bold" : ""
                }else if(e.target.name == "uri_name"){
                    value.contents[key]["text"] = e.target.value
                }else{
                    value.contents[key][column] = e.target.value
                    if(e.target.name == "form"){
                        value.contents[key]["text"] = parseInt(e.target.value) > 0 ? this.props.room_support_chat_forms_list.filter(a=> a.id == parseInt(e.target.value))[0].name : ""
                    }
                }
                

            }
            return value
        },this)

        this.props.change_room_support_chat_template_list(room_support_chat_template_list)

        this.setState({ reflesh: !this.state.reflesh })

    }

    onChangeLabel(e, id, status, key, contents_key){
        // alert("onChangeLabel")
        let room_support_chat_template_list = this.props.room_support_chat_template_list

        
        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {
                
                value.contents[key]["actions"][contents_key]["label"] = e.target.value

            }
            return value
        })

        this.props.change_room_support_chat_template_list(room_support_chat_template_list)

        this.setState({ reflesh: !this.state.reflesh })

    }

    onChangeHensyu(e, id, status){
        // alert("onChangeHensyu")
        let room_support_chat_template_list = this.props.room_support_chat_template_list
        let tmp = ""
        
        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {
                value[e.target.name] = e.target.value
            }
            return value
        })

        this.setState({ reflesh: !this.state.reflesh })

    }

    onChangeOutai(e, id, status){
        // alert("onChangeOutai")
        let room_support_chat_template_list = this.props.room_support_chat_template_list
        let tmp = ""
        
        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {
                
                value["next_room_support_chat_template_category_id"] = null
                value["main_outai_sub"] = 0
                // value["sections"] = ""

                value[e.target.name] = e.target.value
            }
            return value
        })

        this.setState({ reflesh: !this.state.reflesh })

    }

    onChangeShops(e, id, status){

        let room_support_chat_template_list = this.props.room_support_chat_template_list
        let tmp = ""
        
        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {
                
                // value["next_room_support_chat_template_category_id"] = null
                // value["main_outai_sub"] = 0
                value["sections"] = ""

                value[e.target.name] = e.target.value.join(',')
            }
            return value
        })

        this.setState({ reflesh: !this.state.reflesh })

    }

    onChangeIsGyousyaTel(e, id, status){

        let room_support_chat_template_list = this.props.room_support_chat_template_list
        let tmp = ""
        
        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {
                value[e.target.name] = e.target.value
            }
            return value
        })

        this.setState({ reflesh: !this.state.reflesh })

    }

    onChangeKaketsukeOutaiSub(e, id, status){

        let room_support_chat_template_list = this.props.room_support_chat_template_list
        let tmp = ""
        
        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {
                value[e.target.name] = e.target.value
            }
            return value
        })

        this.setState({ reflesh: !this.state.reflesh })

    }

    onChangeFree(e, id, status){
        // alert("onChangeFree")
        let room_support_chat_template_list = this.props.room_support_chat_template_list
        let tmp = ""
        
        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {
                
                value["next_room_support_chat_template_category_id"] = null
                value["main_outai_sub"] = 0
                // value["sections"] = ""

                value[e.target.name] = e.target.value

                value.contents = []
                value.contents[0] = {"type":"text","text":"","color":"black","style":""}
            }
            return value
        })

        this.setState({ reflesh: !this.state.reflesh })

    }

    onChangeSelected(e, id, status){
        // alert("onChangeSelected")

        let room_support_chat_template_list = this.props.room_support_chat_template_list
        let tmp = ""
        
        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {
                // alert(value.selected)
                // value.contents[key]["type"] = e.target.value
                if(e.target.checked == true){
                    value.selected = 1
                    value.main_outai_sub = 0
                    value.sections = ""
                    value.is_gyousya_tel = 0
                    value.kaketsuke_main_outai_sub = 0
                    value.editable = 0
                    value.next_room_support_chat_template_category_id = null



                    //////自動応答変更時
                    // チェックが入ったら以降のselect削除
                        // さらに完了なら以降のすべてを削除→0(編集可)にするのでしなくていい

                    value.contents.map(function (value_contents, value_key) {
                        
                        if(value_contents["type"] == "select"){
                            value_contents["actions"].map(function (value2) {   
                                let data_tmp2 = value2["data"]
                                let label_tmp2 = value2["label"]
                                if(label_tmp2 != "前の質問に戻る"){
                                    fncDeleteSelect(room_support_chat_template_list, data_tmp2.split("-")[0], data_tmp2.split("-")[1])
                                }
                                
                            })

                            // alert(value_key)
                            value.contents.splice(value_key, 1)

                        }
                        
                    })

                    if(value.contents.length == 0){
                        value.contents[0] = {"type":"text","text":"","color":"black","style":""}
                    }


                    
                }else{
                    value.selected = 0
                    value.main_outai_sub = 0
                    value.sections = ""
                    value.is_gyousya_tel = 0
                    value.kaketsuke_main_outai_sub = 0
                    value.editable = 1
                    value.next_room_support_chat_template_category_id = null
                }

            }
            return value
        })        


        for(let i = 0; i < delete_list.length; i++){
            let result = room_support_chat_template_list.filter((a) => {
                return !(a.id == delete_list[i].split("-")[0] && a.status == delete_list[i].split("-")[1]);
            });
    
            room_support_chat_template_list = result
        }
        delete_list=[]
        this.props.change_room_support_chat_template_list(room_support_chat_template_list)

        this.setState({ reflesh: !this.state.reflesh })



        
        


    }

    onChangeType(e, id, status, key){
        // alert(key)
        let room_support_chat_template_list = this.props.room_support_chat_template_list
        let tmp = ""
        
        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {
                // alert(e.target.value)
                // value.contents[key]["type"] = e.target.value
                
                if(value.contents[key].type == "select"){
                    //以降削除
                    value.contents[key]["actions"].map(function (value2) {   
                        let data_tmp2 = value2["data"]
                        let label_tmp2 = value2["label"]
                        if(label_tmp2 != "前の質問に戻る"){
                            // alert(data_tmp2)
                            // alert(label_tmp2)
                            fncDeleteSelect(room_support_chat_template_list, data_tmp2.split("-")[0], data_tmp2.split("-")[1])
    
                        }
                        
                    })
                }

                value.contents[key] = {}
                if(e.target.value == "text"){
                    value.contents[key] = {"type":"text","text":"","color":"black","style":""}
                }else if(e.target.value == "select"){
                    let max_id = Math.max.apply(null,room_support_chat_template_list.map(function(o){return o.id;}))
                    // alert(max_id)
                    value.contents[key] = {"type":"select","actions":[
                        {"type":"postback","label":"","data":String(max_id + 1) + '-1'},
                        ]
                    }

                    tmp = {id:parseInt(max_id + 1), status:1, room_support_chat_template_category_id:this.props.room_support_chat_template_category_id, name:"", contents:[{"type":"text","text":"","color":"black","style":""}]}

                    value.contents.splice(key + 1)

                }else if(e.target.value == "select_back"){
                    let max_id = Math.max.apply(null,room_support_chat_template_list.map(function(o){return o.id;}))
                    // alert(max_id)
                    value.contents[key] = {"type":"select","actions":[
                        {"type":"postback","label":"前の質問に戻る","data":"back"},
                        ]
                    }
                    
                }else if(e.target.value == "tel"){
                    value.contents[key] = {"type":"tel","tel":"%コールセンターTEL%"}
                }else if(e.target.value == "img"){
                    value.contents[key] = {"type":"img","img":""}
                }else if(e.target.value == "movie"){
                    value.contents[key] = {"type":"movie","youtube_id":""}
                }else if(e.target.value == "link"){
                    value.contents[key] = {"type":"link","uri":"","text":""}
                }else if(e.target.value == "form"){
                    value.contents[key] = {"type":"form","form":"","text":""}
                }else if(e.target.value == "k_denka"){
                    value.contents[key] = {"type":"denka","actions":[{"room_support_chat_template_category_id":"177"},{"room_support_chat_template_category_id":"176"}]}
                }else if(e.target.value == "b_denka"){
                    value.contents[key] = {"type":"denka","actions":[{"room_support_chat_template_category_id":"180"},{"room_support_chat_template_category_id":"179"}]}
                }else if(e.target.value == "s_denka"){
                    value.contents[key] = {"type":"denka","actions":[{"room_support_chat_template_category_id":"291"},{"room_support_chat_template_category_id":"290"}]}
                }else if(e.target.value == "hurikomi"){
                    value.contents[key] = {"type":"hurikomi","actions":[{"room_support_chat_template_category_id":"193"},{"room_support_chat_template_category_id":"194"}]}
                }else if(e.target.value == "net"){
                    value.contents[key] = {"type":"net","actions":[{"room_support_chat_template_category_id":"201"},{"room_support_chat_template_category_id":"202"}]}//常時　個人
                }else if(e.target.value == "kyouyoutou"){
                    value.contents[key] = {"type":"kyouyoutou","actions":[]}
                }
                
                

            }
            return value
        },this)

        // alert(delete_list.length)
        for(let i = 0; i < delete_list.length; i++){
            let result = room_support_chat_template_list.filter((a) => {
                return !(a.id == delete_list[i].split("-")[0] && a.status == delete_list[i].split("-")[1]);
            });
    
            room_support_chat_template_list = result
        }
        delete_list=[]


        if(e.target.value == "select"){
            this.props.change_room_support_chat_template_list(room_support_chat_template_list.concat(tmp))
        }else{
            this.props.change_room_support_chat_template_list(room_support_chat_template_list)
        }

        

        this.setState({ reflesh: !this.state.reflesh })

    }

    onSelectChip(id, status, key, contents_key){
        // alert(len)
        // alert(status)
        // alert(key)
        // alert("onAddSelect")
        // console.log(this.props.room_support_chat_template_list)
        // console.log("cccccccccccccccccccccccccc")
        let room_support_chat_template_list = this.props.room_support_chat_template_list
        
        // alert(key)
        let set_id = 0
        let set_status = 0
        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {
                value["contents_key"] = contents_key
            }
            return value
        })

        // alert(JSON.stringify(tmp))
        this.props.change_room_support_chat_template_list(room_support_chat_template_list)

        
        this.setState({ reflesh: !this.state.reflesh })
        
    }

    onAddSelect(id, status, key, len){
        // alert(len)
        // alert(status)
        // alert(key)
        // alert("onAddSelect")
        // console.log(this.props.room_support_chat_template_list)
        // console.log("cccccccccccccccccccccccccc")
        let room_support_chat_template_list = this.props.room_support_chat_template_list
        
        // alert(key)
        let set_id = 0
        let set_status = 0
        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {
                
                
                let data_key = value["contents"][key]["actions"][len -1]["data"]
                // alert(data_key)
                set_id = data_key.split("-")[0]
                set_status = data_key.split("-")[1]
                
                // value["contents"][key]["actions"].push({"type":"postback","label":"","data": String(set_id) +  "-" + String(parseInt(set_status) + 1)})
                value["contents"][key]["actions"].splice(len, 0, {"type":"postback","label":"","data": String(set_id) +  "-" + String(parseInt(set_status) + 1)})
                
                value["contents_key"] = len
            }
            return value
        })



        let tmp = {id:parseInt(set_id), status:parseInt(set_status) + 1, room_support_chat_template_category_id:this.props.room_support_chat_template_category_id, name:"", editable:1, selected:0, main_outai_sub:0, sections:"", is_gyousya_tel:0, kaketsuke_main_outai_sub:0, next_room_support_chat_template_category_id:null, remarks:null, contents:[{"type":"text","text":"","color":"black","style":""}]}
        
        // alert(JSON.stringify(tmp))
        this.props.change_room_support_chat_template_list(room_support_chat_template_list.concat(tmp))

        
        this.setState({ reflesh: !this.state.reflesh })
        
    }

    onAddSelectRe(id, status, key, pre_id, pre_status){

        // alert(key)

        let room_support_chat_template_list = this.props.room_support_chat_template_list
        
        // alert(key)
        let set_id = 0
        let set_status = 0
        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {

                
                    let data_key = value["contents"][key]["actions"][value["contents"][key]["actions"].length -1]["data"]
                    // alert(data_key)
                    set_id = data_key.split("-")[0]
                    set_status = data_key.split("-")[1]
                
                // value["contents"][key]["actions"].push({"type":"postback","label":"前の質問に戻る","data": String(pre_id) +  "-" + String(parseInt(pre_status))})
                value["contents"][key]["actions"].push({"type":"postback","label":"前の質問に戻る","data": "back"})
                value["contents_key"] = value["contents"][key]["actions"].length-1
            }
            return value
        })



        // let tmp = {id:parseInt(set_id), status:parseInt(set_status) + 1, room_support_chat_template_category_id:this.props.room_support_chat_template_category_id, name:"", contents:[{"type":"text","text":"","color":"black","style":""}]}
        
        // this.props.change_room_support_chat_template_list(room_support_chat_template_list.concat(tmp))

        
        this.setState({ reflesh: !this.state.reflesh })
        
    }

    onDeleteSelect(id, status, key, contents_key, label){
        // alert("onDeleteSelect")
        // alert(id)
        // alert(status)
        // alert(key)
        // alert(contents_key)


        let room_support_chat_template_list = this.props.room_support_chat_template_list
        
        let data_tmp = ""
        let label_tmp = ""
                
        // 削除するレコードのdataとlabelを保存
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {
                data_tmp = value["contents"][key]["actions"][contents_key]["data"]
                label_tmp = value["contents"][key]["actions"][contents_key]["label"]
                value["contents"][key]["actions"].splice(contents_key, 1)
            }
            return value
        })
        
        if(label_tmp != "前の質問に戻る" && data_tmp != ""){
            fncDeleteSelect(this.props.room_support_chat_template_list, data_tmp.split("-")[0], data_tmp.split("-")[1])
        }

        
        // console.log("delete_list")
        // console.log(delete_list)

        for(let i = 0; i < delete_list.length; i++){
            let result = room_support_chat_template_list.filter((a) => {
                return !(a.id == delete_list[i].split("-")[0] && a.status == delete_list[i].split("-")[1]);
            });
    
            room_support_chat_template_list = result
        }
        // console.log(room_support_chat_template_list)
        delete_list=[]
        this.props.change_room_support_chat_template_list(room_support_chat_template_list)


        this.setState({ reflesh: !this.state.reflesh })
        
    }

    
    
    onAddSelectNext(data){ // 使ってない
        // alert("onAddSelectNext")
        let room_support_chat_template_list = this.props.room_support_chat_template_list

        // let max_id = Math.max.apply(null,room_support_chat_template_list.map(function(o){return o.id;}))
        
        // alert(data)
        let tmp = {id:parseInt(data.split("-")[0]), status:parseInt(data.split("-")[1]), room_support_chat_template_category_id:this.props.room_support_chat_template_category_id, name:"ああ", contents:[{"type":"text","text":"","color":"black","style":""}]}
        
        // alert(JSON.stringify(tmp))
        this.props.change_room_support_chat_template_list(room_support_chat_template_list.concat(tmp))
        
        this.setState({ reflesh: !this.state.reflesh })
        
    }

    onChangeDisp(id, status){ 
        // alert("onAddSelectNext")
        let room_support_chat_template_list = this.props.room_support_chat_template_list

        // 一覧の情報更新
        room_support_chat_template_list = room_support_chat_template_list.map(function (value) {
            if (value.id == id && value.status === status) {

                value.disp = value.disp == null || value.disp == true ? false : true
            // alert(value.disp)
            }
            return value
        })

        this.props.change_room_support_chat_template_list(room_support_chat_template_list)
        
        this.setState({ reflesh: !this.state.reflesh })
        
    }

    render() {
        let type = ""
        return (

            <Card style={{backgroundColor:"#f5f5f5", display:'inline-block', marginLeft:15, marginTop:5, marginRight:15}}>
                {this.props.card_data.contents.length > 0 ? <CardContent>
                    {/* {this.props.card_data.name} */}
                    <Card style={{marginBottom:5, width:300}}><CardContent>
                        <TextField size="small" fullWidth value={this.props.card_data.name} id="search" onChange={(e) => this.onChangeName(e, this.props.card_data.id, this.props.card_data.status)} label="タイトル" name="search" variant="outlined" InputLabelProps={{ shrink: true }} />
                        {/* <Tooltip title={"リクエストに追加"}> */}
                            <FormControlLabel label="自動応答終了" style={{ marginLeft:0, marginTop:10}} control={<Checkbox name="selected" value={true} checked={this.props.card_data.selected == 1 ? true : false} onChange={(e) => this.onChangeSelected(e, this.props.card_data.id, this.props.card_data.status)} style={{ transform: "scale(1.0)" }} color="primary" />} />
                        {/* </Tooltip> */}
                        
                        
                        {this.props.card_data.selected == 1 ? <FormControl style={{ width: 100 }}>
                            <InputLabel  id="demo-simple-select-label">編集</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="editable"
                            value={this.props.card_data.editable}
                            style={{marginLeft:5}}
                            label="編集"
                            onChange={(e) => this.onChangeHensyu(e, this.props.card_data.id, this.props.card_data.status)}
                        >
                            <MenuItem value={0} >編集可({0})</MenuItem>
                            {this.props.card_data.selected == 0 ? <MenuItem value={1} >上記手順({1})</MenuItem>:""}
                            {/* <MenuItem value={1} >上記手順</MenuItem> */}
                            <MenuItem value={2} >完了({2})</MenuItem>
                        </Select>
                        </FormControl>:""}

                        {this.props.card_data.selected == 1 ? <FormControl style={{ width: 200, marginLeft:5, marginBottom:5 }}>
                            <InputLabel  id="demo-simple-select-label">フリー入力に対する返事</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="next_room_support_chat_template_category_id"
                                value={this.props.card_data.next_room_support_chat_template_category_id}
                                style={{marginLeft:5}}
                                label="フリー"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => this.onChangeFree(e, this.props.card_data.id, this.props.card_data.status)}

                            >

                                    <MenuItem value={null} >-</MenuItem>
                                {
                                    this.props.room_support_chat_template_category_list.filter(a=>Number(a.id) != Number(this.props.room_support_chat_template_category_id) && Number(a.id) != 1).map(function (value_cate) {
                                        return <MenuItem value={value_cate.id} >{value_cate.id}：{value_cate.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>:""}

                        {this.props.card_data.selected == 1 ? <FormControl style={{ width: 200, marginLeft:5 }}>
                            <InputLabel  id="demo-simple-select-label">応対種別</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="main_outai_sub"
                                value={this.props.card_data.main_outai_sub}
                                style={{marginBottom:10, marginLeft:5}}
                                label="応対種別"
                                // variant="filled"
                                // size="small"
                                // disabled={this.props.card_data.selected == 1 ? false:true}
                                // error = {this.props.card_data.selected == 1 && this.props.card_data.main_outai_sub == 0 ? true:false }
                                onChange={(e) => this.onChangeOutai(e, this.props.card_data.id, this.props.card_data.status)}
                            >
                                <MenuItem value={0} >-</MenuItem>
                                {
                                    this.props.tm_outai_sub_list && this.props.tm_outai_sub_list.map(function (value) {
                                        return <MenuItem value={value.id} > {value.symbol}{value.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>:""}

                        {this.props.card_data.selected == 1 ? <FormControl style={{ width: 200, marginLeft:5 }}>
                            <InputLabel  id="demo-simple-select-label">対応部署</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="sections"
                                // value={this.props.card_data.sections ?this.props.card_data.sections : ""}
                                value={this.props.card_data.sections ? this.props.card_data.sections.split(',') : []}
                                style={{marginBottom:10, marginLeft:5}}
                                label="対応部署"
                                onChange={(e) => this.onChangeShops(e, this.props.card_data.id, this.props.card_data.status)}

                                multiple={true}
                                renderValue={()=>this.props.card_data.sections && this.props.card_data.sections.split(',').length > 0 ? this.props.card_data.sections.split(',').map(function (item) {
                                    return parseInt(item) > 0 ? this.props.shops.filter(a => a.tenpoNo === parseInt(item))[0].tenpoName : ""
                                }, this).join(',') : ""}

                            >
                                {/* <MenuItem value={0} >-</MenuItem> */}
                                {/* {
                                    this.props.shops && this.props.shops.map(function (value) {
                                        return <MenuItem value={value.tenpoNo} > {value.tenpoName}</MenuItem>
                                    })
                                } */}
                                {/* <MenuItem value={4}><Checkbox checked={this.props.card_data.sections.indexOf(4) > -1} /><ListItemText primary={"C"} /></MenuItem> */}

                                {this.props.shops.filter(a=>a.tenpoNo > 0).sort((a, b) => a['tenpoNo'] - b['tenpoNo']).map(function (item, i) {
                                    return (
                                        // <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                        <MenuItem value={item.tenpoNo}><Checkbox checked={this.props.card_data.sections && this.props.card_data.sections.split(',').indexOf(String(item.tenpoNo)) > -1} /><ListItemText primary={item.tenpoName} /></MenuItem>
                                    );

                                },this)}
        
                            </Select>
                        </FormControl>:""}
                        {this.props.card_data.selected == 1 ? 
                            <FormControl style={{ width: 200, marginLeft:5 }}>
                            <InputLabel  id="demo-simple-select-label">業者から連絡</InputLabel>
                            <Select
                                name="is_gyousya_tel"
                                value={this.props.card_data.is_gyousya_tel}
                                style={{marginBottom:10, marginLeft:5}}
                                label="業者から連絡"
                                onChange={(e) => this.onChangeIsGyousyaTel(e, this.props.card_data.id, this.props.card_data.status)}
                            >
                                <MenuItem value={0} >-</MenuItem>
                                <MenuItem value={1} >可</MenuItem>
                                <MenuItem value={2} >不可</MenuItem>
                            </Select>
                        </FormControl>
                            // <FormControlLabel 
                            //     label="業者から直接連絡可" 
                            //     style={{ margin:0}}
                            //     control={<Checkbox name="is_gyousya_tel" value={true} checked={this.props.card_data.is_gyousya_tel == 1 ? true : false} onChange={(e) => this.onChangeIsGyousyaTel(e, this.props.card_data.id, this.props.card_data.status)} style={{ transform: "scale(1.0)" }} color="primary" />} />
                        :""}
                        {this.props.card_data.selected == 1 ? 
                            <FormControl style={{ width: 200, marginLeft:5 }}>
                                <InputLabel  id="demo-simple-select-label">駆け付け応対種別</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="kaketsuke_main_outai_sub"
                                    value={this.props.card_data.kaketsuke_main_outai_sub}
                                    style={{marginBottom:10, marginLeft:5}}
                                    label="駆け付け応対種別"
                                    onChange={(e) => this.onChangeKaketsukeOutaiSub(e, this.props.card_data.id, this.props.card_data.status)}
                                >
                                    <MenuItem value={0} >-</MenuItem>
                                    {
                                        this.props.tm_outai_sub_list && this.props.tm_outai_sub_list.map(function (value) {
                                            return <MenuItem value={value.id} > {value.symbol}{value.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        :""}


                    </CardContent>
                    </Card>

                    {this.props.card_data.contents.length > 0 ? this.props.card_data.contents.map(function (value, key) {
                
                        return <div>
                            {this.props.card_data.next_room_support_chat_template_category_id > 0 && this.props.card_data.editable == 2 ? "" :<Tooltip title={"間に項目 追加"}><IconButton size="small" aria-label="add" onClick={() => this.onAdd(this.props.card_data.id, this.props.card_data.status, key -1 )}>
                            <AddCircleIcon style={{color:"orange"}} />
                        </IconButton></Tooltip>}

                            <Card style={{marginBottom:5, width: value["type"] != "select" ? 300 : this.props.card_data.disp == false ? 100 : null}}><CardContent>
                        {/* {value["type"] == "select" ? <IconButton size="small" style={{marginLeft:5}} onClick={() => this.onChangeDisp(this.props.card_data.id, this.props.card_data.status)}>
                            {this.props.card_data.disp}
                            {this.props.card_data.disp == false ? <ArrowDropDownIcon style ={{transform:"rotate(270deg)"}}/> : <ArrowDropDownIcon style ={{transform:"rotate(90deg)"}} />}
                        </IconButton>:""} */}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="type"
                            value={value["type"] == "denka" && value["actions"][0].room_support_chat_template_category_id == "177" ? "k_denka" : 
                            value["type"] == "denka" && value["actions"][0].room_support_chat_template_category_id == "180" ? "b_denka" : 
                            value["type"] == "denka" && value["actions"][0].room_support_chat_template_category_id == "291" ? "s_denka" : 
                            value["type"] == "select" && value["actions"][0].data == "back" ? "select_back" : 
                            value["type"]}
                            style={{marginBottom:10}}
                            onChange={(e) => this.onChangeType(e, this.props.card_data.id, this.props.card_data.status, key)}
                            disabled={this.props.card_data.next_room_support_chat_template_category_id > 0 && this.props.card_data.editable == 2}
                        >
                            <MenuItem value={"text"}>テキスト</MenuItem>
                            {this.props.card_data.selected != 1 ? <MenuItem value={"select"}>選択肢 {this.props.card_data.id}-{this.props.card_data.status}</MenuItem>:""}
                            <MenuItem value={"select_back"}>前の質問に戻る</MenuItem>
                            <MenuItem value={"tel"}>TEL</MenuItem>
                            <MenuItem value={"img"}>画像</MenuItem>
                            <MenuItem value={"movie"}>youtube</MenuItem>
                            <MenuItem value={"form"}>アプリ内ページ</MenuItem>
                            <MenuItem value={"link"}>リンク先</MenuItem>
                            <MenuItem value={"k_denka"}>キッチン給湯</MenuItem>
                            <MenuItem value={"b_denka"}>浴室給湯</MenuItem>
                            <MenuItem value={"s_denka"}>洗面給湯</MenuItem>
                            <MenuItem value={"kyouyoutou"}>共用灯</MenuItem>
                            <MenuItem value={"hurikomi"}>領収書振込先</MenuItem>
                            <MenuItem value={"net"}>インターネット</MenuItem>
                        </Select>
                        {this.props.card_data.contents.length > 1 ? <Tooltip title={"項目 削除"}><IconButton size="small" aria-label="delete" onClick={() => this.onDelete(this.props.card_data.id, this.props.card_data.status, key)}>
                            <RemoveCircleIcon color="primary" />
                        </IconButton></Tooltip> : ""}
                        {(() => {
                        type = value["type"]
                        if(value["type"] == "text"){
                            return <div>
                            <TextField disabled={this.props.card_data.next_room_support_chat_template_category_id > 0 && this.props.card_data.editable == 2}
                                fullWidth size="small" value={value["text"]} id="search" onChange={(e) => this.onChangeText(e, this.props.card_data.id, this.props.card_data.status, key, "text")} label="テキスト" multiline rows="2" name="search" variant="outlined" InputLabelProps={{ shrink: true }} />
                            <RadioGroup style={{marginLeft:10, marginTop:10, float:"left"}} row aria-label="position" name="color" onChange={(e) => this.onChangeText(e, this.props.card_data.id, this.props.card_data.status, key, "color")} value={value["color"] != "" && value["color"] != null ? value["color"] : "black"} >
                                <FormControlLabel disabled={this.props.card_data.next_room_support_chat_template_category_id > 0 && this.props.card_data.editable == 2} control={<Radio value={"black"} style={{color:"black", padding:0}} />} label="" />
                                <FormControlLabel disabled={this.props.card_data.next_room_support_chat_template_category_id > 0 && this.props.card_data.editable == 2} control={<Radio value={"red"} style={{color:"red", padding:0}} />} label="" />
                                <FormControlLabel disabled={this.props.card_data.next_room_support_chat_template_category_id > 0 && this.props.card_data.editable == 2} control={<Radio value={"blue"} style={{color:"blue", padding:0}} />} label="" />
                                <FormControlLabel disabled={this.props.card_data.next_room_support_chat_template_category_id > 0 && this.props.card_data.editable == 2} control={<Radio value={"green"} style={{color:"green", padding:0}} />} label="" />
                            </RadioGroup>
                            <FormControlLabel disabled={this.props.card_data.next_room_support_chat_template_category_id > 0 && this.props.card_data.editable == 2} label="太字" style={{ marginLeft:0, marginTop:0, float:"left"}} control={<Checkbox name="style" style={{transform: "scale(1.0)"}} value={true} checked={value["style"]  == "bold" ? true : false} onChange={(e) => this.onChangeText(e, this.props.card_data.id, this.props.card_data.status, key, "style")} color="primary" />} />
                            </div>
                        }else if(value["type"] == "img"){
                            return <div>
                            <TextField fullWidth disabled={this.props.card_data.next_room_support_chat_template_category_id > 0 && this.props.card_data.editable == 2}
                            size="small" value={value["img"]} id="search" onChange={(e) => this.onChangeText(e, this.props.card_data.id, this.props.card_data.status, key, "img")} label="ファイル名" name="search" variant="outlined" InputLabelProps={{ shrink: true }} />
                            </div>
                        }else if(value["type"] == "movie"){
                            return <div>
                            <TextField fullWidth disabled={this.props.card_data.next_room_support_chat_template_category_id > 0 && this.props.card_data.editable == 2}
                            size="small" value={value["youtube_id"]} id="search" onChange={(e) => this.onChangeText(e, this.props.card_data.id, this.props.card_data.status, key, "youtube_id")} label="youtube ID" name="search" variant="outlined" InputLabelProps={{ shrink: true }} />
                            </div>
                        }else if(value["type"] == "denka" && value["actions"][0].room_support_chat_template_category_id == "177"){
                            return <div>
                            </div>
                        }else if(value["type"] == "denka" && value["actions"][0].room_support_chat_template_category_id == "180"){
                            return <div></div>
                        }else if(value["type"] == "denka" && value["actions"][0].room_support_chat_template_category_id == "291"){
                            return <div></div>
                        }else if(value["type"] == "hurikomi"){
                            return <div></div>
                        }else if(value["type"] == "net"){
                            return <div></div>    
                        }else if(value["type"] == "kyouyoutou"){
                            return <div></div>
                        }else if(value["type"] == "link"){
                            return <div>
                            <TextField fullWidth disabled={this.props.card_data.next_room_support_chat_template_category_id > 0 && this.props.card_data.editable == 2} size="small" value={value["uri"]} id="search" onChange={(e) => this.onChangeText(e, this.props.card_data.id, this.props.card_data.status, key, "uri")} label="リンク先" name="search" variant="outlined" InputLabelProps={{ shrink: true }} />
                            <TextField fullWidth disabled={this.props.card_data.next_room_support_chat_template_category_id > 0 && this.props.card_data.editable == 2} size="small" value={value["text"]} id="search" onChange={(e) => this.onChangeText(e, this.props.card_data.id, this.props.card_data.status, key, "uri_name")} label="リンク名" name="uri_name" variant="outlined" InputLabelProps={{ shrink: true }} style={{marginTop:5}} />
                            </div>
                        }else if(value["type"] == "form"){
                            return <div>
                            <InputLabel id="demo-simple-select-outlined-label" style={{fontSize:11}}>リンク先</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="form"
                                value={value["form"]}
                                style={{marginBottom:10, width:200}}
                                onChange={(e) => this.onChangeText(e, this.props.card_data.id, this.props.card_data.status, key, "form")}
                                disabled={this.props.card_data.next_room_support_chat_template_category_id > 0 && this.props.card_data.editable == 2}
                            >
                                {
                                    this.props.room_support_chat_forms_list && this.props.room_support_chat_forms_list.map(function (value) {
                                        return <MenuItem value={value.id} > {value.name}</MenuItem>
                                    })
                                }
                            </Select>
                            </div>
                        }else if(value["type"] == "tel"){
                            return <div>
                                <TextField disabled={this.props.card_data.next_room_support_chat_template_category_id > 0 && this.props.card_data.editable == 2}
                                size="small" value={value["tel"]} id="search" onChange={(e) => this.onChangeText(e, this.props.card_data.id, this.props.card_data.status, key, "tel")} label="テキスト" name="search" variant="outlined" InputLabelProps={{ shrink: true }} />
                                </div>
                        }else if(value["type"] == "select"){
                            return <div>

                            {value["actions"].map(function (contents_value, contents_key) {
                                return String(this.props.card_data.contents_key) == String(contents_key) ? <Chip label={contents_value["label"]+"("+contents_value["data"]+")"} style={{backgroundColor:"#4caf50", color:"white"}} onClick={() => this.onSelectChip(this.props.card_data.id, this.props.card_data.status, key, contents_key)} /> : <Chip label={contents_value["label"]+"("+contents_value["data"]+")"} variant={"outlined"} onClick={() => this.onSelectChip(this.props.card_data.id, this.props.card_data.status, key, contents_key)} />
                            },this)}

                            <div>
                                {value["type"] == "select" && value["actions"][0].data == "back" ? "" :<Tooltip title={"選択肢 追加"}>
                                    <IconButton size="small" aria-label="add" style={{height:27}} onClick={() => this.onAddSelect(this.props.card_data.id, this.props.card_data.status, key, value["actions"].filter(a=>a["label"] !== "前の質問に戻る").length)}>
                                        <AddCircleIcon style={{height:21}} color="secondary" />
                                    </IconButton>
                                </Tooltip>}
                            {value["actions"].filter(a=>a["label"] == "前の質問に戻る").length == 0 ? <Tooltip title={"前の質問に戻る 追加"}>
                            <IconButton size="small" aria-label="add" style={{height:27}} onClick={() => this.onAddSelectRe(this.props.card_data.id, this.props.card_data.status, key, this.props.pre_card_data_id, this.props.pre_card_data_status)}>
                                <ArrowCircleUpIcon style={{ transform:"rotate(180deg)", color:"#4169e1", height:21}} />
                            </IconButton>
                            </Tooltip>:""}
                            </div>
                            

                            <div  style={{display:'flex'}}>
                                    

                            {value["actions"].map(function (contents_value, contents_key) {
// alert(JSON.stringify(this.props.card_data))
// alert(contents_value)
// alert(this.props.room_support_chat_template_list.length)
                            if(String(this.props.card_data.contents_key) == String(contents_key)){

                                return <div><Card style={{marginRight:10, backgroundColor:contents_value["label"] == "前の質問に戻る" ? "#e1f0fa" : ""}}>
                                     {/* {JSON.stringify(this.props.room_support_chat_template_list)} */}
                                        {value["type"] == "select" && value["actions"][0].data == "back" ? "" :<CardContent style={{minWidth:300}}>
                                            <TextField size="small" style={{width:240}} disabled = {contents_value["label"] == "前の質問に戻る" ? true : false} value={contents_value["label"]} onChange={(e) => this.onChangeLabel(e, this.props.card_data.id, this.props.card_data.status, key, contents_key)} id="search" label={"選択肢ラベル" + contents_value["data"]} name="search" variant="outlined" InputLabelProps={{ shrink: true }} />
                                            {value["actions"].filter(a=>a["label"] !== "前の質問に戻る").length > 1 || contents_value["label"] == "前の質問に戻る" ? <Tooltip title={"選択肢 削除"}><IconButton size="small" aria-label="delete" onClick={() => this.onDeleteSelect(this.props.card_data.id, this.props.card_data.status, key, contents_key, contents_value["label"])}>
                                                <RemoveCircleIcon color="secondary" />
                                            </IconButton></Tooltip>:""}
                                        </CardContent>}
                                        {/* {console.log("ggggggggggg")}
                                            {console.log(contents_value["data"])}
                                            {console.log(parseInt(contents_value["data"].split('-')[0]))}
                                            {console.log(parseInt(contents_value["data"].split('-')[1]))}
                                            {this.props.room_support_chat_template_list?console.log( this.props.room_support_chat_template_list.filter(a => a.id == 1).length):""} */}
                                        {this.props.room_support_chat_template_list && contents_value["label"] != "前の質問に戻る" ? <CardComponent 
                                        room_support_chat_template_list ={this.props.room_support_chat_template_list} 
                                        change_room_support_chat_template_list = {this.props.change_room_support_chat_template_list} 
                                        tm_outai_sub_list = {this.props.tm_outai_sub_list} 
                                        shops = {this.props.shops} 
                                        room_support_chat_forms_list = {this.props.room_support_chat_forms_list} 
                                        room_support_chat_template_category_list = {this.props.room_support_chat_template_category_list} 
                                        key={contents_key} 
                                        room_support_chat_template_category_id={this.props.room_support_chat_template_category_id} 
                                        pre_card_data_id = {this.props.card_data.id} 
                                        pre_card_data_status = {this.props.card_data.status} 
                                        card_data = {this.props.room_support_chat_template_list && this.props.room_support_chat_template_list.filter(a => a.id == parseInt(contents_value["data"].split('-')[0]) && a.status == parseInt(contents_value["data"].split('-')[1])).length > 0 ? this.props.room_support_chat_template_list.filter(a => a.id == parseInt(contents_value["data"].split('-')[0]) && a.status == parseInt(contents_value["data"].split('-')[1]))[0] : ""} />
                                        :""}

                                        {/* {contents_value["label"] != "前の質問に戻る" ? <CardComponent card_data = {
                                            this.props.room_support_chat_template_list && this.props.room_support_chat_template_list.filter(a => a.id == parseInt(contents_value["data"].split('-')[0]) && a.status == parseInt(contents_value["data"].split('-')[1])).length > 0 ? this.props.room_support_chat_template_list.filter(a => a.id == parseInt(contents_value["data"].split('-')[0]) && a.status == parseInt(contents_value["data"].split('-')[1]))[0] : ""} /> : ""} */}
                                    </Card>
                                    {/* <IconButton size="small" aria-label="add" onClick={() => this.onAddSelectNext(contents_value["data"])}>
                                        <AddCircleIcon color="secondary" />
                                    </IconButton> */}
                                    </div>
                            }else{
                                return ""
                            }
                            },this)}

                            
                            </div>
                        </div>
                            
                        }

                        })()}
                        </CardContent></Card>
                        {/* {type != "select" ? <Tooltip title={"項目 追加"}><IconButton size="small" aria-label="add" onClick={() => this.onAdd(this.props.card_data.id, this.props.card_data.status, key)}>
                            <AddCircleIcon style={{color:"orange"}} />
                        </IconButton></Tooltip>:""} */}
                        </div>
                        
                    },this) : ""}
                    {type != "select" && this.props.card_data.next_room_support_chat_template_category_id == null ? <Tooltip title={"項目 追加"}><IconButton size="small" aria-label="add" onClick={() => this.onAdd(this.props.card_data.id, this.props.card_data.status, this.props.card_data.contents.length-1)}>
                        <AddCircleIcon style={{color:"orange"}} />
                    </IconButton></Tooltip>:""}
                </CardContent >:""}
            </Card >

        );
    }
};


//export  default CustomerList;
const mapStateToProps = state => {
    return {
        // room_support_chat_template_list: state.scenario.room_support_chat_template_list,

        tm_outai_sub_list: state.scenario.tm_outai_sub_list,
        room_support_chat_template_category_list: state.scenario.room_support_chat_template_category_list,
        room_support_chat_forms_list: state.scenario.room_support_chat_forms_list,
        shops: state.masterReducer.shops,

    }
}
function mapDispatchToProps(dispatch) {
    return {

        change_room_support_chat_template_list(state) {
            dispatch(Scenario.change_room_support_chat_template_list(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardComponent);




