import React, { Component } from 'react';
import * as Adjustment from './Saga/Adjustment';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';

import './Css/Search.css';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import jaLocale from "date-fns/locale/ja";
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import NewRequest from "./NewRequest";
import { format } from 'date-fns'
import { CSVLink, CSVDownload } from "react-csv";

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }),
);

const styles = (theme: any) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton style={{ position: 'absolute' }} aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}

    </MuiDialogTitle>
  );
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const moment = require('moment')

// import DatePicker from "react-datepicker";
const kokyaku = [
  { label: "", key: "" },
  { label: "オーナー", key: "オーナー" },
  { label: "入居者", key: "入居者" },
  { label: "退去者", key: "退去者" },
  { label: "その他", key: "その他" },
];

const taiou = [
  { label: "", key: "" },
  { label: "応対中", key: "応対中" },
  { label: "完了", key: "完了" },
  { label: "一旦保留", key: "一旦保留" },
  { label: "業者対応完了", key: "業者対応完了" },
  { label: "全て", key: "全て" },
];

// const type = [
//   { value: "電話", label: "電話" },
//   { value: "メール", label: "メール" },
//   { value: "問い合せフォーム", label: "問い合せフォーム" },
//   { value: "UME", label: "UME" },
//   { value: "来店", label: "来店" },
//   { value: "くらしーど", label: "くらしーど" },
//   { value: "駆け付け", label: "駆け付け" },
// ]

var type = [

  { label: "電話", key: "電話" },
  { label: "メール", key: "メール" },
  { label: "UME", key: "UME" },
  { label: "来店", key: "来店" },
  { label: "シード", key: "シード" },
  { label: "駆付", key: "駆付" },
  { label: "留守電", key: "留守電" },
  { label: "アプリ", key: "アプリ" },
  { label: "Smarep", key: "Smarep" },

]

// csvカラム★
const headers = [
  { label: "カテゴリ", key: "setsubi_shubetsu_name" },
  { label: "区分", key: "syubetu_name" },
  { label: "応対No.", key: "Outai_No" },
  { label: "応対種別", key: "type" },
  { label: "受付日", key: "created_at" },
  { label: "最終対応", key: "lasts" },
  { label: "応対状況", key: "Outai_Joukyou_Name" },
  { label: "建物ID", key: "Tatemono_Id" },
  { label: "部屋ID", key: "Room_Id" },
  { label: "物件名", key: "Tatemono_Name" },
  { label: "部屋名", key: "Room_Name" },
  { label: "間取り", key: "Madori_Name" },
  { label: "部屋タイプ", key: "heya_type" },
  { label: "みのり", key: "omakase_name" },
  { label: "みのり(開始日)", key: "omakase_start" },
  { label: "オーナー担当", key: "Shoyuusha_Tantou_Name" },
  { label: "顧客区分", key: "Kokyaku_Kbn" },
  { label: "顧客名", key: "Kokyaku_Name" },
  { label: "受電者", key: "shokai_user" },
  { label: "連絡先", key: "Renraku_Saki" },
  { label: "案件タイトル", key: "Title" },
  { label: "案件内容", key: "Naiyou" },
  { label: "対応担当", key: "taiou_user" },
  { label: "業務課備考", key: "remarks" },
  { label: "初回訪問", key: "Syokai_Date" },
  { label: "初回時間", key: "syokai_time" },
  { label: "手配業者", key: "Gyosha_Name" },
  { label: "オーナー報告", key: "owner_houkoku" },
  { label: "次回訪問", key: "Next_Date" },
  { label: "手配業者", key: "Gyosha2_Name" },
  { label: "完了日", key: "complete_date" },
  { label: "受付日～完了日(日)", key: "complete_diff" },
  { label: "懸案", key: "is_kenan" },
  { label: "長期案件", key: "is_long" },
  { label: "契約NO.", key: "Keiyaku_No" },
  { label: "工事担当", key: "kouji_tantou" },
  { label: "工事なし家主補修", key: "none1" },
  { label: "工事なし家主都合", key: "none2" },
  { label: "工事なし他", key: "none3" },
  { label: "業者名", key: "Kouji_Gyousha" },
  { label: "原状回復", key: "is_kouji_type1" },
  { label: "設備変更", key: "is_kouji_type2" },
  { label: "間取り変更", key: "is_kouji_type3" },
  { label: "家主保留", key: "is_kouji_type4" },
  { label: "他", key: "is_kouji_type5" },
  { label: "家主選択", key: "is_kouji_type6" },
  { label: "家主選択", key: "is_kouji_type6" },
  { label: "完工日", key: "kanko_at" },
  { label: "入居前チェック", key: "Check_Kanryou_Date" },
  { label: "入居前チェック担当", key: "nkmc_user_name" },
  { label: "契約日", key: "Keiyaku_Date" },
  { label: "契約店舗", key: "Tenpo_Name" },
  { label: "契約担当", key: "keiyaku_user" },
  { label: "駆け付け", key: "is_kaketsuke" },
  { label: "請求対象", key: "is_seikyuu" },
  { label: "重要度", key: "importance" },
  { label: "アプリ", key: "app" },
];



// import DatePicker from "react-datepicker";
let timer: any
const { DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');

class JaLocalizedUtils extends DateFnsUtils {
  // ヘッダ部分のテキストを取得するメソッド
  getDatePickerHeaderText(date: Date) {
    return format(date, 'M月d日(E)', { locale: this.locale })
  }
}


interface Props {
  users_select: any,
  get_request_list: any,
  request_syubetsu: any,
  get_tatemono: any,
  get_gyousya: any,
  get_outaisub: any,
  search_request: any,
  uketuke_Date1: any,
  uketuke_Date2: any,
  syokai_Date1: any,
  syokai_Date2: any,
  saisyu_Date1: any,
  saisyu_Date2: any,
  jikai_Date1: any,
  jikai_Date2: any,
  kanryo_Date1: any,
  kanryo_Date2: any,
  title: any,
  bukken: any,
  naiyou: any,
  room: any,
  joukyou: any,
  kokyaku: any,
  main: any,
  kokyaku_name: any,
  judensya: any,
  tantou_owner: any,
  tantou_taiou: any,
  kaketuke_check: any,
  nyuukyo_check: any,
  tyouki_check: any,
  nozoku_check: any,
  kenan_check: any,
  app_check: any,
  app_apply_check: any,
  not_app_apply_check: any,
  air_con_check:any,
  tousya_hosyu_check: any,
  owner_hosyu_check: any,
  disp: any,
  onChangeuketukeDate1: any,
  onChangeuketukeDate2: any,
  onnowuketukeDate: any,
  onChangeusyokaiDate1: any,
  onChangeusyokaiDate2: any,
  onChangesaisyuDate1: any,
  onChangesaisyuDate2: any,
  onChangejikaiDate1: any,
  onChangejikaiDate2: any,
  onChangekanryoDate1: any,
  onChangekanryoDate2: any,
  onChangetitle: any,
  onChangebukken: any,
  onChangenaiyou: any,
  onChangeroom: any,
  onChangejoukyou: any,
  onChangekokyaku: any,
  onChangemain: any,
  onChangekokyaku_name: any,
  onChangejudensya: any,
  onChangetantou_owner: any,
  onChangetantou_taiou: any,
  onChangekaketuke_check: any,
  onChangenyuukyo_check: any,
  onChangetyouki_check: any,
  onChangekenan_check: any,
  onChangenozoku_check: any,
  onChangeapp_check: any,
  onChangeapp_apply_check: any,
  onChangenot_app_apply_check: any,
  onChangeair_con_check:any,
  onChangetousya_hosyu_check: any,
  onChangeowner_hosyu_check: any,
  req_search: any,
  search_clear: any,
  onChange_Renraku_Saki: any,
  Renraku_Saki: any,
  onChange_OutaiHoho_Biko: any,
  onChange_type: any,
  OutaiHoho_Biko: any,
  type: any,
  onChange_Kanryo_Naiyo: any,
  onChange_completed_title: any,
  request_list: any,
  request_list_csv: any,
  onChangepublished_check: any,
  published_check: any,
  kaketukenasi_check: any,
  onChangekaketukenashi_check: any
}


interface State {
  syousaikensaku: any,
  reqtoggle: boolean,
  open: boolean,
  Syokai_Date: any,
  Shokai_H: any,
  Shokai_M: any,
  csv_data: any
}


function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


var date1 = new Date();
var Hours = date1.getHours();
var Minutes = date1.getMinutes();
class KyousaiSearch extends Component<Props, State> {

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      syousaikensaku: "詳細検索を開く",
      reqtoggle: false,
      open: false,
      Syokai_Date: new Date(),
      Shokai_H: Hours,
      Shokai_M: Minutes,
      csv_data: []
    }


    this.open = this.open.bind(this);
    this.reqtoggle = this.reqtoggle.bind(this);
    this.complete_check = this.complete_check.bind(this);
    this.MultipleSelect = this.MultipleSelect.bind(this);

  }

  MultipleSelect() {
    const classes = useStyles();
    const theme = useTheme();
    const [personName, setPersonName] = React.useState<string[]>([]);

    const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
      console.log("~~~~~~~~")
      console.log(event.target.value)
      console.log(event.target)

      this.props.onChangemain(event.target.value);
      setPersonName(event.target.value as string[]);
    };
    return (
      <FormControl style={{ marginLeft: "50px" }} className={classes.formControl}>
        <InputLabel id="demo-mutiple-chip-label">メイン区分</InputLabel>
        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >

          {/* <option key={0}> </option>
          <option key={5656539} value={5656539}>設備全般①～45</option>
          <option key={5656114} value={5656114}>人的全般A～O</option>
          <option key={5656117} value={5656117}>入居時リクエスト(設備・人的)</option>
          {this.props.request_syubetsu.map((d: any) => (
            <option key={d.id} value={d.id}>
              {d.symbol}:{d.name}
            </option>
          ))} */}

          {this.props.request_syubetsu.map((name: any) => (
            <MenuItem key={name.id} value={name.id} style={getStyles(name.name, personName, theme)}>
              {name.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  complete_check() {
    window.open(`/completecheck`, '_blank', 'noopener')
  }

  open() {
    this.setState(prevState => ({
      open: !prevState.open,
    }));


    if (this.state.open == true) {
      this.setState({
        syousaikensaku: "詳細検索を開く",
      });
    } else {
      this.setState({
        syousaikensaku: "詳細検索を閉じる",
      });
    }

  }

  reqtoggle() {

    date1 = new Date();
    Hours = date1.getHours();
    Minutes = date1.getMinutes();

    // this.props.get_tatemono();
    this.props.get_gyousya();
    this.props.get_outaisub();
    this.setState({
      Syokai_Date: new Date(),
      Shokai_H: Hours,
      Shokai_M: Minutes,
    });
    this.setState({ reqtoggle: !this.state.reqtoggle })
  }

  render() {
    if (this.props.users_select[0]) {
      if (this.props.users_select[0]["label"] !== "担当なし") {
        this.props.users_select.unshift({ value: 1, label: "担当なし" })
      }
    }
    return (
      this.props.disp ?
        <div id="request_search">
          <Button onClick={this.reqtoggle} color="secondary" variant="outlined">リクエスト新規登録</Button>
          {/* <form onSubmit={handleSubmit}  > */}
          <table style={{ border: "0px" }}>

            <tbody>

              <tr style={{ border: "0px" }}>
                <td colSpan={1} style={{ border: "0px", width: 400 }}>
                  {/* <this.MultipleSelect /> */}
                  {/* 日付選択 */}
                  <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker
                      variant="inline"
                      margin="normal"
                      id="date-picker-dialog"
                      label="受付日"
                      format="yyyy/MM/dd"
                      style={{ width: 130 }}
                      value={this.props.uketuke_Date1}
                      onChange={this.props.onChangeuketukeDate1}
                      autoOk={true}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>

                  {/* 日付選択 */}
                  <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker

                      variant="inline"
                      margin="normal"
                      id="date-picker-dialog"
                      label="受付日"
                      format="yyyy/MM/dd"
                      style={{ width: 130 }}
                      value={this.props.uketuke_Date2}
                      onChange={this.props.onChangeuketukeDate2}
                      autoOk={true}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  <Button onClick={this.props.onnowuketukeDate} size="small" style={{ marginLeft: 20, marginTop: 20 }} variant="outlined" color="primary">
                    今日
                  </Button>
                </td>


                <td colSpan={1} style={{ border: "0px", width: 300 }}>
                  <td colSpan={1} style={{ border: "0px" }}>
                    <TextField
                      autoComplete='off'
                      value={this.props.bukken}
                      style={{ width: 200 }}
                      id="standard-basic"
                      onChange={this.props.onChangebukken}
                      label="物件名"
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          this.props.req_search();
                        }
                      }} />
                  </td>
                </td>


                <td colSpan={1} style={{ border: "0px" }}>
                  <TextField
                    autoComplete='off'
                    style={{ width: 200 }}
                    id="standard-basic"
                    onChange={this.props.onChangeroom}
                    label="部屋名"
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        this.props.req_search();
                      }
                    }} />



                  <Button style={{ marginLeft: 20 }} onClick={this.props.search_clear} variant="outlined">検索条件クリア</Button>
                </td>






              </tr>

              <tr style={{ border: "0px" }}>

                <td colSpan={1} style={{ border: "0px" }}>
                  <TextField
                    autoComplete='off'
                    style={{ width: 200 }}
                    onChange={this.props.onChangetitle}
                    id="standard-basic"
                    label="案件タイトル"
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        this.props.req_search();
                      }
                    }} />
                </td>


                <td colSpan={1} style={{ border: "0px" }}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={taiou}
                    defaultValue={{ label: "応対中", key: "応対中" }}
                    getOptionLabel={(option: any) => option.label}
                    style={{ width: 200 }}
                    onChange={this.props.onChangejoukyou}
                    renderInput={(params) => <TextField
                      autoComplete='off' {...params} label="対応状況" />}
                  />
                </td>

                <td colSpan={1} style={{ border: "0px" }}>

                  <FormGroup row>

                    <Autocomplete
                      id="combo-box-demo"
                      options={this.props.users_select}
                      getOptionLabel={(option: any) => option.label}
                      style={{ width: 200 }}
                      onChange={this.props.onChangetantou_taiou}
                      renderInput={(params) => <TextField
                        autoComplete='off' {...params} label="対応担当" />}
                    />



                    <span style={{ marginLeft: 30 }}>
                      <FormControlLabel
                        onChange={this.props.onChangeapp_check}
                        control={<Checkbox
                          color="primary"
                          name="yanushi_hoshu" />}

                        label="アプリリクエストのみ"
                      />
                    </span>


                    <span style={{ marginLeft: 30 }}>
                      <FormControlLabel
                        onChange={this.props.onChangeapp_apply_check}
                        control={<Checkbox
                          color="primary"
                          name="yanushi_hoshu" />}

                        label="申請のみ"
                        checked={this.props.app_apply_check}
                      />
                    </span>

                    <span style={{ marginLeft: 30 }}>
                      <FormControlLabel
                        onChange={this.props.onChangenot_app_apply_check}
                        control={<Checkbox
                          color="primary"
                          name="yanushi_hoshu" />}

                        label="申請を省く"
                        checked={this.props.not_app_apply_check}
                      />
                    </span>

                    <span style={{ marginLeft: 30 }}>
                      <FormControlLabel
                        onChange={this.props.onChangeair_con_check}
                        control={<Checkbox
                          color="primary"
                          name="yanushi_hoshu" />}

                        label="エアコン洗浄"
                        checked={this.props.air_con_check}
                      />
                    </span>
                  </FormGroup>




                </td>


              </tr>
              {(() => {
                if (this.state.open == true) {
                  return (
                    <tr style={{ border: "0px" }}>


                      <td colSpan={1} style={{ border: "0px" }}>
                        <TextField
                          autoComplete='off'
                          style={{ width: 200 }}
                          id="standard-basic"
                          onChange={this.props.onChangenaiyou}
                          label="案件内容"
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              this.props.req_search();
                            }
                          }} />
                      </td>

                      <td colSpan={1} style={{ border: "0px" }}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={this.props.users_select}
                          getOptionLabel={(option: any) => option.label}
                          style={{ width: 200 }}
                          onChange={this.props.onChangetantou_owner}
                          renderInput={(params) => <TextField
                            autoComplete='off' {...params} label="営業担当" />}
                        />
                      </td>


                      <td colSpan={1} style={{ border: "0px" }}>
                        {/* 日付選択 */}
                        <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                          <KeyboardDatePicker

                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="最終対応日"
                            format="yyyy/MM/dd"
                            style={{ width: 130 }}
                            value={this.props.saisyu_Date1}
                            onChange={this.props.onChangesaisyuDate1}
                            autoOk={true}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>


                        <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>

                        {/* 日付選択 */}
                        <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                          <KeyboardDatePicker

                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="最終対応日"
                            format="yyyy/MM/dd"
                            style={{ width: 130 }}
                            value={this.props.saisyu_Date2}
                            onChange={this.props.onChangesaisyuDate2}
                            autoOk={true}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </td>
                    </tr>
                  );
                }
              })()}

              {(() => {
                if (this.state.open == true) {
                  return (
                    <tr style={{ border: "0px" }}>

                      <td colSpan={1} style={{ border: "0px" }}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={kokyaku}
                          getOptionLabel={(option: any) => option.label}
                          style={{ width: 200 }}
                          onChange={this.props.onChangekokyaku}
                          renderInput={(params) => <TextField
                            autoComplete='off' {...params} label="顧客区分" />}
                        />
                      </td>

                      <td colSpan={1} style={{ border: "0px" }}>
                        <InputLabel htmlFor="age-native-helper">メイン区分</InputLabel>
                        <NativeSelect
                          style={{ fontSize: "10px", width: 200 }}
                          onChange={this.props.onChangemain}
                          inputProps={{
                            name: 'メイン区分',
                            id: 'uncontrolled-native',
                          }}
                        >
                          <option key={0}> </option>
                          <option key={5656539} value={5656539}>設備全般①～45</option>
                          <option key={5656114} value={5656114}>人的全般A～O</option>
                          <option key={5656117} value={5656117}>入居時リクエスト(設備・人的)</option>
                          {this.props.request_syubetsu.map((d: any) => (
                            <option key={d.id} value={d.id}>
                              {d.symbol}:{d.name}
                            </option>
                          ))}
                        </NativeSelect>
                      </td>
                      <td colSpan={1} style={{ border: "0px" }}>
                        {/* 日付選択 */}
                        <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                          <KeyboardDatePicker

                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="完了日"
                            format="yyyy/MM/dd"
                            style={{ width: 130 }}
                            value={this.props.kanryo_Date1}
                            onChange={this.props.onChangekanryoDate1}
                            autoOk={true}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>

                        <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>

                        {/* 日付選択 */}
                        <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                          <KeyboardDatePicker

                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="完了日"
                            format="yyyy/MM/dd"
                            style={{ width: 130 }}
                            value={this.props.kanryo_Date2}
                            onChange={this.props.onChangekanryoDate2}
                            autoOk={true}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </td>
                    </tr>

                  );
                }
              })()}




              {(() => {
                if (this.state.open == true) {
                  return (
                    <tr style={{ border: "0px" }}>
                      <td colSpan={1} style={{ border: "0px" }}>
                        <TextField
                          autoComplete='off'
                          style={{ width: 200 }}
                          id="standard-basic"
                          onChange={this.props.onChangekokyaku_name}
                          label="顧客名"
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              this.props.req_search();
                            }
                          }} />
                      </td>

                      <td colSpan={1} style={{ border: "0px" }}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={this.props.users_select}
                          getOptionLabel={(option: any) => option.label}
                          style={{ width: 200 }}
                          onChange={this.props.onChangejudensya}
                          renderInput={(params) => <TextField
                            autoComplete='off' {...params} label="受電者" />}
                        />
                      </td>



                      {/* 日付選択 */}
                      <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                        <KeyboardDatePicker

                          variant="inline"
                          margin="normal"
                          id="date-picker-dialog"
                          label="初回対応日"
                          format="yyyy/MM/dd"
                          style={{ width: 130 }}
                          value={this.props.syokai_Date1}
                          onChange={this.props.onChangeusyokaiDate1}
                          autoOk={true}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>

                      <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>

                      {/* 日付選択 */}
                      <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                        <KeyboardDatePicker

                          variant="inline"
                          margin="normal"
                          id="date-picker-dialog"
                          label="初回対応日"
                          format="yyyy/MM/dd"
                          style={{ width: 130 }}
                          value={this.props.syokai_Date2}
                          onChange={this.props.onChangeusyokaiDate2}
                          autoOk={true}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>


                    </tr>

                  );
                }
              })()}

              {(() => {
                if (this.state.open == true) {
                  return (
                    <tr style={{ border: "0px" }}>
                      <td colSpan={1} style={{ border: "0px" }}>
                        <TextField
                          autoComplete='off'
                          style={{ width: 200 }}
                          id="standard-basic"
                          onChange={this.props.onChange_Renraku_Saki}
                          label="連絡先"
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              this.props.req_search();
                            }
                          }} />
                      </td>

                      <td colSpan={1} style={{ border: "0px" }}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={type}
                          getOptionLabel={(option: any) => option.label}
                          style={{ width: 200 }}
                          onChange={this.props.onChange_type}
                          renderInput={(params) => <TextField
                            autoComplete='off' {...params} label="受付方法" />}
                        />
                      </td>

                      <td colSpan={1} style={{ border: "0px" }}>
                        <TextField
                          autoComplete='off'
                          style={{ width: 200 }}
                          id="standard-basic"
                          onChange={this.props.onChange_OutaiHoho_Biko}
                          label="応対方法備考"
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              this.props.req_search();
                            }
                          }} />
                      </td>



                    </tr>
                  );
                }
              })()}

              {(() => {
                if (this.state.open == true) {
                  return (
                    <tr style={{ border: "0px" }}>

                      <td colSpan={1} style={{ border: "0px" }}>
                        <TextField
                          autoComplete='off'
                          style={{ width: 200, marginTop: 15 }}
                          id="standard-basic"
                          onChange={this.props.onChange_completed_title}
                          label="完了タイトル"
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              this.props.req_search();
                            }
                          }} />
                      </td>

                      <td colSpan={1} style={{ border: "0px" }}>
                        <TextField
                          autoComplete='off'
                          style={{ width: 200, marginTop: 15 }}
                          id="standard-basic"
                          onChange={this.props.onChange_Kanryo_Naiyo}
                          label="完了結果"
                          onKeyPress={e => {
                            if (e.key === 'Enter') {
                              this.props.req_search();
                            }
                          }} />
                      </td>

                    </tr>
                  );
                }
              })()}


              {(() => {
                if (this.state.open == true) {
                  return (
                    <tr style={{ border: "0px" }}>

                      <td colSpan={3} style={{ border: "0px" }}>
                        <FormGroup row>
                          <FormControlLabel
                            onChange={this.props.onChangekaketuke_check}
                            control={<Checkbox
                              color="primary"
                              name="tousha_hoshu" />}

                            label="駆け付けのみ"
                          />
                          <FormControlLabel
                            onChange={this.props.onChangekaketukenashi_check}
                            control={<Checkbox
                              color="primary"
                              name="tousha_hoshu" />}

                            label="駆け付けなし"
                          />
                          <FormControlLabel
                            onChange={this.props.onChangenyuukyo_check}
                            control={<Checkbox
                              color="primary"
                              name="yanushi_hoshu" />}

                            label="入居時リクエストのみ"
                          />
                          <FormControlLabel
                            onChange={this.props.onChangetousya_hosyu_check}
                            control={<Checkbox
                              color="primary"
                              name="yanushi_hoshu" />}

                            label="入居時リクエスト(当社補修)のみ"
                          />
                          <FormControlLabel
                            onChange={this.props.onChangeowner_hosyu_check}
                            control={<Checkbox
                              color="primary"
                              name="yanushi_hoshu" />}

                            label="入居時リクエスト(オーナー補修)のみ"
                          />
                          <FormControlLabel
                            onChange={this.props.onChangetyouki_check}
                            control={<Checkbox
                              color="primary"
                              name="yanushi_hoshu" />}

                            label="長期案件のみ"
                          />
                          <FormControlLabel
                            onChange={this.props.onChangenozoku_check}
                            control={<Checkbox
                              color="primary"
                              name="yanushi_hoshu" />}
                            label="入居時リクエスト/懸案事項/長期案件を除く"
                          />
                          <FormControlLabel
                            onChange={this.props.onChangekenan_check}
                            control={<Checkbox
                              color="primary"
                              name="yanushi_hoshu" />}

                            label="懸案事項"
                          />
                          <FormControlLabel
                            onChange={this.props.onChangepublished_check}
                            control={<Checkbox
                              color="primary"
                              name="yanushi_hoshu" />}
                            label="掲載案件のみ"
                          />
                        </FormGroup>
                      </td>
                    </tr>
                  );
                }
              })()}


            </tbody >
          </table >

          <div style={{ float: "right", marginBottom: "10px", }}>




            <Button
              style={{
                marginLeft: "50px"
              }}
              onClick={this.open} variant="outlined">{this.state.syousaikensaku}</Button>

            <Button onClick={this.complete_check} style={{ marginLeft: "20px" }} variant="outlined">
              <span style={{ color: "blue" }}><i className="fas fa-external-link-alt"></i> 完了リクエスト一覧</span>
            </Button>

            {this.props.request_list.length ?
              <Button style={{ marginLeft: "20px" }} variant="outlined">
                <CSVLink style={{ textDecoration: "none" }} headers={headers} filename={"リクエスト一覧.csv"} data={this.props.request_list_csv}>CSV</CSVLink>
              </Button> : ""}

            <Button
              style={{
                marginLeft: "20px"
              }}
              onClick={this.props.req_search}
              variant="outlined" color="primary">
              検索
            </Button>
          </div>




          < Modal isOpen={this.state.reqtoggle} toggle={this.reqtoggle} size="request" fade={false} backdrop={false}>
            {/* <ModalHeader toggle={this.reqtoggle}>リクエスト新規登録</ModalHeader> */}
            <DialogTitle id="customized-dialog-title" onClose={this.reqtoggle} >リクエスト新規登録</DialogTitle>

            <NewRequest
              req_search={this.props.req_search}
              reqtoggle={this.reqtoggle}
              Syokai_Date={this.state.Syokai_Date}
              Shokai_H={this.state.Shokai_H}
              Shokai_M={this.state.Shokai_M}
            />
            <ModalFooter>
            </ModalFooter>
          </Modal >


        </div > : <div style={{ height: 180 }}></div>
    );
  }
}




const mapStateToProps = (state: any) => {
  return {
    request_syubetsu: state.Adjustment.request_syubetsu,
    users_select: state.masterReducer.users_select,
    request_list: state.Adjustment.request_list,
    request_list_csv: state.Adjustment.request_list_csv,

  }
}
function mapDispatchToProps(dispatch: any) {
  return {
    get_request_list(state: any) {
      dispatch(Adjustment.get_request_list(state))
    },
    get_tatemono(state: any) {
      dispatch(Adjustment.get_tatemono(state))
    },
    get_gyousya(state: any) {
      dispatch(Adjustment.get_gyousya(state))
    },
    get_outaisub(state: any) {
      dispatch(Adjustment.get_outaisub(state))
    },
    search_request(state: any) {
      dispatch(Adjustment.search_request(state))
    },
  };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(KyousaiSearch));
