import React, { Component } from 'react';
import * as Line from './Saga/Line';
import { connect } from 'react-redux';
// import DatePicker from "react-datepicker";
//import { actionCreators } from '../../Store/MailData';
// import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// import "Components/RoomList2/node_modules/Components/RoomList_/node_modules/react-datepicker/dist/react-datepicker.css";
import Fab from '@material-ui/core/Fab';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
// import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Modal, Row, Col, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// import RoomSearchListGridComponent from '../RoomList/Index';
import PublishIcon from '@material-ui/icons/Publish';
//import { actionCreators } from '../../Store/WeatherForecasts';
// import Select from 'react-select';
// import { reduxForm } from 'redux-form';
import './Css/Table.css';
import './Css/Form.css';
// import * as AutoKana from 'vanilla-autokana';
// let autokana1;
let Bosyuflg;


// const FIELDS = {
//     name1: 'name1',
//     kana1:'kana1',
// };

// redux-form 用バリデーション関数
// const validate = (values) => {

//     // 検証に失敗したら values と同じキーで
//     // エラーメッセージをセットする。
//     const errors = {};



//     return errors;
// };

// const RenderSelectInput = ({ input, options, name, id }) => (

//     <Select
//         {...input}
//         id={id}
//         name={name}
//         options={options}
//         value={input.value}
//         onChange={(a) => input.onChange(a ? a.value : 0)}
//         onBlur={(a) => input.onBlur(a ? a.value : 0)}
//     // placeholder="店舗を選択"
//     />


// )

export class RenderField extends React.Component {

    render() {
        const { className, id, name, type, style, input, disabled, onBlur, meta: { touched, error } } = this.props;
        return (
            <div>
                {type === 'textarea' ?
                    <textarea id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style} {...input} type={type} disabled={disabled} />
                    : type === 'select' ?
                        <select id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled}>{this.props.children}</select>
                        :
                        <input id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled} />
                }
                {touched && error && <div className="text-danger">{error}</div>}
            </div>
        );
    }
};

// const ReduxFormDateRange = (props) => {


//     console.log(props);
//     return (

//         <DatePicker

//             selected={props.input.value !== "" ? new Date(props.input.value) : ""}
//             //selected={moment(props.input.value, "YYYY-MM-DD").format("YYYY-MM-DD") || null}
//             onChange={props.input.onChange}
//             //showTimeSelect
//             dateFormat="yyyy/MM/dd"
//             className={props.className}
//         />
//     )
// }

class LineFormComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSetRoomName: false,

            setShubetu0: true,
            setShubetu2: true,
            setShubetu7: true,
            setShubetu21: true,
            setBoshuOnly0: true,
            introduction_rooms_modal: true,
            setKubun1: true,
            message: "",
            row: 1,
        };

        Bosyuflg = 1;

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        //this.handleClick = this.handleClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle_introduction_rooms_modal = this.toggle_introduction_rooms_modal.bind(this);


        // this.props.initialize({});//フォームに初期値を反映させる
    }
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }





    onChange(e) {
        console.log(e.target.value);
        this.setState({ message: e.target.value, row: 4 });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.message !== "") {

            this.props.onSubmit({ message: this.state.message })
            this.setState({ message: "", row: 1 });
        }
    }

    componentDidMount() {
        // This method is called when the component is first added to the document
        this.ensureDataFetched();
        //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        this.ensureDataFetched();
    }

    ensureDataFetched() {
        //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        //console.log(startDateIndex)

        //this.props.requestWeatherForecasts(0);

    }

    toggle_introduction_rooms_modal() {
        this.setState({ introduction_rooms_modal: !this.state.introduction_rooms_modal })
    }
    render() {

        const { handleDate, handlerequestMoveDate, handleSetsubiSubmit, handleSubmit } = this.props;

        //moment.locale('ja')
        return (


            <form onSubmit={this.handleSubmit}  >


                <div className="searchform float-left">
                    {/* <Field
                        name="message"
                        id="message"
                        type="text"
                        placeholder=""
                        className="form-control"
                        component="input"
                    /> */}

                    <TextField
                        id="outlined-multiline-static"
                        //   label=""
                        multiline
                        rows={this.state.row}
                        defaultValue=""
                        style={{ width: "250px", marginTop: "0px", marginBottom: "0px" }}
                        onChange={this.onChange}
                        value={this.state.message}
                        //   className={classes.textField}
                        margin="normal"
                        variant="outlined"

                    />


                </div>
                {this.props.line_send ? <button
                    type="submit"

                    className="btn btn-primary"
                    style={{ margin: "5px" }}
                    disabled
                >送信</button> : <button
                    type="submit"
                    className="btn btn-primary m-10"
                    style={{ margin: "5px" }}
                >送信</button>}


                <Tooltip title="Add" aria-label="部屋選択" style={{ margin: "5px" }}>
                    <Fab color="primary" size="small" onClick={() => this.props.toggle_send(1)}>
                        <ShoppingCartIcon />
                    </Fab>
                </Tooltip>
                <Tooltip title="Add" aria-label="ファイル送信" style={{ margin: "5px" }}>
                    <Fab color="secondary" size="small" onClick={() => this.props.toggle_send(2)}>
                        <PublishIcon />
                    </Fab>
                </Tooltip>
                {/* <Modal isOpen={this.state.introduction_rooms_modal} toggle={this.toggle_introduction_rooms_modal} size="xxl" fade={false} zIndex={5000} >
                    {/* <Modal isOpen={this.state.modalsearch} zIndex={3000} toggle={this.modalsearch} size="xxl" fade={false}> */}
                {/* <ModalHeader toggle={this.toggle_introduction_rooms_modal}>物件検索</ModalHeader>
                <RoomSearchListGridComponent
                    rent_response_id={this.props.responseid} /> </Modal> */}







                <i class="fas fa-times-circle lineclose" onClick={this.props.get_line_close}></i>
            </form >
        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        // rentMMedeas: state.masterReducer.rentMMedeas,
        // rentAgents: state.masterReducer.rentAgents,
        // users: state.masterReducer.users_select,
        // shops: state.masterReducer.section_select,
        line_send: state.line.line_send,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        // toggle_line_send(state) {
        //     dispatch(Line.toggle_line_send(state))
        // }
    };
}

// LineFormComponent = reduxForm({
//     form: 'linesendform', // a unique identifier for this form
//     // validate//バリデーション
//     enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(LineFormComponent)

export default connect(mapStateToProps, mapDispatchToProps)(LineFormComponent);