import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';



const authentication = require('../../react-azure-adb2c2').default;

var moment = require('moment-timezone');

function createData(
    part: any,
    confirm_date: any,
    content: any,
    photo1: any,
    photo2: any,
) {
    return { part, confirm_date, content, photo1, photo2 };
}



interface Props {
    bicycle_detail: any
    
}

interface State {
    photo_load_flag: boolean,
    bloburl: any,
    bloburl_detail: any,
}

class BicycleDetail extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            photo_load_flag: false,
            bloburl: [],
            bloburl_detail: []
        }

        // this.images = this.images.bind(this);

    }

    async componentDidMount() {
        console.log(this.props)
    

        this.props.bicycle_detail.forEach((e: any, index: number) => {


            const token = authentication.getAccessToken();
            var url = "";
            url = process.env.REACT_APP_GOAPI_API_URL + `/bicycle/image/` + e.id;
    
            var bloburl
            fetch(url, {
                method: 'GET',
                headers: { 'Authorization': 'Bearer ' + token }
            }).then(response => response.blob()).then(images => {
                const a = document.createElement('a');
                a.download = e.id + ".jpg";
                a.href = URL.createObjectURL(images);
    
                bloburl = {
                    id: e.id,
                    url: a.href
                }
                var blobs = this.state.bloburl;
                if (blobs[index]) {
    
                } else {
                    blobs[index] = [];
                }
                blobs[index] = bloburl;
                this.setState({
                    bloburl: blobs
                })
                // alert(blobs.length)
                return a.href
            }).catch(error => {
                console.error('Error fetching the image:', error);
                return "";
            });
 
        })

    }


    render() {
      

        return (
            <div>
                <Grid container>
                    {this.state.bloburl.map((data: any, index: any) => (
                        <Grid item xs={3}>
                            <Card style={{ maxWidth: 300, marginBottom: 30 }}>
                                <CardHeader
                                    title={this.props.bicycle_detail[index].type == '1' ? "自転車" : this.props.bicycle_detail[index].type == '2' ? "バイク" : ""}
                                    subheader={this.props.bicycle_detail[index].updated_at ? "更新日：" + moment(this.props.bicycle_detail[index].updated_at).tz("utc").format('YYYY-MM-DD') : ""}
                                />
                        
                                <CardMedia
                                    component="img"
                                    height="194"
                                    image={data.url}
                                    alt="photo"
                                />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div >
        );
    }
};


const mapStateToProps = (state: any) => {
    return {
        bicycle_detail: state.BicycleList.bicycle_detail,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BicycleDetail);




