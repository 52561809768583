import React from 'react';

//import { Container } from 'reactstrap';
import Header from './HeaderComponent';
import LeftSide from './LeftSideComponent';
// import ResponseToastComponent from './ResponseToastComponent';
import firebase from "firebase";
import * as Mail from '../Mail/Saga/Mail';
// import * as Responses from '../ResponseDetail/Saga/Responses';
import moment from 'moment'
import './Css/icomoon/style.css';
import './Css/drawer.min.css';
import './Css/Site.css';
import './Css/fontawesome/css/all.min.css';
import { connect } from 'react-redux';
import MailEditorComponent from "../Mail/MailEditorComponent";
import LineComponent from "../Line/LineComponent"
import * as MessageList from '../MessageList/Saga/MessageList';


import Chat from "../Chat/Chat";
import Sms from "../Sms/Sms";
// import LimitComponent from "./LimitComponent";
import { Modal } from 'reactstrap';
import Draggable from 'react-draggable'; // The default
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import styled from 'styled-components'
interface IProps {
  children: any,
  user_details: any,
  shops: any,
  mail_new: number,
  mail_minimum: number,
  // limitmodal:boolean,
  editor_height: number,
  editor_width: number,
  change_mail_minimum: any,
  draggable: number,
  line_open_flg: boolean,
  chat_open_flg: boolean,
  sms_open_flg: boolean,
  pathname:any
  // change_responseid:any,
  // change_modalResponseDetail:any,
}

interface State {

  // state types
}
// width: ${(props: Props) => props.editor_width}px;
// height: ${(props: Props) => props.editor_height}px;
//  const MailRap  = styled.div`
// height:500px;
// width: ${(props: IProps) => props.editor_width}px;
// position:fixed;
// bottom: 0;
// right: 30px;
// z-index:1000;
// background:#FFFFFF;
// filter: drop-shadow(10px 10px 10px rgba(0,0,0,0.6));
// `;

let notify;


class LayoutComponent extends React.Component<IProps, State>  {

  constructor(props: Readonly<IProps>) {
    super(props)    
  }
  openResponse(id: number) {
    // this.props.change_responseid(id)
    // // this.props.change_modalResponseDetail(true)
  }

  notify = () => toast("Wow so easy !");

  render() {
    return (
      <div className={this.props.pathname.match(/demo/) ? "" : "app"}>

        <Header user_details={this.props.user_details} shops={this.props.shops} />
        {this.props.pathname.match(/demo/) ? "" : <LeftSide />}
        
        {this.props.user_details.id ? 
        <>
        <div id="container">
          {this.props.children}
        </div>
        {this.props.mail_new === 1 ? <Draggable enableUserSelectHack={false} disabled={this.props.draggable === 1 ? false : true}>
          <div id="mail" style={{ display: (this.props.mail_minimum === 0) ? "" : "none", width: this.props.editor_width, height: this.props.editor_height }}><MailEditorComponent /> </div></Draggable> : ""}

        <div id="mailminimum" style={{ display: (this.props.mail_new === 1 && this.props.mail_minimum === 1) ? "" : "none" }} onClick={() => this.props.change_mail_minimum(0)}> 新規メッセージ<span className="far fa-window-minimize float-right" /></div>

        {this.props.line_open_flg === true ? <div id="line"><LineComponent /> </div> : ""}
        {this.props.chat_open_flg ? <div id="chat"><Chat /> </div> : ""}
        {this.props.sms_open_flg ? <div id="sms"><Sms /> </div> : ""}
        <ToastContainer />
        {this.props.user_details.amSectionId ? <Firestore user_details={this.props.user_details} openResponse={this.openResponse} /> : ""}
        </>:""}

      </div>
    )
  }
};
//コンテナ
const mapStateToProps = (state: any) => {
  return {

    user_details: state.masterReducer.user_details,
    shops: state.masterReducer.shops,
    mail_new: state.mailReducer.mail_new,
    mail_minimum: state.mailReducer.mail_minimum,
    // limitmodal:state.commonReducer.limitmodal,
    editor_width: state.mailReducer.editor_width,
    editor_height: state.mailReducer.editor_height,
    draggable: state.masterReducer.draggable,
    line_open_flg: state.line.line_open_flg,

    chat_open_flg: state.chat.chat_open_flg,
    sms_open_flg: state.sms.sms_open_flg,
    pathname: state.router.location.pathname,
  }
}
function mapDispatchToProps(dispatch: any) {
  return {
    change_mail_minimum(state: number) {
      dispatch(Mail.change_mail_minimum(state))
    },
    //    change_responseid(state:number) {
    //     dispatch(Responses.change_responseid(state))
    // },
    // change_modalResponseDetail(state:boolean) {
    //     dispatch(Responses.change_modalResponseDetail(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);


interface FProps {

  user_details: any,
  openResponse: any,

}
class Firestore extends React.Component<FProps, State>  {
  async componentDidMount() {

    const db = firebase.firestore();
    // const query6 =db.collection("response").where("Timestamp",">",Math.floor( new Date().getTime() / 1000 )).where("RentContractId","==",0).where("MailAccountId","==",6);

    // let MailAccountId=0;
    // if (this.props.user_details.amSectionId == 1)
    // {
    //   MailAccountId = 1;
    // }
    // else if (this.props.user_details.amSectionId == 2)
    // {
    //   MailAccountId = 2;
    // }
    // else if (this.props.user_details.amSectionId == 3)
    // {
    //   MailAccountId = 3;
    // }
    // else if (this.props.user_details.amSectionId == 4)
    // {
    //   MailAccountId = 4;
    // }
    // else if (this.props.user_details.amSectionId== 6)
    // {
    //   MailAccountId = 5;
    // }
    // else if (this.props.user_details.amSectionId == 17)
    // {
    //   MailAccountId = 6;
    // }
    // else if (this.props.user_details.amSectionId == 11)
    // {
    //   MailAccountId = 11;
    // }
    // const db = firebase.firestore();



    // let query11
    // if(this.props.user_details.amSectionId==10 || this.props.user_details.amSectionId==26){
    //    query11 =db.collection("response").where("Timestamp",">",Math.floor( new Date().getTime() / 1000 ));



    // }else{
    //   query11 =db.collection("response").where("MailAccountId","==",MailAccountId).where("Timestamp",">",Math.floor( new Date().getTime() / 1000 ));

    // }

    // // query11.onSnapshot(function(snapshot) {
    // //   snapshot.docChanges().forEach(function(change) {
    //     // query11.onSnapshot(snapshot => {
    //     //   snapshot.docChanges().forEach(function(change) {
    //       query11.onSnapshot(function(snapshot) {
    //       snapshot.docChanges().forEach(function(change) {
    //   // snapshot.forEach(doc => {

    //      console.log(change.doc.data())
    //      if (change.type === 'added') {
    //       if(change.doc.data().MailAccountId!=8 && change.doc.data().MailAccountId!=9){//テナントマンスリー省く



    //        if(change.doc.data().RentContractId===0){


    //         let shopname="";
    //         if (change.doc.data().MailAccountId == 1)
    //         {
    //           shopname = "番町店";
    //         }
    //         else if (change.doc.data().MailAccountId == 2)
    //         {
    //           shopname = "レインボーロード店";
    //         }
    //         else if (change.doc.data().MailAccountId  == 3)
    //         {
    //           shopname = "高松駅前店";
    //         }
    //         else if (change.doc.data().MailAccountId  == 4)
    //         {
    //           shopname = "屋島店";
    //         }
    //         else if (change.doc.data().MailAccountId == 5)
    //         {
    //           shopname = "中央公園店";
    //         }
    //         else if (change.doc.data().MailAccountId  == 6)
    //         {
    //           shopname = "サンフラワー店";
    //         }
    //         else if (change.doc.data().MailAccountId  == 11)
    //         {
    //           shopname = "中央通り店";
    //         }



    //       let  info=<div>{change.doc.data().Subject}</div>
    //       if(change.doc.data().Status===4){
    //         info=<div><div  style={{color:"#000"}}>{change.doc.data().Subject==null?"":change.doc.data().Subject.slice(0,20)}</div><div>既読になりました</div><div  style={{fontWeight:"bold",color:"#000"}}>{change.doc.data().FromName}様</div><div  style={{color:"#000"}}>{moment(change.doc.data().Date).format("YYYY/MM/DD　hh:mm:ss")} </div><div  style={{color:"#000"}}>{shopname} </div></div>
    //       }else  if(change.doc.data().Status===1){
    //         // info=<div><div  style={{color:"#000"}}>{change.doc.data().Subject==null?"":change.doc.data().Subject.slice(0,20)}</div><div style={{fontWeight:"bold",color:"red"}}>メールを受信しました。</div><div  style={{fontWeight:"bold",color:"#000"}} >{change.doc.data().FromName} 様<ResponseToastComponent RentResponseId={change.doc.data().RentResponseId}/></div><div  style={{color:"#000"}}>{moment(change.doc.data().Date).format("YYYY/MM/DD　hh:mm:ss")} </div><div  style={{color:"#000"}}>{shopname} </div></div>

    //       }else{
    //         info=<div><div  style={{color:"#000"}}>{change.doc.data().Subject==null?"":change.doc.data().Subject.slice(0,20)}</div><div style={{fontWeight:"bold",color:"red"}}>反響メールを受信しました。</div><div  style={{fontWeight:"bold",color:"#000"}}>{change.doc.data().FromName} 様</div><div  style={{color:"#000"}}>{moment(change.doc.data().Date).format("YYYY/MM/DD　hh:mm:ss")} </div><div  style={{color:"#000"}}>{shopname} </div></div>

    //       }
    //  toast(info,{
    //   autoClose: false,
    //   draggable: true,
    //   //etc you get the idea

    //      })
    //     notify=change.doc.data();
    //     }
    //   }
    // }
    //   });
    // })










    // const line =db.collection("line").where("sectionId","==",this.props.user_details.amSectionId).where("timestamp",">",Math.floor( new Date().getTime() / 1000 )).orderBy("timestamp", "desc")
    // .onSnapshot(snapshot => {
    //   snapshot.docChanges().forEach(function(change) {
    //     if(change.doc.data().replytoken!=="globalcenter"){
    //     const infoline = <div><div  style={{color:"#000"}}>{change.doc.data().messageType==="text"?change.doc.data().messageText.slice(0,20):""}</div><div style={{fontWeight:"bold",color:"green"}}>LINEを受信しました。</div><div  style={{fontWeight:"bold",color:"#000"}}>{change.doc.data().name} 様</div></div>

    //     toast(infoline,{
    //       autoClose: false,
    //       draggable: true,
    //       //etc you get the idea

    //          })
    //         notify=change.doc.data();
    //       }
    //         },this)

    //       })



    const line = db.collection("line").where("sectionId", "==", this.props.user_details.amSectionId).where("timestamp", ">", Math.floor(new Date().getTime() / 1000)).orderBy("timestamp", "desc")
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(function (change) {
          if (change.doc.data().replytoken !== "globalcenter") {
            const infoline = <div><div style={{ color: "#000" }}>{change.doc.data().messageType === "text" ? change.doc.data().messageText.slice(0, 20) : ""}</div><div style={{ fontWeight: "bold", color: "green" }}>LINEを受信しました。</div><div style={{ fontWeight: "bold", color: "#000" }}>{change.doc.data().name} 様</div></div>

            toast(infoline, {
              autoClose: false,
              draggable: true,
              //etc you get the idea

            })
            notify = change.doc.data();
          }
        }, this)

      })

  }


  render() {
    return (
      <div></div>
    );
  }
}




