import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from "date-fns/locale/ja";
import Button from '@material-ui/core/Button';
import format from "date-fns/format";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as MessageList from './Saga/MessageList';
const moment = require('moment')


class ExtendedUtils extends DateFnsUtils {
    getCalendarHeaderText(date: any) {
        return format(date, "yyyy MMM", { locale: this.locale });
    }
    getDatePickerHeaderText(date: any) {
        return format(date, "MMMd日", { locale: this.locale });
    }
}


class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}


const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');

interface Props {
    search_flag: any,
    issei_sousin: any,
    search_customers: any,
    section_id: any,
    not_process_only_flag: any,
    not_process_only_flag_data: any,
    auto_complete_flag: any,
    auto_complete_flag_data: any,
    middle_text_flag: any,
    middle_text_flag_data: any,
    search_status_true: any,
    user_details: any
}



interface State {
    name: any,
    Tatemono_Name: any,
    selectedDate1: any,
    selectedDate2: any,
    tel: any,
    naiyou: any,
    sms_check: boolean,
    app_check: boolean
}

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            name: "",
            Tatemono_Name: "",
            selectedDate1: moment(new Date()).utc().add(-1, 'month').format("YYYY-MM-DD"),
            selectedDate2: null,
            tel: "",
            naiyou: "",
            sms_check: false,
            app_check: false,
        }
        this.onChangename = this.onChangename.bind(this);
        this.onChangeTatemono = this.onChangeTatemono.bind(this);
        this.onChangetel = this.onChangetel.bind(this);
        this.onChangenaiyou = this.onChangenaiyou.bind(this);

        this.onChangeDate1 = this.onChangeDate1.bind(this);
        this.onChangeDate2 = this.onChangeDate2.bind(this);
        this.search = this.search.bind(this);

    }


    onChangename = (e: any) => {
        this.setState({
            name: e.target.value
        });
    };

    onChangeTatemono = (e: any) => {
        this.setState({
            Tatemono_Name: e.target.value
        });
    };

    onChangetel = (e: any) => {
        this.setState({
            tel: e.target.value
        });
    };

    onChangenaiyou = (e: any) => {
        this.setState({
            naiyou: e.target.value
        });
    };

    onChangeDate1 = (date: any) => {
        this.setState({
            selectedDate1: new Date(date),
        });
        // setTimeout(() => {
        //     this.search();
        // }, 100)
    };
    onChangeDate2 = (date: any) => {
        this.setState({
            selectedDate2: new Date(date),
        });
        // setTimeout(() => {
        //     this.search();
        // }, 100)
    };

    onChangesms_check = (e: any) => {
        this.setState({
            sms_check: e.target.checked
        });
    };
    onChangeapp_check = (e: any) => {
        this.setState({
            app_check: e.target.checked
        });
    };

    search() {
        this.props.search_flag();
        var data = {
            name: this.state.name,
            Tatemono_Name: this.state.Tatemono_Name,
            selectedDate1: this.state.selectedDate1,
            selectedDate2: this.state.selectedDate2,
            tel: this.state.tel,
            naiyou: this.state.naiyou,
            sms_check: this.state.sms_check,
            app_check: this.state.app_check,
            section_id: this.props.section_id,
            not_process_only_flag_data: this.props.not_process_only_flag_data,
            middle_text_flag: this.props.middle_text_flag_data,
            auto_complete_flag: this.props.auto_complete_flag_data,
            user_id: this.props.user_details.id
        }

        console.log(data);
        this.props.search_status_true();
        this.props.search_customers(data);
    }


    render() {

        return (
            <div>
                <TextField
                    autoComplete='off'
                    id="standard-basic"
                    onChange={this.onChangename}
                    style={{ marginTop: "16px" }}
                    label="顧客名"
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            this.search();
                        }
                    }}
                />

                <TextField
                    autoComplete='off'
                    id="standard-basic"
                    onChange={this.onChangeTatemono}
                    style={{ marginLeft: "50px", marginTop: "16px" }}
                    label="建物名"
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            this.search();
                        }
                    }}
                />

                <TextField
                    autoComplete='off'
                    id="standard-basic"
                    onChange={this.onChangeTatemono}
                    style={{ marginLeft: "50px", marginTop: "16px" }}
                    label="部屋名"
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            this.search();
                        }
                    }}
                />


                {/* 日付選択 */}
                <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker

                        variant="inline"
                        margin="normal"
                        id="date-picker-dialog"
                        label="送受信日"
                        format="yyyy/MM/dd"
                        autoOk={true}
                        style={{ marginLeft: "50px" }}
                        onChange={this.onChangeDate1}
                        value={this.state.selectedDate1}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>

                <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>


                {/* 日付選択 */}
                <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker

                        variant="inline"
                        margin="normal"
                        id="date-picker-dialog"
                        label="送受信日"
                        format="yyyy/MM/dd"
                        autoOk={true}
                        style={{ marginLeft: "50px" }}
                        onChange={this.onChangeDate2}
                        value={this.state.selectedDate2}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>

                <FormControlLabel
                    style={{
                        marginLeft: 20, marginTop: 20
                    }}
                    onChange={this.props.not_process_only_flag}
                    control={<Checkbox
                        color="primary"
                        name="yanushi_hoshu" />}

                    label="未対応のみ"
                />

                {/* <FormControlLabel
                    style={{
                        marginLeft: 20, marginTop: 20
                    }}
                    onChange={this.props.auto_complete_flag}
                    control={<Checkbox
                        color="primary"
                        name="yanushi_hoshu" />}

                    label="自動完了も表示"
                />

                <FormControlLabel
                    style={{
                        marginLeft: 20, marginTop: 20
                    }}
                    onChange={this.props.middle_text_flag}
                    control={<Checkbox
                        color="primary"
                        name="yanushi_hoshu" />}

                    label="途中入力も表示"
                /> */}


                <table style={{ border: "0px" }}>

                    <tbody>
                        <tr style={{ border: "0px" }}>

                            <td colSpan={1} style={{ border: "0px" }}>
                                <Button
                                    style={{
                                        verticalAlign: "bottom",
                                        marginRight: "400px",
                                        float: "right"
                                    }}
                                    onClick={this.search}
                                    variant="outlined" color="primary">
                                    検索
          </Button>

                            </td>
                        </tr>
                    </tbody >
                </table >

            </div >

        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        user_details: state.masterReducer.user_details,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        search_customers(state: any) {
            dispatch(MessageList.search_customers(state))
        },
        search_status_true(state: any) {
            dispatch(MessageList.search_status_true(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




