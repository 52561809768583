import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './Css/RequestDetail.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns'
import moment from 'moment'
import { Row, Col } from 'reactstrap';
import * as RequestDetailSaga from './Saga/RequestDetail';
import { Table, Column, Cell } from 'fixed-data-table-2';


class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}

interface Props {
    request_detail: any,
    user_details: any,
    shinyouModaltoggle: any
}
interface State {
}

class CustomerList extends Component<Props, State> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
        }
        this.url_open = this.url_open.bind(this);
    }
    url_open(attention_id:any) {
        // var url = '/attentiondetail/' + attention_id;
        // window.open(url, '_blank')
    }
    render() {
        return (
            <div style={{ padding: 10, background: "#b0c4de" }} >
                <div className="item-outer col-lg-12">
                    <Card className="col-lg-12" >
                        <CardContent style={{ float: "left" }} className="col-lg-12">
                            {this.props.request_detail.shinyou.length ? <Table rowHeight={35} rowsCount={this.props.request_detail.shinyou.length} width={window.innerWidth / 1.7} height={500} headerHeight={40}>
                                <Column header={<Cell>名前</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }} onClick={()=>this.url_open(this.props.request_detail.shinyou[rowIndex].id)}>{this.props.request_detail.shinyou[rowIndex].name}</Cell>)} width={150} />
                                <Column header={<Cell>tel</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }} onClick={()=>this.url_open(this.props.request_detail.shinyou[rowIndex].id)}>{this.props.request_detail.shinyou[rowIndex].tel1}</Cell>)} width={150} />
                                <Column header={<Cell>内容</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} title={this.props.request_detail.shinyou[rowIndex].remarks} style={{ cursor: "pointer" }} onClick={()=>this.url_open(this.props.request_detail.shinyou[rowIndex].id)}>{this.props.request_detail.shinyou[rowIndex].remarks}</Cell>)} width={700} />

                            </Table> : ""}
                        </CardContent>
                    </Card>
                </div>
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        user_details: state.masterReducer.user_details,
        request_detail: state.Adjustment.request_detail,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);

