import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './Css/RequestDetail.css';
import Button from '@material-ui/core/Button';
import KanrenKoujiGrid from "./KanrenKoujiGrid";
import KanrenDenpyouGrid from "./KanrenDenpyouGrid";
import * as Sms from '../Sms/Saga/Sms';
import * as RequestDetailSaga from './Saga/RequestDetail';
import * as Keiyaku from '../Keiyaku/Saga/Keiyaku';
import moment from 'moment'



interface Props {
    request_detail: any
}
interface State {
}

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);

        this.url_open_irai = this.url_open_irai.bind(this);

    }


    url_open_irai() {
        var url = process.env.REACT_APP_HOMESTATION4_URL + '/manage/construction/construction/request?request_no=' + this.props.request_detail.detail[0].Outai_No + '&vendor_id=' + this.props.request_detail.detail[0].syokai_gyousha_no;
        window.open(url, '_blank', 'noopener')
    }

    render() {

        return (
            <div style={{ padding: 10, background: "#b0c4de" }} >
                <div className="item-outer col-lg-12">
                    <Card className="col-lg-12" >
                        <CardContent style={{ float: "left" }} className="col-lg-12">

                            <div style={{ float: "right" }}>
                                <Button onClick={this.url_open_irai} size="small" style={{ color: "white", backgroundColor: "#28a745", float: "left", margin: 5 }} variant="contained">
                                    工事見積
                            </Button>
                            </div>

                            <div>関連工事</div>
                            <div>
                                <KanrenKoujiGrid />
                            </div>

                            <div style={{ marginTop: 30 }}>関連伝票</div>
                            <div>
                                <KanrenDenpyouGrid />
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        request_detail: state.Adjustment.request_detail,
        user_details: state.masterReducer.user_details,
        sms_status: state.sms.sms_status,
        template_category_list: state.Keiyaku.template_category_list,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        send_message(state: any) {
            dispatch(Sms.send_sms_message(state))
        },
        syousai_insert(state: any) {
            dispatch(RequestDetailSaga.syousai_insert(state))
        },
        get_template(state: any) {
            dispatch(Keiyaku.get_template(state))
        },
        get_template_category_list(state: any) {
            dispatch(Keiyaku.get_template_category_list(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);

