import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { RouteComponentProps } from 'react-router-dom'
import { Route } from 'react-router';
import Layout from './Components/Layout/LayoutComponent';
import Adjustment from './Components/Adjustment/Index';
import NewRequestWindow from './Components/Adjustment/NewRequestWindow';
import Demo from './Components/Adjustment/demoIndex';
import DemoDetail from './Components/RequestDetail/demoIndex';
import RequestDetail from './Components/RequestDetail/Index';

import RentMoney from './Components/RentMoney/Index';

import Vacancy from './Components/Vacancy/Index';
import Keiyaku from './Components/Keiyaku/Index';
import MessageList from './Components/MessageList/Index';
import ContractsList from './Components/ContractsList/Index';

import CustomerLink from './Components/CustomerLink/Index';
import Calendar from './Components/Calendar/Index';

import Board from './Components/Board/Index';

import Video from './Components/Board/Video';

import Scenario from './Components/Scenario/Index';

import KizuCheckList from './Components/KizuCheckList/Index';
import KizuDetail from './Components/KizuDetail/Index';
import CompleteCheck from './Components/CompleteCheck/Index';
import RequestAnalysis from './Components/RequestAnalysis/Index';
import BicycleList from './Components/BicycleList/Index';
import BicycleDetail from './Components/BicycleDetail/Index';

const authentication = require('./react-azure-adb2c2').default;
export const Authentication = React.createContext(null)
interface Props extends RouteComponentProps<{}> {
  // 他の型定義
}

const App: React.FC = () => {

  return (

    <Layout >
      <Authentication.Provider value={authentication}>
        <Route path='/RentMoney' component={RentMoney} />
        <Route exact path='/Board' component={Board} />
        <Route exact path='/RequestAnalysis' component={RequestAnalysis} />
        <Route exact path='/Adjustment/:outai_no' component={Adjustment} />
        <Route exact path='/Adjustment' component={Adjustment} />
        <Route exact path='/NewRequest' component={NewRequestWindow} />
        <Route exact path='/NewRequest/:tatemono_id/:room_id' component={NewRequestWindow} />
        <Route exact path='/RequestDetail/:outai_no' component={RequestDetail} />
        <Route exact path='/' component={Adjustment} />
        <Route exact path='/demo' component={Demo} />
        <Route exact path='/demo/:outai_no' component={DemoDetail} />
        <Route exact path='/Vacancy' component={Vacancy} />
        <Route exact path='/Keiyaku' component={Keiyaku} />
        <Route exact path='/MessageList' component={MessageList} />
        <Route exact path='/ContractsList' component={ContractsList} />
        <Route exact path='/CustomerLink' component={CustomerLink} />
        <Route exact path='/Video' component={Video} />
        <Route exact path='/Calendar' component={Calendar} />
        <Route exact path='/Scenario' component={Scenario} />
        <Route exact path='/KizuCheckList' component={KizuCheckList} />
        <Route exact path='/KizuDetail' component={KizuDetail} />
        <Route exact path='/KizuDetail/:keiyaku_no' component={KizuDetail} />
        <Route exact path='/CompleteCheck' component={CompleteCheck} />
        <Route exact path='/BicycleList' component={BicycleList} />
        <Route exact path='/BicycleDetail' component={BicycleDetail} />
        <Route exact path='/BicycleDetail/:keiyaku_no' component={BicycleDetail} />
        </Authentication.Provider>

    </Layout>


  );
}
export default App;
