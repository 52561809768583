import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import Button from '@material-ui/core/Button';
// import { CSVLink, CSVDownload } from "react-csv";
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from "date-fns/locale/ja";
import format from "date-fns/format";
import * as Board from './Saga/Board';
import { CSVLink } from "react-csv";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Bar,BarChart,Cell, Text, PieChart, Pie, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

var moment = require('moment-timezone');
class ExtendedUtils extends DateFnsUtils {
    getCalendarHeaderText(date: any) {
        return format(date, "yyyy MMM", { locale: this.locale });
    }
    getDatePickerHeaderText(date: any) {
        return format(date, "MMMd日", { locale: this.locale });
    }
}
const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');


class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のあテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}

interface Props {
    get_entered_list_list: any,
    entered_list: any,
    search_status_true: any,
    search_status: any,
}



interface State {
    date1: any,
    date2: any,
    kosu: boolean,
    all:any
}




const date1 = new Date();
const year = date1.getFullYear();
const month = date1.getMonth() + 1;
var sdate = moment(year + "-" + month + "-01").add(-1, 'years').format('YYYY-MM-DD');
class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            date1: sdate,
            date2: new Date(),
            kosu: false,
            all:0
        }
        this.kosu = this.kosu.bind(this);
        this.search = this.search.bind(this);

    }


    kosu() {
        this.setState({ kosu: !this.state.kosu })
    }


    onchange_date1 = (date: any) => {
        this.setState({
            date1: new Date(date),
        });
    };

    onchange_date2 = (date: any) => {
        this.setState({
            date2: new Date(date),
        });
    };

    search() {

        this.props.search_status_true();

        if (this.state.kosu) {
            var data = {
                entered_at1: this.state.date1,
                entered_at2: this.state.date2,
                type: "not"
            }
        } else {
            var data = {
                entered_at1: this.state.date1,
                entered_at2: this.state.date2,
                type: "all"
            }
        }

        this.props.get_entered_list_list(data);
    }
    

    render() {
        if (this.props.search_status == true) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'auto';
        }
        return (
            <div>
                <div>
                    アプリ登録数の集計

                    
                    
                </div>
                <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker
                        variant="inline"
                        margin="normal"
                        id="date1"
                        format="yyyy/MM/dd"
                        style={{ width: 130 }}
                        value={this.state.date1}
                        onChange={this.onchange_date1}
                        autoOk={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>

                <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>

                <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker
                        variant="inline"
                        margin="normal"
                        id="date-picker-dialog"
                        format="yyyy/MM/dd"
                        style={{ width: 130 }}
                        value={this.state.date2}
                        onChange={this.onchange_date2}
                        autoOk={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>


             


                <FormControlLabel
                    onChange={this.kosu}
                    control={<Checkbox
                        color="primary"
                        name="kosu" />}
                    label="戸数で計算"
                />



                <Button
                    onClick={this.search}
                    size="small"
                    style={{ color: "white", backgroundColor: "#4169e1", margin: 1 }}
                    variant="contained">
                    検索
                            </Button>
                {this.props.entered_list[0] ?
                    <CSVLink
                        data={this.props.entered_list[0].csv}
                        filename={"my-file.csv"}
                        target="_blank"
                    >
                        <Button
                            size="small"
                            style={{ color: "white", backgroundColor: "#008000", margin: 1 }}
                            variant="contained">
                            CSV
                            </Button>
                    </CSVLink> : ""}
                <div>
                {this.props.entered_list[0] ? "総登録数(解約除く)" + this.props.entered_list[0].all + "人":""}
                </div>
                <BarChart width={window.innerWidth - 1450} height={190} data={this.props.entered_list}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="登録者数" fill="#8884d8" />
                </BarChart>
            </div>
        );

    };
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        entered_list: state.board.entered_list,
        search_status: state.board.search_status,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_entered_list_list(state: any) {
            dispatch(Board.get_entered_list_list(state))
        },
        search_status_true(state: any) {
            dispatch(Board.search_status_true(state))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);




