import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Search from "./Search";
import Grid from "./Grid";
import { Row, Col } from 'reactstrap';
import * as BicycleList from './Saga/BicycleList';


// import * as MesssageList from './Saga/MessageList';
var moment = require('moment-timezone');


interface Props {
    get_bicycle_registration_list: any
}

interface State {
    section_id: any,
    user_id: any,
    // tantou_id: any,
    Keiyaku_Date1: any,
    Keiyaku_Date2: any,
    tatemono_name: any,
    // room_name: any,
    // not_app: boolean
    check_at1: any,
    check_at2: any,
    contractor_name: any,
    // kouji_gl: boolean,
    // kouji_owner: boolean,
    // houkoku: boolean
}

const date1 = new Date();
const year = date1.getFullYear();
const month = date1.getMonth() + 1;
var sdate = moment(year + "-" + month + "-01").format('YYYY-MM-DD');


class Bicycle extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            section_id: null,
            user_id: null,
            // tantou_id: null,
            check_at1: null,
            // check_at1: sdate,
            check_at2: null,
            Keiyaku_Date1: null,
            Keiyaku_Date2: null,
            tatemono_name: "",
            // room_name: "",
            contractor_name: "",
            // not_app: false,
            // kouji_gl: false,
            // kouji_owner: false,
            // houkoku: false
        }
        // this.kouji_gl = this.kouji_gl.bind(this);
        // this.kouji_owner = this.kouji_owner.bind(this);
        // this.houkoku = this.houkoku.bind(this);

        // this.not_app = this.not_app.bind(this);
        this.search_change_names = this.search_change_names.bind(this);
        // this.search_change_section = this.search_change_section.bind(this);
        // this.search_change_user = this.search_change_user.bind(this);
        this.search_Keiyaku_Date1 = this.search_Keiyaku_Date1.bind(this);
        this.search_Keiyaku_Date2 = this.search_Keiyaku_Date2.bind(this);
        // this.search_change_tantou_id = this.search_change_tantou_id.bind(this);
        this.search = this.search.bind(this);

    }

    // kouji_gl = (e: any) => {
    //     this.setState({
    //         kouji_gl: e.target.checked
    //     });
    // };
    // kouji_owner = (e: any) => {
    //     this.setState({
    //         kouji_owner: e.target.checked
    //     });
    // };
    // houkoku = (e: any) => {
    //     this.setState({
    //         houkoku: e.target.checked
    //     });
    // };
    // not_app() {
    //     this.setState({ not_app: !this.state.not_app })
    // }

    search_change_names(e: any) {
        this.setState({ ...this.state, [e.target.id]: e.target.value });
    }
    // search_change_section(e: any, values: any) {
    //     if (values) {
    //         this.setState({
    //             section_id: values.tenpoNo
    //         });
    //     } else {
    //         this.setState({
    //             section_id: 0
    //         });
    //     }
    // }
    // search_change_user(e: any, values: any) {
    //     if (values) {
    //         this.setState({
    //             user_id: values.value
    //         });
    //     } else {
    //         this.setState({
    //             user_id: 0
    //         });
    //     }
    // }
    // search_change_tantou_id(e: any, values: any) {
    //     if (values) {
    //         this.setState({
    //             tantou_id: values.value
    //         });
    //     } else {
    //         this.setState({
    //             tantou_id: 0
    //         });
    //     }
    // }
    search_Keiyaku_Date1 = (date: any) => {
        this.setState({
            Keiyaku_Date1: new Date(date),
        });
    };
    search_Keiyaku_Date2 = (date: any) => {
        this.setState({
            Keiyaku_Date2: new Date(date),
        });

    };

    search_check_at1 = (date: any) => {
        this.setState({
            check_at1: new Date(date),
        });
    };
    search_check_at2 = (date: any) => {
        this.setState({
            check_at2: new Date(date),
        });
    };

    search() {
        setTimeout(() => {
            var data = {
                section_id: this.state.section_id,
                user_id: this.state.user_id,
                // tantou_id: this.state.tantou_id,
                check_at1: this.state.check_at1,
                check_at2: this.state.check_at2,
                Keiyaku_Date1: this.state.Keiyaku_Date1,
                Keiyaku_Date2: this.state.Keiyaku_Date2,
                tatemono_name: this.state.tatemono_name,
                // room_name: this.state.room_name,
                contractor_name: this.state.contractor_name,
                // kouji_gl: this.state.kouji_gl,
                // kouji_owner: this.state.kouji_owner,
                // houkoku: this.state.houkoku
            }

// alert("*****search*****");
// console.log("########", data);

            this.props.get_bicycle_registration_list(data);

        }, 100)
    }

    render() {
        return (
            <div className="row" style={{justifyContent: 'center', width: window.innerWidth - 60, minWidth: 737}}>
                <div className="item-outer" style={{ padding: 0 }}>
                    <Card style={{ marginLeft: 16, width: 350, height: window.innerHeight - 65, overflowY: 'auto' }}>
                        <CardContent>
                            <Search
                                search_check_at1={this.search_check_at1}
                                search_check_at2={this.search_check_at2}
                                search_change_names={this.search_change_names}
                                // search_change_section={this.search_change_section}
                                // search_change_user={this.search_change_user}
                                // search_change_tantou_id={this.search_change_tantou_id}
                                search_Keiyaku_Date1={this.search_Keiyaku_Date1}
                                search_Keiyaku_Date2={this.search_Keiyaku_Date2}
                                search={this.search}
                                Keiyaku_Date1={this.state.Keiyaku_Date1}
                                Keiyaku_Date2={this.state.Keiyaku_Date2}
                                // not_app={this.not_app}
                                check_at1={this.state.check_at1}
                                check_at2={this.state.check_at2}
                                tatemono_name={this.state.tatemono_name}
                                // room_name={this.state.room_name}
                                contractor_name={this.state.contractor_name}
                                // tantou_id={this.state.tantou_id}
                                // kouji_gl={this.kouji_gl}
                                // kouji_owner={this.kouji_owner}
                                // houkoku={this.houkoku}
                            />
                        </CardContent >
                    </Card >
                </div>
                <div className="item-outer" style={{ padding: 0 }}>
                    <Card style={{ padding: 0, height: window.innerHeight - 65 }}>
                        <CardContent >
                            <Grid />
                        </CardContent >
                    </Card >
                </div >
            </div >
        );
    }
};


const mapStateToProps = (state: any) => {
    return {
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_bicycle_registration_list(state: any) {
            dispatch(BicycleList.get_bicycle_registration_list(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Bicycle);




