import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from "date-fns/locale/ja";
import Button from '@material-ui/core/Button';
import format from "date-fns/format";
import Chip from '@material-ui/core/Chip';

const moment = require('moment')


class ExtendedUtils extends DateFnsUtils {
    getCalendarHeaderText(date: any) {
        return format(date, "yyyy MMM", { locale: this.locale });
    }
    getDatePickerHeaderText(date: any) {
        return format(date, "MMMd日", { locale: this.locale });
    }
}


class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}


const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');

interface Props {
    search_check_at1: any,
    search_check_at2: any,
    // shops: any,
    // users_select: any,
    search_change_names: any,
    // search_change_section: any,
    // search_change_user: any,
    search_Keiyaku_Date1: any,
    search_Keiyaku_Date2: any,
    // search_change_tantou_id: any,
    search: any,
    Keiyaku_Date1: any,
    Keiyaku_Date2: any,
    // not_app: any,
    search_status: any,
    check_at1: any,
    check_at2: any,
    tatemono_name: any,
    // room_name: any,
    contractor_name: any,
    bicycle_registration_list: any,
    // tantou_id: any,
    // kizu_check_list: any,
    // kouji_owner: any,
    // kouji_gl: any,
    // houkoku:any
}



interface State {
}

class BicycleSearchList extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
    }
    render() {

        if (this.props.search_status == true) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'auto';
        }


        return (
            <div>
                <div style={{ fontSize: 15, fontWeight: 'bold' }}>所有自転車登録確認</div>

                <div style={{textAlign: 'right'}}>
                <Chip label={(this.props.search_status == true ? 0 : this.props.bicycle_registration_list.length) + "件"} style={{ marginTop: 15, fontSize: 14 }} variant="outlined" />
                <Button
                    onClick={this.props.search}
                    style={{ color: "white", backgroundColor: "#43BBBB", marginTop: 15, marginLeft: 10 }}
                    variant="contained">
                    検索
                </Button>
                </div>

                {/* <div>
                    <FormControlLabel
                        style={{ marginTop: 10 }}
                        onChange={this.props.houkoku}
                        control={<Checkbox
                            color="primary"
                            name="yanushi_hoshu" />}

                        label="報告ありのみ"
                    />
                </div> */}

                {/* 登録日1 */}
                <div style={{display: 'flex'}}>
                    <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                        <KeyboardDatePicker
                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="登録日"
                            format="yyyy/MM/dd"
                            style={{ width: 128, marginTop: 30, marginRight: 20 }}
                            value={this.props.check_at1}
                            onChange={this.props.search_check_at1}
                            autoOk={true}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <div style={{marginTop: 45, textAlign: 'center'}}>～</div>
                    {/* <i className="fas fa-long-arrow-alt-down" style={{ verticalAlign: "bottom", marginLeft: 20, marginTop: 10, fontSize: 20 }}></i> */}
                
                    {/* 登録日2 */}
                    <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                        <KeyboardDatePicker

                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="登録日"
                            format="yyyy/MM/dd"
                            style={{ width: 128, marginTop: 30, marginLeft: 20 }}
                            value={this.props.check_at2}
                            onChange={this.props.search_check_at2}
                            autoOk={true}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </div>

                {/* 契約日1 */}
                <div style={{display: 'flex'}}>
                    <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                        <KeyboardDatePicker
                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="契約日"
                            format="yyyy/MM/dd"
                            style={{ width: 128, marginTop: 30, marginRight: 20 }}
                            value={this.props.Keiyaku_Date1}
                            onChange={this.props.search_Keiyaku_Date1}
                            autoOk={true}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <div style={{marginTop: 45, textAlign: 'center'}}>～</div>                
                    {/* <i className="fas fa-long-arrow-alt-down" style={{ verticalAlign: "bottom", marginLeft: 20, marginTop: 10, fontSize: 20 }}></i> */}
                
                {/* 契約日2 */}
                    <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                        <KeyboardDatePicker
                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="契約日"
                            format="yyyy/MM/dd"
                            style={{ width: 128, marginTop: 30, marginLeft: 20 }}
                            value={this.props.Keiyaku_Date2}
                            onChange={this.props.search_Keiyaku_Date2}
                            autoOk={true}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </div>


                {/* 物件名 */}
                <TextField
                    autoComplete='off'
                    value={this.props.tatemono_name}
                    style={{ width: 150, marginTop: 20, margin: 5 }}
                    id="tatemono_name"
                    onChange={this.props.search_change_names}
                    label="物件名"
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            this.props.search();
                        }
                    }}
                />
                {/* <TextField
                    autoComplete='off'
                    value={this.props.room_name}
                    style={{ width: 150, marginTop: 20, margin: 5 }}
                    id="room_name"
                    onChange={this.props.search_change_names}
                    label="部屋号室"
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            this.props.search();
                        }
                    }}
                /> */}
                <TextField
                    autoComplete='off'
                    value={this.props.contractor_name}
                    style={{ width: 150, marginTop: 20, margin: 5 }}
                    id="contractor_name"
                    onChange={this.props.search_change_names}
                    label="契約者名"
                    onKeyPress={e => {
                        if (e.key === 'Enter') {
                            this.props.search();
                        }
                    }}
                />
                {/* 客付店舗 */}
                {/* <Autocomplete
                    id="section_id"
                    className="section_id"
                    options={this.props.shops}
                    getOptionLabel={(option: any) => option.tenpoName}
                    style={{ width: 150, margin: 5 }}
                    onChange={this.props.search_change_section}
                    renderInput={(params) => <TextField
                        autoComplete='off' {...params} label="客付店舗" />}
                /> */}
                {/* 客付担当 */}
                {/* <Autocomplete
                    id="combo-box-demo"
                    options={this.props.users_select}
                    getOptionLabel={(option: any) => option.label}
                    style={{ width: 150, margin: 5 }}
                    onChange={this.props.search_change_user}
                    renderInput={(params) => <TextField
                        autoComplete='off' {...params} label="客付担当" />}
                /> */}
                {/* 管理担当 */}
                {/* <Autocomplete
                    id="combo-box-demo"
                    options={this.props.users_select}
                    getOptionLabel={(option: any) => option.label}
                    style={{ width: 150, margin: 5 }}
                    onChange={this.props.search_change_tantou_id}
                    renderInput={(params) => <TextField
                        autoComplete='off' {...params} label="管理担当" />}
                /> */}

                {/* 確認担当 */}
                {/* <Autocomplete
                    id="combo-box-demo"
                    options={this.props.users_select}
                    getOptionLabel={(option: any) => option.label}
                    style={{ width: 150, margin: 5 }}
                    onChange={this.props.search_change_user}
                    renderInput={(params) => <TextField
                        autoComplete='off' {...params} label="確認担当" />}
                /> */}

                {/* <FormControlLabel
                    style={{ marginTop: 10 }}
                    onChange={this.props.kouji_gl}
                    control={<Checkbox
                        color="primary"
                        name="yanushi_hoshu" />}

                    label="当社補修のみ"
                />
                <FormControlLabel
                    style={{ marginTop: 10 }}
                    onChange={this.props.kouji_owner}
                    control={<Checkbox
                        color="primary"
                        name="yanushi_hoshu" />}

                    label="家主補修のみ"
                /> */}

                {/* <FormControl style={{ width: 170, marginTop: 20 }}>
                    <FormLabel component="legend">傷汚れの有無</FormLabel>
                    <RadioGroup row defaultValue="1">
                        <FormControlLabel value='1' control={<Radio color="primary" size='small' />} label={<span style={{fontSize: 12}}>すべて</span>} />
                        <FormControlLabel value='2' control={<Radio color="primary" size='small' />} label={<span style={{fontSize: 12}}>ありのみ</span>} />
                    </RadioGroup>
                </FormControl> */}

                {/* <FormControl style={{ width: 170, marginTop: 20 }}>
                    <FormLabel component="legend">確認状況</FormLabel>
                    <RadioGroup row defaultValue="1">
                        <FormControlLabel value='1' control={<Radio color="primary" size='small' />} label={<span style={{fontSize: 12}}>すべて</span>} />
                        <FormControlLabel value='2' control={<Radio color="primary" size='small' />} label={<span style={{fontSize: 12}}>確認済み</span>} />
                        <FormControlLabel value='3' control={<Radio color="primary" size='small' />} label={<span style={{fontSize: 12}}>未確認</span>} />
                    </RadioGroup>
                </FormControl> */}
                <div style={{ textAlign: 'right', maxWidth: '736px' }}>

                </div>
            </div>
        );
    }
};



const mapStateToProps = (state: any) => {
    return {
        // shops: state.masterReducer.shops,
        // users_select: state.masterReducer.users_select,
        search_status: state.ContractsList.search_status,
        bicycle_registration_list: state.BicycleList.bicycle_registration_list,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BicycleSearchList);




