import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import moment from 'moment'



interface Props {
    customerdetail: any
}



interface State {

}

class CustomerList extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);


    }





    render() {

        return (
            <div>
                {this.props.customerdetail[0] ? <Card style={{ marginTop: 10 }} className="col-lg-12">
                    <CardContent style={{ float: "left", padding: 10 }} className="col-lg-12">


                        <table className="col-lg-5" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                            <tbody>
                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >分類</th>
                                    <td colSpan={1} >
                                        個人
                                    </td>
                                    <th colSpan={1} >性別</th>
                                    <td colSpan={1} >
                                        {this.props.customerdetail[0].sex == 2 ? "女性" : "男性"}
                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >名称</th>
                                    <td colSpan={3} >
                                        {this.props.customerdetail[0].name}
                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >カナ</th>
                                    <td colSpan={3} >
                                        {this.props.customerdetail[0].kana}
                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >〒</th>
                                    <td colSpan={1} >
                                        {this.props.customerdetail[0].post}
                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >住所</th>
                                    <td colSpan={3} >
                                        {this.props.customerdetail[0].add1}
                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} ></th>
                                    <td colSpan={3} >
                                        {this.props.customerdetail[0].add2}
                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >TEL</th>
                                    <td colSpan={1} >
                                        {this.props.customerdetail[0].tel}
                                    </td>
                                    <th colSpan={1} >FAX</th>
                                    <td colSpan={1} >

                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} ></th>
                                    <td colSpan={1} >

                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >E-Mail</th>
                                    <td colSpan={3} >

                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >http://</th>
                                    <td colSpan={3} >

                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >生年月日</th>
                                    <td colSpan={1} >
                                        {this.props.customerdetail[0].birthday ? moment(this.props.customerdetail[0].birthday).tz("Asia/Tokyo").format('YYYY-MM-DD') : ""}

                                    </td>
                                    {(() => {


                                        var birthdays = new Date(this.props.customerdetail[0].birthday);

                                        // 文字列に分解
                                        const y2 = birthdays.getFullYear().toString().padStart(4, '0');
                                        const m2 = (birthdays.getMonth() + 1).toString().padStart(2, '0');
                                        const d2 = birthdays.getDate().toString().padStart(2, '0');

                                        // 今日の日付
                                        const today = new Date();
                                        const y1 = today.getFullYear().toString().padStart(4, '0');
                                        const m1 = (today.getMonth() + 1).toString().padStart(2, '0');
                                        const d1 = today.getDate().toString().padStart(2, '0');

                                        // 引き算
                                        const age = Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000);
                                        console.log(age);

                                        return <span>現在{age}歳</span>


                                    })()}

                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >本籍</th>
                                    <td colSpan={3} >

                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >備考</th>
                                    <td colSpan={3} >

                                    </td>
                                </tr>

                            </tbody>
                        </table>

                        <table className="col-lg-5" style={{ border: "0px", float: "left", marginRight: "50px" }}>
                            <tbody>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >業種/事業</th>
                                    <td colSpan={3} >

                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >代表者</th>
                                    <td colSpan={3} >

                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >カナ</th>
                                    <td colSpan={3} >

                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >担当者</th>
                                    <td colSpan={3} >

                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >カナ</th>
                                    <td colSpan={3} >

                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >所属</th>
                                    <td colSpan={3} >

                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >役職</th>
                                    <td colSpan={3} >

                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >内線番号</th>
                                    <td colSpan={3} >

                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >資本金</th>
                                    <td colSpan={1} >

                                    </td>
                                    百万円
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >年収</th>
                                    <td colSpan={1} >
                                        万円
                                    </td>

                                    <th colSpan={1} >従業員数</th>
                                    <td colSpan={1} >
                                        人
                                    </td>
                                </tr>

                                <tr style={{ border: "0px" }}>
                                    <th colSpan={1} >主要取引先</th>
                                    <td colSpan={3} >

                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </CardContent>
                </Card>
                    : ""}
            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        customerdetail: state.Keiyaku.customerdetail,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




