import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from "./Grid";
// import * as ContractSearch from '../ContractSearch/Saga/ContractSearch';
// import ContractSearchComponent from '../ContractSearch/Index';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Search from "./Search";
import CustomSelect from "./CustomSelect";
import CustomUnSelect from "./CustomUnSelect";

// import { customerList,  customerNewDispSet } from '../../Store/Customers';
import * as Adjustment from './Saga/Adjustment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { Row, Col } from 'reactstrap';
import * as MessageList from '../MessageList/Saga/MessageList';
import Dropzone from 'react-dropzone';


const moment = require('moment')


const styles = (theme: any) => ({
    margin: {
        margin: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tab: {

        minWidth: 50, // a number of your choice
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props: any) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton style={{ position: 'absolute' }} aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}

        </MuiDialogTitle>
    );
});


interface Props {
    upfile: any,
    search_request: any,
    user_details: any,
    get_syubetsu_list: any,
    get_request_list: any,
    match: any,
    search_status_true: any,
    search_status: any,
    get_notset_contents: any,
    get_inset_contents: any,
    inset_contents: any,
    insert_notset_contents: any,
    change_inset_contents: any,
    change_notset_contents: any,
    get_message_count: any,
    get_complete_percent: any,
    complete_percent: any
}



interface State {
    uketuke_Date1: any,
    uketuke_Date2: any,
    syokai_Date1: any,
    syokai_Date2: any,
    saisyu_Date1: any,
    saisyu_Date2: any,
    jikai_Date1: any,
    jikai_Date2: any,
    kanryo_Date1: any,
    kanryo_Date2: any,
    title: any,
    bukken: any,
    naiyou: any,
    room: any,
    joukyou: any,
    kokyaku: any,
    main: any,
    kokyaku_name: any,
    judensya: any,
    tantou_owner: any,
    tantou_taiou: any,
    Renraku_Saki: any,
    kaketuke_check: boolean,
    kaketukenasi_check: boolean,
    nyuukyo_check: boolean,
    tyouki_check: boolean,
    nozoku_check: boolean,
    kenan_check: boolean,
    app_check: boolean,
    app_apply_check: boolean,
    not_app_apply_check: boolean,
    air_con_check: boolean,
    tousya_hosyu_check:boolean,
    owner_hosyu_check:boolean,
    disp: boolean,
    type: any,
    OutaiHoho_Biko: any,
    custom_toggle: any,
    get_grid_set: boolean,
    inset_contents_disp: boolean,
    user_seach: boolean,
    first_loading: boolean,
    completed_title: any,
    completed_remarks: any,
    published_check: boolean

}

class CustomerList extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);

        props.get_syubetsu_list();
        props.get_complete_percent({ date: new Date() });

        this.state = {
            uketuke_Date1: null,
            uketuke_Date2: new Date(),
            syokai_Date1: null,
            syokai_Date2: null,
            saisyu_Date1: null,
            saisyu_Date2: null,
            jikai_Date1: null,
            jikai_Date2: null,
            kanryo_Date1: null,
            kanryo_Date2: null,
            title: null,
            bukken: '',
            naiyou: null,
            room: null,
            joukyou: null,
            kokyaku: null,
            main: null,
            kokyaku_name: null,
            Renraku_Saki: null,
            judensya: null,
            tantou_owner: null,
            tantou_taiou: null,
            kaketuke_check: false,
            kaketukenasi_check: false,
            nyuukyo_check: false,
            tyouki_check: false,
            nozoku_check: false,
            kenan_check: false,
            app_check: false,
            app_apply_check: false,
            not_app_apply_check: false,
            air_con_check: false,
            tousya_hosyu_check:false,
            owner_hosyu_check:false,
            type: null,
            OutaiHoho_Biko: null,
            disp: true,
            custom_toggle: false,
            get_grid_set: false,
            inset_contents_disp: true,
            user_seach: false,
            first_loading: false,
            completed_title: null,
            completed_remarks: null,
            published_check: false
        }

        // props.get_request_list();

        this.req_search = this.req_search.bind(this);
        this.search_clear = this.search_clear.bind(this);
        this.custom_toggle = this.custom_toggle.bind(this);
        this.inset_contents_disp = this.inset_contents_disp.bind(this);
        this.save_contents = this.save_contents.bind(this);
        this.reset_contents = this.reset_contents.bind(this);
        this.onDrop = this.onDrop.bind(this);


    }
    onDrop = (files: any) => {
        console.log(files);
        this.props.upfile(files[0]);
        // files.forEach((e: any) => {
        // });
    };
    custom_toggle() {
        this.props.get_notset_contents(this.props.user_details.id);

        this.setState({ custom_toggle: !this.state.custom_toggle })
    }


    req_search() {

        this.props.search_status_true();

        var data = {
            created_at1: moment(this.state.uketuke_Date1).utc().format('YYYY-MM-DD'),
            created_at2: moment(this.state.uketuke_Date2).utc().format('YYYY-MM-DD'),
            // created_at1: this.state.uketuke_Date1,
            // created_at2: this.state.uketuke_Date2,
            Syokai_Date1: moment(this.state.syokai_Date1).utc().format('YYYY-MM-DD'),
            Syokai_Date2: moment(this.state.syokai_Date2).utc().format('YYYY-MM-DD'),
            title: "%" + this.state.title + "%",
            Tatemono_Name: this.state.bukken,
            Naiyou: "%" + this.state.naiyou + "%",
            saisyu_Date1: moment(this.state.saisyu_Date1).utc().format('YYYY-MM-DD'),
            saisyu_Date2: moment(this.state.saisyu_Date2).utc().format('YYYY-MM-DD'),
            Room_Name: "%" + this.state.room + "%",
            Outai_Joukyou_Name: this.state.joukyou,
            second_date1: moment(this.state.jikai_Date1).utc().format('YYYY-MM-DD'),
            second_date2: moment(this.state.jikai_Date2).utc().format('YYYY-MM-DD'),
            Kokyaku_Kbn: this.state.kokyaku,
            main_outai_sub: this.state.main,
            complete_date1: moment(this.state.kanryo_Date1).utc().format('YYYY-MM-DD'),
            complete_date2: moment(this.state.kanryo_Date2).utc().format('YYYY-MM-DD'),
            Kokyaku_Name: "%" + this.state.kokyaku_name + "%",
            Shokai_User_Id: this.state.judensya,
            Shoyuusha_Tantou_Name: this.state.tantou_owner,
            taiou_tantou_user_id: this.state.tantou_taiou,
            Renraku_Saki: "%" + this.state.Renraku_Saki + "%",
            kaketuke_check: this.state.kaketuke_check,
            kaketukenasi_check: this.state.kaketukenasi_check,
            nyuukyo_check: this.state.nyuukyo_check,
            tyouki_check: this.state.tyouki_check,
            kenan_check: this.state.kenan_check,
            nozoku_check: this.state.nozoku_check,
            app_check: this.state.app_check,
            app_apply_check: this.state.app_apply_check,
            not_app_apply_check: this.state.not_app_apply_check,
            air_con_check: this.state.air_con_check,
            published_check: this.state.published_check,
            tousya_hosyu_check:this.state.tousya_hosyu_check,
            owner_hosyu_check:this.state.owner_hosyu_check,
            type: this.state.type,
            OutaiHoho_Biko: "%" + this.state.OutaiHoho_Biko + "%",
            completed_title: "%" + this.state.completed_title + "%",
            completed_remarks: "%" + this.state.completed_remarks + "%"
        }

        this.props.search_request(data);

    }

    save_contents() {
        var datas: { t_outai_list_content_id: any; user_id: any; content_order: number; width: any; }[] = [];
        var i = 0;
        this.props.inset_contents.forEach((element: any) => {
            var pushs = {
                t_outai_list_content_id: element.id,
                user_id: this.props.user_details.id,
                content_order: i,
                width: element.width
            }
            i++;
            datas.push(pushs);
        });
        this.props.insert_notset_contents(datas);
        alert("保存しました");
        this.custom_toggle();
    }

    reset_contents() {
        var datas: never[] = [];
        this.props.change_inset_contents(datas);
        this.props.insert_notset_contents({ user_id: this.props.user_details.id, delete: 1 });
        alert("リセットしました");
        this.custom_toggle();
    }

    inset_contents_disp() {
        this.setState({
            inset_contents_disp: false
        });
        setTimeout(() => {
            this.setState({
                inset_contents_disp: true
            });
        }, 100)

    }

    onChange_Kanryo_Naiyo = (e: any) => {
        this.setState({
            completed_remarks: e.target.value
        });
    };

    onChange_completed_title = (e: any) => {
        this.setState({
            completed_title: e.target.value
        });
    };


    onChange_type = (e: any, values: any) => {
        if (values) {
            this.setState({
                type: values.label
            });
        } else {
            this.setState({
                type: null
            });
        }

        setTimeout(() => {
            this.req_search();
        }, 100)

    };

    onChange_OutaiHoho_Biko = (e: any) => {
        this.setState({
            OutaiHoho_Biko: e.target.value
        });
    };


    onChangeuketukeDate1 = (date: any) => {
        this.setState({
            uketuke_Date1: new Date(date),
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };
    onChangeuketukeDate2 = (date: any) => {
        this.setState({
            uketuke_Date2: new Date(date),
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };
    onnowuketukeDate = (date: any) => {
        this.setState({
            uketuke_Date1: new Date(),
            uketuke_Date2: new Date(),
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };

    onChangeusyokaiDate1 = (date: any) => {
        this.setState({
            syokai_Date1: new Date(date),
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };
    onChangeusyokaiDate2 = (date: any) => {
        this.setState({
            syokai_Date2: new Date(date),
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };


    onChangesaisyuDate1 = (date: any) => {
        this.setState({
            saisyu_Date1: new Date(date),
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };
    onChangesaisyuDate2 = (date: any) => {
        this.setState({
            saisyu_Date2: new Date(date),
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };


    onChangejikaiDate1 = (date: any) => {
        this.setState({
            jikai_Date1: new Date(date),
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };
    onChangejikaiDate2 = (date: any) => {
        this.setState({
            jikai_Date2: new Date(date),
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };

    onChangekanryoDate1 = (date: any) => {
        this.setState({
            kanryo_Date1: new Date(date),
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };
    onChangekanryoDate2 = (date: any) => {
        this.setState({
            kanryo_Date2: new Date(date),
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };

    onChangetitle = (e: any) => {
        this.setState({
            title: e.target.value
        });
    };

    onChange_Renraku_Saki = (e: any) => {
        this.setState({
            Renraku_Saki: e.target.value
        });
    };


    onChangebukken = (e: any) => {
        this.setState({
            bukken: e.target.value
        });
    };

    onChangenaiyou = (e: any) => {
        this.setState({
            naiyou: e.target.value
        });
    };

    onChangeroom = (e: any) => {
        this.setState({
            room: e.target.value
        });
    };

    onChangejoukyou = (e: any, values: any) => {
        if (values) {
            this.setState({
                joukyou: values.label
            });
        } else {
            this.setState({
                joukyou: "全て"
            });
        }

        setTimeout(() => {
            this.req_search();
        }, 100)

    };

    onChangekokyaku = (e: any, values: any) => {
        if (values) {
            this.setState({
                kokyaku: values.label
            });
        } else {
            this.setState({
                kokyaku: null
            });
        }

        setTimeout(() => {
            this.req_search();
        }, 100)

    };
    onChangemain = (e: any) => {
        console.log(e.target.value);

        if (e.target.value == "") {
            this.setState({
                main: null
            });
        } else {
            this.setState({
                main: e.target.value
            });
        }
        setTimeout(() => {
            this.req_search();
        }, 100)
    };
    onChangekokyaku_name = (e: any) => {
        this.setState({
            kokyaku_name: e.target.value
        });
    };
    onChangejudensya = (event: any, values: any) => {
        if (values) {
            this.setState({
                judensya: values.value
            });
        } else {
            this.setState({
                judensya: null
            });
        }
        setTimeout(() => {
            this.req_search();
        }, 100)
    };
    onChangetantou_owner = (event: any, values: any) => {
        if (values) {
            this.setState({
                tantou_owner: values.value
            });
        } else {
            this.setState({
                tantou_owner: null
            });
        }
        setTimeout(() => {
            this.req_search();
        }, 100)
    };
    onChangetantou_taiou = (event: any, values: any) => {
        if (values) {
            this.setState({
                tantou_taiou: values.value
            });
        } else {
            this.setState({
                tantou_taiou: null
            });
        }
        setTimeout(() => {
            this.req_search();
        }, 100)
    };


    onChangekaketuke_check = (e: any) => {
        this.setState({
            kaketuke_check: e.target.checked
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };

    onChangekaketukenashi_check = (e: any) => {
        this.setState({
            kaketukenasi_check: e.target.checked
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };

    onChangenyuukyo_check = (e: any) => {
        this.setState({
            nyuukyo_check: e.target.checked
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };

    onChangetyouki_check = (e: any) => {
        this.setState({
            tyouki_check: e.target.checked
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };

    onChangekenan_check = (e: any) => {
        this.setState({
            kenan_check: e.target.checked
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };


    onChangenozoku_check = (e: any) => {
        this.setState({
            nozoku_check: e.target.checked
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };

    onChangeapp_check = (e: any) => {
        this.setState({
            app_check: e.target.checked
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };

    onChangeapp_apply_check = (e: any) => {
        this.setState({
            app_apply_check: e.target.checked
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };
    onChangenot_app_apply_check = (e: any) => {
        this.setState({
            not_app_apply_check: e.target.checked
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };

    onChangeair_con_check = (e: any) => {
        this.setState({
            air_con_check: e.target.checked
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };

    onChangetousya_hosyu_check = (e: any) => {
        this.setState({
            tousya_hosyu_check: e.target.checked
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };

    onChangeowner_hosyu_check = (e: any) => {
        this.setState({
            owner_hosyu_check: e.target.checked
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };

    onChangepublished_check = (e: any) => {
        this.setState({
            published_check: e.target.checked
        });
        setTimeout(() => {
            this.req_search();
        }, 100)
    };


    search_clear() {
        this.setState({
            uketuke_Date1: null,
            uketuke_Date2: null,
            syokai_Date1: null,
            syokai_Date2: null,
            saisyu_Date1: null,
            saisyu_Date2: null,
            jikai_Date1: null,
            jikai_Date2: null,
            kanryo_Date1: null,
            kanryo_Date2: null,
            title: null,
            bukken: null,
            naiyou: null,
            room: null,
            joukyou: null,
            kokyaku: null,
            main: null,
            kokyaku_name: null,
            judensya: null,
            tantou_owner: null,
            tantou_taiou: null,
            kaketuke_check: false,
            kaketukenasi_check: false,
            nyuukyo_check: false,
            tyouki_check: false,
            nozoku_check: false,
            kenan_check: false,
            app_check: false,
            app_apply_check: false,
            not_app_apply_check: true,
            air_con_check:false,
            published_check: false,
            disp: false,
            Renraku_Saki: null
        });
        setTimeout(() => {
            this.req_search();
            this.setState({
                disp: true
            });
        }, 100)
    }


    render() {

        // 一旦消す
        // if (this.state.user_seach == false && this.props.user_details.id) {
        //     this.props.get_message_count(this.props.user_details.id);
        //     this.setState({ user_seach: true });
        // }

        if (this.props.search_status == true) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'auto';
        }


        if (this.state.get_grid_set == false) {
            if (this.props.user_details.id) {
                this.props.get_inset_contents(this.props.user_details.id);
                this.setState({
                    get_grid_set: true
                });

            }
        }
        if (this.state.first_loading == false) {
            if (this.props.user_details.amSectionId) {
                this.props.get_request_list(this.props.user_details.amSectionId);
                this.setState({
                    first_loading: true
                });
                if ([40, 43, 9].includes(this.props.user_details.amSectionId)) {
                    this.setState({
                        app_apply_check: true
                    });
                }
                if ([10, 19].includes(this.props.user_details.amSectionId)) {
                    this.setState({
                        not_app_apply_check: true
                    });
                }
                if ([52].includes(this.props.user_details.amSectionId)) {
                    this.setState({
                        air_con_check: true
                    });
                }
            }
        }

        return (
            <div className="row" >

                {/* <div style={{ width: "250px", height: 40, margin: 5 }}>
                    <Dropzone
                        onDrop={this.onDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: 'dropzone' })} style={{ background: "#f5f5f5", height: 60, border: "solid" }}>
                                <input {...getInputProps()} />
                                <div>
                                    <p>クリックまたはドラッグでファイルをアップロード</p>
                                    <ul style={{ padding: 0, marginTop: 3 }}  >
                                    </ul>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </div> */}


                <div className="item-outer col-lg-12" >
                    <Card >
                        <CardContent >
                            {this.props.user_details.id ?
                                <Search
                                    uketuke_Date1={this.state.uketuke_Date1}
                                    uketuke_Date2={this.state.uketuke_Date2}
                                    syokai_Date1={this.state.syokai_Date1}
                                    syokai_Date2={this.state.syokai_Date2}
                                    saisyu_Date1={this.state.saisyu_Date1}
                                    saisyu_Date2={this.state.saisyu_Date2}
                                    jikai_Date1={this.state.jikai_Date1}
                                    jikai_Date2={this.state.jikai_Date2}
                                    kanryo_Date1={this.state.kanryo_Date1}
                                    kanryo_Date2={this.state.kanryo_Date2}
                                    title={this.state.title}
                                    bukken={this.state.bukken}
                                    naiyou={this.state.naiyou}
                                    room={this.state.room}
                                    joukyou={this.state.joukyou}
                                    kokyaku={this.state.kokyaku}
                                    main={this.state.main}
                                    kokyaku_name={this.state.kokyaku_name}
                                    judensya={this.state.judensya}
                                    tantou_owner={this.state.tantou_owner}
                                    tantou_taiou={this.state.tantou_taiou}
                                    kaketuke_check={this.state.kaketuke_check}
                                    kaketukenasi_check={this.state.kaketukenasi_check}
                                    nyuukyo_check={this.state.nyuukyo_check}
                                    tyouki_check={this.state.tyouki_check}
                                    nozoku_check={this.state.nozoku_check}
                                    kenan_check={this.state.kenan_check}
                                    app_check={this.state.app_check}
                                    app_apply_check={this.state.app_apply_check}
                                    not_app_apply_check={this.state.not_app_apply_check}
                                    air_con_check={this.state.air_con_check}                                    
                                    tousya_hosyu_check={this.state.tousya_hosyu_check}
                                    owner_hosyu_check={this.state.owner_hosyu_check}
                                    published_check={this.state.published_check}
                                    disp={this.state.disp}
                                    onChangeuketukeDate1={this.onChangeuketukeDate1}
                                    onChangeuketukeDate2={this.onChangeuketukeDate2}
                                    onnowuketukeDate={this.onnowuketukeDate}
                                    onChangeusyokaiDate1={this.onChangeusyokaiDate1}
                                    onChangeusyokaiDate2={this.onChangeusyokaiDate2}
                                    onChangesaisyuDate1={this.onChangesaisyuDate1}
                                    onChangesaisyuDate2={this.onChangesaisyuDate2}
                                    onChangejikaiDate1={this.onChangejikaiDate1}
                                    onChangejikaiDate2={this.onChangejikaiDate2}
                                    onChangekanryoDate1={this.onChangekanryoDate1}
                                    onChangekanryoDate2={this.onChangekanryoDate2}
                                    onChangetitle={this.onChangetitle}
                                    onChangebukken={this.onChangebukken}
                                    onChangenaiyou={this.onChangenaiyou}
                                    onChangeroom={this.onChangeroom}
                                    onChangejoukyou={this.onChangejoukyou}
                                    onChangekokyaku={this.onChangekokyaku}
                                    onChangemain={this.onChangemain}
                                    onChangekokyaku_name={this.onChangekokyaku_name}
                                    onChangejudensya={this.onChangejudensya}
                                    onChangetantou_owner={this.onChangetantou_owner}
                                    onChangetantou_taiou={this.onChangetantou_taiou}
                                    onChangekaketuke_check={this.onChangekaketuke_check}
                                    onChangekaketukenashi_check={this.onChangekaketukenashi_check}
                                    onChangenyuukyo_check={this.onChangenyuukyo_check}
                                    onChangetyouki_check={this.onChangetyouki_check}
                                    onChangekenan_check={this.onChangekenan_check}
                                    onChangenozoku_check={this.onChangenozoku_check}
                                    onChangeapp_check={this.onChangeapp_check}
                                    onChangeapp_apply_check={this.onChangeapp_apply_check}
                                    onChangenot_app_apply_check={this.onChangenot_app_apply_check}
                                    onChangeair_con_check={this.onChangeair_con_check}
                                    onChangetousya_hosyu_check={this.onChangetousya_hosyu_check}
                                    onChangeowner_hosyu_check={this.onChangeowner_hosyu_check}
                                    onChangepublished_check={this.onChangepublished_check}
                                    req_search={this.req_search}
                                    search_clear={this.search_clear}
                                    Renraku_Saki={this.state.Renraku_Saki}
                                    onChange_Renraku_Saki={this.onChange_Renraku_Saki}
                                    onChange_OutaiHoho_Biko={this.onChange_OutaiHoho_Biko}
                                    onChange_type={this.onChange_type}
                                    OutaiHoho_Biko={this.state.OutaiHoho_Biko}
                                    type={this.state.type}
                                    onChange_Kanryo_Naiyo={this.onChange_Kanryo_Naiyo}
                                    onChange_completed_title={this.onChange_completed_title}
                                /> : ""}
                        </CardContent >
                    </Card >
                </div>

                <div className="item-outer col-lg-12">
                    <Card >
                        <CardContent >
                            {this.state.inset_contents_disp && this.state.get_grid_set ?
                                <Grid
                                    custom_toggle={this.custom_toggle}
                                    req_search={this.req_search}
                                />
                                : ""}
                        </CardContent >
                    </Card >
                </div>





                {/* 項目をカスタマイズするモーダル */}
                < Modal isOpen={this.state.custom_toggle} toggle={this.custom_toggle} size="kokyaku" fade={false}>
                    <DialogTitle id="customized-dialog-title" onClose={this.custom_toggle} >
                        リクエスト一覧カスタマイズ

                        <Button size="small" style={{ color: "white", backgroundColor: "#5bc0de", marginLeft: 40 }} onClick={this.save_contents} variant="contained">
                            保存
                        </Button>

                        <Button size="small" style={{ color: "white", marginLeft: 40 }} onClick={this.reset_contents} color="secondary" variant="contained">
                            リセット
                        </Button>

                    </DialogTitle>

                    <div style={{ padding: 10 }}>
                        ※設定していない場合やリセットした際はデフォルトの項目になります。
                    </div>

                    <Row>
                        <div className="col-lg-6">
                            <h5 style={{ marginLeft: 10 }}>表示する項目</h5>
                            <CustomSelect inset_contents_disp={this.state.inset_contents_disp} />
                        </div>

                        <div className="col-lg-6">
                            <h5 style={{ marginLeft: 10 }}>非表示の項目</h5>
                            <CustomUnSelect inset_contents_disp={this.inset_contents_disp} />
                        </div>
                    </Row>

                    <ModalFooter>
                        <Button size="small" onClick={this.custom_toggle} color="secondary" variant="contained">
                            <i className="fas fa-door-closed"></i>閉じる
                        </Button>
                    </ModalFooter>
                </Modal >

            </div >
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        // aaaaa
        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // banks: state.rentContractMoniesReducer.banks,
        search_modal: state.Adjustment.search_modal,
        room_details: state.roomsReducer.room_details,
        seisan_follow_list: state.Adjustment.seisan_follow_list,
        user_details: state.masterReducer.user_details,
        search_status: state.Adjustment.search_status,
        inset_contents: state.Adjustment.inset_contents,
        complete_percent: state.Adjustment.complete_percent,

    }
}



function mapDispatchToProps(dispatch: any) {
    return {

        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },

        get_seisan_follow_list(state: any) {
            dispatch(Adjustment.get_seisan_follow_list(state))
        },
        get_kanri_m_follows(state: any) {
            dispatch(Adjustment.get_kanri_m_follows(state))
        },
        get_syubetsu_list(state: any) {
            dispatch(Adjustment.get_syubetsu_list(state))
        },
        get_request_list(state: any) {
            dispatch(Adjustment.get_request_list(state))
        },
        search_request(state: any) {
            dispatch(Adjustment.search_request(state))
        },

        search_status_true(state: any) {
            dispatch(Adjustment.search_status_true(state))
        },
        get_notset_contents(state: any) {
            dispatch(Adjustment.get_notset_contents(state))
        },
        get_inset_contents(state: any) {
            dispatch(Adjustment.get_inset_contents(state))
        },
        insert_notset_contents(state: any) {
            dispatch(Adjustment.insert_notset_contents(state))
        },
        change_inset_contents(state: any) {
            dispatch(Adjustment.change_inset_contents(state))
        },
        change_notset_contents(state: any) {
            dispatch(Adjustment.change_notset_contents(state))
        },
        get_message_count(state: any) {
            dispatch(MessageList.message_count(state))
        },
        get_complete_percent(state: any) {
            dispatch(Adjustment.get_complete_percent(state))
        },

        upfile(state: any) {
            dispatch(Adjustment.upfile(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




