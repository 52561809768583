import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Modal, Row, Col, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import Paper from '@material-ui/core/Paper';
import './Css/Table.css';
import './Css/Form.css';
import ChatTemplateModal from './ChatTemplateModal';
import * as Keiyaku from '../Keiyaku/Saga/Keiyaku';
import * as RequestDetailSaga from '../RequestDetail/Saga/RequestDetail';
import * as Chat from './Saga/Chat';
import Tooltip from '@material-ui/core/Tooltip';
import MButton from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import InsertPhoto from '@material-ui/icons/InsertPhoto';
import SubjectIcon from '@material-ui/icons/Subject';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dropzone from 'react-dropzone';
import Card from '@material-ui/core/Card';
import SendIcon from '@material-ui/icons/Send';


const moment = require('moment')

let Bosyuflg;

export class RenderField extends React.Component {

    render() {
        const { className, id, name, type, style, input, disabled, onBlur, meta: { touched, error } } = this.props;
        return (
            <div>
                {type === 'textarea' ?
                    <textarea id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style} {...input} type={type} disabled={disabled} />
                    : type === 'select' ?
                        <select id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled}>{this.props.children}</select>
                        :
                        <input id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled} />
                }
                {touched && error && <div className="text-danger">{error}</div>}
            </div>
        );
    }
};


class ChatForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSetRoomName: false,

            setShubetu0: true,
            setShubetu2: true,
            setShubetu7: true,
            setShubetu21: true,
            setBoshuOnly0: true,
            introduction_rooms_modal: true,
            setKubun1: true,
            message: "",
            row: 8,
            Templatetoggle: false,
            button_disp: true,

            image_dialog_disp:false,
            base64: null,
            mimeType: null
        };

        Bosyuflg = 1;

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        //this.handleClick = this.handleClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle_introduction_rooms_modal = this.toggle_introduction_rooms_modal.bind(this);
        this.Templatetoggle = this.Templatetoggle.bind(this);
        this.kanryou = this.kanryou.bind(this);
        this.onDrop = this.onDrop.bind(this);

        // this.props.initialize({});//フォームに初期値を反映させる
    }



    kanryou() {
        var result = window.confirm('完了にすると、アプリからチャットが送れなくなります。よろしいですか？');
        if (result) {
            // this.setState({ button_disp: false });
            
            this.props.set_completion_at_success(new Date())


            this.props.chat_complet({
                id: this.props.chat_id
            })
            alert("完了にしました");
            setTimeout(() => {
                this.props.get_chatlist({
                    m_customer_id: this.props.m_customer_id,
                    room_support_m_user_id: this.props.room_support_m_user_id
                });
            }, 500)
        }
    }

    Templatetoggle() {
        if (this.state.Templatetoggle == false) {
            this.props.get_template_category_list();
        }
        this.setState({ Templatetoggle: !this.state.Templatetoggle })
    }

    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }





    onChange(e) {
        console.log(e.target.value);
        this.setState({ message: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.message !== "") {

            this.props.onSubmit({ message: this.state.message })
            this.setState({ message: "", row: 8 });
        }
    }

    componentDidMount() {
        // This method is called when the component is first added to the document
        this.ensureDataFetched();
        //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        this.ensureDataFetched();
    }

    ensureDataFetched() {
        //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        //console.log(startDateIndex)

        //this.props.requestWeatherForecasts(0);

    }

    toggle_introduction_rooms_modal() {
        this.setState({ introduction_rooms_modal: !this.state.introduction_rooms_modal })
    }

    onDrop = (files) => {
        console.log(files)
        const file = files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        // alert(file.name)
        // alert(file.type)


        if (file.name.indexOf('jpeg') == -1 && (file.type.indexOf('jpeg') != -1 || file.type.indexOf('png') != -1 || file.type.indexOf('bmp') != -1)) {
            reader.onload = () => {
                this.setState({
                    base64: reader.result,
                    name: file.name,
                    mimeType: file.type
                })
                this.props.change_chat_file_data({
                    file: files,
                    name: file.name,
                    mimeType: file.type
                })
            }
        }else{
            alert("送信可能な画像はjpg、png、bmpのみです。")
        }

        // let sendfile = [];
        // sendfile.file = files;



    };
    render() {

        const { handleDate, handlerequestMoveDate, handleSetsubiSubmit, handleSubmit } = this.props;

        //moment.locale('ja')
        return (

            <div>

            
            <form onSubmit={this.handleSubmit} >
                    {/* <div style={{overflow:"visible"}}>
                    <Tooltip title={"aaaaaaaa"} style={{position: "relative", zIndex:99999, }}>
                    <span>
                        <IconButton size="small" style={{}} aria-label="add" onClick={this.Templatetoggle}>
                            <AddCircleIcon style={{color:"orange"}} />
                        </IconButton>
                    </span>
                    </Tooltip>
                    </div> */}
                    

                <div className="searchform float-left">
                    <TextField
                        id="outlined-multiline-static"
                        //   label=""
                        multiline
                        rows={this.state.row}
                        defaultValue=""
                        style={{ width: "250px", marginTop: "0px", marginBottom: "0px" }}
                        onChange={this.onChange}
                        value={this.state.message}
                        //   className={classes.textField}
                        margin="normal"
                        variant="outlined"

                    />
                </div>
                {this.props.chat_send ? <button
                    type="submit"

                    className="btn btn-primary"
                    style={{ margin: "5px", height:39 }}
                    disabled
                >送信</button> : <button
                    type="submit"
                    className="btn btn-primary m-10"
                    style={{ margin: "5px", height:39 }}
                >送信</button>}


                {this.props.chat_image_disp ? <div class="tooltip3" ><IconButton style={{boxSizing: "content-box", borderRadius: "4px", padding:0}} size="small" onClick={(e) => { this.props.change_chat_file_dialog_disp(true) }}>
                    <InsertPhoto  fontSize="large" style={{ color: "white",      
                        width: "1em",
                        height: "1em",
                        display: "inline-block",
                        fill: "currentcolor",
                        flexShrink: 0,
                        transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        margin: "0px",
                        padding: "4px 4px",
                        borderRadius: "4px",
                        boxSizing: "content-box",
                        backgroundColor: "rgb(92, 184, 92)" }}>
                    </InsertPhoto>
                </IconButton><div class="description3">画像を送信</div></div>:""}

                {/* <Tooltip title={"テンプレート"}> */}
                
                <div class="tooltip3" >
                    <IconButton style={{boxSizing: "content-box", borderRadius: "4px", padding:0, marginLeft:5}} size="small" onClick={this.Templatetoggle}>
                    <SubjectIcon  fontSize="large" style={{ color: "#007bff",      
                        width: "1em",
                        height: "1em",
                        display: "inline-block",
                        fill: "currentcolor",
                        flexShrink: 0,
                        transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        margin: "0px",
                        padding: "4px 4px",
                        borderRadius: "4px",
                        boxSizing: "content-box",
                        backgroundColor: "rgb(25, 118, 210)",
                        color: "rgb(255, 255, 255)",}}>
                    </SubjectIcon>
                    </IconButton>
                    <div class="description3">テンプレート</div>
                </div>
                {/* </Tooltip> */}
                    {/* <button
                        onClick={this.Templatetoggle}
                        className="btn btn-primary m-10"
                        style={{ color: "white", backgroundColor: "#008000", margin: 10, marginTop: 20 }}>
                        テンプレ</button> */}

                {/* リクエストありのチャットの場合、もしくは完了している案件の場合は完了にするボタンは出さない */}
                {this.state.button_disp ?
                    this.props.completion_at ? "" :
                        this.props.Outai_No ? "" :
                            <span>
                                <div class="tooltip3" ><IconButton style={{boxSizing: "content-box", borderRadius: "4px", padding:0, marginLeft:5}} size="small" onClick={this.kanryou}>
                                    <CheckIcon  fontSize="large" style={{ color: "#007bff",      
                                            width: "1em",
                                            height: "1em",
                                            display: "inline-block",
                                            fill: "currentcolor",
                                            flexShrink: 0,
                                            transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                            margin: "0px",
                                            padding: "4px 4px",
                                            borderRadius: "4px",
                                            boxSizing: "content-box",
                                            color: "rgb(255, 255, 255)",
                                            backgroundColor: "#ffa500"
                                            
                                            }}>
                                    </CheckIcon>
                                </IconButton><div class="description3">完了にする</div></div>


                                {/* <MButton variant="outlined" startIcon={<CheckIcon />}></MButton> */}
                                {/* <button
                                    onClick={this.kanryou}
                                    className="btn btn-primary m-10"
                                    style={{ color: "white", backgroundColor: "#ffa500", margin: 10, marginTop: 20 }}>
                                    案件を完了にする</button> */}
                            </span> : ""}


                {/* <Tooltip title="Add" aria-label="ファイル送信" style={{ margin: "5px" }}>
                    <Fab color="secondary" size="small" onClick={() => this.props.toggle_send(2)}>
                        添付
                    </Fab>
                </Tooltip> */}

                {this.props.chat_file_dialog_disp === true ? <div style={{ position: "absolute", zIndex: 3000, background: "", width: "100%", height: "100%", marginLeft: -15, marginTop: -530 }}>
                        <div style={{ margin: "auto", marginTop: 50, borderRadius: "10px", width: "400px", height: "400px", padding: "20px", background: "#FFF" }}>
                            <p>ファイル送信</p>
                            {this.props.chat_file_data != null && this.state.base64 && (this.state.mimeType === "image/jpeg" || this.state.mimeType === "image/png" || this.state.mimeType === "image/bmp") ? <div><img src={this.state.base64} style={{ maxWidth: 250, maxHeight: 250 }} /></div>
                                : this.props.chat_file_data != null && this.state.base64 ? <div><Card style={{ padding: 5 }}><b>{this.state.name}</b> </Card></div> : <Dropzone

                                    onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps }) => (

                                        <div {...getRootProps({ className: 'dropzone' })} style={{ background: "#f5f5f5" }}>

                                            <input {...getInputProps()} />

                                            <div style={{ height: 100 }}>
                                                <p>{this.props.chat_file_data != null && this.state.name ? this.state.name : "ファイル添付　クリックまたはドラッグ&ドロップ"}</p>
                                                <ul style={{ padding: 0, marginTop: 3 }}  >


                                                </ul>

                                            </div>

                                        </div>

                                    )}
                                </Dropzone>}
                            {this.props.chat_file_data != null && this.state.base64 ? <Button color="danger" style={{ margin: "20px", marginTop: "50px" }} onClick={this.props.upload_chat_file} disabled={this.props.line_send_file_loading}>送信する</Button> : ""}

                            <Button color="secondary" style={{ margin: "20px", marginTop: "50px" }} onClick={(e) => { this.props.change_chat_file_dialog_disp(false);this.setState({ "base64": null, "name": null, "mimeType": null }) }}>キャンセル</Button>

                        </div>
                    </div> : ""}

                


                < Modal isOpen={this.state.Templatetoggle} toggle={this.Templatetoggle} fade={false}>
                    <ModalHeader toggle={this.Templatetoggle}>テンプレート</ModalHeader>
                    <div style={{ padding: 10 }} >
                        <Paper square={false} style={{ marginTop: 10}}>
                            <ChatTemplateModal onChange={this.onChange} />
                        </Paper>
                    </div>
                    <ModalFooter>
                    </ModalFooter>
                </Modal >

            </form >
            </div>
        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        chat_send: state.line.chat_send,
        Outai_No: state.chat.Outai_No,
        completion_at: state.chat.completion_at,
        chat_id: state.chat.chat_id,
        m_customer_id: state.Keiyaku.m_customer_id,
        room_support_m_user_id: state.Keiyaku.room_support_m_user_id,
        chat_file_dialog_disp: state.chat.chat_file_dialog_disp,
        chat_file_data: state.chat.chat_file_data,
        chat_image_disp: state.chat.chat_image_disp,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_template_category_list(state) {
            dispatch(Keiyaku.get_template_category_list(state))
        },
        chat_complet(state) {
            dispatch(RequestDetailSaga.chat_complet(state))
        },
        get_chatlist(state) {
            dispatch(Keiyaku.get_chatlist(state))
        },
        
        change_chat_file_data(state) {
            dispatch(Chat.change_chat_file_data(state))
        },
        upload_chat_file(state) {
            dispatch(Chat.upload_chat_file(state))
        },
        change_chat_file_dialog_disp(state) {
            dispatch(Chat.change_chat_file_dialog_disp(state))
        },
        set_completion_at_success(state) {
            dispatch(Chat.set_completion_at_success(state))
        },
    };
}

// LineFormComponent = reduxForm({
//     form: 'linesendform', // a unique identifier for this form
//     // validate//バリデーション
//     enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(LineFormComponent)

export default connect(mapStateToProps, mapDispatchToProps)(ChatForm);