import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import ja from "date-fns/locale/ja";
import './Css/Grid.css';
import DateFnsUtils from '@date-io/date-fns';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import KyDetails from '../KyDetail/Index';
import * as Board from './Saga/Board';
// import * as Adjustment from './Saga/Bord';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import IconButton from '@material-ui/core/IconButton';
import GavelIcon from '@material-ui/icons/Gavel';

import { CallClient, CallAgent, VideoStreamRenderer, LocalVideoStream } from "@azure/communication-calling";
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
const { CommunicationIdentityClient } = require('@azure/communication-identity');

let call;
let callAgent;


let placeCallOptions;
let deviceManager;
let localVideoStream;
let rendererLocal;
let rendererRemote;



const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');


class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {

      reflash: false,

      calleeInput: "",
      callButton: true,
      hangUpButton: true,
      stopVideoButton: true,
      startVideoButton: true,
      inputValue: ""
      // dataList: new FakeObjectDataListStore(1000000),
      // columnWidths: {
      //   firstName: 240,
      //   lastName: 150,
      //   sentence: 140,
      //   companyName: 60,
      // },
    };

    this.init()

    // this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);

    this.callAgent = this.callAgent.bind(this);
    this.localVideoView = this.localVideoView.bind(this);
    this.input = this.input.bind(this);
    this.remoteVideoView = this.remoteVideoView.bind(this);
    this.subscribeToRemoteParticipantInCall = this.subscribeToRemoteParticipantInCall.bind(this);
    this.subscribeToParticipantVideoStreams = this.subscribeToParticipantVideoStreams.bind(this);
    this.handleVideoStream = this.handleVideoStream.bind(this);



  }
  async init(newColumnWidth, columnKey) {

    const identityClient = new CommunicationIdentityClient("endpoint=https://sample.communication.azure.com/;accesskey=6XYbpN07OEIQqbX8BpcZuZKNeeffkJ2SPdB3Tpu04dwfDq6Aq9UroopUrqo/DYXJGLFETkyrL9+CLWXuyLfgsg==");


    let identityResponse = await identityClient.createUser();
    console.log(`\nCreated an identity with ID: ${identityResponse.communicationUserId}`);
    alert(identityResponse.communicationUserId)
    let tokenResponse = await identityClient.getToken(identityResponse, ["voip"]);
    const { token, expiresOn } = tokenResponse;

    alert(token)
    const callClient = new CallClient();
    const tokenCredential = new AzureCommunicationTokenCredential(token);
    callAgent = await callClient.createCallAgent(tokenCredential, { displayName: 'optional ACS user name' });

    deviceManager = await callClient.getDeviceManager();

    this.setState({ callButton: false })




    callAgent.on('incomingCall', async e => {
      const videoDevices = await deviceManager.getCameras();
      const videoDeviceInfo = videoDevices[0];
      console.log(videoDeviceInfo)
      alert()
      localVideoStream = new LocalVideoStream(videoDeviceInfo);
      this.localVideoView();
      this.setState({ stopVideoButton: false })
      this.setState({ callButton: true })
      this.setState({ hangUpButton: false })
      // stopVideoButton.disabled = false;
      // callButton.disabled = true;
      // hangUpButton.disabled = false;

      const addedCall = await e.incomingCall.accept({ videoOptions: { localVideoStreams: [localVideoStream] } });
      call = addedCall;

      this.subscribeToRemoteParticipantInCall(addedCall);
    });
    callAgent.on('callsUpdated', e => {
      e.removed.forEach(removedCall => {
        // dispose of video renderers
        rendererLocal.dispose();
        rendererRemote.dispose();
        // toggle button states
        this.setState({ hangUpButton: true })
        this.setState({ callButton: false })
        this.setState({ stopVideoButton: true })
        // hangUpButton.disabled = true;
        // callButton.disabled = false;
        // stopVideoButton.disabled = true;
      })
    })

  }


  async callAgent() {
    const videoDevices = await deviceManager.getCameras();
    const videoDeviceInfo = videoDevices[0];
    console.log(videoDeviceInfo)
    alert(this.state.inputValue)
    localVideoStream = new LocalVideoStream(videoDeviceInfo);
    this.localVideoView();



    // const userToCall = calleeInput.value;
    call = callAgent.startCall(
      [{ communicationUserId: this.state.inputValue }],
      placeCallOptions
    );

    this.subscribeToRemoteParticipantInCall(call);

    // stopVideoButton.disabled = false;
    // callButton.disabled = true;
    // hangUpButton.disabled = false;
    this.setState({ stopVideoButton: false })
    this.setState({ callButton: true })
    this.setState({ hangUpButton: false })
    // const addedCall = await e.incomingCall.accept({ videoOptions: { localVideoStreams: [localVideoStream] } });
    // let call = addedCall;

    // subscribeToRemoteParticipantInCall(addedCall);
  }

  async localVideoView() {
    let rendererLocal = new VideoStreamRenderer(localVideoStream);
    const view = await rendererLocal.createView();
    // this.setState({ myVideo: view.target })
    document.getElementById("myVideo").appendChild(view.target);
  }

  async subscribeToRemoteParticipantInCall(callInstance) {
    callInstance.on('remoteParticipantsUpdated', e => {
      e.added.forEach(p => {
        this.subscribeToParticipantVideoStreams(p);
      })
    });
    callInstance.remoteParticipants.forEach(p => {
      this.subscribeToParticipantVideoStreams(p);
    })
  }


  async subscribeToParticipantVideoStreams(remoteParticipant) {

    remoteParticipant.on('videoStreamsUpdated', e => {
      e.added.forEach(v => {
        this.handleVideoStream(v);
      })
    });
    remoteParticipant.videoStreams.forEach(v => {
      this.handleVideoStream(v);
    });
  }

  async handleVideoStream(remoteVideoStream) {
    alert("re")
    alert(remoteVideoStream.isAvailable)
    remoteVideoStream.on('isAvailableChanged', async () => {
      if (remoteVideoStream.isAvailable) {
        this.remoteVideoView(remoteVideoStream);
      } else {
        this.rendererRemote.dispose();
      }
    });
    if (remoteVideoStream.isAvailable) {
      this.remoteVideoView(remoteVideoStream);
    }
  }

  async remoteVideoView(remoteVideoStream) {
    rendererRemote = new VideoStreamRenderer(remoteVideoStream);
    const view = await rendererRemote.createView();
    alert("remote")
    document.getElementById("remoteVideo").appendChild(view.target);
  }
  input(e) {
    this.setState({ inputValue: e.target.value })
  }
  // async  remoteVideoView(remoteVideoStream) {
  //   rendererRemote = new VideoStreamRenderer(remoteVideoStream);
  //   const view = await rendererRemote.createView();
  //   document.getElementById("remoteVideo").appendChild(view.target);
  // }



  render() {

    return (
      <div id="boardlist">
        <h4>Azure Communication Services</h4>
        <h1>1:1 Video Calling Quickstart</h1>
        <input id="callee-id-input" value={this.state.inputValue} onChange={this.input} type="text" placeholder="Who would you like to call?" />

        <div>
          <button id="call-button" type="button" onClick={this.callAgent} disabled={this.state.callButton}>
            start call
          </button>
          &nbsp;
          <button id="hang-up-button" type="button" disabled={this.state.hangUpButton}>
            hang up
          </button>
          &nbsp;
          <button id="start-Video" type="button" disabled={this.state.startVideoButton}>
            start video
          </button>
          &nbsp;
          <button id="stop-Video" type="button" disabled={this.state.stopVideoButton}>
            stop video
          </button>
        </div>

        <div>Local Video</div>
        <div style={{ height: "200px", width: "300px", backgroundColor: "black", position: "relative" }}>
          <div id="myVideo" style={{ backgroundColor: "black", position: "absolute", top: "50%", transform: "translateY(-50%)" }}>
            {this.state.myVideo}
          </div>
        </div>
        <div>Remote Video</div>
        <div style={{ height: "200px", width: "300px", backgroundColor: "black", position: "relative" }}>
          <div id="remoteVideo" style={{ backgroundColor: "black", position: "absolute", top: "50%", transform: "translateY(-50%)" }}>
          </div>
        </div>

      </div>
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    // room_details: state.roomsReducer.room_details,
    // seisan_follow_list: state.Adjustment.seisan_follow_list,
    // board_kouji_seisan_follow_list: state.board.board_kouji_seisan_follow_list,
  }
}
function mapDispatchToProps(dispatch) {
  return {


  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);