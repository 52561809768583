import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import ja from "date-fns/locale/ja";
import './Css/Grid.css';
import DateFnsUtils from '@date-io/date-fns';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { CSVLink } from "react-csv";

const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');



var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    }
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


const columns =
  [
    { key: 'application_date', name: '申込日', width: 100, resizable: true },
    { key: 'contract_date', name: '契約日', width: 100, resizable: true },
    { key: 'building_name', name: '物件名', width: 300, resizable: true },
    { key: 'room_name', name: '部屋名', width: 100, resizable: true },
    { key: 'name1', name: '契約者名', width: 200, resizable: true },
    { key: 'user_name_all', name: '担当', width: 100, resizable: true },
    { key: 'room_support_m_user_id', name: 'アプリ', width: 100, resizable: true },
    { key: 'created_at', name: 'アプリ登録', width: 150, resizable: true },

  ]

const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} > {props[rowIndex][column.key]} </Cell>

const Date = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).tz("utc").format('YYYY-MM-DD') : ""}
  </Cell>

const Custmors = ({ column, props, rowIndex }) =>
  (() => {
    var url = process.env.REACT_APP_CRM_URL + '/customer/' + props[rowIndex]['id'];
    return <Cell style={{ whiteSpace: "nowrap" }} >
      <a target="_blank" rel="noopener" href={url} className="fas fa-external-link-alt"></a>
      {props[rowIndex][column.key]}
    </Cell>;
  })()

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns,
      reflash: false,
      colSortDirs: {},
    };
    this._rowClassNameGetter = this._rowClassNameGetter.bind(this);
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }
  _onSortChange(columnKey, sortDir) {

    let seisan_follow_search_list = this.props.contracts_list;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }


  _rowClassNameGetter(index) {

    if (this.props.contracts_list[index]) {
      if (this.props.contracts_list[index]["room_support_m_user_id"] == '未登録') {
        return 'unapp-row';
      }
    }
  }


  render() {
    return (
      <div>
        <div>{this.props.contracts_list.length}件
         <CSVLink
            data={this.props.contracts_list}
            filename={"my-file.csv"}
            target="_blank"
          >
            <Button
              size="small"
              style={{ color: "white", backgroundColor: "#008000", margin: 1, marginLeft: 10 }}
              variant="contained">
              CSV
                            </Button>
          </CSVLink> </div>
        <Table
          rowHeight={30}
          rowsCount={this.props.contracts_list.length}
          // rowsCount={10}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          width={window.innerWidth - 600}
          height={window.innerHeight - 120}
          headerHeight={30}
          rowClassNameGetter={this._rowClassNameGetter}>

          {columns.map(function (value, i) {
            return <Column
              columnKey={value.key}
              header={<SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={this.state.colSortDirs[value.key]}
                style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}
              cell={({ rowIndex, ...props }) => (
                value.key === "name1" ? <Custmors column={value} props={this.props.contracts_list} rowIndex={rowIndex}></Custmors>
                  : value.key === "created_at" || value.key === "contract_date" || value.key === "application_date" ? <Date column={value} props={this.props.contracts_list} rowIndex={rowIndex}></Date>
                    : <Base column={value} props={this.props.contracts_list} rowIndex={rowIndex}></Base>
              )}
              width={this.state.columns.filter(a => a.key === value.key)[0].width}
              isResizable={true}
              fixed={value.frozen}

            />
          }, this)}
        </Table >
      </div >
    );
  }
};


//export  default CsustomerLあ」ist;
const mapStateToProps = (state) => {
  return {
    contracts_list: state.ContractsList.contracts_list,
  }
}
function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);