import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import ja from "date-fns/locale/ja";
import './Css/Grid.css';
import DateFnsUtils from '@date-io/date-fns';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CSVLink } from "react-csv";
import * as KizuCheckList from './Saga/KizuCheckList';
import KizuDetail from '../KizuDetail/Index';


const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');



var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    }
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


const columns =
  [
    { key: 'check_at', name: '受付日', width: 100, resizable: true },
    { key: 'Keiyaku_Date', name: '契約日', width: 100, resizable: true },
    // { key: 'kizu_flag', name: '傷汚れ', width: 80, resizable: true },
    { key: 'Tatemono_Name', name: '物件名', width: 300, resizable: true },
    { key: 'Room_Name', name: '号室', width: 100, resizable: true },
    { key: 'keiyakusya_name', name: '契約者', width: 100, resizable: true },
    { key: 'name', name: '登録者', width: 100, resizable: true },
    { key: 'Tenpo_Name_Short', name: '客付店舗', width: 100, resizable: true },
    { key: 'nickname', name: '客付担当', width: 100, resizable: true },
    { key: 'Shoyuusha_Tantou_Name', name: '管理担当', width: 100, resizable: true },
    { key: 'no', name: '', width: 100, resizable: true },
  ]

const Base = ({ column, props, rowIndex, detail_list }) =>
  props[rowIndex]['no'] !== -1 ?
    <div onClick={() => detail_list(props[rowIndex]['Keiyaku_No'])} style={{ cursor: "pointer" }}>
      <Cell style={{ whiteSpace: "nowrap", cursor: "pointer" }}> {props[rowIndex][column.key]} </Cell>
    </div> :
    <div>
      <Cell style={{ whiteSpace: "nowrap" }}> {props[rowIndex][column.key]} </Cell>
    </div>

const Date = ({ column, props, rowIndex, detail_list }) =>
  props[rowIndex]['no'] !== -1 ?
    <div onClick={() => detail_list(props[rowIndex]['Keiyaku_No'])} style={{ cursor: "pointer" }}>
      <Cell style={{ whiteSpace: "nowrap", cursor: "pointer" }}>
        {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).tz("utc").format('YYYY-MM-DD') : ""}
      </Cell>
    </div> :
    <div>
      <Cell style={{ whiteSpace: "nowrap" }}>
        {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).tz("utc").format('YYYY-MM-DD') : ""}
      </Cell>
    </div>

const Nashi = ({ column, props, rowIndex, detail_list }) =>
  props[rowIndex][column.key] == "-1" ?
    <div>
      <Cell style={{ whiteSpace: "nowrap" }}>報告なし</Cell>
    </div> : ""


class KizuList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns,
      reflash: false,
      colSortDirs: {},
      reqtoggle: false,
    };
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.detail_list = this.detail_list.bind(this);
    this.reqtoggle = this.reqtoggle.bind(this);
    this._rowClassNameGetter = this._rowClassNameGetter.bind(this);

  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }
  _onSortChange(columnKey, sortDir) {

    let seisan_follow_search_list = this.props.kizu_check_list;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }


  _


  detail_list = (Keiyaku_No) => {
    window.open(`/KizuDetail/` + Keiyaku_No, '_blank', 'noopener')
  }

  requestopen = () => {
    // this.props.get_request_detail({ Outai_No: Outai_No });
    // this.props.get_kouji_list(Outai_No)
    this.reqtoggle();
  }

  reqtoggle() {
    // 閉じた時にデータを空にする
    // リストを検索しなおす
    // チャットを閉じる
    if (this.state.reqtoggle == true) {
      // this.props.get_request_detail({ Outai_No: 0 });
      // this.props.get_chat_close();
    }
    this.setState({ reqtoggle: !this.state.reqtoggle })
  }
  _rowClassNameGetter(index) {
    if (this.props.kizu_check_list[index]) {
      if (this.props.kizu_check_list[index]['no'] == -1) {
        return 'gray-row';
      }
    }
  }
  render() {
    return (
      <div>
        <Table
          rowHeight={30}
          rowsCount={this.props.kizu_check_list.length}
          // rowsCount={10}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          width={window.innerWidth - 460}
          // width={window.innerWidth - 400}
          height={window.innerHeight - 120}
          headerHeight={30}
          rowClassNameGetter={this._rowClassNameGetter}
        >

          {columns.map(function (value, i) {
            return <Column
              columnKey={value.key}
              header={<SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={this.state.colSortDirs[value.key]}
                style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}
              cell={({ rowIndex, ...props }) => (
                value.key === "no" ? <Nashi column={value} props={this.props.kizu_check_list} rowIndex={rowIndex} detail_list={this.detail_list}></Nashi>
                  : value.key === "check_at" || value.key === "Keiyaku_Date" ? <Date column={value} props={this.props.kizu_check_list} rowIndex={rowIndex} detail_list={this.detail_list}></Date>
                    : <Base column={value} props={this.props.kizu_check_list} rowIndex={rowIndex} detail_list={this.detail_list}></Base>
              )}
              width={this.state.columns.filter(a => a.key === value.key)[0].width}
              isResizable={true}
              fixed={value.frozen}

            />
          }, this)}
        </Table >

        <Modal isOpen={this.state.reqtoggle} toggle={this.reqtoggle} size='xl' fade={false}>
          <DialogTitle id="customized-dialog-title" onClose={this.reqtoggle}>
            傷汚れ詳細
            <IconButton aria-label="close" onClick={this.reqtoggle} style={{ float: 'right' }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <KizuDetail
            reqtoggle={this.reqtoggle}
          // ky={this.state.ky}
          />
          <ModalFooter>
          </ModalFooter>
        </Modal >

      </div >
    );
  }
};


//export  default CsustomerList;
const mapStateToProps = (state) => {
  return {
    kizu_check_list: state.KizuCheckList.kizu_check_list,
  }
}
function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KizuList);
