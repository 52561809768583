const authentication = require('../../../react-azure-adb2c2').default;

export const list = (values: any) => {

  let url = process.env.REACT_APP_GOAPI_API_URL + '/bicycleregistrationlist';

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


export const get_details = (values: any) => {

  let url = process.env.REACT_APP_GOAPI_API_URL + `/bicyclelist/` + values.Keiyaku_No;

  const token = authentication.getAccessToken();

  return fetch(url, {
    method: 'get',
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};