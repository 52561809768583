import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import './Css/Grid.css';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}

    </MuiDialogTitle>
  );
});



const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
var moment = require('moment-timezone');



var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      select_row: 0
    }
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}
const columns =
  [
    { key: 'template_name', name: 'タイトル', width: 150, resizable: true },
    { key: 'template_contents', name: '内容', width: 350, resizable: true },
  ]

const Base = ({ column, props, rowIndex, onChange, mouse_hover }) =>
  <div onMouseEnter={() => mouse_hover(rowIndex)} style={{ cursor: "pointer" }} onClick={() => onChange(props[rowIndex].template_contents)}>
    <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}><Cell style={{ whiteSpace: "nowrap" }} > {props[rowIndex][column.key]} </Cell></Tooltip>
  </div>

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns,
      reflash: false,
      reqtoggle: false,
      edittoggle: false,
      colSortDirs: {},
    };


    this.mouse_hover = this.mouse_hover.bind(this);

    this.edittoggle = this.edittoggle.bind(this);

    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    // this.reqtoggle = this.reqtoggle.bind(this);
    this._rowClassNameGetter = this._rowClassNameGetter.bind(this);

  }




  mouse_hover(id) {
    this.setState({ select_row: id })
  }


  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }





  _onSortChange(columnKey, sortDir) {

    let seisan_follow_search_list = this.props.template_list;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  onChange = (message) => {

    var data = {
      target: {
        value: message
      }
    }
    this.props.onChange(data);

  }
  edittoggle() {

    if (this.state.edittoggle == true) {
      this.props.get_request_file({ Outai_No: 0 });
      this.props.get_request_detail({ Outai_No: this.props.request_detail.detail[this.state.index].Outai_No });
    }

    this.setState({ edittoggle: !this.state.edittoggle })
  }

  _rowClassNameGetter(index) {
    if (this.props.template_list[index]) {
      if (this.state.select_row == index) {
        return 'pushed-row';
      }
    }
  }


  render() {

    return (
      <div id="seisanlist">



        <Table
          rowHeight={30}
          rowsCount={this.props.template_list.length}
          // rowsCount={10}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          width={470}
          height={window.innerHeight - 320}
          headerHeight={30}
          rowClassNameGetter={this._rowClassNameGetter}>
          {columns.map(function (value, i) {
            return <Column
              columnKey={value.key}
              header={<SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={this.state.colSortDirs[value.key]}
                style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}
              cell={({ rowIndex, ...props }) => (
                <Base column={value} props={this.props.template_list} rowIndex={rowIndex} onChange={this.onChange} mouse_hover={this.mouse_hover}></Base>
              )}
              width={this.state.columns.filter(a => a.key === value.key)[0].width}
              isResizable={true}
              fixed={value.frozen}
            />
          }, this)}
        </Table >
      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    request_detail: state.Adjustment.request_detail,
    request_file: state.RequestDetail.request_file,
    template_list: state.Keiyaku.template_list,

  }
}
function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);