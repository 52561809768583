import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import Button from '@material-ui/core/Button';
// import { CSVLink, CSVDownload } from "react-csv";
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from "date-fns/locale/ja";
import format from "date-fns/format";
import * as Board from './Saga/Board';
import { CSVLink } from "react-csv";
import SinkikizonGrid from "./SinkikizonGrid";
import TourokuGrid from "./TourokuGrid";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';


var moment = require('moment-timezone');
class ExtendedUtils extends DateFnsUtils {
    getCalendarHeaderText(date: any) {
        return format(date, "yyyy MMM", { locale: this.locale });
    }
    getDatePickerHeaderText(date: any) {
        return format(date, "MMMd日", { locale: this.locale });
    }
}
const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');


class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}



interface Props {
    get_splus_count_list: any,
    loading2: any,
    loading1: any,
}

interface State {
    year: any
}

const date1 = new Date();
const year = date1.getFullYear();

const month = date1.getMonth() + 1;
var sdate = moment(year + "-" + month + "-01").format('YYYY-MM-DD');


const taiou = [
    { label: String(year), key: String(year) },
    { label: String(date1.getFullYear() - 1), key: String(date1.getFullYear() - 1) },
];


class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            year: String(year)
        }
        this.year = this.year.bind(this);
        this.search = this.search.bind(this);
    }

    year = (e: any, values: any) => {
        if (values) {
            this.setState({
                year: values.label
            });
        } else {
            this.setState({
                year: ""
            });
        }
    };

    search() {
        this.props.get_splus_count_list(
            { year: this.state.year }
        )
    }

    render() {
        return (
            <div>
                <div className="row" >
                    <span style={{ marginLeft: 20 }}>アプリ登録増数</span>
                    {this.props.loading2 ?
                        <Autocomplete
                            id="combo-box-demo"
                            options={taiou}
                            defaultValue={{ label: this.state.year, key: String(year) }}
                            getOptionLabel={(option: any) => option.label}
                            style={{ width: 100, marginLeft: 20 }}
                            onChange={this.year}
                            renderInput={(params: any) => <TextField
                                autoComplete='off' {...params} />}
                        /> : ""}

                    {this.props.loading1 && this.props.loading2 && this.state.year ?
                        <Button
                            onClick={this.search}
                            size="small"
                            style={{ color: "white", backgroundColor: "#4169e1", margin: 1 }}
                            variant="contained">
                            検索
                        </Button> : ""}

                </div>


                {this.props.loading2 ?

                    <div>
                        <SinkikizonGrid />
                    </div>
                    : <div style={{ height: window.innerHeight - 700 }}>
                        <CircularProgress color="inherit" /></div>}
            </div>
        );

    }
};


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {
        loading1: state.board.loading1,
        loading2: state.board.loading2,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_splus_count_list(state: any) {
            dispatch(Board.get_splus_count_list(state))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);




