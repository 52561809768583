import React, { Component } from 'react';
import { connect } from 'react-redux';
import CardComponent from "./CardComponent";
import * as Scenario from './Saga/Scenario';
import EditChatFlowComponent from './EditChatFlowComponent';
import { Table, Column, Cell } from 'fixed-data-table-2';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';

import { Modal, ModalHeader, ModalFooter, ModalBody, Row, Col } from 'reactstrap';
// import * as Board from './Saga/Board';
import Draggable from 'react-draggable'; // The default
import Owner from '../Owner/Index';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { TextField } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Radio, RadioGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';




class CustomerList extends Component {


    constructor(props) {
        super(props);

        this.state = {
            refresh: false,
            modal: false,
            columnWidths: {
                id: 50,
                name: 400,
                remarks: 400,
              },
        }


        // alert(JSON.stringify(this.props.room_support_chat_template_list))


        // this.togglesheet = this.togglesheet.bind(this);
        // this.togglesheet_set = this.togglesheet_set.bind(this);

        // this.toggle_rent_money = this.toggle_rent_money.bind(this);
        // this.toggle_owner = this.toggle_owner.bind(this);

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);

        // this.state = {
        //     modalcontract: false,


        // }

        this.props.get_room_support_chat_template_category_list()

    }

    handleOpen(id){


        this.props.change_room_support_chat_template_category_id(id)

        this.setState({
            modal: true
        });

    }

    onChangeType(e){

        let room_support_chat_template = this.props.room_support_chat_template

        room_support_chat_template = e.target.value

        this.props.change_room_support_chat_template(room_support_chat_template)

        this.props.get_room_support_chat_template_category_list()

        this.setState({
            change: !this.state.change
        });
    }

    handleChange(e, rowIndex) {
        let room_support_chat_template_category_list = this.props.room_support_chat_template_category_list

        // let index = room_support_chat_template_category_list.findIndex(e => e.id == id)
        // console.log(this.props.claso_blog_places)
        

        if(e.target.name == "is_deleted"){
            room_support_chat_template_category_list.filter(a=>a.is_deleted != 1)[rowIndex][e.target.name] = 1
        }else{
            room_support_chat_template_category_list.filter(a=>a.is_deleted != 1)[rowIndex][e.target.name] = e.target.value
        }

        room_support_chat_template_category_list.filter(a=>a.is_deleted != 1)[rowIndex].is_changed = 1

        this.props.change_room_support_chat_template_category_list(room_support_chat_template_category_list)

        this.setState({
            change: !this.state.change
        });

    }

    handleUpdate() {

        this.props.update_room_support_chat_template_category_list()

        this.setState({
            edit_mode:false,
            change: !this.state.change
        });

        
    }

    handleDel(rowIndex) {
        // console.log(this.props.claso_blog_places)
        let room_support_chat_template_category_list = this.props.room_support_chat_template_category_list

        // let index = owner_renrakusaki.findIndex(e => e.no == no)
        // console.log("aaaaaaaaaaaaaaaaaaaaaaa")
        // alert(rowIndex)
        // console.log(room_support_chat_template_category_list.filter(a=>a.is_deleted != 1)[rowIndex])

        room_support_chat_template_category_list.filter(a=>a.is_deleted != 1)[rowIndex].is_changed = 1
        room_support_chat_template_category_list.filter(a=>a.is_deleted != 1)[rowIndex].is_deleted = 1


        this.props.change_room_support_chat_template_category_list(room_support_chat_template_category_list)
        this.setState({
            change: !this.state.change
        });

    }

    handleAdd() {

        // this.props.add_owner_renrakusaki()


        let room_support_chat_template_category_list = this.props.room_support_chat_template_category_list
    
        let new_data = {
            id: -1,
            name: "",
            remarks: "",
            is_deleted: 0,
            is_changed: 0,
        }
        

        this.props.change_room_support_chat_template_category_list(room_support_chat_template_category_list.concat(new_data));



        this.setState({
            change: !this.state.change
        });
    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({ columnWidths }) => ({
          columnWidths: {
            ...columnWidths,
            [columnKey]: newColumnWidth,
          },
        }));
    }
    
    componentWillMount() {
        // this.props.get_kyousais()
        // this.props.customerNewDispSet(0)
        // this.submit = this.submit.bind(this);
    }
  

    render() {

        
        return (
            // <div style={{overflowX:"scroll" ,width:500 }}>
            <Card style={{ padding: "10px", }}>
                <div>
                <RadioGroup style={{marginLeft:10, marginTop:10, float:"left"}} row aria-label="position" name="room_support_chat_template" onChange={(e) => this.onChangeType(e)} value={parseInt(this.props.room_support_chat_template)} >
                    <FormControlLabel control={<Radio value={0} style={{padding:0}} />} label="分けたタイプ" />
                    <FormControlLabel control={<Radio value={1} style={{padding:0}} />} label="繋げたタイプ(2)" />
                </RadioGroup>
                <Button size="small" variant="contained" style={{float:'right', marginRight:185, color:"white", backgroundColor: "#5cb85c",}} onClick={()=>this.handleAdd()} startIcon={<AddCircleOutlineIcon/>}>追加</Button>
                <Button size="small" variant="contained" style={{float:'right'}} color="primary" onClick={()=>this.handleUpdate()} startIcon={<SaveAltIcon/>}>保存</Button>
                </div>
                <Table 
                    rowHeight={35} 
                    rowsCount={this.props.room_support_chat_template_category_list.filter(a=>a.is_deleted != 1).length} 
                    height={window.innerHeight - 190} 
                    headerHeight={30} 
                    width={window.innerWidth - 100}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={false}
                >

                <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton onClick={(e) => { this.handleOpen(this.props.room_support_chat_template_category_list.filter(a=>a.is_deleted != 1)[rowIndex].id); }} aria-label="edit" size="small"><EditIcon /></IconButton></Cell>)} width={40} />
                    <Column width={this.state.columnWidths.id} isResizable={true} columnKey="id" header={<Cell>ID</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.room_support_chat_template_category_list.filter(a=>a.is_deleted != 1)[rowIndex].id != -1 ? this.props.room_support_chat_template_category_list.filter(a=>a.is_deleted != 1)[rowIndex].id : ""}</Cell>)} />
                    <Column width={this.state.columnWidths.name} isResizable={true} columnKey="name" header={<Cell>タイトル</Cell>} cell={({ rowIndex, ...props }) => (
                        <Cell {...props}>
                            <TextField name="name" style={{width: "100%"}} value={this.props.room_support_chat_template_category_list.filter(a=>a.is_deleted != 1)[rowIndex].name} onChange={(e)=>this.handleChange(e, rowIndex)} />
                            {/* {this.props.room_support_chat_template_category_list[rowIndex].name} */}
                        </Cell>
                    )} />
                    <Column width={this.state.columnWidths.remarks} isResizable={true} columnKey="remarks" header={<Cell>備考</Cell>} cell={({ rowIndex, ...props }) => (
                        <Cell {...props} style={{backgroundColor:""}}>
                            <TextField name="remarks" style={{width: "100%"}} value={this.props.room_support_chat_template_category_list.filter(a=>a.is_deleted != 1)[rowIndex].remarks} onChange={(e)=>this.handleChange(e, rowIndex)} />
                            {/* {this.props.room_support_chat_template_category_list[rowIndex].remarks} */}
                        </Cell>
                    )} />
                    <Column
                        cell={({rowIndex, ...props}) => (
                            <Cell {...props}>
                                <IconButton name="is_deleted" style={{marginTop:-5, marginLeft:5}} onClick={(e)=>this.handleDel(rowIndex)} aria-label="edit" size="small"><DeleteForeverIcon color="secondary" /></IconButton>
                            </Cell>
                        )}
                        width={50}
                    />
                </Table>
            
                <Modal isOpen={this.props.category_save_modal_disp} fade={false} >
                    {/* <ModalHeader toggle={(e) => { this.setState({ "modal": false }) }}>
                        <span>営業対応履歴</span>
                    </ModalHeader> */}
                    <ModalBody>
                        <div>保存しました！</div>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="contained" color="" style={{ marginLeft: 3 }} onClick={() => this.props.change_category_save_modal_disp(false)}>閉じる</Button>
                    </ModalFooter>

                </Modal>


                <Modal isOpen={this.state.modal} fade={false} size="xl" toggle={(e) => { this.setState({ "modal": false }) }} >
                    {/* <ModalHeader toggle={(e) => { this.setState({ "modal": false }) }}>
                        <span>営業対応履歴</span>
                    </ModalHeader> */}


                    <ModalBody>


                        <EditChatFlowComponent />




                    </ModalBody>


                    {/* <Button onClick={this.handleClose}>閉じる</Button> */}
                    <ModalFooter>
                        <Button variant="contained" color="primary" onClick={() => this.props.change_save_modal_disp(true)}>保存</Button>
                        <Button variant="contained" color="" style={{ marginLeft: 3 }} onClick={(e) => { this.setState({ "modal": false }) }}>閉じる</Button>

                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.props.save_modal_disp} fade={false} >
                    {/* <ModalHeader toggle={(e) => { this.setState({ "modal": false }) }}>
                        <span>営業対応履歴</span>
                    </ModalHeader> */}
                    <ModalBody>
                        <div>本当に保存してよろしいですか</div>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="contained" color="primary" onClick={() => this.props.update_room_support_chat_template_list()}>保存</Button>
                        <Button variant="contained" color="" style={{ marginLeft: 3 }} onClick={() => this.props.change_save_modal_disp(false)}>閉じる</Button>
                    </ModalFooter>

                </Modal>
            </Card>
       
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {

        room_support_chat_template_category_list: state.scenario.room_support_chat_template_category_list,
        save_modal_disp: state.scenario.save_modal_disp,
        category_save_modal_disp: state.scenario.category_save_modal_disp,
        room_support_chat_template: state.scenario.room_support_chat_template,
    }
}
function mapDispatchToProps(dispatch) {
    return {

        get_room_support_chat_template_category_list(state) {
            dispatch(Scenario.get_room_support_chat_template_category_list(state))
        },
        change_room_support_chat_template_category_list(state) {
            dispatch(Scenario.change_room_support_chat_template_category_list(state))
        },
        update_room_support_chat_template_category_list(state) {
            dispatch(Scenario.update_room_support_chat_template_category_list(state))
        },
        change_save_modal_disp(state) {
            dispatch(Scenario.change_save_modal_disp(state))
        },
        change_room_support_chat_template_category_id(state) {
            dispatch(Scenario.change_room_support_chat_template_category_id(state))
        },
        update_room_support_chat_template_list(state) {
            dispatch(Scenario.update_room_support_chat_template_list(state))
        },
        change_category_save_modal_disp(state) {
            dispatch(Scenario.change_category_save_modal_disp(state))
        },
        change_room_support_chat_template(state) {
            dispatch(Scenario.change_room_support_chat_template(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




