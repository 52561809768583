import React, { Component }  from 'react';
import { connect } from 'react-redux';

import './Css/Loading.css';

class Loading extends Component {
   

    render() {
       
        return (

            <div >
               
                <div className="cv-spinner">
                    <span className="spinner"></span>
                    </div>
                

            </div>

        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,
        //customeredit2: state.customersReducer.customeredit2,



    }
}
function mapDispatchToProps(dispatch) {
    return {
//        customeredit1change(state) {
//            dispatch(customeredit1change(state))
//        },
//customeredit2change(state) {
//        dispatch(customeredit2change(state))
//    },
//        responseeditchange(state) {
//            dispatch(responseeditchange(state))
//        },
//        customerdetailData1(state) {
//            dispatch(customerdetailData1(state))
//        }, responsedetailData(state) {
//            dispatch(responsedetailData(state))
//        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
