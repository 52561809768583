
export const detail = (values: any) => {

    let url = ``

    return fetch(url, {
        method: 'post',

        // headers: { 'Content-type': 'application/json; charset=UTF-8' }
        body: JSON.stringify(values),
        // headers: { 'Content-type': 'application/json' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}

export const biko = (values: any) => {

    let url = ``

    return fetch(url, {
        method: 'post',

        // headers: { 'Content-type': 'application/json; charset=UTF-8' }
        body: JSON.stringify(values),
        // headers: { 'Content-type': 'application/json' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}