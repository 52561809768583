import React, { Component } from 'react';

import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
//import CustomerInfoEdit from './CustomerInfoEditComponent';
//import CustomerInfoDisp from './CustomerInfoDispComponent';
import Fab from '@material-ui/core/Fab';
import { firebaseDb } from '../../Store/FirebaseStore';
import { Modal, Row, Col, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import './Css/Line.css';
import LineRightTextComponent from './LineRightTextComponent';
import LineRightImgComponent from './LineRightImgComponent';
import LineRightType1Component from './LineRightType1Component';
import LineRightType2Component from './LineRightType2Component';
import LineRightType3Component from './LineRightType3Component';
import LineRightType4Component from './LineRightType4Component';
import LineRightType5Component from './LineRightType5Component';
import LineLeftTextComponent from './LineLeftTextComponent';
import LineLeftImgComponent from './LineLeftImgComponent';
import LineTimeComponent from './LineTimeComponent';
import LineFormComponent from './LineFormComponent';
import throttle from 'lodash.throttle';
import * as Line from './Saga/Line';
import LineDispComponent from './LineDispComponent';
import Dropzone from 'react-dropzone';
// import Grid from '../StockRooms/Grid';
import Card from '@material-ui/core/Card';
// function separate(num) {
//     // 文字列にする
//     num = String(num);

//     var len = num.length;

//     // 再帰的に呼び出すよ
//     if (len > 3) {
//         // 前半を引数に再帰呼び出し + 後半3桁
//         return separate(num.substring(0, len - 3)) + ',' + num.substring(len - 3);
//     } else {
//         return num;
//     }
// }




class Lines extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: '1',
            messages: [],
            messages2: {},
            scrollTop: 0,
            old_id: 0,
            back_date: "",
            take: 20,
            // top: this.props.lines[0].id,
            modal_send: 0,

            base64: null,
            mimeType: null
        };
        console.log(this.props.lines)
        // this.props.lines.map(){
        //     this.Print()
        // }
        //
        this.send = this.send.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.lineopen = this.lineopen.bind(this);
        this.toggle_send = this.toggle_send.bind(this);

        this.scrollToBottom = this.scrollToBottom.bind(this);

        this.onDrop = this.onDrop.bind(this);


        // var result = props.lines.map(function( value ) {

        //         // alert()
        //     return this.Print(value)

        //     //配列の各要素を2倍にする
        //     // return value * 2;

        // });
    }






    // }


    async componentDidMount() {
        console.log(this.props)

        if (this.node != null && this.props.line_bottom === true) {

            this.node.scrollTop = this.node.scrollHeight;
            this.props.change_line_bottom(false);
        }
    }
    componentDidUpdate() {
        // alert(this.props.line_bottom)
        // alert(this.props.line_bottom)
        if (this.node != null && this.props.line_bottom === true) {

            this.node.scrollTop = this.node.scrollHeight;
            this.props.change_line_bottom(false);
        }
    }


    send(value) {
        // alert(this.props.line_user_details.id)
        this.props.send_message({ replytoken: "globalcenter", message_type: "text", message_text: value.message, line_user_id: this.props.line_user_details.id, rent_response_id: this.props.responsedetail.id })


    }


    lineopen() {
        this.props.get_line_open({ lineuserid: this.props.line_user_details.id, take: 20 })
    }


    toggle_send(nom) {

        this.setState({
            // modal_send: nom,
            base64: null,
            name: null,
            mimeType: null,
        })
        this.props.change_line_file_data(null)
        this.props.change_line_send_modal(nom)
    }

    scrollToBottom = () => {

        if (this.node != null && this.props.line_bottom === true) {

            this.node.scrollTop = this.node.scrollHeight;


            this.props.change_line_bottom(false);
        }
    }

    onDrop = (files) => {
        console.log(files)
        const file = files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        console.log(file.type)
        reader.onload = () => {
            this.setState({
                base64: reader.result,
                name: file.name,
                mimeType: file.type
            })
            this.props.change_line_file_data({
                file: files,
                name: file.name,
                mimeType: file.type
            })
        }

        // let sendfile = [];
        // sendfile.file = files;



    };


    handleScroll = throttle((event) => {
        console.log(this.node.scrollTop)

        // console.log(this.refs[this.state.top])

        if (this.node.scrollTop === 0) {
            // alert(this.state.take)

            this.props.get_lines({ lineuserid: this.props.line_user_details.id, take: this.state.take + 5 })
            this.setState({ take: this.state.take + 5 })
            this.node.scrollTop = 50;
            //     let ref = firebaseDb.child('line/all').orderByChild('id').startAt(this.state.old_id + 1).limitToFirst(1);

            //     ref.off()
            //     ref.on('child_added',
            //         (snapshot) => {

            //             this.Print(snapshot);

            //             this.node.scrollTop = 30;

            //         })
        }
        // this.setState({
        //     scrollTop: this.node.scrollTop
        //  })

    }, 100)

    render() {


        return (
            <div className="item-line" id="item-line" onScroll={this.handleScroll} ref={(node) => this.node = node} style={{ height: this.props.line_open_flg ? "calc(100% - 60px)" : "500px" }}>

                <div>
                    {this.props.line_send_modal === 1 ? <div style={{ position: "absolute", zIndex: 3000, background: "rgba(45,45,45, 0.5)", width: "100%", height: "100%", marginLeft: -15, marginTop: -30 }}>

                        <div style={{ margin: "auto", marginTop: 50, borderRadius: "10px", width: "400px", height: "450px", padding: "20px", background: "#FFF" }}>
                            LINE物件情報送信
                        {/* <Grid /> */}

                            <Button color="danger" style={{ margin: "20px", marginTop: "50px" }} onClick={this.props.send_line_stock_rooms} disabled={this.props.line_send_list_loading}>送信する</Button>
                            <Button color="secondary" style={{ margin: "20px", marginTop: "50px" }} onClick={() => this.toggle_send(0)}>キャンセル</Button>

                        </div>

                    </div> : ""
                    }

                    {this.props.line_send_modal === 2 ? <div style={{ position: "absolute", zIndex: 3000, background: "rgba(45,45,45, 0.5)", width: "100%", height: "100%", marginLeft: -15, marginTop: -30 }}>
                        <div style={{ margin: "auto", marginTop: 50, borderRadius: "10px", width: "400px", height: "400px", padding: "20px", background: "#FFF" }}>
                            <p>ファイル送信</p>
                            {this.state.base64 && (this.state.mimeType === "image/jpeg" || this.state.mimeType === "image/png") ? <div><img src={this.state.base64} style={{ maxWidth: 250, maxHeight: 250 }} /></div>
                                : this.state.base64 ? <div><Card style={{ padding: 5 }}><b>{this.state.name}</b> </Card></div> : <Dropzone

                                    onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps }) => (

                                        <div {...getRootProps({ className: 'dropzone' })} style={{ background: "#f5f5f5" }}>

                                            <input {...getInputProps()} />

                                            <div style={{ height: 100 }}>
                                                <p>{this.state.name ? this.state.name : "ファイル添付　クリックまたはドラッグ&ドロップ"}</p>
                                                <ul style={{ padding: 0, marginTop: 3 }}  >


                                                </ul>

                                            </div>

                                        </div>

                                    )}
                                </Dropzone>}
                            {this.state.base64 ? <Button color="danger" style={{ margin: "20px", marginTop: "50px" }} onClick={this.props.upload_line_file} disabled={this.props.line_send_file_loading}>送信する</Button> : ""}

                            <Button color="secondary" style={{ margin: "20px", marginTop: "50px" }} onClick={() => this.toggle_send(0)}>キャンセル</Button>

                        </div>
                    </div> : ""}



                    {this.props.lines.length > 0 ? <LineDispComponent /> : ""}
                    <div style={{ float: "left", clear: "both" }}
                        ref={(el) => { this.messagesEnd = el; }}>
                    </div>

                    {this.props.line_open_flg ? <div className="linebottom">
                        <LineFormComponent onSubmit={this.send} get_line_close={this.props.get_line_close} toggle_send={this.toggle_send} />

                    </div> : <div className="lineResponseBottom"><Fab variant="extended" size="small" style={{ marginLeft: "5px" }} onClick={this.lineopen}>
                        <i class="fab fa-line" style={{ fontSize: "20px", color: "green" }}></i>

                    </Fab></div>}




                </div>
            </div>
        );
    }
}


//コンテナ
const mapStateToProps = state => {
    return {

        // responsedetail: state.responsesReducer.responsedetail,
        lines: state.line.lines,
        line_bottom: state.line.line_bottom,
        line_user_details: state.line.line_user_details,
        line_open_flg: state.line.line_open_flg,
        line_send_modal: state.line.line_send_modal,
        line_send_file_loading: state.line.line_send_file_loading,
        line_send_list_loading: state.line.line_send_list_loading,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_mail_new_success(state) {
        //    dispatch(Mail.change_mail_new_success(state))
        // },
        send_message(state) {
            dispatch(Line.send_message(state))
        },
        get_lines(state) {
            dispatch(Line.get_lines(state))
        },
        get_lines_success(state) {
            dispatch(Line.get_lines_success(state))
        },
        change_line_bottom(state) {
            dispatch(Line.change_line_bottom(state))
        },
        get_line_close(state) {
            dispatch(Line.get_line_close(state))
        },
        get_line_open(state) {
            dispatch(Line.get_line_open(state))
        },
        send_line_stock_rooms(state) {
            dispatch(Line.send_line_stock_rooms(state))
        },
        change_line_file_data(state) {
            dispatch(Line.change_line_file_data(state))
        },
        upload_line_file(state) {
            dispatch(Line.upload_line_file(state))
        },
        change_line_send_modal(state) {
            dispatch(Line.change_line_send_modal(state))
        },
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Lines);
