import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import KizuAriContent from "./KizuAriContent";
import KizuNashiContent from "./KizuNashiContent";
import * as CustomerLink from './Saga/CustomerLink';
import Button from '@material-ui/core/Button';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import * as KizuCheckList from '../KizuCheckList/Saga/KizuCheckList';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


var moment = require('moment-timezone');


interface Props {
    kizu_check_detail: any,
}

interface State {
}

class KizuDetail extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
    }



    render() {
        return (
            <div>
                <TableContainer>
                    <Table>


                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ width: '15%', fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    受付日
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.kizu_check_detail[0].check_at ? moment(this.props.kizu_check_detail[0].check_at).tz("utc").format('YYYY-MM-DD') : ""}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ width: '15%', fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    契約者
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.kizu_check_detail[0].keiyakusya_name}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ width: '15%', fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    客付店舗
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.kizu_check_detail[0].Tenpo_Name}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    契約日
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.kizu_check_detail[0].Keiyaku_Date ? moment(this.props.kizu_check_detail[0].Keiyaku_Date).tz("utc").format('YYYY-MM-DD') : ""}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    契約者電話番号
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.kizu_check_detail[0].keiyakusya_tel}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    客付担当
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.kizu_check_detail[0].user_name_all}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    物件名
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.kizu_check_detail[0].Tatemono_Name}
                                    <span style={{ marginLeft: 5 }}>
                                        <a target="_blank" rel="noopener" href={process.env.REACT_APP_HOMESTATION4_URL + "/manage/building/detail/index?t_id=" + this.props.kizu_check_detail[0].Tatemono_Id} className="fas fa-external-link-alt"></a>
                                    </span>
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    登録者
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.kizu_check_detail[0].name}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    オーナー担当
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.kizu_check_detail[0].Shoyuusha_Tantou_Name}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    号室
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.kizu_check_detail[0].Room_Name}
                                    <span style={{ marginLeft: 5 }}>
                                        <a target="_blank" rel="noopener" href={process.env.REACT_APP_HOMESTATION4_URL + "/manage/room/detail/index?t_id=" + this.props.kizu_check_detail[0].Tatemono_Id + "&r_id=" + this.props.kizu_check_detail[0].Room_Id} className="fas fa-external-link-alt"></a>
                                    </span>
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    登録者電話番号
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    {this.props.kizu_check_detail[0].tel}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ fontWeight: 'bold', fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    確認担当
                                </TableCell>
                                <TableCell style={{ fontSize: '14px', paddingTop: 0, paddingBottom: 0 }}>
                                    -
                                </TableCell>
                            </TableRow>
                        </TableBody>

                    </Table>
                </TableContainer>
            </div >
        );
    }
};


const mapStateToProps = (state: any) => {
    return {
        kizu_check_detail: state.KizuCheckList.kizu_check_detail,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(KizuDetail);




