const authentication = require('../../react-azure-adb2c2').default;
export const get_chinryoukoumoku = (values: any) => {

        const url = process.env.REACT_APP_API_BASE_URL + `/api/TmChinryouKoumokus/`;

        const token = authentication.getAccessToken();
        //const search = values ? values : {};


        return fetch(url, {
                headers: { 'Authorization': 'Bearer ' + token }

        }).then(res => res.json())
                .then(payload => ({ payload }))
                .catch(error => ({ error }));
}
