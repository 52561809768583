
export const list = (values: any) => {

    let url = ``
    // let url = `http://localhost:5000/api/v1/owner/remittance/check/logs`;

    return fetch(url, {
        method: 'get',

        // headers: { 'Content-type': 'application/json; charset=UTF-8' }
        // body: JSON.stringify(values),
        // headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}

