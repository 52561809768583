import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import './Css/Grid.css';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import * as MessageList from './Saga/MessageList';
import Button from '@material-ui/core/Button';
import { smsDB } from '../../Store/FirebaseStore';
import IsseiDetailGrid from "./IsseiDetailGrid";

const { MuiPickersUtilsProvider, KeyboardDatePicker } = require('@material-ui/pickers');
let unsub
var moment = require('moment-timezone');
var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    }
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>
        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}
      </Cell>
    );
  }

  _onSortChange(e) {
    e.preventDefault();
    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}
const columns =
  [
    { key: 'id', name: 'ID', width: 80, resizable: true },
    { key: 'Tatemono_Name', name: '物件名/送付先', width: 200, resizable: true },
    { key: 'message', name: '内容', width: 400, resizable: true },
    { key: 'sended_at', name: '配信日時', width: 200, resizable: true },
    { key: 'Tenpo_Name_Short', name: '配信部署', width: 100, resizable: true },
    { key: 'user_name_all', name: '配信者', width: 200, resizable: true },
    { key: 'error_count', name: 'エラー', width: 200, resizable: true },
  ]

const Base = ({ column, props, rowIndex }) =>
  <Tooltip placement="bottom-start" title={props[rowIndex][column.key]}>
    <Cell style={{ whiteSpace: "nowrap" }} > {props[rowIndex][column.key]} </Cell>
  </Tooltip>

const Date = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).tz("Asia/Tokyo").format('YYYY-MM-DD HH:mm:ss') : ""}
  </Cell>

const Error = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props[rowIndex][column.key] !== 0 ? <div style={{ color: "red" }}>送信エラー： {props[rowIndex][column.key]} 件</div> : ""}
  </Cell>

const To = ({ column, props, rowIndex, opento }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <div>{props[rowIndex][column.key]}</div>
    <div>
      <i className="fas fa-user-alt" style={{ color: "blue" }} onClick={() => opento(props[rowIndex]["id"])}></i>
    </div>
  </Cell>

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns,
      reflash: false,
      colSortDirs: {},
      detailtoggle: false

    };




    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.detailtoggle = this.detailtoggle.bind(this);

  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }





  _onSortChange(columnKey, sortDir) {

    let seisan_follow_search_list = this.props.newlist_issei;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }



  detailtoggle(e) {

    this.props.issei_detail(e);
    this.setState({ detailtoggle: !this.state.detailtoggle })

  }


  render() {
    return (
      <div id="Keiyaku">
        <div style={{ fontSize: 15, fontWeight: "bold" }}><i className="far fa-paper-plane"></i> 一斉送信履歴</div>
        <Table
          rowHeight={90}
          rowsCount={this.props.newlist_issei.length}
          // rowsCount={10}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          width={window.innerWidth - 260}
          height={window.innerHeight - 300}
          headerHeight={30}>
          {columns.map(function (value, i) {
            return <Column
              columnKey={value.key}
              header={<SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={this.state.colSortDirs[value.key]}
                style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

              // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
              cell={({ rowIndex, ...props }) => (
                value.key === "Tatemono_Name" ? <To column={value} props={this.props.newlist_issei} rowIndex={rowIndex} opento={this.detailtoggle}></To>
                  : value.key === "error_count" ? <Error column={value} props={this.props.newlist_issei} rowIndex={rowIndex}></Error>
                    : value.key === "sended_at" ? <Date column={value} props={this.props.newlist_issei} rowIndex={rowIndex}></Date>
                      : <Base column={value} props={this.props.newlist_issei} rowIndex={rowIndex} ></Base>
              )}
              width={this.state.columns.filter(a => a.key === value.key)[0].width}
              isResizable={true}
              fixed={value.frozen}

            />
          }, this)}
        </Table >





        < Modal isOpen={this.state.detailtoggle} toggle={this.detailtoggle} size="lg" fade={false}>
          <ModalHeader toggle={this.detailtoggle}>送付先</ModalHeader>
          {this.props.issei_details[0] ?
            <IsseiDetailGrid />
            : ""}
          <ModalFooter>
          </ModalFooter>
        </Modal >



      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    newlist_issei: state.MessageList.newlist_issei,
    issei_details: state.MessageList.issei_details,

  }
}
function mapDispatchToProps(dispatch) {
  return {
    newmessage_customers(state) {
      dispatch(MessageList.newmessage_customers(state))
    },
    issei_detail(state) {
      dispatch(MessageList.issei_detail(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);