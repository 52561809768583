import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
//const authentication = require('react-azure-adb2c').default;
//import { Dispatch } from 'redux';


import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
// import * as RentContractMoniesApi from '../Api/RentContractMoniesApi';
// import * as RentContractAccountingApi from '../Api/RentContractAccountingApi.ts_';
import * as KeiyakuApi from '../Api/Keiyaku';



// import * as RentAgentsApi from './Api/RentAgentsApi';

///types
const moment = require('moment')

//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}
export interface State {
    Tatemono: any;
    customers: any;
    customerdetail: any;
    returnsms: any;
    news_personal: any;
    chatlist: any;
    m_customer_id: any;
    news_list: any;
    template_category_list: any;
    template_list: any;
    template: any;
    room_support_m_user_id: any;
    periodic_report: any;

}
const initialState: State = {
    Tatemono: [],
    customers: [],
    customerdetail: [],
    returnsms: [],
    news_personal: [],
    chatlist: [],
    m_customer_id: 0,
    news_list: [],
    template_category_list: [],
    template_list: [],
    template: [],
    room_support_m_user_id: [],
    periodic_report: []
}



///action 
const actionCreator = actionCreatorFactory();

export const get_tatemono = actionCreator('GET_TATEMONO');
export const get_tatemono_success = actionCreator('GET_TATEMONO_SUCCESS');

export const del_tatemono = actionCreator('DEL_TATEMONO');
export const del_tatemono_success = actionCreator('DEL_TATEMONO_SUCCESS');

export const get_customers = actionCreator('GET_CUSTOMERS');
export const get_customers_success = actionCreator('GET_CUSTOMERS_SUCCESS');

export const get_detail = actionCreator('GET_DETAIL');
export const get_detail_success = actionCreator('GET_DETAIL_SUCCESS');

export const get_news_list = actionCreator('GET_NEWS_LIST');
export const get_news_list_success = actionCreator('GET_NEWS_LIST_SUCCESS');

export const get_template_category_list = actionCreator('GET_TEMPLATE_CATEGORY_LIST');
export const get_template_category_list_success = actionCreator('GET_TEMPLATE_CATEGORY_LIST_SUCCESS');

export const get_template = actionCreator('GET_TEMPLATE');
export const get_template_success = actionCreator('GET_TEMPLATE_SUCCESS');

export const get_template_detail = actionCreator('GET_TEMPLATE_DETAIL');
export const get_template_detail_success = actionCreator('GET_TEMPLATE_DETAIL_SUCCESS');

export const insert_template_category = actionCreator('INSERT_TEMPLATE_CATEGORY');
export const update_template_category = actionCreator('UPDATE_TEMPLATE_CATEGORY');

export const insert_template = actionCreator('INSERT_TEMPLATE');
export const update_template = actionCreator('UPDATE_TEMPLATE');

export const sms_allsend = actionCreator('SMS_ALLSEND');
export const sms_allsend_success = actionCreator('SMS_ALLSEND_SUCCESS');

export const send_news_personal = actionCreator('SEND_NEWS_PERSONAL');
export const send_news_personal_success = actionCreator('SEND_NEWS_PERSONAL_SUCCESS');

export const send_news_gakusei = actionCreator('SEND_NEWS_GAKUSEI');
export const send_news_gakusei_success = actionCreator('SEND_NEWS_GAKUSEI_SUCCESS');


export const get_chatlist = actionCreator('GET_CHATLIST');
export const get_chatlist_success = actionCreator('GET_CHATLIST_SUCCESS');
export const change_m_customer_id = actionCreator<any>('CHANGE_M_CUSTOMER_ID');
export const change_room_support_m_user_id = actionCreator<any>('CHANGE_ROOM_SUPPORT_M_USER_ID');

// export const get_periodic_report = actionCreator('GET_PERIODIC_REPORT');
// export const get_periodic_report_success = actionCreator('GET_PERIODIC_REPORT_SUCCESS');



export const KeiyakuReducer = reducerWithInitialState(initialState)
    .case(get_tatemono_success, (state, payload) => ({ ...state, Tatemono: payload }))
    .case(del_tatemono_success, (state, payload) => ({ ...state, Tatemono: payload }))
    .case(get_customers_success, (state, payload) => ({ ...state, customers: payload }))
    .case(get_detail_success, (state, payload) => ({ ...state, customerdetail: payload }))
    .case(sms_allsend_success, (state, payload) => ({ ...state, returnsms: payload }))
    .case(send_news_personal_success, (state, payload) => ({ ...state, news_personal: payload }))
    .case(send_news_gakusei_success, (state, payload) => ({ ...state, news_personal: payload }))
    .case(get_chatlist_success, (state, payload) => ({ ...state, chatlist: payload }))
    .case(change_m_customer_id, (state, payload) => ({ ...state, m_customer_id: payload }))
    .case(change_room_support_m_user_id, (state, payload) => ({ ...state, room_support_m_user_id: payload }))
    .case(get_news_list_success, (state, payload) => ({ ...state, news_list: payload }))
    .case(get_template_category_list_success, (state, payload) => ({ ...state, template_category_list: payload }))
    .case(get_template_success, (state, payload) => ({ ...state, template_list: payload }))
    .case(get_template_detail_success, (state, payload) => ({ ...state, template: payload }))
    // .case(get_periodic_report_success, (state, payload) => ({ ...state, periodic_report: payload }))




// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const KeiyakuSaga = [
    takeEvery('GET_TATEMONO', handle_get_tatemono),
    takeEvery('DEL_TATEMONO', handle_del_tatemono),
    takeEvery('GET_NEWS_LIST', handle_get_news_list),
    takeEvery('GET_CUSTOMERS', handle_get_customers),
    takeEvery('GET_DETAIL', handle_get_detail),
    takeEvery('SMS_ALLSEND', handle_sms_allsend),
    takeEvery('SEND_NEWS_PERSONAL', handle_send_news_personal),
    takeEvery('SEND_NEWS_GAKUSEI', handle_send_news_gakusei),
    takeEvery('GET_CHATLIST', handle_get_chatlist),
    takeEvery('GET_TEMPLATE_CATEGORY_LIST', handle_get_template_category_list),
    takeEvery('GET_TEMPLATE', handle_get_template),
    takeEvery('GET_TEMPLATE_DETAIL', handle_get_template_detail),
    takeEvery('INSERT_TEMPLATE_CATEGORY', handle_insert_template_category),
    takeEvery('UPDATE_TEMPLATE_CATEGORY', handle_update_template_category),
    takeEvery('INSERT_TEMPLATE', handle_insert_template),
    takeEvery('UPDATE_TEMPLATE', handle_update_template),
    // takeEvery('GET_PERIODIC_REPORT', handle_get_periodic_report),



];
// export default masterSaaaaaga;

// function* handle_get_periodic_report(action: any) {
//     const { payload, error } = yield call(KeiyakuApi.periodic_report, action.payload);
//     // サーバーからのレスポンスaaaデータによる分岐処理
//     if (payload && !error) {

//         console.log("aaaaaaaaaaa")
//         console.log(payload);
//         console.log(payload.type);
//         yield put(get_periodic_report_success(payload));
         
//         if (payload.type == 'application/pdf') {
//             const a = document.createElement('a');
//             a.download = "PeriodicReport.pdf";
//             a.href = URL.createObjectURL(payload);
//             a.click();
//         }else{
//             const a = document.createElement('a');
//             a.download = "PeriodicReport.html";
//             a.href = URL.createObjectURL(payload);
//             a.click();
//         }
//     } else {
//         alert(error)
//     }
// }

function* handle_update_template(action: any) {
    const { payload, error } = yield call(KeiyakuApi.update_template, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // yield put(get_tatemono_success(payload));
    } else {
        alert(error)
    }
}

function* handle_insert_template(action: any) {
    const { payload, error } = yield call(KeiyakuApi.insert_template, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // yield put(get_tateｓｓｓmono_success(payload));
    } else {
        alert(error)
    }
}

function* handle_update_template_category(action: any) {
    const { payload, error } = yield call(KeiyakuApi.update_template_category, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // yield put(get_tatemono_success(payload));
    } else {
        alert(error)
    }
}

function* handle_insert_template_category(action: any) {
    const { payload, error } = yield call(KeiyakuApi.insert_template_category, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // yield put(get_tatemono_success(payload));
    } else {
        alert(error)
    }
}

function* handle_get_template_detail(action: any) {
    const { payload, error } = yield call(KeiyakuApi.get_template_byid, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_template_detail_success(payload));
    } else {
        alert(error)
    }
}


function* handle_get_template(action: any) {
    const { payload, error } = yield call(KeiyakuApi.get_template_bycategory_id, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_template_success(payload));
    } else {
        alert(error)
    }
}

function* handle_get_template_category_list(action: any) {
    const { payload, error } = yield call(KeiyakuApi.get_template_category, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_template_category_list_success(payload));
    } else {
        alert(error)
    }
}

function* handle_get_news_list(action: any) {
    const { payload, error } = yield call(KeiyakuApi.news_by_tatemono_id, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_news_list_success(payload));
    } else {
        alert(error)
    }
}

function* handle_get_chatlist(action: any) {
    yield put(change_m_customer_id(action.payload.m_customer_id));
    yield put(change_room_support_m_user_id(action.payload.room_support_m_user_id));

    const { payload, error } = yield call(KeiyakuApi.get_chatlist, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_chatlist_success(payload));

        // yield put(get_room_support_m_devices())
    } else {
        alert(error)
    }
}



function* handle_get_tatemono(action: any) {
    const { payload, error } = yield call(KeiyakuApi.get_tatemono, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_tatemono_success(payload));
    } else {
        alert(error)
    }
}

function* handle_del_tatemono(action: any) {
    yield put(del_tatemono_success());
}

function* handle_get_customers(action: any) {
    const { payload, error } = yield call(KeiyakuApi.get_customers, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_customers_success(payload));
    } else {
        alert(error)
    }
}

function* handle_get_detail(action: any) {
    const { payload, error } = yield call(KeiyakuApi.get_detail, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_detail_success(payload));
    } else {
        alert(error)
    }
}


function* handle_sms_allsend(action: any) {
    const { payload, error } = yield call(KeiyakuApi.allsend, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(sms_allsend_success(payload));
    } else {
        alert(error)
    }
}


function* handle_send_news_personal(action: any) {
    const { payload, error } = yield call(KeiyakuApi.news_personal, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(send_news_personal_success(payload));
    } else {
        alert(error)
    }
}


function* handle_send_news_gakusei(action: any) {
    const { payload, error } = yield call(KeiyakuApi.news_gakusei, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(send_news_gakusei_success(payload));
    } else {
        alert(error)
    }
}