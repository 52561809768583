import React, { createContext, useState, useContext } from 'react'
import { Authentication } from '../../App'
import axios from "axios";
import Grid from '@mui/material/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Table, Column, Cell } from 'fixed-data-table-2';

function RoomGrid(props) {
  const authentication = useContext(Authentication);
  const rooms_data = props.rooms_data
  // const [created_at2, setCreated_at2] = useState(new Date());
  // React.useEffect(() => {
  // }, []);


  return (
    <div>
      {rooms_data['fam'] ?
        <Table rowHeight={35} rowsCount={4} width={window.innerWidth / 2.5} height={200} headerHeight={40}>
          <Column header={<Cell>部屋区分</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? "ファミリー" :
              rowIndex == 1 ? "単身" :
                rowIndex == 2 ? "その他" :
                  rowIndex == 3 ? "合計" : ""}
          </Cell>)} width={100} />

          <Column header={<Cell style={{ textAlign: "center" }}>1</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? rooms_data['fam']["1"] :
              rowIndex == 1 ? rooms_data['tan']["1"] :
                rowIndex == 2 ? rooms_data['etc']["1"] :
                  rowIndex == 3 ? rooms_data['total']["1"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>2</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? rooms_data['fam']["2"] :
              rowIndex == 1 ? rooms_data['tan']["2"] :
                rowIndex == 2 ? rooms_data['etc']["2"] :
                  rowIndex == 3 ? rooms_data['total']["2"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>3</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? rooms_data['fam']["3"] :
              rowIndex == 1 ? rooms_data['tan']["3"] :
                rowIndex == 2 ? rooms_data['etc']["3"] :
                  rowIndex == 3 ? rooms_data['total']["3"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>4</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? rooms_data['fam']["4"] :
              rowIndex == 1 ? rooms_data['tan']["4"] :
                rowIndex == 2 ? rooms_data['etc']["4"] :
                  rowIndex == 3 ? rooms_data['total']["4"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>5</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? rooms_data['fam']["5"] :
              rowIndex == 1 ? rooms_data['tan']["5"] :
                rowIndex == 2 ? rooms_data['etc']["5"] :
                  rowIndex == 3 ? rooms_data['total']["5"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>6</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? rooms_data['fam']["6"] :
              rowIndex == 1 ? rooms_data['tan']["6"] :
                rowIndex == 2 ? rooms_data['etc']["6"] :
                  rowIndex == 3 ? rooms_data['total']["6"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>7</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? rooms_data['fam']["7"] :
              rowIndex == 1 ? rooms_data['tan']["7"] :
                rowIndex == 2 ? rooms_data['etc']["7"] :
                  rowIndex == 3 ? rooms_data['total']["7"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>8</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? rooms_data['fam']["8"] :
              rowIndex == 1 ? rooms_data['tan']["8"] :
                rowIndex == 2 ? rooms_data['etc']["8"] :
                  rowIndex == 3 ? rooms_data['total']["8"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>9</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? rooms_data['fam']["9"] :
              rowIndex == 1 ? rooms_data['tan']["9"] :
                rowIndex == 2 ? rooms_data['etc']["9"] :
                  rowIndex == 3 ? rooms_data['total']["9"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>10</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? rooms_data['fam']["10"] :
              rowIndex == 1 ? rooms_data['tan']["10"] :
                rowIndex == 2 ? rooms_data['etc']["10"] :
                  rowIndex == 3 ? rooms_data['total']["10"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>11</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? rooms_data['fam']["11"] :
              rowIndex == 1 ? rooms_data['tan']["11"] :
                rowIndex == 2 ? rooms_data['etc']["11"] :
                  rowIndex == 3 ? rooms_data['total']["11"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>12</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? rooms_data['fam']["12"] :
              rowIndex == 1 ? rooms_data['tan']["12"] :
                rowIndex == 2 ? rooms_data['etc']["12"] :
                  rowIndex == 3 ? rooms_data['total']["12"] : ""}
          </Cell>)} width={45} />

          <Column header={<Cell style={{ textAlign: "center" }}>平均</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? rooms_data['fam']["avg"] :
              rowIndex == 1 ? rooms_data['tan']["avg"] :
                rowIndex == 2 ? rooms_data['etc']["avg"] :
                  rowIndex == 3 ? rooms_data['total']["avg"] : ""}
          </Cell>)} width={60} />

          <Column header={<Cell style={{ textAlign: "center" }}>全部</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ cursor: "pointer" }}>
            {rowIndex == 0 ? rooms_data['fam']["all"] :
              rowIndex == 1 ? rooms_data['tan']["all"] :
                rowIndex == 2 ? rooms_data['etc']["all"] :
                  rowIndex == 3 ? rooms_data['total']["all"] : ""}
          </Cell>)} width={60} />


          {/* <Column header={<Cell>配信予定日</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} onClick={() => edit(noticeList[rowIndex])} style={{ cursor: "pointer" }}>{noticeList[rowIndex].send_schedule_at ? moment(noticeList[rowIndex].send_schedule_at).utc().format('YYYY-MM-DD') : ""}</Cell>)} width={100} />
        <Column header={<Cell>配信日</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} onClick={() => edit(noticeList[rowIndex])} style={{ cursor: "pointer" }}>{noticeList[rowIndex].send_at ? moment(noticeList[rowIndex].send_at).utc().format('YYYY-MM-DD') : ""}</Cell>)} width={100} />
        <Column header={<Cell>記入者</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} onClick={() => edit(noticeList[rowIndex])} style={{ cursor: "pointer" }}>{noticeList[rowIndex].user_name_all}</Cell>)} width={120} />
        <Column header={<Cell>承認</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} onClick={() => edit(noticeList[rowIndex])} style={{ cursor: "pointer", textAlign: 'center', fontSize: noticeList[rowIndex].approval_flag === true ? 11 : "" }}>{noticeList[rowIndex].status === false ? "下書き" : noticeList[rowIndex].approval_flag === false ? "待" : "済"}</Cell>)} width={60} />
        <Column header={<Cell>承認日</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} onClick={() => edit(noticeList[rowIndex])} style={{ cursor: "pointer" }}>{noticeList[rowIndex].approval_at ? moment(noticeList[rowIndex].approval_at).utc().format('YYYY-MM-DD') : ""}</Cell>)} width={100} />
        <Column header={<Cell>通知</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} onClick={() => edit(noticeList[rowIndex])} style={{ cursor: "pointer", textAlign: 'center' }}>{noticeList[rowIndex].notice === "" ? "" : noticeList[rowIndex].is_push ? "あり" : "なし"}</Cell>)} width={50} />
        <Column header={<Cell>へやのしおり公開</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} style={{ textAlign: 'center' }}>{noticeList[rowIndex].heyanoshiori === "" ? "" : noticeList[rowIndex].is_heyanoshiori ? "あり" : "なし"}</Cell>)} width={150} /> */}
        </Table> : ""}
    </div>
  );
};

export default RoomGrid;
