
const authentication = require('../../../react-azure-adb2c2').default;






export const list = (values) => {
    let url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/lines/list/` + values.lineuserid + '/' + values.take;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



