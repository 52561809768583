import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Fab from '@material-ui/core/Fab';
import Badge from '@material-ui/core/Badge';
var moment = require('moment-timezone');
// Table data as a list of array.
const rows = [
  "first row",
  "second row",
  "third row"
  // .... and more
];
var columns = [
  {
    key: 'tatemono_name', name: '建物名', width: 150, frozen: true,

  },

  { key: 'kashinushi_name', name: '貸主', width: 90, frozen: true },
  // { key: 'owner_kaiyaku_date', name: '解約報告日', width: 80, resizable: true },
  { key: 'shoyuusha_name', name: '所有者', width: 120, frozen: true },
  { key: 'shoyuusha_tantou_name', name: '担当', width: 60, frozen: true },

  // { key: 'kosu24', name: '管理戸数', width: 50 },
  // { key: 'application24', name: '申込', width: 50 },
  // { key: 'kuushitsu24', name: '空室', width: 50 },
  // { key: 'kaiyaku24', name: '解約', width: 50 },
]


for (var i = 24; i !== 0; --i) {
  let month = moment().tz("Asia/Tokyo").subtract(i, 'months').endOf('month').format('YYYY/MM')
  columns.push({ key: 'kosu' + i, name: month + '管理戸数', width: 80 })
  columns.push({ key: 'kuushitsu' + i, name: month + '空室', width: 80 })
  columns.push({
    key: 'kaiyaku' + i, name: month + '解約', width: 80
  })
  columns.push({ key: 'application' + i, name: month + '成約', width: 80 })

}
// Custom cell implementation with special prop
const MyCustomCell = ({ mySpecialProp }) =>
  <Cell>
    {mySpecialProp === "column2" ? "I'm column 2" : "I'm not column 2"}
  </Cell>;

const TatemonoName = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }}><HomeWorkIcon color="primary" onClick={() => props.togglesheet(props.vacancies[rowIndex].tatemono_id, props.vacancies[rowIndex].room_id)} /> {props.vacancies[rowIndex][column.key]}</Cell>;
const RoomName = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >{props.vacancies[rowIndex][column.key]}</Cell>;


const Date = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap", width: "100%", height: "100%", background: moment(props.vacancies[rowIndex][column.key]).tz("Asia/Tokyo").format('YYYY/MM/DD') > moment().tz("Asia/Tokyo").format('YYYY/MM/DD') ? "#87cefa" : "#98fb98" }} >

    {props.vacancies[rowIndex][column.key] ? moment(props.vacancies[rowIndex][column.key]).tz("Asia/Tokyo").format('YYYY/MM/DD') : ""}
    <Badge badgeContent={"次2日"} color="secondary">　</Badge>

  </Cell>;


const DateCheck = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap", background: props.vacancies[rowIndex][column.key] ? "#f5f5f5" : "pink", width: "100%", height: "100%" }} >{props.vacancies[rowIndex][column.key] ? moment(props.vacancies[rowIndex][column.key]).tz("Asia/Tokyo").format('YYYY/MM/DD') : ""}</Cell>;


const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >{props.vacancies[rowIndex][column.key]}</Cell>;

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns
      // dataList: new FakeObjectDataListStore(1000000),
      // columnWidths: {
      //   firstName: 240,
      //   lastName: 150,
      //   sentence: 140,
      //   companyName: 60,
      // },
    };

    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }
  render() {

    return (
      <Table

        rowHeight={35}
        rowsCount={this.props.vacancies.length}
        onColumnResizeEndCallback={this._onColumnResizeEndCallback}
        width={1200}
        height={600}
        headerHeight={60}>
        {columns.map(function (value, i) {
          return <Column
            columnKey={value.key}
            header={<Cell >{value.name}</Cell>}
            // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
            cell={({ rowIndex, ...props }) => (

              value.key === "tatemono_name" ? <TatemonoName column={value} props={this.props} rowIndex={rowIndex}></TatemonoName>
                : value.key === "room_name" ? <RoomName column={value} props={this.props} rowIndex={rowIndex}></RoomName>
                  : value.key === "kaiyaku_date" ? <Date column={value} props={this.props} rowIndex={rowIndex}></Date>
                    : value.key === "order_at" ? <DateCheck column={value} props={this.props} rowIndex={rowIndex}></DateCheck>
                      : value.key === "hoshuu_date" ? <DateCheck column={value} props={this.props} rowIndex={rowIndex}></DateCheck>
                        : value.key === "completion_at" ? <DateCheck column={value} props={this.props} rowIndex={rowIndex}></DateCheck>
                          : value.key === "estimate_request" ? <DateCheck column={value} props={this.props} rowIndex={rowIndex}></DateCheck>
                            : value.key === "after_check" ? <DateCheck column={value} props={this.props} rowIndex={rowIndex}></DateCheck>
                              : value.key === "estimate_receives" ? <DateCheck column={value} props={this.props} rowIndex={rowIndex}></DateCheck>


                                : <Base column={value} props={this.props} rowIndex={rowIndex}></Base>
            )}
            width={this.state.columns.filter(a => a.key === value.key)[0].width}
            isResizable={true}
            fixed={value.frozen}

          />
        }, this)}

        {/* <Column
          header={<Cell>Col 2</Cell>}
          cell={<MyCustomCell mySpecialProp="column2" />}
          width={100}
        />
        <Column
          header={<Cell>Col 3</Cell>}
          cell={({ rowIndex, ...props }) => (
            <Cell {...props}>
              Data for column 3: {rows[rowIndex]}
            </Cell>
          )}
          width={200}
        /> */}
      </Table >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {

    search_modal: state.Adjustment.search_modal,
    room_details: state.roomsReducer.room_details,
    vacancies: state.vacancy.vacancies,
  }
}
function mapDispatchToProps(dispatch) {
  return {


  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);